/**
 * Created by liuyc14 on 2018/9/5.
 */
import React from 'react';
import request from '../../utils/request';
import apiDomain from '../../utils/domain';
import LoginInfo from '../../utils/loginInfo';
import {filterMenuItem} from '../../utils/menu';

const domain = apiDomain.baseDomain[apiDomain.env];

export const EVENTKEY_POWER = 'event_key_power';

/**
 * 基础组件
 * 实现功能：集成登录信息、事件监听、权限获取、路由对象获取
 * 
 * 使用方法：需在子类componentDidMount中调用super.componentDidMount();方法，完成数据初始化
 * 
 * 1、登录信息：使用 this.loginInfo 调用，比如 this.loginInfo.id 获取用户id
 * 2、事件监听：基于 MyEvent 实现，使用 this.event.on() 监听事件, this.event.trigger('click') 发送事件
 * 3、权限获取：
 * 
 *          第一种方法：可以在子类中定义 receivePower 函数，接受权限对象
 *          第二种方法：在子类 constructor 函数中使用 this.event.off(EVENTKEY_POWER, 'goodsList_power').on(EVENTKEY_POWER, 'goodsList_power', () => {
                this.setState({
                    buttonList: this.buttonList,
                    scanList: this.scanList,
                    tabList: this.tabList
                });
            });
 * 
 * 4、路由对象：使用this.router / this.match / this.params 获取路由对象及相应属性
 * 
 */
export default class BaseComponent extends React.Component{
    constructor(props){
        super(props);

        this.event = MyEvent.getInstance();

        const loginInfo = LoginInfo.getInstance();
        
        //登录信息
        this.loginInfo = loginInfo;
        //router信息
        this.router = {};
        this.match = {};
        this.params = {};
        //menuId
        this.menuId = "";

        //权限相关
        this.buttonList = [];
        this.scanList = [];
        this.tabList = [];
    }

    componentDidMount(){
        const router = this.props.router || {};
        const match = router.match || {};
        const params = match.params || {};
        
        this.router = router;
        this.match = match;
        this.params = params;
        
        const {index, regTab} = this.props;
        if(regTab && index){
            const menuId = regTab(filterMenuItem(index)) || '';
            this.menuId = this.menuId || menuId;
        }
        
        this.initPower({});
    }

    /**
     * 声明周期函数，权限发生变化时回调
     * @param nextPower 权限数据
     */
    receivePower(nextPower){
    }

    initPower = ({callback = null}) => {
        const req = this.getPower({});
        if(req !== false){
            req
                .then( res => {
                    const body = res.data || {};
                    if(body.code == 200){
                        const data = body.data || {};
                        this.buttonList = data.buttonList || [];
                        this.scanList = data.scanList || [];
                        this.tabList = data.tabList || [];
                        
                        this.event.trigger(EVENTKEY_POWER);
                        if(this.receivePower){
                            this.receivePower({buttonList: this.buttonList, scanList: this.scanList, tabList: this.tabList});
                        }
                        
                        if(typeof callback === 'function'){
                            callback();
                        }
                    }else{
                        console.warn(`获取权限[${body.code || 'error'}]${body.msg || '错误'}`);
                    }
                })
                .catch( e => {
                    console.error('获取权限报错！');
                    console.error(e);
                })
        }
    };

    getPower = ({menuId = '', callback=null}) => {
        const id = menuId || this.menuId;
        if(!id){
            console.log('模块id为空，无法获取权限');
            return false;
        }

        return request(`${domain}/jurisdiction/getOperaList/${id}`, {
            method: 'GET'
        });
    };
    
    reanderComponent = (key, array, reactComponent) => {
        const hasPower = this.hasPower(key, array);
        if(hasPower){
            return reactComponent;
        }else{
            return null;
        }
    };

    hasPower = (key, array = []) => {
        let result = false;
        for(let i = 0; i < array.length; i++){
            if(array[i] === key){
                result = true;
                break;
            }
        }
        return result;
    };
}

export class MyEvent {
    constructor(){
        this.listnerList = [];
    }

    on = (key, cbKey, cb) => {
        const filter = this.listnerList.filter(c => c.key === key);
        const callbackObj = {key: cbKey, callback: cb};

        if(filter && filter.length == 1){
            const listner = filter[0];
            listner.callbacks.push(callbackObj);
        }else{
            this.listnerList.push({key: key, callbacks: [callbackObj]});
        }
        return this;
    };

    off = (key, cbKey) => {
        const filter = this.listnerList.filter(c => c.key === key);
        if(filter && filter.length == 1){
            const listner = filter[0];
            if(!cbKey){
                listner.callbacks = [];
            }
            else{
                const array = [];
                listner.callbacks.map(c => {
                    if(c.key != cbKey){
                        array.push(c);
                    }
                });
                
                listner.callbacks = array;
            }
        }
        return this;
    };

    trigger = (key) => {
        const filter = this.listnerList.filter(c => c.key === key);
        if(filter && filter.length == 1){
            const listner = filter[0];
            listner.callbacks.forEach(c => {
                typeof c.callback == 'function' && c.callback();
            });
        }
        return this;
    };
}

MyEvent.getInstance = () => {
    if(window.__myEvent){
        return window.__myEvent;
    }else{
        return window.__myEvent = new MyEvent();
    }
};