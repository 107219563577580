export const allMenu = [
    {
        name: '工作台',
        url: 'index',
        icon: 'tico_bench',
    }, {
        name: '货源',
        url: 'goodsSrc',
        icon: 'tico_goodsrc',
    },{
        name: '发布货源',
        url: 'goodsSrcNew',
        icon: 'tico_goodsrc',
    },{
        name: '货源详情',
        url: 'goodsSrcView',
        icon: 'tico_goodsrc',
    }, {
        name: '运单',
        url: 'books',
        icon: 'tico_book',
        hasPop: true
    }, {
        name: '熟车',
        url: 'familiarCar',
        icon: 'tico_car',
    },
    {
        name: '修改密码',
        url: 'setPwd',
        notMenu: true,
    },
    {
        name: '详情',
        url: 'goodsSrcView',
        notMenu: true,
    },
    {
        name: '忘记密码',
        url: 'forget',
        notMenu: true
    },
    {
        name: '修改头像',
        url: 'updateHeadPic',
        notMenu: true,
    },
    {
        name: '404',
        url: 'noMatch',
        notMenu: true,
    },
    {
        name: '新手帮助',
        url: 'newUserHelp',
        notMenu: true,
    },
    {
        name: '我的消息',
        url: 'myMsg',
        notMenu: true,
    },

]

export const filterMenuItem = (url,arr) =>{
    let menus = arr || allMenu
    let farr = menus.filter(v=>v.url===url)
    return farr.length ? farr[0] : null
}