/**
 * Created by liuyc14 on 2018/9/10.
 */
import React from 'react';
import propTypes from 'prop-types';
import {message} from 'antd';
import './index.less';

import service from '../../service/loginService';

const __djs = (c, seconds, text) => {
    seconds--;
    if(seconds == 0){
        c.setState({sended: false, codeText: text});
    }else{
        c.setState({sended: true, codeText: seconds + 's后重发'});
        window.setTimeout(()=>{
            __djs(c, seconds, text);
        }, 1000);
    }
};

export default class VCode extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            sended: false,
            codeText: ''
        };
    }

    componentDidMount(){

    }

    render(){
        const {reSendText} = this.props;
        return (
            <div className="forget_box">
                <div className="formItem" style={{paddingLeft: 0, marginBottom: 0}}>
                    <div className="input inputCode">
                        <input
                            autoComplete="false"
                            className="value"
                            type="password"
                            placeholder= {this.props.placeholder || "请输入验证码"}
                            maxLength="16"
                            onChange={this.handleChange}
                            onBlur={this.handleBlur}
                        />
                        <a className="send" disabled={this.state.sended} onClick={this.sendCode}>{this.state.codeText}</a>
                    </div>
                </div>
            </div>
        );
    }

    handleChange = (e) => {
        const value = e.target.value;
        console.log(this.props);
        if(this.props.onChange){
            this.props.onChange(value);
        }
    };

    handleBlur = (e, ...args) => {
        if(this.props.onBlur){
            this.props.onBlur(e, ...args);
        }
    };

    sendCode = () => {
        service
            .sendVerify(this.props.phone, {
                ok: () => {
                    __djs(this, 60, this.props.reSendText);
                    this.setState({sended: true,phone:this.state.value});
                    return true;
                },
                failed: e => {
                    message.warn('短信发送失败，' + e.msg);
                    this.setState({sended: false});
                },
                error: () => {
                    message.error('短信发送接口错误，请稍后再试！');
                    this.setState({sended: false});
                }
            });
    };

    djs = (seconds) => {
        __djs(this, seconds, this.props.reSendText);
    };
}

VCode.propTypes = {
    defaultText: propTypes.string.isRequired,
    reSendText: propTypes.string.isRequired,
    placeholder: propTypes.string,
    defaultData: propTypes.array
};
