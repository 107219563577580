import React from 'react';
import {filterMenuItem} from 'utils/menu';
import { Row, Col, Button, Divider, message, notification, Icon, Modal, Select, Input } from 'antd';
import './view.less';
import {payType, offReason, POWER_GoodsList} from '../../constant';
import service from '../../service/goodsSrcService';
import moment from 'moment';
import BaseComponent, {EVENTKEY_POWER} from '../../components/base/BaseComponent';

const Option = Select.Option;
const TextArea = Input.TextArea;
const POWER = POWER_GoodsList;

export default class ViewGoodsSrc extends BaseComponent {
    constructor(props){
        super(props);
        this.menuId = "2";
        this.state = {
            buttonList: [],
            scanList: [],
            tabList: [],
            lastUnloadReason: offReason[0].name,
            remark: '',
            dep: "",
            joinInfo: {},
            parentDepartmentInfo: "",
            data: {},
            showOffModal: false,
            getFamiliarInfo: []
        };
    }
    componentDidMount() {
        super.componentDidMount();

        const id = this.props.id || "";
        this.id = id;
        service.view(id, {
            ok: data => {
                const dep = data.curDepartmentInfo || "";
                const joinInfo = data.joinInfo || {};
                const parentDepartmentInfo = data.parentDepartmentInfo || "";
                const d = data.transportInfo || {};
                this.setState({dep, joinInfo, parentDepartmentInfo, data: d});
            },
            failed: e => {
                let message = '加载详情失败';
                let description = '加载详情接口加载失败，请稍后再试！';
                notification.open({
                    message,
                    description,
                    icon: <Icon type="smile-circle" style={{ color: '#108ee9' }} />
                });
            },
            error: e => {
                let message = '加载详情失败';
                let description = '加载详情接口加载失败，请稍后再试！';
                notification.open({
                    message,
                    description,
                    icon: <Icon type="smile-circle" style={{ color: 'red' }} />
                });
            }
        });
        service.getFamiliarInfo(id,{
            ok: data => {
                this.setState({getFamiliarInfo:data.data||[]})
            },
            failed: e => {
                let message = '加载详情熟车失败';
                let description = '加载详情熟车接口加载失败，请稍后再试！';
                notification.open({
                    message,
                    description,
                    icon: <Icon type="smile-circle" style={{ color: '#108ee9' }} />
                });
            },
            error: e => {
                let message = '加载详情熟车失败';
                let description = '加载详情熟车接口加载失败，请稍后再试！';
                notification.open({
                    message,
                    description,
                    icon: <Icon type="smile-circle" style={{ color: 'red' }} />
                });
            }
        })
    }

    receivePower(power){
        //或者在这个生命周期函数中也可以获得权限
        this.setState({
            buttonList: power.buttonList,
            scanList: power.scanList,
            tabList: power.tabList
        });
    }

    render() {
        const {dep, joinInfo, parentDepartmentInfo, data} = this.state;
        let cell = [];
        if(data.length) cell.push('长' + data.length + '米');
        if(data.wide) cell.push('宽' + data.wide + '米');
        if(data.high) cell.push('高' + data.high + '米');
        let tzsc = '特运通代发'
        if(this.state.data.notifyPattern!=2){
            tzsc = this.state.getFamiliarInfo.map(v=>`${v.platUserName}(${v.platCellPhone})；`)
        }
        return (
            <div className='container_gsView'>
                <div className="content">
                    <Row className="row">
                        <Col span={2} className="colTitle colTitleBold">
                            <span className="icon icon-start">出发地：</span>
                        </Col>
                        <Col span={22} className="colText colTextBold">{data.startPoint}</Col>
                    </Row>
                    <Row className="row">
                        <Col span={2} className="colTitle"></Col>
                        <Col span={22} className="colText">{data.startDetailAdd}</Col>
                    </Row>
                    <Row className="row">
                        <Col span={2} className="colTitle colTitleBold">
                            <span className="icon icon-end">目的地：</span>
                        </Col>
                        <Col span={22} className="colText colTextBold">{data.destPoint}</Col>
                    </Row>
                    <Row className="row">
                        <Col span={2} className="colTitle"></Col>
                        <Col span={22} className="colText">{data.destDetailAdd}</Col>
                    </Row>
                    <Divider />
                    <Row className="row">
                        <Col span={2} className="colTitle">货物：</Col>
                        <Col span={10} className="colText colTextBold">{data.taskContent}</Col>
                        <Col span={2} className="colTitle">重量：</Col>
                        <Col span={10} className="colText colTextBold">{data.weight}</Col>
                    </Row>
                    <Row className="row">
                        <Col span={2} className="colTitle">运价：</Col>
                        <Col span={10} className="colText colTextBold">{data.price}元</Col>
                        <Col span={2} className="colTitle">尺寸：</Col>
                        <Col span={10} className="colText colTextBold">
                            {cell.join('*')}
                        </Col>
                    </Row>
                    <Divider />
                    <Row className="row">
                        <Col span={2} className="colTitle">装车时间：</Col>
                        <Col span={10} className="colText">{data.loadingTime ? moment(data.loadingTime).format('YYYY-MM-DD') : ''}</Col>
                        <Col span={2} className="colTitle">卸车时间：</Col>
                        <Col span={10} className="colText">{data.unloadTime ? moment(data.unloadTime).format('YYYY-MM-DD') : ''}</Col>
                    </Row>
                    <Row className="row">
                        <Col span={2} className="colTitle">车辆类型：</Col>
                        <Col span={10} className="colText">{(data.carTypeLength || '') + (data.carSpecialRequire || '')}</Col>
                        <Col span={2} className="colTitle">装卸方式：</Col>
                        <Col span={10} className="colText">{data.handingMode}</Col>
                    </Row>
                    <Divider />
                    <Row className="row">
                        <Col span={2} className="colTitle">装车人姓名：</Col>
                        <Col span={10} className="colText">{data.loadUserName}</Col>
                        <Col span={2} className="colTitle">电话：</Col>
                        <Col span={10} className="colText">{data.loadUserPhone}</Col>
                    </Row>
                    <Row className="row">
                        <Col span={2} className="colTitle">卸车人姓名：</Col>
                        <Col span={10} className="colText">{data.unloadUserName}</Col>
                        <Col span={2} className="colTitle">电话：</Col>
                        <Col span={10} className="colText">{data.unloadUserPhone}</Col>
                    </Row>
                    <Divider />
                    {this.renderPayType()}
                    <Row className="row">
                        <Col span={2} className="colTitle">备注：</Col>
                        <Col span={22} className="colText">{data.remark}</Col>
                    </Row>
                    <Divider />
                    <Row className="row">
                        <Col span={2} className="colTitle">发货部门：</Col>
                        <Col span={22} className="colText">{parentDepartmentInfo ? (parentDepartmentInfo + " - " + dep) : dep}</Col>
                    </Row>
                    <Row className="row">
                        <Col span={2} className="colTitle">发货人：</Col>
                        <Col span={10} className="colText">{data.corpUserName}</Col>
                        <Col span={2} className="colTitle">联系电话：</Col>
                        <Col span={10} className="colText">{data.tel}</Col>
                    </Row>
                    <Row className="row">
                        <Col span={2} className="colTitle">通知熟车：</Col>
                        <Col span={22} className="colText">{tzsc}</Col>
                    </Row>
                    {this.renderButton()}
                </div>

                <Modal
                    width={600}
                    maskClosable={false}
                    closable={true}
                    visible={this.state.showOffModal}
                    onCancel={()=>{this.setState({showOffModal: false})}}
                    title=""
                    footer=""
                >
                    <div className="modalBox">
                        <h3 className="modal-title-off">下架货源</h3>
                        <div className="modal-title-label firstTitle">确定要将该货源下架么？</div>
                        <div className="modal-label-off firstTitle">货源下架后如果装车未完成，您可以重新上架货源</div>
                        <div className="modal-title-label grayTitle">货源下架原因：</div>
                        <div>
                            <Select
                                style={{width: '100%'}}
                                value={this.state.lastUnloadReason}
                                size="large"
                                onSelect={e => this.setState({lastUnloadReason: e})}
                            >
                                {offReason.map(c=><Option key={c.id} value={c.name}>{c.name}</Option>)}
                            </Select>
                        </div>
                        <div className="modal-title-label grayTitle">备&nbsp;&nbsp;&nbsp;&nbsp;注：</div>
                        <div>
                            <TextArea
                                rows={4}
                                value={this.state.remark}
                                maxLength="200"
                                onChange={e => this.setState({remark: e.target.value})}
                            />
                        </div>
                        <div className="btnGroup">
                            <Row>
                                <Col span={12}>
                                    <Button
                                        style={{width:'98%'}}
                                        size="large"
                                        className="defaultButton"
                                        onClick={()=>{this.setState({showOffModal: false})}}
                                    >取消</Button>
                                </Col>
                                <Col span={12} style={{textAlign: 'right'}}>
                                    <Button
                                        type="primary"
                                        style={{width:'98%'}}
                                        size="large"
                                        className="okButton"
                                        onClick={this.offGoods}
                                    >下架</Button>
                                </Col>
                            </Row>
                        </div>
                    </div>
                </Modal>
            </div>
        )
    }

    renderPayType = () => {
        const {data} = this.state;
        if(data.settlementType == 1){
            return (
                <Row className="row">
                    <Col span={2} className="colTitle">结算方式：</Col>
                    <Col span={10} className="colText">现金</Col>
                    <Col span={2} className="colTitle">现金：</Col>
                    <Col span={10} className="colText">{data.cashPrice}</Col>
                </Row>
            );
        }
        else if(data.settlementType == 2){
            return (
                <Row className="row">
                    <Col span={2} className="colTitle">结算方式：</Col>
                    <Col span={10} className="colText">油卡</Col>
                    <Col span={2} className="colTitle">油卡：</Col>
                    <Col span={10} className="colText">{data.oilPrice}</Col>
                </Row>
            );
        }
        else{
            return (
                <div>
                    <Row className="row">
                        <Col span={2} className="colTitle">结算方式：</Col>
                        <Col span={10} className="colText">现金+油卡</Col>
                    </Row>
                    <Row className="row">
                        <Col span={2} className="colTitle">现金：</Col>
                        <Col span={10} className="colText">{data.cashPrice}</Col>
                        <Col span={2} className="colTitle">油卡：</Col>
                        <Col span={10} className="colText">{data.oilPrice}</Col>
                    </Row>
                </div>
            );
        }

    };

    renderButton = () => {
        const {data} = this.state;
        if (data.status == 1) {
            return (
                <Row className="row" style={{textAlign: 'right', marginTop: 10}}>
                    <Col span={24}>
                        {this.reanderComponent(POWER.buttonList_off, this.state.buttonList,
                            <Button
                                type="primary"
                                size="large"
                                style={{width: 200}}
                                onClick={() => this.setState({showOffModal: true})}
                            >
                                下架
                            </Button>
                        )}
                    </Col>
                </Row>
            );
        } else {
            return (
                <Row className="row" style={{textAlign: 'right', marginTop: 10}}>
                    <Col span={24}>
                        {this.reanderComponent(POWER.buttonList_up, this.state.buttonList,
                            <Button size="large" style={{width: 200, marginRight: 20}} onClick={this.rePublish}>上架</Button>
                        )}
                        {this.reanderComponent(POWER.buttonList_publish, this.state.buttonList,
                            <Button
                                type="primary"
                                size="large"
                                style={{width: 200}}
                                onClick={() => {window.location.hash = `goodsSrcNew/${this.id}`}}
                            >
                                发布相似货源
                            </Button>
                        )}
                    </Col>
                </Row>
            )
        }
    };

    handlePayType = (typeid) => {
        if(!typeid) return '';
        const filter = payType.filter( c => c.id == typeid);
        if(filter && filter.length == 1){
            return filter[0].name;
        }else{
            return '结算类型数据错误';
        }
    };

    /**
     * 下架货源
     */
    offGoods = () => {
        const id = this.id;
        const lastUnloadReason = this.state.lastUnloadReason || '';
        const remark = this.state.remark || '';

        service.cancel({id, lastUnloadReason, remark}, {
            ok: data => {
                message.success('下架成功');
                this.setState({showOffModal: false, lastUnloadReason: offReason[0].name, remark: ''});
            },
            failed: e => {
                let message = '下架加载失败';
                let description = '下架接口加载失败，请稍后再试！';
                notification.open({
                    message,
                    description,
                    icon: <Icon type="smile-circle" style={{ color: '#108ee9' }} />
                });
            },
            error: e => {
                let message = '下架加载失败';
                let description = '下架接口加载失败，请稍后再试！';
                notification.open({
                    message,
                    description,
                    icon: <Icon type="smile-circle" style={{ color: 'red' }} />
                });
            }
        });
    };

    rePublish = () => {
        service.rePublish(this.id, {
            ok: () => {
                message.success('重新上架成功');
            },
            failed: e=> {
                let message = '重新上架失败';
                let description = '重新上架接口加载失败，请稍后再试！';
                notification.open({
                    message,
                    description,
                    icon: <Icon type="smile-circle" style={{ color: '#108ee9' }} />
                });
            },
            error: e => {
                let message = '重新上架失败';
                let description = '重新上架接口加载失败，请稍后再试！';
                notification.open({
                    message,
                    description,
                    icon: <Icon type="smile-circle" style={{ color: 'red' }} />
                });
            }
        });
    };
}
