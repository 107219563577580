/**
 * Created by liuyc14 on 2018/8/24.
 */
import { Spin, AutoComplete  } from 'antd';
import React from 'react';
import service from '../../service/goodsSrcService';
import propTypes from 'prop-types';

//const Option = Select.Option;
const Option = AutoComplete.Option;
let f;
const reg2 = /(^\s*)|(\s*$)/gi; //去除首末位空格

export default class GoodsSearch extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            searching: false,
            data: props.defaultData || [],
            value: props.value || ""
        };

        this.autoComplete = {};
    }

    componentWillReceiveProps(nextProps) {
        if(nextProps.value){
            this.setState({value: nextProps.value});
        }
    }

    render() {
        const { data, value, searching } = this.state;
        return (
            <AutoComplete
                ref={ e => this.autoComplete = e}
                {...this.props}
                value={value}
                getPopupContainer={() => document.getElementById(this.props.cid)}
                placeholder={this.props.placeholder || ''}
                filterOption={false}
                onSelect={this.handleSelect}
                onChange={this.handleChange}
                onSearch={this.handleSearch}
                onBlur={this.handleBlur}
                style={{ width: '100%' }}
            >
                {data.map((d, index) => <Option key={d.id} value={d.displayContent} item={d}>{d.displayContent}</Option>)}
            </AutoComplete>
        );
    }

    /**
     * 获取从服务器匹配的数据
     * @param value
     */
    fetchData = (value) => {
        service.matches(value, {
            ok: data => {
                this.setState({data, searching: false});
            },
            failed: e => {
                this.setState({data: [], searching: false});
            },
            error: e => {
                this.setState({data: [], searching: false});
            }
        });
    };

    handleChange = (value) => {
        const {onChange} = this.props;
        //const _v = value.replace(reg2, '')
        const _v = value;
        this.setState({
            searching: false,
            value: _v
        });
        if(onChange){
            onChange(_v);
        }
    };

    handleSearch = (value) => {
        const reg = /^\s+$/gi; //全是空格

        if(!value || reg.test(value)) return;

        const _value = value.replace(reg2, '');

        const delay = this.props.delay || 500;
        clearTimeout(f);
        f = setTimeout(() => {
            this.setState({data: [], searching: true});
            this.fetchData(_value);
        }, delay);
    };

    handleSelect = (value, option) => {
        const {onChangedData} = this.props;
        const item = option.props.item || {};

        if(onChangedData){
            onChangedData(item);
        }

        setTimeout(() => {
            this.autoComplete.blur();
            //this.handleBlur();
        }, 100);
    };

    handleBlur = () => {
        const {onCheckedData} = this.props;

        service.check(this.state.value, {
            ok: data => {
                onCheckedData(data);
                // if(data && data.standardStatus == 0){
                //     this.setState({value: data.displayContent});
                // }
            },
            failed: e => {
                console.warn('标准化校验接口错误', e);
            },
            error: e => {
                console.error('标准化校验接口错误');
                console.error(e);
            }
        });
    };
}

GoodsSearch.propTypes = {
    onChange: propTypes.func,
    onChangedData: propTypes.func,
    onCheckedData: propTypes.func.isRequired,//获取校验后的数据
    defaultData: propTypes.array
};
