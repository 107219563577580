import { handleActions } from 'redux-actions'

export const demoList = handleActions({
    'request demo list'(state, action) {
        return { ...state, loading: true }
    },
    'receive demo list'(state, action) {
        const { res } = action.payload
        return { data: res, loading: false }
    }
}, {})
