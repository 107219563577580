import React from 'react'
//import { HashRouter } from 'react-router-dom'
import { Route, Redirect, Switch } from 'react-router'
import Container from 'container'
import Login from 'pages/login'
import createHistory from 'history/createBrowserHistory'
import Forgot from './pages/forget/forget';
import FamiliarCarHistory from './pages/familiarCar/history'
import BooksDetail from 'pages/booksDetail'
import GoodsView from 'pages/goodsSrc/view';

const history = createHistory()
const location = history.location

const routes = (
  <Switch>
      <Route path="/forget" component={Forgot} />
      <Route path="/login" component={Login} />
      <Route exact path="/familiarCar/history/:name/:phone" component={FamiliarCarHistory} />
      <Route exact path="/goodsSrcView/:id/:status" render={(match) => <GoodsView id={match.match.params.id} />} />
      <Route exact path="/books/exact/:tabActive/:id" render={(match) => <BooksDetail bookActiveTab={match.match.params.tabActive} bookDetailId={match.match.params.id} index="books" />} />
      <Route path="/" component={Container} />
  </Switch>
)

export default routes
