import React from 'react'
import moment from 'moment'
import {filterMenuItem} from 'utils/menu'
import Crumbs from 'components/crumbs'
import { ajax } from 'utils'
import  './books.less'
import { Pagination,Badge } from 'antd'
import createHistory from 'history/createHashHistory'
const history = createHistory()
import emitter from 'utils/events'
import ExPop from 'components/exPop'
import Confirm from 'components/confirm'
import tips from 'components/tips'

import {kindsStatusObj,allBtnsMap,getTabsItem,getBtnCnByEn,data2uiMap} from './booksConfig'
export default class Books extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            activeTab: '',
            tabArr: [],
            btnList:[],
            theadList: [],
            tbodyList: [],
            pages:{
                currentPage:1,
                total:0,
                limit:10
            },
            popExOprateShow: false,
            oprateItem:null,
            exTypeObj: {'1':{}, '2':{}},
            confirm: {
                title: '提 示',
                closable:true,
                btnOkText: '',
                visible: false,
                content: '',
                btnEnName: ''
            }
        }
    }
    componentDidMount() {
        const {index,regTab,bookActiveTab} = this.props
        let makePowers = menuId=> {
            ajax.fetchJson(`getPowers/${menuId}`).then(data => {
                if (data && data.code == 200) {
                    let tabArr = []
                    data.data.tabList.forEach(v=>{
                        if(v.indexOf('订单')>-1) v = v.replace('订单','运单')
                        tabArr.push({key:kindsStatusObj[v].key,name:v})
                    })
                    let btnList = data.data.buttonList
                    this.setState({tabArr, btnList}, () => { //获取tab列表后选择active项执行
                        if (bookActiveTab && tabArr.some(v=>v.key===bookActiveTab)) this.tabClick(bookActiveTab)
                        else tabArr.length > 0 && this.tabClick(tabArr[0].key)
                    })
                } else tips('error', data && data.msg || '查询操作项失败！')
            })
        }
        let menuId = regTab(filterMenuItem(index))
        if(menuId){
            makePowers(menuId)
        }else{
            this.eventEmitter = emitter.addListener('meunDataYet', (menuId) => {
                makePowers(menuId)
            })
        }

        //异常类型请求
        ajax.fetchJson(`getExTypes`,{exParty:1}).then(data => {
            if (data && data.code == 200) {
                if(data.data && data.data.length){
                    let obj = {}
                    data.data.forEach(v=>obj[v.value]=v.name)
                    let  exTypeObj  = {...this.state.exTypeObj, '2':obj} //此与下一个请求中的相同部分'1'、'2'对调，已便在使用this.state.exTypeObj时，能车方上报查货方原因，货方上报查车方原因
                    this.setState({exTypeObj})
                }
            }
        })
        ajax.fetchJson(`getExTypes`,{exParty:2}).then(data => {
            if (data && data.code == 200) {
                if(data.data && data.data.length){
                    let obj = {}
                    data.data.forEach(v=>obj[v.value]=v.name)
                    let  exTypeObj  = {...this.state.exTypeObj, '1':obj}
                    this.setState({exTypeObj})
                }
            }
        })
    }
    refreshPage(){
        this.fetchList()
    }
    componentWillUnmount() {
        typeof this.eventEmitter==='function' && emitter.removeListener(this.eventEmitter)
        this.setState = (state,callback)=>{
            return
        }
    }
    tabClick (tabKey){
        let { limit } = this.state.pages
        let currentPage = 1
        let total = 0
        if(tabKey===this.state.activeTab) return
        this.setState({activeTab:tabKey, pages:{currentPage,total, limit}, theadList: getTabsItem(tabKey).tableList, tbodyList:[]},()=> this.fetchList() )
        if(history.location.pathname===`/books/${tabKey}`) return
        history.push(`/books/${tabKey}`)
    }
    serverDataItemType2local(robStatus,ifbackCn){
        let en = (robStatus=='0'||robStatus=='4'||robStatus=='5'||robStatus=='6'||robStatus=='13'||robStatus=='14'||robStatus=='7'||robStatus=='15') ?  data2uiMap[robStatus] : ''
        if(ifbackCn) return  getTabsItem(en,true)
        return en
    }
    getBtns (item){
        let { btnList, activeTab } = this.state
        let getKindsOf = itemKey=>{
            if(!itemKey) return ''
            let btns = getTabsItem(itemKey).btns[item.corpOrderType==2?'normal':'agent']  // corpOrderType：2 //企业订单类型，1-特运通代理订单,2-企业自发订单
            let newArr = []
            btns.forEach((v,i)=>{//权限筛选
                for(let ii=0,ll=btnList.length;ii<ll;ii++){
                    if(btnList.indexOf(v)>-1){
                        newArr.push(v)
                        break
                    }
                }
            })
            let ind = newArr.indexOf('卸货完成') //如果ind>-1则说明有此按钮权限且当前在装货完成tab
            if(ind>-1 && (item.robStatus!=13)){//如果robStatus==5|6  或者说robStatus!=13，则删此按钮   //5车主装货完成  6系统装货完成  13车方卸货完成-待货方确认
                newArr.splice(ind,1)
            }
            ind = newArr.indexOf('添加熟车') //如果ind>-1则说明有此按钮权限且当前在卸货完成tab
            if(ind>-1 && (item.corpOrderType!=2)){//如果corpOrderType==2 是企业自发订单，则删此按钮
                newArr.splice(ind,1)
            }
            return newArr.map((vv,ii)=>{
                let k = allBtnsMap[vv]
                return ii===0?<a key={ii} href="javascript:;" className={k} onClick={()=>this.btnClick(item,k)}>{vv}</a>:(
                    <React.Fragment key={ii}>
                        <span className="sp"></span>
                        <a href="javascript:;" className={k} onClick={()=>this.btnClick(item,k)}>{vv}</a>
                    </React.Fragment>
                )
            })
        }
        if(activeTab==='all'){
            let activeTabNew = this.serverDataItemType2local(item.robStatus)
            return getKindsOf(activeTabNew)
        }
        return getKindsOf(activeTab)
    }
    btnClick(item,k){
        if(k==='btnDetail'){
            let id = item.id
            if(this.state.activeTab=='ex') id = item.orderId;
            history.push(`/books/${this.state.activeTab}/${id}`) //location.replace(`#/books/${this.state.activeTab}/${item.tsOrderNo}`)
            // let goto = window.location.href.split('/#/')[0] + '/#/books/'+this.state.activeTab+'/'+id
            // window.open(goto, '_blank', 'height=600,width=1000, toolbar=no, Resizable=no,location=no, status=no')
        }
        if(k==='btnOperate'){
            let id = item.id
            if(this.state.activeTab=='ex') id = item.orderId;
            history.push(`/books/${this.state.activeTab}/${id}`) //location.replace(`#/books/${this.state.activeTab}/${item.tsOrderNo}`)
        }
        else this.setState({oprateItem:item},()=>{
            switch (k){
                case 'btnCatch': //异常上报
                    this.setState({popExOprateShow: true})
                    break
                case 'btnGoodsDumped': //卸货完成
                    let newOpt = {
                        title: '提示',
                        closable:false,
                        btnOkText : getBtnCnByEn(k),
                        content : '车方已到达卸货地点并完成卸货,请确认',
                        visible : true,
                        btnEnName : k,
                    }
                    this.setState({confirm:newOpt})
                    break
                case 'btnShowCredentials': //查看三证
                    history.push(`/books/${this.state.activeTab}/${item.id}/threeCard`)
                    break
                case 'btnShowReceipt': //查看回执
                    history.push(`/books/${this.state.activeTab}/${item.id}/showReceipt`)
                    break
                case 'btnAddCar': //添加熟车
                    let url = '/familiarCar' + ( item.payLinkPhone ? `/${item.payLinkPhone}` : '' )
                    history.push(url)
                    //this.props.regTab(filterMenuItem('familiarCar'))
                    break
            }
        })
    }
    fetchList(){
        let tab = this.state.activeTab
        let interfac = 'getBooksList'
        if(tab==='ex') interfac = 'getBookCatchList'
        let postData = {queryMenuType: getTabsItem(tab).typeNum, offset: (this.state.pages.currentPage-1)*this.state.pages.limit, limit: this.state.pages.limit}
        if(postData.queryMenuType===0||postData.queryMenuType===-1) delete postData.queryMenuType //全部、异常 时，不传queryMenuType
        ajax.fetchJson(interfac,'get',postData).then(data=>{
            if(data && data.code==200){
                let tableData = []
                if(data.data && data.data.data && data.data.data.length){
                    data.data.data.forEach((item,index)=>{
                        let tr = getTabsItem(tab).tableList.map((v,i)=>{
                            let dataKey = Object.values(v)[0]
                            let midJoinStrStart = dataKey.indexOf('${')
                            let midJoinStrEnd = dataKey.indexOf('}')
                            if(midJoinStrStart>-1 && midJoinStrEnd>=3){
                                if(dataKey.indexOf('${*}')>-1){
                                    return this.getBtns(item)
                                }
                                let timeIndex = dataKey.indexOf('${time:')
                                if(timeIndex>-1){
                                    let key = dataKey.slice(timeIndex+7,dataKey.length-1)
                                    return (key in item) && !isNaN(item[key]) ? moment(item[key]).format('YYYY-MM-DD HH:mm') : ''
                                }
                                if(dataKey.indexOf('${o|')>-1){ //${o|1:特运通代理,2:熟车:corpOrderType}
                                    let s = dataKey.lastIndexOf(':')
                                    let ostr = dataKey.slice(4,s)
                                    let key = dataKey.slice(s+1,dataKey.length-1)
                                    let oarr = ostr.split(',')
                                    let o = {}
                                    oarr.forEach(v=>{let sarr = v.split(':'); o[sarr[0]] = sarr[1]})
                                    let keykey = item[key] || ''
                                    return o[keykey] || ''
                                }
                                if(dataKey.indexOf('${fn1:')>-1){//${fn1:robStatus}
                                    let data = item[dataKey.split(':')[1].replace('}','')] || ''
                                    return (<span className={this.serverDataItemType2local(data)}>{this.serverDataItemType2local(data,true)}</span>)
                                }
                                if(dataKey.indexOf('${fn2:')>-1){//${fn1:robStatus}
                                    let data = item[dataKey.split(':')[1].replace('}','')] || ''
                                    return (<span className={{'0':'forAgree','1':'forAgree','2':'unloaded'}[data]||''}>{{'0':'异常待处理','1':'异常待处理','2':'处理完成'}[data]||''}</span>)
                                }
                                let arr = dataKey.split(/\$\{.+\}/)
                                let midJoinStr = dataKey.slice(midJoinStrStart+2,midJoinStrEnd+1-1)
                                return arr.map(v=>(v in item) ? item[v] : '').join(midJoinStr)
                            }
                            if(Object.keys(v)[0]==='异常类型'){
                                let arr = dataKey.split('-')
                                arr.length===1 && arr.push(-1)
                                return `exType:${item[arr[0]]},${item[arr[1]]}`
                            }
                            return (dataKey in item) ?  item[dataKey] : ''
                        })
                        if(item.corpOrderType==1) tr.instead=true
                        tableData.push(tr)
                    })
                }
                let {currentPage, limit } = this.state.pages
                let total = data.count || 0
                this.setState({tbodyList: tableData, pages:{currentPage, total, limit}})
            }else tips('error',data && data.msg || '列表查询失败!')
        }).catch(err=>tips('error','列表查询失败!'))
    }
    onPageChange(page, pageSizes){
        let { limit,total } = this.state.pages
        let currentPage = page
        this.setState({pages: {currentPage,total, limit}}, ()=>this.fetchList())
    }
    onExPopOk(exType,exOther,ifChoosedOtherItem,chooseTxt){
        /*
         exParty:1, //异常上报方 1.车方 2.货方
        exType:2, //异常上报类型
        exOther:"不给报", //异常上报其他原因
        orderId:1723 //订单id
        */
        let orderId = this.state.oprateItem.id
        let exParty = 2
        if(ifChoosedOtherItem){
            if(!chooseTxt){
                tips('info','请填写其他原因内容！')
                return
            }
        }
        ajax.fetchJson('sbtCatch','post',{exParty,exType,exOther,orderId}).then(data=>{
            if(data && data.code ==200){
                tips('success',data.msg || '提交成功！')
                this.setState({popExOprateShow: false},()=>this.fetchList())
            }else tips('error',data && data.msg || '提交失败!')
        }).catch(err=>tips('error','提交失败！'))
    }
    onExPopCancel(){
        this.setState({popExOprateShow: false})
    }
    confirmOk(e){
        //console.log(this.state.oprateItem.tsOrderNo,this.state.confirm.btnEnName)
        //console.log(this.state.oprateItem)
        let {tsOrderNo,id} = this.state.oprateItem
        if(this.state.confirm.btnEnName==='btnGoodsDumped'){ //点击的是卸货完成
            ajax.fetchJson(`unloadGoods/${id}`,'post').then(data=>{
                if(data && data.code==200){
                    tips('success','卸货完成操作成功！')
                    this.setState({confirm:{title:'',btnOkText:'',content:'',visible:false,btnEnName:''}},()=>this.fetchList())
                }else tips('error',data && data.msg || '提交失败!')
            }).catch(err=>tips('error','提交失败！'))
        }
    }
    confirmCancel(e){
        this.setState({confirm:{title:'',btnOkText:'',content:'',visible:false,btnEnName:''}})
    }
    btnGoPage(){
        let ipt = document.querySelector('.paginationBox li input')
        if(ipt){
            let v = ipt.value
            if(v && !isNaN(v)){
                v = +v
                if(this.state.pages.total){
                    if(v > this.state.pages.total){
                        v = this.state.pages.total
                    }
                    this.onPageChange(v)
                }
            }
        }
    }
    render() {
        let {exceptionNum,inLoadingNum,loadOverNum,waitAgreeNum} = this.props.forIndex
        //违约异常数量  装货中数量  装货完成数量  待同意数量
        //console.log(exceptionNum,inLoadingNum,loadOverNum,waitAgreeNum)
        let tableListHtml = () =>{
            if(this.state.tbodyList.length) return this.state.tbodyList.map((v,i)=>{
                return (
                    <tr key={i} className={v.instead?'instead':''}>
                        {v.map((vv,ii)=>{
                            if(typeof vv==='string' && vv.indexOf('exType:')>-1){
                                let m = vv.replace('exType:','')
                                let marr = m.split(',')
                                let gr = this.state.exTypeObj[marr[1]] || {}
                                let str = gr[marr[0]] || '其他'
                                return <td key={ii}><div>{str}</div></td>
                            }
                            return <td key={ii}><div>{vv}</div></td>
                        })}
                    </tr>
                )
            })
            return <tr><td colSpan={this.state.theadList.length||1} className="tdNodata"><div>暂无运单</div></td></tr>
        }

        return (
            <div>
                <Crumbs str="运单" />
                <div>
                    <div className="bktabs">
                        <ul className="ful">
                            {
                                this.state.tabArr.map((item,index)=> {
                                    let key2dataObj={forAgree:waitAgreeNum,loading:inLoadingNum,loaded:loadOverNum,ex:exceptionNum,all:exceptionNum+inLoadingNum+loadOverNum+waitAgreeNum}
                                    if(item.key in key2dataObj) return (

                                        <li key={index} className={this.state.activeTab===item.key?'active':''} onClick={()=>this.tabClick(item.key)}>{item.name}<Badge count={key2dataObj[item.key]||0}></Badge></li>

                                    )
                                    return (
                                        <li key={index} className={this.state.activeTab===item.key?'active':''} onClick={()=>this.tabClick(item.key)}>{item.name}</li>
                                    )
                                })
                            }
                        </ul>
                    </div>
                    <div className="bklists">
                        <table width="100%" cellPadding="0" cellSpacing="0" border="0">
                            <thead>
                            <tr>
                                {this.state.theadList.map((v,i)=><th key={i}><div>{Object.keys(v)[0]}</div></th>)}
                            </tr>
                            </thead>
                            <tbody>{tableListHtml()}</tbody>
                        </table>
                        <div className="paginationBox">
                            <Pagination
                                className="paginationLage cf"
                                pageSize={this.state.pages.limit}
                                current={this.state.pages.currentPage}
                                total={this.state.pages.total}
                                onChange={this.onPageChange.bind(this)}
                                showTotal={(total)=> `共${Math.ceil(total/this.state.pages.limit)}页，`}
                                showQuickJumper
                                hideOnSinglePage={true}
                            />
                            {/*<span style={{cursor:'pointer',color:'#000',fontSize:'14px',marginLeft:'10px',border: '1px solid rgba(0, 0, 0, 0.3)',borderRadius: '3px',padding: '6px 8px'}}
                                  onClick={()=>this.btnGoPage()}>确定</span>*/}
                        </div>
                    </div>
                </div>

                <ExPop visible={this.state.popExOprateShow} onOk={this.onExPopOk.bind(this)} onCancel={this.onExPopCancel.bind(this)} />

                <Confirm centered={true}
                        title={this.state.confirm.title}
                        closable={true}
                        visible={this.state.confirm.visible}
                        onOk={this.confirmOk.bind(this)}
                        onCancel={this.confirmCancel.bind(this)}
                        content={this.state.confirm.content}
                        okText={this.state.confirm.btnOkText}/>

            </div>
        )
    }
}
