/**
 * Created by liuyc14 on 2017/11/3.
 */
import React, {Component} from 'react';
import './forget.less';
import {Link} from 'react-router-dom';
import forge from 'node-forge';
import VCode from '../../components/vcode';
import {regexPhone, regPwd} from '../../constant';
import {Button, message, Input, Form, Row, Col} from 'antd';

import service from '../../service/loginService';

const FormItem = Form.Item;

const formItemLayout = {
    labelCol: {
        xs: { span: 24 },
        sm: { span: 6 }
    },
    wrapperCol: {
        xs: { span: 24 },
        sm: { span: 14 }
    }
};

let VCODE;

class Step1 extends Component{
    constructor(props){
        super(props);
    }

    render(){
        const {getFieldDecorator} = this.props.form;
        return (
            <Form
                className=""
                onSubmit={this.handleSubmit}
            >
                <FormItem
                    {...formItemLayout}
                    label="手机号"
                >
                    {getFieldDecorator('phone', {
                        validateTrigger: 'onBlur',
                        rules: [
                            {required: true, message: '请输入手机号!'},
                            {pattern: regexPhone, message: '请输入正确的手机号'}
                        ],
                        initialValue: this.props.phone
                    })(
                        <Input
                            autoComplete="off"
                            placeholder="请输入手机号"
                            maxLength="11"
                            size="large"
                        />
                    )}
                </FormItem>
                <Row style={{paddingTop: 130}}>
                    <Col span={6}></Col>
                    <Col span={14}>
                        <Button
                            type="primary"
                            style={{fontSize:16, width: '100%'}}
                            size="large"
                            htmlType="submit"
                            className="btnSave"
                            loading={this.props.loading}
                        >
                            下一步
                        </Button>
                    </Col>
                </Row>
            </Form>
        );
    }

    handleSubmit = (e) => {
        e && e.preventDefault();
        const {validateFields} = this.props.form;
        validateFields((err, values) => {
            if(!err){
                if(this.props.ok){
                    this.props.ok(values);
                }
            }
        });
    };
}

class Step2 extends Component{
    constructor(props){
        super(props);
    }

    render(){
        const {getFieldDecorator} = this.props.form;
        return (
            <Form
                className=""
                onSubmit={this.handleSubmit}
            >
                <FormItem
                    {...formItemLayout}
                    label="短信验证码"
                >
                    {getFieldDecorator('vcode', {
                        validateTrigger: 'onBlur',
                        rules: [
                            {required: true, message: '请输入短信验证码!'},
                            {pattern: /^\d{4,6}$/, message: '验证码为4-6位数字'}
                        ]
                    })(
                        <VCode
                            defaultText="获取短信验证码"
                            reSendText="重新发送"
                            phone={this.props.phone}
                            ref={e => VCODE = e}
                        />
                    )}
                </FormItem>
                <FormItem
                    {...formItemLayout}
                    label="设置新密码"
                >
                    {getFieldDecorator('pwd', {
                        validateTrigger: 'onBlur',
                        rules: [
                            {required: true, message: '请输入新密码!'},
                            {pattern: regPwd, message: '请输入6-16位以内的新密码'}
                        ]
                    })(
                        <Input
                            autoComplete="off"
                            type="password"
                            size="large"
                            style={{fontSize: 14}}
                            placeholder="请输入6-16位以内的新密码"
                        />
                    )}
                </FormItem>
                <FormItem
                    {...formItemLayout}
                    label="确认新密码"
                >
                    {getFieldDecorator('confirm', {
                        validateTrigger: 'onBlur',
                        rules: [
                            {required: true, message: '请再次输入新密码!'},
                            {validator: this.checkConfirm}
                        ]
                    })(
                        <Input
                            autoComplete="off"
                            type="password"
                            size="large"
                            style={{fontSize: 14}}
                            placeholder="再次输入新密码"
                        />
                    )}
                </FormItem>
                <Row style={{paddingTop: 20}}>
                    <Col span={6}></Col>
                    <Col span={14}>
                        <Button
                            onClick={() => this.props.back()}
                            style={{width:'48%'}}
                            className="btnSave btnPre"
                            size="large"
                        >
                            上一步
                        </Button>
                        <Button
                            type="primary"
                            style={{width:'48%', float: 'right'}}
                            size="large"
                            htmlType="submit"
                            className="btnSave"
                            loading={this.props.loading}
                        >
                            确定
                        </Button>
                    </Col>
                </Row>
            </Form>
        );
    }

    handleSubmit = (e) => {
        e && e.preventDefault();
        const {validateFields} = this.props.form;
        validateFields((err, values) => {
            if(!err){
                if(this.props.ok){
                    this.props.ok(values);
                }
            }
        });
    };

    checkConfirm = (rule, value, callback) => {
        const { getFieldValue } = this.props.form;
        if (value && value !== getFieldValue('pwd')) {
            callback('两次输入不一致！')
        }

        // Note: 必须总是返回一个 callback，否则 validateFieldsAndScroll 无法响应
        callback()
    };
}

const Step1Form = Form.create()(Step1);
const Step2Form = Form.create()(Step2);




class Forget extends Component{
    constructor(props){
        super(props);
        this.state = {
            step: 1,
            phone: '',
            getting: false,
            submiting: false
        };

        this.getcode = "";

        this.formData = {
            phone: "",
            pwd: "",
            repwd: "",
            code: ""
        };
    }

    componentDidMount(){
        const {location} = this.props;
        if(location && location.state){
            const phone = location.state.phone;
            this.setState({phone});
        }
    }

    render(){
        let left = 0;
        const step = this.state.step;
        left = 0 - (step - 1) * 600;

        let step2ClassName = "";
        let step2SpanClassName = "";
        let step2SpanText = "";
        let step3ClassName = "";

        if(step == 2){
            step2ClassName = "step step-active";
            step2SpanClassName = "number";
            step2SpanText = "2";

            step3ClassName = "step";
        }
        else{
            step2ClassName = "step";
            step2SpanClassName = "number";
            step2SpanText = "2";
            step3ClassName = "step";
        }

        return(
            <div id="box_forget" className="box">
                <div className="navbar">
                    <div className="box">
                        <div className="left">
                            特运通企业版 | 找回密码
                        </div>
                        <div className="right">
                            {/*企业入驻 |*/}
                            <Link to="/login" className="btnLogin">登录</Link>
                        </div>
                    </div>
                </div>
                <div className="navbg">
                    <div className="navigation">
                        <div className={'step step-active'}>
                            <span className={step == 1 ? 'number' : 'number nsfont nsicon-ok'}>1</span>
                            <span className="text">输入账号</span>
                        </div>
                        <div className={step > 1 ? 'step-line step-line-active' : 'step-line'}></div>

                        <div className={step2ClassName}>
                            <span className={step2SpanClassName}>{step2SpanText}</span>
                            <span className="text" style={{margin: '0 -12px'}}>验证并重置密码</span>
                        </div>
                    </div>
                </div>
                <div className="scroll_box">
                    <div className="scroll" style={{left: left}}>
                        <div className="content">
                            <div className="step1">
                                <div className="stepTitle" style={{paddingLeft: 68}}>请输入登录手机号</div>
                                <Step1Form
                                    phone={this.state.phone}
                                    loading={this.state.getting}
                                    ok={this.handleGet}
                                />
                            </div>
                        </div>
                        <div className="content">
                            <div className="step2">
                                <div className="stepTitle" style={{paddingLeft: 40}}>验证码已发送至 <span style={{color: 'red'}}>{this.handlePhone()}</span> , 请查收并在此输入</div>
                                <Step2Form
                                    phone = {this.state.phone}
                                    loading={this.state.submiting}
                                    ok={this.handleSubmit}
                                    back={() => this.setState({step: 1})}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    handleGet = (values) => {
        this.setState({getting: true, phone: values.phone});
        service.validPhone(values.phone, {
            ok: () => {
                this.setState({step:2, getting: false});
                if(VCODE){
                    VCODE.djs(60);
                }
                return true;
            },
            failed: e => {
                message.warn('短信发送失败，' + e.msg);
                this.setState({getting: false});
            },
            error: () => {
                message.error('短信发送接口错误，请稍后再试！');
                this.setState({getting: false});
            }
        });
    };

    handlePhone = () => {
        const {phone} = this.state;
        return phone.substr(0, 3) + "****" + phone.substr(7, 4);
    };

    handleSubmit = (values) => {
        this.setState({submiting: true});
        let {vcode, pwd} = values;

        let md = forge.md.md5.create();
        md.update(pwd);
        const password = md.digest().toHex();
        service.forgetPwd({verifyCode: vcode, newPwd: password, cellphone: this.state.phone}, {
            ok: () => {
                this.setState({submiting: false});
                message.success('密码修改成功！');
                setTimeout(() => {
                    location.hash = '/login';
                }, 1000)
            },
            failed: e => {
                message.warn('修改密码失败，' + e.msg);
                this.setState({submiting: false});
            },
            error: () => {
                message.error('修改密码接口错误，请稍后再试！');
                this.setState({submiting: false});
            }
        });

    };
}

export default Form.create()(Forget);
