/**
 * Created by liuyc14 on 2018/8/22.
 */
import request from '../utils/request';
import apiDomain from '../utils/domain';
import {contentType_json} from '../constant';

const domain = apiDomain.baseDomain[apiDomain.env];

export default {
    /**
     * 登录
     * @param phone
     * @param pwd
     * @param ok
     * @param failed
     * @param error
     */
    login({phone, pwd}, {ok, failed, error}){
        let cellPhone = phone;
        let password = pwd;
        request(`${domain}/user/login`, {
            method: 'POST',
            headers: {
                'Content-Type': contentType_json
            },
            body: JSON.stringify({cellPhone, password})
        }).then(res => {
            const data = res.data || {};
            if(data.code == 200){
                ok && ok(data.data || {});
            }else{
                if(failed){
                    failed({code: data.code, msg: data.msg});
                }
            }
        }, e => {
            if(error){
                error(e);
            }
        });
    },
    /**
     * 更新密码
     * @param oldPwd
     * @param newPwd
     * @param ok
     * @param failed
     * @param error
     */
    updatePwd({oldPwd, newPwd}, {ok, failed, error}){
        request(`${domain}/user/changePwd`, {
            method: 'POST',
            headers: {
                'Content-Type': contentType_json
            },
            body: JSON.stringify({oldPwd, newPwd})
        })
            .then(res => {
                const data = res.data || {};
                if(data.code == 200){
                    ok && ok(data.data || {});
                }else{
                    if(failed){
                        failed({code: data.code, msg: data.msg});
                    }
                }
            }, e => {
                if(error){
                    error(e);
                }
            });
    },
    /**
     * 修改头像
     * @param file
     * @param ok
     * @param failed
     * @param error
     */
    changeAvatar(file, {ok, failed, error}){
        request(`${domain}/user/changeAvatar`, {
            method: 'POST',
            body: file
        })
            .then(res => {
                const data = res.data || {};
                if(data.code == 200){
                    ok && ok(data.data || {});
                }else{
                    if(failed){
                        failed({code: data.code, msg: data.msg});
                    }
                }
            }, e => {
                if(error){
                    error(e);
                }
            });
    },
    /**
     * 验证手机号是否存在
     * @param phone
     * @param ok
     * @param failed
     * @param error
     */
    verifyCellPhone(phone, {ok, failed, error}){
        request(`${domain}/user/verifyCellPhone`, {
            method: 'POST',
            headers: {
                'Content-type': contentType_json
            },
            body: JSON.stringify({cellPhone: phone})
        })
            .then(res => {
                const data = res.data || {};
                if(data.code == 200){
                    ok && ok(data.data || {});
                }else{
                    if(failed){
                        failed({code: data.code, msg: data.msg || ''});
                    }
                }
            }, e => {
                if(error){
                    error(e);
                }
            });
    },
    /**
     * 发送短信
     * @param phone
     * @param ok
     * @param failed
     * @param error
     */
    sendVerify(phone, {ok, failed, error}){
        request(`${domain}/user/sendVerify`, {
            method: 'POST',
            headers: {
                'Content-type': contentType_json
            },
            body: JSON.stringify({cellPhone: phone})
        })
            .then(res => {
                const data = res.data || {};
                if(data.code == 200){
                    ok && ok(data.data || {});
                }else{
                    if(failed){
                        failed({code: data.code, msg: data.msg || ''});
                    }
                }
            }, e => {
                if(error){
                    error(e);
                }
            });
    },
    /**
     * 验证手机号并且发送短信
     * @param phone
     * @param ok
     * @param failed
     * @param error
     */
    validPhone(phone, {ok, failed, error}){
        const self = this;
        self.verifyCellPhone(phone, {
            ok: () => {
                self.sendVerify(phone, {ok, failed, error});
            },
            failed: e => {
                if(failed){
                    failed({code: e.code, msg: e.msg});
                }
            },
            error: () => {
                if(error){
                    error();
                }
            }
        });
    },
    /**
     * 忘记密码确认接口
     * @param verifyCode
     * @param newPwd
     * @param cellphone
     * @param ok
     * @param failed
     * @param error
     */
    forgetPwd({verifyCode, newPwd, cellphone}, {ok, failed, error}){
        request(`${domain}/user/resetPwd`, {
            method: 'POST',
            headers: {
                'Content-type': contentType_json
            },
            body: JSON.stringify({verifyCode, newPwd, cellphone})
        })
            .then(res => {
                const data = res.data || {};
                if(data.code == 200){
                    ok && ok(data.data || {});
                }else{
                    if(failed){
                        failed({code: data.code, msg: data.msg || ''});
                    }
                }
            }, e => {
                if(error){
                    error(e);
                }
            });
    },
    /**
     * 交验是否首次登录
     * @param cellphone
     * @param ok
     * @param failed
     * @param error
     * */
    checkFirst({cellphone}, {ok, failed, error}){
        request(`${domain}/user/checkFirstLogin/${cellphone}`, {
            method: 'GET',
        })
            .then(res => {
                const data = res.data || {};
                if(data.code == 200){
                    ok && ok(data.data || {});
                }else{
                    if(failed){
                        failed({code: data.code, msg: data.msg || ''});
                    }
                }
            }, e => {
                if(error){
                    error(e);
                }
            });
    },
};