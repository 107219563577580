/**
 * Created by chaoqin on 18/8/28.
 */
import React from 'react'
import {message, Button, Input, Modal, Tabs, Form, Upload, Icon} from 'antd'
import { ajax } from 'utils'
import LoginInfo from '../../utils/loginInfo';
import { regexPhone, regexName, regexTel } from '../../constant';
import apiDomain from 'utils/domain';
import '../../container/reset_antd.less'

const domain = apiDomain.baseDomain[apiDomain.env]
const TabPane = Tabs.TabPane, FormItem = Form.Item, TextArea = Input.TextArea, Search = Input.Search

class AddFamiliarCar extends React.Component{
    constructor(props){
        super(props)

        this.state = {
            addModalVisible: false,
            addLoading: false
        }

        this.data = {
            addKey: 1,
            uploadStatus: false
        }
    }

    componentWillReceiveProps(nextProps){
        this.setState({
            addModalVisible: nextProps.visible
        })
    }

    clearAddInput = () => {
        const {setFieldsValue} = this.props.form

        setFieldsValue({
            "addName": "",
            "addPhone": "",
            "addRemark": ""
        });
    }

    //添加熟车
    addHandler = () =>{
        let { addKey } = this.data, that = this

        if(addKey == 1){

            this.props.form.validateFields(['addName', 'addPhone', 'addRemark'], (err, values) => {
                if (!err) {
                    this.setState({ addLoading: true })

                    let that = this,
                        body = {
                            "userName": values.addName,
                            "cellPhone": values.addPhone,
                            "remark": values.addRemark
                        }

                    ajax.fetchJson('addFamiliar', 'post', body).then((res) => {
                        this.setState({ addLoading: false })

                        if(res && res.code == 200){
                            message.success('添加成功！')
                            that.props.cancelCallBack()
                            that.setState({ addLoading: false }, function(){
                                that.props.callBack()

                                that.clearAddInput()
                            })
                        }else{
                            message.error(res && res.msg)
                            that.clearAddInput()
                        }
                    }, ()=>{
                        this.setState({ addLoading: false })
                        that.clearAddInput()
                    })
                }
            });
        }else if(addKey == 2){
            this.upload(function(){

                that.props.callBack()
                that.props.cancelCallBack()
                /*this.setState({ addLoading: false })
                that.props.cancelCallBack()
                */
            });

            /*
            if(this.data.uploadStatus){
                this.setState({ addLoading: false })
                that.props.cancelCallBack()
            }else{
                message.warning('请先选择要导入的文件')
            }
            */
        }
    }

    addHandleCancel = () => {
        this.props.cancelCallBack()
    }

    addTab = (key) => {
        this.data.addKey = key;
    }

    triggerUpload = () => {
        const input = document.getElementById('my-upload');

        if(!!window.ActiveXObject || 'ActiveXObject' in window){
            input.click();
        }else{
            const me = document.createEvent('MouseEvents');
            me.initEvent('click', false, true);
            input.dispatchEvent(me);
        }
    }

    change = (e) => {
        document.getElementById('changeInput').value = (/([^/\\]+)$/.exec(e.target.value))[1];
    }

    upload = function(callback) {
        const elm = document.getElementById('my-upload'), files = Array.prototype.slice.call(elm.files);
        var fileReader = new FileReader();

        if(!files.length){
            message.warning('请先选择要导入的文件')
            return;
        };

        const formData = new FormData();
        formData.append('familiarExcel', files[0])

        fetch(`${domain}/familiar/import`, {
            method: 'POST',
            headers: {
                "token": loginInfo.token
            },
            body: formData
        }).then((res) => {
            if(res.status == 200){  
                if(typeof callback == 'function') callback();
                res.json().then((data) => {
                    message.success(data.msg || '导入成功');
                    document.getElementById('changeInput').value = '';
                })
            }else{
                res.json().then((data) => {
                    message.error(data.msg || '导入失败');
                })
            }
        })
    }

    render(){
        const loginInfo = LoginInfo.getInstance();
        const { initialTelValue } = this.props
        const {getFieldDecorator} = this.props.form, that = this

        const upLoadProps = {
            accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel',
            action: `${domain}/familiar/import`,
            headers: {
                authorization: 'authorization-text',
                token: loginInfo.token
            },
            name: 'familiarExcel',
            showUploadList: false,
            onChange(info) {
                if (info.file.status !== 'uploading') {
                }
                if (info.file.status === 'done') {
                    let res = info.file && info.file.response || {};

                    if(res && res.code == 200){
                        message.success(res && res.msg || '上传成功！')
                        that.props.cancelCallBack()
                        that.setState({ addLoading: false })
                        that.data.uploadStatus = true
                    }else{
                        message.error('上传失败！')
                    }
                } else if (info.file.status === 'error') {
                    message.error(`${info.file.name} file upload failed.`);
                }
            },
        }

        const { addModalVisible, addLoading } = this.state

        return (
            <Modal
                maskClosable={false}
                title=""
                visible={ addModalVisible }
                onOk={ this.addHandler }
                onCancel={ this.addHandleCancel }
                confirmLoading={ addLoading }
                cancelText="取消"
                okText="添加"
                className="my-modal-confirm"
                header=""
                width={ 600 }
            >
                <div>
                    <Tabs onChange={ this.addTab } type="card" className='addCarTabs' >
                        <TabPane tab="添加熟车" key="1">
                            <Form layout='vertical' className="add-familiar-pop">
                                <FormItem label="车主姓名">
                                    {getFieldDecorator('addName', {
                                        rules: [
                                            { required: true, message: '车主姓名不能为空!' },
                                            { pattern: regexName, message: '车主姓名录入格式不正确' }
                                        ],
                                    })(<Input maxLength="15" />)}
                                </FormItem>
                                <FormItem label="联系电话">
                                    {getFieldDecorator('addPhone', {
                                        initialValue:initialTelValue || '',
                                        rules: [
                                            { required: true, message: '联系电话不能为空!' },
                                            { pattern: regexTel, message: '联系电话输入格式不正确' }
                                        ],
                                    })(<Input maxLength="11" />)}
                                </FormItem>
                                <FormItem label="备注">
                                    {getFieldDecorator('addRemark', {})(<TextArea autosize={{ minRows: 2, maxRows: 4 }} maxLength="200" />)}
                                </FormItem>
                            </Form>
                        </TabPane>
                        <TabPane tab="批量导入" key="2">
                            <Form layout='vertical' className="add-familiar-pop">
                                <FormItem label="选择导入文件" style={{ marginTop: 60, paddingBottom: 0 }}>
                                        <div className="my-upload" style={{position:'relative'}}>
                                            <input id="my-upload" accept='.xls, .xlsx' type="file" onChange={ e => this.change(e) } style={{display: 'none'}} />
                                            <Input readOnly placeholder='请点击"选择"选择需要导入的模板文件' size="large" id="changeInput" />
                                            <Button style={{position:'absolute', 'right': 0, top: 0, borderTopLeftRadius: 0, borderBottomLeftRadius: 0}} type="primary" size="large" onClick={ this.triggerUpload }>选择</Button>
                                        </div>
                                </FormItem>
                                <a href={ `${domain}/familiar/downloadTemplate` } target="_blank" rel="noopener noreferrer">下载导入数据模板</a>
                            </Form>
                        </TabPane>
                    </Tabs>
                </div>
            </Modal>
        )
    }
}

export default Form.create()(AddFamiliarCar)
