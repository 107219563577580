import React from 'react'
import {filterMenuItem} from 'utils/menu'
import nopic from './nones.png'
import './nones.less'

export default class NoMatch extends React.Component {
    componentDidMount() {
        const {index} = this.props
        this.props.regTab(filterMenuItem(index))
    }
    render() {
        return (
            <div className="n404">
                <div>
                    <img src={nopic} alt="" className="n_img_404"/>
                    <p className="n_p_404">您访问的页面被板车拉走了～</p>
                    {/*<a href="javascript:;" className="n_btn_404">返回上一页</a>*/}
                </div>
            </div>
        )
    }
}