import React from 'react'
import { ajax } from 'utils'
import {filterMenuItem} from 'utils/menu'
import Crumbs from 'components/crumbs'
import Confirm from 'components/confirm'
import DetailPart from 'components/detailPart'
import './booksDetail.less'
import { Row, Col } from 'antd'
import {kindsStatusObj, allBtnsMap, getTabsItem, detailSatusBtnsObj, data2uiMap, getBtnCnByEn} from '../books/booksConfig'
import emitter from 'utils/events'
import moment from 'moment'
import tips from 'components/tips'
import ExPop from 'components/exPop'

import createHistory from 'history/createHashHistory'
const history = createHistory()

export default class BooksDetail extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            btnList: [],
            detailBean: {},
            exBean: {},
            confirm: {
                title: '提 示',
                closable:true,
                btnOkText: '',
                visible: false,
                content: '',
                btnEnName: '',
                popExOprateShow: false,
            },
            bookActiveTab: this.props.bookActiveTab || 'all',
            //exTypeObj: {'1':{}, '2':{}},
        }
    }
    componentWillReceiveProps({bookActiveTab,bookDetailId,index,fFlag,fFlagFn}){
        if(fFlag&&bookActiveTab&&bookDetailId&&index&&fFlagFn){
            if(history.location.pathname!==`/${index}/${bookActiveTab}/${bookDetailId}`){
                this.setState({bookActiveTab},()=>{
                    this.refreshPage()
                    fFlagFn(false)
                })
            }
        }
    }
    componentWillUnmount() {
        this._isMounted = false
        this.setState({clickDelay: null})
    }
    componentDidMount() {
        this._isMounted = true
        const {index} = this.props
        let makePowers = menuId=> {
            ajax.fetchJson(`getPowers/${menuId}`).then(data => {
                if (data && data.code == 200) {
                    let btnList = data.data.buttonList
                    this.setState({btnList})
                } else tips('error', data && data.msg || '查询操作项失败！')
            })
        }
        let menuId = this.props.regTab ? this.props.regTab(filterMenuItem(index)) : null;
        if(menuId){
            makePowers(menuId)
        }else{
            this.eventEmitter = emitter.addListener('meunDataYet', (menuId) => {
                makePowers(menuId)
            })
        }
        this.pageInit()
    }
    pageInit(){
        const {bookDetailId} = this.props
        bookDetailId && ajax.fetchJson(`bookDetail/${bookDetailId}`).then(data=>{
            if(data && data.code==200){
                if(data.data){
                    let detailBean = {...data.data.detailBean}
                    let exBean  =  {...data.data.exBean}
                    this._isMounted && this.setState({exBean,detailBean})
                }
            }else tips('error',data && data.msg || '详情请求失败!')
        }).catch(err=>{/*tips('error','详情请求失败!')*/})

        /*//异常类型请求
        ajax.fetchJson(`getExTypes`,{exParty:1}).then(data => {
            if (data && data.code == 200) {
                if(data.data && data.data.length){
                    let obj = {}
                    data.data.forEach(v=>obj[v.value]=v.name)
                    let  exTypeObj  = {...this.state.exTypeObj, '2':obj} //此与下一个请求中的相同部分'1'、'2'对调，已便在使用this.state.exTypeObj时，能车方上报查货方原因，货方上报查车方原因
                    this._isMounted && this.setState({exTypeObj})
                }
            }else tips('error',data && data.msg||'请求异常类型失败：part1')
        }).catch(err=>{
            console.error('error: >>>>>>>>>>>')
            console.error(err)
        })
        ajax.fetchJson(`getExTypes`,{exParty:2}).then(data => {
            if (data && data.code == 200) {
                if(data.data && data.data.length){
                    let obj = {}
                    data.data.forEach(v=>obj[v.value]=v.name)
                    let  exTypeObj  = {...this.state.exTypeObj, '1':obj}
                    this._isMounted && this.setState({exTypeObj})
                }
            }else tips('error',data && data.msg||'请求异常类型失败：part2')
        }).catch(err=>{
            console.error('error: >>>>>>>>>>>')
            console.error(err)
        })*/
    }
    refreshPage(){
        this.pageInit()
    }
    btmBtnsClick(obj,classn){
        const {bookActiveTab} = this.state
        const {detailBean,exBean} = obj || {detailBean:{},exBean:{}}
        let newOpt
        switch (classn){
            case 'btnShowCredentials': //查看三证
                history.push(`/books/${bookActiveTab}/${detailBean.id}/threeCard`)
                break
            case 'btnRejectDeal': //拒绝承运
                newOpt = {
                    title: '提示',
                    closable:false,
                    btnOkText : getBtnCnByEn(classn),
                    content : '确定拒绝该车主承运吗？',
                    visible : true,
                    btnEnName : classn,
                }
                this.setState({confirm:newOpt})
                break
            case 'btnAgreeDeal': //同意承运
                newOpt = {
                    title: '提示',
                    closable:false,
                    btnOkText : getBtnCnByEn(classn),
                    content : '确定同意该车主承运吗？',
                    visible : true,
                    btnEnName : classn,
                }
                this.setState({confirm:newOpt})
                break
            case 'btnUndoGoodsrc': //撤销货源
                newOpt = {
                    title: '提示',
                    closable:false,
                    btnOkText : getBtnCnByEn(classn),
                    content : '撤销该货源同时会拒绝车主,确定撤销该货源吗？',
                    visible : true,
                    btnEnName : classn,
                }
                this.setState({confirm:newOpt})
                break
            case 'btnCatch': //异常上报
                this.setState({popExOprateShow: true})
                break
            case 'btnGoodsDumped': //卸货完成
                newOpt = {
                    title: '提示',
                    closable:false,
                    btnOkText : getBtnCnByEn(classn),
                    content : '确认车方已卸货完成',
                    visible : true,
                    btnEnName : classn,
                }
                this.setState({confirm:newOpt})
                break
            case 'btnShowReceipt': //查看回执
                history.push(`/books/${bookActiveTab}/${detailBean.id}/showReceipt`)
                break
            case 'btnAddCar': //添加熟车
                let url = '/familiarCar' + ( detailBean.payLinkPhone ? `/${detailBean.payLinkPhone}` : '' )
                history.push(url)
                //this.props.regTab(filterMenuItem('familiarCar'))
                break
        }
    }
    confirmOk(){
        let {tsOrderNo,id} = this.state.detailBean
        let now = Date.now()
        if(this.state.clickDelay && now-this.state.clickDelay<1000*2) return;
        else this.setState({clickDelay: now})
        if(this.state.confirm.btnEnName==='btnGoodsDumped'){ //点击的是卸货完成 确定
            /*
            {
                code:500, msg:"服务器异常"
                code:208, msg:"异常上报"
                code:210, msg:"货方确认卸货完成"
                code:211, msg:"异常上报处理完成"
            }
            */
            ajax.fetchJson(`unloadGoods/${id}`,'post').then(data=>{
                if(data && data.code==200){
                    tips('success',data.msg||'卸货完成操作成功！')
                    this.setState({confirm:{title:'',btnOkText:'',content:'',visible:false,btnEnName:''}},()=>this.pageInit())
                }else tips('error',data && data.msg||'操作失败！')
            }).catch(err=>tips('error','操作失败！'))
        }else if(this.state.confirm.btnEnName==='btnRejectDeal'){ //点击的拒绝承运 确定
            /*
            code:500, msg:"服务器异常"
            code:202, msg:"车方取消"
            code:203, msg:"货方拒绝"
            code:204, msg:"系统拒绝"
            code:205, msg:"同意装货/装货中"
            code:206, msg:"车主装货完成"
            code:207, msg:"系统装货完成"
            code:208, msg:"异常上报"
            code:209, msg:"车方卸货完成"
            code:210, msg:"货方确认卸货完成"
            code:211, msg:"异常上报处理完成"
            */
            ajax.fetchJson(`rejectDeal/${id}`,'post').then(data=>{
                if(data && data.code==200){
                    tips('success',data.msg||'拒绝承运操作成功！')
                    this.setState({confirm:{title:'',btnOkText:'',content:'',visible:false,btnEnName:''}},()=>this.pageInit())
                }else tips('error',data && data.msg||'操作失败！')
            }).catch(err=>tips('error','操作失败！'))
        }else if(this.state.confirm.btnEnName==='btnAgreeDeal'){ //点击的同意承运 确定
            /*
            {
                code:500, msg:"服务器异常"
                code:202, msg:"车方取消"
                code:203, msg:"货方拒绝"
                code:204, msg:"系统拒绝"
                code:205, msg:"同意装货/装货中"
                code:206, msg:"车主装货完成"
                code:207, msg:"系统装货完成"
                code:208, msg:"异常上报"
                code:209, msg:"车方卸货完成"
                code:210, msg:"货方确认卸货完成"
                code:211, msg:"异常上报处理完成"
            }
            */
            ajax.fetchJson(`agreeDeal/${id}`,'post').then(data=>{
                if(data && data.code==200){
                    tips('success',data.msg||'同意承运操作成功！')
                    this.setState({confirm:{title:'',btnOkText:'',content:'',visible:false,btnEnName:''}},()=>this.pageInit())
                }else tips('error',data && data.msg||'操作失败！')
            }).catch(err=>tips('error','操作失败！'))
        }else if(this.state.confirm.btnEnName==='btnUndoGoodsrc'){ //点击的撤销货源 确定
            console.log('撤销货源')
        }
    }
    confirmCancel(){
        this.setState({clickDelay:null, confirm:{title:'',btnOkText:'',content:'',visible:false,btnEnName:''}})
    }
    onExPopOk(exType,exOther,ifChoosedOtherItem,chooseTxt){
        //console.log(exType,exOther)
        /*
         exParty:1, //异常上报方 1.车方 2.货方
        exType:2, //异常上报类型
        exOther:"不给报", //异常上报其他原因
        orderId:1723 //订单id
        */
        let now = Date.now()
        if(this.state.clickDelay && now-this.state.clickDelay<1000*2) return;
        else this.setState({clickDelay: now})
        let orderId = this.state.detailBean.id
        let exParty = 2
        if(ifChoosedOtherItem){
            if(!chooseTxt){
                tips('info','请填写其他原因内容！')
                return
            }
        }
        ajax.fetchJson('sbtCatch','post',{exParty,exType,exOther,orderId}).then(data=>{
            if(data && data.code ==200){
                tips('success',data.msg||'提交成功！')
                this.setState({popExOprateShow: false},()=>this.pageInit())
            }else tips('error',data && data.msg || '提交失败！')
        }).catch(err=>tips('error','提交失败！'))
    }
    onExPopCancel(){
        this.setState({popExOprateShow: false,clickDelay:null, })
    }
    renderHtml(){
        let detailBtmBtns = ''
        let catchPart = ''
        let infoPart = ''

        if(this.state.detailBean && this.state.detailBean.id){
            let {robStatus, corpOrderType, tsOrderNo, payLinkPhone, orderContent, agreeTime, loadTime,unloadTime, confirmUploadTime, payUserName,headPlateNumbers,tailPlateNumbers,
                consultSettleType, consultCashPrice, consultOilPrice, headNo, tailNo,} = this.state.detailBean
            let {exStatus, exTime, exType, completeTime, resultOpinion,exTypeName} = this.state.exBean
            let datass = {detailBean:this.state.detailBean, exBean:this.state.exBean}
            let exStatus2localObj = {'0':'处理中','1':'处理中','2':'处理完成'}
            let consultSettleType2localObj = {'3':'现金+油卡','1':'现金','2':'油卡'}
            //按钮准备
            let btns = [], powerdBtns = []
            let {btnList} = this.state
            let en = data2uiMap[robStatus]
            if(en){
                let statusCn = getTabsItem(en,true)
                if(statusCn){
                    if(detailSatusBtnsObj[statusCn]){
                        btns = detailSatusBtnsObj[statusCn][corpOrderType==2?'normal':'agent'] || []
                    }
                }
            }
            btns.forEach(v=>{
                for(let i=0,l=btnList.length;i<l;i++){
                    if(btnList[i]==v){
                        powerdBtns.push(v)
                        break
                    }
                }
            })
            let ind = powerdBtns.indexOf('卸货完成') //如果ind>-1则说明当前在装货完成tab
            if(ind>-1 && (robStatus!=13)){//如果robStatus==5|6  或者说robStatus!=13，则删此按钮   //5车主装货完成  6系统装货完成  13车方卸货完成-待货方确认
                powerdBtns.splice(ind,1)
            }
            ind = powerdBtns.indexOf('添加熟车') //如果ind>-1则说明有此按钮权限且当前在卸货完成tab
            if(ind>-1 && (corpOrderType!=2)){//如果corpOrderType==2 是企业自发订单，则删此按钮
                powerdBtns.splice(ind,1)
            }

            //违约异常信息部分
            let catchHtmlPart = exStatus==2?(
                <div className="new-dtl">
                    <Row>
                        <Col span={24} className="new-dtl-col"><label>异常上报状态：</label><span style={{color:'#4381E6'}}>{(exStatus in exStatus2localObj) ? exStatus2localObj[exStatus] : ''}</span></Col>
                    </Row>
                    <Row>
                        <Col span={12} className="new-dtl-col"><label>异常上报时间：</label><span>{exTime?moment(exTime).format('YYYY-MM-DD HH:mm:ss'):''}</span></Col>
                        <Col span={12} className="new-dtl-col"><label>处理完成时间：</label><span>{completeTime?moment(completeTime).format('YYYY-MM-DD HH:mm:ss'):''}</span></Col>
                    </Row>
                    <Row>
                        <Col span={24} className="new-dtl-col"><label>异常上报原因：</label><span>{exTypeName}</span></Col>
                    </Row>
                    <Row>
                        <Col span={24} className="new-dtl-col"><label>处理结果：</label><span>{resultOpinion}</span></Col>
                    </Row>
                </div>
            ):(
                <div className="new-dtl">
                    <Row>
                        <Col span={24} className="new-dtl-col"><label>异常上报状态：</label><span style={{color:'#4381E6'}}>{(exStatus in exStatus2localObj) ? exStatus2localObj[exStatus] : ''}</span></Col>
                    </Row>
                    <Row>
                        <Col span={24} className="new-dtl-col"><label>异常上报原因：</label><span>{exTypeName}</span></Col>
                    </Row>
                    <Row>
                        <Col span={12} className="new-dtl-col"><label>异常上报时间：</label><span>{exTime?moment(exTime).format('YYYY-MM-DD HH:mm:ss'):''}</span></Col>
                    </Row>
                </div>
            )
            catchPart = (
                <div className="catchDetail">
                    <div className="catchDetail_tit"><p>异常上报</p></div>
                    {catchHtmlPart}
                </div>
            )
            catchPart = (robStatus==7||robStatus==15)?catchPart:'' //7、15: //违约异常
            //info信息部分
            let agreeTimeTxt = agreeTime?moment(agreeTime).format('YYYY-MM-DD HH:mm:ss'):''
            let loadTimeTxt = loadTime?moment(loadTime).format('YYYY-MM-DD HH:mm:ss'):''
            let unloadTimeTxt = unloadTime?moment(unloadTime).format('YYYY-MM-DD HH:mm:ss'):''
            let confirmUploadTimeTxt = confirmUploadTime?moment(confirmUploadTime).format('YYYY-MM-DD HH:mm:ss'):''
            let caclWay = (consultSettleType in consultSettleType2localObj)?consultSettleType2localObj[consultSettleType]:''
            let s1 = consultCashPrice ? `${consultCashPrice}元` : ''
            let s2 = consultOilPrice ? `${consultOilPrice}元` : ''
            let payWay = (
                <React.Fragment>
                    <label>现  金：</label><span>{s1}</span><span className="as_label">油  卡：</span><span>{s2}</span>
                </React.Fragment>
            )
            if(consultSettleType==1) {
                payWay = (
                    <React.Fragment>
                        <label>现  金：</label><span>{s1}</span>
                    </React.Fragment>
                )
            }else if(consultSettleType==2){
                payWay = (
                    <React.Fragment>
                        <label>油  卡：</label><span>{s2}</span>
                    </React.Fragment>
                )
            }

            if(robStatus==0){ //待同意
                if(corpOrderType==2){//自发货
                    infoPart = (
                        <div className="chengYunDetail">
                            <div className="fix chengYunDetail_tit">
                                <strong className="fl">申请承运详情</strong>
                                <span className="fr"><span>运单号：</span>{tsOrderNo}</span>
                            </div>

                            <div className="new-dtl">
                                <Row>
                                    <Col span={24} className="new-dtl-col b"><label>申请承运货物<em style={{fontWeight:'normal'}}>：</em></label><span>{orderContent}</span></Col>
                                </Row>
                                <Row>
                                    <Col span={12} className="new-dtl-col"><label>车主电话：</label><span>{payLinkPhone}</span></Col>
                                    <Col span={12} className="new-dtl-col"><label>车主姓名：</label><span>{payUserName}</span></Col>
                                </Row>
                                <Row>
                                    <Col span={12} className="new-dtl-col"><label>车头牌号：</label><span>{headPlateNumbers}</span></Col>
                                    <Col span={12} className="new-dtl-col"><label>挂车牌号：</label><span>{tailPlateNumbers}</span></Col>
                                </Row>
                                <Row>
                                    <Col span={12} className="new-dtl-col"><label>协商结算方式：</label><span>{caclWay}</span></Col>
                                    <Col span={12} className="new-dtl-col">{payWay}</Col>
                                </Row>
                            </div>
                        </div>
                    )

                }else{
                    infoPart = ''
                }
            }else if(robStatus==4){//装货中
                /*原自发和代发显示不一，后经嘉乐与产品确认，代发也显示如此，故合并一起*/
                infoPart = (
                    <div className="chengYunDetail">
                        <div className="fix chengYunDetail_tit">
                            <strong className="fl">承运详情</strong>
                            <span className="fr"><span>运单号：</span>{tsOrderNo}</span>
                        </div>
                        <div className="new-dtl">
                            <Row>
                                <Col span={24} className="new-dtl-col b"><label>承运货物：</label><span>{orderContent}</span></Col>
                            </Row>
                            <Row>
                                <Col span={12} className="new-dtl-col"><label>车主电话：</label><span>{payLinkPhone}</span></Col>
                                <Col span={12} className="new-dtl-col"><label>车主姓名：</label><span>{payUserName}</span></Col>
                            </Row>
                            <Row>
                                <Col span={12} className="new-dtl-col"><label>车头牌号：</label><span>{headNo}</span></Col>
                                <Col span={12} className="new-dtl-col"><label>挂车牌号：</label><span>{tailNo}</span></Col>
                            </Row>
                            <Row>
                                <Col span={12} className="new-dtl-col"><label>协商结算方式：</label><span>{caclWay}</span></Col>
                                <Col span={12} className="new-dtl-col">{payWay}</Col>
                            </Row>
                            <Row>
                                <Col span={12} className="new-dtl-col"><label>同意装货时间：</label><span>{agreeTimeTxt}</span></Col>
                            </Row>
                        </div>
                    </div>
                )
            }else if(robStatus==5||robStatus==6||robStatus==13){//装货完成
                if(corpOrderType==2) {//自发货
                    infoPart = (
                        <div className="chengYunDetail">
                            <div className="fix chengYunDetail_tit">
                                <strong className="fl">承运详情</strong>
                                <span className="fr"><span>运单号：</span>{tsOrderNo}</span>
                            </div>
                            <div className="new-dtl">
                                <Row>
                                    <Col span={24} className="new-dtl-col b"><label>承运货物：</label><span>{orderContent}</span></Col>
                                </Row>
                                <Row>
                                    <Col span={12} className="new-dtl-col"><label>车主电话：</label><span>{payLinkPhone}</span></Col>
                                    <Col span={12} className="new-dtl-col"><label>车主姓名：</label><span>{payUserName}</span></Col>
                                </Row>
                                <Row>
                                    <Col span={12} className="new-dtl-col"><label>车头牌号：</label><span>{headNo}</span></Col>
                                    <Col span={12} className="new-dtl-col"><label>挂车牌号：</label><span>{tailNo}</span></Col>
                                </Row>
                                <Row>
                                    <Col span={12} className="new-dtl-col"><label>协商结算方式：</label><span>{caclWay}</span></Col>
                                    <Col span={12} className="new-dtl-col">{payWay}</Col>
                                </Row>
                                <Row>
                                    <Col span={12} className="new-dtl-col"><label>同意装货时间：</label><span>{agreeTimeTxt}</span></Col>
                                    <Col span={12} className="new-dtl-col"><label>装货完成时间：</label><span>{loadTimeTxt}</span></Col>
                                </Row>
                            </div>
                        </div>
                    )
                }else{
                    infoPart = (
                        <div className="chengYunDetail">
                            <div className="fix chengYunDetail_tit">
                                <strong className="fl">承运详情</strong>
                                <span className="fr"><span>运单号：</span>{tsOrderNo}</span>
                            </div>
                            <div className="new-dtl">
                                <Row>
                                    <Col span={24} className="new-dtl-col b"><label>承运货物：</label><span>{orderContent}</span></Col>
                                </Row>
                                <Row>
                                    <Col span={12} className="new-dtl-col"><label>车主电话：</label><span>{payLinkPhone}</span></Col>
                                    <Col span={12} className="new-dtl-col"><label>车主姓名：</label><span>{payUserName}</span></Col>
                                </Row>
                                <Row>
                                    <Col span={12} className="new-dtl-col"><label>车头牌号：</label><span>{headNo}</span></Col>
                                    <Col span={12} className="new-dtl-col"><label>挂车牌号：</label><span>{tailNo}</span></Col>
                                </Row>
                                <Row>
                                    <Col span={12} className="new-dtl-col"><label>协商结算方式：</label><span>{caclWay}</span></Col>
                                    <Col span={12} className="new-dtl-col">{payWay}</Col>
                                </Row>
                                <Row>
                                    <Col span={12} className="new-dtl-col"><label>同意装货时间：</label><span>{agreeTimeTxt}</span></Col>
                                    <Col span={12} className="new-dtl-col"><label>装货完成时间：</label><span>{loadTimeTxt}</span></Col>
                                </Row>
                                {/*支付时间：此处无*/}
                            </div>
                        </div>
                    )
                }
            }else if(robStatus==14){//卸货完成
                if(corpOrderType==2) {//自发货
                    infoPart = (
                        <div className="chengYunDetail">
                            <div className="fix chengYunDetail_tit">
                                <strong className="fl">承运详情</strong>
                                <span className="fr"><span>运单号：</span>{tsOrderNo}</span>
                            </div>
                            <div className="new-dtl">
                                <Row>
                                    <Col span={24} className="new-dtl-col b"><label>承运货物：</label><span>{orderContent}</span></Col>
                                </Row>
                                <Row>
                                    <Col span={12} className="new-dtl-col"><label>车主电话：</label><span>{payLinkPhone}</span></Col>
                                    <Col span={12} className="new-dtl-col"><label>车主姓名：</label><span>{payUserName}</span></Col>
                                </Row>
                                <Row>
                                    <Col span={12} className="new-dtl-col"><label>车头牌号：</label><span>{headNo}</span></Col>
                                    <Col span={12} className="new-dtl-col"><label>挂车牌号：</label><span>{tailNo}</span></Col>
                                </Row>
                                <Row>
                                    <Col span={12} className="new-dtl-col"><label>协商结算方式：</label><span>{caclWay}</span></Col>
                                    <Col span={12} className="new-dtl-col">{payWay}</Col>
                                </Row>
                                <Row>
                                    <Col span={12} className="new-dtl-col"><label>同意装货时间：</label><span>{agreeTimeTxt}</span></Col>
                                    <Col span={12} className="new-dtl-col"><label>装货完成时间：</label><span>{loadTimeTxt}</span></Col>
                                </Row>
                                <Row>
                                    <Col span={12} className="new-dtl-col"><label>卸货完成时间：</label><span>{unloadTimeTxt}</span></Col>
                                </Row>
                            </div>
                        </div>
                    )
                }else{
                    infoPart = (
                        <div className="chengYunDetail">
                            <div className="fix chengYunDetail_tit">
                                <strong className="fl">承运详情</strong>
                                <span className="fr"><span>运单号：</span>{tsOrderNo}</span>
                            </div>
                            <div className="new-dtl">
                                <Row>
                                    <Col span={24} className="new-dtl-col b"><label>承运货物：</label><span>{orderContent}</span></Col>
                                </Row>
                                <Row>
                                    <Col span={12} className="new-dtl-col"><label>车主电话：</label><span>{payLinkPhone}</span></Col>
                                    <Col span={12} className="new-dtl-col"><label>车主姓名：</label><span>{payUserName}</span></Col>
                                </Row>
                                <Row>
                                    <Col span={12} className="new-dtl-col"><label>车头牌号：</label><span>{headNo}</span></Col>
                                    <Col span={12} className="new-dtl-col"><label>挂车牌号：</label><span>{tailNo}</span></Col>
                                </Row>
                                <Row>
                                    <Col span={12} className="new-dtl-col"><label>协商结算方式：</label><span>{caclWay}</span></Col>
                                    <Col span={12} className="new-dtl-col">{payWay}</Col>
                                </Row>
                                <Row>
                                    <Col span={12} className="new-dtl-col"><label>同意装货时间：</label><span>{agreeTimeTxt}</span></Col>
                                    <Col span={12} className="new-dtl-col"><label>装货完成时间：</label><span>{loadTimeTxt}</span></Col>
                                </Row>
                                <Row>
                                    <Col span={12} className="new-dtl-col"><label>卸货完成时间：</label><span>{unloadTimeTxt}</span></Col>
                                </Row>
                                {/*支付时间：此处无*/}
                            </div>
                        </div>
                    )
                }
            }else if(robStatus==7||robStatus==15){//违约异常
                if(corpOrderType==2) {//自发货
                    infoPart = (
                        <div className="chengYunDetail">
                            <div className="fix chengYunDetail_tit">
                                <strong className="fl">承运详情</strong>
                                <span className="fr"><span>运单号：</span>{tsOrderNo}</span>
                            </div>
                            <div className="new-dtl">
                                <Row>
                                    <Col span={24} className="new-dtl-col b"><label>承运货物：</label><span>{orderContent}</span></Col>
                                </Row>
                                <Row>
                                    <Col span={12} className="new-dtl-col"><label>车主电话：</label><span>{payLinkPhone}</span></Col>
                                    <Col span={12} className="new-dtl-col"><label>车主姓名：</label><span>{payUserName}</span></Col>
                                </Row>
                                <Row>
                                    <Col span={12} className="new-dtl-col"><label>车头牌号：</label><span>{headNo}</span></Col>
                                    <Col span={12} className="new-dtl-col"><label>挂车牌号：</label><span>{tailNo}</span></Col>
                                </Row>
                                <Row>
                                    <Col span={12} className="new-dtl-col"><label>协商结算方式：</label><span>{caclWay}</span></Col>
                                    <Col span={12} className="new-dtl-col">{payWay}</Col>
                                </Row>
                                <Row>
                                    <Col span={12} className="new-dtl-col"><label>同意装货时间：</label><span>{agreeTimeTxt}</span></Col>
                                    <Col span={12} className="new-dtl-col"><label>装货完成时间：</label><span>{loadTimeTxt}</span></Col>
                                </Row>
                                <Row>
                                    <Col span={12} className="new-dtl-col"><label>卸货完成时间：</label><span>{unloadTimeTxt}</span></Col>
                                </Row>
                            </div>
                        </div>
                    )
                }else{
                    infoPart = (
                        <div className="chengYunDetail">
                            <div className="fix chengYunDetail_tit">
                                <strong className="fl">承运详情</strong>
                                <span className="fr"><span>运单号：</span>{tsOrderNo}</span>
                            </div>
                            <div className="new-dtl">
                                <Row>
                                    <Col span={24} className="new-dtl-col b"><label>承运货物：</label><span>{orderContent}</span></Col>
                                </Row>
                                <Row>
                                    <Col span={12} className="new-dtl-col"><label>车主电话：</label><span>{payLinkPhone}</span></Col>
                                    <Col span={12} className="new-dtl-col"><label>车主姓名：</label><span>{payUserName}</span></Col>
                                </Row>
                                <Row>
                                    <Col span={12} className="new-dtl-col"><label>车头牌号：</label><span>{headNo}</span></Col>
                                    <Col span={12} className="new-dtl-col"><label>挂车牌号：</label><span>{tailNo}</span></Col>
                                </Row>
                                <Row>
                                    <Col span={12} className="new-dtl-col"><label>协商结算方式：</label><span>{caclWay}</span></Col>
                                    <Col span={12} className="new-dtl-col">{payWay}</Col>
                                </Row>
                                <Row>
                                    <Col span={12} className="new-dtl-col"><label>同意装货时间：</label><span>{agreeTimeTxt}</span></Col>
                                    <Col span={12} className="new-dtl-col"><label>装货完成时间：</label><span>{loadTimeTxt}</span></Col>
                                </Row>
                                <Row>
                                    <Col span={12} className="new-dtl-col"><label>卸货完成时间：</label><span>{unloadTimeTxt}</span></Col>
                                </Row>
                                {/*支付时间：此处无*/}
                            </div>
                        </div>
                    )
                }
            }
            if(powerdBtns.length){
                detailBtmBtns = (
                    <div className="dt_btns_wp">
                        {
                            powerdBtns.map((vv,ii)=>{
                                return <span key={ii} className={'btns_ws '+allBtnsMap[vv]+'_w'}><a onClick={()=>this.btmBtnsClick(datass,allBtnsMap[vv])} className={'btn_t '+allBtnsMap[vv]} href="javascript:;">{vv}</a></span>
                            })
                        }
                    </div>
                )
            }
        }

        return (
            <div className="bookdetail_m_b" style={{margin: '-14px -44px 0 -36px',padding:'0 44px 14px 36px'}}>
                {infoPart}
                {catchPart}
                {detailBtmBtns}
            </div>
        )
    }
    render() {
        const {bookActiveTab} = this.state
        return (
            <div className="bookdetail" style={{paddingBottom:'20px'}}>
                <Crumbs str={
                    [
                        {name:'运单'},
                        {name:getTabsItem(bookActiveTab,true), link:`#/books/${bookActiveTab}`},
                        {name: '详情'}
                    ]
                } />
                <div className="bookdetail_m" style={{paddingTop:'15px',paddingBottom:'15px'}}>
                    {this.renderHtml()}
                    <div className="sline" style={{marginTop:0}}></div>
                    <DetailPart data={this.state.detailBean} />
                </div>
                <ExPop visible={this.state.popExOprateShow} onOk={this.onExPopOk.bind(this)} onCancel={this.onExPopCancel.bind(this)} />

                <Confirm centered={true}
                         title={this.state.confirm.title}
                         closable={true}
                         visible={this.state.confirm.visible}
                         onOk={this.confirmOk.bind(this)}
                         onCancel={this.confirmCancel.bind(this)}
                         content={this.state.confirm.content}
                         okText={this.state.confirm.btnOkText}/>
            </div>
        )
    }
}
