export const kindsStatusObj = {
    '全部运单': {
        tableList:[{'运单编号':'tsOrderNo'},{'路线':'startPoint${-}destPoint'},{'货物信息':'taskContent'},{'发布时间':'${time:ctime}'},{'货源渠道':'${o|1:特运通代理,2:熟车:corpOrderType}'},{'运单状态':'${fn1:robStatus}'},{'操作':'${*}'}],
        typeNum:0,
        key: 'all',
    },
    '待同意':   {
        tableList:[{'运单编号':'tsOrderNo'},{'路线':'startPoint${-}destPoint'},{'货物信息':'taskContent'},{'申请时间':'${time:createTime}'},{'货源渠道':'${o|1:特运通代理,2:熟车:corpOrderType}'},{'运单状态':'${fn1:robStatus}'},{'操作':'${*}'}],
        typeNum:2,
        btns: {normal: ['详情','立即处理'], agent: ['详情'],},
        key: 'forAgree',
    },
    '装货中':   {
        tableList:[{'运单编号':'tsOrderNo'},{'路线':'startPoint${-}destPoint'},{'货物信息':'taskContent'},{'同意装货时间':'${time:agreeTime}'},{'货源渠道':'${o|1:特运通代理,2:熟车:corpOrderType}'},{'运单状态':'${fn1:robStatus}'},{'操作':'${*}'}],
        typeNum:3,
        btns: {normal: ['详情','异常上报'], agent: ['详情','异常上报'],},
        key: 'loading',
    },
    '装货完成': {
        tableList:[{'运单编号':'tsOrderNo'},{'路线':'startPoint${-}destPoint'},{'货物信息':'taskContent'},{'装货完成时间':'${time:loadTime}'},{'货源渠道':'${o|1:特运通代理,2:熟车:corpOrderType}'},{'运单状态':'${fn1:robStatus}'},{'操作':'${*}'}],
        typeNum:4,
        btns: {normal: ['详情','卸货完成','异常上报'], agent: ['详情','查看三证','异常上报'],},
        key: 'loaded',
    },
    '卸货完成': {
        tableList:[{'运单编号':'tsOrderNo'},{'路线':'startPoint${-}destPoint'},{'货物信息':'taskContent'},{'卸货完成时间':'${time:confirmUploadTime}'},{'货源渠道':'${o|1:特运通代理,2:熟车:corpOrderType}'},{'运单状态':'${fn1:robStatus}'},{'操作':'${*}'}],
        typeNum:6,
        btns: {normal: ['详情','查看回执','添加熟车'], agent: ['详情','查看回执','添加熟车'],},
        key: 'unloaded',
    },
    '违约异常': {
        tableList:[{'运单编号':'tsOrderNo'},{'路线':'startPoint${-}destPoint'},{'货物信息':'taskContent'},{'上报时间':'${time:exTime}'},{'货源渠道':'${o|1:特运通代理,2:熟车:corpOrderType}'},{'异常类型':'exType-exParty'},{'上报人':'${o|1:车主,2:货主:exParty}'},{'运单状态':'${fn2:exStatus}'},{'操作':'${*}'}],
        typeNum:-1,
        btns: {normal: ['详情'], agent: ['详情'],},
        key: 'ex'
    },
}
export const detailSatusBtnsObj = {
    '待同意': {normal: ['查看三证','拒绝承运','同意承运'/*,'撤销货源'*/], agent: [],},
    '装货中': {normal: ['查看三证','异常上报'], agent: ['查看三证','异常上报'],},
    '装货完成': {normal: ['查看三证','异常上报','卸货完成','查看回执'], agent: ['查看三证','异常上报','查看回执'],},
    '卸货完成': {normal: ['查看回执','添加熟车'], agent: ['查看回执','添加熟车'],},
    '违约异常': {normal: [], agent: [],},
}

export const allBtnsMap = {
    '详情':     'btnDetail',
    '立即处理': 'btnOperate',
    '异常上报': 'btnCatch',
    '卸货完成': 'btnGoodsDumped',
    '查看三证': 'btnShowCredentials',
    '查看回执': 'btnShowReceipt',
    '添加熟车': 'btnAddCar',
    '同意卸货': 'btnAgreeUnLoad',
    '拒绝承运': 'btnRejectDeal',
    '同意承运': 'btnAgreeDeal',
    '撤销货源': 'btnUndoGoodsrc',
    '拒绝所有车主': 'btnRejectAll',
}
export const getTabsItem = (itemKey,ifBackNameCN) => {
    for(let v in kindsStatusObj){
        if(kindsStatusObj[v].key===itemKey) return ifBackNameCN ? v : kindsStatusObj[v]
    }
    return ifBackNameCN ? '' : null
}
export const getBtnCnByEn = (en) => {
    for(let i in allBtnsMap){
        if(allBtnsMap[i]===en) return i
    }
    return ''
}

export const data2uiMap = {
    // 0待接单/同意, 12车方取消, 2货主拒绝 3系统拒绝 4同意装货/装货中 5车主装货完成  6系统装货完成
    // 7异常上报处理中 13车方卸货完成-待货方确认 14货方确认卸货完成, 15-异常上报处理完成
    '0': 'forAgree',

    '4': 'loading',

    /*s: 装货完成tab */
    '5': 'loaded', //车主装货完成
    '6': 'loaded', //系统装货完成
    '13': 'loaded', //车方卸货完成-待货方确认
    /*e: 装货完成tab */

    '14': 'unloaded', //货方确认卸货完成

    '7': 'ex', //异常上报处理中
    '15': 'ex', //异常上报处理完成
}
