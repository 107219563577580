/**
 * Created by liuyc14 on 2018/8/22.
 */
import request from '../utils/request';
import apiDomain from '../utils/domain';
import {contentType_json, contentType_form} from '../constant';

const domain = apiDomain.baseDomain[apiDomain.env];

export default {
    init(){
        return request(`${domain}/transport/initConfig`, {
            method: 'GET'
        });
    },
    getFamiliarInfo(tsId, {ok, failed, error}){
        request(`${domain}/transport/getFamiliarInfo?tsId=${tsId}`, {
            method: 'GET'
        }).then(d => {
            const data = d.data || {};
            if(data.code == 200){
                ok && ok({
                    data: data.data,
                });
            }else{
                if(failed){
                    failed({code: data.code, msg: data.msg});
                }
            }
        }, e => {
            if(error){
                error(e);
            }
        });
    },
    getList({status, page, pageSize}, {ok, failed, error}){
        request(`${domain}/transport/list?status=${status}&currentPage=${page}&pageSize=${pageSize}`, {
            method: 'GET'
        }).then(d => {
            const data = d.data || {};
            if(data.code == 200){
                ok && ok({
                    total: data.totalCount,
                    page: page,
                    data: (data.data && data.data.transportList) || []
                });
            }else{
                if(failed){
                    failed({code: data.code, msg: data.msg});
                }
            }
        }, e => {
            if(error){
                error(e);
            }
        });
    },
    getHistoryList(){
        return request(`${domain}/transport/history/list`, {
            method: 'GET'
        });
    },
    /**
     * 下架接口
     * @param id
     * @param lastUnloadReason 下架原因
     * @param remark 备注信息
     * @param cancelType 下架位置：1-企业pc操作下架; 2-tyt后台操作下架
     * @param ok
     * @param failed
     * @param error
     */
    cancel({id, lastUnloadReason, remark, cancelType = 1}, {ok, failed, error}){
        request(`${domain}/transport/cancel`, {
            method: 'POST',
            headers: {
                'Content-Type': contentType_json
            },
            body: JSON.stringify({id: id, lastUnloadReason, remark, cancelType})
        }).then(d => {
            const data = d.data || {};
            if(data.code == 200){
                ok && ok();
            }else{
                if(failed){
                    failed({code: data.code, msg: data.msg});
                }
            }
        }, e => {
            if(error){
                error(e);
            }
        });
    },
    /**
     * 重新发布接口
     * @param id
     * @param ok
     * @param failed
     * @param error
     */
    rePublish(id, {ok, failed, error}){
        request(`${domain}/transport/rePublish`, {
            method: 'POST',
            headers: {
                'Content-Type': contentType_form
            },
            body: 'id=' + id
        }).then(d => {
            const data = d.data || {};
            if(data.code == 200){
                ok && ok(data.data || {});
            }else{
                if(failed){
                    failed({code: data.code, msg: data.msg});
                }
            }
        }, e => {
            if(error){
                error(e);
            }
        });
    },
    matches(keyword, {ok, failed, error}){
        request(`${domain}/transport/search/matches?keyword=${keyword}`, {
            method: 'GET'
        }).then(d => {
            const data = d.data || {};
            if(data.code == 200){
                ok && ok(data.data || []);
            }else{
                if(failed){
                    failed({code: data.code, msg: data.msg});
                }
            }
        }, e => {
            if(error){
                error(e);
            }
        });
    },
    check(keyword, {ok, failed, error}){
        request(`${domain}/transport/check/standard?keyword=${keyword}`, {
            method: 'GET'
        }).then(d => {
            const data = d.data || {};
            if(data.code == 200){
                ok && ok(data.data || []);
            }else{
                if(failed){
                    failed({code: data.code, msg: data.msg});
                }
            }
        }, e => {
            if(error){
                error(e);
            }
        });
    },
    publish(requestData, {ok, failed, error}){
        request(`${domain}/transport/pub`, {
            method: 'POST',
            headers: {
                'Content-type': contentType_json
            },
            body: JSON.stringify(requestData)
        }).then(d => {
            const data = d.data || {};
            if(data.code == 200){
                ok && ok();
            }else{
                if(failed){
                    failed({code: data.code, msg: data.msg});
                }
            }
        }, e => {
            if(error){
                error(e);
            }
        });
    },
    getHistoryInfo(id, {ok, failed, error}){
        request(`${domain}/transport/getTransport?id=${id}`, {
            method: 'GET'
        }).then(d => {
            const data = d.data || {};
            if(data.code == 200){
                ok && ok(data.data || []);
            }else{
                if(failed){
                    failed({code: data.code, msg: data.msg});
                }
            }
        }, e => {
            if(error){
                error(e);
            }
        });
    },
    view(id, {ok, failed, error}){
        request(`${domain}/transport/detail?tsId=${id}&isJoin=1&isDepartment=1`, {
            method: 'GET'
        }).then(d => {
            const data = d.data || {};
            if(data.code == 200){
                ok && ok(data.data || []);
            }else{
                if(failed){
                    failed({code: data.code, msg: data.msg});
                }
            }
        }, e => {
            if(error){
                error(e);
            }
        });
    }
};