import React from 'react'
import {filterMenuItem} from 'utils/menu'
import {Button, Form, Input, message} from 'antd';
import service from '../../service/loginService';
import forge from 'node-forge';
import {regPwd} from '../../constant';
import './index.less';

const FormItem = Form.Item;
const formItemLayout = {
    labelCol: {
        xs: { span: 24 },
        sm: { span: 5 }
    },
    wrapperCol: {
        xs: { span: 24 },
        sm: { span: 15 }
    }
};
const tailFormItemLayout = {
    wrapperCol: {
        xs: {
            span: 24,
            offset: 0
        },
        sm: {
            span: 15,
            offset: 5
        }
    }
};

class DataForm extends React.Component {
    handleSubmit = (e) => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if(!err){
                this.props.OKCallback(values);
            }
        });
    };

    handleReset = () => {
        this.props.form.resetFields();
    };

    checkConfirm = (rule, value, callback) => {
        const form = this.props.form;
        if (value && value !== form.getFieldValue('password')) {
            callback('两次密码输入不一致!');
        } else {
            callback();
        }
    };

    // To generate mock Form.Item
    render() {
        const { getFieldDecorator } = this.props.form;
        return (
            <Form
                className=""
                onSubmit={this.handleSubmit}
            >
                <FormItem
                    {...formItemLayout}
                    label="旧密码"
                >
                    {getFieldDecorator('oldPwd', {
                        rules: [{
                            required: true, message: '请输入当前登录密码!'
                        }]
                    })(
                        <Input type="password" size="large" autoComplete="off"
                               placeholder="请输入旧密码"
                        />
                    )}
                </FormItem>
                <FormItem
                    {...formItemLayout}
                    label="新密码"
                >
                    {getFieldDecorator('password', {
                        rules: [{
                            required: true, message: '请输入新的密码!'
                        },
                            {pattern: regPwd, message: '请输入6-16位以内的新密码'}
                        ]
                    })(
                        <Input type="password" size="large" autoComplete="off"
                               placeholder="请输入6-16位以内的新密码"
                        />
                    )}
                </FormItem>
                <FormItem
                    {...formItemLayout}
                    label="确认新密码"
                >
                    {getFieldDecorator('confirm', {
                        rules: [{
                            required: true, message: '请再次输入新密码!'
                        },
                            {validator: this.checkConfirm}
                        ]
                    })(
                        <Input type="password" size="large" autoComplete="off"
                               placeholder="再次输入新密码"
                        />
                    )}
                </FormItem>
                <FormItem {...tailFormItemLayout} style={{marginTop: 38}}>
                    <Button type="primary" htmlType="submit" className="btnSave" size="large">保存</Button>
                </FormItem>
            </Form>
        );
    }
}
const WrappedDataForm = Form.create()(DataForm);

export default class SetPwd extends React.Component {
    constructor(props){
        super(props);
    }
    componentDidMount() {
        const {index} = this.props;
        this.props.regTab(filterMenuItem(index))
    }
    render(){
        return (
            <div className="container_setPwd">
                <h3 className="title">修改密码</h3>
                <div className="content">
                    <div className="box">
                        <WrappedDataForm
                            OKCallback={this.OKHandler.bind(this)}
                        />
                    </div>
                </div>
            </div>
        );
    }

    OKHandler(values) {
        let md1 = forge.md.md5.create();
        md1.update(values.oldPwd);
        values.oldPwd = md1.digest().toHex();


        let md = forge.md.md5.create();
        md.update(values.password);
        values.newPwd = md.digest().toHex();

        // console.log(values);

        service.updatePwd(values, {
            ok: () => {
                message.success('密码修改成功');
            },
            failed: e => {
                // console.log(e);
                message.warn(`密码修改失败，${e.msg},请稍后再试！`);
            },
            error: e => {
                message.error('密码修改失败，请稍后再试！');
                // console.error(e);
            }
        });
    }
}
