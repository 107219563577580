import React from 'react';
import {Upload, Button, Form, Row, Col, message} from 'antd';
import {filterMenuItem} from 'utils/menu';
import apiDomain from '../../utils/domain';
import BaseComponent from '../../components/base/BaseComponent';
import service from '../../service/loginService';
import emitter from 'utils/events'
import { ajax } from 'utils'

import './index.less';

const domain = apiDomain.baseDomain[apiDomain.env];
const FormItem = Form.Item;

const formItemLayout = {
    labelCol: {
        xs: { span: 24 },
        sm: { span: 6 }
    },
    wrapperCol: {
        xs: { span: 24 },
        sm: { span: 14 }
    }
};

const tailFormItemLayout = {
    wrapperCol: {
        xs: {
            span: 24,
            offset: 0
        },
        sm: {
            span: 14,
            offset: 6
        }
    }
};

const uploadButton = (
    <div>
        <button type="button" className="btn-upload"></button>
        <a className="btnUpdate">点击修改</a>
    </div>
);

const updateButton = (url) => {
    return (
        <div>
            <img src={url} className="img-upload" alt="avatar"/>
            <a className="btnUpdate">点击修改</a>
        </div>
    );
};

class DataForm extends BaseComponent {
    constructor(props){
        super(props);
        this.state = {
            loading: false,
            imageUrl: ""
        };

        this.file = null;
    }

    componentDidMount(){
        let makePicUrlPre = imageUrl =>{
            if(!(imageUrl.indexOf('http')===0 || imageUrl.indexOf('//')===0)){
                if(imageUrl.indexOf('/')===0) imageUrl = '/rootdata' + imageUrl
                else imageUrl = '/rootdata/' + imageUrl
                imageUrl = ajax.getUrl(imageUrl,'com')
            }
            return imageUrl
        }
        //user info
        let avatar = sessionStorage.getItem('avatar')
        if(avatar){
            this.setState({imageUrl:makePicUrlPre(avatar)})
        }
        super.componentDidMount();
        this.eventEmitter = emitter.addListener('headPicChange', (imageUrl) => {
            this.setState({imageUrl:makePicUrlPre(imageUrl)})
        })
    }
    componentWillUnmount() {
        typeof this.eventEmitter==='function' && emitter.removeListener(this.eventEmitter)
    }

    // To generate mock Form.Item
    render() {
        const { getFieldDecorator, getFieldError } = this.props.form;
        const {imageUrl, loading} = this.state;
        return (
            <Form
                className=""
                onSubmit={this.handleSubmit}
            >
                <FormItem
                    {...formItemLayout}
                    label="头像"
                    className="no"
                >
                    {getFieldDecorator('pic', {
                        rules: [{
                            required: true, message: '请点击上传新头像!'
                        }]
                    })(
                        <Upload
                            name="avatarImg"
                            listType="picture-card"
                            headers={{token: this.loginInfo.token}}
                            className="avatar-uploader center-upload"
                            showUploadList={false}
                            action={`${domain}/user/changeAvatar`}
                            beforeUpload={this.beforeUpload}
                            onChange={this.handleChange}
                            onPreview={this.handlePreview}
                        >
                            {imageUrl ? updateButton(imageUrl) : uploadButton}
                        </Upload>
                    )}
                </FormItem>
                <Row style={{fontSize: 14, color: '#999999'}}>
                    <Col span={6}></Col>
                    <Col span={18}><span style={{paddingLeft: 37}}>推荐10M以内JPEG/JPG/PNG/GIF等图片</span></Col>
                </Row>
                <FormItem {...tailFormItemLayout} style={{marginTop: 50}}>
                    <Button type="primary" htmlType="submit" loading={this.props.submitting} className="btnSave" size="large">保存</Button>
                </FormItem>
            </Form>
        );
    }

    handleSubmit = (e) => {
        const getError = this.props.form.getFieldError;
        e.preventDefault();
        if(getError('pic') && getError('pic').length) return;
        this.props.form.validateFields((err, values) => {
            console.log('>>>>>>>>>>');
            console.log(err);
            console.log(values);
            if(!err && this.file ){
                this.props.OKCallback(values);
            }
        });
    };

    handleReset = () => {
        this.props.form.resetFields();
    };

    checkConfirm = (rule, value, callback) => {
        const form = this.props.form;
        if (value && value !== form.getFieldValue('password')) {
            callback('两次输入的密码不一致!');
        } else {
            callback();
        }
    };

    handlePreview = (file) => {
        console.log('preview');
        console.log(file);
    };

    getBase64 = (img, callback) => {
        const reader = new FileReader();
        reader.addEventListener('load', () => callback(reader.result));
        reader.readAsDataURL(img);
    };

    beforeUpload = (file) => {
        //console.log('beforeUpload');
        //.log(file);

        //return isJPG && isLt2M;
        return false;
    };

    handleChange = (info) => {

        if(this.checkFile(info)){


        //if (info.file.status === 'done') {
            // Get this url from response in real world.
        // this.getBase64(info.file.originFileObj, imageUrl => this.setState({
            this.getBase64(info.file, imageUrl => this.setState({
                imageUrl,
                loading: false
            }));
        //message.success('修改头像成功！');
        //}else if(info.file.status == 'error'){
        //   message.error('修改头像失败，请稍后再试！');
        //}
        }
    };

    checkFile = (info) => {
        const file = info.file;
        const {setFields, validateFields} = this.props.form;
        const isJPG = file.type === 'image/jpg' || file.type == 'image/png' || file.type == 'image/gif' || file.type == 'image/jpeg';
        console.log(isJPG);
        if (!isJPG) {
            setTimeout(() => {
                setFields({
                    "pic": {
                        value: info,
                        errors: [new Error('图片格式不正确，请上传JPEG/JPG/PNG/GIF等格式图片')]
                    }
                });
            }, 1);
        }
        const isLt2M = file.size / 1024 / 1024 < 10;
        if (!isLt2M) {
            setTimeout(() => {
                setFields({
                    "pic": {
                        value: info,
                        errors: [new Error('图片太大了，换一张小点的图片吧')]
                    }
                });
            }, 1);
        }
        if(isJPG && isLt2M){
            this.file = file;
            return true;
        }else{
            this.file = null;
            return false;
        }
    };
}
const WrappedDataForm = Form.create()(DataForm);

export default class UpdateHeadPic extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            submitting: false
        };
    }

    componentDidMount() {
        const {index} = this.props;
        this.props.regTab(filterMenuItem(index))
    }
    render() {
        return (
            <div className="container_updateHead">
                <h3 className="title">修改头像</h3>
                <div className="content">
                    <div className="box">
                        <WrappedDataForm
                            submitting={this.state.submitting}
                            OKCallback={this.OKHandler}
                        />
                    </div>
                </div>
            </div>
        )
    }

    OKHandler = (values) => {
        this.setState({submitting: true});
        let formData = new FormData();
        formData.append('avatarImg', values.pic.file);

        service.changeAvatar(formData, {
            ok: (d) => {
                this.setState({submitting: false});
                if(d && d.avatarUrl) {
                    let user = sessionStorage.getItem('user')
                    if(user){
                        user = JSON.parse(user)
                        user.avatar = d.avatarUrl
                        sessionStorage.setItem('user',JSON.stringify(user))
                    }
                    sessionStorage.setItem('avatar',d.avatarUrl)
                    emitter.emit('headPicChange', d.avatarUrl)
                    message.success('修改头像成功');
                }
            },
            failed: () => {
                this.setState({submitting: false});
                message.warn('修改头像失败');
            },
            error: () => {
                this.setState({submitting: false});
                message.error('修改头像失败');
            }
        });
    };
}
