import React from 'react'
import {Link} from 'react-router-dom'
import {allMenu,filterMenuItem} from 'utils/menu'
import { ajax } from 'utils'
import Contents from './content'
import './index.less'
import './header.less'
import emitter from 'utils/events'
import { LocaleProvider,notification,Popover,Badge, Tooltip } from 'antd'
import zh_CN from 'antd/lib/locale-provider/zh_CN'
import tips from 'components/tips'
import Confirm from 'components/confirm'
import createHistory from 'history/createHashHistory'
import LoginInfo from '../utils/loginInfo';
const history = createHistory()

notification.config({
    // placement: 'topRight',
    getContainer: ()=> document.querySelector('.headRightMsg'),
    duration: 60,
    // duration: 60*60,
    top: 0
})
const openNotification = (tit,con,key) => {
    let opt = {
        message: tit || '消息：',
        description: con || '',
    }
    if(key) opt.key = key
    notification.open(opt)
}

export default class Container extends React.Component {
    state = {
        current: '',
        collapsed: false,
        menuArr:[],
        navTabArr: [filterMenuItem('index')],
        navTabArrCurrent: 0,
        arrowsVisibility: false,
        tabsUIMarginLeft: 0,
        arrowLeftDisabled: false,
        arrowRightDisabled: false,
        booksPopNum: 0,
        msgPopNum: 0,
        user: {},
        loginOutVisible: false,
        forIndex:{exceptionNum:0,inLoadingNum:0,loadOverNum:0,waitAgreeNum:0},
        fFlag: false,
        evtDisplay: false,
    }
    componentDidMount() {
        const loginInfo = LoginInfo.getInstance();
        //this.menuItemClick(allMenu[0])
        //location.replace("#/index") //或改location.hash

        //user info
        let user = loginInfo.user;
        if(user) this.setState({user:JSON.parse(user)})
        //左侧菜单请求
        ajax.fetchJson('leftMenu').then(data=>{
            let newData = []
            if(data && data.code==200){
                if(data.data&&data.data.length){
                    data.data.forEach((v,i)=>{
                        for(let ii=0,l=allMenu.length;ii<l;ii++){
                            let obj = allMenu[ii]
                            if(v.jurisdictionName===obj.name){
                                obj.id = v.id
                                if(obj.notMenu) obj.notMenu = false
                                newData.push(obj)
                                break
                            }
                        }
                    })
                }
            }else{
                //tips('error', data && data.msg || '查询菜单失败！')
            }
            if(newData.length) {
                this.setState({menuArr: newData},()=>emitter.emit('meunDataYet', this.state.navTabArr[this.state.navTabArrCurrent].id))
            }
        })//.catch(err=>tips('error', data && data.msg || '查询菜单失败！'))
        //绑定右侧tab区左右按钮可见性
        window.addEventListener('resize', this.tabAreaUiUpdateArrow)
        //全站消息轮询
        let getGlobalMsg = ()=>{
            ajax.fetchJson('globalMsg').then(data=>{
                if(data && data.code==200){
                    if(data.data && data.data.length){
                        function delDom(x){
                            while (!x.classList || !x.classList.contains('ant-notification-notice')) {
                                x = x.parentNode;
                                if(x.tagName.toUpperCase()==='BODY') break
                            }
                            if(x) x.parentNode.removeChild(x)
                        }
                        data.data.forEach((v,i)=>{
                            //<span myclass="t-s2">段文昌（13269587655）</span><span myclass="t-s4">申请承运</span>
                            if(v.content && v.title){
                                let tit = v.title.replace(/<\/?[^>]*>/g, '').replace(/[|]*\n/, '')
                                let conStr = v.content.replace(/^<div>/,'').replace(/<\/div>$/,'')
                                /*conStr = conStr.replace(/(.+)(<span\s+link=")(.+)([\u67e5][\u770b][\u8be6][\u60c5]<\/span>)/g,($0, $1, $2, $3, $4)=>{
                                    let route = '/#/' + $3.replace('"', '')
                                    return $1 + '<a href='+ route + '查看详情</ a>'
                                })*/
                                //let con = <div dangerouslySetInnerHTML={{ __html: conStr }}  />
                                //openNotification(tit,con)
                                let arr = []
                                while(conStr.indexOf('</span>')>-1){
                                    let ind = conStr.indexOf('</span>');
                                    let lt = conStr.slice(0,ind+7)
                                    conStr = conStr.slice(ind+7)
                                    arr.push(lt)
                                }
                                /*
                                arr : [
                                    "<span myclass="t-s1">北京市东城区->天津市和平区,20吨120铲车</span>",
                                    "<span link="books/forAgree/83" myclass="t-s0">查看详情</span>"
                                ]
                                */
                                let ckey = `nty_${i}_${Date.now()}`
                                let div = (
                                    <div>
                                        {
                                            arr.map((vv,ii)=>{
                                                let cla='', link
                                                if(vv.indexOf('myclass="')>-1){
                                                    let ind1= vv.indexOf('myclass="')
                                                    let ind2= vv.indexOf('"',ind1+10)
                                                    cla = vv.slice(ind1+9,ind2)
                                                }
                                                if(vv.indexOf('link="')>-1){
                                                    let ind1= vv.indexOf('link="')
                                                    let ind2= vv.indexOf('"',ind1+7)
                                                    link = vv.slice(ind1+6,ind2)
                                                }
                                                if(vv.indexOf('link=')>-1) return <a key={ii} className={cla} onClick={()=>this.btnNotificationLink(ckey,link,v.id)} href="javascript:;">{vv.replace(/<\/?[^>]*>/g, '').replace(/[|]*\n/, '')}</a>
                                                return <span key={ii} className={cla}>{vv.replace(/<\/?[^>]*>/g, '').replace(/[|]*\n/, '')}</span>
                                            })
                                        }
                                    </div>
                                )
console.log(tit)
console.log(div)
console.log(ckey)
                                openNotification(tit,div,ckey)
                            }
                        })
                    }

                    let {exceptionNum,inLoadingNum,loadOverNum,waitAgreeNum} = data
                    exceptionNum = isNaN(exceptionNum) ? 0 : +exceptionNum
                    inLoadingNum = isNaN(inLoadingNum) ? 0 : +inLoadingNum
                    loadOverNum = isNaN(loadOverNum) ? 0 : +loadOverNum
                    waitAgreeNum = isNaN(waitAgreeNum) ? 0 : +waitAgreeNum
                    this.setState({booksPopNum:data.totalOrderNum,msgPopNum:data.msgCount,forIndex:{exceptionNum,inLoadingNum,loadOverNum,waitAgreeNum}})
                }
            })
        }
        getGlobalMsg()
        //let timer = setInterval(getGlobalMsg,60*1000)
        this.timer2 = setInterval(getGlobalMsg,30*1000)

        this.eventEmitter = emitter.addListener('headPicChange', (imageUrl) => {
            let user = sessionStorage.getItem('user')
            if(user){
                user = JSON.parse(user)
                user.avatar = imageUrl
                this.setState({user})
            }
        })
    }
    btnNotificationLink(ckey,link,id){
        ajax.fetchJson(`doReaded/${id}`).then(data=> {
            if (data && data.code == 200) {
                this.setState({fFlag:true},()=>location.replace(`#/${link}`))
                notification.close(ckey)
            }else tips('error', data && data.msg || '设置已读失败！')
        }).catch(err=>tips('error', '设置已读失败！'))
    }
    componentWillUnmount() {
        //window.removeEventListener('resize', this.tabAreaUiUpdateArrow)
        if(typeof this.eventEmitter==='function') emitter.removeListener(this.eventEmitter)
        clearInterval(this.timer2)
        this.setState = (state,callback)=>{
            return
        }
    }
    fFlagFn(boolean){
        this.setState({fFlag:!!boolean})
    }
    toggleUiCollapse = () => {
        this.setState({collapsed: !this.state.collapsed},()=>this.tabAreaUiUpdateArrow())
    }
    navTabClick = (obj, index) => {
        this.setState({
            current: obj.url,
            navTabArrCurrent: index
        })
    }
    menuItemClick = (obj) => {
        let navTabArr = this.state.navTabArr
        let has = false
        let index = 0
        navTabArr.forEach((item, eq) => {
            if (item.url === obj.url) {
                index = eq
                has = true
            }
        })
        let opt = {current: obj.url}
        if (has) opt.navTabArrCurrent = index
        else {
            navTabArr.push(obj)
            opt.navTabArrCurrent = navTabArr.length - 1
            opt.navTabArr = navTabArr
        }
        this.setState(opt,()=>{
            this.doTabsScroll(has?index:'leftEnd')
            this.tabAreaUiUpdateArrow()
        })
        return (filterMenuItem(obj.url,this.state.menuArr)||{}).id
    }
    navTabClose = (url) => {
        let arr = this.state.navTabArr
        let hasEq = -1
        arr.forEach((item, index) => {
            if (item.url === url) hasEq = index
        })

        if(hasEq>-1){
            arr.splice(hasEq,1)
            let cur = hasEq-1 < 0 ? 0 : hasEq-1
            this.setState({navTabArr:arr, navTabArrCurrent:cur, current: arr[cur].url}, ()=>{
                this.doTabsScroll('right')
                this.tabAreaUiUpdateArrow()
            })
            location.replace('#/'+arr[cur].url) //或改location.hash
        }
    }
    doTabsScroll = (dir) => {//dir: 'left'||'leftEnd'||'right'||'rightEnd'||Number(index: to item[index]'s place)
        let lis = this.state.navTabArr
        if(lis.length){
            let liWidth = 140 || this.tabULRef.getElementsByTagName('li')[0].offsetWidth  //此处暂采用写死方式感觉较为稳妥
            let oldMargin = this.state.tabsUIMarginLeft
            let tabULRefWidth = this.tabULRef.offsetWidth
            let pers = Math.floor(this.tabUlScroller.offsetWidth / liWidth)
            let leftEnd = liWidth*pers-tabULRefWidth
            let newMargin
            if(typeof dir === 'number'){
                //todo: 判断index==dir的item是否在可视区外，在可视区外的左还是在可视区外的右，重新赋值newMargin代替以下语句
                newMargin = oldMargin
            }
            else newMargin = {left:oldMargin-liWidth, leftEnd:leftEnd, right:oldMargin+liWidth, rightEnd:0}[dir]
            let arrowLeftDisabled, arrowRightDisabled
            if(newMargin>=0){
                newMargin = 0
                arrowLeftDisabled = false
                arrowRightDisabled = true
            }
            else if(newMargin<=leftEnd){
                newMargin=leftEnd
                arrowLeftDisabled = true
                arrowRightDisabled = false
            }
            this.setState({tabsUIMarginLeft: newMargin, arrowLeftDisabled: arrowLeftDisabled, arrowRightDisabled: arrowRightDisabled })
        }
    }
    tabAreaUiUpdateArrow = ()=>{
        clearTimeout(this.timer)
        if(!this.tabUlScroller) return
        let arrowsVisibility
        let scrContainerWidth = this.tabUlScroller.offsetWidth
        let scrWidth = this.tabULRef.offsetWidth
        if(this.state.navTabArr.length===1) arrowsVisibility = false
        else arrowsVisibility = scrWidth>scrContainerWidth
        this.timer = setTimeout(()=> this.setState({arrowsVisibility:arrowsVisibility},()=>{if(!arrowsVisibility) this.doTabsScroll('leftEnd')}) , 0)
    }
    refreshPage=()=>{

    }
    closeOtherPages=()=>{
        let first = this.state.navTabArr[0]
        let one = this.state.navTabArr[this.state.navTabArrCurrent]
        let arr = first.url===one.url ? [first] : [first,one]
        this.setState({navTabArr:arr, navTabArrCurrent:arr.length-1, current: arr[arr.length-1].url}, ()=>{
            this.tabAreaUiUpdateArrow()
        })
        //location.replace('#/'+arr[arr.length-1].url)
    }
    closeAllPages =()=>{
        this.setState({navTabArr:[filterMenuItem('index')], navTabArrCurrent:0, current: 'index'}, ()=>{
            this.tabAreaUiUpdateArrow()
        })
        location.replace('#/index')
    }
    loginOutOk(){
        const loginInfo = LoginInfo.getInstance();
        ajax.fetchJson('loginOut').then(data => {
            if(data && data.code==200) {
                loginInfo.clearInfo(true);
                history.replace(`/login`);
            }
            else tips('error', data && data.msg || '退出失败！')
        }).catch(err=>tips('error', '退出失败！'))
    }
    evtShowTip(){
        if(this.state.collapsed) this.setState({evtDisplay:true})
    }
    evtHideTip(){
        this.setState({evtDisplay:false})
    }
    render() {
        let settingNav = (
            <ul className="settingNav">
                <li className="setHead"><Link to="/updateHeadPic"><i></i><span>修改头像</span></Link></li>
                <li className="setPwd"><Link to="/setPwd" replace={true}><i></i><span>修改密码</span></Link></li>
                <li className="setExit"><a onClick={this.loginOutOk.bind(this)} href="javascript:;"><i></i><span>安全退出</span></a></li>{/*()=>this.setState({loginOutVisible:true})*/}
            </ul>
        )
        let moreNav = (
            <ul className="moreNav">
                {/*<li className="moreReload"><a href="javascript:;" onClick={this.refreshPage}><i></i><span>刷新当前页面</span></a></li>*/}
                <li className="moreClose"><a href="javascript:;" onClick={this.closeOtherPages}><i></i><span>关闭其他页面</span></a></li>
                <li className="moreCloseAll"><a href="javascript:;" onClick={this.closeAllPages}><i></i><span>关闭所有页面</span></a></li>
            </ul>
        )
        let userImg = this.state.user && this.state.user.avatar || ''
        if(userImg){
            if(!(userImg.indexOf('http')===0 || userImg.indexOf('//')===0)){
                if(userImg.indexOf('/')===0) userImg = '/rootdata' + userImg
                else userImg = '/rootdata/' + userImg
                userImg = ajax.getUrl(userImg,'com')
            }
        }
        let img = userImg ? <img src={userImg} /> : ''
        return (
            <LocaleProvider locale={zh_CN}>
                <div className="j_b">
                    {/* s: header */}
                    <header className="header cf">
                        <div className="keep headLeft fl">
                            <Link to=""><img src={require('../images/headlogo.png')}/><span>特运通</span></Link>
                            <i></i>
                            <span>{this.state.user.adminName||''}</span>
                        </div>

                        <div className="headRight fr">

                            <Link className="mymsglink" to="/myMsg">
                                <Badge className="badgePop" count={this.state.msgPopNum} overflowCount={99}>
                                    <i className="msgIcon"></i>
                                </Badge>
                                <div className="tipp bottomCenter"><p>我的消息</p></div>
                            </Link>
                                {/*企业入口
                                <Tooltip overlayClassName="CompanyToolBox" arrowPointAtCenter="true" placement="top" title={<a className="jumpCompany">企业管理</a>} >
                                    <a><i className="companyIcon"></i></a>
                                </Tooltip>
                            */}

                            <a className="mymsglink" href="javascript:;" onClick={this.loginOutOk.bind(this)}>{/*onClick={()=>this.setState({loginOutVisible:true})}*/}
                                <i className="exitIcon"></i>
                                <div className="tipp bottomRight"><p>安全退出</p></div>
                            </a>
                        </div>

                        <div className="fr headRightMsg"></div>

                    </header>
                    {/* e: header */}

                    <div className={['mmain', this.state.collapsed?'collapsed':''].join(' ')} >

                        <div className="tipp rightBottom" style={{display:this.state.evtDisplay?'block':'none'}}>
                            <div className="fl menuCustom_con2">
                                <em>客服</em>
                                <p className="numPhone">400-6688-998</p>
                                <p>08:00-20:00</p>
                            {/*<Link to="/newUserHelp">新手帮助</Link>*/}
                            </div>
                        </div>


                        <div className="leftMenu">
                            <div className="leftMenu_sub">

                                <div className="fix cmenuTop">
                                    <div className="cmenuTopLeft" onClick={this.toggleUiCollapse}>
                                        <span className="tico tico_tg_l"/>
                                        <span className="tico tico_tg_r"/>
                                    </div>
                                    <div className="tc cmenuTop_img">
                                        <a href="javascript:;">{img}</a>
                                        <p className="ell userName">{this.state.user.nickname||''}</p>
                                        <p className="ell portTit">{this.state.user.positionName||''}</p>
                                    </div>
                                    <div className="cmenuTopRight cmenuTop_set">
                                        <Popover style={{width:160}} placement="bottomLeft" content={settingNav} trigger="hover">
                                            <span className="tico tico_set"/>
                                        </Popover>
                                    </div>
                                </div>

                                <Popover className="PopoverLeftTop" style={{width:160}} placement="leftTop" content={settingNav} trigger="hover">
                                    <div className="tc cmenu_sm_pic">
                                        <a href="javascript:;">{img}</a>
                                    </div>
                                </Popover>

                                <ul className="menu">
                                    {
                                        this.state.menuArr.map((subMenu) => {
                                            if(subMenu.notMenu) return ''
                                            return (
                                                <li key={subMenu.url}>
                                                    <Link title={subMenu.name} className={['fix','menuLink',this.state.current===subMenu.url?'active':''].join(' ')}
                                                          to={`/${subMenu.url}`}
                                                          replace={true}
                                                    >
                                                        <span className={['fl','tico','positionIocn',subMenu.icon].join(' ')}/>
                                                        {
                                                            subMenu.hasPop ?
                                                            <Badge className={this.state.collapsed?'dotBadgePop':'listBadgePop'} count={this.state.booksPopNum} dot={this.state.collapsed} overflowCount={99}>
                                                                <span className="fl menu_link_txt">{subMenu.name}</span>
                                                            </Badge>
                                                            :
                                                            <span className="fl menu_link_txt">{subMenu.name}</span>
                                                        }
                                                        <span className="fr tico tico_arrow_r"/>
                                                    </Link>
                                                </li>
                                            )
                                        })
                                    }
                                </ul>
                                <div className="fix menuCustom" onMouseEnter={this.evtShowTip.bind(this)} onMouseLeave={this.evtHideTip.bind(this)}>
                                    <span className={['fl','tico','tico_customer'].join(' ')}/>
                                    <div className="fl menuCustom_con">
                                        <em>客服</em>
                                        <p className="numPhone">400-6688-998</p>
                                        <p>08:00-20:00</p>
                                        {/*<Link to="/newUserHelp">新手帮助</Link>*/}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="main_con" id="content">

                            <div className="fix main_con_head">
                                <div className="fl main_con_head_l" ref={dom=>this.tabUlScroller=dom}>
                                    <ul className="fl nav_tab" ref={dom=>this.tabULRef=dom} style={{marginLeft:this.state.tabsUIMarginLeft}}>
                                        {
                                            this.state.navTabArr.map((item,index)=> (
                                                <li key={item.url} className={this.state.navTabArrCurrent===index?'active':''}>
                                                    <Link
                                                        onClick={()=>{this.navTabClick(item,index)}}
                                                        className="navLink"
                                                        to={`/${item.url}`}
                                                        replace={item.url === this.state.current}
                                                    >{item.name}</Link>
                                                    {
                                                        item.url===allMenu[0].url ? '' : <a className="nav_tab-close" href="javascript:;" onClick={()=>{this.navTabClose(item.url)}}></a>
                                                    }
                                                </li>
                                            ))
                                        }
                                    </ul>
                                </div>
                                <div className={['fl','main_con_head_arrows',this.state.arrowsVisibility?'showen':'hidden'].join(' ')}>
                                    <a className={['fl',this.state.arrowLeftDisabled?'disabled':''].join(' ')} href="javascript:;" onClick={()=>this.doTabsScroll('left')}>
                                        <span className="tico tico_arrow2_l"></span>
                                    </a>
                                    <a className={['fl',this.state.arrowRightDisabled?'disabled':''].join(' ')} href="javascript:;" onClick={()=>this.doTabsScroll('right')}>
                                        <span className="tico tico_arrow2_r"></span>
                                    </a>
                                </div>
                                <div className="fr main_con_head_other">
                                    <Popover style={{width:160}} placement="bottomRight" content={moreNav} trigger="hover">
                                        <i></i>
                                    </Popover>
                                </div>
                            </div>

                            <Contents regTab={this.menuItemClick} forIndex={this.state.forIndex} fFlagFn={this.fFlagFn.bind(this)} fFlag={this.state.fFlag}  />

                        </div>

                    </div>

                    <Confirm centered={true}
                             title="提示"
                             closable={true}
                             visible={this.state.loginOutVisible}
                             onOk={this.loginOutOk.bind(this)}
                             onCancel={()=>this.setState({loginOutVisible:false})}
                             content="确认退出系统？"
                             okText="确 定"/>

                </div>
            </LocaleProvider>
        )
    }
}