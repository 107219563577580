import React from 'react'
import {filterMenuItem} from 'utils/menu'
import {openWinAuto} from '../../utils/myOpt';
import {Modal, message, List, Avatar, Popconfirm, Link} from 'antd'
import { ajax } from 'utils'
import '../../container/reset_antd.less'

const pageSize = 10

export default class MyMsg extends React.Component {
    constructor(props){
        super(props)

        this.state = {
            visible:{status: false},
            loading: false,
            data: [],
            total: 0,
            page: 1,
        };
    }

    componentDidMount() {
        const {index} = this.props
        this.props.regTab(filterMenuItem(index))

        this.getMessage()
    }

    gotoDetail = (_url) => {
        openWinAuto(_url,'linkJumb',window.screen.availWidth,window.screen.availHeight,0,0)
    }

    getMessage = (page = 1) => {
        this.setState({ loading: true })

        ajax.fetchJson('getMessageList', 'get', {offset: (page-1) * pageSize, limit: pageSize}).then(res => {
            this.setState({ loading: false })

            if(res && res.code == 200){
                for(let i=0; i<res.data.length; i++){
                    let content = res.data[i].content

                    let _content = content.replace(/(.+)(<span\s+link=")(.+)([\u67e5][\u770b][\u8be6][\u60c5]<\/span>)/g,
                        function($0, $1, $2, $3, $4){
                            let route = '/#/' + $3.replace('"', '')

                            let arr=$3.replace('"', '').split("myclass");
                            console.log(route)
                            // console.log($1 + '<a href='+ route + '查看详情</a>')
                            return $1 + '<a href='+ route + '查看详情</a>'
                            // openWinAuto(route,'linkJumb',window.screen.availWidth,window.screen.availHeight,0,0)
                            // return $1 + `<a onclick="openWinAuto('${arr[0]}','linkJumb',window.screen.availWidth,window.screen.availHeight,0,0)" myclass${arr[1]}查看详情</a>`

                            // return $1 + `<a onClick=gotoDetail('${arr[0].replace(' ', '')}') myclass${arr[1]}查看详情</a>`
                    })

                    res.data[i].content = _content

                    let userImg = res.data[i].pushUserImg
                    if(userImg){
                        if(!(userImg.indexOf('http')===0 || userImg.indexOf('//')===0)){
                            if(userImg.indexOf('/')===0) userImg = '/rootdata' + userImg
                            else userImg = '/rootdata/' + userImg
                            userImg = ajax.getUrl(userImg,'com')
                        }
                    }

                    let img = res.data[i].pushUserImg ? userImg : require('../../images/fface.png');
                    res.data[i].pushUserImg = img
                }
                this.setState({ data: res.data, total: res.count })
            }else{
                message.error(res && res.msg)
            }
        }, ()=>{
            message.error('服务异常！')
            this.setState({ loading: false })
        })
    }

    okdel = () => {
        let that = this, rid = this.state.visible.id;
        this.setState({ loading: true })
        ajax.fetchJson(`delMessage/${rid}`, 'get', {}).then((res)=>{
            that.setState({ loading: false })
            if(res && res.code == 200){
                message.success(res.msg)
                that.getMessage()
                this.setState({visible:{ status: false }})
            }else{
                message.error(res && res.msg)
            }
        }, ()=>{
            message.error('服务异常！')
            that.setState({ loading: false })
        })
    }

    canceldel = () => {
        this.setState({visible:{ status: false }})
    }

    confirmDel = (record) => {
        // console.log('删除====', record)
        this.setState({visible:{ status: true, id: record.id }})
    }

    render() {
        const { loading, data, total } = this.state;

        return (
            <div>

                <Modal title="确认要删除当前消息吗" visible={this.state.visible.status} onOk={this.okdel} onCancel={this.canceldel} okText="确认" cancelText="取消">
                    删除将不可恢复
                </Modal>

                <h5 className="title">我的消息</h5>
                <div className="page-pannel clearfix">
                    <h6 className="list-total">共<span>{ total }</span>条</h6>

                    <List
                        className="my-msg"
                        loading={loading}
                        itemLayout="horizontal"
                        pagination={{
                            onChange: (page) => {
                                this.setState({ page })
                                this.getMessage(page)
                            },
                            current: this.state.page,
                            total: this.state.total,
                            pageSize: pageSize,
                            showQuickJumper: true
                        }}
                        dataSource={data}
                        renderItem={item => (
                            <List.Item actions={
                                [
                                    <div className="myMsg-ctr" key={ item.id }>
                                        <div>{ item.pushTime }</div>
                                        {/*<Popconfirm
                                            title="您确定要删除这条消息吗?"
                                            onConfirm={ this.confirmDel.bind(this, item) }
                                            okText="确定"
                                            cancelText="取消"
                                            document={ () => document.getElementById('delButton-'+item.id) }
                                    >
                                            <div className="del" id={'delButton-'+item.id}>del</div>
                                        </Popconfirm>*/}
                                        <div className="del" onClick={ this.confirmDel.bind(this, item) }>del</div>
                                    </div>
                                ]
                            }>
                                <List.Item.Meta
                                    avatar={<Avatar size={48} src={ item.pushUserImg } />}
                                    title={ <div dangerouslySetInnerHTML={{ __html: item.title }}></div> }
                                    description={<div dangerouslySetInnerHTML={{ __html: item.content }}></div>}
                                />
                            </List.Item>
                        )}
                    />
                </div>
            </div>
        )
    }
}
