import fetch from 'dva/fetch';
import LoginInfo from './loginInfo';

function parseJSON(response) {
  return response.json();
}

function checkStatus(response) {
  if (response.status >= 200 && response.status < 300) {
    return response;
  }
  const error = new Error(response.statusText);
  error.response = response;
  throw error;
}

/**
 * Requests a URL, returning a promise.
 *
 * @param  {string} url       The URL we want to request
 * @param  {object} [options] The options we want to pass to "fetch"
 * @return {object}           An object containing either "data" or "err"
 */
export default function request(url, options) {
  const loginInfo = LoginInfo.getInstance();
  let nOptions = options || {}, headers = nOptions.headers || {};
  const nHeaders = {
      ...headers,
      "token" : loginInfo.token
  };
  nOptions.headers = nHeaders;
  return fetch(url, nOptions)
    .then(checkStatus)
    .then(parseJSON)
    .then((data, headers) => ({data, headers}) )
    .catch(err => {throw new Error(err)});
}

export const parseFormData = (formData) => {

};
