import React from 'react'
import {filterMenuItem} from 'utils/menu'
import {message, Table, Icon} from 'antd'
import {Link} from 'dva/router';
import { ajax } from 'utils'
import emitter from 'utils/events'
import AddFamiliarCar from '../../components/addFamiliarCar/addFamiliarCar'
import '../../container/reset_antd.less'
import tips from 'components/tips'

class FamiliarCarHistory extends React.Component {
    constructor(props){
        super(props)

        this.state = {
            historyData: [],
            page: 1,
            currentPersonal: {},
        }
    }

    componentDidMount() {
        console.log(this.props.match)
        let params = this.props.match.params
        let record = {
            userName: decodeURIComponent(params.name),
            cellphone: params.phone
        }

        if(JSON.stringify(record) != ''){
            this.cooperationDetail(record)
        }else{
            window.location.hash = '/familiarCar'
        }
    }

    cooperationDetail = (record) => {
        this.setState({ currentPersonal: record })

        let cellphone = record.cellphone
        ajax.fetchJson(`historyOrder/${cellphone}`, 'get', {}).then((res)=>{
            if(res && res.code == 200){
                this.setState({ historyVisible: true, historyData: res && res.data })
            }else{
                message.error(res && res.msg)
            }
        }, ()=>{
            message.error('服务异常！')
        })
    }

    gotoDetail = (record) => {
        ajax.fetchJson(`bookDetail/${record.id}`, 'get').then((res) => {
            if(res && res.code == 200){
                window.location.hash = `/books/exact/all/${record.id}`
            }else{
                message.error(res && res.msg)
            }
        }, () => {
            message.error('服务异常！')
        })
    }

    render() {
        const historyColumns = [
            {
                title: '路线',
                key: 'route',
                width: 300,
                render: (text, record) => {
                    return (<span>{ (record.startPoint || '') + '--' + (record.destPoint || '') }</span>)
                }
            },
            {
                title: '货物信息',
                dataIndex: 'taskContent',
                width: 300
            },
            {
                title: '运价(现金＋油卡)',
                key: 'price',
                width: 180,
                render: (text, record) => {
                    return (<span>{ (record.cashPrice || 0) + '+' + (record.oilPrice || 0) }</span>)
                }
            },
            {
                title: '合作时间',
                dataIndex: 'ctime',
                width: 130
            },
            {
                title: '操作',
                key: 'operation',
                width: 100,
                // render: (text, record) => { return (<span><Link to={`/books/all/${record.id}`}>详情</Link></span>) }
                render: (text, record) => { return (<span><a onClick={ this.gotoDetail.bind(this, record) }>详情</a></span>) }
            }
        ]

        const {
            historyData,
            currentPersonal,
        } = this.state

        return (
            <div style={{padding: 30, height: '100%', overflowY: 'auto'}}>
                <h6 className="history-name">当前所在位置：熟车 <Icon type="right" theme="outlined" /> <span>合作历史</span></h6>
                <div className="page-pannel clearfix">
                    <h6 className="familiar-history">与车主 <strong>{currentPersonal.userName}（{currentPersonal.cellphone}）</strong> 合作历史</h6>
                    <Table
                        className='page-pannel-list'
                        columns={ historyColumns }
                        dataSource={ historyData }
                        rowKey={record => record.tsOrderNo}
                    />
                </div>
            </div>
        )
    }
}

export default FamiliarCarHistory
