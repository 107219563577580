
const cities = [{
    "name": "上海市",
    "match": "上海市|SHS",
    "py": "SHS",
    "provinc": "上海",
    "city": "上海市",
    "area": "上海市"
}, {
    "name": "上海黄浦区",
    "match": "上海市黄浦区|SHSHPQ",
    "py": "HPQ",
    "provinc": "上海",
    "city": "上海市",
    "area": "黄浦区"
}, {
    "name": "上海卢湾区",
    "match": "上海市卢湾区|SHSLWQ",
    "py": "LWQ",
    "provinc": "上海",
    "city": "上海市",
    "area": "卢湾区"
}, {
    "name": "上海徐汇区",
    "match": "上海市徐汇区|SHSXHQ",
    "py": "XHQ",
    "provinc": "上海",
    "city": "上海市",
    "area": "徐汇区"
}, {
    "name": "上海长宁区",
    "match": "上海市长宁区|SHSCNQ",
    "py": "CNQ",
    "provinc": "上海",
    "city": "上海市",
    "area": "长宁区"
}, {
    "name": "上海静安区",
    "match": "上海市静安区|SHSJAQ",
    "py": "JAQ",
    "provinc": "上海",
    "city": "上海市",
    "area": "静安区"
}, {
    "name": "上海普陀区",
    "match": "上海市普陀区|SHSPTQ",
    "py": "PTQ",
    "provinc": "上海",
    "city": "上海市",
    "area": "普陀区"
},/* {
    "name": "上海闸北区",
    "match": "上海市闸北区|SHSZBQ",
    "py": "ZBQ",
    "provinc": "上海",
    "city": "上海市",
    "area": "闸北区"
},*/ {
    "name": "上海虹口区",
    "match": "上海市虹口区|SHSHKQ",
    "py": "HKQ",
    "provinc": "上海",
    "city": "上海市",
    "area": "虹口区"
}, {
    "name": "上海杨浦区",
    "match": "上海市杨浦区|SHSYPQ",
    "py": "YPQ",
    "provinc": "上海",
    "city": "上海市",
    "area": "杨浦区"
}, {
    "name": "上海闵行区",
    "match": "上海市闵行区|SHSMHQ",
    "py": "MHQ",
    "provinc": "上海",
    "city": "上海市",
    "area": "闵行区"
}, {
    "name": "上海宝山区",
    "match": "上海市宝山区|SHSBSQ",
    "py": "BSQ",
    "provinc": "上海",
    "city": "上海市",
    "area": "宝山区"
}, {
    "name": "上海嘉定区",
    "match": "上海市嘉定区|SHSJDQ",
    "py": "JDQ",
    "provinc": "上海",
    "city": "上海市",
    "area": "嘉定区"
}, {
    "name": "上海浦东新区",
    "match": "上海市浦东新区|SHSPDXQ",
    "py": "PDXQ",
    "provinc": "上海",
    "city": "上海市",
    "area": "浦东新区"
}, {
    "name": "上海金山区",
    "match": "上海市金山区|SHSJSQ",
    "py": "JSQ",
    "provinc": "上海",
    "city": "上海市",
    "area": "金山区"
}, {
    "name": "上海松江区",
    "match": "上海市松江区|SHSSJQ",
    "py": "SJQ",
    "provinc": "上海",
    "city": "上海市",
    "area": "松江区"
}, {
    "name": "上海青浦区",
    "match": "上海市青浦区|SHSQPQ",
    "py": "QPQ",
    "provinc": "上海",
    "city": "上海市",
    "area": "青浦区"
}, {
    "name": "上海南汇区",
    "match": "上海市南汇区|SHSNHQ",
    "py": "NHQ",
    "provinc": "上海",
    "city": "上海市",
    "area": "南汇区"
}, {
    "name": "上海奉贤区",
    "match": "上海市奉贤区|SHSFXQ",
    "py": "FXQ",
    "provinc": "上海",
    "city": "上海市",
    "area": "奉贤区"
}, {
    "name": "上海崇明区",
    "match": "上海市崇明区|SHSCMQ",
    "py": "CMQ",
    "provinc": "上海",
    "city": "上海市",
    "area": "崇明区"
}, {
    "name": "云南西双版纳州",
    "match": "云南西双版纳州|YNXSBNZZZ",
    "py": "XSBNZZZ",
    "provinc": "云南",
    "city": "西双版纳州",
    "area": "西双版纳州"
}, {
    "name": "云南西双版纳州景洪市",
    "match": "云南西双版纳州景洪市|YNXSBNZZZJHS",
    "py": "JHS",
    "provinc": "云南",
    "city": "西双版纳州",
    "area": "景洪市"
}, {
    "name": "云南西双版纳州勐海县",
    "match": "云南西双版纳州勐海县|YNXSBNZZZMHX",
    "py": "MHX",
    "provinc": "云南",
    "city": "西双版纳州",
    "area": "勐海县"
}, {
    "name": "云南西双版纳州勐腊县",
    "match": "云南西双版纳州勐腊县|YNXSBNZZZMLX",
    "py": "MLX",
    "provinc": "云南",
    "city": "西双版纳州",
    "area": "勐腊县"
}, {
    "name": "云南德宏州",
    "match": "云南德宏州|YNDHZZZ",
    "py": "DHZZZ",
    "provinc": "云南",
    "city": "德宏州",
    "area": "德宏州"
}, {
    "name": "云南德宏州瑞丽市",
    "match": "云南德宏州瑞丽市|YNDHZZZRLS",
    "py": "RLS",
    "provinc": "云南",
    "city": "德宏州",
    "area": "瑞丽市"
}, {
    "name": "云南德宏州芒市",
    "match": "云南德宏州芒市|YNDHZZZLXS",
    "py": "LXS",
    "provinc": "云南",
    "city": "德宏州",
    "area": "芒市"
}, {
    "name": "云南德宏州梁河县",
    "match": "云南德宏州梁河县|YNDHZZZLHX",
    "py": "LHX",
    "provinc": "云南",
    "city": "德宏州",
    "area": "梁河县"
}, {
    "name": "云南德宏州盈江县",
    "match": "云南德宏州盈江县|YNDHZZZYJX",
    "py": "YJX",
    "provinc": "云南",
    "city": "德宏州",
    "area": "盈江县"
}, {
    "name": "云南德宏州陇川县",
    "match": "云南德宏州陇川县|YNDHZZZLCX",
    "py": "LCX",
    "provinc": "云南",
    "city": "德宏州",
    "area": "陇川县"
}, {
    "name": "云南昭通市",
    "match": "云南昭通市|YNZTS",
    "py": "ZTS",
    "provinc": "云南",
    "city": "昭通市",
    "area": "昭通市"
}, {
    "name": "云南昭通市昭阳区",
    "match": "云南昭通市昭阳区|YNZTSZYQ",
    "py": "ZYQ",
    "provinc": "云南",
    "city": "昭通市",
    "area": "昭阳区"
}, {
    "name": "云南昭通市鲁甸县",
    "match": "云南昭通市鲁甸县|YNZTSLDX",
    "py": "LDX",
    "provinc": "云南",
    "city": "昭通市",
    "area": "鲁甸县"
}, {
    "name": "云南昭通市巧家县",
    "match": "云南昭通市巧家县|YNZTSQJX",
    "py": "QJX",
    "provinc": "云南",
    "city": "昭通市",
    "area": "巧家县"
}, {
    "name": "云南昭通市盐津县",
    "match": "云南昭通市盐津县|YNZTSYJX",
    "py": "YJX",
    "provinc": "云南",
    "city": "昭通市",
    "area": "盐津县"
}, {
    "name": "云南昭通市大关县",
    "match": "云南昭通市大关县|YNZTSDGX",
    "py": "DGX",
    "provinc": "云南",
    "city": "昭通市",
    "area": "大关县"
}, {
    "name": "云南昭通市永善县",
    "match": "云南昭通市永善县|YNZTSYSX",
    "py": "YSX",
    "provinc": "云南",
    "city": "昭通市",
    "area": "永善县"
}, {
    "name": "云南昭通市绥江县",
    "match": "云南昭通市绥江县|YNZTSSJX",
    "py": "SJX",
    "provinc": "云南",
    "city": "昭通市",
    "area": "绥江县"
}, {
    "name": "云南昭通市镇雄县",
    "match": "云南昭通市镇雄县|YNZTSZXX",
    "py": "ZXX",
    "provinc": "云南",
    "city": "昭通市",
    "area": "镇雄县"
}, {
    "name": "云南昭通市彝良县",
    "match": "云南昭通市彝良县|YNZTSYLX",
    "py": "YLX",
    "provinc": "云南",
    "city": "昭通市",
    "area": "彝良县"
}, {
    "name": "云南昭通市威信县",
    "match": "云南昭通市威信县|YNZTSWXX",
    "py": "WXX",
    "provinc": "云南",
    "city": "昭通市",
    "area": "威信县"
}, {
    "name": "云南昭通市水富县",
    "match": "云南昭通市水富县|YNZTSSFX",
    "py": "SFX",
    "provinc": "云南",
    "city": "昭通市",
    "area": "水富县"
}, {
    "name": "云南昆明市",
    "match": "云南昆明市|YNKMS",
    "py": "KMS",
    "provinc": "云南",
    "city": "昆明市",
    "area": "昆明市"
}, {
    "name": "云南昆明市五华区",
    "match": "云南昆明市五华区|YNKMSWHQ",
    "py": "WHQ",
    "provinc": "云南",
    "city": "昆明市",
    "area": "五华区"
}, {
    "name": "云南昆明市盘龙区",
    "match": "云南昆明市盘龙区|YNKMSPLQ",
    "py": "PLQ",
    "provinc": "云南",
    "city": "昆明市",
    "area": "盘龙区"
}, {
    "name": "云南昆明市官渡区",
    "match": "云南昆明市官渡区|YNKMSGDQ",
    "py": "GDQ",
    "provinc": "云南",
    "city": "昆明市",
    "area": "官渡区"
}, {
    "name": "云南昆明市西山区",
    "match": "云南昆明市西山区|YNKMSXSQ",
    "py": "XSQ",
    "provinc": "云南",
    "city": "昆明市",
    "area": "西山区"
}, {
    "name": "云南昆明市东川区",
    "match": "云南昆明市东川区|YNKMSDCQ",
    "py": "DCQ",
    "provinc": "云南",
    "city": "昆明市",
    "area": "东川区"
}, {
    "name": "云南昆明市呈贡县",
    "match": "云南昆明市呈贡县|YNKMSCGX",
    "py": "CGX",
    "provinc": "云南",
    "city": "昆明市",
    "area": "呈贡县"
}, {
    "name": "云南昆明市晋宁区",
    "match": "云南昆明市晋宁区|YNKMSJNQ",
    "py": "JNQ",
    "provinc": "云南",
    "city": "昆明市",
    "area": "晋宁区"
}, {
    "name": "云南昆明市富民县",
    "match": "云南昆明市富民县|YNKMSFMX",
    "py": "FMX",
    "provinc": "云南",
    "city": "昆明市",
    "area": "富民县"
}, {
    "name": "云南昆明市宜良县",
    "match": "云南昆明市宜良县|YNKMSYLX",
    "py": "YLX",
    "provinc": "云南",
    "city": "昆明市",
    "area": "宜良县"
}, {
    "name": "云南昆明市石林县",
    "match": "云南昆明市石林县|YNKMSSLZZX",
    "py": "SLZZX",
    "provinc": "云南",
    "city": "昆明市",
    "area": "石林县"
}, {
    "name": "云南昆明市嵩明县",
    "match": "云南昆明市嵩明县|YNKMSSMX",
    "py": "SMX",
    "provinc": "云南",
    "city": "昆明市",
    "area": "嵩明县"
}, {
    "name": "云南昆明市禄劝县",
    "match": "云南昆明市禄劝县|YNKMSLQZZX",
    "py": "LQZZX",
    "provinc": "云南",
    "city": "昆明市",
    "area": "禄劝县"
}, {
    "name": "云南昆明市寻甸县",
    "match": "云南昆明市寻甸县|YNKMSXDZZX",
    "py": "XDZZX",
    "provinc": "云南",
    "city": "昆明市",
    "area": "寻甸县"
}, {
    "name": "云南昆明市安宁市",
    "match": "云南昆明市安宁市|YNKMSANS",
    "py": "ANS",
    "provinc": "云南",
    "city": "昆明市",
    "area": "安宁市"
}, {
    "name": "云南大理州",
    "match": "云南大理州|YNDLZZZ",
    "py": "DLZZZ",
    "provinc": "云南",
    "city": "大理州",
    "area": "大理州"
}, {
    "name": "云南大理州大理市",
    "match": "云南大理州大理市|YNDLZZZDLS",
    "py": "DLS",
    "provinc": "云南",
    "city": "大理州",
    "area": "大理市"
}, {
    "name": "云南大理州漾濞县",
    "match": "云南大理州漾濞县|YNDLZZZYBZZX",
    "py": "YBZZX",
    "provinc": "云南",
    "city": "大理州",
    "area": "漾濞县"
}, {
    "name": "云南大理州祥云县",
    "match": "云南大理州祥云县|YNDLZZZXYX",
    "py": "XYX",
    "provinc": "云南",
    "city": "大理州",
    "area": "祥云县"
}, {
    "name": "云南大理州宾川县",
    "match": "云南大理州宾川县|YNDLZZZBCX",
    "py": "BCX",
    "provinc": "云南",
    "city": "大理州",
    "area": "宾川县"
}, {
    "name": "云南大理州弥渡县",
    "match": "云南大理州弥渡县|YNDLZZZMDX",
    "py": "MDX",
    "provinc": "云南",
    "city": "大理州",
    "area": "弥渡县"
}, {
    "name": "云南大理州南涧县",
    "match": "云南大理州南涧县|YNDLZZZNJZZX",
    "py": "NJZZX",
    "provinc": "云南",
    "city": "大理州",
    "area": "南涧县"
}, {
    "name": "云南大理州巍山县",
    "match": "云南大理州巍山县|YNDLZZZWSZZX",
    "py": "WSZZX",
    "provinc": "云南",
    "city": "大理州",
    "area": "巍山县"
}, {
    "name": "云南大理州永平县",
    "match": "云南大理州永平县|YNDLZZZYPX",
    "py": "YPX",
    "provinc": "云南",
    "city": "大理州",
    "area": "永平县"
}, {
    "name": "云南大理州云龙县",
    "match": "云南大理州云龙县|YNDLZZZYLX",
    "py": "YLX",
    "provinc": "云南",
    "city": "大理州",
    "area": "云龙县"
}, {
    "name": "云南大理州洱源县",
    "match": "云南大理州洱源县|YNDLZZZEYX",
    "py": "EYX",
    "provinc": "云南",
    "city": "大理州",
    "area": "洱源县"
}, {
    "name": "云南大理州剑川县",
    "match": "云南大理州剑川县|YNDLZZZJCX",
    "py": "JCX",
    "provinc": "云南",
    "city": "大理州",
    "area": "剑川县"
}, {
    "name": "云南大理州鹤庆县",
    "match": "云南大理州鹤庆县|YNDLZZZHQX",
    "py": "HQX",
    "provinc": "云南",
    "city": "大理州",
    "area": "鹤庆县"
}, {
    "name": "云南红河州",
    "match": "云南红河州|YNHHZZZ",
    "py": "HHZZZ",
    "provinc": "云南",
    "city": "红河州",
    "area": "红河州"
}, {
    "name": "云南红河州个旧市",
    "match": "云南红河州个旧市|YNHHZZZGJS",
    "py": "GJS",
    "provinc": "云南",
    "city": "红河州",
    "area": "个旧市"
}, {
    "name": "云南红河州开远市",
    "match": "云南红河州开远市|YNHHZZZKYS",
    "py": "KYS",
    "provinc": "云南",
    "city": "红河州",
    "area": "开远市"
}, {
    "name": "云南红河州蒙自市",
    "match": "云南红河州蒙自市|YNHHZZZMZS",
    "py": "MZS",
    "provinc": "云南",
    "city": "红河州",
    "area": "蒙自市"
}, {
    "name": "云南红河州屏边县",
    "match": "云南红河州屏边县|YNHHZZZPBZZX",
    "py": "PBZZX",
    "provinc": "云南",
    "city": "红河州",
    "area": "屏边县"
}, {
    "name": "云南红河州建水县",
    "match": "云南红河州建水县|YNHHZZZJSX",
    "py": "JSX",
    "provinc": "云南",
    "city": "红河州",
    "area": "建水县"
}, {
    "name": "云南红河州石屏县",
    "match": "云南红河州石屏县|YNHHZZZSPX",
    "py": "SPX",
    "provinc": "云南",
    "city": "红河州",
    "area": "石屏县"
}, {
    "name": "云南红河州弥勒市",
    "match": "云南红河州弥勒市|YNHHZZZMLS",
    "py": "MLS",
    "provinc": "云南",
    "city": "红河州",
    "area": "弥勒市"
}, {
    "name": "云南红河州泸西县",
    "match": "云南红河州泸西县|YNHHZZZLXX",
    "py": "LXX",
    "provinc": "云南",
    "city": "红河州",
    "area": "泸西县"
}, {
    "name": "云南红河州元阳县",
    "match": "云南红河州元阳县|YNHHZZZYYX",
    "py": "YYX",
    "provinc": "云南",
    "city": "红河州",
    "area": "元阳县"
}, {
    "name": "云南红河州红河县",
    "match": "云南红河州红河县|YNHHZZZHHX",
    "py": "HHX",
    "provinc": "云南",
    "city": "红河州",
    "area": "红河县"
}, {
    "name": "云南红河州金平县",
    "match": "云南红河州金平县|YNHHZZZJPZZX",
    "py": "JPZZX",
    "provinc": "云南",
    "city": "红河州",
    "area": "金平县"
}, {
    "name": "云南红河州绿春县",
    "match": "云南红河州绿春县|YNHHZZZLCX",
    "py": "LCX",
    "provinc": "云南",
    "city": "红河州",
    "area": "绿春县"
}, {
    "name": "云南红河州河口县",
    "match": "云南红河州河口县|YNHHZZZHKZZX",
    "py": "HKZZX",
    "provinc": "云南",
    "city": "红河州",
    "area": "河口县"
}, {
    "name": "云南曲靖市",
    "match": "云南曲靖市|YNQJS",
    "py": "QJS",
    "provinc": "云南",
    "city": "曲靖市",
    "area": "曲靖市"
}, {
    "name": "云南曲靖市麒麟区",
    "match": "云南曲靖市麒麟区|YNQJSQLQ",
    "py": "QLQ",
    "provinc": "云南",
    "city": "曲靖市",
    "area": "麒麟区"
}, {
    "name": "云南曲靖市马龙县",
    "match": "云南曲靖市马龙县|YNQJSMLX",
    "py": "MLX",
    "provinc": "云南",
    "city": "曲靖市",
    "area": "马龙县"
}, {
    "name": "云南曲靖市陆良县",
    "match": "云南曲靖市陆良县|YNQJSLLX",
    "py": "LLX",
    "provinc": "云南",
    "city": "曲靖市",
    "area": "陆良县"
}, {
    "name": "云南曲靖市师宗县",
    "match": "云南曲靖市师宗县|YNQJSSZX",
    "py": "SZX",
    "provinc": "云南",
    "city": "曲靖市",
    "area": "师宗县"
}, {
    "name": "云南曲靖市罗平县",
    "match": "云南曲靖市罗平县|YNQJSLPX",
    "py": "LPX",
    "provinc": "云南",
    "city": "曲靖市",
    "area": "罗平县"
}, {
    "name": "云南曲靖市富源县",
    "match": "云南曲靖市富源县|YNQJSFYX",
    "py": "FYX",
    "provinc": "云南",
    "city": "曲靖市",
    "area": "富源县"
}, {
    "name": "云南曲靖市会泽县",
    "match": "云南曲靖市会泽县|YNQJSHZX",
    "py": "HZX",
    "provinc": "云南",
    "city": "曲靖市",
    "area": "会泽县"
}, {
    "name": "云南曲靖市沾益区",
    "match": "云南曲靖市沾益区|YNQJSZYQ",
    "py": "ZYQ",
    "provinc": "云南",
    "city": "曲靖市",
    "area": "沾益区"
}, {
    "name": "云南曲靖市宣威市",
    "match": "云南曲靖市宣威市|YNQJSXWS",
    "py": "XWS",
    "provinc": "云南",
    "city": "曲靖市",
    "area": "宣威市"
}, {
    "name": "云南保山市",
    "match": "云南保山市|YNBSS",
    "py": "BSS",
    "provinc": "云南",
    "city": "保山市",
    "area": "保山市"
}, {
    "name": "云南保山市隆阳区",
    "match": "云南保山市隆阳区|YNBSSLYQ",
    "py": "LYQ",
    "provinc": "云南",
    "city": "保山市",
    "area": "隆阳区"
}, {
    "name": "云南保山市施甸县",
    "match": "云南保山市施甸县|YNBSSSDX",
    "py": "SDX",
    "provinc": "云南",
    "city": "保山市",
    "area": "施甸县"
}, {
    "name": "云南保山市腾冲市",
    "match": "云南保山市腾冲市|YNBSSTCS",
    "py": "TCS",
    "provinc": "云南",
    "city": "保山市",
    "area": "腾冲市"
}, {
    "name": "云南保山市龙陵县",
    "match": "云南保山市龙陵县|YNBSSLLX",
    "py": "LLX",
    "provinc": "云南",
    "city": "保山市",
    "area": "龙陵县"
}, {
    "name": "云南保山市昌宁县",
    "match": "云南保山市昌宁县|YNBSSCNX",
    "py": "CNX",
    "provinc": "云南",
    "city": "保山市",
    "area": "昌宁县"
}, {
    "name": "云南文山州",
    "match": "云南文山州|YNWSZZZ",
    "py": "WSZZZ",
    "provinc": "云南",
    "city": "文山州",
    "area": "文山州"
}, {
    "name": "云南文山州文山市",
    "match": "云南文山州文山市|YNWSZZZWSS",
    "py": "WSS",
    "provinc": "云南",
    "city": "文山州",
    "area": "文山市"
}, {
    "name": "云南文山州砚山县",
    "match": "云南文山州砚山县|YNWSZZZYSX",
    "py": "YSX",
    "provinc": "云南",
    "city": "文山州",
    "area": "砚山县"
}, {
    "name": "云南文山州西畴县",
    "match": "云南文山州西畴县|YNWSZZZXCX",
    "py": "XCX",
    "provinc": "云南",
    "city": "文山州",
    "area": "西畴县"
}, {
    "name": "云南文山州麻栗坡县",
    "match": "云南文山州麻栗坡县|YNWSZZZMLPX",
    "py": "MLPX",
    "provinc": "云南",
    "city": "文山州",
    "area": "麻栗坡县"
}, {
    "name": "云南文山州马关县",
    "match": "云南文山州马关县|YNWSZZZMGX",
    "py": "MGX",
    "provinc": "云南",
    "city": "文山州",
    "area": "马关县"
}, {
    "name": "云南文山州丘北县",
    "match": "云南文山州丘北县|YNWSZZZQBX",
    "py": "QBX",
    "provinc": "云南",
    "city": "文山州",
    "area": "丘北县"
}, {
    "name": "云南文山州广南县",
    "match": "云南文山州广南县|YNWSZZZGNX",
    "py": "GNX",
    "provinc": "云南",
    "city": "文山州",
    "area": "广南县"
}, {
    "name": "云南文山州富宁县",
    "match": "云南文山州富宁县|YNWSZZZFNX",
    "py": "FNX",
    "provinc": "云南",
    "city": "文山州",
    "area": "富宁县"
}, {
    "name": "云南玉溪市",
    "match": "云南玉溪市|YNYXS",
    "py": "YXS",
    "provinc": "云南",
    "city": "玉溪市",
    "area": "玉溪市"
}, {
    "name": "云南玉溪市红塔区",
    "match": "云南玉溪市红塔区|YNYXSHTQ",
    "py": "HTQ",
    "provinc": "云南",
    "city": "玉溪市",
    "area": "红塔区"
}, {
    "name": "云南玉溪市江川区",
    "match": "云南玉溪市江川区|YNYXSJCQ",
    "py": "JCQ",
    "provinc": "云南",
    "city": "玉溪市",
    "area": "江川区"
}, {
    "name": "云南玉溪市澄江县",
    "match": "云南玉溪市澄江县|YNYXSCJX",
    "py": "CJX",
    "provinc": "云南",
    "city": "玉溪市",
    "area": "澄江县"
}, {
    "name": "云南玉溪市通海县",
    "match": "云南玉溪市通海县|YNYXSTHX",
    "py": "THX",
    "provinc": "云南",
    "city": "玉溪市",
    "area": "通海县"
}, {
    "name": "云南玉溪市华宁县",
    "match": "云南玉溪市华宁县|YNYXSHNX",
    "py": "HNX",
    "provinc": "云南",
    "city": "玉溪市",
    "area": "华宁县"
}, {
    "name": "云南玉溪市易门县",
    "match": "云南玉溪市易门县|YNYXSYMX",
    "py": "YMX",
    "provinc": "云南",
    "city": "玉溪市",
    "area": "易门县"
}, {
    "name": "云南玉溪市峨山县",
    "match": "云南玉溪市峨山县|YNYXSESZZX",
    "py": "ESZZX",
    "provinc": "云南",
    "city": "玉溪市",
    "area": "峨山县"
}, {
    "name": "云南玉溪市新平县",
    "match": "云南玉溪市新平县|YNYXSXPZZX",
    "py": "XPZZX",
    "provinc": "云南",
    "city": "玉溪市",
    "area": "新平县"
}, {
    "name": "云南玉溪市元江县",
    "match": "云南玉溪市元江县|YNYXSYJZZX",
    "py": "YJZZX",
    "provinc": "云南",
    "city": "玉溪市",
    "area": "元江县"
}, {
    "name": "云南楚雄州",
    "match": "云南楚雄州|YNCXZZZ",
    "py": "CXZZZ",
    "provinc": "云南",
    "city": "楚雄州",
    "area": "楚雄州"
}, {
    "name": "云南楚雄州楚雄市",
    "match": "云南楚雄州楚雄市|YNCXZZZCXS",
    "py": "CXS",
    "provinc": "云南",
    "city": "楚雄州",
    "area": "楚雄市"
}, {
    "name": "云南楚雄州双柏县",
    "match": "云南楚雄州双柏县|YNCXZZZSBX",
    "py": "SBX",
    "provinc": "云南",
    "city": "楚雄州",
    "area": "双柏县"
}, {
    "name": "云南楚雄州牟定县",
    "match": "云南楚雄州牟定县|YNCXZZZMDX",
    "py": "MDX",
    "provinc": "云南",
    "city": "楚雄州",
    "area": "牟定县"
}, {
    "name": "云南楚雄州南华县",
    "match": "云南楚雄州南华县|YNCXZZZNHX",
    "py": "NHX",
    "provinc": "云南",
    "city": "楚雄州",
    "area": "南华县"
}, {
    "name": "云南楚雄州姚安县",
    "match": "云南楚雄州姚安县|YNCXZZZYAX",
    "py": "YAX",
    "provinc": "云南",
    "city": "楚雄州",
    "area": "姚安县"
}, {
    "name": "云南楚雄州大姚县",
    "match": "云南楚雄州大姚县|YNCXZZZDYX",
    "py": "DYX",
    "provinc": "云南",
    "city": "楚雄州",
    "area": "大姚县"
}, {
    "name": "云南楚雄州永仁县",
    "match": "云南楚雄州永仁县|YNCXZZZYRX",
    "py": "YRX",
    "provinc": "云南",
    "city": "楚雄州",
    "area": "永仁县"
}, {
    "name": "云南楚雄州元谋县",
    "match": "云南楚雄州元谋县|YNCXZZZYMX",
    "py": "YMX",
    "provinc": "云南",
    "city": "楚雄州",
    "area": "元谋县"
}, {
    "name": "云南楚雄州武定县",
    "match": "云南楚雄州武定县|YNCXZZZWDX",
    "py": "WDX",
    "provinc": "云南",
    "city": "楚雄州",
    "area": "武定县"
}, {
    "name": "云南楚雄州禄丰县",
    "match": "云南楚雄州禄丰县|YNCXZZZLFX",
    "py": "LFX",
    "provinc": "云南",
    "city": "楚雄州",
    "area": "禄丰县"
}, {
    "name": "云南普洱市",
    "match": "云南普洱市|YNPES",
    "py": "PES",
    "provinc": "云南",
    "city": "普洱市",
    "area": "普洱市"
}, {
    "name": "云南普洱市思茅区",
    "match": "云南普洱市思茅区|YNPESSMQ",
    "py": "SMQ",
    "provinc": "云南",
    "city": "普洱市",
    "area": "思茅区"
}, {
    "name": "云南普洱市宁洱县",
    "match": "云南普洱市宁洱县|YNPESNEZZX",
    "py": "NEZZX",
    "provinc": "云南",
    "city": "普洱市",
    "area": "宁洱县"
}, {
    "name": "云南普洱市墨江县",
    "match": "云南普洱市墨江县|YNPESMJZZX",
    "py": "MJZZX",
    "provinc": "云南",
    "city": "普洱市",
    "area": "墨江县"
}, {
    "name": "云南普洱市景东县",
    "match": "云南普洱市景东县|YNPESJDZZX",
    "py": "JDZZX",
    "provinc": "云南",
    "city": "普洱市",
    "area": "景东县"
}, {
    "name": "云南普洱市景谷县",
    "match": "云南普洱市景谷县|YNPESJGZZX",
    "py": "JGZZX",
    "provinc": "云南",
    "city": "普洱市",
    "area": "景谷县"
}, {
    "name": "云南普洱市镇沅县",
    "match": "云南普洱市镇沅县|YNPESZYZZX",
    "py": "ZYZZX",
    "provinc": "云南",
    "city": "普洱市",
    "area": "镇沅县"
}, {
    "name": "云南普洱市江城县",
    "match": "云南普洱市江城县|YNPESJCZZX",
    "py": "JCZZX",
    "provinc": "云南",
    "city": "普洱市",
    "area": "江城县"
}, {
    "name": "云南普洱市孟连县",
    "match": "云南普洱市孟连县|YNPESMLZZX",
    "py": "MLZZX",
    "provinc": "云南",
    "city": "普洱市",
    "area": "孟连县"
}, {
    "name": "云南普洱市澜沧县",
    "match": "云南普洱市澜沧县|YNPESLCZZX",
    "py": "LCZZX",
    "provinc": "云南",
    "city": "普洱市",
    "area": "澜沧县"
}, {
    "name": "云南普洱市西盟县",
    "match": "云南普洱市西盟县|YNPESXMZZX",
    "py": "XMZZX",
    "provinc": "云南",
    "city": "普洱市",
    "area": "西盟县"
}, {
    "name": "云南临沧市",
    "match": "云南临沧市|YNLCS",
    "py": "LCS",
    "provinc": "云南",
    "city": "临沧市",
    "area": "临沧市"
}, {
    "name": "云南临沧市临翔区",
    "match": "云南临沧市临翔区|YNLCSLXQ",
    "py": "LXQ",
    "provinc": "云南",
    "city": "临沧市",
    "area": "临翔区"
}, {
    "name": "云南临沧市凤庆县",
    "match": "云南临沧市凤庆县|YNLCSFQX",
    "py": "FQX",
    "provinc": "云南",
    "city": "临沧市",
    "area": "凤庆县"
}, {
    "name": "云南临沧市云县",
    "match": "云南临沧市云县|YNLCSYX",
    "py": "YX",
    "provinc": "云南",
    "city": "临沧市",
    "area": "云县"
}, {
    "name": "云南临沧市永德县",
    "match": "云南临沧市永德县|YNLCSYDX",
    "py": "YDX",
    "provinc": "云南",
    "city": "临沧市",
    "area": "永德县"
}, {
    "name": "云南临沧市镇康县",
    "match": "云南临沧市镇康县|YNLCSZKX",
    "py": "ZKX",
    "provinc": "云南",
    "city": "临沧市",
    "area": "镇康县"
}, {
    "name": "云南临沧市双江县",
    "match": "云南临沧市双江县|YNLCSSJZZX",
    "py": "SJZZX",
    "provinc": "云南",
    "city": "临沧市",
    "area": "双江县"
}, {
    "name": "云南临沧市耿马县",
    "match": "云南临沧市耿马县|YNLCSGMZZX",
    "py": "GMZZX",
    "provinc": "云南",
    "city": "临沧市",
    "area": "耿马县"
}, {
    "name": "云南临沧市沧源县",
    "match": "云南临沧市沧源县|YNLCSCYZZX",
    "py": "CYZZX",
    "provinc": "云南",
    "city": "临沧市",
    "area": "沧源县"
}, {
    "name": "云南怒江州",
    "match": "云南怒江州|YNNJZZZ",
    "py": "NJZZZ",
    "provinc": "云南",
    "city": "怒江州",
    "area": "怒江州"
}, {
    "name": "云南怒江州泸水市",
    "match": "云南怒江州泸水市|YNNJZZZLSS",
    "py": "LSS",
    "provinc": "云南",
    "city": "怒江州",
    "area": "泸水市"
}, {
    "name": "云南怒江州福贡县",
    "match": "云南怒江州福贡县|YNNJZZZFGX",
    "py": "FGX",
    "provinc": "云南",
    "city": "怒江州",
    "area": "福贡县"
}, {
    "name": "云南怒江州贡山县",
    "match": "云南怒江州贡山县|YNNJZZZGSZZX",
    "py": "GSZZX",
    "provinc": "云南",
    "city": "怒江州",
    "area": "贡山县"
}, {
    "name": "云南怒江州兰坪县",
    "match": "云南怒江州兰坪县|YNNJZZZLPZZX",
    "py": "LPZZX",
    "provinc": "云南",
    "city": "怒江州",
    "area": "兰坪县"
}, {
    "name": "云南迪庆州",
    "match": "云南迪庆州|YNDQZZZ",
    "py": "DQZZZ",
    "provinc": "云南",
    "city": "迪庆州",
    "area": "迪庆州"
}, {
    "name": "云南迪庆州香格里拉市",
    "match": "云南迪庆州香格里拉市|YNDQZZZXGLLS",
    "py": "XGLLS",
    "provinc": "云南",
    "city": "迪庆州",
    "area": "香格里拉市"
}, {
    "name": "云南迪庆州德钦县",
    "match": "云南迪庆州德钦县|YNDQZZZDQX",
    "py": "DQX",
    "provinc": "云南",
    "city": "迪庆州",
    "area": "德钦县"
}, {
    "name": "云南迪庆州维西县",
    "match": "云南迪庆州维西县|YNDQZZZWXZZX",
    "py": "WXZZX",
    "provinc": "云南",
    "city": "迪庆州",
    "area": "维西县"
}, {
    "name": "云南丽江市",
    "match": "云南丽江市|YNLJS",
    "py": "LJS",
    "provinc": "云南",
    "city": "丽江市",
    "area": "丽江市"
}, {
    "name": "云南丽江市古城区",
    "match": "云南丽江市古城区|YNLJSGCQ",
    "py": "GCQ",
    "provinc": "云南",
    "city": "丽江市",
    "area": "古城区"
}, {
    "name": "云南丽江市玉龙县",
    "match": "云南丽江市玉龙县|YNLJSYLZZX",
    "py": "YLZZX",
    "provinc": "云南",
    "city": "丽江市",
    "area": "玉龙县"
}, {
    "name": "云南丽江市永胜县",
    "match": "云南丽江市永胜县|YNLJSYSX",
    "py": "YSX",
    "provinc": "云南",
    "city": "丽江市",
    "area": "永胜县"
}, {
    "name": "云南丽江市华坪县",
    "match": "云南丽江市华坪县|YNLJSHPX",
    "py": "HPX",
    "provinc": "云南",
    "city": "丽江市",
    "area": "华坪县"
}, {
    "name": "云南丽江市宁蒗县",
    "match": "云南丽江市宁蒗县|YNLJSNLZZX",
    "py": "NLZZX",
    "provinc": "云南",
    "city": "丽江市",
    "area": "宁蒗县"
}, {
    "name": "内蒙古呼伦贝尔市",
    "match": "内蒙古呼伦贝尔市|NMGHLBES",
    "py": "HLBES",
    "provinc": "内蒙古",
    "city": "呼伦贝尔市",
    "area": "呼伦贝尔市"
},

    /*ADD*/
    {
        "name": "内蒙古呼伦贝尔市扎赉诺尔区",
        "match": "内蒙古呼伦贝尔市扎赉诺尔区|NMGHLBESZLNEQ",
        "py": "ZLNEQ",
        "provinc": "内蒙古",
        "city": "呼伦贝尔市",
        "area": "扎赉诺尔区"
    },
    /*ADD*/

    {
    "name": "内蒙古呼伦贝尔市海拉尔区",
    "match": "内蒙古呼伦贝尔市海拉尔区|NMGHLBESHLEQ",
    "py": "HLEQ",
    "provinc": "内蒙古",
    "city": "呼伦贝尔市",
    "area": "海拉尔区"
}, {
    "name": "内蒙古呼伦贝尔市阿荣旗",
    "match": "内蒙古呼伦贝尔市阿荣旗|NMGHLBESARQ",
    "py": "ARQ",
    "provinc": "内蒙古",
    "city": "呼伦贝尔市",
    "area": "阿荣旗"
}, {
    "name": "内蒙古呼伦贝尔市莫力达瓦旗",
    "match": "内蒙古呼伦贝尔市莫力达瓦旗|NMGHLBESMLDWZZQ",
    "py": "MLDWZZQ",
    "provinc": "内蒙古",
    "city": "呼伦贝尔市",
    "area": "莫力达瓦旗"
}, {
    "name": "内蒙古呼伦贝尔市鄂伦春旗",
    "match": "内蒙古呼伦贝尔市鄂伦春旗|NMGHLBESELCZZQ",
    "py": "ELCZZQ",
    "provinc": "内蒙古",
    "city": "呼伦贝尔市",
    "area": "鄂伦春旗"
}, {
    "name": "内蒙古呼伦贝尔市鄂温克族旗",
    "match": "内蒙古呼伦贝尔市鄂温克族旗|NMGHLBESEWKZZZQ",
    "py": "EWKZZZQ",
    "provinc": "内蒙古",
    "city": "呼伦贝尔市",
    "area": "鄂温克族旗"
}, {
    "name": "内蒙古呼伦贝尔市陈巴尔虎旗",
    "match": "内蒙古呼伦贝尔市陈巴尔虎旗|NMGHLBESCBEHQ",
    "py": "CBEHQ",
    "provinc": "内蒙古",
    "city": "呼伦贝尔市",
    "area": "陈巴尔虎旗"
}, {
    "name": "内蒙古呼伦贝尔市新巴尔虎左旗",
    "match": "内蒙古呼伦贝尔市新巴尔虎左旗|NMGHLBESXBEHZQ",
    "py": "XBEHZQ",
    "provinc": "内蒙古",
    "city": "呼伦贝尔市",
    "area": "新巴尔虎左旗"
}, {
    "name": "内蒙古呼伦贝尔市新巴尔虎右旗",
    "match": "内蒙古呼伦贝尔市新巴尔虎右旗|NMGHLBESXBEHYQ",
    "py": "XBEHYQ",
    "provinc": "内蒙古",
    "city": "呼伦贝尔市",
    "area": "新巴尔虎右旗"
}, {
    "name": "内蒙古呼伦贝尔市满洲里市",
    "match": "内蒙古呼伦贝尔市满洲里市|NMGHLBESMZLS",
    "py": "MZLS",
    "provinc": "内蒙古",
    "city": "呼伦贝尔市",
    "area": "满洲里市"
}, {
    "name": "内蒙古呼伦贝尔市牙克石市",
    "match": "内蒙古呼伦贝尔市牙克石市|NMGHLBESYKSS",
    "py": "YKSS",
    "provinc": "内蒙古",
    "city": "呼伦贝尔市",
    "area": "牙克石市"
}, {
    "name": "内蒙古呼伦贝尔市扎兰屯市",
    "match": "内蒙古呼伦贝尔市扎兰屯市|NMGHLBESZLTS",
    "py": "ZLTS",
    "provinc": "内蒙古",
    "city": "呼伦贝尔市",
    "area": "扎兰屯市"
}, {
    "name": "内蒙古呼伦贝尔市额尔古纳市",
    "match": "内蒙古呼伦贝尔市额尔古纳市|NMGHLBESEEGNS",
    "py": "EEGNS",
    "provinc": "内蒙古",
    "city": "呼伦贝尔市",
    "area": "额尔古纳市"
}, {
    "name": "内蒙古呼伦贝尔市根河市",
    "match": "内蒙古呼伦贝尔市根河市|NMGHLBESGHS",
    "py": "GHS",
    "provinc": "内蒙古",
    "city": "呼伦贝尔市",
    "area": "根河市"
}, {
    "name": "内蒙古呼和浩特市",
    "match": "内蒙古呼和浩特市|NMGHHHTS",
    "py": "HHHTS",
    "provinc": "内蒙古",
    "city": "呼和浩特市",
    "area": "呼和浩特市"
}, {
    "name": "内蒙古呼和浩特市新城区",
    "match": "内蒙古呼和浩特市新城区|NMGHHHTSXCQ",
    "py": "XCQ",
    "provinc": "内蒙古",
    "city": "呼和浩特市",
    "area": "新城区"
}, {
    "name": "内蒙古呼和浩特市回民区",
    "match": "内蒙古呼和浩特市回民区|NMGHHHTSHMQ",
    "py": "HMQ",
    "provinc": "内蒙古",
    "city": "呼和浩特市",
    "area": "回民区"
}, {
    "name": "内蒙古呼和浩特市玉泉区",
    "match": "内蒙古呼和浩特市玉泉区|NMGHHHTSYQQ",
    "py": "YQQ",
    "provinc": "内蒙古",
    "city": "呼和浩特市",
    "area": "玉泉区"
}, {
    "name": "内蒙古呼和浩特市赛罕区",
    "match": "内蒙古呼和浩特市赛罕区|NMGHHHTSSHQ",
    "py": "SHQ",
    "provinc": "内蒙古",
    "city": "呼和浩特市",
    "area": "赛罕区"
}, {
    "name": "内蒙古呼和浩特市土默特左旗",
    "match": "内蒙古呼和浩特市土默特左旗|NMGHHHTSTMTZQ",
    "py": "TMTZQ",
    "provinc": "内蒙古",
    "city": "呼和浩特市",
    "area": "土默特左旗"
}, {
    "name": "内蒙古呼和浩特市托克托县",
    "match": "内蒙古呼和浩特市托克托县|NMGHHHTSTKTX",
    "py": "TKTX",
    "provinc": "内蒙古",
    "city": "呼和浩特市",
    "area": "托克托县"
}, {
    "name": "内蒙古呼和浩特市和林格尔县",
    "match": "内蒙古呼和浩特市和林格尔县|NMGHHHTSHLGEX",
    "py": "HLGEX",
    "provinc": "内蒙古",
    "city": "呼和浩特市",
    "area": "和林格尔县"
}, {
    "name": "内蒙古呼和浩特市清水河县",
    "match": "内蒙古呼和浩特市清水河县|NMGHHHTSQSHX",
    "py": "QSHX",
    "provinc": "内蒙古",
    "city": "呼和浩特市",
    "area": "清水河县"
}, {
    "name": "内蒙古呼和浩特市武川县",
    "match": "内蒙古呼和浩特市武川县|NMGHHHTSWCX",
    "py": "WCX",
    "provinc": "内蒙古",
    "city": "呼和浩特市",
    "area": "武川县"
}, {
    "name": "内蒙古包头市",
    "match": "内蒙古包头市|NMGBTS",
    "py": "BTS",
    "provinc": "内蒙古",
    "city": "包头市",
    "area": "包头市"
}, {
    "name": "内蒙古包头市东河区",
    "match": "内蒙古包头市东河区|NMGBTSDHQ",
    "py": "DHQ",
    "provinc": "内蒙古",
    "city": "包头市",
    "area": "东河区"
}, {
    "name": "内蒙古包头市昆都仑区",
    "match": "内蒙古包头市昆都仑区|NMGBTSKDLQ",
    "py": "KDLQ",
    "provinc": "内蒙古",
    "city": "包头市",
    "area": "昆都仑区"
}, {
    "name": "内蒙古包头市青山区",
    "match": "内蒙古包头市青山区|NMGBTSQSQ",
    "py": "QSQ",
    "provinc": "内蒙古",
    "city": "包头市",
    "area": "青山区"
}, {
    "name": "内蒙古包头市石拐区",
    "match": "内蒙古包头市石拐区|NMGBTSSGQ",
    "py": "SGQ",
    "provinc": "内蒙古",
    "city": "包头市",
    "area": "石拐区"
}, {
    "name": "内蒙古包头市白云鄂博矿区",
    "match": "内蒙古包头市白云鄂博矿区|NMGBTSBYEBKQ",
    "py": "BYEBKQ",
    "provinc": "内蒙古",
    "city": "包头市",
    "area": "白云鄂博矿区"
}, {
    "name": "内蒙古包头市九原区",
    "match": "内蒙古包头市九原区|NMGBTSJYQ",
    "py": "JYQ",
    "provinc": "内蒙古",
    "city": "包头市",
    "area": "九原区"
}, {
    "name": "内蒙古包头市土默特右旗",
    "match": "内蒙古包头市土默特右旗|NMGBTSTMTYQ",
    "py": "TMTYQ",
    "provinc": "内蒙古",
    "city": "包头市",
    "area": "土默特右旗"
}, {
    "name": "内蒙古包头市固阳县",
    "match": "内蒙古包头市固阳县|NMGBTSGYX",
    "py": "GYX",
    "provinc": "内蒙古",
    "city": "包头市",
    "area": "固阳县"
}, {
    "name": "内蒙古包头市达尔罕茂明安联合旗",
    "match": "内蒙古包头市达尔罕茂明安联合旗|NMGBTSDEHMMALHQ",
    "py": "DEHMMALHQ",
    "provinc": "内蒙古",
    "city": "包头市",
    "area": "达尔罕茂明安联合旗"
}, {
    "name": "内蒙古乌海市",
    "match": "内蒙古乌海市|NMGWHS",
    "py": "WHS",
    "provinc": "内蒙古",
    "city": "乌海市",
    "area": "乌海市"
}, {
    "name": "内蒙古乌海市海勃湾区",
    "match": "内蒙古乌海市海勃湾区|NMGWHSHBWQ",
    "py": "HBWQ",
    "provinc": "内蒙古",
    "city": "乌海市",
    "area": "海勃湾区"
}, {
    "name": "内蒙古乌海市海南区",
    "match": "内蒙古乌海市海南区|NMGWHSHNQ",
    "py": "HNQ",
    "provinc": "内蒙古",
    "city": "乌海市",
    "area": "海南区"
}, {
    "name": "内蒙古乌海市乌达区",
    "match": "内蒙古乌海市乌达区|NMGWHSWDQ",
    "py": "WDQ",
    "provinc": "内蒙古",
    "city": "乌海市",
    "area": "乌达区"
}, {
    "name": "内蒙古乌兰察布市",
    "match": "内蒙古乌兰察布市|NMGWLCBS",
    "py": "WLCBS",
    "provinc": "内蒙古",
    "city": "乌兰察布市",
    "area": "乌兰察布市"
}, {
    "name": "内蒙古乌兰察布市集宁区",
    "match": "内蒙古乌兰察布市集宁区|NMGWLCBSJNQ",
    "py": "JNQ",
    "provinc": "内蒙古",
    "city": "乌兰察布市",
    "area": "集宁区"
}, {
    "name": "内蒙古乌兰察布市卓资县",
    "match": "内蒙古乌兰察布市卓资县|NMGWLCBSZZX",
    "py": "ZZX",
    "provinc": "内蒙古",
    "city": "乌兰察布市",
    "area": "卓资县"
}, {
    "name": "内蒙古乌兰察布市化德县",
    "match": "内蒙古乌兰察布市化德县|NMGWLCBSHDX",
    "py": "HDX",
    "provinc": "内蒙古",
    "city": "乌兰察布市",
    "area": "化德县"
}, {
    "name": "内蒙古乌兰察布市商都县",
    "match": "内蒙古乌兰察布市商都县|NMGWLCBSSDX",
    "py": "SDX",
    "provinc": "内蒙古",
    "city": "乌兰察布市",
    "area": "商都县"
}, {
    "name": "内蒙古乌兰察布市兴和县",
    "match": "内蒙古乌兰察布市兴和县|NMGWLCBSXHX",
    "py": "XHX",
    "provinc": "内蒙古",
    "city": "乌兰察布市",
    "area": "兴和县"
}, {
    "name": "内蒙古乌兰察布市凉城县",
    "match": "内蒙古乌兰察布市凉城县|NMGWLCBSLCX",
    "py": "LCX",
    "provinc": "内蒙古",
    "city": "乌兰察布市",
    "area": "凉城县"
}, {
    "name": "内蒙古乌兰察布市察哈尔右翼前旗",
    "match": "内蒙古乌兰察布市察哈尔右翼前旗|NMGWLCBSCHEYYQQ",
    "py": "CHEYYQQ",
    "provinc": "内蒙古",
    "city": "乌兰察布市",
    "area": "察哈尔右翼前旗"
}, {
    "name": "内蒙古乌兰察布市察哈尔右翼中旗",
    "match": "内蒙古乌兰察布市察哈尔右翼中旗|NMGWLCBSCHEYYZQ",
    "py": "CHEYYZQ",
    "provinc": "内蒙古",
    "city": "乌兰察布市",
    "area": "察哈尔右翼中旗"
}, {
    "name": "内蒙古乌兰察布市察哈尔右翼后旗",
    "match": "内蒙古乌兰察布市察哈尔右翼后旗|NMGWLCBSCHEYYHQ",
    "py": "CHEYYHQ",
    "provinc": "内蒙古",
    "city": "乌兰察布市",
    "area": "察哈尔右翼后旗"
}, {
    "name": "内蒙古乌兰察布市四子王旗",
    "match": "内蒙古乌兰察布市四子王旗|NMGWLCBSSZWQ",
    "py": "SZWQ",
    "provinc": "内蒙古",
    "city": "乌兰察布市",
    "area": "四子王旗"
}, {
    "name": "内蒙古乌兰察布市丰镇市",
    "match": "内蒙古乌兰察布市丰镇市|NMGWLCBSFZS",
    "py": "FZS",
    "provinc": "内蒙古",
    "city": "乌兰察布市",
    "area": "丰镇市"
}, {
    "name": "内蒙古通辽市",
    "match": "内蒙古通辽市|NMGTLS",
    "py": "TLS",
    "provinc": "内蒙古",
    "city": "通辽市",
    "area": "通辽市"
}, {
    "name": "内蒙古通辽市科尔沁区",
    "match": "内蒙古通辽市科尔沁区|NMGTLSKEQQ",
    "py": "KEQQ",
    "provinc": "内蒙古",
    "city": "通辽市",
    "area": "科尔沁区"
}, {
    "name": "内蒙古通辽市科尔沁左翼中旗",
    "match": "内蒙古通辽市科尔沁左翼中旗|NMGTLSKEQZYZQ",
    "py": "KEQZYZQ",
    "provinc": "内蒙古",
    "city": "通辽市",
    "area": "科尔沁左翼中旗"
}, {
    "name": "内蒙古通辽市科尔沁左翼后旗",
    "match": "内蒙古通辽市科尔沁左翼后旗|NMGTLSKEQZYHQ",
    "py": "KEQZYHQ",
    "provinc": "内蒙古",
    "city": "通辽市",
    "area": "科尔沁左翼后旗"
}, {
    "name": "内蒙古通辽市开鲁县",
    "match": "内蒙古通辽市开鲁县|NMGTLSKLX",
    "py": "KLX",
    "provinc": "内蒙古",
    "city": "通辽市",
    "area": "开鲁县"
}, {
    "name": "内蒙古通辽市库伦旗",
    "match": "内蒙古通辽市库伦旗|NMGTLSKLQ",
    "py": "KLQ",
    "provinc": "内蒙古",
    "city": "通辽市",
    "area": "库伦旗"
}, {
    "name": "内蒙古通辽市奈曼旗",
    "match": "内蒙古通辽市奈曼旗|NMGTLSNMQ",
    "py": "NMQ",
    "provinc": "内蒙古",
    "city": "通辽市",
    "area": "奈曼旗"
}, {
    "name": "内蒙古通辽市扎鲁特旗",
    "match": "内蒙古通辽市扎鲁特旗|NMGTLSZLTQ",
    "py": "ZLTQ",
    "provinc": "内蒙古",
    "city": "通辽市",
    "area": "扎鲁特旗"
}, {
    "name": "内蒙古通辽市霍林郭勒市",
    "match": "内蒙古通辽市霍林郭勒市|NMGTLSHLGLS",
    "py": "HLGLS",
    "provinc": "内蒙古",
    "city": "通辽市",
    "area": "霍林郭勒市"
}, {
    "name": "内蒙古赤峰市",
    "match": "内蒙古赤峰市|NMGCFS",
    "py": "CFS",
    "provinc": "内蒙古",
    "city": "赤峰市",
    "area": "赤峰市"
}, {
    "name": "内蒙古赤峰市红山区",
    "match": "内蒙古赤峰市红山区|NMGCFSHSQ",
    "py": "HSQ",
    "provinc": "内蒙古",
    "city": "赤峰市",
    "area": "红山区"
}, {
    "name": "内蒙古赤峰市元宝山区",
    "match": "内蒙古赤峰市元宝山区|NMGCFSYBSQ",
    "py": "YBSQ",
    "provinc": "内蒙古",
    "city": "赤峰市",
    "area": "元宝山区"
}, {
    "name": "内蒙古赤峰市松山区",
    "match": "内蒙古赤峰市松山区|NMGCFSSSQ",
    "py": "SSQ",
    "provinc": "内蒙古",
    "city": "赤峰市",
    "area": "松山区"
}, {
    "name": "内蒙古赤峰市阿鲁科尔沁旗",
    "match": "内蒙古赤峰市阿鲁科尔沁旗|NMGCFSALKEQQ",
    "py": "ALKEQQ",
    "provinc": "内蒙古",
    "city": "赤峰市",
    "area": "阿鲁科尔沁旗"
}, {
    "name": "内蒙古赤峰市巴林左旗",
    "match": "内蒙古赤峰市巴林左旗|NMGCFSBLZQ",
    "py": "BLZQ",
    "provinc": "内蒙古",
    "city": "赤峰市",
    "area": "巴林左旗"
}, {
    "name": "内蒙古赤峰市巴林右旗",
    "match": "内蒙古赤峰市巴林右旗|NMGCFSBLYQ",
    "py": "BLYQ",
    "provinc": "内蒙古",
    "city": "赤峰市",
    "area": "巴林右旗"
}, {
    "name": "内蒙古赤峰市林西县",
    "match": "内蒙古赤峰市林西县|NMGCFSLXX",
    "py": "LXX",
    "provinc": "内蒙古",
    "city": "赤峰市",
    "area": "林西县"
}, {
    "name": "内蒙古赤峰市克什克腾旗",
    "match": "内蒙古赤峰市克什克腾旗|NMGCFSKSKTQ",
    "py": "KSKTQ",
    "provinc": "内蒙古",
    "city": "赤峰市",
    "area": "克什克腾旗"
}, {
    "name": "内蒙古赤峰市翁牛特旗",
    "match": "内蒙古赤峰市翁牛特旗|NMGCFSWNTQ",
    "py": "WNTQ",
    "provinc": "内蒙古",
    "city": "赤峰市",
    "area": "翁牛特旗"
}, {
    "name": "内蒙古赤峰市喀喇沁旗",
    "match": "内蒙古赤峰市喀喇沁旗|NMGCFSKLQQ",
    "py": "KLQQ",
    "provinc": "内蒙古",
    "city": "赤峰市",
    "area": "喀喇沁旗"
}, {
    "name": "内蒙古赤峰市宁城县",
    "match": "内蒙古赤峰市宁城县|NMGCFSNCX",
    "py": "NCX",
    "provinc": "内蒙古",
    "city": "赤峰市",
    "area": "宁城县"
}, {
    "name": "内蒙古赤峰市敖汉旗",
    "match": "内蒙古赤峰市敖汉旗|NMGCFSAHQ",
    "py": "AHQ",
    "provinc": "内蒙古",
    "city": "赤峰市",
    "area": "敖汉旗"
}, {
    "name": "内蒙古鄂尔多斯市",
    "match": "内蒙古鄂尔多斯市|NMGEEDSS",
    "py": "EEDSS",
    "provinc": "内蒙古",
    "city": "鄂尔多斯市",
    "area": "鄂尔多斯市"
},

    /*ADD*/
    {
        "name": "内蒙古鄂尔多斯市康巴什区",
        "match": "内蒙古鄂尔多斯市康巴什区|NMGEEDSSKBSQ",
        "py": "KBSQ",
        "provinc": "内蒙古",
        "city": "鄂尔多斯市",
        "area": "康巴什区"
    },
    /*ADD*/


    {
    "name": "内蒙古鄂尔多斯市东胜区",
    "match": "内蒙古鄂尔多斯市东胜区|NMGEEDSSDSQ",
    "py": "DSQ",
    "provinc": "内蒙古",
    "city": "鄂尔多斯市",
    "area": "东胜区"
}, {
    "name": "内蒙古鄂尔多斯市达拉特旗",
    "match": "内蒙古鄂尔多斯市达拉特旗|NMGEEDSSDLTQ",
    "py": "DLTQ",
    "provinc": "内蒙古",
    "city": "鄂尔多斯市",
    "area": "达拉特旗"
}, {
    "name": "内蒙古鄂尔多斯市准格尔旗",
    "match": "内蒙古鄂尔多斯市准格尔旗|NMGEEDSSZGEQ",
    "py": "ZGEQ",
    "provinc": "内蒙古",
    "city": "鄂尔多斯市",
    "area": "准格尔旗"
}, {
    "name": "内蒙古鄂尔多斯市鄂托克前旗",
    "match": "内蒙古鄂尔多斯市鄂托克前旗|NMGEEDSSETKQQ",
    "py": "ETKQQ",
    "provinc": "内蒙古",
    "city": "鄂尔多斯市",
    "area": "鄂托克前旗"
}, {
    "name": "内蒙古鄂尔多斯市鄂托克旗",
    "match": "内蒙古鄂尔多斯市鄂托克旗|NMGEEDSSETKQ",
    "py": "ETKQ",
    "provinc": "内蒙古",
    "city": "鄂尔多斯市",
    "area": "鄂托克旗"
}, {
    "name": "内蒙古鄂尔多斯市杭锦旗",
    "match": "内蒙古鄂尔多斯市杭锦旗|NMGEEDSSHJQ",
    "py": "HJQ",
    "provinc": "内蒙古",
    "city": "鄂尔多斯市",
    "area": "杭锦旗"
}, {
    "name": "内蒙古鄂尔多斯市乌审旗",
    "match": "内蒙古鄂尔多斯市乌审旗|NMGEEDSSWSQ",
    "py": "WSQ",
    "provinc": "内蒙古",
    "city": "鄂尔多斯市",
    "area": "乌审旗"
}, {
    "name": "内蒙古鄂尔多斯市伊金霍洛旗",
    "match": "内蒙古鄂尔多斯市伊金霍洛旗|NMGEEDSSYJHLQ",
    "py": "YJHLQ",
    "provinc": "内蒙古",
    "city": "鄂尔多斯市",
    "area": "伊金霍洛旗"
}, {
    "name": "内蒙古巴彦淖尔市",
    "match": "内蒙古巴彦淖尔市|NMGBYNES",
    "py": "BYNES",
    "provinc": "内蒙古",
    "city": "巴彦淖尔市",
    "area": "巴彦淖尔市"
}, {
    "name": "内蒙古巴彦淖尔市临河区",
    "match": "内蒙古巴彦淖尔市临河区|NMGBYNESLHQ",
    "py": "LHQ",
    "provinc": "内蒙古",
    "city": "巴彦淖尔市",
    "area": "临河区"
}, {
    "name": "内蒙古巴彦淖尔市五原县",
    "match": "内蒙古巴彦淖尔市五原县|NMGBYNESWYX",
    "py": "WYX",
    "provinc": "内蒙古",
    "city": "巴彦淖尔市",
    "area": "五原县"
}, {
    "name": "内蒙古巴彦淖尔市磴口县",
    "match": "内蒙古巴彦淖尔市磴口县|NMGBYNESDKX",
    "py": "DKX",
    "provinc": "内蒙古",
    "city": "巴彦淖尔市",
    "area": "磴口县"
}, {
    "name": "内蒙古巴彦淖尔市乌拉特前旗",
    "match": "内蒙古巴彦淖尔市乌拉特前旗|NMGBYNESWLTQQ",
    "py": "WLTQQ",
    "provinc": "内蒙古",
    "city": "巴彦淖尔市",
    "area": "乌拉特前旗"
}, {
    "name": "内蒙古巴彦淖尔市乌拉特中旗",
    "match": "内蒙古巴彦淖尔市乌拉特中旗|NMGBYNESWLTZQ",
    "py": "WLTZQ",
    "provinc": "内蒙古",
    "city": "巴彦淖尔市",
    "area": "乌拉特中旗"
}, {
    "name": "内蒙古巴彦淖尔市乌拉特后旗",
    "match": "内蒙古巴彦淖尔市乌拉特后旗|NMGBYNESWLTHQ",
    "py": "WLTHQ",
    "provinc": "内蒙古",
    "city": "巴彦淖尔市",
    "area": "乌拉特后旗"
}, {
    "name": "内蒙古巴彦淖尔市杭锦后旗",
    "match": "内蒙古巴彦淖尔市杭锦后旗|NMGBYNESHJHQ",
    "py": "HJHQ",
    "provinc": "内蒙古",
    "city": "巴彦淖尔市",
    "area": "杭锦后旗"
}, {
    "name": "内蒙古锡林郭勒盟",
    "match": "内蒙古锡林郭勒盟|NMGXLGLM",
    "py": "XLGLM",
    "provinc": "内蒙古",
    "city": "锡林郭勒盟",
    "area": "锡林郭勒盟"
}, {
    "name": "内蒙古锡林郭勒盟二连浩特市",
    "match": "内蒙古锡林郭勒盟二连浩特市|NMGXLGLMELHTS",
    "py": "ELHTS",
    "provinc": "内蒙古",
    "city": "锡林郭勒盟",
    "area": "二连浩特市"
}, {
    "name": "内蒙古锡林郭勒盟锡林浩特市",
    "match": "内蒙古锡林郭勒盟锡林浩特市|NMGXLGLMXLHTS",
    "py": "XLHTS",
    "provinc": "内蒙古",
    "city": "锡林郭勒盟",
    "area": "锡林浩特市"
}, {
    "name": "内蒙古锡林郭勒盟阿巴嘎旗",
    "match": "内蒙古锡林郭勒盟阿巴嘎旗|NMGXLGLMABGQ",
    "py": "ABGQ",
    "provinc": "内蒙古",
    "city": "锡林郭勒盟",
    "area": "阿巴嘎旗"
}, {
    "name": "内蒙古锡林郭勒盟苏尼特左旗",
    "match": "内蒙古锡林郭勒盟苏尼特左旗|NMGXLGLMSNTZQ",
    "py": "SNTZQ",
    "provinc": "内蒙古",
    "city": "锡林郭勒盟",
    "area": "苏尼特左旗"
}, {
    "name": "内蒙古锡林郭勒盟苏尼特右旗",
    "match": "内蒙古锡林郭勒盟苏尼特右旗|NMGXLGLMSNTYQ",
    "py": "SNTYQ",
    "provinc": "内蒙古",
    "city": "锡林郭勒盟",
    "area": "苏尼特右旗"
}, {
    "name": "内蒙古锡林郭勒盟东乌珠穆沁旗",
    "match": "内蒙古锡林郭勒盟东乌珠穆沁旗|NMGXLGLMDWZMQQ",
    "py": "DWZMQQ",
    "provinc": "内蒙古",
    "city": "锡林郭勒盟",
    "area": "东乌珠穆沁旗"
}, {
    "name": "内蒙古锡林郭勒盟西乌珠穆沁旗",
    "match": "内蒙古锡林郭勒盟西乌珠穆沁旗|NMGXLGLMXWZMQQ",
    "py": "XWZMQQ",
    "provinc": "内蒙古",
    "city": "锡林郭勒盟",
    "area": "西乌珠穆沁旗"
}, {
    "name": "内蒙古锡林郭勒盟太仆寺旗",
    "match": "内蒙古锡林郭勒盟太仆寺旗|NMGXLGLMTPSQ",
    "py": "TPSQ",
    "provinc": "内蒙古",
    "city": "锡林郭勒盟",
    "area": "太仆寺旗"
}, {
    "name": "内蒙古锡林郭勒盟镶黄旗",
    "match": "内蒙古锡林郭勒盟镶黄旗|NMGXLGLMXHQ",
    "py": "XHQ",
    "provinc": "内蒙古",
    "city": "锡林郭勒盟",
    "area": "镶黄旗"
}, {
    "name": "内蒙古锡林郭勒盟正镶白旗",
    "match": "内蒙古锡林郭勒盟正镶白旗|NMGXLGLMZXBQ",
    "py": "ZXBQ",
    "provinc": "内蒙古",
    "city": "锡林郭勒盟",
    "area": "正镶白旗"
}, {
    "name": "内蒙古锡林郭勒盟正蓝旗",
    "match": "内蒙古锡林郭勒盟正蓝旗|NMGXLGLMZLQ",
    "py": "ZLQ",
    "provinc": "内蒙古",
    "city": "锡林郭勒盟",
    "area": "正蓝旗"
}, {
    "name": "内蒙古锡林郭勒盟多伦县",
    "match": "内蒙古锡林郭勒盟多伦县|NMGXLGLMDLX",
    "py": "DLX",
    "provinc": "内蒙古",
    "city": "锡林郭勒盟",
    "area": "多伦县"
}, {
    "name": "内蒙古兴安盟",
    "match": "内蒙古兴安盟|NMGXAM",
    "py": "XAM",
    "provinc": "内蒙古",
    "city": "兴安盟",
    "area": "兴安盟"
}, {
    "name": "内蒙古兴安盟乌兰浩特市",
    "match": "内蒙古兴安盟乌兰浩特市|NMGXAMWLHTS",
    "py": "WLHTS",
    "provinc": "内蒙古",
    "city": "兴安盟",
    "area": "乌兰浩特市"
}, {
    "name": "内蒙古兴安盟阿尔山市",
    "match": "内蒙古兴安盟阿尔山市|NMGXAMAESS",
    "py": "AESS",
    "provinc": "内蒙古",
    "city": "兴安盟",
    "area": "阿尔山市"
}, {
    "name": "内蒙古兴安盟科尔沁右翼前旗",
    "match": "内蒙古兴安盟科尔沁右翼前旗|NMGXAMKEQYYQQ",
    "py": "KEQYYQQ",
    "provinc": "内蒙古",
    "city": "兴安盟",
    "area": "科尔沁右翼前旗"
}, {
    "name": "内蒙古兴安盟科尔沁右翼中旗",
    "match": "内蒙古兴安盟科尔沁右翼中旗|NMGXAMKEQYYZQ",
    "py": "KEQYYZQ",
    "provinc": "内蒙古",
    "city": "兴安盟",
    "area": "科尔沁右翼中旗"
}, {
    "name": "内蒙古兴安盟扎赉特旗",
    "match": "内蒙古兴安盟扎赉特旗|NMGXAMZLTQ",
    "py": "ZLTQ",
    "provinc": "内蒙古",
    "city": "兴安盟",
    "area": "扎赉特旗"
}, {
    "name": "内蒙古兴安盟突泉县",
    "match": "内蒙古兴安盟突泉县|NMGXAMTQX",
    "py": "TQX",
    "provinc": "内蒙古",
    "city": "兴安盟",
    "area": "突泉县"
}, {
    "name": "内蒙古阿拉善盟区",
    "match": "内蒙古阿拉善盟区|NMGALSM",
    "py": "ALSM",
    "provinc": "内蒙古",
    "city": "阿拉善盟区",
    "area": "阿拉善盟区"
}, {
    "name": "内蒙古阿拉善盟区阿拉善左旗",
    "match": "内蒙古阿拉善盟区阿拉善左旗|NMGALSMALSZQ",
    "py": "ALSZQ",
    "provinc": "内蒙古",
    "city": "阿拉善盟区",
    "area": "阿拉善左旗"
}, {
    "name": "内蒙古阿拉善盟区阿拉善右旗",
    "match": "内蒙古阿拉善盟区阿拉善右旗|NMGALSMALSYQ",
    "py": "ALSYQ",
    "provinc": "内蒙古",
    "city": "阿拉善盟区",
    "area": "阿拉善右旗"
}, {
    "name": "内蒙古阿拉善盟区额济纳旗",
    "match": "内蒙古阿拉善盟区额济纳旗|NMGALSMEJNQ",
    "py": "EJNQ",
    "provinc": "内蒙古",
    "city": "阿拉善盟区",
    "area": "额济纳旗"
}, {
    "name": "北京市",
    "match": "北京市|BJS",
    "py": "BJS",
    "provinc": "北京",
    "city": "北京市",
    "area": "北京市"
}, {
    "name": "北京市东城区",
    "match": "北京市东城区|BJSDCQ",
    "py": "DCQ",
    "provinc": "北京",
    "city": "北京市",
    "area": "东城区"
}, {
    "name": "北京市西城区",
    "match": "北京市西城区|BJSXCQ",
    "py": "XCQ",
    "provinc": "北京",
    "city": "北京市",
    "area": "西城区"
}, {
    "name": "北京市崇文区",
    "match": "北京市崇文区|BJSCWQ",
    "py": "CWQ",
    "provinc": "北京",
    "city": "北京市",
    "area": "崇文区"
}, {
    "name": "北京市宣武区",
    "match": "北京市宣武区|BJSXWQ",
    "py": "XWQ",
    "provinc": "北京",
    "city": "北京市",
    "area": "宣武区"
}, {
    "name": "北京市朝阳区",
    "match": "北京市朝阳区|BJSCYQ",
    "py": "CYQ",
    "provinc": "北京",
    "city": "北京市",
    "area": "朝阳区"
}, {
    "name": "北京市丰台区",
    "match": "北京市丰台区|BJSFTQ",
    "py": "FTQ",
    "provinc": "北京",
    "city": "北京市",
    "area": "丰台区"
}, {
    "name": "北京市石景山区",
    "match": "北京市石景山区|BJSSJSQ",
    "py": "SJSQ",
    "provinc": "北京",
    "city": "北京市",
    "area": "石景山区"
}, {
    "name": "北京市海淀区",
    "match": "北京市海淀区|BJSHDQ",
    "py": "HDQ",
    "provinc": "北京",
    "city": "北京市",
    "area": "海淀区"
}, {
    "name": "北京市门头沟区",
    "match": "北京市门头沟区|BJSMTGQ",
    "py": "MTGQ",
    "provinc": "北京",
    "city": "北京市",
    "area": "门头沟区"
}, {
    "name": "北京市房山区",
    "match": "北京市房山区|BJSFSQ",
    "py": "FSQ",
    "provinc": "北京",
    "city": "北京市",
    "area": "房山区"
}, {
    "name": "北京市通州区",
    "match": "北京市通州区|BJSTZQ",
    "py": "TZQ",
    "provinc": "北京",
    "city": "北京市",
    "area": "通州区"
}, {
    "name": "北京市顺义区",
    "match": "北京市顺义区|BJSSYQ",
    "py": "SYQ",
    "provinc": "北京",
    "city": "北京市",
    "area": "顺义区"
}, {
    "name": "北京市昌平区",
    "match": "北京市昌平区|BJSCPQ",
    "py": "CPQ",
    "provinc": "北京",
    "city": "北京市",
    "area": "昌平区"
}, {
    "name": "北京市大兴区",
    "match": "北京市大兴区|BJSDXQ",
    "py": "DXQ",
    "provinc": "北京",
    "city": "北京市",
    "area": "大兴区"
}, {
    "name": "北京市怀柔区",
    "match": "北京市怀柔区|BJSHRQ",
    "py": "HRQ",
    "provinc": "北京",
    "city": "北京市",
    "area": "怀柔区"
}, {
    "name": "北京市平谷区",
    "match": "北京市平谷区|BJSPGQ",
    "py": "PGQ",
    "provinc": "北京",
    "city": "北京市",
    "area": "平谷区"
}, {
    "name": "北京市密云区",
    "match": "北京市密云区|BJSMYQ",
    "py": "MYQ",
    "provinc": "北京",
    "city": "北京市",
    "area": "密云区"
}, {
    "name": "北京市延庆区",
    "match": "北京市延庆区|BJSYQQ",
    "py": "YQQ",
    "provinc": "北京",
    "city": "北京市",
    "area": "延庆区"
}, {
    "name": "台湾台北市",
    "match": "台湾台北市|TWTBS",
    "py": "TBS",
    "provinc": "台湾",
    "city": "台北市",
    "area": "台北市"
}, {
    "name": "台湾高雄市",
    "match": "台湾高雄市|TWGXS",
    "py": "GXS",
    "provinc": "台湾",
    "city": "高雄市",
    "area": "高雄市"
}, {
    "name": "台湾基隆市",
    "match": "台湾基隆市|TWJLS",
    "py": "JLS",
    "provinc": "台湾",
    "city": "基隆市",
    "area": "基隆市"
}, {
    "name": "台湾台中市",
    "match": "台湾台中市|TWTZS",
    "py": "TZS",
    "provinc": "台湾",
    "city": "台中市",
    "area": "台中市"
}, {
    "name": "台湾台南市",
    "match": "台湾台南市|TWTNS",
    "py": "TNS",
    "provinc": "台湾",
    "city": "台南市",
    "area": "台南市"
}, {
    "name": "台湾新竹市",
    "match": "台湾新竹市|TWXZS",
    "py": "XZS",
    "provinc": "台湾",
    "city": "新竹市",
    "area": "新竹市"
}, {
    "name": "台湾嘉义市",
    "match": "台湾嘉义市|TWJYS",
    "py": "JYS",
    "provinc": "台湾",
    "city": "嘉义市",
    "area": "嘉义市"
}, {
    "name": "吉林长春市",
    "match": "吉林长春市|JLCCS",
    "py": "CCS",
    "provinc": "吉林",
    "city": "长春市",
    "area": "长春市"
},

/*ADD*/
    {
        "name": "吉林长春市汽车产业开发区",
        "match": "吉林长春市汽车产业开发区|JLCCSQCCYKFQ",
        "py": "QCCYKFQ",
        "provinc": "吉林",
        "city": "长春市",
        "area": "汽车产业开发区"
    },
    {
        "name": "吉林长春市净月高新技术开发区",
        "match": "吉林长春市净月高新技术开发区|JLCCSJYGXJSKFQ",
        "py": "JYGXJSKFQ",
        "provinc": "吉林",
        "city": "长春市",
        "area": "净月高新技术开发区"
    },
    {
        "name": "吉林长春市经济技术开发区",
        "match": "吉林长春市经济技术开发区|JLCCSJJJSKFQ",
        "py": "JJJSKFQ",
        "provinc": "吉林",
        "city": "长春市",
        "area": "经济技术开发区"
    },
    /*ADD*/

{
    "name": "吉林长春市南关区",
    "match": "吉林长春市南关区|JLCCSNGQ",
    "py": "NGQ",
    "provinc": "吉林",
    "city": "长春市",
    "area": "南关区"
}, {
    "name": "吉林长春市宽城区",
    "match": "吉林长春市宽城区|JLCCSKCQ",
    "py": "KCQ",
    "provinc": "吉林",
    "city": "长春市",
    "area": "宽城区"
}, {
    "name": "吉林长春市朝阳区",
    "match": "吉林长春市朝阳区|JLCCSCYQ",
    "py": "CYQ",
    "provinc": "吉林",
    "city": "长春市",
    "area": "朝阳区"
}, {
    "name": "吉林长春市二道区",
    "match": "吉林长春市二道区|JLCCSEDQ",
    "py": "EDQ",
    "provinc": "吉林",
    "city": "长春市",
    "area": "二道区"
}, {
    "name": "吉林长春市绿园区",
    "match": "吉林长春市绿园区|JLCCSLYQ",
    "py": "LYQ",
    "provinc": "吉林",
    "city": "长春市",
    "area": "绿园区"
}, {
    "name": "吉林长春市双阳区",
    "match": "吉林长春市双阳区|JLCCSSYQ",
    "py": "SYQ",
    "provinc": "吉林",
    "city": "长春市",
    "area": "双阳区"
}, {
    "name": "吉林长春市农安县",
    "match": "吉林长春市农安县|JLCCSNAX",
    "py": "NAX",
    "provinc": "吉林",
    "city": "长春市",
    "area": "农安县"
}, {
    "name": "吉林长春市九台区",
    "match": "吉林长春市九台区|JLCCSJTQ",
    "py": "JTQ",
    "provinc": "吉林",
    "city": "长春市",
    "area": "九台区"
}, {
    "name": "吉林长春市榆树市",
    "match": "吉林长春市榆树市|JLCCSYSS",
    "py": "YSS",
    "provinc": "吉林",
    "city": "长春市",
    "area": "榆树市"
}, {
    "name": "吉林长春市德惠市",
    "match": "吉林长春市德惠市|JLCCSDHS",
    "py": "DHS",
    "provinc": "吉林",
    "city": "长春市",
    "area": "德惠市"
}, {
    "name": "吉林市",
    "match": "吉林市|JLS",
    "py": "JLS",
    "provinc": "吉林",
    "city": "吉林市",
    "area": "吉林市"
}, {
    "name": "吉林昌邑区",
    "match": "吉林市昌邑区|JLSCYQ",
    "py": "CYQ",
    "provinc": "吉林",
    "city": "吉林市",
    "area": "昌邑区"
}, {
    "name": "吉林龙潭区",
    "match": "吉林市龙潭区|JLSLTQ",
    "py": "LTQ",
    "provinc": "吉林",
    "city": "吉林市",
    "area": "龙潭区"
}, {
    "name": "吉林船营区",
    "match": "吉林市船营区|JLSCYQ",
    "py": "CYQ",
    "provinc": "吉林",
    "city": "吉林市",
    "area": "船营区"
}, {
    "name": "吉林丰满区",
    "match": "吉林市丰满区|JLSFMQ",
    "py": "FMQ",
    "provinc": "吉林",
    "city": "吉林市",
    "area": "丰满区"
}, {
    "name": "吉林永吉县",
    "match": "吉林市永吉县|JLSYJX",
    "py": "YJX",
    "provinc": "吉林",
    "city": "吉林市",
    "area": "永吉县"
}, {
    "name": "吉林蛟河市",
    "match": "吉林市蛟河市|JLSJHS",
    "py": "JHS",
    "provinc": "吉林",
    "city": "吉林市",
    "area": "蛟河市"
}, {
    "name": "吉林桦甸市",
    "match": "吉林市桦甸市|JLSHDS",
    "py": "HDS",
    "provinc": "吉林",
    "city": "吉林市",
    "area": "桦甸市"
}, {
    "name": "吉林舒兰市",
    "match": "吉林市舒兰市|JLSSLS",
    "py": "SLS",
    "provinc": "吉林",
    "city": "吉林市",
    "area": "舒兰市"
}, {
    "name": "吉林磐石市",
    "match": "吉林市磐石市|JLSPSS",
    "py": "PSS",
    "provinc": "吉林",
    "city": "吉林市",
    "area": "磐石市"
}, {
    "name": "吉林延边州",
    "match": "吉林延边州|JLYBZZZ",
    "py": "YBZZZ",
    "provinc": "吉林",
    "city": "延边州",
    "area": "延边州"
}, {
    "name": "吉林延边州延吉市",
    "match": "吉林延边州延吉市|JLYBZZZYJS",
    "py": "YJS",
    "provinc": "吉林",
    "city": "延边州",
    "area": "延吉市"
}, {
    "name": "吉林延边州图们市",
    "match": "吉林延边州图们市|JLYBZZZTMS",
    "py": "TMS",
    "provinc": "吉林",
    "city": "延边州",
    "area": "图们市"
}, {
    "name": "吉林延边州敦化市",
    "match": "吉林延边州敦化市|JLYBZZZDHS",
    "py": "DHS",
    "provinc": "吉林",
    "city": "延边州",
    "area": "敦化市"
}, {
    "name": "吉林延边州珲春市",
    "match": "吉林延边州珲春市|JLYBZZZHCS",
    "py": "HCS",
    "provinc": "吉林",
    "city": "延边州",
    "area": "珲春市"
}, {
    "name": "吉林延边州龙井市",
    "match": "吉林延边州龙井市|JLYBZZZLJS",
    "py": "LJS",
    "provinc": "吉林",
    "city": "延边州",
    "area": "龙井市"
}, {
    "name": "吉林延边州和龙市",
    "match": "吉林延边州和龙市|JLYBZZZHLS",
    "py": "HLS",
    "provinc": "吉林",
    "city": "延边州",
    "area": "和龙市"
}, {
    "name": "吉林延边州汪清县",
    "match": "吉林延边州汪清县|JLYBZZZWQX",
    "py": "WQX",
    "provinc": "吉林",
    "city": "延边州",
    "area": "汪清县"
}, {
    "name": "吉林延边州安图县",
    "match": "吉林延边州安图县|JLYBZZZATX",
    "py": "ATX",
    "provinc": "吉林",
    "city": "延边州",
    "area": "安图县"
}, {
    "name": "吉林四平市",
    "match": "吉林四平市|JLSPS",
    "py": "SPS",
    "provinc": "吉林",
    "city": "四平市",
    "area": "四平市"
}, {
    "name": "吉林四平市铁西区",
    "match": "吉林四平市铁西区|JLSPSTXQ",
    "py": "TXQ",
    "provinc": "吉林",
    "city": "四平市",
    "area": "铁西区"
}, {
    "name": "吉林四平市铁东区",
    "match": "吉林四平市铁东区|JLSPSTDQ",
    "py": "TDQ",
    "provinc": "吉林",
    "city": "四平市",
    "area": "铁东区"
}, {
    "name": "吉林四平市梨树县",
    "match": "吉林四平市梨树县|JLSPSLSX",
    "py": "LSX",
    "provinc": "吉林",
    "city": "四平市",
    "area": "梨树县"
}, {
    "name": "吉林四平市伊通县",
    "match": "吉林四平市伊通县|JLSPSYTZZX",
    "py": "YTZZX",
    "provinc": "吉林",
    "city": "四平市",
    "area": "伊通县"
}, {
    "name": "吉林四平市公主岭市",
    "match": "吉林四平市公主岭市|JLSPSGZLS",
    "py": "GZLS",
    "provinc": "吉林",
    "city": "四平市",
    "area": "公主岭市"
}, {
    "name": "吉林四平市双辽市",
    "match": "吉林四平市双辽市|JLSPSSLS",
    "py": "SLS",
    "provinc": "吉林",
    "city": "四平市",
    "area": "双辽市"
}, {
    "name": "吉林通化市",
    "match": "吉林通化市|JLTHS",
    "py": "THS",
    "provinc": "吉林",
    "city": "通化市",
    "area": "通化市"
}, {
    "name": "吉林通化市东昌区",
    "match": "吉林通化市东昌区|JLTHSDCQ",
    "py": "DCQ",
    "provinc": "吉林",
    "city": "通化市",
    "area": "东昌区"
}, {
    "name": "吉林通化市二道江区",
    "match": "吉林通化市二道江区|JLTHSEDJQ",
    "py": "EDJQ",
    "provinc": "吉林",
    "city": "通化市",
    "area": "二道江区"
}, {
    "name": "吉林通化市通化县",
    "match": "吉林通化市通化县|JLTHSTHX",
    "py": "THX",
    "provinc": "吉林",
    "city": "通化市",
    "area": "通化县"
}, {
    "name": "吉林通化市辉南县",
    "match": "吉林通化市辉南县|JLTHSHNX",
    "py": "HNX",
    "provinc": "吉林",
    "city": "通化市",
    "area": "辉南县"
}, {
    "name": "吉林通化市柳河县",
    "match": "吉林通化市柳河县|JLTHSLHX",
    "py": "LHX",
    "provinc": "吉林",
    "city": "通化市",
    "area": "柳河县"
}, {
    "name": "吉林通化市梅河口市",
    "match": "吉林通化市梅河口市|JLTHSMHKS",
    "py": "MHKS",
    "provinc": "吉林",
    "city": "通化市",
    "area": "梅河口市"
}, {
    "name": "吉林通化市集安市",
    "match": "吉林通化市集安市|JLTHSJAS",
    "py": "JAS",
    "provinc": "吉林",
    "city": "通化市",
    "area": "集安市"
}, {
    "name": "吉林白城市",
    "match": "吉林白城市|JLBCS",
    "py": "BCS",
    "provinc": "吉林",
    "city": "白城市",
    "area": "白城市"
}, {
    "name": "吉林白城市洮北区",
    "match": "吉林白城市洮北区|JLBCSTBQ",
    "py": "TBQ",
    "provinc": "吉林",
    "city": "白城市",
    "area": "洮北区"
}, {
    "name": "吉林白城市镇赉县",
    "match": "吉林白城市镇赉县|JLBCSZLX",
    "py": "ZLX",
    "provinc": "吉林",
    "city": "白城市",
    "area": "镇赉县"
}, {
    "name": "吉林白城市通榆县",
    "match": "吉林白城市通榆县|JLBCSTYX",
    "py": "TYX",
    "provinc": "吉林",
    "city": "白城市",
    "area": "通榆县"
}, {
    "name": "吉林白城市洮南市",
    "match": "吉林白城市洮南市|JLBCSTNS",
    "py": "TNS",
    "provinc": "吉林",
    "city": "白城市",
    "area": "洮南市"
}, {
    "name": "吉林白城市大安市",
    "match": "吉林白城市大安市|JLBCSDAS",
    "py": "DAS",
    "provinc": "吉林",
    "city": "白城市",
    "area": "大安市"
}, {
    "name": "吉林辽源市",
    "match": "吉林辽源市|JLLYS",
    "py": "LYS",
    "provinc": "吉林",
    "city": "辽源市",
    "area": "辽源市"
}, {
    "name": "吉林辽源市龙山区",
    "match": "吉林辽源市龙山区|JLLYSLSQ",
    "py": "LSQ",
    "provinc": "吉林",
    "city": "辽源市",
    "area": "龙山区"
}, {
    "name": "吉林辽源市西安区",
    "match": "吉林辽源市西安区|JLLYSXAQ",
    "py": "XAQ",
    "provinc": "吉林",
    "city": "辽源市",
    "area": "西安区"
}, {
    "name": "吉林辽源市东丰县",
    "match": "吉林辽源市东丰县|JLLYSDFX",
    "py": "DFX",
    "provinc": "吉林",
    "city": "辽源市",
    "area": "东丰县"
}, {
    "name": "吉林辽源市东辽县",
    "match": "吉林辽源市东辽县|JLLYSDLX",
    "py": "DLX",
    "provinc": "吉林",
    "city": "辽源市",
    "area": "东辽县"
}, {
    "name": "吉林松原市",
    "match": "吉林松原市|JLSYS",
    "py": "SYS",
    "provinc": "吉林",
    "city": "松原市",
    "area": "松原市"
}, {
    "name": "吉林松原市宁江区",
    "match": "吉林松原市宁江区|JLSYSNJQ",
    "py": "NJQ",
    "provinc": "吉林",
    "city": "松原市",
    "area": "宁江区"
}, {
    "name": "吉林松原市前郭尔罗斯县",
    "match": "吉林松原市前郭尔罗斯县|JLSYSQGELSZZX",
    "py": "QGELSZZX",
    "provinc": "吉林",
    "city": "松原市",
    "area": "前郭尔罗斯县"
}, {
    "name": "吉林松原市长岭县",
    "match": "吉林松原市长岭县|JLSYSCLX",
    "py": "CLX",
    "provinc": "吉林",
    "city": "松原市",
    "area": "长岭县"
}, {
    "name": "吉林松原市乾安县",
    "match": "吉林松原市乾安县|JLSYSQAX",
    "py": "QAX",
    "provinc": "吉林",
    "city": "松原市",
    "area": "乾安县"
}, {
    "name": "吉林松原市扶余市",
    "match": "吉林松原市扶余市|JLSYSFYS",
    "py": "FYS",
    "provinc": "吉林",
    "city": "松原市",
    "area": "扶余市"
}, {
    "name": "吉林白山市",
    "match": "吉林白山市|JLBSS",
    "py": "BSS",
    "provinc": "吉林",
    "city": "白山市",
    "area": "白山市"
}, {
    "name": "吉林白山市八道江区",
    "match": "吉林白山市八道江区|JLBSSBDJQ",
    "py": "BDJQ",
    "provinc": "吉林",
    "city": "白山市",
    "area": "八道江区"
}, {
    "name": "吉林白山市江源区",
    "match": "吉林白山市江源区|JLBSSJYQ",
    "py": "JYQ",
    "provinc": "吉林",
    "city": "白山市",
    "area": "江源区"
}, {
    "name": "吉林白山市抚松县",
    "match": "吉林白山市抚松县|JLBSSFSX",
    "py": "FSX",
    "provinc": "吉林",
    "city": "白山市",
    "area": "抚松县"
}, {
    "name": "吉林白山市靖宇县",
    "match": "吉林白山市靖宇县|JLBSSJYX",
    "py": "JYX",
    "provinc": "吉林",
    "city": "白山市",
    "area": "靖宇县"
}, {
    "name": "吉林白山市长白县",
    "match": "吉林白山市长白县|JLBSSCBZZX",
    "py": "CBZZX",
    "provinc": "吉林",
    "city": "白山市",
    "area": "长白县"
}, {
    "name": "吉林白山市临江市",
    "match": "吉林白山市临江市|JLBSSLJS",
    "py": "LJS",
    "provinc": "吉林",
    "city": "白山市",
    "area": "临江市"
}, {
    "name": "四川成都市",
    "match": "四川成都市|SCCDS",
    "py": "CDS",
    "provinc": "四川",
    "city": "成都市",
    "area": "成都市"
},

    /*ADD*/
    {
        "name": "四川成都市简阳市",
        "match": "四川成都市简阳市|SCCDSJYS",
        "py": "JYS",
        "provinc": "四川",
        "city": "成都市",
        "area": "简阳市"
    },
    /*ADD*/

    {
    "name": "四川成都市锦江区",
    "match": "四川成都市锦江区|SCCDSJJQ",
    "py": "JJQ",
    "provinc": "四川",
    "city": "成都市",
    "area": "锦江区"
}, {
    "name": "四川成都市青羊区",
    "match": "四川成都市青羊区|SCCDSQYQ",
    "py": "QYQ",
    "provinc": "四川",
    "city": "成都市",
    "area": "青羊区"
}, {
    "name": "四川成都市金牛区",
    "match": "四川成都市金牛区|SCCDSJNQ",
    "py": "JNQ",
    "provinc": "四川",
    "city": "成都市",
    "area": "金牛区"
}, {
    "name": "四川成都市武侯区",
    "match": "四川成都市武侯区|SCCDSWHQ",
    "py": "WHQ",
    "provinc": "四川",
    "city": "成都市",
    "area": "武侯区"
}, {
    "name": "四川成都市成华区",
    "match": "四川成都市成华区|SCCDSCHQ",
    "py": "CHQ",
    "provinc": "四川",
    "city": "成都市",
    "area": "成华区"
}, {
    "name": "四川成都市龙泉驿区",
    "match": "四川成都市龙泉驿区|SCCDSLQYQ",
    "py": "LQYQ",
    "provinc": "四川",
    "city": "成都市",
    "area": "龙泉驿区"
}, {
    "name": "四川成都市青白江区",
    "match": "四川成都市青白江区|SCCDSQBJQ",
    "py": "QBJQ",
    "provinc": "四川",
    "city": "成都市",
    "area": "青白江区"
}, {
    "name": "四川成都市新都区",
    "match": "四川成都市新都区|SCCDSXDQ",
    "py": "XDQ",
    "provinc": "四川",
    "city": "成都市",
    "area": "新都区"
}, {
    "name": "四川成都市温江区",
    "match": "四川成都市温江区|SCCDSWJQ",
    "py": "WJQ",
    "provinc": "四川",
    "city": "成都市",
    "area": "温江区"
}, {
    "name": "四川成都市金堂县",
    "match": "四川成都市金堂县|SCCDSJTX",
    "py": "JTX",
    "provinc": "四川",
    "city": "成都市",
    "area": "金堂县"
}, {
    "name": "四川成都市双流区",
    "match": "四川成都市双流区|SCCDSSLQ",
    "py": "SLQ",
    "provinc": "四川",
    "city": "成都市",
    "area": "双流区"
}, {
    "name": "四川成都市郫都区",
    "match": "四川成都市郫都区|SCCDSPDQ",
    "py": "PDQ",
    "provinc": "四川",
    "city": "成都市",
    "area": "郫都区"
}, {
    "name": "四川成都市大邑县",
    "match": "四川成都市大邑县|SCCDSDYX",
    "py": "DYX",
    "provinc": "四川",
    "city": "成都市",
    "area": "大邑县"
}, {
    "name": "四川成都市蒲江县",
    "match": "四川成都市蒲江县|SCCDSPJX",
    "py": "PJX",
    "provinc": "四川",
    "city": "成都市",
    "area": "蒲江县"
}, {
    "name": "四川成都市新津县",
    "match": "四川成都市新津县|SCCDSXJX",
    "py": "XJX",
    "provinc": "四川",
    "city": "成都市",
    "area": "新津县"
}, {
    "name": "四川成都市都江堰市",
    "match": "四川成都市都江堰市|SCCDSDJYS",
    "py": "DJYS",
    "provinc": "四川",
    "city": "成都市",
    "area": "都江堰市"
}, {
    "name": "四川成都市彭州市",
    "match": "四川成都市彭州市|SCCDSPZS",
    "py": "PZS",
    "provinc": "四川",
    "city": "成都市",
    "area": "彭州市"
}, {
    "name": "四川成都市邛崃市",
    "match": "四川成都市邛崃市|SCCDSQLS",
    "py": "QLS",
    "provinc": "四川",
    "city": "成都市",
    "area": "邛崃市"
}, {
    "name": "四川成都市崇州市",
    "match": "四川成都市崇州市|SCCDSCZS",
    "py": "CZS",
    "provinc": "四川",
    "city": "成都市",
    "area": "崇州市"
}, {
    "name": "四川攀枝花市",
    "match": "四川攀枝花市|SCPZHS",
    "py": "PZHS",
    "provinc": "四川",
    "city": "攀枝花市",
    "area": "攀枝花市"
}, {
    "name": "四川攀枝花市东区",
    "match": "四川攀枝花市东区|SCPZHSDQ",
    "py": "DQ",
    "provinc": "四川",
    "city": "攀枝花市",
    "area": "东区"
}, {
    "name": "四川攀枝花市西区",
    "match": "四川攀枝花市西区|SCPZHSXQ",
    "py": "XQ",
    "provinc": "四川",
    "city": "攀枝花市",
    "area": "西区"
}, {
    "name": "四川攀枝花市仁和区",
    "match": "四川攀枝花市仁和区|SCPZHSRHQ",
    "py": "RHQ",
    "provinc": "四川",
    "city": "攀枝花市",
    "area": "仁和区"
}, {
    "name": "四川攀枝花市米易县",
    "match": "四川攀枝花市米易县|SCPZHSMYX",
    "py": "MYX",
    "provinc": "四川",
    "city": "攀枝花市",
    "area": "米易县"
}, {
    "name": "四川攀枝花市盐边县",
    "match": "四川攀枝花市盐边县|SCPZHSYBX",
    "py": "YBX",
    "provinc": "四川",
    "city": "攀枝花市",
    "area": "盐边县"
}, {
    "name": "四川自贡市",
    "match": "四川自贡市|SCZGS",
    "py": "ZGS",
    "provinc": "四川",
    "city": "自贡市",
    "area": "自贡市"
}, {
    "name": "四川自贡市自流井区",
    "match": "四川自贡市自流井区|SCZGSZLJQ",
    "py": "ZLJQ",
    "provinc": "四川",
    "city": "自贡市",
    "area": "自流井区"
}, {
    "name": "四川自贡市贡井区",
    "match": "四川自贡市贡井区|SCZGSGJQ",
    "py": "GJQ",
    "provinc": "四川",
    "city": "自贡市",
    "area": "贡井区"
}, {
    "name": "四川自贡市大安区",
    "match": "四川自贡市大安区|SCZGSDAQ",
    "py": "DAQ",
    "provinc": "四川",
    "city": "自贡市",
    "area": "大安区"
}, {
    "name": "四川自贡市沿滩区",
    "match": "四川自贡市沿滩区|SCZGSYTQ",
    "py": "YTQ",
    "provinc": "四川",
    "city": "自贡市",
    "area": "沿滩区"
}, {
    "name": "四川自贡市荣县",
    "match": "四川自贡市荣县|SCZGSRX",
    "py": "RX",
    "provinc": "四川",
    "city": "自贡市",
    "area": "荣县"
}, {
    "name": "四川自贡市富顺县",
    "match": "四川自贡市富顺县|SCZGSFSX",
    "py": "FSX",
    "provinc": "四川",
    "city": "自贡市",
    "area": "富顺县"
}, {
    "name": "四川绵阳市",
    "match": "四川绵阳市|SCMYS",
    "py": "MYS",
    "provinc": "四川",
    "city": "绵阳市",
    "area": "绵阳市"
}, {
    "name": "四川绵阳市涪城区",
    "match": "四川绵阳市涪城区|SCMYSFCQ",
    "py": "FCQ",
    "provinc": "四川",
    "city": "绵阳市",
    "area": "涪城区"
}, {
    "name": "四川绵阳市游仙区",
    "match": "四川绵阳市游仙区|SCMYSYXQ",
    "py": "YXQ",
    "provinc": "四川",
    "city": "绵阳市",
    "area": "游仙区"
}, {
    "name": "四川绵阳市三台县",
    "match": "四川绵阳市三台县|SCMYSSTX",
    "py": "STX",
    "provinc": "四川",
    "city": "绵阳市",
    "area": "三台县"
}, {
    "name": "四川绵阳市盐亭县",
    "match": "四川绵阳市盐亭县|SCMYSYTX",
    "py": "YTX",
    "provinc": "四川",
    "city": "绵阳市",
    "area": "盐亭县"
}, {
    "name": "四川绵阳市安州区",
    "match": "四川绵阳市安州区|SCMYSAZQ",
    "py": "AZQ",
    "provinc": "四川",
    "city": "绵阳市",
    "area": "安州区"
}, {
    "name": "四川绵阳市梓潼县",
    "match": "四川绵阳市梓潼县|SCMYSZTX",
    "py": "ZTX",
    "provinc": "四川",
    "city": "绵阳市",
    "area": "梓潼县"
}, {
    "name": "四川绵阳市北川县",
    "match": "四川绵阳市北川县|SCMYSBCZZX",
    "py": "BCZZX",
    "provinc": "四川",
    "city": "绵阳市",
    "area": "北川县"
}, {
    "name": "四川绵阳市平武县",
    "match": "四川绵阳市平武县|SCMYSPWX",
    "py": "PWX",
    "provinc": "四川",
    "city": "绵阳市",
    "area": "平武县"
}, {
    "name": "四川绵阳市江油市",
    "match": "四川绵阳市江油市|SCMYSJYS",
    "py": "JYS",
    "provinc": "四川",
    "city": "绵阳市",
    "area": "江油市"
}, {
    "name": "四川南充市",
    "match": "四川南充市|SCNCS",
    "py": "NCS",
    "provinc": "四川",
    "city": "南充市",
    "area": "南充市"
}, {
    "name": "四川南充市顺庆区",
    "match": "四川南充市顺庆区|SCNCSSQQ",
    "py": "SQQ",
    "provinc": "四川",
    "city": "南充市",
    "area": "顺庆区"
}, {
    "name": "四川南充市高坪区",
    "match": "四川南充市高坪区|SCNCSGPQ",
    "py": "GPQ",
    "provinc": "四川",
    "city": "南充市",
    "area": "高坪区"
}, {
    "name": "四川南充市嘉陵区",
    "match": "四川南充市嘉陵区|SCNCSJLQ",
    "py": "JLQ",
    "provinc": "四川",
    "city": "南充市",
    "area": "嘉陵区"
}, {
    "name": "四川南充市南部县",
    "match": "四川南充市南部县|SCNCSNBX",
    "py": "NBX",
    "provinc": "四川",
    "city": "南充市",
    "area": "南部县"
}, {
    "name": "四川南充市营山县",
    "match": "四川南充市营山县|SCNCSYSX",
    "py": "YSX",
    "provinc": "四川",
    "city": "南充市",
    "area": "营山县"
}, {
    "name": "四川南充市蓬安县",
    "match": "四川南充市蓬安县|SCNCSPAX",
    "py": "PAX",
    "provinc": "四川",
    "city": "南充市",
    "area": "蓬安县"
}, {
    "name": "四川南充市仪陇县",
    "match": "四川南充市仪陇县|SCNCSYLX",
    "py": "YLX",
    "provinc": "四川",
    "city": "南充市",
    "area": "仪陇县"
}, {
    "name": "四川南充市西充县",
    "match": "四川南充市西充县|SCNCSXCX",
    "py": "XCX",
    "provinc": "四川",
    "city": "南充市",
    "area": "西充县"
}, {
    "name": "四川南充市阆中市",
    "match": "四川南充市阆中市|SCNCSLZS",
    "py": "LZS",
    "provinc": "四川",
    "city": "南充市",
    "area": "阆中市"
}, {
    "name": "四川达州市",
    "match": "四川达州市|SCDZS",
    "py": "DZS",
    "provinc": "四川",
    "city": "达州市",
    "area": "达州市"
}, {
    "name": "四川达州市通川区",
    "match": "四川达州市通川区|SCDZSTCQ",
    "py": "TCQ",
    "provinc": "四川",
    "city": "达州市",
    "area": "通川区"
}, {
    "name": "四川达州市达川区",
    "match": "四川达州市达川区|SCDZSDZQ",
    "py": "DZQ",
    "provinc": "四川",
    "city": "达州市",
    "area": "达川区"
}, {
    "name": "四川达州市宣汉县",
    "match": "四川达州市宣汉县|SCDZSXHX",
    "py": "XHX",
    "provinc": "四川",
    "city": "达州市",
    "area": "宣汉县"
}, {
    "name": "四川达州市开江县",
    "match": "四川达州市开江县|SCDZSKJX",
    "py": "KJX",
    "provinc": "四川",
    "city": "达州市",
    "area": "开江县"
}, {
    "name": "四川达州市大竹县",
    "match": "四川达州市大竹县|SCDZSDZX",
    "py": "DZX",
    "provinc": "四川",
    "city": "达州市",
    "area": "大竹县"
}, {
    "name": "四川达州市渠县",
    "match": "四川达州市渠县|SCDZSQX",
    "py": "QX",
    "provinc": "四川",
    "city": "达州市",
    "area": "渠县"
}, {
    "name": "四川达州市万源市",
    "match": "四川达州市万源市|SCDZSWYS",
    "py": "WYS",
    "provinc": "四川",
    "city": "达州市",
    "area": "万源市"
}, {
    "name": "四川遂宁市",
    "match": "四川遂宁市|SCSNS",
    "py": "SNS",
    "provinc": "四川",
    "city": "遂宁市",
    "area": "遂宁市"
}, {
    "name": "四川遂宁市船山区",
    "match": "四川遂宁市船山区|SCSNSCSQ",
    "py": "CSQ",
    "provinc": "四川",
    "city": "遂宁市",
    "area": "船山区"
}, {
    "name": "四川遂宁市安居区",
    "match": "四川遂宁市安居区|SCSNSAJQ",
    "py": "AJQ",
    "provinc": "四川",
    "city": "遂宁市",
    "area": "安居区"
}, {
    "name": "四川遂宁市蓬溪县",
    "match": "四川遂宁市蓬溪县|SCSNSPXX",
    "py": "PXX",
    "provinc": "四川",
    "city": "遂宁市",
    "area": "蓬溪县"
}, {
    "name": "四川遂宁市射洪县",
    "match": "四川遂宁市射洪县|SCSNSSHX",
    "py": "SHX",
    "provinc": "四川",
    "city": "遂宁市",
    "area": "射洪县"
}, {
    "name": "四川遂宁市大英县",
    "match": "四川遂宁市大英县|SCSNSDYX",
    "py": "DYX",
    "provinc": "四川",
    "city": "遂宁市",
    "area": "大英县"
}, {
    "name": "四川广安市",
    "match": "四川广安市|SCGAS",
    "py": "GAS",
    "provinc": "四川",
    "city": "广安市",
    "area": "广安市"
},

    /*ADD*/
    {
        "name": "四川广安市前锋区",
        "match": "四川广安市前锋区|SCGASQFQ",
        "py": "QFQ",
        "provinc": "四川",
        "city": "广安市",
        "area": "前锋区"
    },
    /*ADD*/

    {
    "name": "四川广安市广安区",
    "match": "四川广安市广安区|SCGASGAQ",
    "py": "GAQ",
    "provinc": "四川",
    "city": "广安市",
    "area": "广安区"
}, {
    "name": "四川广安市岳池县",
    "match": "四川广安市岳池县|SCGASYCX",
    "py": "YCX",
    "provinc": "四川",
    "city": "广安市",
    "area": "岳池县"
}, {
    "name": "四川广安市武胜县",
    "match": "四川广安市武胜县|SCGASWSX",
    "py": "WSX",
    "provinc": "四川",
    "city": "广安市",
    "area": "武胜县"
}, {
    "name": "四川广安市邻水县",
    "match": "四川广安市邻水县|SCGASLSX",
    "py": "LSX",
    "provinc": "四川",
    "city": "广安市",
    "area": "邻水县"
}, {
    "name": "四川广安市华蓥市",
    "match": "四川广安市华蓥市|SCGASHYS",
    "py": "HYS",
    "provinc": "四川",
    "city": "广安市",
    "area": "华蓥市"
}, {
    "name": "四川巴中市",
    "match": "四川巴中市|SCBZS",
    "py": "BZS",
    "provinc": "四川",
    "city": "巴中市",
    "area": "巴中市"
},

    /*ADD*/
    {
        "name": "四川巴中市恩阳区",
        "match": "四川巴中市恩阳区|SCBZSEYQ",
        "py": "EYQ",
        "provinc": "四川",
        "city": "巴中市",
        "area": "恩阳区"
    },
    /*Add*/


    {
    "name": "四川巴中市巴州区",
    "match": "四川巴中市巴州区|SCBZSBZQ",
    "py": "BZQ",
    "provinc": "四川",
    "city": "巴中市",
    "area": "巴州区"
}, {
    "name": "四川巴中市通江县",
    "match": "四川巴中市通江县|SCBZSTJX",
    "py": "TJX",
    "provinc": "四川",
    "city": "巴中市",
    "area": "通江县"
}, {
    "name": "四川巴中市南江县",
    "match": "四川巴中市南江县|SCBZSNJX",
    "py": "NJX",
    "provinc": "四川",
    "city": "巴中市",
    "area": "南江县"
}, {
    "name": "四川巴中市平昌县",
    "match": "四川巴中市平昌县|SCBZSPCX",
    "py": "PCX",
    "provinc": "四川",
    "city": "巴中市",
    "area": "平昌县"
}, {
    "name": "四川泸州市",
    "match": "四川泸州市|SCLZS",
    "py": "LZS",
    "provinc": "四川",
    "city": "泸州市",
    "area": "泸州市"
}, {
    "name": "四川泸州市江阳区",
    "match": "四川泸州市江阳区|SCLZSJYQ",
    "py": "JYQ",
    "provinc": "四川",
    "city": "泸州市",
    "area": "江阳区"
}, {
    "name": "四川泸州市纳溪区",
    "match": "四川泸州市纳溪区|SCLZSNXQ",
    "py": "NXQ",
    "provinc": "四川",
    "city": "泸州市",
    "area": "纳溪区"
}, {
    "name": "四川泸州市龙马潭区",
    "match": "四川泸州市龙马潭区|SCLZSLMTQ",
    "py": "LMTQ",
    "provinc": "四川",
    "city": "泸州市",
    "area": "龙马潭区"
}, {
    "name": "四川泸州市泸县",
    "match": "四川泸州市泸县|SCLZSLX",
    "py": "LX",
    "provinc": "四川",
    "city": "泸州市",
    "area": "泸县"
}, {
    "name": "四川泸州市合江县",
    "match": "四川泸州市合江县|SCLZSHJX",
    "py": "HJX",
    "provinc": "四川",
    "city": "泸州市",
    "area": "合江县"
}, {
    "name": "四川泸州市叙永县",
    "match": "四川泸州市叙永县|SCLZSXYX",
    "py": "XYX",
    "provinc": "四川",
    "city": "泸州市",
    "area": "叙永县"
}, {
    "name": "四川泸州市古蔺县",
    "match": "四川泸州市古蔺县|SCLZSGLX",
    "py": "GLX",
    "provinc": "四川",
    "city": "泸州市",
    "area": "古蔺县"
}, {
    "name": "四川宜宾市",
    "match": "四川宜宾市|SCYBS",
    "py": "YBS",
    "provinc": "四川",
    "city": "宜宾市",
    "area": "宜宾市"
}, {
    "name": "四川宜宾市翠屏区",
    "match": "四川宜宾市翠屏区|SCYBSCPQ",
    "py": "CPQ",
    "provinc": "四川",
    "city": "宜宾市",
    "area": "翠屏区"
}, {
    "name": "四川宜宾市宜宾县",
    "match": "四川宜宾市宜宾县|SCYBSYBX",
    "py": "YBX",
    "provinc": "四川",
    "city": "宜宾市",
    "area": "宜宾县"
}, {
    "name": "四川宜宾市南溪区",
    "match": "四川宜宾市南溪区|SCYBSNXQ",
    "py": "NXQ",
    "provinc": "四川",
    "city": "宜宾市",
    "area": "南溪区"
}, {
    "name": "四川宜宾市江安县",
    "match": "四川宜宾市江安县|SCYBSJAX",
    "py": "JAX",
    "provinc": "四川",
    "city": "宜宾市",
    "area": "江安县"
}, {
    "name": "四川宜宾市长宁县",
    "match": "四川宜宾市长宁县|SCYBSCNX",
    "py": "CNX",
    "provinc": "四川",
    "city": "宜宾市",
    "area": "长宁县"
}, {
    "name": "四川宜宾市高县",
    "match": "四川宜宾市高县|SCYBSGX",
    "py": "GX",
    "provinc": "四川",
    "city": "宜宾市",
    "area": "高县"
}, {
    "name": "四川宜宾市珙县",
    "match": "四川宜宾市珙县|SCYBSGX",
    "py": "GX",
    "provinc": "四川",
    "city": "宜宾市",
    "area": "珙县"
}, {
    "name": "四川宜宾市筠连县",
    "match": "四川宜宾市筠连县|SCYBSJLX",
    "py": "JLX",
    "provinc": "四川",
    "city": "宜宾市",
    "area": "筠连县"
}, {
    "name": "四川宜宾市兴文县",
    "match": "四川宜宾市兴文县|SCYBSXWX",
    "py": "XWX",
    "provinc": "四川",
    "city": "宜宾市",
    "area": "兴文县"
}, {
    "name": "四川宜宾市屏山县",
    "match": "四川宜宾市屏山县|SCYBSPSX",
    "py": "PSX",
    "provinc": "四川",
    "city": "宜宾市",
    "area": "屏山县"
}, {
    "name": "四川资阳市",
    "match": "四川资阳市|SCZYS",
    "py": "ZYS",
    "provinc": "四川",
    "city": "资阳市",
    "area": "资阳市"
}, {
    "name": "四川资阳市雁江区",
    "match": "四川资阳市雁江区|SCZYSYJQ",
    "py": "YJQ",
    "provinc": "四川",
    "city": "资阳市",
    "area": "雁江区"
}, {
    "name": "四川资阳市安岳县",
    "match": "四川资阳市安岳县|SCZYSAYX",
    "py": "AYX",
    "provinc": "四川",
    "city": "资阳市",
    "area": "安岳县"
}, {
    "name": "四川资阳市乐至县",
    "match": "四川资阳市乐至县|SCZYSLZX",
    "py": "LZX",
    "provinc": "四川",
    "city": "资阳市",
    "area": "乐至县"
}, /*{
    "name": "四川资阳市简阳市",
    "match": "四川资阳市简阳市|SCZYSJYS",
    "py": "JYS",
    "provinc": "四川",
    "city": "资阳市",
    "area": "简阳市"
},*/ {
    "name": "四川内江市",
    "match": "四川内江市|SCNJS",
    "py": "NJS",
    "provinc": "四川",
    "city": "内江市",
    "area": "内江市"
}, {
    "name": "四川内江市市中区",
    "match": "四川内江市市中区|SCNJSSZQ",
    "py": "SZQ",
    "provinc": "四川",
    "city": "内江市",
    "area": "市中区"
}, {
    "name": "四川内江市东兴区",
    "match": "四川内江市东兴区|SCNJSDXQ",
    "py": "DXQ",
    "provinc": "四川",
    "city": "内江市",
    "area": "东兴区"
}, {
    "name": "四川内江市威远县",
    "match": "四川内江市威远县|SCNJSWYX",
    "py": "WYX",
    "provinc": "四川",
    "city": "内江市",
    "area": "威远县"
}, {
    "name": "四川内江市资中县",
    "match": "四川内江市资中县|SCNJSZZX",
    "py": "ZZX",
    "provinc": "四川",
    "city": "内江市",
    "area": "资中县"
}, {
    "name": "四川内江市隆昌县",
    "match": "四川内江市隆昌县|SCNJSLCX",
    "py": "LCX",
    "provinc": "四川",
    "city": "内江市",
    "area": "隆昌县"
}, {
    "name": "四川乐山市",
    "match": "四川乐山市|SCLSS",
    "py": "LSS",
    "provinc": "四川",
    "city": "乐山市",
    "area": "乐山市"
}, {
    "name": "四川乐山市市中区",
    "match": "四川乐山市市中区|SCLSSSZQ",
    "py": "SZQ",
    "provinc": "四川",
    "city": "乐山市",
    "area": "市中区"
}, {
    "name": "四川乐山市沙湾区",
    "match": "四川乐山市沙湾区|SCLSSSWQ",
    "py": "SWQ",
    "provinc": "四川",
    "city": "乐山市",
    "area": "沙湾区"
}, {
    "name": "四川乐山市五通桥区",
    "match": "四川乐山市五通桥区|SCLSSWTQQ",
    "py": "WTQQ",
    "provinc": "四川",
    "city": "乐山市",
    "area": "五通桥区"
}, {
    "name": "四川乐山市金口河区",
    "match": "四川乐山市金口河区|SCLSSJKHQ",
    "py": "JKHQ",
    "provinc": "四川",
    "city": "乐山市",
    "area": "金口河区"
}, {
    "name": "四川乐山市犍为县",
    "match": "四川乐山市犍为县|SCLSSQWX",
    "py": "QWX",
    "provinc": "四川",
    "city": "乐山市",
    "area": "犍为县"
}, {
    "name": "四川乐山市井研县",
    "match": "四川乐山市井研县|SCLSSJYX",
    "py": "JYX",
    "provinc": "四川",
    "city": "乐山市",
    "area": "井研县"
}, {
    "name": "四川乐山市夹江县",
    "match": "四川乐山市夹江县|SCLSSJJX",
    "py": "JJX",
    "provinc": "四川",
    "city": "乐山市",
    "area": "夹江县"
}, {
    "name": "四川乐山市沐川县",
    "match": "四川乐山市沐川县|SCLSSMCX",
    "py": "MCX",
    "provinc": "四川",
    "city": "乐山市",
    "area": "沐川县"
}, {
    "name": "四川乐山市峨边县",
    "match": "四川乐山市峨边县|SCLSSEBZZX",
    "py": "EBZZX",
    "provinc": "四川",
    "city": "乐山市",
    "area": "峨边县"
}, {
    "name": "四川乐山市马边县",
    "match": "四川乐山市马边县|SCLSSMBZZX",
    "py": "MBZZX",
    "provinc": "四川",
    "city": "乐山市",
    "area": "马边县"
}, {
    "name": "四川乐山市峨眉山市",
    "match": "四川乐山市峨眉山市|SCLSSEMSS",
    "py": "EMSS",
    "provinc": "四川",
    "city": "乐山市",
    "area": "峨眉山市"
}, {
    "name": "四川眉山市",
    "match": "四川眉山市|SCMSS",
    "py": "MSS",
    "provinc": "四川",
    "city": "眉山市",
    "area": "眉山市"
}, {
    "name": "四川眉山市东坡区",
    "match": "四川眉山市东坡区|SCMSSDPQ",
    "py": "DPQ",
    "provinc": "四川",
    "city": "眉山市",
    "area": "东坡区"
}, {
    "name": "四川眉山市仁寿县",
    "match": "四川眉山市仁寿县|SCMSSRSX",
    "py": "RSX",
    "provinc": "四川",
    "city": "眉山市",
    "area": "仁寿县"
}, {
    "name": "四川眉山市彭山区",
    "match": "四川眉山市彭山区|SCMSSPSQ",
    "py": "PSQ",
    "provinc": "四川",
    "city": "眉山市",
    "area": "彭山区"
}, {
    "name": "四川眉山市洪雅县",
    "match": "四川眉山市洪雅县|SCMSSHYX",
    "py": "HYX",
    "provinc": "四川",
    "city": "眉山市",
    "area": "洪雅县"
}, {
    "name": "四川眉山市丹棱县",
    "match": "四川眉山市丹棱县|SCMSSDLX",
    "py": "DLX",
    "provinc": "四川",
    "city": "眉山市",
    "area": "丹棱县"
}, {
    "name": "四川眉山市青神县",
    "match": "四川眉山市青神县|SCMSSQSX",
    "py": "QSX",
    "provinc": "四川",
    "city": "眉山市",
    "area": "青神县"
}, {
    "name": "四川凉山州",
    "match": "四川凉山州|SCLSZZZ",
    "py": "LSZZZ",
    "provinc": "四川",
    "city": "凉山州",
    "area": "凉山州"
}, {
    "name": "四川凉山州西昌市",
    "match": "四川凉山州西昌市|SCLSZZZXCS",
    "py": "XCS",
    "provinc": "四川",
    "city": "凉山州",
    "area": "西昌市"
}, {
    "name": "四川凉山州木里县",
    "match": "四川凉山州木里县|SCLSZZZMLZZX",
    "py": "MLZZX",
    "provinc": "四川",
    "city": "凉山州",
    "area": "木里县"
}, {
    "name": "四川凉山州盐源县",
    "match": "四川凉山州盐源县|SCLSZZZYYX",
    "py": "YYX",
    "provinc": "四川",
    "city": "凉山州",
    "area": "盐源县"
}, {
    "name": "四川凉山州德昌县",
    "match": "四川凉山州德昌县|SCLSZZZDCX",
    "py": "DCX",
    "provinc": "四川",
    "city": "凉山州",
    "area": "德昌县"
}, {
    "name": "四川凉山州会理县",
    "match": "四川凉山州会理县|SCLSZZZHLX",
    "py": "HLX",
    "provinc": "四川",
    "city": "凉山州",
    "area": "会理县"
}, {
    "name": "四川凉山州会东县",
    "match": "四川凉山州会东县|SCLSZZZHDX",
    "py": "HDX",
    "provinc": "四川",
    "city": "凉山州",
    "area": "会东县"
}, {
    "name": "四川凉山州宁南县",
    "match": "四川凉山州宁南县|SCLSZZZNNX",
    "py": "NNX",
    "provinc": "四川",
    "city": "凉山州",
    "area": "宁南县"
}, {
    "name": "四川凉山州普格县",
    "match": "四川凉山州普格县|SCLSZZZPGX",
    "py": "PGX",
    "provinc": "四川",
    "city": "凉山州",
    "area": "普格县"
}, {
    "name": "四川凉山州布拖县",
    "match": "四川凉山州布拖县|SCLSZZZBTX",
    "py": "BTX",
    "provinc": "四川",
    "city": "凉山州",
    "area": "布拖县"
}, {
    "name": "四川凉山州金阳县",
    "match": "四川凉山州金阳县|SCLSZZZJYX",
    "py": "JYX",
    "provinc": "四川",
    "city": "凉山州",
    "area": "金阳县"
}, {
    "name": "四川凉山州昭觉县",
    "match": "四川凉山州昭觉县|SCLSZZZZJX",
    "py": "ZJX",
    "provinc": "四川",
    "city": "凉山州",
    "area": "昭觉县"
}, {
    "name": "四川凉山州喜德县",
    "match": "四川凉山州喜德县|SCLSZZZXDX",
    "py": "XDX",
    "provinc": "四川",
    "city": "凉山州",
    "area": "喜德县"
}, {
    "name": "四川凉山州冕宁县",
    "match": "四川凉山州冕宁县|SCLSZZZMNX",
    "py": "MNX",
    "provinc": "四川",
    "city": "凉山州",
    "area": "冕宁县"
}, {
    "name": "四川凉山州越西县",
    "match": "四川凉山州越西县|SCLSZZZYXX",
    "py": "YXX",
    "provinc": "四川",
    "city": "凉山州",
    "area": "越西县"
}, {
    "name": "四川凉山州甘洛县",
    "match": "四川凉山州甘洛县|SCLSZZZGLX",
    "py": "GLX",
    "provinc": "四川",
    "city": "凉山州",
    "area": "甘洛县"
}, {
    "name": "四川凉山州美姑县",
    "match": "四川凉山州美姑县|SCLSZZZMGX",
    "py": "MGX",
    "provinc": "四川",
    "city": "凉山州",
    "area": "美姑县"
}, {
    "name": "四川凉山州雷波县",
    "match": "四川凉山州雷波县|SCLSZZZLBX",
    "py": "LBX",
    "provinc": "四川",
    "city": "凉山州",
    "area": "雷波县"
}, {
    "name": "四川雅安市",
    "match": "四川雅安市|SCYAS",
    "py": "YAS",
    "provinc": "四川",
    "city": "雅安市",
    "area": "雅安市"
}, {
    "name": "四川雅安市雨城区",
    "match": "四川雅安市雨城区|SCYASYCQ",
    "py": "YCQ",
    "provinc": "四川",
    "city": "雅安市",
    "area": "雨城区"
}, {
    "name": "四川雅安市名山区",
    "match": "四川雅安市名山区|SCYASMSQ",
    "py": "MSQ",
    "provinc": "四川",
    "city": "雅安市",
    "area": "名山区"
}, {
    "name": "四川雅安市荥经县",
    "match": "四川雅安市荥经县|SCYASYJX",
    "py": "YJX",
    "provinc": "四川",
    "city": "雅安市",
    "area": "荥经县"
}, {
    "name": "四川雅安市汉源县",
    "match": "四川雅安市汉源县|SCYASHYX",
    "py": "HYX",
    "provinc": "四川",
    "city": "雅安市",
    "area": "汉源县"
}, {
    "name": "四川雅安市石棉县",
    "match": "四川雅安市石棉县|SCYASSMX",
    "py": "SMX",
    "provinc": "四川",
    "city": "雅安市",
    "area": "石棉县"
}, {
    "name": "四川雅安市天全县",
    "match": "四川雅安市天全县|SCYASTQX",
    "py": "TQX",
    "provinc": "四川",
    "city": "雅安市",
    "area": "天全县"
}, {
    "name": "四川雅安市芦山县",
    "match": "四川雅安市芦山县|SCYASLSX",
    "py": "LSX",
    "provinc": "四川",
    "city": "雅安市",
    "area": "芦山县"
}, {
    "name": "四川雅安市宝兴县",
    "match": "四川雅安市宝兴县|SCYASBXX",
    "py": "BXX",
    "provinc": "四川",
    "city": "雅安市",
    "area": "宝兴县"
}, {
    "name": "四川甘孜州",
    "match": "四川甘孜州|SCGZZZZ",
    "py": "GZZZZ",
    "provinc": "四川",
    "city": "甘孜州",
    "area": "甘孜州"
}, {
    "name": "四川甘孜州康定市",
    "match": "四川甘孜州康定市|SCGZZZZKDS",
    "py": "KDS",
    "provinc": "四川",
    "city": "甘孜州",
    "area": "康定市"
}, {
    "name": "四川甘孜州泸定县",
    "match": "四川甘孜州泸定县|SCGZZZZLDX",
    "py": "LDX",
    "provinc": "四川",
    "city": "甘孜州",
    "area": "泸定县"
}, {
    "name": "四川甘孜州丹巴县",
    "match": "四川甘孜州丹巴县|SCGZZZZDBX",
    "py": "DBX",
    "provinc": "四川",
    "city": "甘孜州",
    "area": "丹巴县"
}, {
    "name": "四川甘孜州九龙县",
    "match": "四川甘孜州九龙县|SCGZZZZJLX",
    "py": "JLX",
    "provinc": "四川",
    "city": "甘孜州",
    "area": "九龙县"
}, {
    "name": "四川甘孜州雅江县",
    "match": "四川甘孜州雅江县|SCGZZZZYJX",
    "py": "YJX",
    "provinc": "四川",
    "city": "甘孜州",
    "area": "雅江县"
}, {
    "name": "四川甘孜州道孚县",
    "match": "四川甘孜州道孚县|SCGZZZZDFX",
    "py": "DFX",
    "provinc": "四川",
    "city": "甘孜州",
    "area": "道孚县"
}, {
    "name": "四川甘孜州炉霍县",
    "match": "四川甘孜州炉霍县|SCGZZZZLHX",
    "py": "LHX",
    "provinc": "四川",
    "city": "甘孜州",
    "area": "炉霍县"
}, {
    "name": "四川甘孜州甘孜县",
    "match": "四川甘孜州甘孜县|SCGZZZZGZX",
    "py": "GZX",
    "provinc": "四川",
    "city": "甘孜州",
    "area": "甘孜县"
}, {
    "name": "四川甘孜州新龙县",
    "match": "四川甘孜州新龙县|SCGZZZZXLX",
    "py": "XLX",
    "provinc": "四川",
    "city": "甘孜州",
    "area": "新龙县"
}, {
    "name": "四川甘孜州德格县",
    "match": "四川甘孜州德格县|SCGZZZZDGX",
    "py": "DGX",
    "provinc": "四川",
    "city": "甘孜州",
    "area": "德格县"
}, {
    "name": "四川甘孜州白玉县",
    "match": "四川甘孜州白玉县|SCGZZZZBYX",
    "py": "BYX",
    "provinc": "四川",
    "city": "甘孜州",
    "area": "白玉县"
}, {
    "name": "四川甘孜州石渠县",
    "match": "四川甘孜州石渠县|SCGZZZZSQX",
    "py": "SQX",
    "provinc": "四川",
    "city": "甘孜州",
    "area": "石渠县"
}, {
    "name": "四川甘孜州色达县",
    "match": "四川甘孜州色达县|SCGZZZZSDX",
    "py": "SDX",
    "provinc": "四川",
    "city": "甘孜州",
    "area": "色达县"
}, {
    "name": "四川甘孜州理塘县",
    "match": "四川甘孜州理塘县|SCGZZZZLTX",
    "py": "LTX",
    "provinc": "四川",
    "city": "甘孜州",
    "area": "理塘县"
}, {
    "name": "四川甘孜州巴塘县",
    "match": "四川甘孜州巴塘县|SCGZZZZBTX",
    "py": "BTX",
    "provinc": "四川",
    "city": "甘孜州",
    "area": "巴塘县"
}, {
    "name": "四川甘孜州乡城县",
    "match": "四川甘孜州乡城县|SCGZZZZXCX",
    "py": "XCX",
    "provinc": "四川",
    "city": "甘孜州",
    "area": "乡城县"
}, {
    "name": "四川甘孜州稻城县",
    "match": "四川甘孜州稻城县|SCGZZZZDCX",
    "py": "DCX",
    "provinc": "四川",
    "city": "甘孜州",
    "area": "稻城县"
}, {
    "name": "四川甘孜州得荣县",
    "match": "四川甘孜州得荣县|SCGZZZZDRX",
    "py": "DRX",
    "provinc": "四川",
    "city": "甘孜州",
    "area": "得荣县"
}, {
    "name": "四川阿坝州",
    "match": "四川阿坝州|SCABZZZ",
    "py": "ABZZZ",
    "provinc": "四川",
    "city": "阿坝州",
    "area": "阿坝州"
}, {
    "name": "四川阿坝州汶川县",
    "match": "四川阿坝州汶川县|SCABZZZWCX",
    "py": "WCX",
    "provinc": "四川",
    "city": "阿坝州",
    "area": "汶川县"
}, {
    "name": "四川阿坝州理县",
    "match": "四川阿坝州理县|SCABZZZLX",
    "py": "LX",
    "provinc": "四川",
    "city": "阿坝州",
    "area": "理县"
}, {
    "name": "四川阿坝州茂县",
    "match": "四川阿坝州茂县|SCABZZZMX",
    "py": "MX",
    "provinc": "四川",
    "city": "阿坝州",
    "area": "茂县"
}, {
    "name": "四川阿坝州松潘县",
    "match": "四川阿坝州松潘县|SCABZZZSPX",
    "py": "SPX",
    "provinc": "四川",
    "city": "阿坝州",
    "area": "松潘县"
}, {
    "name": "四川阿坝州九寨沟县",
    "match": "四川阿坝州九寨沟县|SCABZZZJZGX",
    "py": "JZGX",
    "provinc": "四川",
    "city": "阿坝州",
    "area": "九寨沟县"
}, {
    "name": "四川阿坝州金川县",
    "match": "四川阿坝州金川县|SCABZZZJCX",
    "py": "JCX",
    "provinc": "四川",
    "city": "阿坝州",
    "area": "金川县"
}, {
    "name": "四川阿坝州小金县",
    "match": "四川阿坝州小金县|SCABZZZXJX",
    "py": "XJX",
    "provinc": "四川",
    "city": "阿坝州",
    "area": "小金县"
}, {
    "name": "四川阿坝州黑水县",
    "match": "四川阿坝州黑水县|SCABZZZHSX",
    "py": "HSX",
    "provinc": "四川",
    "city": "阿坝州",
    "area": "黑水县"
}, {
    "name": "四川阿坝州马尔康市",
    "match": "四川阿坝州马尔康市|SCABZZZMEKS",
    "py": "MEKS",
    "provinc": "四川",
    "city": "阿坝州",
    "area": "马尔康市"
}, {
    "name": "四川阿坝州壤塘县",
    "match": "四川阿坝州壤塘县|SCABZZZRTX",
    "py": "RTX",
    "provinc": "四川",
    "city": "阿坝州",
    "area": "壤塘县"
}, {
    "name": "四川阿坝州阿坝县",
    "match": "四川阿坝州阿坝县|SCABZZZABX",
    "py": "ABX",
    "provinc": "四川",
    "city": "阿坝州",
    "area": "阿坝县"
}, {
    "name": "四川阿坝州若尔盖县",
    "match": "四川阿坝州若尔盖县|SCABZZZREGX",
    "py": "REGX",
    "provinc": "四川",
    "city": "阿坝州",
    "area": "若尔盖县"
}, {
    "name": "四川阿坝州红原县",
    "match": "四川阿坝州红原县|SCABZZZHYX",
    "py": "HYX",
    "provinc": "四川",
    "city": "阿坝州",
    "area": "红原县"
}, {
    "name": "四川德阳市",
    "match": "四川德阳市|SCDYS",
    "py": "DYS",
    "provinc": "四川",
    "city": "德阳市",
    "area": "德阳市"
}, {
    "name": "四川德阳市旌阳区",
    "match": "四川德阳市旌阳区|SCDYSJYQ",
    "py": "JYQ",
    "provinc": "四川",
    "city": "德阳市",
    "area": "旌阳区"
}, {
    "name": "四川德阳市中江县",
    "match": "四川德阳市中江县|SCDYSZJX",
    "py": "ZJX",
    "provinc": "四川",
    "city": "德阳市",
    "area": "中江县"
}, {
    "name": "四川德阳市罗江区",
    "match": "四川德阳市罗江区|SCDYSLJQ",
    "py": "LJQ",
    "provinc": "四川",
    "city": "德阳市",
    "area": "罗江区"
}, {
    "name": "四川德阳市广汉市",
    "match": "四川德阳市广汉市|SCDYSGHS",
    "py": "GHS",
    "provinc": "四川",
    "city": "德阳市",
    "area": "广汉市"
}, {
    "name": "四川德阳市什邡市",
    "match": "四川德阳市什邡市|SCDYSSFS",
    "py": "SFS",
    "provinc": "四川",
    "city": "德阳市",
    "area": "什邡市"
}, {
    "name": "四川德阳市绵竹市",
    "match": "四川德阳市绵竹市|SCDYSMZS",
    "py": "MZS",
    "provinc": "四川",
    "city": "德阳市",
    "area": "绵竹市"
}, {
    "name": "四川广元市",
    "match": "四川广元市|SCGYS",
    "py": "GYS",
    "provinc": "四川",
    "city": "广元市",
    "area": "广元市"
}, {
    "name": "四川广元市利州区",
    "match": "四川广元市利州区|SCGYSLZQ",
    "py": "LZQ",
    "provinc": "四川",
    "city": "广元市",
    "area": "利州区"
}, {
    "name": "四川广元市昭化区",
    "match": "四川广元市昭化区|SCGYSZHQ",
    "py": "ZHQ",
    "provinc": "四川",
    "city": "广元市",
    "area": "昭化区"
}, {
    "name": "四川广元市朝天区",
    "match": "四川广元市朝天区|SCGYSCTQ",
    "py": "CTQ",
    "provinc": "四川",
    "city": "广元市",
    "area": "朝天区"
}, {
    "name": "四川广元市旺苍县",
    "match": "四川广元市旺苍县|SCGYSWCX",
    "py": "WCX",
    "provinc": "四川",
    "city": "广元市",
    "area": "旺苍县"
}, {
    "name": "四川广元市青川县",
    "match": "四川广元市青川县|SCGYSQCX",
    "py": "QCX",
    "provinc": "四川",
    "city": "广元市",
    "area": "青川县"
}, {
    "name": "四川广元市剑阁县",
    "match": "四川广元市剑阁县|SCGYSJGX",
    "py": "JGX",
    "provinc": "四川",
    "city": "广元市",
    "area": "剑阁县"
}, {
    "name": "四川广元市苍溪县",
    "match": "四川广元市苍溪县|SCGYSCXX",
    "py": "CXX",
    "provinc": "四川",
    "city": "广元市",
    "area": "苍溪县"
}, {
    "name": "天津市",
    "match": "天津市|TJS",
    "py": "TJS",
    "provinc": "天津",
    "city": "天津市",
    "area": "天津市"
}, {
    "name": "天津和平区",
    "match": "天津市和平区|TJSHPQ",
    "py": "HPQ",
    "provinc": "天津",
    "city": "天津市",
    "area": "和平区"
}, {
    "name": "天津河东区",
    "match": "天津市河东区|TJSHDQ",
    "py": "HDQ",
    "provinc": "天津",
    "city": "天津市",
    "area": "河东区"
}, {
    "name": "天津河西区",
    "match": "天津市河西区|TJS香港",
    "py": "香港",
    "provinc": "天津",
    "city": "天津市",
    "area": "河西区"
}, {
    "name": "天津南开区",
    "match": "天津市南开区|TJSNKQ",
    "py": "NKQ",
    "provinc": "天津",
    "city": "天津市",
    "area": "南开区"
}, {
    "name": "天津河北区",
    "match": "天津市河北区|TJSHBQ",
    "py": "HBQ",
    "provinc": "天津",
    "city": "天津市",
    "area": "河北区"
}, {
    "name": "天津红桥区",
    "match": "天津市红桥区|TJSHQQ",
    "py": "HQQ",
    "provinc": "天津",
    "city": "天津市",
    "area": "红桥区"
}, /*{
    "name": "天津塘沽区",
    "match": "天津市塘沽区|TJSTGQ",
    "py": "TGQ",
    "provinc": "天津",
    "city": "天津市",
    "area": "塘沽区"
}, {
    "name": "天津汉沽区",
    "match": "天津市汉沽区|TJSHGQ",
    "py": "HGQ",
    "provinc": "天津",
    "city": "天津市",
    "area": "汉沽区"
},{
    "name": "天津大港区",
    "match": "天津市大港区|TJSDGQ",
    "py": "DGQ",
    "provinc": "天津",
    "city": "天津市",
    "area": "大港区"
},*/  {
    "name": "天津东丽区",
    "match": "天津市东丽区|TJSDLQ",
    "py": "DLQ",
    "provinc": "天津",
    "city": "天津市",
    "area": "东丽区"
}, {
    "name": "天津西青区",
    "match": "天津市西青区|TJSXQQ",
    "py": "XQQ",
    "provinc": "天津",
    "city": "天津市",
    "area": "西青区"
}, {
    "name": "天津津南区",
    "match": "天津市津南区|TJSJNQ",
    "py": "JNQ",
    "provinc": "天津",
    "city": "天津市",
    "area": "津南区"
}, {
    "name": "天津北辰区",
    "match": "天津市北辰区|TJSBCQ",
    "py": "BCQ",
    "provinc": "天津",
    "city": "天津市",
    "area": "北辰区"
}, {
    "name": "天津武清区",
    "match": "天津市武清区|TJSWQQ",
    "py": "WQQ",
    "provinc": "天津",
    "city": "天津市",
    "area": "武清区"
}, {
    "name": "天津宝坻区",
    "match": "天津市宝坻区|TJSBDQ",
    "py": "BDQ",
    "provinc": "天津",
    "city": "天津市",
    "area": "宝坻区"
}, {
    "name": "天津宁河区",
    "match": "天津市宁河区|TJSNHQ",
    "py": "NHQ",
    "provinc": "天津",
    "city": "天津市",
    "area": "宁河区"
}, {
    "name": "天津静海区",
    "match": "天津市静海区|TJSJHQ",
    "py": "JHQ",
    "provinc": "天津",
    "city": "天津市",
    "area": "静海区"
}, {
    "name": "天津蓟州区",
    "match": "天津市蓟州区|TJSJZQ",
    "py": "JZQ",
    "provinc": "天津",
    "city": "天津市",
    "area": "蓟州区"
},


    /*ADD*/
    {
        "name": "天津滨海新区",
        "match": "天津滨海新区|TJSBHXQ",
        "py": "BHXQ",
        "provinc": "天津",
        "city": "天津市",
        "area": "滨海新区"
    },
    /*ADD*/


    {
    "name": "宁夏银川市",
    "match": "宁夏银川市|NXYCS",
    "py": "YCS",
    "provinc": "宁夏",
    "city": "银川市",
    "area": "银川市"
}, {
    "name": "宁夏银川市兴庆区",
    "match": "宁夏银川市兴庆区|NXYCSXQQ",
    "py": "XQQ",
    "provinc": "宁夏",
    "city": "银川市",
    "area": "兴庆区"
}, {
    "name": "宁夏银川市西夏区",
    "match": "宁夏银川市西夏区|NXYCSXXQ",
    "py": "XXQ",
    "provinc": "宁夏",
    "city": "银川市",
    "area": "西夏区"
}, {
    "name": "宁夏银川市金凤区",
    "match": "宁夏银川市金凤区|NXYCSJFQ",
    "py": "JFQ",
    "provinc": "宁夏",
    "city": "银川市",
    "area": "金凤区"
}, {
    "name": "宁夏银川市永宁县",
    "match": "宁夏银川市永宁县|NXYCSYNX",
    "py": "YNX",
    "provinc": "宁夏",
    "city": "银川市",
    "area": "永宁县"
}, {
    "name": "宁夏银川市贺兰县",
    "match": "宁夏银川市贺兰县|NXYCSHLX",
    "py": "HLX",
    "provinc": "宁夏",
    "city": "银川市",
    "area": "贺兰县"
}, {
    "name": "宁夏银川市灵武市",
    "match": "宁夏银川市灵武市|NXYCSLWS",
    "py": "LWS",
    "provinc": "宁夏",
    "city": "银川市",
    "area": "灵武市"
}, {
    "name": "宁夏石嘴山市",
    "match": "宁夏石嘴山市|NXSZSS",
    "py": "SZSS",
    "provinc": "宁夏",
    "city": "石嘴山市",
    "area": "石嘴山市"
}, {
    "name": "宁夏石嘴山市大武口区",
    "match": "宁夏石嘴山市大武口区|NXSZSSDWKQ",
    "py": "DWKQ",
    "provinc": "宁夏",
    "city": "石嘴山市",
    "area": "大武口区"
}, {
    "name": "宁夏石嘴山市惠农区",
    "match": "宁夏石嘴山市惠农区|NXSZSSHNQ",
    "py": "HNQ",
    "provinc": "宁夏",
    "city": "石嘴山市",
    "area": "惠农区"
}, {
    "name": "宁夏石嘴山市平罗县",
    "match": "宁夏石嘴山市平罗县|NXSZSSPLX",
    "py": "PLX",
    "provinc": "宁夏",
    "city": "石嘴山市",
    "area": "平罗县"
}, {
    "name": "宁夏吴忠市",
    "match": "宁夏吴忠市|NXWZS",
    "py": "WZS",
    "provinc": "宁夏",
    "city": "吴忠市",
    "area": "吴忠市"
},

    /*ADD*/
    {
        "name": "宁夏吴忠市红寺堡区",
        "match": "宁夏吴忠市红寺堡区|NXWZSHSBQ",
        "py": "HSBQ",
        "provinc": "宁夏",
        "city": "吴忠市",
        "area": "红寺堡区"
    },
    /*ADD*/

    {
    "name": "宁夏吴忠市利通区",
    "match": "宁夏吴忠市利通区|NXWZSLTQ",
    "py": "LTQ",
    "provinc": "宁夏",
    "city": "吴忠市",
    "area": "利通区"
}, {
    "name": "宁夏吴忠市盐池县",
    "match": "宁夏吴忠市盐池县|NXWZSYCX",
    "py": "YCX",
    "provinc": "宁夏",
    "city": "吴忠市",
    "area": "盐池县"
}, {
    "name": "宁夏吴忠市同心县",
    "match": "宁夏吴忠市同心县|NXWZSTXX",
    "py": "TXX",
    "provinc": "宁夏",
    "city": "吴忠市",
    "area": "同心县"
}, {
    "name": "宁夏吴忠市青铜峡市",
    "match": "宁夏吴忠市青铜峡市|NXWZSQTXS",
    "py": "QTXS",
    "provinc": "宁夏",
    "city": "吴忠市",
    "area": "青铜峡市"
}, {
    "name": "宁夏固原市",
    "match": "宁夏固原市|NXGYS",
    "py": "GYS",
    "provinc": "宁夏",
    "city": "固原市",
    "area": "固原市"
}, {
    "name": "宁夏固原市原州区",
    "match": "宁夏固原市原州区|NXGYSYZQ",
    "py": "YZQ",
    "provinc": "宁夏",
    "city": "固原市",
    "area": "原州区"
}, {
    "name": "宁夏固原市西吉县",
    "match": "宁夏固原市西吉县|NXGYSXJX",
    "py": "XJX",
    "provinc": "宁夏",
    "city": "固原市",
    "area": "西吉县"
}, {
    "name": "宁夏固原市隆德县",
    "match": "宁夏固原市隆德县|NXGYSLDX",
    "py": "LDX",
    "provinc": "宁夏",
    "city": "固原市",
    "area": "隆德县"
}, {
    "name": "宁夏固原市泾源县",
    "match": "宁夏固原市泾源县|NXGYSJYX",
    "py": "JYX",
    "provinc": "宁夏",
    "city": "固原市",
    "area": "泾源县"
}, {
    "name": "宁夏固原市彭阳县",
    "match": "宁夏固原市彭阳县|NXGYSPYX",
    "py": "PYX",
    "provinc": "宁夏",
    "city": "固原市",
    "area": "彭阳县"
}, {
    "name": "宁夏中卫市",
    "match": "宁夏中卫市|NXZWS",
    "py": "ZWS",
    "provinc": "宁夏",
    "city": "中卫市",
    "area": "中卫市"
}, {
    "name": "宁夏中卫市沙坡头区",
    "match": "宁夏中卫市沙坡头区|NXZWSSPTQ",
    "py": "SPTQ",
    "provinc": "宁夏",
    "city": "中卫市",
    "area": "沙坡头区"
}, {
    "name": "宁夏中卫市中宁县",
    "match": "宁夏中卫市中宁县|NXZWSZNX",
    "py": "ZNX",
    "provinc": "宁夏",
    "city": "中卫市",
    "area": "中宁县"
}, {
    "name": "宁夏中卫市海原县",
    "match": "宁夏中卫市海原县|NXZWSHYX",
    "py": "HYX",
    "provinc": "宁夏",
    "city": "中卫市",
    "area": "海原县"
}, {
    "name": "安徽合肥市",
    "match": "安徽合肥市|AHHFS",
    "py": "HFS",
    "provinc": "安徽",
    "city": "合肥市",
    "area": "合肥市"
},

    /*ADD*/
    {
        "name": "安徽合肥市庐江县",
        "match": "安徽合肥市庐江县|AHHFSLJX",
        "py": "LJX",
        "provinc": "安徽",
        "city": "合肥市",
        "area": "庐江县"
    },
    {
        "name": "安徽合肥市滨湖新区",
        "match": "安徽合肥市滨湖新区|AHHFSBHXQ",
        "py": "BHXQ",
        "provinc": "安徽",
        "city": "合肥市",
        "area": "滨湖新区"
    },
    {
        "name": "安徽合肥市经济技术开发区",
        "match": "安徽合肥市经济技术开发区|AHHFSJJJSKFQ",
        "py": "JJJSKFQ",
        "provinc": "安徽",
        "city": "合肥市",
        "area": "经济技术开发区"
    },
    {
        "name": "安徽合肥市高新技术开发区",
        "match": "安徽合肥市高新技术开发区|AHHFSGXJSKFQ",
        "py": "GXJSKFQ",
        "provinc": "安徽",
        "city": "合肥市",
        "area": "高新技术开发区"
    },
    {
        "name": "安徽合肥市新站综合开发试验区",
        "match": "安徽合肥市新站综合开发试验区|AHHFSXZZHKFSYQ",
        "py": "XZZHKFSYQ",
        "provinc": "安徽",
        "city": "合肥市",
        "area": "新站综合开发试验区"
    },
    {
        "name": "安徽合肥市政务文化新区",
        "match": "安徽合肥市政务文化新区|AHHFSZWWHXQ",
        "py": "ZWWHXQ",
        "provinc": "安徽",
        "city": "合肥市",
        "area": "政务文化新区"
    },
    {
        "name": "安徽合肥市巢湖市",
        "match": "安徽合肥市巢湖市|AHHFSCHS",
        "py": "CHS",
        "provinc": "安徽",
        "city": "合肥市",
        "area": "巢湖市"
    },
    {
        "name": "安徽合肥市北城新区",
        "match": "安徽合肥市北城新区|AHHFSBCXQ",
        "py": "BCXQ",
        "provinc": "安徽",
        "city": "合肥市",
        "area": "北城新区"
    },
    /*ADD*/

    {
    "name": "安徽合肥市瑶海区",
    "match": "安徽合肥市瑶海区|AHHFSYHQ",
    "py": "YHQ",
    "provinc": "安徽",
    "city": "合肥市",
    "area": "瑶海区"
}, {
    "name": "安徽合肥市庐阳区",
    "match": "安徽合肥市庐阳区|AHHFSLYQ",
    "py": "LYQ",
    "provinc": "安徽",
    "city": "合肥市",
    "area": "庐阳区"
}, {
    "name": "安徽合肥市蜀山区",
    "match": "安徽合肥市蜀山区|AHHFSSSQ",
    "py": "SSQ",
    "provinc": "安徽",
    "city": "合肥市",
    "area": "蜀山区"
}, {
    "name": "安徽合肥市包河区",
    "match": "安徽合肥市包河区|AHHFSBHQ",
    "py": "BHQ",
    "provinc": "安徽",
    "city": "合肥市",
    "area": "包河区"
}, {
    "name": "安徽合肥市长丰县",
    "match": "安徽合肥市长丰县|AHHFSCFX",
    "py": "CFX",
    "provinc": "安徽",
    "city": "合肥市",
    "area": "长丰县"
}, {
    "name": "安徽合肥市肥东县",
    "match": "安徽合肥市肥东县|AHHFSFDX",
    "py": "FDX",
    "provinc": "安徽",
    "city": "合肥市",
    "area": "肥东县"
}, {
    "name": "安徽合肥市肥西县",
    "match": "安徽合肥市肥西县|AHHFSFXX",
    "py": "FXX",
    "provinc": "安徽",
    "city": "合肥市",
    "area": "肥西县"
}, {
    "name": "安徽芜湖市",
    "match": "安徽芜湖市|AHWHS",
    "py": "WHS",
    "provinc": "安徽",
    "city": "芜湖市",
    "area": "芜湖市"
},

/*ADD*/
    {
        "name": "安徽芜湖市无为县",
        "match": "安徽芜湖市无为县|AHWHSWWX",
        "py": "WWX",
        "provinc": "安徽",
        "city": "芜湖市",
        "area": "无为县"
    },
    /*ADD*/

{
    "name": "安徽芜湖市镜湖区",
    "match": "安徽芜湖市镜湖区|AHWHSJHQ",
    "py": "JHQ",
    "provinc": "安徽",
    "city": "芜湖市",
    "area": "镜湖区"
}, {
    "name": "安徽芜湖市弋江区",
    "match": "安徽芜湖市弋江区|AHWHSYJQ",
    "py": "YJQ",
    "provinc": "安徽",
    "city": "芜湖市",
    "area": "弋江区"
}, {
    "name": "安徽芜湖市鸠江区",
    "match": "安徽芜湖市鸠江区|AHWHSJJQ",
    "py": "JJQ",
    "provinc": "安徽",
    "city": "芜湖市",
    "area": "鸠江区"
}, {
    "name": "安徽芜湖市三山区",
    "match": "安徽芜湖市三山区|AHWHSSSQ",
    "py": "SSQ",
    "provinc": "安徽",
    "city": "芜湖市",
    "area": "三山区"
}, {
    "name": "安徽芜湖市芜湖县",
    "match": "安徽芜湖市芜湖县|AHWHSWHX",
    "py": "WHX",
    "provinc": "安徽",
    "city": "芜湖市",
    "area": "芜湖县"
}, {
    "name": "安徽芜湖市繁昌县",
    "match": "安徽芜湖市繁昌县|AHWHSFCX",
    "py": "FCX",
    "provinc": "安徽",
    "city": "芜湖市",
    "area": "繁昌县"
}, {
    "name": "安徽芜湖市南陵县",
    "match": "安徽芜湖市南陵县|AHWHSNLX",
    "py": "NLX",
    "provinc": "安徽",
    "city": "芜湖市",
    "area": "南陵县"
}, {
    "name": "安徽蚌埠市",
    "match": "安徽蚌埠市|AHBBS",
    "py": "BBS",
    "provinc": "安徽",
    "city": "蚌埠市",
    "area": "蚌埠市"
}, {
    "name": "安徽蚌埠市龙子湖区",
    "match": "安徽蚌埠市龙子湖区|AHBBSLZHQ",
    "py": "LZHQ",
    "provinc": "安徽",
    "city": "蚌埠市",
    "area": "龙子湖区"
}, {
    "name": "安徽蚌埠市蚌山区",
    "match": "安徽蚌埠市蚌山区|AHBBSBSQ",
    "py": "BSQ",
    "provinc": "安徽",
    "city": "蚌埠市",
    "area": "蚌山区"
}, {
    "name": "安徽蚌埠市禹会区",
    "match": "安徽蚌埠市禹会区|AHBBSYHQ",
    "py": "YHQ",
    "provinc": "安徽",
    "city": "蚌埠市",
    "area": "禹会区"
}, {
    "name": "安徽蚌埠市淮上区",
    "match": "安徽蚌埠市淮上区|AHBBSHSQ",
    "py": "HSQ",
    "provinc": "安徽",
    "city": "蚌埠市",
    "area": "淮上区"
}, {
    "name": "安徽蚌埠市怀远县",
    "match": "安徽蚌埠市怀远县|AHBBSHYX",
    "py": "HYX",
    "provinc": "安徽",
    "city": "蚌埠市",
    "area": "怀远县"
}, {
    "name": "安徽蚌埠市五河县",
    "match": "安徽蚌埠市五河县|AHBBSWHX",
    "py": "WHX",
    "provinc": "安徽",
    "city": "蚌埠市",
    "area": "五河县"
}, {
    "name": "安徽蚌埠市固镇县",
    "match": "安徽蚌埠市固镇县|AHBBSGZX",
    "py": "GZX",
    "provinc": "安徽",
    "city": "蚌埠市",
    "area": "固镇县"
}, {
    "name": "安徽淮南市",
    "match": "安徽淮南市|AHHNS",
    "py": "HNS",
    "provinc": "安徽",
    "city": "淮南市",
    "area": "淮南市"
},

    /*ADD*/
    {
        "name": "安徽淮南市寿县",
        "match": "安徽淮南市寿县|AHHNSSX",
        "py": "SX",
        "provinc": "安徽",
        "city": "淮南市",
        "area": "寿县"
    },
    /*ADD*/

    {
    "name": "安徽淮南市大通区",
    "match": "安徽淮南市大通区|AHHNSDTQ",
    "py": "DTQ",
    "provinc": "安徽",
    "city": "淮南市",
    "area": "大通区"
}, {
    "name": "安徽淮南市田家庵区",
    "match": "安徽淮南市田家庵区|AHHNSTJAQ",
    "py": "TJAQ",
    "provinc": "安徽",
    "city": "淮南市",
    "area": "田家庵区"
}, {
    "name": "安徽淮南市谢家集区",
    "match": "安徽淮南市谢家集区|AHHNSXJJQ",
    "py": "XJJQ",
    "provinc": "安徽",
    "city": "淮南市",
    "area": "谢家集区"
}, {
    "name": "安徽淮南市八公山区",
    "match": "安徽淮南市八公山区|AHHNSBGSQ",
    "py": "BGSQ",
    "provinc": "安徽",
    "city": "淮南市",
    "area": "八公山区"
}, {
    "name": "安徽淮南市潘集区",
    "match": "安徽淮南市潘集区|AHHNSPJQ",
    "py": "PJQ",
    "provinc": "安徽",
    "city": "淮南市",
    "area": "潘集区"
}, {
    "name": "安徽淮南市凤台县",
    "match": "安徽淮南市凤台县|AHHNSFTX",
    "py": "FTX",
    "provinc": "安徽",
    "city": "淮南市",
    "area": "凤台县"
}, {
    "name": "安徽马鞍山市",
    "match": "安徽马鞍山市|AHMASS",
    "py": "MASS",
    "provinc": "安徽",
    "city": "马鞍山市",
    "area": "马鞍山市"
},

    /*ADD*/
    {
        "name": "安徽马鞍山市含山县",
        "match": "安徽马鞍山市含山县|AHMASSHSX",
        "py": "HSX",
        "provinc": "安徽",
        "city": "马鞍山市",
        "area": "含山县"
    },
    {
        "name": "安徽马鞍山市和县",
        "match": "安徽马鞍山市和县|AHMASSHX",
        "py": "HX",
        "provinc": "安徽",
        "city": "马鞍山市",
        "area": "和县"
    },
    {
        "name": "安徽马鞍山市博望区",
        "match": "安徽马鞍山市博望区|AHMASSBWQ",
        "py": "BWQ",
        "provinc": "安徽",
        "city": "马鞍山市",
        "area": "博望区"
    },
    /*ADD*/


    /*{
    "name": "安徽马鞍山市金家庄区",
    "match": "安徽马鞍山市金家庄区|AHMASSJJZQ",
    "py": "JJZQ",
    "provinc": "安徽",
    "city": "马鞍山市",
    "area": "金家庄区"
},*/ {
    "name": "安徽马鞍山市花山区",
    "match": "安徽马鞍山市花山区|AHMASSHSQ",
    "py": "HSQ",
    "provinc": "安徽",
    "city": "马鞍山市",
    "area": "花山区"
}, {
    "name": "安徽马鞍山市雨山区",
    "match": "安徽马鞍山市雨山区|AHMASSYSQ",
    "py": "YSQ",
    "provinc": "安徽",
    "city": "马鞍山市",
    "area": "雨山区"
}, {
    "name": "安徽马鞍山市当涂县",
    "match": "安徽马鞍山市当涂县|AHMASSDTX",
    "py": "DTX",
    "provinc": "安徽",
    "city": "马鞍山市",
    "area": "当涂县"
}, {
    "name": "安徽淮北市",
    "match": "安徽淮北市|AHHBS",
    "py": "HBS",
    "provinc": "安徽",
    "city": "淮北市",
    "area": "淮北市"
}, {
    "name": "安徽淮北市杜集区",
    "match": "安徽淮北市杜集区|AHHBSDJQ",
    "py": "DJQ",
    "provinc": "安徽",
    "city": "淮北市",
    "area": "杜集区"
}, {
    "name": "安徽淮北市相山区",
    "match": "安徽淮北市相山区|AHHBSXSQ",
    "py": "XSQ",
    "provinc": "安徽",
    "city": "淮北市",
    "area": "相山区"
}, {
    "name": "安徽淮北市烈山区",
    "match": "安徽淮北市烈山区|AHHBSLSQ",
    "py": "LSQ",
    "provinc": "安徽",
    "city": "淮北市",
    "area": "烈山区"
}, {
    "name": "安徽淮北市濉溪县",
    "match": "安徽淮北市濉溪县|AHHBSSXX",
    "py": "SXX",
    "provinc": "安徽",
    "city": "淮北市",
    "area": "濉溪县"
}, {
    "name": "安徽铜陵市",
    "match": "安徽铜陵市|AHTLS",
    "py": "TLS",
    "provinc": "安徽",
    "city": "铜陵市",
    "area": "铜陵市"
},

    /*ADD*/
    {
        "name": "安徽铜陵市铜官区",
        "match": "安徽铜陵市铜官区|AHTLSTGQ",
        "py": "TGQ",
        "provinc": "安徽",
        "city": "铜陵市",
        "area": "铜官区"
    },
    {
        "name": "安徽铜陵市义安区",
        "match": "安徽铜陵市义安区|AHTLSYAQ",
        "py": "YAQ",
        "provinc": "安徽",
        "city": "铜陵市",
        "area": "义安区"
    },
    {
        "name": "安徽铜陵市枞阳县",
        "match": "安徽铜陵市枞阳县|AHTLSZYX",
        "py": "ZYX",
        "provinc": "安徽",
        "city": "铜陵市",
        "area": "枞阳县"
    },
    {
        "name": "安徽铜陵市郊区",
        "match": "安徽铜陵市郊区|AHTLSJQ",
        "py": "JQ",
        "provinc": "安徽",
        "city": "铜陵市",
        "area": "郊区"
    },
    /*ADD*/


    /*{
    "name": "安徽铜陵市铜官山区",
    "match": "安徽铜陵市铜官山区|AHTLSTGSQ",
    "py": "TGSQ",
    "provinc": "安徽",
    "city": "铜陵市",
    "area": "铜官山区"
}, {
    "name": "安徽铜陵市狮子山区",
    "match": "安徽铜陵市狮子山区|AHTLSSZSQ",
    "py": "SZSQ",
    "provinc": "安徽",
    "city": "铜陵市",
    "area": "狮子山区"
}, {
    "name": "安徽铜陵市铜陵县",
    "match": "安徽铜陵市铜陵县|AHTLSTLX",
    "py": "TLX",
    "provinc": "安徽",
    "city": "铜陵市",
    "area": "铜陵县"
}, */{
    "name": "安徽安庆市",
    "match": "安徽安庆市|AHAQS",
    "py": "AQS",
    "provinc": "安徽",
    "city": "安庆市",
    "area": "安庆市"
}, {
    "name": "安徽安庆市迎江区",
    "match": "安徽安庆市迎江区|AHAQSYJQ",
    "py": "YJQ",
    "provinc": "安徽",
    "city": "安庆市",
    "area": "迎江区"
}, {
    "name": "安徽安庆市大观区",
    "match": "安徽安庆市大观区|AHAQSDGQ",
    "py": "DGQ",
    "provinc": "安徽",
    "city": "安庆市",
    "area": "大观区"
}, {
    "name": "安徽安庆市宜秀区",
    "match": "安徽安庆市宜秀区|AHAQSYXQ",
    "py": "YXQ",
    "provinc": "安徽",
    "city": "安庆市",
    "area": "宜秀区"
}, {
    "name": "安徽安庆市怀宁县",
    "match": "安徽安庆市怀宁县|AHAQSHNX",
    "py": "HNX",
    "provinc": "安徽",
    "city": "安庆市",
    "area": "怀宁县"
},/* {
    "name": "安徽安庆市枞阳县",
    "match": "安徽安庆市枞阳县|AHAQSZYX",
    "py": "ZYX",
    "provinc": "安徽",
    "city": "安庆市",
    "area": "枞阳县"
},*/ {
    "name": "安徽安庆市潜山县",
    "match": "安徽安庆市潜山县|AHAQSQSX",
    "py": "QSX",
    "provinc": "安徽",
    "city": "安庆市",
    "area": "潜山县"
}, {
    "name": "安徽安庆市太湖县",
    "match": "安徽安庆市太湖县|AHAQSTHX",
    "py": "THX",
    "provinc": "安徽",
    "city": "安庆市",
    "area": "太湖县"
}, {
    "name": "安徽安庆市宿松县",
    "match": "安徽安庆市宿松县|AHAQSSSX",
    "py": "SSX",
    "provinc": "安徽",
    "city": "安庆市",
    "area": "宿松县"
}, {
    "name": "安徽安庆市望江县",
    "match": "安徽安庆市望江县|AHAQSWJX",
    "py": "WJX",
    "provinc": "安徽",
    "city": "安庆市",
    "area": "望江县"
}, {
    "name": "安徽安庆市岳西县",
    "match": "安徽安庆市岳西县|AHAQSYXX",
    "py": "YXX",
    "provinc": "安徽",
    "city": "安庆市",
    "area": "岳西县"
}, {
    "name": "安徽安庆市桐城市",
    "match": "安徽安庆市桐城市|AHAQSTCS",
    "py": "TCS",
    "provinc": "安徽",
    "city": "安庆市",
    "area": "桐城市"
}, {
    "name": "安徽黄山市",
    "match": "安徽黄山市|AHHSS",
    "py": "HSS",
    "provinc": "安徽",
    "city": "黄山市",
    "area": "黄山市"
}, {
    "name": "安徽黄山市屯溪区",
    "match": "安徽黄山市屯溪区|AHHSSTXQ",
    "py": "TXQ",
    "provinc": "安徽",
    "city": "黄山市",
    "area": "屯溪区"
}, {
    "name": "安徽黄山市黄山区",
    "match": "安徽黄山市黄山区|AHHSSHSQ",
    "py": "HSQ",
    "provinc": "安徽",
    "city": "黄山市",
    "area": "黄山区"
}, {
    "name": "安徽黄山市徽州区",
    "match": "安徽黄山市徽州区|AHHSSHZQ",
    "py": "HZQ",
    "provinc": "安徽",
    "city": "黄山市",
    "area": "徽州区"
}, {
    "name": "安徽黄山市歙县",
    "match": "安徽黄山市歙县|AHHSSSX",
    "py": "SX",
    "provinc": "安徽",
    "city": "黄山市",
    "area": "歙县"
}, {
    "name": "安徽黄山市休宁县",
    "match": "安徽黄山市休宁县|AHHSSXNX",
    "py": "XNX",
    "provinc": "安徽",
    "city": "黄山市",
    "area": "休宁县"
}, {
    "name": "安徽黄山市黟县",
    "match": "安徽黄山市黟县|AHHSSYX",
    "py": "YX",
    "provinc": "安徽",
    "city": "黄山市",
    "area": "黟县"
}, {
    "name": "安徽黄山市祁门县",
    "match": "安徽黄山市祁门县|AHHSSQMX",
    "py": "QMX",
    "provinc": "安徽",
    "city": "黄山市",
    "area": "祁门县"
}, {
    "name": "安徽滁州市",
    "match": "安徽滁州市|AHCZS",
    "py": "CZS",
    "provinc": "安徽",
    "city": "滁州市",
    "area": "滁州市"
}, {
    "name": "安徽滁州市琅琊区",
    "match": "安徽滁州市琅琊区|AHCZSLYQ",
    "py": "LYQ",
    "provinc": "安徽",
    "city": "滁州市",
    "area": "琅琊区"
}, {
    "name": "安徽滁州市南谯区",
    "match": "安徽滁州市南谯区|AHCZSNQQ",
    "py": "NQQ",
    "provinc": "安徽",
    "city": "滁州市",
    "area": "南谯区"
}, {
    "name": "安徽滁州市来安县",
    "match": "安徽滁州市来安县|AHCZSLAX",
    "py": "LAX",
    "provinc": "安徽",
    "city": "滁州市",
    "area": "来安县"
}, {
    "name": "安徽滁州市全椒县",
    "match": "安徽滁州市全椒县|AHCZSQJX",
    "py": "QJX",
    "provinc": "安徽",
    "city": "滁州市",
    "area": "全椒县"
}, {
    "name": "安徽滁州市定远县",
    "match": "安徽滁州市定远县|AHCZSDYX",
    "py": "DYX",
    "provinc": "安徽",
    "city": "滁州市",
    "area": "定远县"
}, {
    "name": "安徽滁州市凤阳县",
    "match": "安徽滁州市凤阳县|AHCZSFYX",
    "py": "FYX",
    "provinc": "安徽",
    "city": "滁州市",
    "area": "凤阳县"
}, {
    "name": "安徽滁州市天长市",
    "match": "安徽滁州市天长市|AHCZSTCS",
    "py": "TCS",
    "provinc": "安徽",
    "city": "滁州市",
    "area": "天长市"
}, {
    "name": "安徽滁州市明光市",
    "match": "安徽滁州市明光市|AHCZSMGS",
    "py": "MGS",
    "provinc": "安徽",
    "city": "滁州市",
    "area": "明光市"
}, {
    "name": "安徽阜阳市",
    "match": "安徽阜阳市|AHFYS",
    "py": "FYS",
    "provinc": "安徽",
    "city": "阜阳市",
    "area": "阜阳市"
},

    /*ADD*/
    {
        "name": "安徽阜阳市经济开发区",
        "match": "安徽阜阳市经济开发区|AHFYSJJKFQ",
        "py": "JJKFQ",
        "provinc": "安徽",
        "city": "阜阳市",
        "area": "经济开发区"
    },
    /*ADD*/


    {
    "name": "安徽阜阳市颍州区",
    "match": "安徽阜阳市颍州区|AHFYSYZQ",
    "py": "YZQ",
    "provinc": "安徽",
    "city": "阜阳市",
    "area": "颍州区"
}, {
    "name": "安徽阜阳市颍东区",
    "match": "安徽阜阳市颍东区|AHFYSYDQ",
    "py": "YDQ",
    "provinc": "安徽",
    "city": "阜阳市",
    "area": "颍东区"
}, {
    "name": "安徽阜阳市颍泉区",
    "match": "安徽阜阳市颍泉区|AHFYSYQQ",
    "py": "YQQ",
    "provinc": "安徽",
    "city": "阜阳市",
    "area": "颍泉区"
}, {
    "name": "安徽阜阳市临泉县",
    "match": "安徽阜阳市临泉县|AHFYSLQX",
    "py": "LQX",
    "provinc": "安徽",
    "city": "阜阳市",
    "area": "临泉县"
}, {
    "name": "安徽阜阳市太和县",
    "match": "安徽阜阳市太和县|AHFYSTHX",
    "py": "THX",
    "provinc": "安徽",
    "city": "阜阳市",
    "area": "太和县"
}, {
    "name": "安徽阜阳市阜南县",
    "match": "安徽阜阳市阜南县|AHFYSFNX",
    "py": "FNX",
    "provinc": "安徽",
    "city": "阜阳市",
    "area": "阜南县"
}, {
    "name": "安徽阜阳市颍上县",
    "match": "安徽阜阳市颍上县|AHFYSYSX",
    "py": "YSX",
    "provinc": "安徽",
    "city": "阜阳市",
    "area": "颍上县"
}, {
    "name": "安徽阜阳市界首市",
    "match": "安徽阜阳市界首市|AHFYSJSS",
    "py": "JSS",
    "provinc": "安徽",
    "city": "阜阳市",
    "area": "界首市"
}, {
    "name": "安徽宿州市",
    "match": "安徽宿州市|AHSZS",
    "py": "SZS",
    "provinc": "安徽",
    "city": "宿州市",
    "area": "宿州市"
}, {
    "name": "安徽宿州市埇桥区",
    "match": "安徽宿州市埇桥区|AHSZSYQQ",
    "py": "YQQ",
    "provinc": "安徽",
    "city": "宿州市",
    "area": "埇桥区"
}, {
    "name": "安徽宿州市砀山县",
    "match": "安徽宿州市砀山县|AHSZSDSX",
    "py": "DSX",
    "provinc": "安徽",
    "city": "宿州市",
    "area": "砀山县"
}, {
    "name": "安徽宿州市萧县",
    "match": "安徽宿州市萧县|AHSZSXX",
    "py": "XX",
    "provinc": "安徽",
    "city": "宿州市",
    "area": "萧县"
}, {
    "name": "安徽宿州市灵璧县",
    "match": "安徽宿州市灵璧县|AHSZSLBX",
    "py": "LBX",
    "provinc": "安徽",
    "city": "宿州市",
    "area": "灵璧县"
}, {
    "name": "安徽宿州市泗县",
    "match": "安徽宿州市泗县|AHSZSSX",
    "py": "SX",
    "provinc": "安徽",
    "city": "宿州市",
    "area": "泗县"
},/* {
    "name": "安徽巢湖市",
    "match": "安徽巢湖市|AHCHS",
    "py": "CHS",
    "provinc": "安徽",
    "city": "巢湖市",
    "area": "巢湖市"
}, {
    "name": "安徽巢湖市居巢区",
    "match": "安徽巢湖市居巢区|AHCHSJCQ",
    "py": "JCQ",
    "provinc": "安徽",
    "city": "巢湖市",
    "area": "居巢区"
}, {
    "name": "安徽巢湖市庐江县",
    "match": "安徽巢湖市庐江县|AHCHSLJX",
    "py": "LJX",
    "provinc": "安徽",
    "city": "巢湖市",
    "area": "庐江县"
}, {
    "name": "安徽巢湖市无为县",
    "match": "安徽巢湖市无为县|AHCHSWWX",
    "py": "WWX",
    "provinc": "安徽",
    "city": "巢湖市",
    "area": "无为县"
}, {
    "name": "安徽巢湖市含山县",
    "match": "安徽巢湖市含山县|AHCHSHSX",
    "py": "HSX",
    "provinc": "安徽",
    "city": "巢湖市",
    "area": "含山县"
}, {
    "name": "安徽巢湖市和县",
    "match": "安徽巢湖市和县|AHCHSHX",
    "py": "HX",
    "provinc": "安徽",
    "city": "巢湖市",
    "area": "和县"
},*/ {
    "name": "安徽六安市",
    "match": "安徽六安市|AHLAS",
    "py": "LAS",
    "provinc": "安徽",
    "city": "六安市",
    "area": "六安市"
},

    /*ADD*/
    {
        "name": "安徽六安市叶集区",
        "match": "安徽六安市叶集区|AHLASYJQ",
        "py": "YJQ",
        "provinc": "安徽",
        "city": "六安市",
        "area": "叶集区"
    },
    /*ADD*/


    {
    "name": "安徽六安市金安区",
    "match": "安徽六安市金安区|AHLASJAQ",
    "py": "JAQ",
    "provinc": "安徽",
    "city": "六安市",
    "area": "金安区"
}, {
    "name": "安徽六安市裕安区",
    "match": "安徽六安市裕安区|AHLASYAQ",
    "py": "YAQ",
    "provinc": "安徽",
    "city": "六安市",
    "area": "裕安区"
}, {
    "name": "安徽六安市寿县",
    "match": "安徽六安市寿县|AHLASSX",
    "py": "SX",
    "provinc": "安徽",
    "city": "六安市",
    "area": "寿县"
}, {
    "name": "安徽六安市霍邱县",
    "match": "安徽六安市霍邱县|AHLASHQX",
    "py": "HQX",
    "provinc": "安徽",
    "city": "六安市",
    "area": "霍邱县"
}, {
    "name": "安徽六安市舒城县",
    "match": "安徽六安市舒城县|AHLASSCX",
    "py": "SCX",
    "provinc": "安徽",
    "city": "六安市",
    "area": "舒城县"
}, {
    "name": "安徽六安市金寨县",
    "match": "安徽六安市金寨县|AHLASJZX",
    "py": "JZX",
    "provinc": "安徽",
    "city": "六安市",
    "area": "金寨县"
}, {
    "name": "安徽六安市霍山县",
    "match": "安徽六安市霍山县|AHLASHSX",
    "py": "HSX",
    "provinc": "安徽",
    "city": "六安市",
    "area": "霍山县"
}, {
    "name": "安徽亳州市",
    "match": "安徽亳州市|AHBZS",
    "py": "BZS",
    "provinc": "安徽",
    "city": "亳州市",
    "area": "亳州市"
}, {
    "name": "安徽亳州市谯城区",
    "match": "安徽亳州市谯城区|AHBZSQCQ",
    "py": "QCQ",
    "provinc": "安徽",
    "city": "亳州市",
    "area": "谯城区"
}, {
    "name": "安徽亳州市涡阳县",
    "match": "安徽亳州市涡阳县|AHBZSWYX",
    "py": "WYX",
    "provinc": "安徽",
    "city": "亳州市",
    "area": "涡阳县"
}, {
    "name": "安徽亳州市蒙城县",
    "match": "安徽亳州市蒙城县|AHBZSMCX",
    "py": "MCX",
    "provinc": "安徽",
    "city": "亳州市",
    "area": "蒙城县"
}, {
    "name": "安徽亳州市利辛县",
    "match": "安徽亳州市利辛县|AHBZSLXX",
    "py": "LXX",
    "provinc": "安徽",
    "city": "亳州市",
    "area": "利辛县"
}, {
    "name": "安徽池州市",
    "match": "安徽池州市|AHCZS",
    "py": "CZS",
    "provinc": "安徽",
    "city": "池州市",
    "area": "池州市"
}, {
    "name": "安徽池州市贵池区",
    "match": "安徽池州市贵池区|AHCZSGCQ",
    "py": "GCQ",
    "provinc": "安徽",
    "city": "池州市",
    "area": "贵池区"
}, {
    "name": "安徽池州市东至县",
    "match": "安徽池州市东至县|AHCZSDZX",
    "py": "DZX",
    "provinc": "安徽",
    "city": "池州市",
    "area": "东至县"
}, {
    "name": "安徽池州市石台县",
    "match": "安徽池州市石台县|AHCZSSTX",
    "py": "STX",
    "provinc": "安徽",
    "city": "池州市",
    "area": "石台县"
}, {
    "name": "安徽池州市青阳县",
    "match": "安徽池州市青阳县|AHCZSQYX",
    "py": "QYX",
    "provinc": "安徽",
    "city": "池州市",
    "area": "青阳县"
}, {
    "name": "安徽宣城市",
    "match": "安徽宣城市|AHXCS",
    "py": "XCS",
    "provinc": "安徽",
    "city": "宣城市",
    "area": "宣城市"
}, {
    "name": "安徽宣城市宣州区",
    "match": "安徽宣城市宣州区|AHXCSXZQ",
    "py": "XZQ",
    "provinc": "安徽",
    "city": "宣城市",
    "area": "宣州区"
}, {
    "name": "安徽宣城市郎溪县",
    "match": "安徽宣城市郎溪县|AHXCSLXX",
    "py": "LXX",
    "provinc": "安徽",
    "city": "宣城市",
    "area": "郎溪县"
}, {
    "name": "安徽宣城市广德县",
    "match": "安徽宣城市广德县|AHXCSGDX",
    "py": "GDX",
    "provinc": "安徽",
    "city": "宣城市",
    "area": "广德县"
}, {
    "name": "安徽宣城市泾县",
    "match": "安徽宣城市泾县|AHXCSJX",
    "py": "JX",
    "provinc": "安徽",
    "city": "宣城市",
    "area": "泾县"
}, {
    "name": "安徽宣城市绩溪县",
    "match": "安徽宣城市绩溪县|AHXCSJXX",
    "py": "JXX",
    "provinc": "安徽",
    "city": "宣城市",
    "area": "绩溪县"
}, {
    "name": "安徽宣城市旌德县",
    "match": "安徽宣城市旌德县|AHXCSJDX",
    "py": "JDX",
    "provinc": "安徽",
    "city": "宣城市",
    "area": "旌德县"
}, {
    "name": "安徽宣城市宁国市",
    "match": "安徽宣城市宁国市|AHXCSNGS",
    "py": "NGS",
    "provinc": "安徽",
    "city": "宣城市",
    "area": "宁国市"
}, {
    "name": "山东菏泽市",
    "match": "山东菏泽市|SDHZS",
    "py": "HZS",
    "provinc": "山东",
    "city": "菏泽市",
    "area": "菏泽市"
}, {
    "name": "山东菏泽市牡丹区",
    "match": "山东菏泽市牡丹区|SDHZSMDQ",
    "py": "MDQ",
    "provinc": "山东",
    "city": "菏泽市",
    "area": "牡丹区"
}, {
    "name": "山东菏泽市曹县",
    "match": "山东菏泽市曹县|SDHZSCX",
    "py": "CX",
    "provinc": "山东",
    "city": "菏泽市",
    "area": "曹县"
}, {
    "name": "山东菏泽市单县",
    "match": "山东菏泽市单县|SDHZSDX",
    "py": "DX",
    "provinc": "山东",
    "city": "菏泽市",
    "area": "单县"
}, {
    "name": "山东菏泽市成武县",
    "match": "山东菏泽市成武县|SDHZSCWX",
    "py": "CWX",
    "provinc": "山东",
    "city": "菏泽市",
    "area": "成武县"
}, {
    "name": "山东菏泽市巨野县",
    "match": "山东菏泽市巨野县|SDHZSJYX",
    "py": "JYX",
    "provinc": "山东",
    "city": "菏泽市",
    "area": "巨野县"
}, {
    "name": "山东菏泽市郓城县",
    "match": "山东菏泽市郓城县|SDHZSYCX",
    "py": "YCX",
    "provinc": "山东",
    "city": "菏泽市",
    "area": "郓城县"
}, {
    "name": "山东菏泽市鄄城县",
    "match": "山东菏泽市鄄城县|SDHZSJCX",
    "py": "JCX",
    "provinc": "山东",
    "city": "菏泽市",
    "area": "鄄城县"
}, {
    "name": "山东菏泽市定陶区",
    "match": "山东菏泽市定陶区|SDHZSDTQ",
    "py": "DTQ",
    "provinc": "山东",
    "city": "菏泽市",
    "area": "定陶区"
}, {
    "name": "山东菏泽市东明县",
    "match": "山东菏泽市东明县|SDHZSDMX",
    "py": "DMX",
    "provinc": "山东",
    "city": "菏泽市",
    "area": "东明县"
}, {
    "name": "山东济南市",
    "match": "山东济南市|SDJNS",
    "py": "JNS",
    "provinc": "山东",
    "city": "济南市",
    "area": "济南市"
}, {
    "name": "山东济南市历下区",
    "match": "山东济南市历下区|SDJNSLXQ",
    "py": "LXQ",
    "provinc": "山东",
    "city": "济南市",
    "area": "历下区"
}, {
    "name": "山东济南市市中区",
    "match": "山东济南市市中区|SDJNSSZQ",
    "py": "SZQ",
    "provinc": "山东",
    "city": "济南市",
    "area": "市中区"
}, {
    "name": "山东济南市槐荫区",
    "match": "山东济南市槐荫区|SDJNSHYQ",
    "py": "HYQ",
    "provinc": "山东",
    "city": "济南市",
    "area": "槐荫区"
}, {
    "name": "山东济南市天桥区",
    "match": "山东济南市天桥区|SDJNSTQQ",
    "py": "TQQ",
    "provinc": "山东",
    "city": "济南市",
    "area": "天桥区"
}, {
    "name": "山东济南市历城区",
    "match": "山东济南市历城区|SDJNSLCQ",
    "py": "LCQ",
    "provinc": "山东",
    "city": "济南市",
    "area": "历城区"
}, {
    "name": "山东济南市长清区",
    "match": "山东济南市长清区|SDJNSCQQ",
    "py": "CQQ",
    "provinc": "山东",
    "city": "济南市",
    "area": "长清区"
}, {
    "name": "山东济南市平阴县",
    "match": "山东济南市平阴县|SDJNSPYX",
    "py": "PYX",
    "provinc": "山东",
    "city": "济南市",
    "area": "平阴县"
}, {
    "name": "山东济南市济阳县",
    "match": "山东济南市济阳县|SDJNSJYX",
    "py": "JYX",
    "provinc": "山东",
    "city": "济南市",
    "area": "济阳县"
}, {
    "name": "山东济南市商河县",
    "match": "山东济南市商河县|SDJNSSHX",
    "py": "SHX",
    "provinc": "山东",
    "city": "济南市",
    "area": "商河县"
}, {
    "name": "山东济南市章丘区",
    "match": "山东济南市章丘区|SDJNSZQQ",
    "py": "ZQQ",
    "provinc": "山东",
    "city": "济南市",
    "area": "章丘区"
}, {
    "name": "山东青岛市",
    "match": "山东青岛市|SDQDS",
    "py": "QDS",
    "provinc": "山东",
    "city": "青岛市",
    "area": "青岛市"
}, {
    "name": "山东青岛市市南区",
    "match": "山东青岛市市南区|SDQDSSNQ",
    "py": "SNQ",
    "provinc": "山东",
    "city": "青岛市",
    "area": "市南区"
}, {
    "name": "山东青岛市市北区",
    "match": "山东青岛市市北区|SDQDSSBQ",
    "py": "SBQ",
    "provinc": "山东",
    "city": "青岛市",
    "area": "市北区"
},/* {
    "name": "山东青岛市四方区",
    "match": "山东青岛市四方区|SDQDSSFQ",
    "py": "SFQ",
    "provinc": "山东",
    "city": "青岛市",
    "area": "四方区"
}, */{
    "name": "山东青岛市黄岛区",
    "match": "山东青岛市黄岛区|SDQDSHDQ",
    "py": "HDQ",
    "provinc": "山东",
    "city": "青岛市",
    "area": "黄岛区"
}, {
    "name": "山东青岛市崂山区",
    "match": "山东青岛市崂山区|SDQDSLSQ",
    "py": "LSQ",
    "provinc": "山东",
    "city": "青岛市",
    "area": "崂山区"
}, {
    "name": "山东青岛市李沧区",
    "match": "山东青岛市李沧区|SDQDSLCQ",
    "py": "LCQ",
    "provinc": "山东",
    "city": "青岛市",
    "area": "李沧区"
}, {
    "name": "山东青岛市城阳区",
    "match": "山东青岛市城阳区|SDQDSCYQ",
    "py": "CYQ",
    "provinc": "山东",
    "city": "青岛市",
    "area": "城阳区"
}, {
    "name": "山东青岛市胶州市",
    "match": "山东青岛市胶州市|SDQDSJZS",
    "py": "JZS",
    "provinc": "山东",
    "city": "青岛市",
    "area": "胶州市"
}, {
    "name": "山东青岛市即墨市",
    "match": "山东青岛市即墨市|SDQDSJMS",
    "py": "JMS",
    "provinc": "山东",
    "city": "青岛市",
    "area": "即墨市"
}, {
    "name": "山东青岛市平度市",
    "match": "山东青岛市平度市|SDQDSPDS",
    "py": "PDS",
    "provinc": "山东",
    "city": "青岛市",
    "area": "平度市"
}, /*{
    "name": "山东青岛市胶南市",
    "match": "山东青岛市胶南市|SDQDSJNS",
    "py": "JNS",
    "provinc": "山东",
    "city": "青岛市",
    "area": "胶南市"
}, */{
    "name": "山东青岛市莱西市",
    "match": "山东青岛市莱西市|SDQDSLXS",
    "py": "LXS",
    "provinc": "山东",
    "city": "青岛市",
    "area": "莱西市"
}, {
    "name": "山东淄博市",
    "match": "山东淄博市|SDZBS",
    "py": "ZBS",
    "provinc": "山东",
    "city": "淄博市",
    "area": "淄博市"
}, {
    "name": "山东淄博市淄川区",
    "match": "山东淄博市淄川区|SDZBSZCQ",
    "py": "ZCQ",
    "provinc": "山东",
    "city": "淄博市",
    "area": "淄川区"
}, {
    "name": "山东淄博市张店区",
    "match": "山东淄博市张店区|SDZBSZDQ",
    "py": "ZDQ",
    "provinc": "山东",
    "city": "淄博市",
    "area": "张店区"
}, {
    "name": "山东淄博市博山区",
    "match": "山东淄博市博山区|SDZBSBSQ",
    "py": "BSQ",
    "provinc": "山东",
    "city": "淄博市",
    "area": "博山区"
}, {
    "name": "山东淄博市临淄区",
    "match": "山东淄博市临淄区|SDZBSLZQ",
    "py": "LZQ",
    "provinc": "山东",
    "city": "淄博市",
    "area": "临淄区"
}, {
    "name": "山东淄博市周村区",
    "match": "山东淄博市周村区|SDZBSZCQ",
    "py": "ZCQ",
    "provinc": "山东",
    "city": "淄博市",
    "area": "周村区"
}, {
    "name": "山东淄博市桓台县",
    "match": "山东淄博市桓台县|SDZBSHTX",
    "py": "HTX",
    "provinc": "山东",
    "city": "淄博市",
    "area": "桓台县"
}, {
    "name": "山东淄博市高青县",
    "match": "山东淄博市高青县|SDZBSGQX",
    "py": "GQX",
    "provinc": "山东",
    "city": "淄博市",
    "area": "高青县"
}, {
    "name": "山东淄博市沂源县",
    "match": "山东淄博市沂源县|SDZBSYYX",
    "py": "YYX",
    "provinc": "山东",
    "city": "淄博市",
    "area": "沂源县"
}, {
    "name": "山东德州市",
    "match": "山东德州市|SDDZS",
    "py": "DZS",
    "provinc": "山东",
    "city": "德州市",
    "area": "德州市"
}, {
    "name": "山东德州市德城区",
    "match": "山东德州市德城区|SDDZSDCQ",
    "py": "DCQ",
    "provinc": "山东",
    "city": "德州市",
    "area": "德城区"
}, {
    "name": "山东德州市陵城区",
    "match": "山东德州市陵城区|SDDZSLCQ",
    "py": "LCQ",
    "provinc": "山东",
    "city": "德州市",
    "area": "陵城区"
}, {
    "name": "山东德州市宁津县",
    "match": "山东德州市宁津县|SDDZSNJX",
    "py": "NJX",
    "provinc": "山东",
    "city": "德州市",
    "area": "宁津县"
}, {
    "name": "山东德州市庆云县",
    "match": "山东德州市庆云县|SDDZSQYX",
    "py": "QYX",
    "provinc": "山东",
    "city": "德州市",
    "area": "庆云县"
}, {
    "name": "山东德州市临邑县",
    "match": "山东德州市临邑县|SDDZSLYX",
    "py": "LYX",
    "provinc": "山东",
    "city": "德州市",
    "area": "临邑县"
}, {
    "name": "山东德州市齐河县",
    "match": "山东德州市齐河县|SDDZSQHX",
    "py": "QHX",
    "provinc": "山东",
    "city": "德州市",
    "area": "齐河县"
}, {
    "name": "山东德州市平原县",
    "match": "山东德州市平原县|SDDZSPYX",
    "py": "PYX",
    "provinc": "山东",
    "city": "德州市",
    "area": "平原县"
}, {
    "name": "山东德州市夏津县",
    "match": "山东德州市夏津县|SDDZSXJX",
    "py": "XJX",
    "provinc": "山东",
    "city": "德州市",
    "area": "夏津县"
}, {
    "name": "山东德州市武城县",
    "match": "山东德州市武城县|SDDZSWCX",
    "py": "WCX",
    "provinc": "山东",
    "city": "德州市",
    "area": "武城县"
}, {
    "name": "山东德州市乐陵市",
    "match": "山东德州市乐陵市|SDDZSLLS",
    "py": "LLS",
    "provinc": "山东",
    "city": "德州市",
    "area": "乐陵市"
}, {
    "name": "山东德州市禹城市",
    "match": "山东德州市禹城市|SDDZSYCS",
    "py": "YCS",
    "provinc": "山东",
    "city": "德州市",
    "area": "禹城市"
}, {
    "name": "山东烟台市",
    "match": "山东烟台市|SDYTS",
    "py": "YTS",
    "provinc": "山东",
    "city": "烟台市",
    "area": "烟台市"
},

    /*ADD*/
    {
        "name": "山东烟台市高新技术产业开发区",
        "match": "山东烟台市高新技术产业开发区|SDYTSGXJSCYKFQ",
        "py": "GXJSCYKFQ",
        "provinc": "山东",
        "city": "烟台市",
        "area": "高新技术产业开发区"
    },
    {
        "name": "山东烟台市经济技术开发区",
        "match": "山东烟台市经济技术开发区|SDYTSJJJSKFQ",
        "py": "JJJSKFQ",
        "provinc": "山东",
        "city": "烟台市",
        "area": "经济技术开发区"
    },
    /*ADD*/

    {
    "name": "山东烟台市芝罘区",
    "match": "山东烟台市芝罘区|SDYTSZFQ",
    "py": "ZFQ",
    "provinc": "山东",
    "city": "烟台市",
    "area": "芝罘区"
}, {
    "name": "山东烟台市福山区",
    "match": "山东烟台市福山区|SDYTSFSQ",
    "py": "FSQ",
    "provinc": "山东",
    "city": "烟台市",
    "area": "福山区"
}, {
    "name": "山东烟台市牟平区",
    "match": "山东烟台市牟平区|SDYTSMPQ",
    "py": "MPQ",
    "provinc": "山东",
    "city": "烟台市",
    "area": "牟平区"
}, {
    "name": "山东烟台市莱山区",
    "match": "山东烟台市莱山区|SDYTSLSQ",
    "py": "LSQ",
    "provinc": "山东",
    "city": "烟台市",
    "area": "莱山区"
}, {
    "name": "山东烟台市长岛县",
    "match": "山东烟台市长岛县|SDYTSCDX",
    "py": "CDX",
    "provinc": "山东",
    "city": "烟台市",
    "area": "长岛县"
}, {
    "name": "山东烟台市龙口市",
    "match": "山东烟台市龙口市|SDYTSLKS",
    "py": "LKS",
    "provinc": "山东",
    "city": "烟台市",
    "area": "龙口市"
}, {
    "name": "山东烟台市莱阳市",
    "match": "山东烟台市莱阳市|SDYTSLYS",
    "py": "LYS",
    "provinc": "山东",
    "city": "烟台市",
    "area": "莱阳市"
}, {
    "name": "山东烟台市莱州市",
    "match": "山东烟台市莱州市|SDYTSLZS",
    "py": "LZS",
    "provinc": "山东",
    "city": "烟台市",
    "area": "莱州市"
}, {
    "name": "山东烟台市蓬莱市",
    "match": "山东烟台市蓬莱市|SDYTSPLS",
    "py": "PLS",
    "provinc": "山东",
    "city": "烟台市",
    "area": "蓬莱市"
}, {
    "name": "山东烟台市招远市",
    "match": "山东烟台市招远市|SDYTSZYS",
    "py": "ZYS",
    "provinc": "山东",
    "city": "烟台市",
    "area": "招远市"
}, {
    "name": "山东烟台市栖霞市",
    "match": "山东烟台市栖霞市|SDYTSQXS",
    "py": "QXS",
    "provinc": "山东",
    "city": "烟台市",
    "area": "栖霞市"
}, {
    "name": "山东烟台市海阳市",
    "match": "山东烟台市海阳市|SDYTSHYS",
    "py": "HYS",
    "provinc": "山东",
    "city": "烟台市",
    "area": "海阳市"
}, {
    "name": "山东潍坊市",
    "match": "山东潍坊市|SDWFS",
    "py": "WFS",
    "provinc": "山东",
    "city": "潍坊市",
    "area": "潍坊市"
}, {
    "name": "山东潍坊市潍城区",
    "match": "山东潍坊市潍城区|SDWFSWCQ",
    "py": "WCQ",
    "provinc": "山东",
    "city": "潍坊市",
    "area": "潍城区"
}, {
    "name": "山东潍坊市寒亭区",
    "match": "山东潍坊市寒亭区|SDWFSHTQ",
    "py": "HTQ",
    "provinc": "山东",
    "city": "潍坊市",
    "area": "寒亭区"
}, {
    "name": "山东潍坊市坊子区",
    "match": "山东潍坊市坊子区|SDWFSFZQ",
    "py": "FZQ",
    "provinc": "山东",
    "city": "潍坊市",
    "area": "坊子区"
}, {
    "name": "山东潍坊市奎文区",
    "match": "山东潍坊市奎文区|SDWFSKWQ",
    "py": "KWQ",
    "provinc": "山东",
    "city": "潍坊市",
    "area": "奎文区"
}, {
    "name": "山东潍坊市临朐县",
    "match": "山东潍坊市临朐县|SDWFSLQX",
    "py": "LQX",
    "provinc": "山东",
    "city": "潍坊市",
    "area": "临朐县"
}, {
    "name": "山东潍坊市昌乐县",
    "match": "山东潍坊市昌乐县|SDWFSCLX",
    "py": "CLX",
    "provinc": "山东",
    "city": "潍坊市",
    "area": "昌乐县"
}, {
    "name": "山东潍坊市青州市",
    "match": "山东潍坊市青州市|SDWFSQZS",
    "py": "QZS",
    "provinc": "山东",
    "city": "潍坊市",
    "area": "青州市"
}, {
    "name": "山东潍坊市诸城市",
    "match": "山东潍坊市诸城市|SDWFSZCS",
    "py": "ZCS",
    "provinc": "山东",
    "city": "潍坊市",
    "area": "诸城市"
}, {
    "name": "山东潍坊市寿光市",
    "match": "山东潍坊市寿光市|SDWFSSGS",
    "py": "SGS",
    "provinc": "山东",
    "city": "潍坊市",
    "area": "寿光市"
}, {
    "name": "山东潍坊市安丘市",
    "match": "山东潍坊市安丘市|SDWFSAQS",
    "py": "AQS",
    "provinc": "山东",
    "city": "潍坊市",
    "area": "安丘市"
}, {
    "name": "山东潍坊市高密市",
    "match": "山东潍坊市高密市|SDWFSGMS",
    "py": "GMS",
    "provinc": "山东",
    "city": "潍坊市",
    "area": "高密市"
}, {
    "name": "山东潍坊市昌邑市",
    "match": "山东潍坊市昌邑市|SDWFSCYS",
    "py": "CYS",
    "provinc": "山东",
    "city": "潍坊市",
    "area": "昌邑市"
}, {
    "name": "山东济宁市",
    "match": "山东济宁市|SDJNS",
    "py": "JNS",
    "provinc": "山东",
    "city": "济宁市",
    "area": "济宁市"
}, /*{
    "name": "山东济宁市市中区",
    "match": "山东济宁市市中区|SDJNSSZQ",
    "py": "SZQ",
    "provinc": "山东",
    "city": "济宁市",
    "area": "市中区"
}, */{
    "name": "山东济宁市任城区",
    "match": "山东济宁市任城区|SDJNSRCQ",
    "py": "RCQ",
    "provinc": "山东",
    "city": "济宁市",
    "area": "任城区"
}, {
    "name": "山东济宁市微山县",
    "match": "山东济宁市微山县|SDJNSWSX",
    "py": "WSX",
    "provinc": "山东",
    "city": "济宁市",
    "area": "微山县"
}, {
    "name": "山东济宁市鱼台县",
    "match": "山东济宁市鱼台县|SDJNSYTX",
    "py": "YTX",
    "provinc": "山东",
    "city": "济宁市",
    "area": "鱼台县"
}, {
    "name": "山东济宁市金乡县",
    "match": "山东济宁市金乡县|SDJNSJXX",
    "py": "JXX",
    "provinc": "山东",
    "city": "济宁市",
    "area": "金乡县"
}, {
    "name": "山东济宁市嘉祥县",
    "match": "山东济宁市嘉祥县|SDJNSJXX",
    "py": "JXX",
    "provinc": "山东",
    "city": "济宁市",
    "area": "嘉祥县"
}, {
    "name": "山东济宁市汶上县",
    "match": "山东济宁市汶上县|SDJNSWSX",
    "py": "WSX",
    "provinc": "山东",
    "city": "济宁市",
    "area": "汶上县"
}, {
    "name": "山东济宁市泗水县",
    "match": "山东济宁市泗水县|SDJNSSSX",
    "py": "SSX",
    "provinc": "山东",
    "city": "济宁市",
    "area": "泗水县"
}, {
    "name": "山东济宁市梁山县",
    "match": "山东济宁市梁山县|SDJNSLSX",
    "py": "LSX",
    "provinc": "山东",
    "city": "济宁市",
    "area": "梁山县"
}, {
    "name": "山东济宁市曲阜市",
    "match": "山东济宁市曲阜市|SDJNSQFS",
    "py": "QFS",
    "provinc": "山东",
    "city": "济宁市",
    "area": "曲阜市"
}, {
    "name": "山东济宁市兖州区",
    "match": "山东济宁市兖州区|SDJNSYZQ",
    "py": "YZQ",
    "provinc": "山东",
    "city": "济宁市",
    "area": "兖州区"
}, {
    "name": "山东济宁市邹城市",
    "match": "山东济宁市邹城市|SDJNSZCS",
    "py": "ZCS",
    "provinc": "山东",
    "city": "济宁市",
    "area": "邹城市"
}, {
    "name": "山东泰安市",
    "match": "山东泰安市|SDTAS",
    "py": "TAS",
    "provinc": "山东",
    "city": "泰安市",
    "area": "泰安市"
}, {
    "name": "山东泰安市泰山区",
    "match": "山东泰安市泰山区|SDTASTSQ",
    "py": "TSQ",
    "provinc": "山东",
    "city": "泰安市",
    "area": "泰山区"
}, {
    "name": "山东泰安市岱岳区",
    "match": "山东泰安市岱岳区|SDTASDYQ",
    "py": "DYQ",
    "provinc": "山东",
    "city": "泰安市",
    "area": "岱岳区"
}, {
    "name": "山东泰安市宁阳县",
    "match": "山东泰安市宁阳县|SDTASNYX",
    "py": "NYX",
    "provinc": "山东",
    "city": "泰安市",
    "area": "宁阳县"
}, {
    "name": "山东泰安市东平县",
    "match": "山东泰安市东平县|SDTASDPX",
    "py": "DPX",
    "provinc": "山东",
    "city": "泰安市",
    "area": "东平县"
}, {
    "name": "山东泰安市新泰市",
    "match": "山东泰安市新泰市|SDTASXTS",
    "py": "XTS",
    "provinc": "山东",
    "city": "泰安市",
    "area": "新泰市"
}, {
    "name": "山东泰安市肥城市",
    "match": "山东泰安市肥城市|SDTASFCS",
    "py": "FCS",
    "provinc": "山东",
    "city": "泰安市",
    "area": "肥城市"
}, {
    "name": "山东临沂市",
    "match": "山东临沂市|SDLYS",
    "py": "LYS",
    "provinc": "山东",
    "city": "临沂市",
    "area": "临沂市"
}, {
    "name": "山东临沂市兰山区",
    "match": "山东临沂市兰山区|SDLYSLSQ",
    "py": "LSQ",
    "provinc": "山东",
    "city": "临沂市",
    "area": "兰山区"
}, {
    "name": "山东临沂市罗庄区",
    "match": "山东临沂市罗庄区|SDLYSLZQ",
    "py": "LZQ",
    "provinc": "山东",
    "city": "临沂市",
    "area": "罗庄区"
}, {
    "name": "山东临沂市河东区",
    "match": "山东临沂市河东区|SDLYSHDQ",
    "py": "HDQ",
    "provinc": "山东",
    "city": "临沂市",
    "area": "河东区"
}, {
    "name": "山东临沂市沂南县",
    "match": "山东临沂市沂南县|SDLYSYNX",
    "py": "YNX",
    "provinc": "山东",
    "city": "临沂市",
    "area": "沂南县"
}, {
    "name": "山东临沂市郯城县",
    "match": "山东临沂市郯城县|SDLYSTCX",
    "py": "TCX",
    "provinc": "山东",
    "city": "临沂市",
    "area": "郯城县"
}, {
    "name": "山东临沂市沂水县",
    "match": "山东临沂市沂水县|SDLYSYSX",
    "py": "YSX",
    "provinc": "山东",
    "city": "临沂市",
    "area": "沂水县"
}, {
    "name": "山东临沂市兰陵县",
    "match": "山东临沂市兰陵县|SDLYSLLX",
    "py": "LLX",
    "provinc": "山东",
    "city": "临沂市",
    "area": "兰陵县"
}, {
    "name": "山东临沂市费县",
    "match": "山东临沂市费县|SDLYSFX",
    "py": "FX",
    "provinc": "山东",
    "city": "临沂市",
    "area": "费县"
}, {
    "name": "山东临沂市平邑县",
    "match": "山东临沂市平邑县|SDLYSPYX",
    "py": "PYX",
    "provinc": "山东",
    "city": "临沂市",
    "area": "平邑县"
}, {
    "name": "山东临沂市莒南县",
    "match": "山东临沂市莒南县|SDLYSLNX",
    "py": "LNX",
    "provinc": "山东",
    "city": "临沂市",
    "area": "莒南县"
}, {
    "name": "山东临沂市蒙阴县",
    "match": "山东临沂市蒙阴县|SDLYSMYX",
    "py": "MYX",
    "provinc": "山东",
    "city": "临沂市",
    "area": "蒙阴县"
}, {
    "name": "山东临沂市临沭县",
    "match": "山东临沂市临沭县|SDLYSLSX",
    "py": "LSX",
    "provinc": "山东",
    "city": "临沂市",
    "area": "临沭县"
}, {
    "name": "山东滨州市",
    "match": "山东滨州市|SDBZS",
    "py": "BZS",
    "provinc": "山东",
    "city": "滨州市",
    "area": "滨州市"
}, {
    "name": "山东滨州市滨城区",
    "match": "山东滨州市滨城区|SDBZSBCQ",
    "py": "BCQ",
    "provinc": "山东",
    "city": "滨州市",
    "area": "滨城区"
}, {
    "name": "山东滨州市惠民县",
    "match": "山东滨州市惠民县|SDBZSHMX",
    "py": "HMX",
    "provinc": "山东",
    "city": "滨州市",
    "area": "惠民县"
}, {
    "name": "山东滨州市阳信县",
    "match": "山东滨州市阳信县|SDBZSYXX",
    "py": "YXX",
    "provinc": "山东",
    "city": "滨州市",
    "area": "阳信县"
}, {
    "name": "山东滨州市无棣县",
    "match": "山东滨州市无棣县|SDBZSWDX",
    "py": "WDX",
    "provinc": "山东",
    "city": "滨州市",
    "area": "无棣县"
}, {
    "name": "山东滨州市沾化区",
    "match": "山东滨州市沾化区|SDBZSZHQ",
    "py": "ZHQ",
    "provinc": "山东",
    "city": "滨州市",
    "area": "沾化区"
}, {
    "name": "山东滨州市博兴县",
    "match": "山东滨州市博兴县|SDBZSBXX",
    "py": "BXX",
    "provinc": "山东",
    "city": "滨州市",
    "area": "博兴县"
}, {
    "name": "山东滨州市邹平县",
    "match": "山东滨州市邹平县|SDBZSZPX",
    "py": "ZPX",
    "provinc": "山东",
    "city": "滨州市",
    "area": "邹平县"
}, {
    "name": "山东东营市",
    "match": "山东东营市|SDDYS",
    "py": "DYS",
    "provinc": "山东",
    "city": "东营市",
    "area": "东营市"
}, {
    "name": "山东东营市东营区",
    "match": "山东东营市东营区|SDDYSDYQ",
    "py": "DYQ",
    "provinc": "山东",
    "city": "东营市",
    "area": "东营区"
}, {
    "name": "山东东营市河口区",
    "match": "山东东营市河口区|SDDYSHKQ",
    "py": "HKQ",
    "provinc": "山东",
    "city": "东营市",
    "area": "河口区"
}, {
    "name": "山东东营市垦利区",
    "match": "山东东营市垦利区|SDDYSKLQ",
    "py": "KLQ",
    "provinc": "山东",
    "city": "东营市",
    "area": "垦利区"
}, {
    "name": "山东东营市利津县",
    "match": "山东东营市利津县|SDDYSLJX",
    "py": "LJX",
    "provinc": "山东",
    "city": "东营市",
    "area": "利津县"
}, {
    "name": "山东东营市广饶县",
    "match": "山东东营市广饶县|SDDYSGRX",
    "py": "GRX",
    "provinc": "山东",
    "city": "东营市",
    "area": "广饶县"
}, {
    "name": "山东威海市",
    "match": "山东威海市|SDWHS",
    "py": "WHS",
    "provinc": "山东",
    "city": "威海市",
    "area": "威海市"
}, {
    "name": "山东威海市环翠区",
    "match": "山东威海市环翠区|SDWHSHCQ",
    "py": "HCQ",
    "provinc": "山东",
    "city": "威海市",
    "area": "环翠区"
}, {
    "name": "山东威海市文登区",
    "match": "山东威海市文登区|SDWHSWDQ",
    "py": "WDQ",
    "provinc": "山东",
    "city": "威海市",
    "area": "文登区"
}, {
    "name": "山东威海市荣成市",
    "match": "山东威海市荣成市|SDWHSRCS",
    "py": "RCS",
    "provinc": "山东",
    "city": "威海市",
    "area": "荣成市"
}, {
    "name": "山东威海市乳山市",
    "match": "山东威海市乳山市|SDWHSRSS",
    "py": "RSS",
    "provinc": "山东",
    "city": "威海市",
    "area": "乳山市"
}, {
    "name": "山东枣庄市",
    "match": "山东枣庄市|SDZZS",
    "py": "ZZS",
    "provinc": "山东",
    "city": "枣庄市",
    "area": "枣庄市"
}, {
    "name": "山东枣庄市市中区",
    "match": "山东枣庄市市中区|SDZZSSZQ",
    "py": "SZQ",
    "provinc": "山东",
    "city": "枣庄市",
    "area": "市中区"
}, {
    "name": "山东枣庄市薛城区",
    "match": "山东枣庄市薛城区|SDZZSXCQ",
    "py": "XCQ",
    "provinc": "山东",
    "city": "枣庄市",
    "area": "薛城区"
}, {
    "name": "山东枣庄市峄城区",
    "match": "山东枣庄市峄城区|SDZZSYCQ",
    "py": "YCQ",
    "provinc": "山东",
    "city": "枣庄市",
    "area": "峄城区"
}, {
    "name": "山东枣庄市台儿庄区",
    "match": "山东枣庄市台儿庄区|SDZZSTEZQ",
    "py": "TEZQ",
    "provinc": "山东",
    "city": "枣庄市",
    "area": "台儿庄区"
}, {
    "name": "山东枣庄市山亭区",
    "match": "山东枣庄市山亭区|SDZZSSTQ",
    "py": "STQ",
    "provinc": "山东",
    "city": "枣庄市",
    "area": "山亭区"
}, {
    "name": "山东枣庄市滕州市",
    "match": "山东枣庄市滕州市|SDZZSTZS",
    "py": "TZS",
    "provinc": "山东",
    "city": "枣庄市",
    "area": "滕州市"
}, {
    "name": "山东日照市",
    "match": "山东日照市|SDRZS",
    "py": "RZS",
    "provinc": "山东",
    "city": "日照市",
    "area": "日照市"
}, {
    "name": "山东日照市东港区",
    "match": "山东日照市东港区|SDRZSDGQ",
    "py": "DGQ",
    "provinc": "山东",
    "city": "日照市",
    "area": "东港区"
}, {
    "name": "山东日照市岚山区",
    "match": "山东日照市岚山区|SDRZSLSQ",
    "py": "LSQ",
    "provinc": "山东",
    "city": "日照市",
    "area": "岚山区"
}, {
    "name": "山东日照市五莲县",
    "match": "山东日照市五莲县|SDRZSWLX",
    "py": "WLX",
    "provinc": "山东",
    "city": "日照市",
    "area": "五莲县"
}, {
    "name": "山东日照市莒县",
    "match": "山东日照市莒县|SDRZSLX",
    "py": "LX",
    "provinc": "山东",
    "city": "日照市",
    "area": "莒县"
}, {
    "name": "山东莱芜市",
    "match": "山东莱芜市|SDLWS",
    "py": "LWS",
    "provinc": "山东",
    "city": "莱芜市",
    "area": "莱芜市"
}, {
    "name": "山东莱芜市莱城区",
    "match": "山东莱芜市莱城区|SDLWSLCQ",
    "py": "LCQ",
    "provinc": "山东",
    "city": "莱芜市",
    "area": "莱城区"
}, {
    "name": "山东莱芜市钢城区",
    "match": "山东莱芜市钢城区|SDLWSGCQ",
    "py": "GCQ",
    "provinc": "山东",
    "city": "莱芜市",
    "area": "钢城区"
}, {
    "name": "山东聊城市",
    "match": "山东聊城市|SDLCS",
    "py": "LCS",
    "provinc": "山东",
    "city": "聊城市",
    "area": "聊城市"
}, {
    "name": "山东聊城市东昌府区",
    "match": "山东聊城市东昌府区|SDLCSDCFQ",
    "py": "DCFQ",
    "provinc": "山东",
    "city": "聊城市",
    "area": "东昌府区"
}, {
    "name": "山东聊城市阳谷县",
    "match": "山东聊城市阳谷县|SDLCSYGX",
    "py": "YGX",
    "provinc": "山东",
    "city": "聊城市",
    "area": "阳谷县"
}, {
    "name": "山东聊城市莘县",
    "match": "山东聊城市莘县|SDLCSXX",
    "py": "XX",
    "provinc": "山东",
    "city": "聊城市",
    "area": "莘县"
}, {
    "name": "山东聊城市茌平县",
    "match": "山东聊城市茌平县|SDLCSCPX",
    "py": "CPX",
    "provinc": "山东",
    "city": "聊城市",
    "area": "茌平县"
}, {
    "name": "山东聊城市东阿县",
    "match": "山东聊城市东阿县|SDLCSDAX",
    "py": "DAX",
    "provinc": "山东",
    "city": "聊城市",
    "area": "东阿县"
}, {
    "name": "山东聊城市冠县",
    "match": "山东聊城市冠县|SDLCSGX",
    "py": "GX",
    "provinc": "山东",
    "city": "聊城市",
    "area": "冠县"
}, {
    "name": "山东聊城市高唐县",
    "match": "山东聊城市高唐县|SDLCSGTX",
    "py": "GTX",
    "provinc": "山东",
    "city": "聊城市",
    "area": "高唐县"
}, {
    "name": "山东聊城市临清区",
    "match": "山东聊城市临清区|SDLCSLQQ",
    "py": "LQQ",
    "provinc": "山东",
    "city": "聊城市",
    "area": "临清区"
}, {
    "name": "山西朔州市",
    "match": "山西朔州市|SXSZS",
    "py": "SZS",
    "provinc": "山西",
    "city": "朔州市",
    "area": "朔州市"
}, {
    "name": "山西朔州市朔城区",
    "match": "山西朔州市朔城区|SXSZSSCQ",
    "py": "SCQ",
    "provinc": "山西",
    "city": "朔州市",
    "area": "朔城区"
}, {
    "name": "山西朔州市平鲁区",
    "match": "山西朔州市平鲁区|SXSZSPLQ",
    "py": "PLQ",
    "provinc": "山西",
    "city": "朔州市",
    "area": "平鲁区"
}, {
    "name": "山西朔州市山阴县",
    "match": "山西朔州市山阴县|SXSZSSYX",
    "py": "SYX",
    "provinc": "山西",
    "city": "朔州市",
    "area": "山阴县"
}, {
    "name": "山西朔州市应县",
    "match": "山西朔州市应县|SXSZSYX",
    "py": "YX",
    "provinc": "山西",
    "city": "朔州市",
    "area": "应县"
}, {
    "name": "山西朔州市右玉县",
    "match": "山西朔州市右玉县|SXSZSYYX",
    "py": "YYX",
    "provinc": "山西",
    "city": "朔州市",
    "area": "右玉县"
}, {
    "name": "山西朔州市怀仁县",
    "match": "山西朔州市怀仁县|SXSZSHRX",
    "py": "HRX",
    "provinc": "山西",
    "city": "朔州市",
    "area": "怀仁县"
}, {
    "name": "山西忻州市",
    "match": "山西忻州市|SXXZS",
    "py": "XZS",
    "provinc": "山西",
    "city": "忻州市",
    "area": "忻州市"
}, {
    "name": "山西忻州市忻府区",
    "match": "山西忻州市忻府区|SXXZSXFQ",
    "py": "XFQ",
    "provinc": "山西",
    "city": "忻州市",
    "area": "忻府区"
}, {
    "name": "山西忻州市定襄县",
    "match": "山西忻州市定襄县|SXXZSDXX",
    "py": "DXX",
    "provinc": "山西",
    "city": "忻州市",
    "area": "定襄县"
}, {
    "name": "山西忻州市五台县",
    "match": "山西忻州市五台县|SXXZSWTX",
    "py": "WTX",
    "provinc": "山西",
    "city": "忻州市",
    "area": "五台县"
}, {
    "name": "山西忻州市代县",
    "match": "山西忻州市代县|SXXZSDX",
    "py": "DX",
    "provinc": "山西",
    "city": "忻州市",
    "area": "代县"
}, {
    "name": "山西忻州市繁峙县",
    "match": "山西忻州市繁峙县|SXXZSFZX",
    "py": "FZX",
    "provinc": "山西",
    "city": "忻州市",
    "area": "繁峙县"
}, {
    "name": "山西忻州市宁武县",
    "match": "山西忻州市宁武县|SXXZSNWX",
    "py": "NWX",
    "provinc": "山西",
    "city": "忻州市",
    "area": "宁武县"
}, {
    "name": "山西忻州市静乐县",
    "match": "山西忻州市静乐县|SXXZSJLX",
    "py": "JLX",
    "provinc": "山西",
    "city": "忻州市",
    "area": "静乐县"
}, {
    "name": "山西忻州市神池县",
    "match": "山西忻州市神池县|SXXZSSCX",
    "py": "SCX",
    "provinc": "山西",
    "city": "忻州市",
    "area": "神池县"
}, {
    "name": "山西忻州市五寨县",
    "match": "山西忻州市五寨县|SXXZSWZX",
    "py": "WZX",
    "provinc": "山西",
    "city": "忻州市",
    "area": "五寨县"
}, {
    "name": "山西忻州市岢岚县",
    "match": "山西忻州市岢岚县|SXXZSKLX",
    "py": "KLX",
    "provinc": "山西",
    "city": "忻州市",
    "area": "岢岚县"
}, {
    "name": "山西忻州市河曲县",
    "match": "山西忻州市河曲县|SXXZSHQX",
    "py": "HQX",
    "provinc": "山西",
    "city": "忻州市",
    "area": "河曲县"
}, {
    "name": "山西忻州市保德县",
    "match": "山西忻州市保德县|SXXZSBDX",
    "py": "BDX",
    "provinc": "山西",
    "city": "忻州市",
    "area": "保德县"
}, {
    "name": "山西忻州市偏关县",
    "match": "山西忻州市偏关县|SXXZSPGX",
    "py": "PGX",
    "provinc": "山西",
    "city": "忻州市",
    "area": "偏关县"
}, {
    "name": "山西忻州市原平市",
    "match": "山西忻州市原平市|SXXZSYPS",
    "py": "YPS",
    "provinc": "山西",
    "city": "忻州市",
    "area": "原平市"
}, {
    "name": "山西太原市",
    "match": "山西太原市|SXTYS",
    "py": "TYS",
    "provinc": "山西",
    "city": "太原市",
    "area": "太原市"
}, {
    "name": "山西太原市小店区",
    "match": "山西太原市小店区|SXTYSXDQ",
    "py": "XDQ",
    "provinc": "山西",
    "city": "太原市",
    "area": "小店区"
}, {
    "name": "山西太原市迎泽区",
    "match": "山西太原市迎泽区|SXTYSYZQ",
    "py": "YZQ",
    "provinc": "山西",
    "city": "太原市",
    "area": "迎泽区"
}, {
    "name": "山西太原市杏花岭区",
    "match": "山西太原市杏花岭区|SXTYSXHLQ",
    "py": "XHLQ",
    "provinc": "山西",
    "city": "太原市",
    "area": "杏花岭区"
}, {
    "name": "山西太原市尖草坪区",
    "match": "山西太原市尖草坪区|SXTYSJCPQ",
    "py": "JCPQ",
    "provinc": "山西",
    "city": "太原市",
    "area": "尖草坪区"
}, {
    "name": "山西太原市万柏林区",
    "match": "山西太原市万柏林区|SXTYSWBLQ",
    "py": "WBLQ",
    "provinc": "山西",
    "city": "太原市",
    "area": "万柏林区"
}, {
    "name": "山西太原市晋源区",
    "match": "山西太原市晋源区|SXTYSJYQ",
    "py": "JYQ",
    "provinc": "山西",
    "city": "太原市",
    "area": "晋源区"
}, {
    "name": "山西太原市清徐县",
    "match": "山西太原市清徐县|SXTYSQXX",
    "py": "QXX",
    "provinc": "山西",
    "city": "太原市",
    "area": "清徐县"
}, {
    "name": "山西太原市阳曲县",
    "match": "山西太原市阳曲县|SXTYSYQX",
    "py": "YQX",
    "provinc": "山西",
    "city": "太原市",
    "area": "阳曲县"
}, {
    "name": "山西太原市娄烦县",
    "match": "山西太原市娄烦县|SXTYSLFX",
    "py": "LFX",
    "provinc": "山西",
    "city": "太原市",
    "area": "娄烦县"
}, {
    "name": "山西太原市古交市",
    "match": "山西太原市古交市|SXTYSGJS",
    "py": "GJS",
    "provinc": "山西",
    "city": "太原市",
    "area": "古交市"
}, {
    "name": "山西大同市",
    "match": "山西大同市|SXDTS",
    "py": "DTS",
    "provinc": "山西",
    "city": "大同市",
    "area": "大同市"
},

    /*ADD*/
    {
        "name": "山西大同市城区",
        "match": "山西大同市城区|SXDTSCQ",
        "py": "CQ",
        "provinc": "山西",
        "city": "大同市",
        "area": "城区"
    },
    /*ADD*/

    {
    "name": "山西大同市矿区",
    "match": "山西大同市矿区|SXDTSKQ",
    "py": "KQ",
    "provinc": "山西",
    "city": "大同市",
    "area": "矿区"
}, {
    "name": "山西大同市南郊区",
    "match": "山西大同市南郊区|SXDTSNJQ",
    "py": "NJQ",
    "provinc": "山西",
    "city": "大同市",
    "area": "南郊区"
}, {
    "name": "山西大同市新荣区",
    "match": "山西大同市新荣区|SXDTSXRQ",
    "py": "XRQ",
    "provinc": "山西",
    "city": "大同市",
    "area": "新荣区"
}, {
    "name": "山西大同市阳高县",
    "match": "山西大同市阳高县|SXDTSYGX",
    "py": "YGX",
    "provinc": "山西",
    "city": "大同市",
    "area": "阳高县"
}, {
    "name": "山西大同市天镇县",
    "match": "山西大同市天镇县|SXDTSTZX",
    "py": "TZX",
    "provinc": "山西",
    "city": "大同市",
    "area": "天镇县"
}, {
    "name": "山西大同市广灵县",
    "match": "山西大同市广灵县|SXDTSGLX",
    "py": "GLX",
    "provinc": "山西",
    "city": "大同市",
    "area": "广灵县"
}, {
    "name": "山西大同市灵丘县",
    "match": "山西大同市灵丘县|SXDTSLQX",
    "py": "LQX",
    "provinc": "山西",
    "city": "大同市",
    "area": "灵丘县"
}, {
    "name": "山西大同市浑源县",
    "match": "山西大同市浑源县|SXDTSHYX",
    "py": "HYX",
    "provinc": "山西",
    "city": "大同市",
    "area": "浑源县"
}, {
    "name": "山西大同市左云县",
    "match": "山西大同市左云县|SXDTSZYX",
    "py": "ZYX",
    "provinc": "山西",
    "city": "大同市",
    "area": "左云县"
}, {
    "name": "山西大同市大同县",
    "match": "山西大同市大同县|SXDTSDTX",
    "py": "DTX",
    "provinc": "山西",
    "city": "大同市",
    "area": "大同县"
}, {
    "name": "山西阳泉市",
    "match": "山西阳泉市|SXYQS",
    "py": "YQS",
    "provinc": "山西",
    "city": "阳泉市",
    "area": "阳泉市"
},

    /*ADD*/
    {
        "name": "山西阳泉市城区",
        "match": "山西阳泉市城区|SXYQSCQ",
        "py": "CQ",
        "provinc": "山西",
        "city": "阳泉市",
        "area": "城区"
    },
    {
        "name": "山西阳泉市郊区",
        "match": "山西阳泉市郊区|SXYQSJQ",
        "py": "JQ",
        "provinc": "山西",
        "city": "阳泉市",
        "area": "郊区"
    },
    /*ADD*/

    {
    "name": "山西阳泉市矿区",
    "match": "山西阳泉市矿区|SXYQSKQ",
    "py": "KQ",
    "provinc": "山西",
    "city": "阳泉市",
    "area": "矿区"
}, {
    "name": "山西阳泉市平定县",
    "match": "山西阳泉市平定县|SXYQSPDX",
    "py": "PDX",
    "provinc": "山西",
    "city": "阳泉市",
    "area": "平定县"
}, {
    "name": "山西阳泉市盂县",
    "match": "山西阳泉市盂县|SXYQSYX",
    "py": "YX",
    "provinc": "山西",
    "city": "阳泉市",
    "area": "盂县"
}, {
    "name": "山西晋中市",
    "match": "山西晋中市|SXJZS",
    "py": "JZS",
    "provinc": "山西",
    "city": "晋中市",
    "area": "晋中市"
}, {
    "name": "山西晋中市榆次区",
    "match": "山西晋中市榆次区|SXJZSYCQ",
    "py": "YCQ",
    "provinc": "山西",
    "city": "晋中市",
    "area": "榆次区"
}, {
    "name": "山西晋中市榆社县",
    "match": "山西晋中市榆社县|SXJZSYSX",
    "py": "YSX",
    "provinc": "山西",
    "city": "晋中市",
    "area": "榆社县"
}, {
    "name": "山西晋中市左权县",
    "match": "山西晋中市左权县|SXJZSZQX",
    "py": "ZQX",
    "provinc": "山西",
    "city": "晋中市",
    "area": "左权县"
}, {
    "name": "山西晋中市和顺县",
    "match": "山西晋中市和顺县|SXJZSHSX",
    "py": "HSX",
    "provinc": "山西",
    "city": "晋中市",
    "area": "和顺县"
}, {
    "name": "山西晋中市昔阳县",
    "match": "山西晋中市昔阳县|SXJZSXYX",
    "py": "XYX",
    "provinc": "山西",
    "city": "晋中市",
    "area": "昔阳县"
}, {
    "name": "山西晋中市寿阳县",
    "match": "山西晋中市寿阳县|SXJZSSYX",
    "py": "SYX",
    "provinc": "山西",
    "city": "晋中市",
    "area": "寿阳县"
}, {
    "name": "山西晋中市太谷县",
    "match": "山西晋中市太谷县|SXJZSTGX",
    "py": "TGX",
    "provinc": "山西",
    "city": "晋中市",
    "area": "太谷县"
}, {
    "name": "山西晋中市祁县",
    "match": "山西晋中市祁县|SXJZSQX",
    "py": "QX",
    "provinc": "山西",
    "city": "晋中市",
    "area": "祁县"
}, {
    "name": "山西晋中市平遥县",
    "match": "山西晋中市平遥县|SXJZSPYX",
    "py": "PYX",
    "provinc": "山西",
    "city": "晋中市",
    "area": "平遥县"
}, {
    "name": "山西晋中市灵石县",
    "match": "山西晋中市灵石县|SXJZSLSX",
    "py": "LSX",
    "provinc": "山西",
    "city": "晋中市",
    "area": "灵石县"
}, {
    "name": "山西晋中市介休市",
    "match": "山西晋中市介休市|SXJZSJXS",
    "py": "JXS",
    "provinc": "山西",
    "city": "晋中市",
    "area": "介休市"
}, {
    "name": "山西长治市",
    "match": "山西长治市|SXCZS",
    "py": "CZS",
    "provinc": "山西",
    "city": "长治市",
    "area": "长治市"
},

    /*ADD*/
    {
        "name": "山西长治市城区",
        "match": "山西长治市城区|SXCZSCQ",
        "py": "CQ",
        "provinc": "山西",
        "city": "长治市",
        "area": "城区"
    },
    {
        "name": "山西长治市郊区",
        "match": "山西长治市郊区|SXCZSJQ",
        "py": "JQ",
        "provinc": "山西",
        "city": "长治市",
        "area": "郊区"
    },
    /*ADD*/

    {
    "name": "山西长治市长治县",
    "match": "山西长治市长治县|SXCZSCZX",
    "py": "CZX",
    "provinc": "山西",
    "city": "长治市",
    "area": "长治县"
}, {
    "name": "山西长治市襄垣县",
    "match": "山西长治市襄垣县|SXCZSXYX",
    "py": "XYX",
    "provinc": "山西",
    "city": "长治市",
    "area": "襄垣县"
}, {
    "name": "山西长治市屯留县",
    "match": "山西长治市屯留县|SXCZSTLX",
    "py": "TLX",
    "provinc": "山西",
    "city": "长治市",
    "area": "屯留县"
}, {
    "name": "山西长治市平顺县",
    "match": "山西长治市平顺县|SXCZSPSX",
    "py": "PSX",
    "provinc": "山西",
    "city": "长治市",
    "area": "平顺县"
}, {
    "name": "山西长治市黎城县",
    "match": "山西长治市黎城县|SXCZSLCX",
    "py": "LCX",
    "provinc": "山西",
    "city": "长治市",
    "area": "黎城县"
}, {
    "name": "山西长治市壶关县",
    "match": "山西长治市壶关县|SXCZSHGX",
    "py": "HGX",
    "provinc": "山西",
    "city": "长治市",
    "area": "壶关县"
}, {
    "name": "山西长治市长子县",
    "match": "山西长治市长子县|SXCZSCZX",
    "py": "CZX",
    "provinc": "山西",
    "city": "长治市",
    "area": "长子县"
}, {
    "name": "山西长治市武乡县",
    "match": "山西长治市武乡县|SXCZSWXX",
    "py": "WXX",
    "provinc": "山西",
    "city": "长治市",
    "area": "武乡县"
}, {
    "name": "山西长治市沁县",
    "match": "山西长治市沁县|SXCZSQX",
    "py": "QX",
    "provinc": "山西",
    "city": "长治市",
    "area": "沁县"
}, {
    "name": "山西长治市沁源县",
    "match": "山西长治市沁源县|SXCZSQYX",
    "py": "QYX",
    "provinc": "山西",
    "city": "长治市",
    "area": "沁源县"
}, {
    "name": "山西长治市潞城市",
    "match": "山西长治市潞城市|SXCZSLCS",
    "py": "LCS",
    "provinc": "山西",
    "city": "长治市",
    "area": "潞城市"
}, {
    "name": "山西晋城市",
    "match": "山西晋城市|SXJCS",
    "py": "JCS",
    "provinc": "山西",
    "city": "晋城市",
    "area": "晋城市"
},

    /*ADD*/
    {
        "name": "山西晋城市城区",
        "match": "山西晋城市城区|SXJCSCQ",
        "py": "CQ",
        "provinc": "山西",
        "city": "晋城市",
        "area": "城区"
    },
    /*ADD*/

    {
    "name": "山西晋城市沁水县",
    "match": "山西晋城市沁水县|SXJCSQSX",
    "py": "QSX",
    "provinc": "山西",
    "city": "晋城市",
    "area": "沁水县"
}, {
    "name": "山西晋城市阳城县",
    "match": "山西晋城市阳城县|SXJCSYCX",
    "py": "YCX",
    "provinc": "山西",
    "city": "晋城市",
    "area": "阳城县"
}, {
    "name": "山西晋城市陵川县",
    "match": "山西晋城市陵川县|SXJCSLCX",
    "py": "LCX",
    "provinc": "山西",
    "city": "晋城市",
    "area": "陵川县"
}, {
    "name": "山西晋城市泽州县",
    "match": "山西晋城市泽州县|SXJCSZZX",
    "py": "ZZX",
    "provinc": "山西",
    "city": "晋城市",
    "area": "泽州县"
}, {
    "name": "山西晋城市高平市",
    "match": "山西晋城市高平市|SXJCSGPS",
    "py": "GPS",
    "provinc": "山西",
    "city": "晋城市",
    "area": "高平市"
}, {
    "name": "山西临汾市",
    "match": "山西临汾市|SXLFS",
    "py": "LFS",
    "provinc": "山西",
    "city": "临汾市",
    "area": "临汾市"
}, {
    "name": "山西临汾市尧都区",
    "match": "山西临汾市尧都区|SXLFSYDQ",
    "py": "YDQ",
    "provinc": "山西",
    "city": "临汾市",
    "area": "尧都区"
}, {
    "name": "山西临汾市曲沃县",
    "match": "山西临汾市曲沃县|SXLFSQWX",
    "py": "QWX",
    "provinc": "山西",
    "city": "临汾市",
    "area": "曲沃县"
}, {
    "name": "山西临汾市翼城县",
    "match": "山西临汾市翼城县|SXLFSYCX",
    "py": "YCX",
    "provinc": "山西",
    "city": "临汾市",
    "area": "翼城县"
}, {
    "name": "山西临汾市襄汾县",
    "match": "山西临汾市襄汾县|SXLFSXFX",
    "py": "XFX",
    "provinc": "山西",
    "city": "临汾市",
    "area": "襄汾县"
}, {
    "name": "山西临汾市洪洞县",
    "match": "山西临汾市洪洞县|SXLFSHDX",
    "py": "HDX",
    "provinc": "山西",
    "city": "临汾市",
    "area": "洪洞县"
}, {
    "name": "山西临汾市古县",
    "match": "山西临汾市古县|SXLFSGX",
    "py": "GX",
    "provinc": "山西",
    "city": "临汾市",
    "area": "古县"
}, {
    "name": "山西临汾市安泽县",
    "match": "山西临汾市安泽县|SXLFSAZX",
    "py": "AZX",
    "provinc": "山西",
    "city": "临汾市",
    "area": "安泽县"
}, {
    "name": "山西临汾市浮山县",
    "match": "山西临汾市浮山县|SXLFSFSX",
    "py": "FSX",
    "provinc": "山西",
    "city": "临汾市",
    "area": "浮山县"
}, {
    "name": "山西临汾市吉县",
    "match": "山西临汾市吉县|SXLFSJX",
    "py": "JX",
    "provinc": "山西",
    "city": "临汾市",
    "area": "吉县"
}, {
    "name": "山西临汾市乡宁县",
    "match": "山西临汾市乡宁县|SXLFSXNX",
    "py": "XNX",
    "provinc": "山西",
    "city": "临汾市",
    "area": "乡宁县"
}, {
    "name": "山西临汾市大宁县",
    "match": "山西临汾市大宁县|SXLFSDNX",
    "py": "DNX",
    "provinc": "山西",
    "city": "临汾市",
    "area": "大宁县"
}, {
    "name": "山西临汾市隰县",
    "match": "山西临汾市隰县|SXLFSXX",
    "py": "XX",
    "provinc": "山西",
    "city": "临汾市",
    "area": "隰县"
}, {
    "name": "山西临汾市永和县",
    "match": "山西临汾市永和县|SXLFSYHX",
    "py": "YHX",
    "provinc": "山西",
    "city": "临汾市",
    "area": "永和县"
}, {
    "name": "山西临汾市蒲县",
    "match": "山西临汾市蒲县|SXLFSPX",
    "py": "PX",
    "provinc": "山西",
    "city": "临汾市",
    "area": "蒲县"
}, {
    "name": "山西临汾市汾西县",
    "match": "山西临汾市汾西县|SXLFSFXX",
    "py": "FXX",
    "provinc": "山西",
    "city": "临汾市",
    "area": "汾西县"
}, {
    "name": "山西临汾市侯马市",
    "match": "山西临汾市侯马市|SXLFSHMS",
    "py": "HMS",
    "provinc": "山西",
    "city": "临汾市",
    "area": "侯马市"
}, {
    "name": "山西临汾市霍州市",
    "match": "山西临汾市霍州市|SXLFSHZS",
    "py": "HZS",
    "provinc": "山西",
    "city": "临汾市",
    "area": "霍州市"
}, {
    "name": "山西吕梁市",
    "match": "山西吕梁市|SXLLS",
    "py": "LLS",
    "provinc": "山西",
    "city": "吕梁市",
    "area": "吕梁市"
}, {
    "name": "山西吕梁市离石区",
    "match": "山西吕梁市离石区|SXLLSLSQ",
    "py": "LSQ",
    "provinc": "山西",
    "city": "吕梁市",
    "area": "离石区"
}, {
    "name": "山西吕梁市文水县",
    "match": "山西吕梁市文水县|SXLLSWSX",
    "py": "WSX",
    "provinc": "山西",
    "city": "吕梁市",
    "area": "文水县"
}, {
    "name": "山西吕梁市交城县",
    "match": "山西吕梁市交城县|SXLLSJCX",
    "py": "JCX",
    "provinc": "山西",
    "city": "吕梁市",
    "area": "交城县"
}, {
    "name": "山西吕梁市兴县",
    "match": "山西吕梁市兴县|SXLLSXX",
    "py": "XX",
    "provinc": "山西",
    "city": "吕梁市",
    "area": "兴县"
}, {
    "name": "山西吕梁市临县",
    "match": "山西吕梁市临县|SXLLSLX",
    "py": "LX",
    "provinc": "山西",
    "city": "吕梁市",
    "area": "临县"
}, {
    "name": "山西吕梁市柳林县",
    "match": "山西吕梁市柳林县|SXLLSLLX",
    "py": "LLX",
    "provinc": "山西",
    "city": "吕梁市",
    "area": "柳林县"
}, {
    "name": "山西吕梁市石楼县",
    "match": "山西吕梁市石楼县|SXLLSSLX",
    "py": "SLX",
    "provinc": "山西",
    "city": "吕梁市",
    "area": "石楼县"
}, {
    "name": "山西吕梁市岚县",
    "match": "山西吕梁市岚县|SXLLSLX",
    "py": "LX",
    "provinc": "山西",
    "city": "吕梁市",
    "area": "岚县"
}, {
    "name": "山西吕梁市方山县",
    "match": "山西吕梁市方山县|SXLLSFSX",
    "py": "FSX",
    "provinc": "山西",
    "city": "吕梁市",
    "area": "方山县"
}, {
    "name": "山西吕梁市中阳县",
    "match": "山西吕梁市中阳县|SXLLSZYX",
    "py": "ZYX",
    "provinc": "山西",
    "city": "吕梁市",
    "area": "中阳县"
}, {
    "name": "山西吕梁市交口县",
    "match": "山西吕梁市交口县|SXLLSJKX",
    "py": "JKX",
    "provinc": "山西",
    "city": "吕梁市",
    "area": "交口县"
}, {
    "name": "山西吕梁市孝义市",
    "match": "山西吕梁市孝义市|SXLLSXYS",
    "py": "XYS",
    "provinc": "山西",
    "city": "吕梁市",
    "area": "孝义市"
}, {
    "name": "山西吕梁市汾阳市",
    "match": "山西吕梁市汾阳市|SXLLSFYS",
    "py": "FYS",
    "provinc": "山西",
    "city": "吕梁市",
    "area": "汾阳市"
}, {
    "name": "山西运城市",
    "match": "山西运城市|SXYCS",
    "py": "YCS",
    "provinc": "山西",
    "city": "运城市",
    "area": "运城市"
}, {
    "name": "山西运城市盐湖区",
    "match": "山西运城市盐湖区|SXYCSYHQ",
    "py": "YHQ",
    "provinc": "山西",
    "city": "运城市",
    "area": "盐湖区"
}, {
    "name": "山西运城市临猗县",
    "match": "山西运城市临猗县|SXYCSLYX",
    "py": "LYX",
    "provinc": "山西",
    "city": "运城市",
    "area": "临猗县"
}, {
    "name": "山西运城市万荣县",
    "match": "山西运城市万荣县|SXYCSWRX",
    "py": "WRX",
    "provinc": "山西",
    "city": "运城市",
    "area": "万荣县"
}, {
    "name": "山西运城市闻喜县",
    "match": "山西运城市闻喜县|SXYCSWXX",
    "py": "WXX",
    "provinc": "山西",
    "city": "运城市",
    "area": "闻喜县"
}, {
    "name": "山西运城市稷山县",
    "match": "山西运城市稷山县|SXYCSJSX",
    "py": "JSX",
    "provinc": "山西",
    "city": "运城市",
    "area": "稷山县"
}, {
    "name": "山西运城市新绛县",
    "match": "山西运城市新绛县|SXYCSXJX",
    "py": "XJX",
    "provinc": "山西",
    "city": "运城市",
    "area": "新绛县"
}, {
    "name": "山西运城市绛县",
    "match": "山西运城市绛县|SXYCSJX",
    "py": "JX",
    "provinc": "山西",
    "city": "运城市",
    "area": "绛县"
}, {
    "name": "山西运城市垣曲县",
    "match": "山西运城市垣曲县|SXYCSYQX",
    "py": "YQX",
    "provinc": "山西",
    "city": "运城市",
    "area": "垣曲县"
}, {
    "name": "山西运城市夏县",
    "match": "山西运城市夏县|SXYCSXX",
    "py": "XX",
    "provinc": "山西",
    "city": "运城市",
    "area": "夏县"
}, {
    "name": "山西运城市平陆县",
    "match": "山西运城市平陆县|SXYCSPLX",
    "py": "PLX",
    "provinc": "山西",
    "city": "运城市",
    "area": "平陆县"
}, {
    "name": "山西运城市芮城县",
    "match": "山西运城市芮城县|SXYCSRCX",
    "py": "RCX",
    "provinc": "山西",
    "city": "运城市",
    "area": "芮城县"
}, {
    "name": "山西运城市永济市",
    "match": "山西运城市永济市|SXYCSYJS",
    "py": "YJS",
    "provinc": "山西",
    "city": "运城市",
    "area": "永济市"
}, {
    "name": "山西运城市河津市",
    "match": "山西运城市河津市|SXYCSHJS",
    "py": "HJS",
    "provinc": "山西",
    "city": "运城市",
    "area": "河津市"
}, {
    "name": "广东广州市",
    "match": "广东广州市|GDGZS",
    "py": "GZS",
    "provinc": "广东",
    "city": "广州市",
    "area": "广州市"
}, {
    "name": "广东广州市从化区",
    "match": "广东广州市从化区|GDGZSCHQ",
    "py": "CHQ",
    "provinc": "广东",
    "city": "广州市",
    "area": "从化区"
}, {
    "name": "广东广州市荔湾区",
    "match": "广东广州市荔湾区|GDGZSLWQ",
    "py": "LWQ",
    "provinc": "广东",
    "city": "广州市",
    "area": "荔湾区"
}, {
    "name": "广东广州市越秀区",
    "match": "广东广州市越秀区|GDGZSYXQ",
    "py": "YXQ",
    "provinc": "广东",
    "city": "广州市",
    "area": "越秀区"
}, {
    "name": "广东广州市海珠区",
    "match": "广东广州市海珠区|GDGZSHZQ",
    "py": "HZQ",
    "provinc": "广东",
    "city": "广州市",
    "area": "海珠区"
}, {
    "name": "广东广州市天河区",
    "match": "广东广州市天河区|GDGZSTHQ",
    "py": "THQ",
    "provinc": "广东",
    "city": "广州市",
    "area": "天河区"
}, {
    "name": "广东广州市白云区",
    "match": "广东广州市白云区|GDGZSBYQ",
    "py": "BYQ",
    "provinc": "广东",
    "city": "广州市",
    "area": "白云区"
}, {
    "name": "广东广州市花都区",
    "match": "广东广州市花都区|GDGZSHDQ",
    "py": "HDQ",
    "provinc": "广东",
    "city": "广州市",
    "area": "花都区"
}, {
    "name": "广东广州市黄埔区",
    "match": "广东广州市黄埔区|GDGZSHPQ",
    "py": "HPQ",
    "provinc": "广东",
    "city": "广州市",
    "area": "黄埔区"
}, /*{
    "name": "广东广州市萝岗区",
    "match": "广东广州市萝岗区|GDGZSLGQ",
    "py": "LGQ",
    "provinc": "广东",
    "city": "广州市",
    "area": "萝岗区"
},*/ {
    "name": "广东广州市南沙区",
    "match": "广东广州市南沙区|GDGZSNSQ",
    "py": "NSQ",
    "provinc": "广东",
    "city": "广州市",
    "area": "南沙区"
}, {
    "name": "广东广州市番禺区",
    "match": "广东广州市番禺区|GDGZSFYQ",
    "py": "FYQ",
    "provinc": "广东",
    "city": "广州市",
    "area": "番禺区"
}, {
    "name": "广东广州市增城区",
    "match": "广东广州市增城区|GDGZSZCQ",
    "py": "ZCQ",
    "provinc": "广东",
    "city": "广州市",
    "area": "增城区"
}, {
    "name": "广东汕尾市",
    "match": "广东汕尾市|GDSWS",
    "py": "SWS",
    "provinc": "广东",
    "city": "汕尾市",
    "area": "汕尾市"
},

/*ADD*/
    {
        "name": "广东汕尾市城区",
        "match": "广东汕尾市城区|GDSWSCQ",
        "py": "CQ",
        "provinc": "广东",
        "city": "汕尾市",
        "area": "城区"
    },
/*ADD*/


{
    "name": "广东汕尾市海丰县",
    "match": "广东汕尾市海丰县|GDSWSHFX",
    "py": "HFX",
    "provinc": "广东",
    "city": "汕尾市",
    "area": "海丰县"
}, {
    "name": "广东汕尾市陆河县",
    "match": "广东汕尾市陆河县|GDSWSLHX",
    "py": "LHX",
    "provinc": "广东",
    "city": "汕尾市",
    "area": "陆河县"
}, {
    "name": "广东汕尾市陆丰市",
    "match": "广东汕尾市陆丰市|GDSWSLFS",
    "py": "LFS",
    "provinc": "广东",
    "city": "汕尾市",
    "area": "陆丰市"
}, {
    "name": "广东阳江市",
    "match": "广东阳江市|GDYJS",
    "py": "YJS",
    "provinc": "广东",
    "city": "阳江市",
    "area": "阳江市"
}, {
    "name": "广东阳江市江城区",
    "match": "广东阳江市江城区|GDYJSJCQ",
    "py": "JCQ",
    "provinc": "广东",
    "city": "阳江市",
    "area": "江城区"
}, {
    "name": "广东阳江市阳西县",
    "match": "广东阳江市阳西县|GDYJSYXX",
    "py": "YXX",
    "provinc": "广东",
    "city": "阳江市",
    "area": "阳西县"
}, {
    "name": "广东阳江市阳东县",
    "match": "广东阳江市阳东县|GDYJSYDX",
    "py": "YDX",
    "provinc": "广东",
    "city": "阳江市",
    "area": "阳东县"
}, {
    "name": "广东阳江市阳春市",
    "match": "广东阳江市阳春市|GDYJSYCS",
    "py": "YCS",
    "provinc": "广东",
    "city": "阳江市",
    "area": "阳春市"
}, {
    "name": "广东揭阳市",
    "match": "广东揭阳市|GDJYS",
    "py": "JYS",
    "provinc": "广东",
    "city": "揭阳市",
    "area": "揭阳市"
}, {
    "name": "广东揭阳市榕城区",
    "match": "广东揭阳市榕城区|GDJYSRCQ",
    "py": "RCQ",
    "provinc": "广东",
    "city": "揭阳市",
    "area": "榕城区"
}, {
    "name": "广东揭阳市揭东区",
    "match": "广东揭阳市揭东区|GDJYSJDQ",
    "py": "JDQ",
    "provinc": "广东",
    "city": "揭阳市",
    "area": "揭东区"
}, {
    "name": "广东揭阳市揭西县",
    "match": "广东揭阳市揭西县|GDJYSJXX",
    "py": "JXX",
    "provinc": "广东",
    "city": "揭阳市",
    "area": "揭西县"
}, {
    "name": "广东揭阳市惠来县",
    "match": "广东揭阳市惠来县|GDJYSHLX",
    "py": "HLX",
    "provinc": "广东",
    "city": "揭阳市",
    "area": "惠来县"
}, {
    "name": "广东揭阳市普宁市",
    "match": "广东揭阳市普宁市|GDJYSPNS",
    "py": "PNS",
    "provinc": "广东",
    "city": "揭阳市",
    "area": "普宁市"
}, {
    "name": "广东茂名市",
    "match": "广东茂名市|GDMMS",
    "py": "MMS",
    "provinc": "广东",
    "city": "茂名市",
    "area": "茂名市"
}, {
    "name": "广东茂名市茂南区",
    "match": "广东茂名市茂南区|GDMMSMNQ",
    "py": "MNQ",
    "provinc": "广东",
    "city": "茂名市",
    "area": "茂南区"
}, /*{
    "name": "广东茂名市茂港区",
    "match": "广东茂名市茂港区|GDMMSMGQ",
    "py": "MGQ",
    "provinc": "广东",
    "city": "茂名市",
    "area": "茂港区"
},*/ {
    "name": "广东茂名市电白区",
    "match": "广东茂名市电白区|GDMMSDBQ",
    "py": "DBQ",
    "provinc": "广东",
    "city": "茂名市",
    "area": "电白区"
}, {
    "name": "广东茂名市高州市",
    "match": "广东茂名市高州市|GDMMSGZS",
    "py": "GZS",
    "provinc": "广东",
    "city": "茂名市",
    "area": "高州市"
}, {
    "name": "广东茂名市化州市",
    "match": "广东茂名市化州市|GDMMSHZS",
    "py": "HZS",
    "provinc": "广东",
    "city": "茂名市",
    "area": "化州市"
}, {
    "name": "广东茂名市信宜市",
    "match": "广东茂名市信宜市|GDMMSXYS",
    "py": "XYS",
    "provinc": "广东",
    "city": "茂名市",
    "area": "信宜市"
}, {
    "name": "广东惠州市",
    "match": "广东惠州市|GDHZS",
    "py": "HZS",
    "provinc": "广东",
    "city": "惠州市",
    "area": "惠州市"
}, {
    "name": "广东惠州市惠城区",
    "match": "广东惠州市惠城区|GDHZSHCQ",
    "py": "HCQ",
    "provinc": "广东",
    "city": "惠州市",
    "area": "惠城区"
}, {
    "name": "广东惠州市惠阳区",
    "match": "广东惠州市惠阳区|GDHZSHYQ",
    "py": "HYQ",
    "provinc": "广东",
    "city": "惠州市",
    "area": "惠阳区"
}, {
    "name": "广东惠州市博罗县",
    "match": "广东惠州市博罗县|GDHZSBLX",
    "py": "BLX",
    "provinc": "广东",
    "city": "惠州市",
    "area": "博罗县"
}, {
    "name": "广东惠州市惠东县",
    "match": "广东惠州市惠东县|GDHZSHDX",
    "py": "HDX",
    "provinc": "广东",
    "city": "惠州市",
    "area": "惠东县"
}, {
    "name": "广东惠州市龙门县",
    "match": "广东惠州市龙门县|GDHZSLMX",
    "py": "LMX",
    "provinc": "广东",
    "city": "惠州市",
    "area": "龙门县"
}, {
    "name": "广东江门市",
    "match": "广东江门市|GDJMS",
    "py": "JMS",
    "provinc": "广东",
    "city": "江门市",
    "area": "江门市"
}, {
    "name": "广东江门市蓬江区",
    "match": "广东江门市蓬江区|GDJMSPJQ",
    "py": "PJQ",
    "provinc": "广东",
    "city": "江门市",
    "area": "蓬江区"
}, {
    "name": "广东江门市江海区",
    "match": "广东江门市江海区|GDJMSJHQ",
    "py": "JHQ",
    "provinc": "广东",
    "city": "江门市",
    "area": "江海区"
}, {
    "name": "广东江门市新会区",
    "match": "广东江门市新会区|GDJMSXHQ",
    "py": "XHQ",
    "provinc": "广东",
    "city": "江门市",
    "area": "新会区"
}, {
    "name": "广东江门市台山市",
    "match": "广东江门市台山市|GDJMSTSS",
    "py": "TSS",
    "provinc": "广东",
    "city": "江门市",
    "area": "台山市"
}, {
    "name": "广东江门市开平市",
    "match": "广东江门市开平市|GDJMSKPS",
    "py": "KPS",
    "provinc": "广东",
    "city": "江门市",
    "area": "开平市"
}, {
    "name": "广东江门市鹤山市",
    "match": "广东江门市鹤山市|GDJMSHSS",
    "py": "HSS",
    "provinc": "广东",
    "city": "江门市",
    "area": "鹤山市"
}, {
    "name": "广东江门市恩平市",
    "match": "广东江门市恩平市|GDJMSEPS",
    "py": "EPS",
    "provinc": "广东",
    "city": "江门市",
    "area": "恩平市"
}, {
    "name": "广东韶关市",
    "match": "广东韶关市|GDSGS",
    "py": "SGS",
    "provinc": "广东",
    "city": "韶关市",
    "area": "韶关市"
}, {
    "name": "广东韶关市武江区",
    "match": "广东韶关市武江区|GDSGSWJQ",
    "py": "WJQ",
    "provinc": "广东",
    "city": "韶关市",
    "area": "武江区"
}, {
    "name": "广东韶关市浈江区",
    "match": "广东韶关市浈江区|GDSGSZJQ",
    "py": "ZJQ",
    "provinc": "广东",
    "city": "韶关市",
    "area": "浈江区"
}, {
    "name": "广东韶关市曲江区",
    "match": "广东韶关市曲江区|GDSGSQJQ",
    "py": "QJQ",
    "provinc": "广东",
    "city": "韶关市",
    "area": "曲江区"
}, {
    "name": "广东韶关市始兴县",
    "match": "广东韶关市始兴县|GDSGSSXX",
    "py": "SXX",
    "provinc": "广东",
    "city": "韶关市",
    "area": "始兴县"
}, {
    "name": "广东韶关市仁化县",
    "match": "广东韶关市仁化县|GDSGSRHX",
    "py": "RHX",
    "provinc": "广东",
    "city": "韶关市",
    "area": "仁化县"
}, {
    "name": "广东韶关市翁源县",
    "match": "广东韶关市翁源县|GDSGSWYX",
    "py": "WYX",
    "provinc": "广东",
    "city": "韶关市",
    "area": "翁源县"
}, {
    "name": "广东韶关市乳源县",
    "match": "广东韶关市乳源县|GDSGSRYZZX",
    "py": "RYZZX",
    "provinc": "广东",
    "city": "韶关市",
    "area": "乳源县"
}, {
    "name": "广东韶关市新丰县",
    "match": "广东韶关市新丰县|GDSGSXFX",
    "py": "XFX",
    "provinc": "广东",
    "city": "韶关市",
    "area": "新丰县"
}, {
    "name": "广东韶关市乐昌市",
    "match": "广东韶关市乐昌市|GDSGSLCS",
    "py": "LCS",
    "provinc": "广东",
    "city": "韶关市",
    "area": "乐昌市"
}, {
    "name": "广东韶关市南雄市",
    "match": "广东韶关市南雄市|GDSGSNXS",
    "py": "NXS",
    "provinc": "广东",
    "city": "韶关市",
    "area": "南雄市"
}, {
    "name": "广东梅州市",
    "match": "广东梅州市|GDMZS",
    "py": "MZS",
    "provinc": "广东",
    "city": "梅州市",
    "area": "梅州市"
}, {
    "name": "广东梅州市梅江区",
    "match": "广东梅州市梅江区|GDMZSMJQ",
    "py": "MJQ",
    "provinc": "广东",
    "city": "梅州市",
    "area": "梅江区"
}, {
    "name": "广东梅州市梅县区",
    "match": "广东梅州市梅县区|GDMZSMXQ",
    "py": "MXQ",
    "provinc": "广东",
    "city": "梅州市",
    "area": "梅县区"
}, {
    "name": "广东梅州市大埔县",
    "match": "广东梅州市大埔县|GDMZSDPX",
    "py": "DPX",
    "provinc": "广东",
    "city": "梅州市",
    "area": "大埔县"
}, {
    "name": "广东梅州市丰顺县",
    "match": "广东梅州市丰顺县|GDMZSFSX",
    "py": "FSX",
    "provinc": "广东",
    "city": "梅州市",
    "area": "丰顺县"
}, {
    "name": "广东梅州市五华县",
    "match": "广东梅州市五华县|GDMZSWHX",
    "py": "WHX",
    "provinc": "广东",
    "city": "梅州市",
    "area": "五华县"
}, {
    "name": "广东梅州市平远县",
    "match": "广东梅州市平远县|GDMZSPYX",
    "py": "PYX",
    "provinc": "广东",
    "city": "梅州市",
    "area": "平远县"
}, {
    "name": "广东梅州市蕉岭县",
    "match": "广东梅州市蕉岭县|GDMZSJLX",
    "py": "JLX",
    "provinc": "广东",
    "city": "梅州市",
    "area": "蕉岭县"
}, {
    "name": "广东梅州市兴宁市",
    "match": "广东梅州市兴宁市|GDMZSXNS",
    "py": "XNS",
    "provinc": "广东",
    "city": "梅州市",
    "area": "兴宁市"
}, {
    "name": "广东汕头市",
    "match": "广东汕头市|GDSTS",
    "py": "STS",
    "provinc": "广东",
    "city": "汕头市",
    "area": "汕头市"
}, {
    "name": "广东汕头市龙湖区",
    "match": "广东汕头市龙湖区|GDSTSLHQ",
    "py": "LHQ",
    "provinc": "广东",
    "city": "汕头市",
    "area": "龙湖区"
}, {
    "name": "广东汕头市金平区",
    "match": "广东汕头市金平区|GDSTSJPQ",
    "py": "JPQ",
    "provinc": "广东",
    "city": "汕头市",
    "area": "金平区"
}, {
    "name": "广东汕头市濠江区",
    "match": "广东汕头市濠江区|GDSTSHJQ",
    "py": "HJQ",
    "provinc": "广东",
    "city": "汕头市",
    "area": "濠江区"
}, {
    "name": "广东汕头市潮阳区",
    "match": "广东汕头市潮阳区|GDSTSCYQ",
    "py": "CYQ",
    "provinc": "广东",
    "city": "汕头市",
    "area": "潮阳区"
}, {
    "name": "广东汕头市潮南区",
    "match": "广东汕头市潮南区|GDSTSCNQ",
    "py": "CNQ",
    "provinc": "广东",
    "city": "汕头市",
    "area": "潮南区"
}, {
    "name": "广东汕头市澄海区",
    "match": "广东汕头市澄海区|GDSTSCHQ",
    "py": "CHQ",
    "provinc": "广东",
    "city": "汕头市",
    "area": "澄海区"
}, {
    "name": "广东汕头市南澳县",
    "match": "广东汕头市南澳县|GDSTSNAX",
    "py": "NAX",
    "provinc": "广东",
    "city": "汕头市",
    "area": "南澳县"
}, {
    "name": "广东深圳市",
    "match": "广东深圳市|GDSZS",
    "py": "SZS",
    "provinc": "广东",
    "city": "深圳市",
    "area": "深圳市"
},

    /*ADD*/
    {
        "name": "广东深圳市龙华区",
        "match": "广东深圳市龙华区|GDSZSLHQ",
        "py": "LHQ",
        "provinc": "广东",
        "city": "深圳市",
        "area": "龙华区"
    },
    {
        "name": "广东深圳市坪山区",
        "match": "广东深圳市坪山区|GDSZSPSQ",
        "py": "PSQ",
        "provinc": "广东",
        "city": "深圳市",
        "area": "坪山区"
    },
    {
        "name": "广东深圳市光明新区",
        "match": "广东深圳市光明新区|GDSZSGMXQ",
        "py": "GMXQ",
        "provinc": "广东",
        "city": "深圳市",
        "area": "光明新区"
    },
    {
        "name": "广东深圳市大鹏新区",
        "match": "广东深圳市大鹏新区|GDSZSDPXQ",
        "py": "DPXQ",
        "provinc": "广东",
        "city": "深圳市",
        "area": "大鹏新区"
    },
    /*ADD*/

    {
    "name": "广东深圳市罗湖区",
    "match": "广东深圳市罗湖区|GDSZSLHQ",
    "py": "LHQ",
    "provinc": "广东",
    "city": "深圳市",
    "area": "罗湖区"
}, {
    "name": "广东深圳市福田区",
    "match": "广东深圳市福田区|GDSZSFTQ",
    "py": "FTQ",
    "provinc": "广东",
    "city": "深圳市",
    "area": "福田区"
}, {
    "name": "广东深圳市南山区",
    "match": "广东深圳市南山区|GDSZSNSQ",
    "py": "NSQ",
    "provinc": "广东",
    "city": "深圳市",
    "area": "南山区"
}, {
    "name": "广东深圳市宝安区",
    "match": "广东深圳市宝安区|GDSZSBAQ",
    "py": "BAQ",
    "provinc": "广东",
    "city": "深圳市",
    "area": "宝安区"
}, {
    "name": "广东深圳市龙岗区",
    "match": "广东深圳市龙岗区|GDSZSLGQ",
    "py": "LGQ",
    "provinc": "广东",
    "city": "深圳市",
    "area": "龙岗区"
}, {
    "name": "广东深圳市盐田区",
    "match": "广东深圳市盐田区|GDSZSYTQ",
    "py": "YTQ",
    "provinc": "广东",
    "city": "深圳市",
    "area": "盐田区"
}, {
    "name": "广东珠海市",
    "match": "广东珠海市|GDZHS",
    "py": "ZHS",
    "provinc": "广东",
    "city": "珠海市",
    "area": "珠海市"
}, {
    "name": "广东珠海市香洲区",
    "match": "广东珠海市香洲区|GDZHSXZQ",
    "py": "XZQ",
    "provinc": "广东",
    "city": "珠海市",
    "area": "香洲区"
}, {
    "name": "广东珠海市斗门区",
    "match": "广东珠海市斗门区|GDZHSDMQ",
    "py": "DMQ",
    "provinc": "广东",
    "city": "珠海市",
    "area": "斗门区"
}, {
    "name": "广东珠海市金湾区",
    "match": "广东珠海市金湾区|GDZHSJWQ",
    "py": "JWQ",
    "provinc": "广东",
    "city": "珠海市",
    "area": "金湾区"
}, {
    "name": "广东佛山市",
    "match": "广东佛山市|GDFSS",
    "py": "FSS",
    "provinc": "广东",
    "city": "佛山市",
    "area": "佛山市"
}, {
    "name": "广东佛山市禅城区",
    "match": "广东佛山市禅城区|GDFSSCCQ",
    "py": "CCQ",
    "provinc": "广东",
    "city": "佛山市",
    "area": "禅城区"
}, {
    "name": "广东佛山市南海区",
    "match": "广东佛山市南海区|GDFSSNHQ",
    "py": "NHQ",
    "provinc": "广东",
    "city": "佛山市",
    "area": "南海区"
}, {
    "name": "广东佛山市顺德区",
    "match": "广东佛山市顺德区|GDFSSSDQ",
    "py": "SDQ",
    "provinc": "广东",
    "city": "佛山市",
    "area": "顺德区"
}, {
    "name": "广东佛山市三水区",
    "match": "广东佛山市三水区|GDFSSSSQ",
    "py": "SSQ",
    "provinc": "广东",
    "city": "佛山市",
    "area": "三水区"
}, {
    "name": "广东佛山市高明区",
    "match": "广东佛山市高明区|GDFSSGMQ",
    "py": "GMQ",
    "provinc": "广东",
    "city": "佛山市",
    "area": "高明区"
}, {
    "name": "广东肇庆市",
    "match": "广东肇庆市|GDZQS",
    "py": "ZQS",
    "provinc": "广东",
    "city": "肇庆市",
    "area": "肇庆市"
}, {
    "name": "广东肇庆市端州区",
    "match": "广东肇庆市端州区|GDZQSDZQ",
    "py": "DZQ",
    "provinc": "广东",
    "city": "肇庆市",
    "area": "端州区"
}, {
    "name": "广东肇庆市鼎湖区",
    "match": "广东肇庆市鼎湖区|GDZQSDHQ",
    "py": "DHQ",
    "provinc": "广东",
    "city": "肇庆市",
    "area": "鼎湖区"
}, {
    "name": "广东肇庆市广宁县",
    "match": "广东肇庆市广宁县|GDZQSGNX",
    "py": "GNX",
    "provinc": "广东",
    "city": "肇庆市",
    "area": "广宁县"
}, {
    "name": "广东肇庆市怀集县",
    "match": "广东肇庆市怀集县|GDZQSHJX",
    "py": "HJX",
    "provinc": "广东",
    "city": "肇庆市",
    "area": "怀集县"
}, {
    "name": "广东肇庆市封开县",
    "match": "广东肇庆市封开县|GDZQSFKX",
    "py": "FKX",
    "provinc": "广东",
    "city": "肇庆市",
    "area": "封开县"
}, {
    "name": "广东肇庆市德庆县",
    "match": "广东肇庆市德庆县|GDZQSDQX",
    "py": "DQX",
    "provinc": "广东",
    "city": "肇庆市",
    "area": "德庆县"
}, {
    "name": "广东肇庆市高要区",
    "match": "广东肇庆市高要区|GDZQSGYQ",
    "py": "GYQ",
    "provinc": "广东",
    "city": "肇庆市",
    "area": "高要区"
}, {
    "name": "广东肇庆市四会市",
    "match": "广东肇庆市四会市|GDZQSSHS",
    "py": "SHS",
    "provinc": "广东",
    "city": "肇庆市",
    "area": "四会市"
}, {
    "name": "广东湛江市",
    "match": "广东湛江市|GDZJS",
    "py": "ZJS",
    "provinc": "广东",
    "city": "湛江市",
    "area": "湛江市"
}, {
    "name": "广东湛江市赤坎区",
    "match": "广东湛江市赤坎区|GDZJSCKQ",
    "py": "CKQ",
    "provinc": "广东",
    "city": "湛江市",
    "area": "赤坎区"
}, {
    "name": "广东湛江市霞山区",
    "match": "广东湛江市霞山区|GDZJSXSQ",
    "py": "XSQ",
    "provinc": "广东",
    "city": "湛江市",
    "area": "霞山区"
}, {
    "name": "广东湛江市坡头区",
    "match": "广东湛江市坡头区|GDZJSPTQ",
    "py": "PTQ",
    "provinc": "广东",
    "city": "湛江市",
    "area": "坡头区"
}, {
    "name": "广东湛江市麻章区",
    "match": "广东湛江市麻章区|GDZJSMZQ",
    "py": "MZQ",
    "provinc": "广东",
    "city": "湛江市",
    "area": "麻章区"
}, {
    "name": "广东湛江市遂溪县",
    "match": "广东湛江市遂溪县|GDZJSSXX",
    "py": "SXX",
    "provinc": "广东",
    "city": "湛江市",
    "area": "遂溪县"
}, {
    "name": "广东湛江市徐闻县",
    "match": "广东湛江市徐闻县|GDZJSXWX",
    "py": "XWX",
    "provinc": "广东",
    "city": "湛江市",
    "area": "徐闻县"
}, {
    "name": "广东湛江市廉江市",
    "match": "广东湛江市廉江市|GDZJSLJS",
    "py": "LJS",
    "provinc": "广东",
    "city": "湛江市",
    "area": "廉江市"
}, {
    "name": "广东湛江市雷州市",
    "match": "广东湛江市雷州市|GDZJSLZS",
    "py": "LZS",
    "provinc": "广东",
    "city": "湛江市",
    "area": "雷州市"
}, {
    "name": "广东湛江市吴川市",
    "match": "广东湛江市吴川市|GDZJSWCS",
    "py": "WCS",
    "provinc": "广东",
    "city": "湛江市",
    "area": "吴川市"
}, {
    "name": "广东中山市",
    "match": "广东中山市|GDZSS",
    "py": "ZSS",
    "provinc": "广东",
    "city": "中山市",
    "area": "中山市"
}, {
    "name": "广东河源市",
    "match": "广东河源市|GDHYS",
    "py": "HYS",
    "provinc": "广东",
    "city": "河源市",
    "area": "河源市"
}, {
    "name": "广东河源市源城区",
    "match": "广东河源市源城区|GDHYSYCQ",
    "py": "YCQ",
    "provinc": "广东",
    "city": "河源市",
    "area": "源城区"
}, {
    "name": "广东河源市紫金县",
    "match": "广东河源市紫金县|GDHYSZJX",
    "py": "ZJX",
    "provinc": "广东",
    "city": "河源市",
    "area": "紫金县"
}, {
    "name": "广东河源市龙川县",
    "match": "广东河源市龙川县|GDHYSLCX",
    "py": "LCX",
    "provinc": "广东",
    "city": "河源市",
    "area": "龙川县"
}, {
    "name": "广东河源市连平县",
    "match": "广东河源市连平县|GDHYSLPX",
    "py": "LPX",
    "provinc": "广东",
    "city": "河源市",
    "area": "连平县"
}, {
    "name": "广东河源市和平县",
    "match": "广东河源市和平县|GDHYSHPX",
    "py": "HPX",
    "provinc": "广东",
    "city": "河源市",
    "area": "和平县"
}, {
    "name": "广东河源市东源县",
    "match": "广东河源市东源县|GDHYSDYX",
    "py": "DYX",
    "provinc": "广东",
    "city": "河源市",
    "area": "东源县"
}, {
    "name": "广东清远市",
    "match": "广东清远市|GDQYS",
    "py": "QYS",
    "provinc": "广东",
    "city": "清远市",
    "area": "清远市"
}, {
    "name": "广东清远市清城区",
    "match": "广东清远市清城区|GDQYSQCQ",
    "py": "QCQ",
    "provinc": "广东",
    "city": "清远市",
    "area": "清城区"
}, {
    "name": "广东清远市佛冈县",
    "match": "广东清远市佛冈县|GDQYSFGX",
    "py": "FGX",
    "provinc": "广东",
    "city": "清远市",
    "area": "佛冈县"
}, {
    "name": "广东清远市阳山县",
    "match": "广东清远市阳山县|GDQYSYSX",
    "py": "YSX",
    "provinc": "广东",
    "city": "清远市",
    "area": "阳山县"
}, {
    "name": "广东清远市连山县",
    "match": "广东清远市连山县|GDQYSLSZZX",
    "py": "LSZZX",
    "provinc": "广东",
    "city": "清远市",
    "area": "连山县"
}, {
    "name": "广东清远市连南县",
    "match": "广东清远市连南县|GDQYSLNZZX",
    "py": "LNZZX",
    "provinc": "广东",
    "city": "清远市",
    "area": "连南县"
}, {
    "name": "广东清远市清新区",
    "match": "广东清远市清新区|GDQYSQXQ",
    "py": "QXQ",
    "provinc": "广东",
    "city": "清远市",
    "area": "清新区"
}, {
    "name": "广东清远市英德市",
    "match": "广东清远市英德市|GDQYSYDS",
    "py": "YDS",
    "provinc": "广东",
    "city": "清远市",
    "area": "英德市"
}, {
    "name": "广东清远市连州市",
    "match": "广东清远市连州市|GDQYSLZS",
    "py": "LZS",
    "provinc": "广东",
    "city": "清远市",
    "area": "连州市"
}, {
    "name": "广东云浮市",
    "match": "广东云浮市|GDYFS",
    "py": "YFS",
    "provinc": "广东",
    "city": "云浮市",
    "area": "云浮市"
}, {
    "name": "广东云浮市云城区",
    "match": "广东云浮市云城区|GDYFSYCQ",
    "py": "YCQ",
    "provinc": "广东",
    "city": "云浮市",
    "area": "云城区"
}, {
    "name": "广东云浮市新兴县",
    "match": "广东云浮市新兴县|GDYFSXXX",
    "py": "XXX",
    "provinc": "广东",
    "city": "云浮市",
    "area": "新兴县"
}, {
    "name": "广东云浮市郁南县",
    "match": "广东云浮市郁南县|GDYFSYNX",
    "py": "YNX",
    "provinc": "广东",
    "city": "云浮市",
    "area": "郁南县"
}, {
    "name": "广东云浮市云安区",
    "match": "广东云浮市云安区|GDYFSYAQ",
    "py": "YAQ",
    "provinc": "广东",
    "city": "云浮市",
    "area": "云安区"
}, {
    "name": "广东云浮市罗定市",
    "match": "广东云浮市罗定市|GDYFSLDS",
    "py": "LDS",
    "provinc": "广东",
    "city": "云浮市",
    "area": "罗定市"
}, {
    "name": "广东潮州市",
    "match": "广东潮州市|GDCZS",
    "py": "CZS",
    "provinc": "广东",
    "city": "潮州市",
    "area": "潮州市"
}, {
    "name": "广东潮州市湘桥区",
    "match": "广东潮州市湘桥区|GDCZSXQQ",
    "py": "XQQ",
    "provinc": "广东",
    "city": "潮州市",
    "area": "湘桥区"
}, {
    "name": "广东潮州市潮安区",
    "match": "广东潮州市潮安区|GDCZSCAQ",
    "py": "CAQ",
    "provinc": "广东",
    "city": "潮州市",
    "area": "潮安区"
}, {
    "name": "广东潮州市饶平县",
    "match": "广东潮州市饶平县|GDCZSRPX",
    "py": "RPX",
    "provinc": "广东",
    "city": "潮州市",
    "area": "饶平县"
}, {
    "name": "广东东莞市",
    "match": "广东东莞市|GDDGS",
    "py": "DGS",
    "provinc": "广东",
    "city": "东莞市",
    "area": "东莞市"
}, {
    "name": "广西防城港市",
    "match": "广西防城港市|GXFCGS",
    "py": "FCGS",
    "provinc": "广西",
    "city": "防城港市",
    "area": "防城港市"
}, {
    "name": "广西防城港市港口区",
    "match": "广西防城港市港口区|GXFCGSGKQ",
    "py": "GKQ",
    "provinc": "广西",
    "city": "防城港市",
    "area": "港口区"
}, {
    "name": "广西防城港市防城区",
    "match": "广西防城港市防城区|GXFCGSFCQ",
    "py": "FCQ",
    "provinc": "广西",
    "city": "防城港市",
    "area": "防城区"
}, {
    "name": "广西防城港市上思县",
    "match": "广西防城港市上思县|GXFCGSSSX",
    "py": "SSX",
    "provinc": "广西",
    "city": "防城港市",
    "area": "上思县"
}, {
    "name": "广西防城港市东兴市",
    "match": "广西防城港市东兴市|GXFCGSDXS",
    "py": "DXS",
    "provinc": "广西",
    "city": "防城港市",
    "area": "东兴市"
}, {
    "name": "广西南宁市",
    "match": "广西南宁市|GXNNS",
    "py": "NNS",
    "provinc": "广西",
    "city": "南宁市",
    "area": "南宁市"
}, {
    "name": "广西南宁市兴宁区",
    "match": "广西南宁市兴宁区|GXNNSXNQ",
    "py": "XNQ",
    "provinc": "广西",
    "city": "南宁市",
    "area": "兴宁区"
}, {
    "name": "广西南宁市青秀区",
    "match": "广西南宁市青秀区|GXNNSQXQ",
    "py": "QXQ",
    "provinc": "广西",
    "city": "南宁市",
    "area": "青秀区"
}, {
    "name": "广西南宁市江南区",
    "match": "广西南宁市江南区|GXNNSJNQ",
    "py": "JNQ",
    "provinc": "广西",
    "city": "南宁市",
    "area": "江南区"
}, {
    "name": "广西南宁市西乡塘区",
    "match": "广西南宁市西乡塘区|GXNNSXXTQ",
    "py": "XXTQ",
    "provinc": "广西",
    "city": "南宁市",
    "area": "西乡塘区"
}, {
    "name": "广西南宁市良庆区",
    "match": "广西南宁市良庆区|GXNNSLQQ",
    "py": "LQQ",
    "provinc": "广西",
    "city": "南宁市",
    "area": "良庆区"
}, {
    "name": "广西南宁市邕宁区",
    "match": "广西南宁市邕宁区|GXNNSYNQ",
    "py": "YNQ",
    "provinc": "广西",
    "city": "南宁市",
    "area": "邕宁区"
}, {
    "name": "广西南宁市武鸣区",
    "match": "广西南宁市武鸣区|GXNNSWMQ",
    "py": "WMQ",
    "provinc": "广西",
    "city": "南宁市",
    "area": "武鸣区"
}, {
    "name": "广西南宁市隆安县",
    "match": "广西南宁市隆安县|GXNNSLAX",
    "py": "LAX",
    "provinc": "广西",
    "city": "南宁市",
    "area": "隆安县"
}, {
    "name": "广西南宁市马山县",
    "match": "广西南宁市马山县|GXNNSMSX",
    "py": "MSX",
    "provinc": "广西",
    "city": "南宁市",
    "area": "马山县"
}, {
    "name": "广西南宁市上林县",
    "match": "广西南宁市上林县|GXNNSSLX",
    "py": "SLX",
    "provinc": "广西",
    "city": "南宁市",
    "area": "上林县"
}, {
    "name": "广西南宁市宾阳县",
    "match": "广西南宁市宾阳县|GXNNSBYX",
    "py": "BYX",
    "provinc": "广西",
    "city": "南宁市",
    "area": "宾阳县"
}, {
    "name": "广西南宁市横县",
    "match": "广西南宁市横县|GXNNSHX",
    "py": "HX",
    "provinc": "广西",
    "city": "南宁市",
    "area": "横县"
}, {
    "name": "广西崇左市",
    "match": "广西崇左市|GXCZS",
    "py": "CZS",
    "provinc": "广西",
    "city": "崇左市",
    "area": "崇左市"
}, {
    "name": "广西崇左市江洲区",
    "match": "广西崇左市江洲区|GXCZSJZQ",
    "py": "JZQ",
    "provinc": "广西",
    "city": "崇左市",
    "area": "江洲区"
}, {
    "name": "广西崇左市扶绥县",
    "match": "广西崇左市扶绥县|GXCZSFSX",
    "py": "FSX",
    "provinc": "广西",
    "city": "崇左市",
    "area": "扶绥县"
}, {
    "name": "广西崇左市宁明县",
    "match": "广西崇左市宁明县|GXCZSNMX",
    "py": "NMX",
    "provinc": "广西",
    "city": "崇左市",
    "area": "宁明县"
}, {
    "name": "广西崇左市龙州县",
    "match": "广西崇左市龙州县|GXCZSLZX",
    "py": "LZX",
    "provinc": "广西",
    "city": "崇左市",
    "area": "龙州县"
}, {
    "name": "广西崇左市大新县",
    "match": "广西崇左市大新县|GXCZSDXX",
    "py": "DXX",
    "provinc": "广西",
    "city": "崇左市",
    "area": "大新县"
}, {
    "name": "广西崇左市天等县",
    "match": "广西崇左市天等县|GXCZSTDX",
    "py": "TDX",
    "provinc": "广西",
    "city": "崇左市",
    "area": "天等县"
}, {
    "name": "广西崇左市凭祥市",
    "match": "广西崇左市凭祥市|GXCZSPXS",
    "py": "PXS",
    "provinc": "广西",
    "city": "崇左市",
    "area": "凭祥市"
}, {
    "name": "广西来宾市",
    "match": "广西来宾市|GXLBS",
    "py": "LBS",
    "provinc": "广西",
    "city": "来宾市",
    "area": "来宾市"
}, {
    "name": "广西来宾市兴宾区",
    "match": "广西来宾市兴宾区|GXLBSXBQ",
    "py": "XBQ",
    "provinc": "广西",
    "city": "来宾市",
    "area": "兴宾区"
}, {
    "name": "广西来宾市忻城县",
    "match": "广西来宾市忻城县|GXLBSXCX",
    "py": "XCX",
    "provinc": "广西",
    "city": "来宾市",
    "area": "忻城县"
}, {
    "name": "广西来宾市象州县",
    "match": "广西来宾市象州县|GXLBSXZX",
    "py": "XZX",
    "provinc": "广西",
    "city": "来宾市",
    "area": "象州县"
}, {
    "name": "广西来宾市武宣县",
    "match": "广西来宾市武宣县|GXLBSWXX",
    "py": "WXX",
    "provinc": "广西",
    "city": "来宾市",
    "area": "武宣县"
}, {
    "name": "广西来宾市金秀县",
    "match": "广西来宾市金秀县|GXLBSJXZZX",
    "py": "JXZZX",
    "provinc": "广西",
    "city": "来宾市",
    "area": "金秀县"
}, {
    "name": "广西来宾市合山市",
    "match": "广西来宾市合山市|GXLBSHSS",
    "py": "HSS",
    "provinc": "广西",
    "city": "来宾市",
    "area": "合山市"
}, {
    "name": "广西柳州市",
    "match": "广西柳州市|GXLZS",
    "py": "LZS",
    "provinc": "广西",
    "city": "柳州市",
    "area": "柳州市"
}, {
    "name": "广西柳州市城中区",
    "match": "广西柳州市城中区|GXLZSCZQ",
    "py": "CZQ",
    "provinc": "广西",
    "city": "柳州市",
    "area": "城中区"
}, {
    "name": "广西柳州市鱼峰区",
    "match": "广西柳州市鱼峰区|GXLZSYFQ",
    "py": "YFQ",
    "provinc": "广西",
    "city": "柳州市",
    "area": "鱼峰区"
}, {
    "name": "广西柳州市柳南区",
    "match": "广西柳州市柳南区|GXLZSLNQ",
    "py": "LNQ",
    "provinc": "广西",
    "city": "柳州市",
    "area": "柳南区"
}, {
    "name": "广西柳州市柳北区",
    "match": "广西柳州市柳北区|GXLZSLBQ",
    "py": "LBQ",
    "provinc": "广西",
    "city": "柳州市",
    "area": "柳北区"
}, {
    "name": "广西柳州市柳江区",
    "match": "广西柳州市柳江区|GXLZSLJQ",
    "py": "LJQ",
    "provinc": "广西",
    "city": "柳州市",
    "area": "柳江区"
}, {
    "name": "广西柳州市柳城县",
    "match": "广西柳州市柳城县|GXLZSLCX",
    "py": "LCX",
    "provinc": "广西",
    "city": "柳州市",
    "area": "柳城县"
}, {
    "name": "广西柳州市鹿寨县",
    "match": "广西柳州市鹿寨县|GXLZSLZX",
    "py": "LZX",
    "provinc": "广西",
    "city": "柳州市",
    "area": "鹿寨县"
}, {
    "name": "广西柳州市融安县",
    "match": "广西柳州市融安县|GXLZSRAX",
    "py": "RAX",
    "provinc": "广西",
    "city": "柳州市",
    "area": "融安县"
}, {
    "name": "广西柳州市融水县",
    "match": "广西柳州市融水县|GXLZSRSZZX",
    "py": "RSZZX",
    "provinc": "广西",
    "city": "柳州市",
    "area": "融水县"
}, {
    "name": "广西柳州市三江县",
    "match": "广西柳州市三江县|GXLZSSJZZX",
    "py": "SJZZX",
    "provinc": "广西",
    "city": "柳州市",
    "area": "三江县"
}, {
    "name": "广西桂林市",
    "match": "广西桂林市|GXGLS",
    "py": "GLS",
    "provinc": "广西",
    "city": "桂林市",
    "area": "桂林市"
}, {
    "name": "广西桂林市秀峰区",
    "match": "广西桂林市秀峰区|GXGLSXFQ",
    "py": "XFQ",
    "provinc": "广西",
    "city": "桂林市",
    "area": "秀峰区"
}, {
    "name": "广西桂林市叠彩区",
    "match": "广西桂林市叠彩区|GXGLSDCQ",
    "py": "DCQ",
    "provinc": "广西",
    "city": "桂林市",
    "area": "叠彩区"
}, {
    "name": "广西桂林市象山区",
    "match": "广西桂林市象山区|GXGLSXSQ",
    "py": "XSQ",
    "provinc": "广西",
    "city": "桂林市",
    "area": "象山区"
}, {
    "name": "广西桂林市七星区",
    "match": "广西桂林市七星区|GXGLSQXQ",
    "py": "QXQ",
    "provinc": "广西",
    "city": "桂林市",
    "area": "七星区"
}, {
    "name": "广西桂林市雁山区",
    "match": "广西桂林市雁山区|GXGLSYSQ",
    "py": "YSQ",
    "provinc": "广西",
    "city": "桂林市",
    "area": "雁山区"
}, {
    "name": "广西桂林市阳朔县",
    "match": "广西桂林市阳朔县|GXGLSYSX",
    "py": "YSX",
    "provinc": "广西",
    "city": "桂林市",
    "area": "阳朔县"
}, {
    "name": "广西桂林市临桂区",
    "match": "广西桂林市临桂区|GXGLSLGQ",
    "py": "LGQ",
    "provinc": "广西",
    "city": "桂林市",
    "area": "临桂区"
}, {
    "name": "广西桂林市灵川县",
    "match": "广西桂林市灵川县|GXGLSLCX",
    "py": "LCX",
    "provinc": "广西",
    "city": "桂林市",
    "area": "灵川县"
}, {
    "name": "广西桂林市全州县",
    "match": "广西桂林市全州县|GXGLSQZX",
    "py": "QZX",
    "provinc": "广西",
    "city": "桂林市",
    "area": "全州县"
}, {
    "name": "广西桂林市兴安县",
    "match": "广西桂林市兴安县|GXGLSXAX",
    "py": "XAX",
    "provinc": "广西",
    "city": "桂林市",
    "area": "兴安县"
}, {
    "name": "广西桂林市永福县",
    "match": "广西桂林市永福县|GXGLSYFX",
    "py": "YFX",
    "provinc": "广西",
    "city": "桂林市",
    "area": "永福县"
}, {
    "name": "广西桂林市灌阳县",
    "match": "广西桂林市灌阳县|GXGLSGYX",
    "py": "GYX",
    "provinc": "广西",
    "city": "桂林市",
    "area": "灌阳县"
}, {
    "name": "广西桂林市龙胜县",
    "match": "广西桂林市龙胜县|GXGLSLSZZX",
    "py": "LSZZX",
    "provinc": "广西",
    "city": "桂林市",
    "area": "龙胜县"
}, {
    "name": "广西桂林市资源县",
    "match": "广西桂林市资源县|GXGLSZYX",
    "py": "ZYX",
    "provinc": "广西",
    "city": "桂林市",
    "area": "资源县"
}, {
    "name": "广西桂林市平乐县",
    "match": "广西桂林市平乐县|GXGLSPLX",
    "py": "PLX",
    "provinc": "广西",
    "city": "桂林市",
    "area": "平乐县"
}, {
    "name": "广西桂林市荔浦县",
    "match": "广西桂林市荔浦县|GXGLSLPX",
    "py": "LPX",
    "provinc": "广西",
    "city": "桂林市",
    "area": "荔浦县"
}, {
    "name": "广西桂林市恭城县",
    "match": "广西桂林市恭城县|GXGLSGCZZX",
    "py": "GCZZX",
    "provinc": "广西",
    "city": "桂林市",
    "area": "恭城县"
}, {
    "name": "广西梧州市",
    "match": "广西梧州市|GXWZS",
    "py": "WZS",
    "provinc": "广西",
    "city": "梧州市",
    "area": "梧州市"
},

    /*ADD*/
    {
        "name": "广西梧州市龙圩区",
        "match": "广西梧州市龙圩区|GXWZSLXQ",
        "py": "LXQ",
        "provinc": "广西",
        "city": "梧州市",
        "area": "龙圩区"
    },
    /*ADD*/



    {
    "name": "广西梧州市万秀区",
    "match": "广西梧州市万秀区|GXWZSWXQ",
    "py": "WXQ",
    "provinc": "广西",
    "city": "梧州市",
    "area": "万秀区"
}, /*{
    "name": "广西梧州市碟山区",
    "match": "广西梧州市碟山区|GXWZSDSQ",
    "py": "DSQ",
    "provinc": "广西",
    "city": "梧州市",
    "area": "碟山区"
}, */{
    "name": "广西梧州市长洲区",
    "match": "广西梧州市长洲区|GXWZSCZQ",
    "py": "CZQ",
    "provinc": "广西",
    "city": "梧州市",
    "area": "长洲区"
}, {
    "name": "广西梧州市苍梧县",
    "match": "广西梧州市苍梧县|GXWZSCWX",
    "py": "CWX",
    "provinc": "广西",
    "city": "梧州市",
    "area": "苍梧县"
}, {
    "name": "广西梧州市藤县",
    "match": "广西梧州市藤县|GXWZSTX",
    "py": "TX",
    "provinc": "广西",
    "city": "梧州市",
    "area": "藤县"
}, {
    "name": "广西梧州市蒙山县",
    "match": "广西梧州市蒙山县|GXWZSMSX",
    "py": "MSX",
    "provinc": "广西",
    "city": "梧州市",
    "area": "蒙山县"
}, {
    "name": "广西梧州市岑溪市",
    "match": "广西梧州市岑溪市|GXWZSCXS",
    "py": "CXS",
    "provinc": "广西",
    "city": "梧州市",
    "area": "岑溪市"
}, {
    "name": "广西贺州市",
    "match": "广西贺州市|GXHZS",
    "py": "HZS",
    "provinc": "广西",
    "city": "贺州市",
    "area": "贺州市"
},

    /*ADD*/
    {
        "name": "广西贺州市平桂区",
        "match": "广西贺州市平桂区|GXHZSPGQ",
        "py": "PGQ",
        "provinc": "广西",
        "city": "贺州市",
        "area": "平桂区"
    },
    /*ADD*/


    {
    "name": "广西贺州市八步区",
    "match": "广西贺州市八步区|GXHZSBBQ",
    "py": "BBQ",
    "provinc": "广西",
    "city": "贺州市",
    "area": "八步区"
}, {
    "name": "广西贺州市昭平县",
    "match": "广西贺州市昭平县|GXHZSZPX",
    "py": "ZPX",
    "provinc": "广西",
    "city": "贺州市",
    "area": "昭平县"
}, {
    "name": "广西贺州市钟山县",
    "match": "广西贺州市钟山县|GXHZSZSX",
    "py": "ZSX",
    "provinc": "广西",
    "city": "贺州市",
    "area": "钟山县"
}, {
    "name": "广西贺州市富川县",
    "match": "广西贺州市富川县|GXHZSFCZZX",
    "py": "FCZZX",
    "provinc": "广西",
    "city": "贺州市",
    "area": "富川县"
}, {
    "name": "广西贵港市",
    "match": "广西贵港市|GXGGS",
    "py": "GGS",
    "provinc": "广西",
    "city": "贵港市",
    "area": "贵港市"
}, {
    "name": "广西贵港市港北区",
    "match": "广西贵港市港北区|GXGGSGBQ",
    "py": "GBQ",
    "provinc": "广西",
    "city": "贵港市",
    "area": "港北区"
}, {
    "name": "广西贵港市港南区",
    "match": "广西贵港市港南区|GXGGSGNQ",
    "py": "GNQ",
    "provinc": "广西",
    "city": "贵港市",
    "area": "港南区"
}, {
    "name": "广西贵港市覃塘区",
    "match": "广西贵港市覃塘区|GXGGSQTQ",
    "py": "QTQ",
    "provinc": "广西",
    "city": "贵港市",
    "area": "覃塘区"
}, {
    "name": "广西贵港市平南县",
    "match": "广西贵港市平南县|GXGGSPNX",
    "py": "PNX",
    "provinc": "广西",
    "city": "贵港市",
    "area": "平南县"
}, {
    "name": "广西贵港市桂平市",
    "match": "广西贵港市桂平市|GXGGSGPS",
    "py": "GPS",
    "provinc": "广西",
    "city": "贵港市",
    "area": "桂平市"
}, {
    "name": "广西玉林市",
    "match": "广西玉林市|GXYLS",
    "py": "YLS",
    "provinc": "广西",
    "city": "玉林市",
    "area": "玉林市"
},

    /*ADD*/
    {
        "name": "广西玉林市福绵区",
        "match": "广西玉林市福绵区|GXYLSFMQ",
        "py": "FMQ",
        "provinc": "广西",
        "city": "玉林市",
        "area": "福绵区"
    },
    /*ADD*/


    {
    "name": "广西玉林市玉州区",
    "match": "广西玉林市玉州区|GXYLSYZQ",
    "py": "YZQ",
    "provinc": "广西",
    "city": "玉林市",
    "area": "玉州区"
}, {
    "name": "广西玉林市容县",
    "match": "广西玉林市容县|GXYLSRX",
    "py": "RX",
    "provinc": "广西",
    "city": "玉林市",
    "area": "容县"
}, {
    "name": "广西玉林市陆川县",
    "match": "广西玉林市陆川县|GXYLSLCX",
    "py": "LCX",
    "provinc": "广西",
    "city": "玉林市",
    "area": "陆川县"
}, {
    "name": "广西玉林市博白县",
    "match": "广西玉林市博白县|GXYLSBBX",
    "py": "BBX",
    "provinc": "广西",
    "city": "玉林市",
    "area": "博白县"
}, {
    "name": "广西玉林市兴业县",
    "match": "广西玉林市兴业县|GXYLSXYX",
    "py": "XYX",
    "provinc": "广西",
    "city": "玉林市",
    "area": "兴业县"
}, {
    "name": "广西玉林市北流市",
    "match": "广西玉林市北流市|GXYLSBLS",
    "py": "BLS",
    "provinc": "广西",
    "city": "玉林市",
    "area": "北流市"
}, {
    "name": "广西百色市",
    "match": "广西百色市|GXBSS",
    "py": "BSS",
    "provinc": "广西",
    "city": "百色市",
    "area": "百色市"
}, {
    "name": "广西百色市右江区",
    "match": "广西百色市右江区|GXBSSYJQ",
    "py": "YJQ",
    "provinc": "广西",
    "city": "百色市",
    "area": "右江区"
}, {
    "name": "广西百色市田阳县",
    "match": "广西百色市田阳县|GXBSSTYX",
    "py": "TYX",
    "provinc": "广西",
    "city": "百色市",
    "area": "田阳县"
}, {
    "name": "广西百色市田东县",
    "match": "广西百色市田东县|GXBSSTDX",
    "py": "TDX",
    "provinc": "广西",
    "city": "百色市",
    "area": "田东县"
}, {
    "name": "广西百色市平果县",
    "match": "广西百色市平果县|GXBSSPGX",
    "py": "PGX",
    "provinc": "广西",
    "city": "百色市",
    "area": "平果县"
}, {
    "name": "广西百色市德保县",
    "match": "广西百色市德保县|GXBSSDBX",
    "py": "DBX",
    "provinc": "广西",
    "city": "百色市",
    "area": "德保县"
}, {
    "name": "广西百色市靖西市",
    "match": "广西百色市靖西市|GXBSSJXS",
    "py": "JXS",
    "provinc": "广西",
    "city": "百色市",
    "area": "靖西市"
}, {
    "name": "广西百色市那坡县",
    "match": "广西百色市那坡县|GXBSSNPX",
    "py": "NPX",
    "provinc": "广西",
    "city": "百色市",
    "area": "那坡县"
}, {
    "name": "广西百色市凌云县",
    "match": "广西百色市凌云县|GXBSSLYX",
    "py": "LYX",
    "provinc": "广西",
    "city": "百色市",
    "area": "凌云县"
}, {
    "name": "广西百色市乐业县",
    "match": "广西百色市乐业县|GXBSSLYX",
    "py": "LYX",
    "provinc": "广西",
    "city": "百色市",
    "area": "乐业县"
}, {
    "name": "广西百色市田林县",
    "match": "广西百色市田林县|GXBSSTLX",
    "py": "TLX",
    "provinc": "广西",
    "city": "百色市",
    "area": "田林县"
}, {
    "name": "广西百色市西林县",
    "match": "广西百色市西林县|GXBSSXLX",
    "py": "XLX",
    "provinc": "广西",
    "city": "百色市",
    "area": "西林县"
}, {
    "name": "广西百色市隆林县",
    "match": "广西百色市隆林县|GXBSSLLZZX",
    "py": "LLZZX",
    "provinc": "广西",
    "city": "百色市",
    "area": "隆林县"
}, {
    "name": "广西钦州市",
    "match": "广西钦州市|GXQZS",
    "py": "QZS",
    "provinc": "广西",
    "city": "钦州市",
    "area": "钦州市"
}, {
    "name": "广西钦州市钦南区",
    "match": "广西钦州市钦南区|GXQZSQNQ",
    "py": "QNQ",
    "provinc": "广西",
    "city": "钦州市",
    "area": "钦南区"
}, {
    "name": "广西钦州市钦北区",
    "match": "广西钦州市钦北区|GXQZSQBQ",
    "py": "QBQ",
    "provinc": "广西",
    "city": "钦州市",
    "area": "钦北区"
}, {
    "name": "广西钦州市灵山县",
    "match": "广西钦州市灵山县|GXQZSLSX",
    "py": "LSX",
    "provinc": "广西",
    "city": "钦州市",
    "area": "灵山县"
}, {
    "name": "广西钦州市浦北县",
    "match": "广西钦州市浦北县|GXQZSPBX",
    "py": "PBX",
    "provinc": "广西",
    "city": "钦州市",
    "area": "浦北县"
}, {
    "name": "广西河池市",
    "match": "广西河池市|GXHCS",
    "py": "HCS",
    "provinc": "广西",
    "city": "河池市",
    "area": "河池市"
}, {
    "name": "广西河池市金城江区",
    "match": "广西河池市金城江区|GXHCSJCJQ",
    "py": "JCJQ",
    "provinc": "广西",
    "city": "河池市",
    "area": "金城江区"
}, {
    "name": "广西河池市南丹县",
    "match": "广西河池市南丹县|GXHCSNDX",
    "py": "NDX",
    "provinc": "广西",
    "city": "河池市",
    "area": "南丹县"
}, {
    "name": "广西河池市天峨县",
    "match": "广西河池市天峨县|GXHCSTEX",
    "py": "TEX",
    "provinc": "广西",
    "city": "河池市",
    "area": "天峨县"
}, {
    "name": "广西河池市凤山县",
    "match": "广西河池市凤山县|GXHCSFSX",
    "py": "FSX",
    "provinc": "广西",
    "city": "河池市",
    "area": "凤山县"
}, {
    "name": "广西河池市东兰县",
    "match": "广西河池市东兰县|GXHCSDLX",
    "py": "DLX",
    "provinc": "广西",
    "city": "河池市",
    "area": "东兰县"
}, {
    "name": "广西河池市罗城县",
    "match": "广西河池市罗城县|GXHCSLCZZX",
    "py": "LCZZX",
    "provinc": "广西",
    "city": "河池市",
    "area": "罗城县"
}, {
    "name": "广西河池市环江县",
    "match": "广西河池市环江县|GXHCSHJZZX",
    "py": "HJZZX",
    "provinc": "广西",
    "city": "河池市",
    "area": "环江县"
}, {
    "name": "广西河池市巴马县",
    "match": "广西河池市巴马县|GXHCSBMZZX",
    "py": "BMZZX",
    "provinc": "广西",
    "city": "河池市",
    "area": "巴马县"
}, {
    "name": "广西河池市都安县",
    "match": "广西河池市都安县|GXHCSDAZZX",
    "py": "DAZZX",
    "provinc": "广西",
    "city": "河池市",
    "area": "都安县"
}, {
    "name": "广西河池市大化县",
    "match": "广西河池市大化县|GXHCSDHZZX",
    "py": "DHZZX",
    "provinc": "广西",
    "city": "河池市",
    "area": "大化县"
}, {
    "name": "广西河池市宜州区",
    "match": "广西河池市宜州区|GXHCSYZQ",
    "py": "YZQ",
    "provinc": "广西",
    "city": "河池市",
    "area": "宜州区"
}, {
    "name": "广西北海市",
    "match": "广西北海市|GXBHS",
    "py": "BHS",
    "provinc": "广西",
    "city": "北海市",
    "area": "北海市"
}, {
    "name": "广西北海市海城区",
    "match": "广西北海市海城区|GXBHSHCQ",
    "py": "HCQ",
    "provinc": "广西",
    "city": "北海市",
    "area": "海城区"
}, {
    "name": "广西北海市银海区",
    "match": "广西北海市银海区|GXBHSYHQ",
    "py": "YHQ",
    "provinc": "广西",
    "city": "北海市",
    "area": "银海区"
}, {
    "name": "广西北海市铁山港区",
    "match": "广西北海市铁山港区|GXBHSTSGQ",
    "py": "TSGQ",
    "provinc": "广西",
    "city": "北海市",
    "area": "铁山港区"
}, {
    "name": "广西北海市合浦县",
    "match": "广西北海市合浦县|GXBHSHPX",
    "py": "HPX",
    "provinc": "广西",
    "city": "北海市",
    "area": "合浦县"
}, {
    "name": "新疆塔城地区",
    "match": "新疆塔城地区|XJTCDQ",
    "py": "TCDQ",
    "provinc": "新疆",
    "city": "塔城地区",
    "area": "塔城地区"
}, {
    "name": "新疆塔城地区塔城市",
    "match": "新疆塔城地区塔城市|XJTCDQTCS",
    "py": "TCS",
    "provinc": "新疆",
    "city": "塔城地区",
    "area": "塔城市"
},

    /*ADD*/
    {
        "name": "新疆铁门关市",
        "match": "新疆铁门关市|XJTMGS",
        "py": "TMGS",
        "provinc": "新疆",
        "city": "铁门关市",
        "area": "铁门关市"
    },
    /*ADD*/


    {
    "name": "新疆塔城市乌苏市",
    "match": "新疆塔城市乌苏市|XJTCDQWSS",
    "py": "WSS",
    "provinc": "新疆",
    "city": "塔城市",
    "area": "乌苏市"
}, {
    "name": "新疆塔城市额敏县",
    "match": "新疆塔城市额敏县|XJTCDQEMX",
    "py": "EMX",
    "provinc": "新疆",
    "city": "塔城市",
    "area": "额敏县"
}, {
    "name": "新疆塔城市沙湾县",
    "match": "新疆塔城市沙湾县|XJTCDQSWX",
    "py": "SWX",
    "provinc": "新疆",
    "city": "塔城市",
    "area": "沙湾县"
}, {
    "name": "新疆塔城市托里县",
    "match": "新疆塔城市托里县|XJTCDQTLX",
    "py": "TLX",
    "provinc": "新疆",
    "city": "塔城市",
    "area": "托里县"
}, {
    "name": "新疆塔城市裕民县",
    "match": "新疆塔城市裕民县|XJTCDQYMX",
    "py": "YMX",
    "provinc": "新疆",
    "city": "塔城市",
    "area": "裕民县"
}, {
    "name": "新疆塔城市和布克赛尔县",
    "match": "新疆塔城市和布克赛尔县|XJTCDQHBKSEZZX",
    "py": "HBKSEZZX",
    "provinc": "新疆",
    "city": "塔城市",
    "area": "和布克赛尔县"
}, {
	"name": "新疆哈密市",
	"match": "新疆哈密市|XJHMS",
	"py": "HMS",
	"provinc": "新疆",
	"city": "哈密市",
	"area": "哈密市"
},
	{
	"name": "新疆哈密市伊州区",
	"match": "新疆哈密市伊州区|XJHMSYZQ",
	"py": "YZQ",
	"provinc": "新疆",
	"city": "哈密市",
	"area": "伊州区"
},  {
	"name": "新疆哈密市巴里坤县",
	"match": "新疆哈密市巴里坤县|XJHMSBLKX",
	"py": "BLKX",
	"provinc": "新疆",
	"city": "哈密市",
	"area": "巴里坤县"
}, {
	"name": "新疆哈密市伊吾县",
	"match": "新疆哈密市伊吾县|XJHMSYWX",
	"py": "YWX",
	"provinc": "新疆",
	"city": "哈密市",
	"area": "伊吾县"
}, {
    "name": "新疆和田地区",
    "match": "新疆和田地区|XJHTDQ",
    "py": "HTDQ",
    "provinc": "新疆",
    "city": "和田地区",
    "area": "和田地区"
},

    /*ADD*/
    {
        "name": "新疆和田地区和田市",
        "match": "新疆和田地区和田市|XJHTDQHTS",
        "py": "HTS",
        "provinc": "新疆",
        "city": "和田地区",
        "area": "和田市"
    },
    /*ADD*/


    {
    "name": "新疆和田地区和田县",
    "match": "新疆和田地区和田县|XJHTDQHTX",
    "py": "HTX",
    "provinc": "新疆",
    "city": "和田地区",
    "area": "和田县"
}, {
    "name": "新疆和田地区墨玉县",
    "match": "新疆和田地区墨玉县|XJHTDQMYX",
    "py": "MYX",
    "provinc": "新疆",
    "city": "和田地区",
    "area": "墨玉县"
}, {
    "name": "新疆和田地区皮山县",
    "match": "新疆和田地区皮山县|XJHTDQPSX",
    "py": "PSX",
    "provinc": "新疆",
    "city": "和田地区",
    "area": "皮山县"
}, {
    "name": "新疆和田地区洛浦县",
    "match": "新疆和田地区洛浦县|XJHTDQLPX",
    "py": "LPX",
    "provinc": "新疆",
    "city": "和田地区",
    "area": "洛浦县"
}, {
    "name": "新疆和田地区策勒县",
    "match": "新疆和田地区策勒县|XJHTDQCLX",
    "py": "CLX",
    "provinc": "新疆",
    "city": "和田地区",
    "area": "策勒县"
}, {
    "name": "新疆和田地区于田县",
    "match": "新疆和田地区于田县|XJHTDQYTX",
    "py": "YTX",
    "provinc": "新疆",
    "city": "和田地区",
    "area": "于田县"
}, {
    "name": "新疆和田地区民丰县",
    "match": "新疆和田地区民丰县|XJHTDQMFX",
    "py": "MFX",
    "provinc": "新疆",
    "city": "和田地区",
    "area": "民丰县"
}, {
    "name": "新疆阿勒泰地区",
    "match": "新疆阿勒泰地区|XJALTDQ",
    "py": "ALTDQ",
    "provinc": "新疆",
    "city": "阿勒泰地区",
    "area": "阿勒泰地区"
}, {
    "name": "新疆阿勒泰地区阿勒泰市",
    "match": "新疆阿勒泰地区阿勒泰市|XJALTDQALTS",
    "py": "ALTS",
    "provinc": "新疆",
    "city": "阿勒泰地区",
    "area": "阿勒泰市"
}, {
    "name": "新疆阿勒泰市布尔津县",
    "match": "新疆阿勒泰市布尔津县|XJALTDQBEJX",
    "py": "BEJX",
    "provinc": "新疆",
    "city": "阿勒泰市",
    "area": "布尔津县"
}, {
    "name": "新疆阿勒泰市富蕴县",
    "match": "新疆阿勒泰市富蕴县|XJALTDQFYX",
    "py": "FYX",
    "provinc": "新疆",
    "city": "阿勒泰市",
    "area": "富蕴县"
}, {
    "name": "新疆阿勒泰市福海县",
    "match": "新疆阿勒泰市福海县|XJALTDQFHX",
    "py": "FHX",
    "provinc": "新疆",
    "city": "阿勒泰市",
    "area": "福海县"
}, {
    "name": "新疆阿勒泰市哈巴河县",
    "match": "新疆阿勒泰市哈巴河县|XJALTDQHBHX",
    "py": "HBHX",
    "provinc": "新疆",
    "city": "阿勒泰市",
    "area": "哈巴河县"
}, {
    "name": "新疆阿勒泰市青河县",
    "match": "新疆阿勒泰市青河县|XJALTDQQHX",
    "py": "QHX",
    "provinc": "新疆",
    "city": "阿勒泰市",
    "area": "青河县"
}, {
    "name": "新疆阿勒泰市吉木乃县",
    "match": "新疆阿勒泰市吉木乃县|XJALTDQJMNX",
    "py": "JMNX",
    "provinc": "新疆",
    "city": "阿勒泰市",
    "area": "吉木乃县"
}, {
    "name": "新疆克孜勒苏州",
    "match": "新疆克孜勒苏州|XJKZLSZZZ",
    "py": "KZLSZZZ",
    "provinc": "新疆",
    "city": "克孜勒苏州",
    "area": "克孜勒苏州"
}, {
    "name": "新疆克孜勒苏州阿图什市",
    "match": "新疆克孜勒苏州阿图什市|XJKZLSZZZATSS",
    "py": "ATSS",
    "provinc": "新疆",
    "city": "克孜勒苏州",
    "area": "阿图什市"
}, {
    "name": "新疆克孜勒苏州阿克陶县",
    "match": "新疆克孜勒苏州阿克陶县|XJKZLSZZZAKTX",
    "py": "AKTX",
    "provinc": "新疆",
    "city": "克孜勒苏州",
    "area": "阿克陶县"
}, {
    "name": "新疆克孜勒苏州阿合奇县",
    "match": "新疆克孜勒苏州阿合奇县|XJKZLSZZZAHQX",
    "py": "AHQX",
    "provinc": "新疆",
    "city": "克孜勒苏州",
    "area": "阿合奇县"
}, {
    "name": "新疆克孜勒苏州乌恰县",
    "match": "新疆克孜勒苏州乌恰县|XJKZLSZZZWQX",
    "py": "WQX",
    "provinc": "新疆",
    "city": "克孜勒苏州",
    "area": "乌恰县"
}, {
    "name": "新疆博尔塔拉州",
    "match": "新疆博尔塔拉州|XJBETLZZZ",
    "py": "BETLZZZ",
    "provinc": "新疆",
    "city": "博尔塔拉州",
    "area": "博尔塔拉州"
},

/*ADD*/
    {
        "name": "新疆博尔塔拉州阿拉山口市",
        "match": "新疆博尔塔拉州阿拉山口市|XJBETLZZZALSKS",
        "py": "ALSKS",
        "provinc": "新疆",
        "city": "博尔塔拉州",
        "area": "阿拉山口市"
    },
/*ADD*/


{
    "name": "新疆博尔塔拉州博乐市",
    "match": "新疆博尔塔拉州博乐市|XJBETLZZZBLS",
    "py": "BLS",
    "provinc": "新疆",
    "city": "博尔塔拉州",
    "area": "博乐市"
}, {
    "name": "新疆博尔塔拉州精河县",
    "match": "新疆博尔塔拉州精河县|XJBETLZZZJHX",
    "py": "JHX",
    "provinc": "新疆",
    "city": "博尔塔拉州",
    "area": "精河县"
}, {
    "name": "新疆博尔塔拉州温泉县",
    "match": "新疆博尔塔拉州温泉县|XJBETLZZZWQX",
    "py": "WQX",
    "provinc": "新疆",
    "city": "博尔塔拉州",
    "area": "温泉县"
}, {
    "name": "新疆克拉玛依市",
    "match": "新疆克拉玛依市|XJKLMYS",
    "py": "KLMYS",
    "provinc": "新疆",
    "city": "克拉玛依市",
    "area": "克拉玛依市"
}, {
    "name": "新疆克拉玛依市独山子区",
    "match": "新疆克拉玛依市独山子区|XJKLMYSDSZQ",
    "py": "DSZQ",
    "provinc": "新疆",
    "city": "克拉玛依市",
    "area": "独山子区"
}, {
    "name": "新疆克拉玛依市克拉玛依区",
    "match": "新疆克拉玛依市克拉玛依区|XJKLMYSKLMYQ",
    "py": "KLMYQ",
    "provinc": "新疆",
    "city": "克拉玛依市",
    "area": "克拉玛依区"
}, {
    "name": "新疆克拉玛依市白碱滩区",
    "match": "新疆克拉玛依市白碱滩区|XJKLMYSBJTQ",
    "py": "BJTQ",
    "provinc": "新疆",
    "city": "克拉玛依市",
    "area": "白碱滩区"
}, {
    "name": "新疆克拉玛依市乌尔禾区",
    "match": "新疆克拉玛依市乌尔禾区|XJKLMYSWEHQ",
    "py": "WEHQ",
    "provinc": "新疆",
    "city": "克拉玛依市",
    "area": "乌尔禾区"
}, {
    "name": "新疆乌鲁木齐市",
    "match": "新疆乌鲁木齐市|XJWLMQS",
    "py": "WLMQS",
    "provinc": "新疆",
    "city": "乌鲁木齐市",
    "area": "乌鲁木齐市"
}, {
    "name": "新疆乌鲁木齐市天山区",
    "match": "新疆乌鲁木齐市天山区|XJWLMQSTSQ",
    "py": "TSQ",
    "provinc": "新疆",
    "city": "乌鲁木齐市",
    "area": "天山区"
}, {
    "name": "新疆乌鲁木齐市沙依巴克区",
    "match": "新疆乌鲁木齐市沙依巴克区|XJWLMQSSYBKQ",
    "py": "SYBKQ",
    "provinc": "新疆",
    "city": "乌鲁木齐市",
    "area": "沙依巴克区"
}, {
    "name": "新疆乌鲁木齐市新市区",
    "match": "新疆乌鲁木齐市新市区|XJWLMQSXSQ",
    "py": "XSQ",
    "provinc": "新疆",
    "city": "乌鲁木齐市",
    "area": "新市区"
}, {
    "name": "新疆乌鲁木齐市水磨沟区",
    "match": "新疆乌鲁木齐市水磨沟区|XJWLMQSSMGQ",
    "py": "SMGQ",
    "provinc": "新疆",
    "city": "乌鲁木齐市",
    "area": "水磨沟区"
}, {
    "name": "新疆乌鲁木齐市头屯河区",
    "match": "新疆乌鲁木齐市头屯河区|XJWLMQSTTHQ",
    "py": "TTHQ",
    "provinc": "新疆",
    "city": "乌鲁木齐市",
    "area": "头屯河区"
}, {
    "name": "新疆乌鲁木齐市达坂城区",
    "match": "新疆乌鲁木齐市达坂城区|XJWLMQSDBCQ",
    "py": "DBCQ",
    "provinc": "新疆",
    "city": "乌鲁木齐市",
    "area": "达坂城区"
}, {
    "name": "新疆乌鲁木齐市米东区",
    "match": "新疆乌鲁木齐市米东区|XJWLMQSMDQ",
    "py": "MDQ",
    "provinc": "新疆",
    "city": "乌鲁木齐市",
    "area": "米东区"
}, {
    "name": "新疆乌鲁木齐市乌鲁木齐县",
    "match": "新疆乌鲁木齐市乌鲁木齐县|XJWLMQSWLMQX",
    "py": "WLMQX",
    "provinc": "新疆",
    "city": "乌鲁木齐市",
    "area": "乌鲁木齐县"
}, {
    "name": "新疆石河子市",
    "match": "新疆石河子市|XJSHZS",
    "py": "SHZS",
    "provinc": "新疆",
    "city": "石河子市",
    "area": "石河子市"
}, {
    "name": "新疆昌吉州",
    "match": "新疆昌吉州|XJCJZZZ",
    "py": "CJZZZ",
    "provinc": "新疆",
    "city": "昌吉州",
    "area": "昌吉州"
}, {
    "name": "新疆昌吉州昌吉市",
    "match": "新疆昌吉州昌吉市|XJCJZZZCJS",
    "py": "CJS",
    "provinc": "新疆",
    "city": "昌吉州",
    "area": "昌吉市"
}, {
    "name": "新疆昌吉州阜康市",
    "match": "新疆昌吉州阜康市|XJCJZZZFKS",
    "py": "FKS",
    "provinc": "新疆",
    "city": "昌吉州",
    "area": "阜康市"
}, {
    "name": "新疆昌吉州呼图壁县",
    "match": "新疆昌吉州呼图壁县|XJCJZZZHTBX",
    "py": "HTBX",
    "provinc": "新疆",
    "city": "昌吉州",
    "area": "呼图壁县"
}, {
    "name": "新疆昌吉州玛纳斯县",
    "match": "新疆昌吉州玛纳斯县|XJCJZZZMNSX",
    "py": "MNSX",
    "provinc": "新疆",
    "city": "昌吉州",
    "area": "玛纳斯县"
}, {
    "name": "新疆昌吉州奇台县",
    "match": "新疆昌吉州奇台县|XJCJZZZQTX",
    "py": "QTX",
    "provinc": "新疆",
    "city": "昌吉州",
    "area": "奇台县"
}, {
    "name": "新疆昌吉州吉木萨尔县",
    "match": "新疆昌吉州吉木萨尔县|XJCJZZZJMSEX",
    "py": "JMSEX",
    "provinc": "新疆",
    "city": "昌吉州",
    "area": "吉木萨尔县"
}, {
    "name": "新疆昌吉州木垒县",
    "match": "新疆昌吉州木垒县|XJCJZZZMLZZX",
    "py": "MLZZX",
    "provinc": "新疆",
    "city": "昌吉州",
    "area": "木垒县"
}, {
    "name": "新疆五家渠市",
    "match": "新疆五家渠市|XJWJQS",
    "py": "WJQS",
    "provinc": "新疆",
    "city": "五家渠市",
    "area": "五家渠市"
}, {
    "name": "新疆吐鲁番市高昌区",
    "match": "新疆吐鲁番市高昌区|XJTLFDQGCQ",
    "py": "GCQ",
    "provinc": "新疆",
    "city": "吐鲁番市",
    "area": "高昌区"
},

    /*add 20180330*/
    {
        "name": "新疆吐鲁番市鄯善县",
        "match": "新疆吐鲁番市鄯善县|XJTLFDQSSX",
        "py": "SSX",
        "provinc": "新疆",
        "city": "吐鲁番市",
        "area": "鄯善县"
    },
    {
        "name": "新疆吐鲁番市托克逊县",
        "match": "新疆吐鲁番市托克逊县|XJTLFDQTKXX",
        "py": "TKXX",
        "provinc": "新疆",
        "city": "吐鲁番市",
        "area": "托克逊县"
    },
    /**/

    /*
         注掉：20180330
    {
    "name": "新疆高昌区",
    "match": "新疆高昌区|XJGCQ",
    "py": "GCQ",
    "provinc": "新疆",
    "city": "高昌区",
    "area": "高昌区"
}, {
    "name": "新疆高昌区鄯善县",
    "match": "新疆高昌区鄯善县|XJGCQDQSSX",
    "py": "SSX",
    "provinc": "新疆",
    "city": "高昌区",
    "area": "鄯善县"
}, {
    "name": "新疆高昌区托克逊县",
    "match": "新疆高昌区托克逊县|XJGCQDQTKXX",
    "py": "TKXX",
    "provinc": "新疆",
    "city": "高昌区",
    "area": "托克逊县"
},*/ {
    "name": "新疆巴音郭楞州",
    "match": "新疆巴音郭楞州|XJBYGLZZZ",
    "py": "BYGLZZZ",
    "provinc": "新疆",
    "city": "巴音郭楞州",
    "area": "巴音郭楞州"
}, {
    "name": "新疆巴音郭楞州库尔勒市",
    "match": "新疆巴音郭楞州库尔勒市|XJBYGLZZZKELS",
    "py": "KELS",
    "provinc": "新疆",
    "city": "巴音郭楞州",
    "area": "库尔勒市"
}, {
    "name": "新疆巴音郭楞州轮台县",
    "match": "新疆巴音郭楞州轮台县|XJBYGLZZZLTX",
    "py": "LTX",
    "provinc": "新疆",
    "city": "巴音郭楞州",
    "area": "轮台县"
}, {
    "name": "新疆巴音郭楞州尉犁县",
    "match": "新疆巴音郭楞州尉犁县|XJBYGLZZZWLX",
    "py": "WLX",
    "provinc": "新疆",
    "city": "巴音郭楞州",
    "area": "尉犁县"
}, {
    "name": "新疆巴音郭楞州若羌县",
    "match": "新疆巴音郭楞州若羌县|XJBYGLZZZRQX",
    "py": "RQX",
    "provinc": "新疆",
    "city": "巴音郭楞州",
    "area": "若羌县"
}, {
    "name": "新疆巴音郭楞州且末县",
    "match": "新疆巴音郭楞州且末县|XJBYGLZZZQMX",
    "py": "QMX",
    "provinc": "新疆",
    "city": "巴音郭楞州",
    "area": "且末县"
}, {
    "name": "新疆巴音郭楞州焉耆县",
    "match": "新疆巴音郭楞州焉耆县|XJBYGLZZZYQZZX",
    "py": "YQZZX",
    "provinc": "新疆",
    "city": "巴音郭楞州",
    "area": "焉耆县"
}, {
    "name": "新疆巴音郭楞州和静县",
    "match": "新疆巴音郭楞州和静县|XJBYGLZZZHJX",
    "py": "HJX",
    "provinc": "新疆",
    "city": "巴音郭楞州",
    "area": "和静县"
}, {
    "name": "新疆巴音郭楞州和硕县",
    "match": "新疆巴音郭楞州和硕县|XJBYGLZZZHSX",
    "py": "HSX",
    "provinc": "新疆",
    "city": "巴音郭楞州",
    "area": "和硕县"
}, {
    "name": "新疆巴音郭楞州博湖县",
    "match": "新疆巴音郭楞州博湖县|XJBYGLZZZBHX",
    "py": "BHX",
    "provinc": "新疆",
    "city": "巴音郭楞州",
    "area": "博湖县"
}, {
    "name": "新疆阿克苏地区",
    "match": "新疆阿克苏地区|XJAKSDQ",
    "py": "AKSDQ",
    "provinc": "新疆",
    "city": "阿克苏地区",
    "area": "阿克苏地区"
}, {
    "name": "新疆阿克苏地区阿克苏市",
    "match": "新疆阿克苏地区阿克苏市|XJAKSDQAKSS",
    "py": "AKSS",
    "provinc": "新疆",
    "city": "阿克苏地区",
    "area": "阿克苏市"
}, {
    "name": "新疆阿克苏市温宿县",
    "match": "新疆阿克苏市温宿县|XJAKSDQWSX",
    "py": "WSX",
    "provinc": "新疆",
    "city": "阿克苏市",
    "area": "温宿县"
}, {
    "name": "新疆阿克苏市库车县",
    "match": "新疆阿克苏市库车县|XJAKSDQKCX",
    "py": "KCX",
    "provinc": "新疆",
    "city": "阿克苏市",
    "area": "库车县"
}, {
    "name": "新疆阿克苏市沙雅县",
    "match": "新疆阿克苏市沙雅县|XJAKSDQSYX",
    "py": "SYX",
    "provinc": "新疆",
    "city": "阿克苏市",
    "area": "沙雅县"
}, {
    "name": "新疆阿克苏市新和县",
    "match": "新疆阿克苏市新和县|XJAKSDQXHX",
    "py": "XHX",
    "provinc": "新疆",
    "city": "阿克苏市",
    "area": "新和县"
}, {
    "name": "新疆阿克苏市拜城县",
    "match": "新疆阿克苏市拜城县|XJAKSDQBCX",
    "py": "BCX",
    "provinc": "新疆",
    "city": "阿克苏市",
    "area": "拜城县"
}, {
    "name": "新疆阿克苏市乌什县",
    "match": "新疆阿克苏市乌什县|XJAKSDQWSX",
    "py": "WSX",
    "provinc": "新疆",
    "city": "阿克苏市",
    "area": "乌什县"
}, {
    "name": "新疆阿克苏市阿瓦提县",
    "match": "新疆阿克苏市阿瓦提县|XJAKSDQAWTX",
    "py": "AWTX",
    "provinc": "新疆",
    "city": "阿克苏市",
    "area": "阿瓦提县"
}, {
    "name": "新疆阿克苏市柯坪县",
    "match": "新疆阿克苏市柯坪县|XJAKSDQKPX",
    "py": "KPX",
    "provinc": "新疆",
    "city": "阿克苏市",
    "area": "柯坪县"
}, {
    "name": "新疆阿拉尔市",
    "match": "新疆阿拉尔市|XJALES",
    "py": "ALES",
    "provinc": "新疆",
    "city": "阿拉尔市",
    "area": "阿拉尔市"
}, {
    "name": "新疆喀什地区",
    "match": "新疆喀什地区|XJKSDQ",
    "py": "KSDQ",
    "provinc": "新疆",
    "city": "喀什地区",
    "area": "喀什地区"
}, {
    "name": "新疆喀什地区喀什市",
    "match": "新疆喀什地区喀什市|XJKSDQKSS",
    "py": "KSS",
    "provinc": "新疆",
    "city": "喀什地区",
    "area": "喀什市"
}, {
    "name": "新疆喀什市疏附县",
    "match": "新疆喀什市疏附县|XJKSDQSFX",
    "py": "SFX",
    "provinc": "新疆",
    "city": "喀什市",
    "area": "疏附县"
}, {
    "name": "新疆喀什市疏勒县",
    "match": "新疆喀什市疏勒县|XJKSDQSLX",
    "py": "SLX",
    "provinc": "新疆",
    "city": "喀什市",
    "area": "疏勒县"
}, {
    "name": "新疆喀什市英吉沙县",
    "match": "新疆喀什市英吉沙县|XJKSDQYJSX",
    "py": "YJSX",
    "provinc": "新疆",
    "city": "喀什市",
    "area": "英吉沙县"
}, {
    "name": "新疆喀什市泽普县",
    "match": "新疆喀什市泽普县|XJKSDQZPX",
    "py": "ZPX",
    "provinc": "新疆",
    "city": "喀什市",
    "area": "泽普县"
}, {
    "name": "新疆喀什市莎车县",
    "match": "新疆喀什市莎车县|XJKSDQSCX",
    "py": "SCX",
    "provinc": "新疆",
    "city": "喀什市",
    "area": "莎车县"
}, {
    "name": "新疆喀什市叶城县",
    "match": "新疆喀什市叶城县|XJKSDQYCX",
    "py": "YCX",
    "provinc": "新疆",
    "city": "喀什市",
    "area": "叶城县"
}, {
    "name": "新疆喀什市麦盖提县",
    "match": "新疆喀什市麦盖提县|XJKSDQMGTX",
    "py": "MGTX",
    "provinc": "新疆",
    "city": "喀什市",
    "area": "麦盖提县"
}, {
    "name": "新疆喀什市岳普湖县",
    "match": "新疆喀什市岳普湖县|XJKSDQYPHX",
    "py": "YPHX",
    "provinc": "新疆",
    "city": "喀什市",
    "area": "岳普湖县"
}, {
    "name": "新疆喀什市伽师县",
    "match": "新疆喀什市伽师县|XJKSDQJSX",
    "py": "JSX",
    "provinc": "新疆",
    "city": "喀什市",
    "area": "伽师县"
}, {
    "name": "新疆喀什市巴楚县",
    "match": "新疆喀什市巴楚县|XJKSDQBCX",
    "py": "BCX",
    "provinc": "新疆",
    "city": "喀什市",
    "area": "巴楚县"
}, {
    "name": "新疆喀什市塔什库尔干县",
    "match": "新疆喀什市塔什库尔干县|XJKSDQTSKEGZZX",
    "py": "TSKEGZZX",
    "provinc": "新疆",
    "city": "喀什市",
    "area": "塔什库尔干县"
}, {
    "name": "新疆图木舒克市",
    "match": "新疆图木舒克市|XJTMSKS",
    "py": "TMSKS",
    "provinc": "新疆",
    "city": "图木舒克市",
    "area": "图木舒克市"
}, {
    "name": "新疆伊犁州",
    "match": "新疆伊犁州|XJYLZZZ",
    "py": "YLZZZ",
    "provinc": "新疆",
    "city": "伊犁州",
    "area": "伊犁州"
}, {
    "name": "新疆伊犁州伊宁市",
    "match": "新疆伊犁州伊宁市|XJYLZZZYNS",
    "py": "YNS",
    "provinc": "新疆",
    "city": "伊犁州",
    "area": "伊宁市"
}, {
    "name": "新疆伊犁州奎屯市",
    "match": "新疆伊犁州奎屯市|XJYLZZZKTS",
    "py": "KTS",
    "provinc": "新疆",
    "city": "伊犁州",
    "area": "奎屯市"
}, {
    "name": "新疆伊犁州伊宁县",
    "match": "新疆伊犁州伊宁县|XJYLZZZYNX",
    "py": "YNX",
    "provinc": "新疆",
    "city": "伊犁州",
    "area": "伊宁县"
}, {
    "name": "新疆伊犁州察布查尔县",
    "match": "新疆伊犁州察布查尔县|XJYLZZZCBCEZZX",
    "py": "CBCEZZX",
    "provinc": "新疆",
    "city": "伊犁州",
    "area": "察布查尔县"
}, {
    "name": "新疆伊犁州霍城县",
    "match": "新疆伊犁州霍城县|XJYLZZZHCX",
    "py": "HCX",
    "provinc": "新疆",
    "city": "伊犁州",
    "area": "霍城县"
}, {
    "name": "新疆伊犁州巩留县",
    "match": "新疆伊犁州巩留县|XJYLZZZGLX",
    "py": "GLX",
    "provinc": "新疆",
    "city": "伊犁州",
    "area": "巩留县"
}, {
    "name": "新疆伊犁州新源县",
    "match": "新疆伊犁州新源县|XJYLZZZXYX",
    "py": "XYX",
    "provinc": "新疆",
    "city": "伊犁州",
    "area": "新源县"
}, {
    "name": "新疆伊犁州昭苏县",
    "match": "新疆伊犁州昭苏县|XJYLZZZZSX",
    "py": "ZSX",
    "provinc": "新疆",
    "city": "伊犁州",
    "area": "昭苏县"
}, {
    "name": "新疆伊犁州特克斯县",
    "match": "新疆伊犁州特克斯县|XJYLZZZTKSX",
    "py": "TKSX",
    "provinc": "新疆",
    "city": "伊犁州",
    "area": "特克斯县"
}, {
    "name": "新疆伊犁州尼勒克县",
    "match": "新疆伊犁州尼勒克县|XJYLZZZNLKX",
    "py": "NLKX",
    "provinc": "新疆",
    "city": "伊犁州",
    "area": "尼勒克县"
}, {
    "name": "新疆伊犁州霍尔果斯市",
    "match": "新疆伊犁州霍尔果斯市|XJYLZZZHEGS",
    "py": "HEGS",
    "provinc": "新疆",
    "city": "伊犁州",
    "area": "霍尔果斯市"
}, {
    "name": "江苏南京市",
    "match": "江苏南京市|JSNJS",
    "py": "NJS",
    "provinc": "江苏",
    "city": "南京市",
    "area": "南京市"
}, {
    "name": "江苏南京市江宁区",
    "match": "江苏南京市江宁区|JSNJSJNQ",
    "py": "JNQ",
    "provinc": "江苏",
    "city": "南京市",
    "area": "江宁区"
}, {
    "name": "江苏南京市浦口区",
    "match": "江苏南京市浦口区|JSNJSPKQ",
    "py": "PKQ",
    "provinc": "江苏",
    "city": "南京市",
    "area": "浦口区"
}, {
    "name": "江苏南京市玄武区",
    "match": "江苏南京市玄武区|JSNJSXWQ",
    "py": "XWQ",
    "provinc": "江苏",
    "city": "南京市",
    "area": "玄武区"
}, {
    "name": "江苏南京市白下区",
    "match": "江苏南京市白下区|JSNJSBXQ",
    "py": "BXQ",
    "provinc": "江苏",
    "city": "南京市",
    "area": "白下区"
}, {
    "name": "江苏南京市秦淮区",
    "match": "江苏南京市秦淮区|JSNJSQHQ",
    "py": "QHQ",
    "provinc": "江苏",
    "city": "南京市",
    "area": "秦淮区"
}, {
    "name": "江苏南京市建邺区",
    "match": "江苏南京市建邺区|JSNJSJYQ",
    "py": "JYQ",
    "provinc": "江苏",
    "city": "南京市",
    "area": "建邺区"
}, {
    "name": "江苏南京市鼓楼区",
    "match": "江苏南京市鼓楼区|JSNJSGLQ",
    "py": "GLQ",
    "provinc": "江苏",
    "city": "南京市",
    "area": "鼓楼区"
}, {
    "name": "江苏南京市下关区",
    "match": "江苏南京市下关区|JSNJSXGQ",
    "py": "XGQ",
    "provinc": "江苏",
    "city": "南京市",
    "area": "下关区"
}, {
    "name": "江苏南京市栖霞区",
    "match": "江苏南京市栖霞区|JSNJSQXQ",
    "py": "QXQ",
    "provinc": "江苏",
    "city": "南京市",
    "area": "栖霞区"
}, {
    "name": "江苏南京市雨花台区",
    "match": "江苏南京市雨花台区|JSNJSYHTQ",
    "py": "YHTQ",
    "provinc": "江苏",
    "city": "南京市",
    "area": "雨花台区"
}, {
    "name": "江苏南京市六合区",
    "match": "江苏南京市六合区|JSNJSLHQ",
    "py": "LHQ",
    "provinc": "江苏",
    "city": "南京市",
    "area": "六合区"
}, {
    "name": "江苏南京市溧水区",
    "match": "江苏南京市溧水区|JSNJSLSQ",
    "py": "LSQ",
    "provinc": "江苏",
    "city": "南京市",
    "area": "溧水区"
}, {
    "name": "江苏南京市高淳区",
    "match": "江苏南京市高淳区|JSNJSGCQ",
    "py": "GCQ",
    "provinc": "江苏",
    "city": "南京市",
    "area": "高淳区"
}, {
    "name": "江苏无锡市",
    "match": "江苏无锡市|JSWXS",
    "py": "WXS",
    "provinc": "江苏",
    "city": "无锡市",
    "area": "无锡市"
},

    /*ADD*/
    {
        "name": "江苏无锡市新吴区",
        "match": "江苏无锡市新吴区|JSWXSXWQ",
        "py": "XWQ",
        "provinc": "江苏",
        "city": "无锡市",
        "area": "新吴区"
    },
    {
        "name": "江苏无锡市梁溪区",
        "match": "江苏无锡市梁溪区|JSWXSLXQ",
        "py": "LXQ",
        "provinc": "江苏",
        "city": "无锡市",
        "area": "梁溪区"
    },
    /*ADD*/



    /* {
    "name": "江苏无锡市崇安区",
    "match": "江苏无锡市崇安区|JSWXSCAQ",
    "py": "CAQ",
    "provinc": "江苏",
    "city": "无锡市",
    "area": "崇安区"
},{
    "name": "江苏无锡市南长区",
    "match": "江苏无锡市南长区|JSWXSNCQ",
    "py": "NCQ",
    "provinc": "江苏",
    "city": "无锡市",
    "area": "南长区"
}, {
    "name": "江苏无锡市北塘区",
    "match": "江苏无锡市北塘区|JSWXSBTQ",
    "py": "BTQ",
    "provinc": "江苏",
    "city": "无锡市",
    "area": "北塘区"
},*/  {
    "name": "江苏无锡市锡山区",
    "match": "江苏无锡市锡山区|JSWXSXSQ",
    "py": "XSQ",
    "provinc": "江苏",
    "city": "无锡市",
    "area": "锡山区"
}, {
    "name": "江苏无锡市惠山区",
    "match": "江苏无锡市惠山区|JSWXSHSQ",
    "py": "HSQ",
    "provinc": "江苏",
    "city": "无锡市",
    "area": "惠山区"
}, {
    "name": "江苏无锡市滨湖区",
    "match": "江苏无锡市滨湖区|JSWXSBHQ",
    "py": "BHQ",
    "provinc": "江苏",
    "city": "无锡市",
    "area": "滨湖区"
}, {
    "name": "江苏无锡市江阴市",
    "match": "江苏无锡市江阴市|JSWXSJYS",
    "py": "JYS",
    "provinc": "江苏",
    "city": "无锡市",
    "area": "江阴市"
}, {
    "name": "江苏无锡市宜兴市",
    "match": "江苏无锡市宜兴市|JSWXSYXS",
    "py": "YXS",
    "provinc": "江苏",
    "city": "无锡市",
    "area": "宜兴市"
}, {
    "name": "江苏镇江市",
    "match": "江苏镇江市|JSZJS",
    "py": "ZJS",
    "provinc": "江苏",
    "city": "镇江市",
    "area": "镇江市"
},

    /*ADD*/
    {
        "name": "江苏镇江市镇江新区",
        "match": "江苏镇江市镇江新区|JSZJSZJXQ",
        "py": "ZJXQ",
        "provinc": "江苏",
        "city": "镇江市",
        "area": "镇江新区"
    },
    {
        "name": "江苏镇江市丹徒新区",
        "match": "江苏镇江市丹徒新区|JSZJSDTXQ",
        "py": "DTXQ",
        "provinc": "江苏",
        "city": "镇江市",
        "area": "丹徒新区"
    },
    /*ADD*/

    {
    "name": "江苏镇江市京口区",
    "match": "江苏镇江市京口区|JSZJSJKQ",
    "py": "JKQ",
    "provinc": "江苏",
    "city": "镇江市",
    "area": "京口区"
}, {
    "name": "江苏镇江市润州区",
    "match": "江苏镇江市润州区|JSZJSRZQ",
    "py": "RZQ",
    "provinc": "江苏",
    "city": "镇江市",
    "area": "润州区"
}, {
    "name": "江苏镇江市丹徒区",
    "match": "江苏镇江市丹徒区|JSZJSDTQ",
    "py": "DTQ",
    "provinc": "江苏",
    "city": "镇江市",
    "area": "丹徒区"
}, {
    "name": "江苏镇江市丹阳市",
    "match": "江苏镇江市丹阳市|JSZJSDYS",
    "py": "DYS",
    "provinc": "江苏",
    "city": "镇江市",
    "area": "丹阳市"
}, {
    "name": "江苏镇江市扬中市",
    "match": "江苏镇江市扬中市|JSZJSYZS",
    "py": "YZS",
    "provinc": "江苏",
    "city": "镇江市",
    "area": "扬中市"
}, {
    "name": "江苏镇江市句容市",
    "match": "江苏镇江市句容市|JSZJSJRS",
    "py": "JRS",
    "provinc": "江苏",
    "city": "镇江市",
    "area": "句容市"
}, {
    "name": "江苏苏州市",
    "match": "江苏苏州市|JSSZS",
    "py": "SZS",
    "provinc": "江苏",
    "city": "苏州市",
    "area": "苏州市"
},

    /*ADD*/
    {
        "name": "江苏苏州市工业园区",
        "match": "江苏苏州市工业园区|JSSZSGYYQ",
        "py": "GYYQ",
        "provinc": "江苏",
        "city": "苏州市",
        "area": "工业园区"
    },
    {
        "name": "江苏苏州市高新区",
        "match": "江苏苏州市高新区|JSSZSGXQ",
        "py": "GXQ",
        "provinc": "江苏",
        "city": "苏州市",
        "area": "高新区"
    },
    {
        "name": "江苏苏州市姑苏区",
        "match": "江苏苏州市姑苏区|JSSZSGSQ",
        "py": "GSQ",
        "provinc": "江苏",
        "city": "苏州市",
        "area": "姑苏区"
    },
    /*ADD*/


    /* {
    "name": "江苏苏州市沧浪区",
    "match": "江苏苏州市沧浪区|JSSZSCLQ",
    "py": "CLQ",
    "provinc": "江苏",
    "city": "苏州市",
    "area": "沧浪区"
}, */{
    "name": "江苏苏州市常熟市",
    "match": "江苏苏州市常熟市|JSSZSCSS",
    "py": "CSS",
    "provinc": "江苏",
    "city": "苏州市",
    "area": "常熟市"
}, /*{
    "name": "江苏苏州市平江区",
    "match": "江苏苏州市平江区|JSSZSPJQ",
    "py": "PJQ",
    "provinc": "江苏",
    "city": "苏州市",
    "area": "平江区"
}, {
    "name": "江苏苏州市金阊区",
    "match": "江苏苏州市金阊区|JSSZSJCQ",
    "py": "JCQ",
    "provinc": "江苏",
    "city": "苏州市",
    "area": "金阊区"
}, */{
    "name": "江苏苏州市虎丘区",
    "match": "江苏苏州市虎丘区|JSSZSHQQ",
    "py": "HQQ",
    "provinc": "江苏",
    "city": "苏州市",
    "area": "虎丘区"
}, {
    "name": "江苏苏州市昆山市",
    "match": "江苏苏州市昆山市|JSSZSKSS",
    "py": "KSS",
    "provinc": "江苏",
    "city": "苏州市",
    "area": "昆山市"
}, {
    "name": "江苏苏州市太仓市",
    "match": "江苏苏州市太仓市|JSSZSTCS",
    "py": "TCS",
    "provinc": "江苏",
    "city": "苏州市",
    "area": "太仓市"
}, {
    "name": "江苏苏州市吴江区",
    "match": "江苏苏州市吴江区|JSSZSWJQ",
    "py": "WJQ",
    "provinc": "江苏",
    "city": "苏州市",
    "area": "吴江区"
}, {
    "name": "江苏苏州市吴中区",
    "match": "江苏苏州市吴中区|JSSZSWZQ",
    "py": "WZQ",
    "provinc": "江苏",
    "city": "苏州市",
    "area": "吴中区"
}, {
    "name": "江苏苏州市相城区",
    "match": "江苏苏州市相城区|JSSZSXCQ",
    "py": "XCQ",
    "provinc": "江苏",
    "city": "苏州市",
    "area": "相城区"
}, {
    "name": "江苏苏州市张家港市",
    "match": "江苏苏州市张家港市|JSSZSZJGS",
    "py": "ZJGS",
    "provinc": "江苏",
    "city": "苏州市",
    "area": "张家港市"
}, {
    "name": "江苏南通市",
    "match": "江苏南通市|JSNTS",
    "py": "NTS",
    "provinc": "江苏",
    "city": "南通市",
    "area": "南通市"
},

    /*ADD*/
    {
        "name": "江苏南通市经济技术开发区",
        "match": "江苏南通市经济技术开发区|JSNTSJJJSKFQ",
        "py": "JJJSKFQ",
        "provinc": "江苏",
        "city": "南通市",
        "area": "经济技术开发区"
    },
    /*ADD*/

    {
    "name": "江苏南通市崇川区",
    "match": "江苏南通市崇川区|JSNTSCCQ",
    "py": "CCQ",
    "provinc": "江苏",
    "city": "南通市",
    "area": "崇川区"
}, {
    "name": "江苏南通市港闸区",
    "match": "江苏南通市港闸区|JSNTSGZQ",
    "py": "GZQ",
    "provinc": "江苏",
    "city": "南通市",
    "area": "港闸区"
}, {
    "name": "江苏南通市海安县",
    "match": "江苏南通市海安县|JSNTSHAX",
    "py": "HAX",
    "provinc": "江苏",
    "city": "南通市",
    "area": "海安县"
}, {
    "name": "江苏南通市如东县",
    "match": "江苏南通市如东县|JSNTSRDX",
    "py": "RDX",
    "provinc": "江苏",
    "city": "南通市",
    "area": "如东县"
}, {
    "name": "江苏南通市启东市",
    "match": "江苏南通市启东市|JSNTSQDS",
    "py": "QDS",
    "provinc": "江苏",
    "city": "南通市",
    "area": "启东市"
}, {
    "name": "江苏南通市如皋市",
    "match": "江苏南通市如皋市|JSNTSRGS",
    "py": "RGS",
    "provinc": "江苏",
    "city": "南通市",
    "area": "如皋市"
}, {
    "name": "江苏南通市通州区",
    "match": "江苏南通市通州区|JSNTSTZQ",
    "py": "TZQ",
    "provinc": "江苏",
    "city": "南通市",
    "area": "通州区"
}, {
    "name": "江苏南通市海门市",
    "match": "江苏南通市海门市|JSNTSHMS",
    "py": "HMS",
    "provinc": "江苏",
    "city": "南通市",
    "area": "海门市"
}, {
    "name": "江苏扬州市",
    "match": "江苏扬州市|JSYZS",
    "py": "YZS",
    "provinc": "江苏",
    "city": "扬州市",
    "area": "扬州市"
},

    /*ADD*/
    {
        "name": "江苏扬州市开发区",
        "match": "江苏扬州市开发区|JSYZSKFQ",
        "py": "KFQ",
        "provinc": "江苏",
        "city": "扬州市",
        "area": "开发区"
    },
    /*ADD*/

    {
    "name": "江苏扬州市高邮市",
    "match": "江苏扬州市高邮市|JSYZSGYS",
    "py": "GYS",
    "provinc": "江苏",
    "city": "扬州市",
    "area": "高邮市"
}, {
    "name": "江苏扬州市广陵区",
    "match": "江苏扬州市广陵区|JSYZSGLQ",
    "py": "GLQ",
    "provinc": "江苏",
    "city": "扬州市",
    "area": "广陵区"
}, {
    "name": "江苏扬州市邗江区",
    "match": "江苏扬州市邗江区|JSYZS邗JQ",
    "py": "邗JQ",
    "provinc": "江苏",
    "city": "扬州市",
    "area": "邗江区"
}, {
    "name": "江苏扬州市维扬区",
    "match": "江苏扬州市维扬区|JSYZSWYQ",
    "py": "WYQ",
    "provinc": "江苏",
    "city": "扬州市",
    "area": "维扬区"
}, {
    "name": "江苏扬州市宝应县",
    "match": "江苏扬州市宝应县|JSYZSBYX",
    "py": "BYX",
    "provinc": "江苏",
    "city": "扬州市",
    "area": "宝应县"
}, {
    "name": "江苏扬州市江都市",
    "match": "江苏扬州市江都市|JSYZSJDS",
    "py": "JDS",
    "provinc": "江苏",
    "city": "扬州市",
    "area": "江都市"
}, {
    "name": "江苏扬州市仪征市",
    "match": "江苏扬州市仪征市|JSYZSYZS",
    "py": "YZS",
    "provinc": "江苏",
    "city": "扬州市",
    "area": "仪征市"
}, {
    "name": "江苏盐城市",
    "match": "江苏盐城市|JSYCS",
    "py": "YCS",
    "provinc": "江苏",
    "city": "盐城市",
    "area": "盐城市"
}, {
    "name": "江苏盐城市亭湖区",
    "match": "江苏盐城市亭湖区|JSYCSTHQ",
    "py": "THQ",
    "provinc": "江苏",
    "city": "盐城市",
    "area": "亭湖区"
}, {
    "name": "江苏盐城市盐都区",
    "match": "江苏盐城市盐都区|JSYCSYDQ",
    "py": "YDQ",
    "provinc": "江苏",
    "city": "盐城市",
    "area": "盐都区"
}, {
    "name": "江苏盐城市响水县",
    "match": "江苏盐城市响水县|JSYCSXSX",
    "py": "XSX",
    "provinc": "江苏",
    "city": "盐城市",
    "area": "响水县"
}, {
    "name": "江苏盐城市滨海县",
    "match": "江苏盐城市滨海县|JSYCSBHX",
    "py": "BHX",
    "provinc": "江苏",
    "city": "盐城市",
    "area": "滨海县"
}, {
    "name": "江苏盐城市阜宁县",
    "match": "江苏盐城市阜宁县|JSYCSFNX",
    "py": "FNX",
    "provinc": "江苏",
    "city": "盐城市",
    "area": "阜宁县"
}, {
    "name": "江苏盐城市射阳县",
    "match": "江苏盐城市射阳县|JSYCSSYX",
    "py": "SYX",
    "provinc": "江苏",
    "city": "盐城市",
    "area": "射阳县"
}, {
    "name": "江苏盐城市建湖县",
    "match": "江苏盐城市建湖县|JSYCSJHX",
    "py": "JHX",
    "provinc": "江苏",
    "city": "盐城市",
    "area": "建湖县"
}, {
    "name": "江苏盐城市东台市",
    "match": "江苏盐城市东台市|JSYCSDTS",
    "py": "DTS",
    "provinc": "江苏",
    "city": "盐城市",
    "area": "东台市"
}, {
    "name": "江苏盐城市大丰市",
    "match": "江苏盐城市大丰市|JSYCSDFS",
    "py": "DFS",
    "provinc": "江苏",
    "city": "盐城市",
    "area": "大丰市"
}, {
    "name": "江苏徐州市",
    "match": "江苏徐州市|JSXZS",
    "py": "XZS",
    "provinc": "江苏",
    "city": "徐州市",
    "area": "徐州市"
},

    /*ADD*/
    {
        "name": "江苏徐州市金山桥开发区",
        "match": "江苏徐州市金山桥开发区|JSXZSJSQKFQ",
        "py": "JSQKFQ",
        "provinc": "江苏",
        "city": "徐州市",
        "area": "金山桥开发区"
    },
    {
        "name": "江苏徐州市铜山经济技术开发区",
        "match": "江苏徐州市铜山经济技术开发区|JSXZSTSJJJSKFQ",
        "py": "TSJJJSKFQ",
        "provinc": "江苏",
        "city": "徐州市",
        "area": "铜山经济技术开发区"
    },
    {
        "name": "江苏徐州市八段工业园区",
        "match": "江苏徐州市八段工业园区|JSXZSBDGYYQ",
        "py": "BDGYYQ",
        "provinc": "江苏",
        "city": "徐州市",
        "area": "八段工业园区"
    },
    /*ADD*/



    {
    "name": "江苏徐州市鼓楼区",
    "match": "江苏徐州市鼓楼区|JSXZSGLQ",
    "py": "GLQ",
    "provinc": "江苏",
    "city": "徐州市",
    "area": "鼓楼区"
}, {
    "name": "江苏徐州市云龙区",
    "match": "江苏徐州市云龙区|JSXZSYLQ",
    "py": "YLQ",
    "provinc": "江苏",
    "city": "徐州市",
    "area": "云龙区"
}, {
    "name": "江苏徐州市九里区",
    "match": "江苏徐州市九里区|JSXZSJLQ",
    "py": "JLQ",
    "provinc": "江苏",
    "city": "徐州市",
    "area": "九里区"
}, {
    "name": "江苏徐州市贾汪区",
    "match": "江苏徐州市贾汪区|JSXZSJWQ",
    "py": "JWQ",
    "provinc": "江苏",
    "city": "徐州市",
    "area": "贾汪区"
}, {
    "name": "江苏徐州市泉山区",
    "match": "江苏徐州市泉山区|JSXZSQSQ",
    "py": "QSQ",
    "provinc": "江苏",
    "city": "徐州市",
    "area": "泉山区"
}, {
    "name": "江苏徐州市丰县",
    "match": "江苏徐州市丰县|JSXZSFX",
    "py": "FX",
    "provinc": "江苏",
    "city": "徐州市",
    "area": "丰县"
}, {
    "name": "江苏徐州市沛县",
    "match": "江苏徐州市沛县|JSXZSPX",
    "py": "PX",
    "provinc": "江苏",
    "city": "徐州市",
    "area": "沛县"
}, {
    "name": "江苏徐州市铜山区",
    "match": "江苏徐州市铜山区|JSXZSTSQ",
    "py": "TSQ",
    "provinc": "江苏",
    "city": "徐州市",
    "area": "铜山区"
}, {
    "name": "江苏徐州市睢宁县",
    "match": "江苏徐州市睢宁县|JSXZSSNX",
    "py": "SNX",
    "provinc": "江苏",
    "city": "徐州市",
    "area": "睢宁县"
}, {
    "name": "江苏徐州市新沂市",
    "match": "江苏徐州市新沂市|JSXZSXYS",
    "py": "XYS",
    "provinc": "江苏",
    "city": "徐州市",
    "area": "新沂市"
}, {
    "name": "江苏徐州市邳州市",
    "match": "江苏徐州市邳州市|JSXZSPZS",
    "py": "PZS",
    "provinc": "江苏",
    "city": "徐州市",
    "area": "邳州市"
}, {
    "name": "江苏淮安市",
    "match": "江苏淮安市|JSHAS",
    "py": "HAS",
    "provinc": "江苏",
    "city": "淮安市",
    "area": "淮安市"
},

    /*ADD*/
    {
        "name": "江苏淮安市清江浦区",
        "match": "江苏淮安市清江浦区|JSHASQJFQ",
        "py": "QJFQ",
        "provinc": "江苏",
        "city": "淮安市",
        "area": "清江浦区"
    },
    {
        "name": "江苏淮安市经济开发区",
        "match": "江苏淮安市经济开发区|JSHASJJKFQ",
        "py": "JJKFQ",
        "provinc": "江苏",
        "city": "淮安市",
        "area": "经济开发区"
    },
    /*ADD*/


    /*{
    "name": "江苏淮安市清河区",
    "match": "江苏淮安市清河区|JSHASQHQ",
    "py": "QHQ",
    "provinc": "江苏",
    "city": "淮安市",
    "area": "清河区"
}, */{
    "name": "江苏淮安市楚州区",
    "match": "江苏淮安市楚州区|JSHASCZQ",
    "py": "CZQ",
    "provinc": "江苏",
    "city": "淮安市",
    "area": "楚州区"
}, {
    "name": "江苏淮安市淮阴区",
    "match": "江苏淮安市淮阴区|JSHASHYQ",
    "py": "HYQ",
    "provinc": "江苏",
    "city": "淮安市",
    "area": "淮阴区"
}, /*{
    "name": "江苏淮安市清浦区",
    "match": "江苏淮安市清浦区|JSHASQPQ",
    "py": "QPQ",
    "provinc": "江苏",
    "city": "淮安市",
    "area": "清浦区"
}, */{
    "name": "江苏淮安市涟水县",
    "match": "江苏淮安市涟水县|JSHASLSX",
    "py": "LSX",
    "provinc": "江苏",
    "city": "淮安市",
    "area": "涟水县"
}, {
    "name": "江苏淮安市洪泽区",
    "match": "江苏淮安市洪泽区|JSHASHZQ",
    "py": "HZQ",
    "provinc": "江苏",
    "city": "淮安市",
    "area": "洪泽区"
}, {
    "name": "江苏淮安市盱眙县",
    "match": "江苏淮安市盱眙县|JSHASXYX",
    "py": "XYX",
    "provinc": "江苏",
    "city": "淮安市",
    "area": "盱眙县"
}, {
    "name": "江苏淮安市金湖县",
    "match": "江苏淮安市金湖县|JSHASJHX",
    "py": "JHX",
    "provinc": "江苏",
    "city": "淮安市",
    "area": "金湖县"
}, {
    "name": "江苏连云港市",
    "match": "江苏连云港市|JSLYGS",
    "py": "LYGS",
    "provinc": "江苏",
    "city": "连云港市",
    "area": "连云港市"
}, {
    "name": "江苏连云港市连云区",
    "match": "江苏连云港市连云区|JSLYGSLYQ",
    "py": "LYQ",
    "provinc": "江苏",
    "city": "连云港市",
    "area": "连云区"
}, {
    "name": "江苏连云港市新浦区",
    "match": "江苏连云港市新浦区|JSLYGSXPQ",
    "py": "XPQ",
    "provinc": "江苏",
    "city": "连云港市",
    "area": "新浦区"
}, {
    "name": "江苏连云港市海州区",
    "match": "江苏连云港市海州区|JSLYGSHZQ",
    "py": "HZQ",
    "provinc": "江苏",
    "city": "连云港市",
    "area": "海州区"
}, {
    "name": "江苏连云港市赣榆区",
    "match": "江苏连云港市赣榆区|JSLYGSGYQ",
    "py": "GYQ",
    "provinc": "江苏",
    "city": "连云港市",
    "area": "赣榆区"
}, {
    "name": "江苏连云港市东海县",
    "match": "江苏连云港市东海县|JSLYGSDHX",
    "py": "DHX",
    "provinc": "江苏",
    "city": "连云港市",
    "area": "东海县"
}, {
    "name": "江苏连云港市灌云县",
    "match": "江苏连云港市灌云县|JSLYGSGYX",
    "py": "GYX",
    "provinc": "江苏",
    "city": "连云港市",
    "area": "灌云县"
}, {
    "name": "江苏连云港市灌南县",
    "match": "江苏连云港市灌南县|JSLYGSGNX",
    "py": "GNX",
    "provinc": "江苏",
    "city": "连云港市",
    "area": "灌南县"
}, {
    "name": "江苏常州市",
    "match": "江苏常州市|JSCZS",
    "py": "CZS",
    "provinc": "江苏",
    "city": "常州市",
    "area": "常州市"
}, {
    "name": "江苏常州市天宁区",
    "match": "江苏常州市天宁区|JSCZSTNQ",
    "py": "TNQ",
    "provinc": "江苏",
    "city": "常州市",
    "area": "天宁区"
}, {
    "name": "江苏常州市钟楼区",
    "match": "江苏常州市钟楼区|JSCZSZLQ",
    "py": "ZLQ",
    "provinc": "江苏",
    "city": "常州市",
    "area": "钟楼区"
}, {
    "name": "江苏常州市戚墅堰区",
    "match": "江苏常州市戚墅堰区|JSCZSQSYQ",
    "py": "QSYQ",
    "provinc": "江苏",
    "city": "常州市",
    "area": "戚墅堰区"
}, {
    "name": "江苏常州市新北区",
    "match": "江苏常州市新北区|JSCZSXBQ",
    "py": "XBQ",
    "provinc": "江苏",
    "city": "常州市",
    "area": "新北区"
}, {
    "name": "江苏常州市武进区",
    "match": "江苏常州市武进区|JSCZSWJQ",
    "py": "WJQ",
    "provinc": "江苏",
    "city": "常州市",
    "area": "武进区"
}, {
    "name": "江苏常州市溧阳市",
    "match": "江苏常州市溧阳市|JSCZSLYS",
    "py": "LYS",
    "provinc": "江苏",
    "city": "常州市",
    "area": "溧阳市"
}, {
    "name": "江苏常州市金坛区",
    "match": "江苏常州市金坛区|JSCZSJTQ",
    "py": "JTQ",
    "provinc": "江苏",
    "city": "常州市",
    "area": "金坛区"
}, {
    "name": "江苏泰州市",
    "match": "江苏泰州市|JSTZS",
    "py": "TZS",
    "provinc": "江苏",
    "city": "泰州市",
    "area": "泰州市"
}, {
    "name": "江苏泰州市海陵区",
    "match": "江苏泰州市海陵区|JSTZSHLQ",
    "py": "HLQ",
    "provinc": "江苏",
    "city": "泰州市",
    "area": "海陵区"
}, {
    "name": "江苏泰州市高港区",
    "match": "江苏泰州市高港区|JSTZSGGQ",
    "py": "GGQ",
    "provinc": "江苏",
    "city": "泰州市",
    "area": "高港区"
}, {
    "name": "江苏泰州市兴化市",
    "match": "江苏泰州市兴化市|JSTZSXHS",
    "py": "XHS",
    "provinc": "江苏",
    "city": "泰州市",
    "area": "兴化市"
}, {
    "name": "江苏泰州市靖江市",
    "match": "江苏泰州市靖江市|JSTZSJJS",
    "py": "JJS",
    "provinc": "江苏",
    "city": "泰州市",
    "area": "靖江市"
}, {
    "name": "江苏泰州市泰兴市",
    "match": "江苏泰州市泰兴市|JSTZSTXS",
    "py": "TXS",
    "provinc": "江苏",
    "city": "泰州市",
    "area": "泰兴市"
}, {
    "name": "江苏泰州市姜堰区",
    "match": "江苏泰州市姜堰区|JSTZSJYQ",
    "py": "JYQ",
    "provinc": "江苏",
    "city": "泰州市",
    "area": "姜堰区"
}, {
    "name": "江苏宿迁市",
    "match": "江苏宿迁市|JSSQS",
    "py": "SQS",
    "provinc": "江苏",
    "city": "宿迁市",
    "area": "宿迁市"
},

    /*ADD*/
    {
        "name": "江苏宿迁市经济开发区",
        "match": "江苏宿迁市经济开发区|JSSQSJJKFQ",
        "py": "JJKFQ",
        "provinc": "江苏",
        "city": "宿迁市",
        "area": "经济开发区"
    },
    {
        "name": "江苏宿迁市洋河新区",
        "match": "江苏宿迁市洋河新区|JSSQSYHXQ",
        "py": "YHXQ",
        "provinc": "江苏",
        "city": "宿迁市",
        "area": "洋河新区"
    },
    {
        "name": "江苏宿迁市湖滨新区",
        "match": "江苏宿迁市湖滨新区|JSSQSHBXQ",
        "py": "HBXQ",
        "provinc": "江苏",
        "city": "宿迁市",
        "area": "湖滨新区"
    },
    {
        "name": "江苏宿迁市苏州宿迁工业园区",
        "match": "江苏宿迁市苏州宿迁工业园区|JSSQSSZSQGYYQ",
        "py": "SZSQGYYQ",
        "provinc": "江苏",
        "city": "宿迁市",
        "area": "苏州宿迁工业园区"
    },
    /*ADD*/

    {
    "name": "江苏宿迁市宿城区",
    "match": "江苏宿迁市宿城区|JSSQSSCQ",
    "py": "SCQ",
    "provinc": "江苏",
    "city": "宿迁市",
    "area": "宿城区"
}, {
    "name": "江苏宿迁市宿豫区",
    "match": "江苏宿迁市宿豫区|JSSQSSYQ",
    "py": "SYQ",
    "provinc": "江苏",
    "city": "宿迁市",
    "area": "宿豫区"
}, {
    "name": "江苏宿迁市沭阳县",
    "match": "江苏宿迁市沭阳县|JSSQSSYX",
    "py": "SYX",
    "provinc": "江苏",
    "city": "宿迁市",
    "area": "沭阳县"
}, {
    "name": "江苏宿迁市泗阳县",
    "match": "江苏宿迁市泗阳县|JSSQSSYX",
    "py": "SYX",
    "provinc": "江苏",
    "city": "宿迁市",
    "area": "泗阳县"
}, {
    "name": "江苏宿迁市泗洪县",
    "match": "江苏宿迁市泗洪县|JSSQSSHX",
    "py": "SHX",
    "provinc": "江苏",
    "city": "宿迁市",
    "area": "泗洪县"
}, {
    "name": "江西鹰潭市",
    "match": "江西鹰潭市|JXYTS",
    "py": "YTS",
    "provinc": "江西",
    "city": "鹰潭市",
    "area": "鹰潭市"
},

    /*ADD*/
    //经查 江西鹰潭市信江新区 原数据里有此地区。。不用复加。
    /*{
        "name": "江西鹰潭市信江新区",
        "match": "江西鹰潭市信江新区|JXYTSXJXQ",
        "py": "XJXQ",
        "provinc": "江西",
        "city": "鹰潭市",
        "area": "信江新区"
    },*/
    /*ADD*/


    {
    "name": "江西鹰潭市月湖区",
    "match": "江西鹰潭市月湖区|JXYTSYHQ",
    "py": "YHQ",
    "provinc": "江西",
    "city": "鹰潭市",
    "area": "月湖区"
}, {
    "name": "江西鹰潭市余江县",
    "match": "江西鹰潭市余江县|JXYTSYJX",
    "py": "YJX",
    "provinc": "江西",
    "city": "鹰潭市",
    "area": "余江县"
}, {
    "name": "江西鹰潭市贵溪市",
    "match": "江西鹰潭市贵溪市|JXYTSGXS",
    "py": "GXS",
    "provinc": "江西",
    "city": "鹰潭市",
    "area": "贵溪市"
}, {
    "name": "江西新余市",
    "match": "江西新余市|JXXYS",
    "py": "XYS",
    "provinc": "江西",
    "city": "新余市",
    "area": "新余市"
}, {
    "name": "江西新余市渝水区",
    "match": "江西新余市渝水区|JXXYSYSQ",
    "py": "YSQ",
    "provinc": "江西",
    "city": "新余市",
    "area": "渝水区"
}, {
    "name": "江西新余市分宜县",
    "match": "江西新余市分宜县|JXXYSFYX",
    "py": "FYX",
    "provinc": "江西",
    "city": "新余市",
    "area": "分宜县"
}, {
    "name": "江西南昌市",
    "match": "江西南昌市|JXNCS",
    "py": "NCS",
    "provinc": "江西",
    "city": "南昌市",
    "area": "南昌市"
},
    {
    "name": "江西南昌市东湖区",
    "match": "江西南昌市东湖区|JXNCSDHQ",
    "py": "DHQ",
    "provinc": "江西",
    "city": "南昌市",
    "area": "东湖区"
}, {
    "name": "江西南昌市西湖区",
    "match": "江西南昌市西湖区|JXNCSXHQ",
    "py": "XHQ",
    "provinc": "江西",
    "city": "南昌市",
    "area": "西湖区"
}, {
    "name": "江西南昌市青云谱区",
    "match": "江西南昌市青云谱区|JXNCSQYPQ",
    "py": "QYPQ",
    "provinc": "江西",
    "city": "南昌市",
    "area": "青云谱区"
}, {
    "name": "江西南昌市湾里区",
    "match": "江西南昌市湾里区|JXNCSWLQ",
    "py": "WLQ",
    "provinc": "江西",
    "city": "南昌市",
    "area": "湾里区"
}, {
    "name": "江西南昌市青山湖区",
    "match": "江西南昌市青山湖区|JXNCSQSHQ",
    "py": "QSHQ",
    "provinc": "江西",
    "city": "南昌市",
    "area": "青山湖区"
}, {
    "name": "江西南昌市南昌县",
    "match": "江西南昌市南昌县|JXNCSNCX",
    "py": "NCX",
    "provinc": "江西",
    "city": "南昌市",
    "area": "南昌县"
}, {
    "name": "江西南昌市新建区",
    "match": "江西南昌市新建区|JXNCSXJQ",
    "py": "XJQ",
    "provinc": "江西",
    "city": "南昌市",
    "area": "新建区"
}, {
    "name": "江西南昌市安义县",
    "match": "江西南昌市安义县|JXNCSAYX",
    "py": "AYX",
    "provinc": "江西",
    "city": "南昌市",
    "area": "安义县"
}, {
    "name": "江西南昌市进贤县",
    "match": "江西南昌市进贤县|JXNCSJXX",
    "py": "JXX",
    "provinc": "江西",
    "city": "南昌市",
    "area": "进贤县"
}, {
    "name": "江西九江市",
    "match": "江西九江市|JXJJS",
    "py": "JJS",
    "provinc": "江西",
    "city": "九江市",
    "area": "九江市"
},

    /*ADD*/
    //经查江西九江市共青城市原数据里有此地区。。不用复加。
    /*{
        "name": "江西九江市共青城市",
        "match": "江西九江市共青城市|JXJJSGQCS",
        "py": "GQCS",
        "provinc": "江西",
        "city": "九江市",
        "area": "共青城市"
    },*/
    /*ADD*/


    {
    "name": "江西九江市濂溪区",
    "match": "江西九江市濂溪区|JXJJSLXQ",
    "py": "LXQ",
    "provinc": "江西",
    "city": "九江市",
    "area": "濂溪区"
}, {
    "name": "江西九江市浔阳区",
    "match": "江西九江市浔阳区|JXJJSXYQ",
    "py": "XYQ",
    "provinc": "江西",
    "city": "九江市",
    "area": "浔阳区"
}, {
    "name": "江西九江市柴桑区",
    "match": "江西九江市柴桑区|JXJJSCSQ",
    "py": "CSQ",
    "provinc": "江西",
    "city": "九江市",
    "area": "柴桑区"
}, {
    "name": "江西九江市武宁县",
    "match": "江西九江市武宁县|JXJJSWNX",
    "py": "WNX",
    "provinc": "江西",
    "city": "九江市",
    "area": "武宁县"
}, {
    "name": "江西九江市修水县",
    "match": "江西九江市修水县|JXJJSXSX",
    "py": "XSX",
    "provinc": "江西",
    "city": "九江市",
    "area": "修水县"
}, {
    "name": "江西九江市永修县",
    "match": "江西九江市永修县|JXJJSYXX",
    "py": "YXX",
    "provinc": "江西",
    "city": "九江市",
    "area": "永修县"
}, {
    "name": "江西九江市德安县",
    "match": "江西九江市德安县|JXJJSDAX",
    "py": "DAX",
    "provinc": "江西",
    "city": "九江市",
    "area": "德安县"
}, {
    "name": "江西九江市庐山市",
    "match": "江西九江市庐山市|JXJJSLSS",
    "py": "LSS",
    "provinc": "江西",
    "city": "九江市",
    "area": "庐山市"
}, {
    "name": "江西九江市都昌县",
    "match": "江西九江市都昌县|JXJJSDCX",
    "py": "DCX",
    "provinc": "江西",
    "city": "九江市",
    "area": "都昌县"
}, {
    "name": "江西九江市湖口县",
    "match": "江西九江市湖口县|JXJJSHKX",
    "py": "HKX",
    "provinc": "江西",
    "city": "九江市",
    "area": "湖口县"
}, {
    "name": "江西九江市彭泽县",
    "match": "江西九江市彭泽县|JXJJSPZX",
    "py": "PZX",
    "provinc": "江西",
    "city": "九江市",
    "area": "彭泽县"
}, {
    "name": "江西九江市瑞昌市",
    "match": "江西九江市瑞昌市|JXJJSRCS",
    "py": "RCS",
    "provinc": "江西",
    "city": "九江市",
    "area": "瑞昌市"
}, {
    "name": "江西上饶市",
    "match": "江西上饶市|JXSRS",
    "py": "SRS",
    "provinc": "江西",
    "city": "上饶市",
    "area": "上饶市"
}, {
    "name": "江西上饶市信州区",
    "match": "江西上饶市信州区|JXSRSXZQ",
    "py": "XZQ",
    "provinc": "江西",
    "city": "上饶市",
    "area": "信州区"
}, {
    "name": "江西上饶市上饶县",
    "match": "江西上饶市上饶县|JXSRSSRX",
    "py": "SRX",
    "provinc": "江西",
    "city": "上饶市",
    "area": "上饶县"
}, {
    "name": "江西上饶市广丰区",
    "match": "江西上饶市广丰区|JXSRSGFQ",
    "py": "GFQ",
    "provinc": "江西",
    "city": "上饶市",
    "area": "广丰区"
}, {
    "name": "江西上饶市玉山县",
    "match": "江西上饶市玉山县|JXSRSYSX",
    "py": "YSX",
    "provinc": "江西",
    "city": "上饶市",
    "area": "玉山县"
}, {
    "name": "江西上饶市铅山县",
    "match": "江西上饶市铅山县|JXSRSQSX",
    "py": "QSX",
    "provinc": "江西",
    "city": "上饶市",
    "area": "铅山县"
}, {
    "name": "江西上饶市横峰县",
    "match": "江西上饶市横峰县|JXSRSHFX",
    "py": "HFX",
    "provinc": "江西",
    "city": "上饶市",
    "area": "横峰县"
}, {
    "name": "江西上饶市弋阳县",
    "match": "江西上饶市弋阳县|JXSRSYYX",
    "py": "YYX",
    "provinc": "江西",
    "city": "上饶市",
    "area": "弋阳县"
}, {
    "name": "江西上饶市余干县",
    "match": "江西上饶市余干县|JXSRSYGX",
    "py": "YGX",
    "provinc": "江西",
    "city": "上饶市",
    "area": "余干县"
}, {
    "name": "江西上饶市鄱阳县",
    "match": "江西上饶市鄱阳县|JXSRSPYX",
    "py": "PYX",
    "provinc": "江西",
    "city": "上饶市",
    "area": "鄱阳县"
}, {
    "name": "江西上饶市万年县",
    "match": "江西上饶市万年县|JXSRSWNX",
    "py": "WNX",
    "provinc": "江西",
    "city": "上饶市",
    "area": "万年县"
}, {
    "name": "江西上饶市婺源县",
    "match": "江西上饶市婺源县|JXSRSWYX",
    "py": "WYX",
    "provinc": "江西",
    "city": "上饶市",
    "area": "婺源县"
}, {
    "name": "江西上饶市德兴市",
    "match": "江西上饶市德兴市|JXSRSDXS",
    "py": "DXS",
    "provinc": "江西",
    "city": "上饶市",
    "area": "德兴市"
}, {
    "name": "江西抚州市",
    "match": "江西抚州市|JXFZS",
    "py": "FZS",
    "provinc": "江西",
    "city": "抚州市",
    "area": "抚州市"
}, {
    "name": "江西抚州市临川区",
    "match": "江西抚州市临川区|JXFZSLCQ",
    "py": "LCQ",
    "provinc": "江西",
    "city": "抚州市",
    "area": "临川区"
}, {
    "name": "江西抚州市南城县",
    "match": "江西抚州市南城县|JXFZSNCX",
    "py": "NCX",
    "provinc": "江西",
    "city": "抚州市",
    "area": "南城县"
}, {
    "name": "江西抚州市黎川县",
    "match": "江西抚州市黎川县|JXFZSLCX",
    "py": "LCX",
    "provinc": "江西",
    "city": "抚州市",
    "area": "黎川县"
}, {
    "name": "江西抚州市南丰县",
    "match": "江西抚州市南丰县|JXFZSNFX",
    "py": "NFX",
    "provinc": "江西",
    "city": "抚州市",
    "area": "南丰县"
}, {
    "name": "江西抚州市崇仁县",
    "match": "江西抚州市崇仁县|JXFZSCRX",
    "py": "CRX",
    "provinc": "江西",
    "city": "抚州市",
    "area": "崇仁县"
}, {
    "name": "江西抚州市乐安县",
    "match": "江西抚州市乐安县|JXFZSLAX",
    "py": "LAX",
    "provinc": "江西",
    "city": "抚州市",
    "area": "乐安县"
}, {
    "name": "江西抚州市宜黄县",
    "match": "江西抚州市宜黄县|JXFZSYHX",
    "py": "YHX",
    "provinc": "江西",
    "city": "抚州市",
    "area": "宜黄县"
}, {
    "name": "江西抚州市金溪县",
    "match": "江西抚州市金溪县|JXFZSJXX",
    "py": "JXX",
    "provinc": "江西",
    "city": "抚州市",
    "area": "金溪县"
}, {
    "name": "江西抚州市资溪县",
    "match": "江西抚州市资溪县|JXFZSZXX",
    "py": "ZXX",
    "provinc": "江西",
    "city": "抚州市",
    "area": "资溪县"
}, {
    "name": "江西抚州市东乡区",
    "match": "江西抚州市东乡区|JXFZSDXQ",
    "py": "DXQ",
    "provinc": "江西",
    "city": "抚州市",
    "area": "东乡区"
}, {
    "name": "江西抚州市广昌县",
    "match": "江西抚州市广昌县|JXFZSGCX",
    "py": "GCX",
    "provinc": "江西",
    "city": "抚州市",
    "area": "广昌县"
}, {
    "name": "江西宜春市",
    "match": "江西宜春市|JXYCS",
    "py": "YCS",
    "provinc": "江西",
    "city": "宜春市",
    "area": "宜春市"
}, {
    "name": "江西宜春市袁州区",
    "match": "江西宜春市袁州区|JXYCSYZQ",
    "py": "YZQ",
    "provinc": "江西",
    "city": "宜春市",
    "area": "袁州区"
}, {
    "name": "江西宜春市奉新县",
    "match": "江西宜春市奉新县|JXYCSFXX",
    "py": "FXX",
    "provinc": "江西",
    "city": "宜春市",
    "area": "奉新县"
}, {
    "name": "江西宜春市万载县",
    "match": "江西宜春市万载县|JXYCSWZX",
    "py": "WZX",
    "provinc": "江西",
    "city": "宜春市",
    "area": "万载县"
}, {
    "name": "江西宜春市上高县",
    "match": "江西宜春市上高县|JXYCSSGX",
    "py": "SGX",
    "provinc": "江西",
    "city": "宜春市",
    "area": "上高县"
}, {
    "name": "江西宜春市宜丰县",
    "match": "江西宜春市宜丰县|JXYCSYFX",
    "py": "YFX",
    "provinc": "江西",
    "city": "宜春市",
    "area": "宜丰县"
}, {
    "name": "江西宜春市靖安县",
    "match": "江西宜春市靖安县|JXYCSJAX",
    "py": "JAX",
    "provinc": "江西",
    "city": "宜春市",
    "area": "靖安县"
}, {
    "name": "江西宜春市铜鼓县",
    "match": "江西宜春市铜鼓县|JXYCSTGX",
    "py": "TGX",
    "provinc": "江西",
    "city": "宜春市",
    "area": "铜鼓县"
}, {
    "name": "江西宜春市丰城市",
    "match": "江西宜春市丰城市|JXYCSFCS",
    "py": "FCS",
    "provinc": "江西",
    "city": "宜春市",
    "area": "丰城市"
}, {
    "name": "江西宜春市樟树市",
    "match": "江西宜春市樟树市|JXYCSZSS",
    "py": "ZSS",
    "provinc": "江西",
    "city": "宜春市",
    "area": "樟树市"
}, {
    "name": "江西宜春市高安市",
    "match": "江西宜春市高安市|JXYCSGAS",
    "py": "GAS",
    "provinc": "江西",
    "city": "宜春市",
    "area": "高安市"
}, {
    "name": "江西吉安市",
    "match": "江西吉安市|JXJAS",
    "py": "JAS",
    "provinc": "江西",
    "city": "吉安市",
    "area": "吉安市"
}, {
    "name": "江西吉安市吉州区",
    "match": "江西吉安市吉州区|JXJASJZQ",
    "py": "JZQ",
    "provinc": "江西",
    "city": "吉安市",
    "area": "吉州区"
}, {
    "name": "江西吉安市青原区",
    "match": "江西吉安市青原区|JXJASQYQ",
    "py": "QYQ",
    "provinc": "江西",
    "city": "吉安市",
    "area": "青原区"
}, {
    "name": "江西吉安市吉安县",
    "match": "江西吉安市吉安县|JXJASJAX",
    "py": "JAX",
    "provinc": "江西",
    "city": "吉安市",
    "area": "吉安县"
}, {
    "name": "江西吉安市吉水县",
    "match": "江西吉安市吉水县|JXJASJSX",
    "py": "JSX",
    "provinc": "江西",
    "city": "吉安市",
    "area": "吉水县"
}, {
    "name": "江西吉安市峡江县",
    "match": "江西吉安市峡江县|JXJASXJX",
    "py": "XJX",
    "provinc": "江西",
    "city": "吉安市",
    "area": "峡江县"
}, {
    "name": "江西吉安市新干县",
    "match": "江西吉安市新干县|JXJASXGX",
    "py": "XGX",
    "provinc": "江西",
    "city": "吉安市",
    "area": "新干县"
}, {
    "name": "江西吉安市永丰县",
    "match": "江西吉安市永丰县|JXJASYFX",
    "py": "YFX",
    "provinc": "江西",
    "city": "吉安市",
    "area": "永丰县"
}, {
    "name": "江西吉安市泰和县",
    "match": "江西吉安市泰和县|JXJASTHX",
    "py": "THX",
    "provinc": "江西",
    "city": "吉安市",
    "area": "泰和县"
}, {
    "name": "江西吉安市遂川县",
    "match": "江西吉安市遂川县|JXJASSCX",
    "py": "SCX",
    "provinc": "江西",
    "city": "吉安市",
    "area": "遂川县"
}, {
    "name": "江西吉安市万安县",
    "match": "江西吉安市万安县|JXJASWAX",
    "py": "WAX",
    "provinc": "江西",
    "city": "吉安市",
    "area": "万安县"
}, {
    "name": "江西吉安市安福县",
    "match": "江西吉安市安福县|JXJASAFX",
    "py": "AFX",
    "provinc": "江西",
    "city": "吉安市",
    "area": "安福县"
}, {
    "name": "江西吉安市永新县",
    "match": "江西吉安市永新县|JXJASYXX",
    "py": "YXX",
    "provinc": "江西",
    "city": "吉安市",
    "area": "永新县"
}, {
    "name": "江西吉安市井冈山区",
    "match": "江西吉安市井冈山区|JXJASJGSQ",
    "py": "JGSQ",
    "provinc": "江西",
    "city": "吉安市",
    "area": "井冈山区"
}, {
    "name": "江西赣州市",
    "match": "江西赣州市|JXGZS",
    "py": "GZS",
    "provinc": "江西",
    "city": "赣州市",
    "area": "赣州市"
}, {
    "name": "江西赣州市章贡区",
    "match": "江西赣州市章贡区|JXGZSZGQ",
    "py": "ZGQ",
    "provinc": "江西",
    "city": "赣州市",
    "area": "章贡区"
}, {
    "name": "江西赣州市赣县区",
    "match": "江西赣州市赣县区|JXGZSGXQ",
    "py": "GXQ",
    "provinc": "江西",
    "city": "赣州市",
    "area": "赣县区"
}, {
    "name": "江西赣州市信丰县",
    "match": "江西赣州市信丰县|JXGZSXFX",
    "py": "XFX",
    "provinc": "江西",
    "city": "赣州市",
    "area": "信丰县"
}, {
    "name": "江西赣州市大余县",
    "match": "江西赣州市大余县|JXGZSDYX",
    "py": "DYX",
    "provinc": "江西",
    "city": "赣州市",
    "area": "大余县"
}, {
    "name": "江西赣州市上犹县",
    "match": "江西赣州市上犹县|JXGZSSYX",
    "py": "SYX",
    "provinc": "江西",
    "city": "赣州市",
    "area": "上犹县"
}, {
    "name": "江西赣州市崇义县",
    "match": "江西赣州市崇义县|JXGZSCYX",
    "py": "CYX",
    "provinc": "江西",
    "city": "赣州市",
    "area": "崇义县"
}, {
    "name": "江西赣州市安远县",
    "match": "江西赣州市安远县|JXGZSAYX",
    "py": "AYX",
    "provinc": "江西",
    "city": "赣州市",
    "area": "安远县"
}, {
    "name": "江西赣州市龙南县",
    "match": "江西赣州市龙南县|JXGZSLNX",
    "py": "LNX",
    "provinc": "江西",
    "city": "赣州市",
    "area": "龙南县"
}, {
    "name": "江西赣州市定南县",
    "match": "江西赣州市定南县|JXGZSDNX",
    "py": "DNX",
    "provinc": "江西",
    "city": "赣州市",
    "area": "定南县"
}, {
    "name": "江西赣州市全南县",
    "match": "江西赣州市全南县|JXGZSQNX",
    "py": "QNX",
    "provinc": "江西",
    "city": "赣州市",
    "area": "全南县"
}, {
    "name": "江西赣州市宁都县",
    "match": "江西赣州市宁都县|JXGZSNDX",
    "py": "NDX",
    "provinc": "江西",
    "city": "赣州市",
    "area": "宁都县"
}, {
    "name": "江西赣州市于都县",
    "match": "江西赣州市于都县|JXGZSYDX",
    "py": "YDX",
    "provinc": "江西",
    "city": "赣州市",
    "area": "于都县"
}, {
    "name": "江西赣州市兴国县",
    "match": "江西赣州市兴国县|JXGZSXGX",
    "py": "XGX",
    "provinc": "江西",
    "city": "赣州市",
    "area": "兴国县"
}, {
    "name": "江西赣州市会昌县",
    "match": "江西赣州市会昌县|JXGZSHCX",
    "py": "HCX",
    "provinc": "江西",
    "city": "赣州市",
    "area": "会昌县"
}, {
    "name": "江西赣州市寻乌县",
    "match": "江西赣州市寻乌县|JXGZSXWX",
    "py": "XWX",
    "provinc": "江西",
    "city": "赣州市",
    "area": "寻乌县"
}, {
    "name": "江西赣州市石城县",
    "match": "江西赣州市石城县|JXGZSSCX",
    "py": "SCX",
    "provinc": "江西",
    "city": "赣州市",
    "area": "石城县"
}, {
    "name": "江西赣州市瑞金市",
    "match": "江西赣州市瑞金市|JXGZSRJS",
    "py": "RJS",
    "provinc": "江西",
    "city": "赣州市",
    "area": "瑞金市"
}, {
    "name": "江西赣州市南康区",
    "match": "江西赣州市南康区|JXGZSNKQ",
    "py": "NKQ",
    "provinc": "江西",
    "city": "赣州市",
    "area": "南康区"
},



    /*yy start*/
    {
        "name": "江西景德镇市",
        "match": "江西景德镇市|JXJDZS",
        "py": "JDZS",
        "provinc": "江西",
        "city": "景德镇市",
        "area": "景德镇市"
    }, {
        "name": "江西南昌市赣江新区",
        "match": "江西南昌市赣江新区|JXNCSGJXQ",
        "py": "GJXQ",
        "provinc": "江西",
        "city": "南昌市",
        "area": "赣江新区"
    }, {
        "name": "江西南昌市红谷滩新区",
        "match": "江西南昌市红谷滩新区|JXNCSHGTXQ",
        "py": "HGTXQ",
        "provinc": "江西",
        "city": "南昌市",
        "area": "红谷滩新区"
    }, {
        "name": "江西南昌市高新区",
        "match": "江西南昌市高新区|JXNCSGXQ",
        "py": "GXQ",
        "provinc": "江西",
        "city": "南昌市",
        "area": "高新区"
    }, {
        "name": "江西九江市共青城市",
        "match": "江西九江市共青城市|JXJJSGQCQ",
        "py": "GQCQ",
        "provinc": "江西",
        "city": "九江市",
        "area": "共青城市"
    }, {
        "name": "江西鹰潭市信江新区",
        "match": "江西鹰潭市信江新区|JXYTSXJXQ",
        "py": "XJXQ",
        "provinc": "江西",
        "city": "鹰潭市",
        "area": "信江新区"
    }, {
        "name": "江西景德镇市昌江区",
        "match": "江西景德镇市昌江区|JXJDZSCJQ",
        "py": "CJQ",
        "provinc": "江西",
        "city": "景德镇市",
        "area": "昌江区"
    }, {
        "name": "江西景德镇市珠山区",
        "match": "江西景德镇市珠山区|JXJDZSZSQ",
        "py": "ZSQ",
        "provinc": "江西",
        "city": "景德镇市",
        "area": "珠山区"
    }, {
        "name": "江西景德镇市浮梁县",
        "match": "江西景德镇市浮梁县|JXJDZSFLX",
        "py": "FLX",
        "provinc": "江西",
        "city": "景德镇市",
        "area": "浮梁县"
    }, {
        "name": "江西景德镇市乐平市",
        "match": "江西景德镇市乐平市|JXJDZSLPS",
        "py": "LPS",
        "provinc": "江西",
        "city": "景德镇市",
        "area": "乐平市"
    }, {
        "name": "江西萍乡市",
        "match": "江西萍乡市|JXPXS",
        "py": "PXS",
        "provinc": "江西",
        "city": "萍乡市",
        "area": "萍乡市"
    }, {
        "name": "江西萍乡市安源区",
        "match": "江西萍乡市安源区|JXPXSAYQ",
        "py": "AYQ",
        "provinc": "江西",
        "city": "萍乡市",
        "area": "安源区"
    }, {
        "name": "江西萍乡市湘东区",
        "match": "江西萍乡市湘东区|JXPXSXDQ",
        "py": "XDQ",
        "provinc": "江西",
        "city": "萍乡市",
        "area": "湘东区"
    }, {
        "name": "江西萍乡市莲花县",
        "match": "江西萍乡市莲花县|JXPXSLHX",
        "py": "LHX",
        "provinc": "江西",
        "city": "萍乡市",
        "area": "莲花县"
    }, {
        "name": "江西萍乡市上栗县",
        "match": "江西萍乡市上栗县|JXPXSSLX",
        "py": "SLX",
        "provinc": "江西",
        "city": "萍乡市",
        "area": "上栗县"
    }, {
        "name": "江西萍乡市芦溪县",
        "match": "江西萍乡市芦溪县|JXPXSLXX",
        "py": "LXX",
        "provinc": "江西",
        "city": "萍乡市",
        "area": "芦溪县"
    }, {
        "name": "河北邯郸市",
        "match": "河北邯郸市|HBHDS",
        "py": "HDS",
        "provinc": "河北",
        "city": "邯郸市",
        "area": "邯郸市"
    }, {
        "name": "河北邯郸市邯山区",
        "match": "河北邯郸市邯山区|HBHDSHSQ",
        "py": "HSQ",
        "provinc": "河北",
        "city": "邯郸市",
        "area": "邯山区"
    }, {
        "name": "河北邯郸市丛台区",
        "match": "河北邯郸市丛台区|HBHDSCTQ",
        "py": "CTQ",
        "provinc": "河北",
        "city": "邯郸市",
        "area": "丛台区"
    }, {
        "name": "河北邯郸市复兴区",
        "match": "河北邯郸市复兴区|HBHDSFXQ",
        "py": "FXQ",
        "provinc": "河北",
        "city": "邯郸市",
        "area": "复兴区"
    }, {
        "name": "河北邯郸市峰峰矿区",
        "match": "河北邯郸市峰峰矿区|HBHDSFFKQ",
        "py": "FFKQ",
        "provinc": "河北",
        "city": "邯郸市",
        "area": "峰峰矿区"
    }, {
        "name": "河北邯郸市临漳县",
        "match": "河北邯郸市临漳县|HBHDSLZX",
        "py": "LZX",
        "provinc": "河北",
        "city": "邯郸市",
        "area": "临漳县"
    }, {
        "name": "河北邯郸市成安县",
        "match": "河北邯郸市成安县|HBHDSCAX",
        "py": "CAX",
        "provinc": "河北",
        "city": "邯郸市",
        "area": "成安县"
    }, {
        "name": "河北邯郸市大名县",
        "match": "河北邯郸市大名县|HBHDSDMX",
        "py": "DMX",
        "provinc": "河北",
        "city": "邯郸市",
        "area": "大名县"
    }, {
        "name": "河北邯郸市涉县",
        "match": "河北邯郸市涉县|HBHDSSX",
        "py": "SX",
        "provinc": "河北",
        "city": "邯郸市",
        "area": "涉县"
    }, {
        "name": "河北邯郸市磁县",
        "match": "河北邯郸市磁县|HBHDSCX",
        "py": "CX",
        "provinc": "河北",
        "city": "邯郸市",
        "area": "磁县"
    }, {
        "name": "河北邯郸市肥乡区",
        "match": "河北邯郸市肥乡区|HBHDSFXQ",
        "py": "FXQ",
        "provinc": "河北",
        "city": "邯郸市",
        "area": "肥乡区"
    }, {
        "name": "河北邯郸市永年区",
        "match": "河北邯郸市永年区|HBHDSYNQ",
        "py": "YNQ",
        "provinc": "河北",
        "city": "邯郸市",
        "area": "永年区"
    }, {
        "name": "河北邯郸市邱县",
        "match": "河北邯郸市邱县|HBHDSQX",
        "py": "QX",
        "provinc": "河北",
        "city": "邯郸市",
        "area": "邱县"
    }, {
        "name": "河北邯郸市鸡泽县",
        "match": "河北邯郸市鸡泽县|HBHDSJZX",
        "py": "JZX",
        "provinc": "河北",
        "city": "邯郸市",
        "area": "鸡泽县"
    }, {
        "name": "河北邯郸市广平县",
        "match": "河北邯郸市广平县|HBHDSGPX",
        "py": "GPX",
        "provinc": "河北",
        "city": "邯郸市",
        "area": "广平县"
    }, {
        "name": "河北邯郸市馆陶县",
        "match": "河北邯郸市馆陶县|HBHDSGTX",
        "py": "GTX",
        "provinc": "河北",
        "city": "邯郸市",
        "area": "馆陶县"
    }, {
        "name": "河北邯郸市魏县",
        "match": "河北邯郸市魏县|HBHDSWX",
        "py": "WX",
        "provinc": "河北",
        "city": "邯郸市",
        "area": "魏县"
    }, {
        "name": "河北邯郸市曲周县",
        "match": "河北邯郸市曲周县|HBHDSQZX",
        "py": "QZX",
        "provinc": "河北",
        "city": "邯郸市",
        "area": "曲周县"
    }, {
        "name": "河北邯郸市武安市",
        "match": "河北邯郸市武安市|HBHDSWAS",
        "py": "WAS",
        "provinc": "河北",
        "city": "邯郸市",
        "area": "武安市"
    }, {
        "name": "河北石家庄市",
        "match": "河北石家庄市|HBSJZS",
        "py": "SJZS",
        "provinc": "河北",
        "city": "石家庄市",
        "area": "石家庄市"
    }, {
        "name": "河北石家庄市长安区",
        "match": "河北石家庄市长安区|HBSJZSCAQ",
        "py": "CAQ",
        "provinc": "河北",
        "city": "石家庄市",
        "area": "长安区"
    }, {
        "name": "河北石家庄市桥西区",
        "match": "河北石家庄市桥西区|HBSJZSQXQ",
        "py": "QXQ",
        "provinc": "河北",
        "city": "石家庄市",
        "area": "桥西区"
    }, {
        "name": "河北石家庄市新华区",
        "match": "河北石家庄市新华区|HBSJZSXHQ",
        "py": "XHQ",
        "provinc": "河北",
        "city": "石家庄市",
        "area": "新华区"
    }, {
        "name": "河北石家庄市井陉矿区",
        "match": "河北石家庄市井陉矿区|HBSJZSJXKQ",
        "py": "JXKQ",
        "provinc": "河北",
        "city": "石家庄市",
        "area": "井陉矿区"
    }, {
        "name": "河北石家庄市裕华区",
        "match": "河北石家庄市裕华区|HBSJZSYHQ",
        "py": "YHQ",
        "provinc": "河北",
        "city": "石家庄市",
        "area": "裕华区"
    }, {
        "name": "河北石家庄市井陉县",
        "match": "河北石家庄市井陉县|HBSJZSJXX",
        "py": "JXX",
        "provinc": "河北",
        "city": "石家庄市",
        "area": "井陉县"
    }, {
        "name": "河北石家庄市正定县",
        "match": "河北石家庄市正定县|HBSJZSZDX",
        "py": "ZDX",
        "provinc": "河北",
        "city": "石家庄市",
        "area": "正定县"
    }, {
        "name": "河北石家庄市栾城区",
        "match": "河北石家庄市栾城区|HBSJZSLCQ",
        "py": "LCQ",
        "provinc": "河北",
        "city": "石家庄市",
        "area": "栾城区"
    }, {
        "name": "河北石家庄市行唐县",
        "match": "河北石家庄市行唐县|HBSJZSXTX",
        "py": "XTX",
        "provinc": "河北",
        "city": "石家庄市",
        "area": "行唐县"
    }, {
        "name": "河北石家庄市灵寿县",
        "match": "河北石家庄市灵寿县|HBSJZSLSX",
        "py": "LSX",
        "provinc": "河北",
        "city": "石家庄市",
        "area": "灵寿县"
    }, {
        "name": "河北石家庄市高邑县",
        "match": "河北石家庄市高邑县|HBSJZSGYX",
        "py": "GYX",
        "provinc": "河北",
        "city": "石家庄市",
        "area": "高邑县"
    }, {
        "name": "河北石家庄市深泽县",
        "match": "河北石家庄市深泽县|HBSJZSSZX",
        "py": "SZX",
        "provinc": "河北",
        "city": "石家庄市",
        "area": "深泽县"
    }, {
        "name": "河北石家庄市赞皇县",
        "match": "河北石家庄市赞皇县|HBSJZSZHX",
        "py": "ZHX",
        "provinc": "河北",
        "city": "石家庄市",
        "area": "赞皇县"
    }, {
        "name": "河北石家庄市无极县",
        "match": "河北石家庄市无极县|HBSJZSWJX",
        "py": "WJX",
        "provinc": "河北",
        "city": "石家庄市",
        "area": "无极县"
    }, {
        "name": "河北石家庄市平山县",
        "match": "河北石家庄市平山县|HBSJZSPSX",
        "py": "PSX",
        "provinc": "河北",
        "city": "石家庄市",
        "area": "平山县"
    }, {
        "name": "河北石家庄市元氏县",
        "match": "河北石家庄市元氏县|HBSJZSYSX",
        "py": "YSX",
        "provinc": "河北",
        "city": "石家庄市",
        "area": "元氏县"
    }, {
        "name": "河北石家庄市赵县",
        "match": "河北石家庄市赵县|HBSJZSZX",
        "py": "ZX",
        "provinc": "河北",
        "city": "石家庄市",
        "area": "赵县"
    }, {
        "name": "河北石家庄市辛集市",
        "match": "河北石家庄市辛集市|HBSJZSXJS",
        "py": "XJS",
        "provinc": "河北",
        "city": "石家庄市",
        "area": "辛集市"
    }, {
        "name": "河北石家庄市藁城区",
        "match": "河北石家庄市藁城区|HBSJZSGCQ",
        "py": "GCQ",
        "provinc": "河北",
        "city": "石家庄市",
        "area": "藁城区"
    }, {
        "name": "河北石家庄市晋州市",
        "match": "河北石家庄市晋州市|HBSJZSJZS",
        "py": "JZS",
        "provinc": "河北",
        "city": "石家庄市",
        "area": "晋州市"
    }, {
        "name": "河北石家庄市新乐市",
        "match": "河北石家庄市新乐市|HBSJZSXLS",
        "py": "XLS",
        "provinc": "河北",
        "city": "石家庄市",
        "area": "新乐市"
    }, {
        "name": "河北石家庄市鹿泉区",
        "match": "河北石家庄市鹿泉区|HBSJZSLQQ",
        "py": "LQQ",
        "provinc": "河北",
        "city": "石家庄市",
        "area": "鹿泉区"
    }, {
        "name": "河北保定市",
        "match": "河北保定市|HBBDS",
        "py": "BDS",
        "provinc": "河北",
        "city": "保定市",
        "area": "保定市"
    }, {
        "name": "河北保定市竞秀区",
        "match": "河北保定市竞秀区|HBBDSJXQ",
        "py": "JXQ",
        "provinc": "河北",
        "city": "保定市",
        "area": "竞秀区"
    }, {
        "name": "河北保定市满城区",
        "match": "河北保定市满城区|HBBDSMCQ",
        "py": "MCQ",
        "provinc": "河北",
        "city": "保定市",
        "area": "满城区"
    }, {
        "name": "河北保定市清苑区",
        "match": "河北保定市清苑区|HBBDSQYQ",
        "py": "QYQ",
        "provinc": "河北",
        "city": "保定市",
        "area": "清苑区"
    }, {
        "name": "河北保定市涞水县",
        "match": "河北保定市涞水县|HBBDSLSX",
        "py": "LSX",
        "provinc": "河北",
        "city": "保定市",
        "area": "涞水县"
    }, {
        "name": "河北保定市阜平县",
        "match": "河北保定市阜平县|HBBDSFPX",
        "py": "FPX",
        "provinc": "河北",
        "city": "保定市",
        "area": "阜平县"
    }, {
        "name": "河北保定市莲池区",
        "match": "河北保定市莲池区|HBBDSLCQ",
        "py": "LCQ",
        "provinc": "河北",
        "city": "保定市",
        "area": "莲池区"
    }, {
        "name": "河北保定市徐水区",
        "match": "河北保定市徐水区|HBBDSXSQ",
        "py": "XSQ",
        "provinc": "河北",
        "city": "保定市",
        "area": "徐水区"
    }, {
        "name": "河北保定市定兴县",
        "match": "河北保定市定兴县|HBBDSDXX",
        "py": "DXX",
        "provinc": "河北",
        "city": "保定市",
        "area": "定兴县"
    }, {
        "name": "河北保定市唐县",
        "match": "河北保定市唐县|HBBDSTX",
        "py": "TX",
        "provinc": "河北",
        "city": "保定市",
        "area": "唐县"
    }, {
        "name": "河北保定市高阳县",
        "match": "河北保定市高阳县|HBBDSGYX",
        "py": "GYX",
        "provinc": "河北",
        "city": "保定市",
        "area": "高阳县"
    }, {
        "name": "河北保定市容城县",
        "match": "河北保定市容城县|HBBDSRCX",
        "py": "RCX",
        "provinc": "河北",
        "city": "保定市",
        "area": "容城县"
    }, {
        "name": "河北保定市涞源县",
        "match": "河北保定市涞源县|HBBDSLYX",
        "py": "LYX",
        "provinc": "河北",
        "city": "保定市",
        "area": "涞源县"
    }, {
        "name": "河北保定市望都县",
        "match": "河北保定市望都县|HBBDSWDX",
        "py": "WDX",
        "provinc": "河北",
        "city": "保定市",
        "area": "望都县"
    }, {
        "name": "河北保定市安新县",
        "match": "河北保定市安新县|HBBDSAXX",
        "py": "AXX",
        "provinc": "河北",
        "city": "保定市",
        "area": "安新县"
    }, {
        "name": "河北保定市易县",
        "match": "河北保定市易县|HBBDSYX",
        "py": "YX",
        "provinc": "河北",
        "city": "保定市",
        "area": "易县"
    }, {
        "name": "河北保定市曲阳县",
        "match": "河北保定市曲阳县|HBBDSQYX",
        "py": "QYX",
        "provinc": "河北",
        "city": "保定市",
        "area": "曲阳县"
    }, {
        "name": "河北保定市蠡县",
        "match": "河北保定市蠡县|HBBDSLX",
        "py": "LX",
        "provinc": "河北",
        "city": "保定市",
        "area": "蠡县"
    }, {
        "name": "河北保定市顺平县",
        "match": "河北保定市顺平县|HBBDSSPX",
        "py": "SPX",
        "provinc": "河北",
        "city": "保定市",
        "area": "顺平县"
    }, {
        "name": "河北保定市博野县",
        "match": "河北保定市博野县|HBBDSBYX",
        "py": "BYX",
        "provinc": "河北",
        "city": "保定市",
        "area": "博野县"
    }, {
        "name": "河北保定市雄县",
        "match": "河北保定市雄县|HBBDSXX",
        "py": "XX",
        "provinc": "河北",
        "city": "保定市",
        "area": "雄县"
    }, {
        "name": "河北保定市涿州市",
        "match": "河北保定市涿州市|HBBDSZZS",
        "py": "ZZS",
        "provinc": "河北",
        "city": "保定市",
        "area": "涿州市"
    }, {
        "name": "河北保定市定州市",
        "match": "河北保定市定州市|HBBDSDZS",
        "py": "DZS",
        "provinc": "河北",
        "city": "保定市",
        "area": "定州市"
    }, {
        "name": "河北保定市安国市",
        "match": "河北保定市安国市|HBBDSAGS",
        "py": "AGS",
        "provinc": "河北",
        "city": "保定市",
        "area": "安国市"
    }, {
        "name": "河北保定市高碑店市",
        "match": "河北保定市高碑店市|HBBDSGBDS",
        "py": "GBDS",
        "provinc": "河北",
        "city": "保定市",
        "area": "高碑店市"
    }, {
        "name": "河北保定市白沟县",
        "match": "河北保定市白沟县|HBBDSBGX",
        "py": "BGX",
        "provinc": "河北",
        "city": "保定市",
        "area": "白沟县"
    }, {
        "name": "河北张家口市",
        "match": "河北张家口市|HBZJKS",
        "py": "ZJKS",
        "provinc": "河北",
        "city": "张家口市",
        "area": "张家口市"
    }, {
        "name": "河北张家口市桥东区",
        "match": "河北张家口市桥东区|HBZJKSQDQ",
        "py": "QDQ",
        "provinc": "河北",
        "city": "张家口市",
        "area": "桥东区"
    }, {
        "name": "河北张家口市桥西区",
        "match": "河北张家口市桥西区|HBZJKSQXQ",
        "py": "QXQ",
        "provinc": "河北",
        "city": "张家口市",
        "area": "桥西区"
    }, {
        "name": "河北张家口市宣化区",
        "match": "河北张家口市宣化区|HBZJKSXHQ",
        "py": "XHQ",
        "provinc": "河北",
        "city": "张家口市",
        "area": "宣化区"
    }, {
        "name": "河北张家口市下花园区",
        "match": "河北张家口市下花园区|HBZJKSXHYQ",
        "py": "XHYQ",
        "provinc": "河北",
        "city": "张家口市",
        "area": "下花园区"
    }, {
        "name": "河北张家口市张北县",
        "match": "河北张家口市张北县|HBZJKSZBX",
        "py": "ZBX",
        "provinc": "河北",
        "city": "张家口市",
        "area": "张北县"
    }, {
        "name": "河北张家口市康保县",
        "match": "河北张家口市康保县|HBZJKSKBX",
        "py": "KBX",
        "provinc": "河北",
        "city": "张家口市",
        "area": "康保县"
    }, {
        "name": "河北张家口市沽源县",
        "match": "河北张家口市沽源县|HBZJKSGYX",
        "py": "GYX",
        "provinc": "河北",
        "city": "张家口市",
        "area": "沽源县"
    }, {
        "name": "河北张家口市尚义县",
        "match": "河北张家口市尚义县|HBZJKSSYX",
        "py": "SYX",
        "provinc": "河北",
        "city": "张家口市",
        "area": "尚义县"
    }, {
        "name": "河北张家口市蔚县",
        "match": "河北张家口市蔚县|HBZJKSWX",
        "py": "WX",
        "provinc": "河北",
        "city": "张家口市",
        "area": "蔚县"
    }, {
        "name": "河北张家口市阳原县",
        "match": "河北张家口市阳原县|HBZJKSYYX",
        "py": "YYX",
        "provinc": "河北",
        "city": "张家口市",
        "area": "阳原县"
    }, {
        "name": "河北张家口市怀安县",
        "match": "河北张家口市怀安县|HBZJKSHAX",
        "py": "HAX",
        "provinc": "河北",
        "city": "张家口市",
        "area": "怀安县"
    }, {
        "name": "河北张家口市万全区",
        "match": "河北张家口市万全区|HBZJKSWQQ",
        "py": "WQQ",
        "provinc": "河北",
        "city": "张家口市",
        "area": "万全区"
    }, {
        "name": "河北张家口市怀来县",
        "match": "河北张家口市怀来县|HBZJKSHLX",
        "py": "HLX",
        "provinc": "河北",
        "city": "张家口市",
        "area": "怀来县"
    }, {
        "name": "河北张家口市涿鹿县",
        "match": "河北张家口市涿鹿县|HBZJKSZLX",
        "py": "ZLX",
        "provinc": "河北",
        "city": "张家口市",
        "area": "涿鹿县"
    }, {
        "name": "河北张家口市赤城县",
        "match": "河北张家口市赤城县|HBZJKSCCX",
        "py": "CCX",
        "provinc": "河北",
        "city": "张家口市",
        "area": "赤城县"
    }, {
        "name": "河北张家口市崇礼区",
        "match": "河北张家口市崇礼区|HBZJKSCLQ",
        "py": "CLQ",
        "provinc": "河北",
        "city": "张家口市",
        "area": "崇礼区"
    }, {
        "name": "河北承德市",
        "match": "河北承德市|HBCDS",
        "py": "CDS",
        "provinc": "河北",
        "city": "承德市",
        "area": "承德市"
    }, {
        "name": "河北承德市双桥区",
        "match": "河北承德市双桥区|HBCDSSQQ",
        "py": "SQQ",
        "provinc": "河北",
        "city": "承德市",
        "area": "双桥区"
    }, {
        "name": "河北承德市双滦区",
        "match": "河北承德市双滦区|HBCDSSLQ",
        "py": "SLQ",
        "provinc": "河北",
        "city": "承德市",
        "area": "双滦区"
    }, {
        "name": "河北承德市鹰手营子矿区",
        "match": "河北承德市鹰手营子矿区|HBCDSYSYZKQ",
        "py": "YSYZKQ",
        "provinc": "河北",
        "city": "承德市",
        "area": "鹰手营子矿区"
    }, {
        "name": "河北承德市承德县",
        "match": "河北承德市承德县|HBCDSCDX",
        "py": "CDX",
        "provinc": "河北",
        "city": "承德市",
        "area": "承德县"
    }, {
        "name": "河北承德市兴隆县",
        "match": "河北承德市兴隆县|HBCDSXLX",
        "py": "XLX",
        "provinc": "河北",
        "city": "承德市",
        "area": "兴隆县"
    }, {
        "name": "河北承德市平泉县",
        "match": "河北承德市平泉县|HBCDSPQX",
        "py": "PQX",
        "provinc": "河北",
        "city": "承德市",
        "area": "平泉县"
    }, {
        "name": "河北承德市滦平县",
        "match": "河北承德市滦平县|HBCDSLPX",
        "py": "LPX",
        "provinc": "河北",
        "city": "承德市",
        "area": "滦平县"
    }, {
        "name": "河北承德市隆化县",
        "match": "河北承德市隆化县|HBCDSLHX",
        "py": "LHX",
        "provinc": "河北",
        "city": "承德市",
        "area": "隆化县"
    }, {
        "name": "河北承德市丰宁县",
        "match": "河北承德市丰宁县|HBCDSFNZZX",
        "py": "FNZZX",
        "provinc": "河北",
        "city": "承德市",
        "area": "丰宁县"
    }, {
        "name": "河北承德市宽城县",
        "match": "河北承德市宽城县|HBCDSKCZZX",
        "py": "KCZZX",
        "provinc": "河北",
        "city": "承德市",
        "area": "宽城县"
    }, {
        "name": "河北承德市围场县",
        "match": "河北承德市围场县|HBCDSWCZZX",
        "py": "WCZZX",
        "provinc": "河北",
        "city": "承德市",
        "area": "围场县"
    }, {
        "name": "河北唐山市",
        "match": "河北唐山市|HBTSS",
        "py": "TSS",
        "provinc": "河北",
        "city": "唐山市",
        "area": "唐山市"
    }, {
        "name": "河北唐山市路南区",
        "match": "河北唐山市路南区|HBTSSLNQ",
        "py": "LNQ",
        "provinc": "河北",
        "city": "唐山市",
        "area": "路南区"
    }, {
        "name": "河北唐山市路北区",
        "match": "河北唐山市路北区|HBTSSLBQ",
        "py": "LBQ",
        "provinc": "河北",
        "city": "唐山市",
        "area": "路北区"
    }, {
        "name": "河北唐山市古冶区",
        "match": "河北唐山市古冶区|HBTSSGYQ",
        "py": "GYQ",
        "provinc": "河北",
        "city": "唐山市",
        "area": "古冶区"
    }, {
        "name": "河北唐山市开平区",
        "match": "河北唐山市开平区|HBTSSKPQ",
        "py": "KPQ",
        "provinc": "河北",
        "city": "唐山市",
        "area": "开平区"
    }, {
        "name": "河北唐山市丰南区",
        "match": "河北唐山市丰南区|HBTSSFNQ",
        "py": "FNQ",
        "provinc": "河北",
        "city": "唐山市",
        "area": "丰南区"
    }, {
        "name": "河北唐山市丰润区",
        "match": "河北唐山市丰润区|HBTSSFRQ",
        "py": "FRQ",
        "provinc": "河北",
        "city": "唐山市",
        "area": "丰润区"
    }, {
        "name": "河北唐山市滦县",
        "match": "河北唐山市滦县|HBTSSLX",
        "py": "LX",
        "provinc": "河北",
        "city": "唐山市",
        "area": "滦县"
    }, {
        "name": "河北唐山市滦南县",
        "match": "河北唐山市滦南县|HBTSSLNX",
        "py": "LNX",
        "provinc": "河北",
        "city": "唐山市",
        "area": "滦南县"
    }, {
        "name": "河北唐山市乐亭县",
        "match": "河北唐山市乐亭县|HBTSSLTX",
        "py": "LTX",
        "provinc": "河北",
        "city": "唐山市",
        "area": "乐亭县"
    }, {
        "name": "河北唐山市迁西县",
        "match": "河北唐山市迁西县|HBTSSQXX",
        "py": "QXX",
        "provinc": "河北",
        "city": "唐山市",
        "area": "迁西县"
    }, {
        "name": "河北唐山市玉田县",
        "match": "河北唐山市玉田县|HBTSSYTX",
        "py": "YTX",
        "provinc": "河北",
        "city": "唐山市",
        "area": "玉田县"
    }, {
        "name": "河北唐山市曹妃甸区",
        "match": "河北唐山市曹妃甸区|HBTSSCFDQ",
        "py": "CFDQ",
        "provinc": "河北",
        "city": "唐山市",
        "area": "曹妃甸区"
    }, {
        "name": "河北唐山市遵化市",
        "match": "河北唐山市遵化市|HBTSSZHS",
        "py": "ZHS",
        "provinc": "河北",
        "city": "唐山市",
        "area": "遵化市"
    }, {
        "name": "河北唐山市迁安市",
        "match": "河北唐山市迁安市|HBTSSQAS",
        "py": "QAS",
        "provinc": "河北",
        "city": "唐山市",
        "area": "迁安市"
    }, {
        "name": "河北廊坊市",
        "match": "河北廊坊市|HBLFS",
        "py": "LFS",
        "provinc": "河北",
        "city": "廊坊市",
        "area": "廊坊市"
    }, {
        "name": "河北廊坊市安次区",
        "match": "河北廊坊市安次区|HBLFSACQ",
        "py": "ACQ",
        "provinc": "河北",
        "city": "廊坊市",
        "area": "安次区"
    }, {
        "name": "河北廊坊市广阳区",
        "match": "河北廊坊市广阳区|HBLFSGYQ",
        "py": "GYQ",
        "provinc": "河北",
        "city": "廊坊市",
        "area": "广阳区"
    }, {
        "name": "河北廊坊市固安县",
        "match": "河北廊坊市固安县|HBLFSGAX",
        "py": "GAX",
        "provinc": "河北",
        "city": "廊坊市",
        "area": "固安县"
    }, {
        "name": "河北廊坊市永清县",
        "match": "河北廊坊市永清县|HBLFSYQX",
        "py": "YQX",
        "provinc": "河北",
        "city": "廊坊市",
        "area": "永清县"
    }, {
        "name": "河北廊坊市香河县",
        "match": "河北廊坊市香河县|HBLFSXHX",
        "py": "XHX",
        "provinc": "河北",
        "city": "廊坊市",
        "area": "香河县"
    }, {
        "name": "河北廊坊市大城县",
        "match": "河北廊坊市大城县|HBLFSDCX",
        "py": "DCX",
        "provinc": "河北",
        "city": "廊坊市",
        "area": "大城县"
    }, {
        "name": "河北廊坊市文安县",
        "match": "河北廊坊市文安县|HBLFSWAX",
        "py": "WAX",
        "provinc": "河北",
        "city": "廊坊市",
        "area": "文安县"
    }, {
        "name": "河北廊坊市大厂县",
        "match": "河北廊坊市大厂县|HBLFSDCZZX",
        "py": "DCZZX",
        "provinc": "河北",
        "city": "廊坊市",
        "area": "大厂县"
    }, {
        "name": "河北廊坊市霸州市",
        "match": "河北廊坊市霸州市|HBLFSBZS",
        "py": "BZS",
        "provinc": "河北",
        "city": "廊坊市",
        "area": "霸州市"
    }, {
        "name": "河北廊坊市三河市",
        "match": "河北廊坊市三河市|HBLFSSHS",
        "py": "SHS",
        "provinc": "河北",
        "city": "廊坊市",
        "area": "三河市"
    }, {
        "name": "河北沧州市",
        "match": "河北沧州市|HBCZS",
        "py": "CZS",
        "provinc": "河北",
        "city": "沧州市",
        "area": "沧州市"
    }, {
        "name": "河北沧州市新华区",
        "match": "河北沧州市新华区|HBCZSXHQ",
        "py": "XHQ",
        "provinc": "河北",
        "city": "沧州市",
        "area": "新华区"
    }, {
        "name": "河北沧州市运河区",
        "match": "河北沧州市运河区|HBCZSYHQ",
        "py": "YHQ",
        "provinc": "河北",
        "city": "沧州市",
        "area": "运河区"
    }, {
        "name": "河北沧州市沧县",
        "match": "河北沧州市沧县|HBCZSCX",
        "py": "CX",
        "provinc": "河北",
        "city": "沧州市",
        "area": "沧县"
    }, {
        "name": "河北沧州市青县",
        "match": "河北沧州市青县|HBCZSQX",
        "py": "QX",
        "provinc": "河北",
        "city": "沧州市",
        "area": "青县"
    }, {
        "name": "河北沧州市东光县",
        "match": "河北沧州市东光县|HBCZSDGX",
        "py": "DGX",
        "provinc": "河北",
        "city": "沧州市",
        "area": "东光县"
    }, {
        "name": "河北沧州市海兴县",
        "match": "河北沧州市海兴县|HBCZSHXX",
        "py": "HXX",
        "provinc": "河北",
        "city": "沧州市",
        "area": "海兴县"
    }, {
        "name": "河北沧州市盐山县",
        "match": "河北沧州市盐山县|HBCZSYSX",
        "py": "YSX",
        "provinc": "河北",
        "city": "沧州市",
        "area": "盐山县"
    }, {
        "name": "河北沧州市肃宁县",
        "match": "河北沧州市肃宁县|HBCZSSNX",
        "py": "SNX",
        "provinc": "河北",
        "city": "沧州市",
        "area": "肃宁县"
    }, {
        "name": "河北沧州市南皮县",
        "match": "河北沧州市南皮县|HBCZSNPX",
        "py": "NPX",
        "provinc": "河北",
        "city": "沧州市",
        "area": "南皮县"
    }, {
        "name": "河北沧州市吴桥县",
        "match": "河北沧州市吴桥县|HBCZSWQX",
        "py": "WQX",
        "provinc": "河北",
        "city": "沧州市",
        "area": "吴桥县"
    }, {
        "name": "河北沧州市献县",
        "match": "河北沧州市献县|HBCZSXX",
        "py": "XX",
        "provinc": "河北",
        "city": "沧州市",
        "area": "献县"
    }, {
        "name": "河北沧州市孟村县",
        "match": "河北沧州市孟村县|HBCZSMCZZX",
        "py": "MCZZX",
        "provinc": "河北",
        "city": "沧州市",
        "area": "孟村县"
    }, {
        "name": "河北沧州市泊头市",
        "match": "河北沧州市泊头市|HBCZSBTS",
        "py": "BTS",
        "provinc": "河北",
        "city": "沧州市",
        "area": "泊头市"
    }, {
        "name": "河北沧州市任丘市",
        "match": "河北沧州市任丘市|HBCZSRQS",
        "py": "RQS",
        "provinc": "河北",
        "city": "沧州市",
        "area": "任丘市"
    }, {
        "name": "河北沧州市黄骅市",
        "match": "河北沧州市黄骅市|HBCZSHHS",
        "py": "HHS",
        "provinc": "河北",
        "city": "沧州市",
        "area": "黄骅市"
    }, {
        "name": "河北沧州市河间市",
        "match": "河北沧州市河间市|HBCZSHJS",
        "py": "HJS",
        "provinc": "河北",
        "city": "沧州市",
        "area": "河间市"
    }, {
        "name": "河北衡水市",
        "match": "河北衡水市|HBHSS",
        "py": "HSS",
        "provinc": "河北",
        "city": "衡水市",
        "area": "衡水市"
    }, {
        "name": "河北衡水市桃城区",
        "match": "河北衡水市桃城区|HBHSSTCQ",
        "py": "TCQ",
        "provinc": "河北",
        "city": "衡水市",
        "area": "桃城区"
    }, {
        "name": "河北衡水市枣强县",
        "match": "河北衡水市枣强县|HBHSSZQX",
        "py": "ZQX",
        "provinc": "河北",
        "city": "衡水市",
        "area": "枣强县"
    }, {
        "name": "河北衡水市武邑县",
        "match": "河北衡水市武邑县|HBHSSWYX",
        "py": "WYX",
        "provinc": "河北",
        "city": "衡水市",
        "area": "武邑县"
    }, {
        "name": "河北衡水市武强县",
        "match": "河北衡水市武强县|HBHSSWQX",
        "py": "WQX",
        "provinc": "河北",
        "city": "衡水市",
        "area": "武强县"
    }, {
        "name": "河北衡水市饶阳县",
        "match": "河北衡水市饶阳县|HBHSSRYX",
        "py": "RYX",
        "provinc": "河北",
        "city": "衡水市",
        "area": "饶阳县"
    }, {
        "name": "河北衡水市安平县",
        "match": "河北衡水市安平县|HBHSSAPX",
        "py": "APX",
        "provinc": "河北",
        "city": "衡水市",
        "area": "安平县"
    }, {
        "name": "河北衡水市故城县",
        "match": "河北衡水市故城县|HBHSSGCX",
        "py": "GCX",
        "provinc": "河北",
        "city": "衡水市",
        "area": "故城县"
    }, {
        "name": "河北衡水市景县",
        "match": "河北衡水市景县|HBHSSJX",
        "py": "JX",
        "provinc": "河北",
        "city": "衡水市",
        "area": "景县"
    }, {
        "name": "河北衡水市阜城县",
        "match": "河北衡水市阜城县|HBHSSFCX",
        "py": "FCX",
        "provinc": "河北",
        "city": "衡水市",
        "area": "阜城县"
    }, {
        "name": "河北衡水市冀州区",
        "match": "河北衡水市冀州区|HBHSSJZQ",
        "py": "JZQ",
        "provinc": "河北",
        "city": "衡水市",
        "area": "冀州区"
    }, {
        "name": "河北衡水市深州市",
        "match": "河北衡水市深州市|HBHSSSZS",
        "py": "SZS",
        "provinc": "河北",
        "city": "衡水市",
        "area": "深州市"
    }, {
        "name": "河北邢台市",
        "match": "河北邢台市|HBXTS",
        "py": "XTS",
        "provinc": "河北",
        "city": "邢台市",
        "area": "邢台市"
    }, {
        "name": "河北邢台市桥东区",
        "match": "河北邢台市桥东区|HBXTSQDQ",
        "py": "QDQ",
        "provinc": "河北",
        "city": "邢台市",
        "area": "桥东区"
    }, {
        "name": "河北邢台市桥西区",
        "match": "河北邢台市桥西区|HBXTSQXQ",
        "py": "QXQ",
        "provinc": "河北",
        "city": "邢台市",
        "area": "桥西区"
    }, {
        "name": "河北邢台市邢台县",
        "match": "河北邢台市邢台县|HBXTSXTX",
        "py": "XTX",
        "provinc": "河北",
        "city": "邢台市",
        "area": "邢台县"
    }, {
        "name": "河北邢台市临城县",
        "match": "河北邢台市临城县|HBXTSLCX",
        "py": "LCX",
        "provinc": "河北",
        "city": "邢台市",
        "area": "临城县"
    }, {
        "name": "河北邢台市内丘县",
        "match": "河北邢台市内丘县|HBXTSNQX",
        "py": "NQX",
        "provinc": "河北",
        "city": "邢台市",
        "area": "内丘县"
    }, {
        "name": "河北邢台市柏乡县",
        "match": "河北邢台市柏乡县|HBXTSBXX",
        "py": "BXX",
        "provinc": "河北",
        "city": "邢台市",
        "area": "柏乡县"
    }, {
        "name": "河北邢台市隆尧县",
        "match": "河北邢台市隆尧县|HBXTSLYX",
        "py": "LYX",
        "provinc": "河北",
        "city": "邢台市",
        "area": "隆尧县"
    }, {
        "name": "河北邢台市任县",
        "match": "河北邢台市任县|HBXTSRX",
        "py": "RX",
        "provinc": "河北",
        "city": "邢台市",
        "area": "任县"
    }, {
        "name": "河北邢台市南和县",
        "match": "河北邢台市南和县|HBXTSNHX",
        "py": "NHX",
        "provinc": "河北",
        "city": "邢台市",
        "area": "南和县"
    }, {
        "name": "河北邢台市宁晋县",
        "match": "河北邢台市宁晋县|HBXTSNJX",
        "py": "NJX",
        "provinc": "河北",
        "city": "邢台市",
        "area": "宁晋县"
    }, {
        "name": "河北邢台市巨鹿县",
        "match": "河北邢台市巨鹿县|HBXTSJLX",
        "py": "JLX",
        "provinc": "河北",
        "city": "邢台市",
        "area": "巨鹿县"
    }, {
        "name": "河北邢台市新河县",
        "match": "河北邢台市新河县|HBXTSXHX",
        "py": "XHX",
        "provinc": "河北",
        "city": "邢台市",
        "area": "新河县"
    }, {
        "name": "河北邢台市广宗县",
        "match": "河北邢台市广宗县|HBXTSGZX",
        "py": "GZX",
        "provinc": "河北",
        "city": "邢台市",
        "area": "广宗县"
    }, {
        "name": "河北邢台市平乡县",
        "match": "河北邢台市平乡县|HBXTSPXX",
        "py": "PXX",
        "provinc": "河北",
        "city": "邢台市",
        "area": "平乡县"
    }, {
        "name": "河北邢台市威县",
        "match": "河北邢台市威县|HBXTSWX",
        "py": "WX",
        "provinc": "河北",
        "city": "邢台市",
        "area": "威县"
    }, {
        "name": "河北邢台市清河县",
        "match": "河北邢台市清河县|HBXTSQHX",
        "py": "QHX",
        "provinc": "河北",
        "city": "邢台市",
        "area": "清河县"
    }, {
        "name": "河北邢台市临西县",
        "match": "河北邢台市临西县|HBXTSLXX",
        "py": "LXX",
        "provinc": "河北",
        "city": "邢台市",
        "area": "临西县"
    }, {
        "name": "河北邢台市南宫市",
        "match": "河北邢台市南宫市|HBXTSNGS",
        "py": "NGS",
        "provinc": "河北",
        "city": "邢台市",
        "area": "南宫市"
    }, {
        "name": "河北邢台市沙河市",
        "match": "河北邢台市沙河市|HBXTSSHS",
        "py": "SHS",
        "provinc": "河北",
        "city": "邢台市",
        "area": "沙河市"
    }, {
        "name": "河北秦皇岛市",
        "match": "河北秦皇岛市|HBQHDS",
        "py": "QHDS",
        "provinc": "河北",
        "city": "秦皇岛市",
        "area": "秦皇岛市"
    }, {
        "name": "河北秦皇岛市海港区",
        "match": "河北秦皇岛市海港区|HBQHDSHGQ",
        "py": "HGQ",
        "provinc": "河北",
        "city": "秦皇岛市",
        "area": "海港区"
    }, {
        "name": "河北秦皇岛市山海关区",
        "match": "河北秦皇岛市山海关区|HBQHDSSHGQ",
        "py": "SHGQ",
        "provinc": "河北",
        "city": "秦皇岛市",
        "area": "山海关区"
    }, {
        "name": "河北秦皇岛市北戴河区",
        "match": "河北秦皇岛市北戴河区|HBQHDSBDHQ",
        "py": "BDHQ",
        "provinc": "河北",
        "city": "秦皇岛市",
        "area": "北戴河区"
    }, {
        "name": "河北秦皇岛市青龙县",
        "match": "河北秦皇岛市青龙县|HBQHDSQLZZX",
        "py": "QLZZX",
        "provinc": "河北",
        "city": "秦皇岛市",
        "area": "青龙县"
    }, {
        "name": "河北秦皇岛市昌黎县",
        "match": "河北秦皇岛市昌黎县|HBQHDSCLX",
        "py": "CLX",
        "provinc": "河北",
        "city": "秦皇岛市",
        "area": "昌黎县"
    }, {
        "name": "河北秦皇岛市抚宁区",
        "match": "河北秦皇岛市抚宁区|HBQHDSFNQ",
        "py": "FNQ",
        "provinc": "河北",
        "city": "秦皇岛市",
        "area": "抚宁区"
    }, {
        "name": "河北秦皇岛市卢龙县",
        "match": "河北秦皇岛市卢龙县|HBQHDSLLX",
        "py": "LLX",
        "provinc": "河北",
        "city": "秦皇岛市",
        "area": "卢龙县"
    }, {
        "name": "河南商丘市",
        "match": "河南商丘市|HNSQS",
        "py": "SQS",
        "provinc": "河南",
        "city": "商丘市",
        "area": "商丘市"
    }, {
        "name": "河南商丘市梁园区",
        "match": "河南商丘市梁园区|HNSQSLYQ",
        "py": "LYQ",
        "provinc": "河南",
        "city": "商丘市",
        "area": "梁园区"
    }, {
        "name": "河南商丘市睢阳区",
        "match": "河南商丘市睢阳区|HNSQSSYQ",
        "py": "SYQ",
        "provinc": "河南",
        "city": "商丘市",
        "area": "睢阳区"
    }, {
        "name": "河南商丘市民权县",
        "match": "河南商丘市民权县|HNSQSMQX",
        "py": "MQX",
        "provinc": "河南",
        "city": "商丘市",
        "area": "民权县"
    }, {
        "name": "河南商丘市睢县",
        "match": "河南商丘市睢县|HNSQSSX",
        "py": "SX",
        "provinc": "河南",
        "city": "商丘市",
        "area": "睢县"
    }, {
        "name": "河南商丘市宁陵县",
        "match": "河南商丘市宁陵县|HNSQSNLX",
        "py": "NLX",
        "provinc": "河南",
        "city": "商丘市",
        "area": "宁陵县"
    }, {
        "name": "河南商丘市柘城县",
        "match": "河南商丘市柘城县|HNSQSZCX",
        "py": "ZCX",
        "provinc": "河南",
        "city": "商丘市",
        "area": "柘城县"
    }, {
        "name": "河南商丘市虞城县",
        "match": "河南商丘市虞城县|HNSQSYCX",
        "py": "YCX",
        "provinc": "河南",
        "city": "商丘市",
        "area": "虞城县"
    }, {
        "name": "河南商丘市夏邑县",
        "match": "河南商丘市夏邑县|HNSQSXYX",
        "py": "XYX",
        "provinc": "河南",
        "city": "商丘市",
        "area": "夏邑县"
    }, {
        "name": "河南商丘市永城市",
        "match": "河南商丘市永城市|HNSQSYCS",
        "py": "YCS",
        "provinc": "河南",
        "city": "商丘市",
        "area": "永城市"
    }, {
        "name": "河南郑州市",
        "match": "河南郑州市|HNZZS",
        "py": "ZZS",
        "provinc": "河南",
        "city": "郑州市",
        "area": "郑州市"
    }, {
        "name": "河南郑州市中原区",
        "match": "河南郑州市中原区|HNZZSZYQ",
        "py": "ZYQ",
        "provinc": "河南",
        "city": "郑州市",
        "area": "中原区"
    }, {
        "name": "河南郑州市二七区",
        "match": "河南郑州市二七区|HNZZSEQQ",
        "py": "EQQ",
        "provinc": "河南",
        "city": "郑州市",
        "area": "二七区"
    }, {
        "name": "河南郑州市管城区",
        "match": "河南郑州市管城区|HNZZSGCHZQ",
        "py": "GCHZQ",
        "provinc": "河南",
        "city": "郑州市",
        "area": "管城区"
    }, {
        "name": "河南郑州市金水区",
        "match": "河南郑州市金水区|HNZZSJSQ",
        "py": "JSQ",
        "provinc": "河南",
        "city": "郑州市",
        "area": "金水区"
    }, {
        "name": "河南郑州市上街区",
        "match": "河南郑州市上街区|HNZZSSJQ",
        "py": "SJQ",
        "provinc": "河南",
        "city": "郑州市",
        "area": "上街区"
    }, {
        "name": "河南郑州市惠济区",
        "match": "河南郑州市惠济区|HNZZSHJQ",
        "py": "HJQ",
        "provinc": "河南",
        "city": "郑州市",
        "area": "惠济区"
    }, {
        "name": "河南郑州市中牟县",
        "match": "河南郑州市中牟县|HNZZSZMX",
        "py": "ZMX",
        "provinc": "河南",
        "city": "郑州市",
        "area": "中牟县"
    }, {
        "name": "河南郑州市巩义市",
        "match": "河南郑州市巩义市|HNZZSGYS",
        "py": "GYS",
        "provinc": "河南",
        "city": "郑州市",
        "area": "巩义市"
    }, {
        "name": "河南郑州市荥阳市",
        "match": "河南郑州市荥阳市|HNZZSXYS",
        "py": "XYS",
        "provinc": "河南",
        "city": "郑州市",
        "area": "荥阳市"
    }, {
        "name": "河南郑州市新密市",
        "match": "河南郑州市新密市|HNZZSXMS",
        "py": "XMS",
        "provinc": "河南",
        "city": "郑州市",
        "area": "新密市"
    }, {
        "name": "河南郑州市新郑市",
        "match": "河南郑州市新郑市|HNZZSXZS",
        "py": "XZS",
        "provinc": "河南",
        "city": "郑州市",
        "area": "新郑市"
    }, {
        "name": "河南郑州市登封市",
        "match": "河南郑州市登封市|HNZZSDFS",
        "py": "DFS",
        "provinc": "河南",
        "city": "郑州市",
        "area": "登封市"
    }, {
        "name": "河南安阳市",
        "match": "河南安阳市|HNAYS",
        "py": "AYS",
        "provinc": "河南",
        "city": "安阳市",
        "area": "安阳市"
    }, {
        "name": "河南安阳市文峰区",
        "match": "河南安阳市文峰区|HNAYSWFQ",
        "py": "WFQ",
        "provinc": "河南",
        "city": "安阳市",
        "area": "文峰区"
    }, {
        "name": "河南安阳市北关区",
        "match": "河南安阳市北关区|HNAYSBGQ",
        "py": "BGQ",
        "provinc": "河南",
        "city": "安阳市",
        "area": "北关区"
    }, {
        "name": "河南安阳市殷都区",
        "match": "河南安阳市殷都区|HNAYSYDQ",
        "py": "YDQ",
        "provinc": "河南",
        "city": "安阳市",
        "area": "殷都区"
    }, {
        "name": "河南安阳市龙安区",
        "match": "河南安阳市龙安区|HNAYSLAQ",
        "py": "LAQ",
        "provinc": "河南",
        "city": "安阳市",
        "area": "龙安区"
    }, {
        "name": "河南安阳市安阳县",
        "match": "河南安阳市安阳县|HNAYSAYX",
        "py": "AYX",
        "provinc": "河南",
        "city": "安阳市",
        "area": "安阳县"
    }, {
        "name": "河南安阳市汤阴县",
        "match": "河南安阳市汤阴县|HNAYSTYX",
        "py": "TYX",
        "provinc": "河南",
        "city": "安阳市",
        "area": "汤阴县"
    }, {
        "name": "河南安阳市滑县",
        "match": "河南安阳市滑县|HNAYSHX",
        "py": "HX",
        "provinc": "河南",
        "city": "安阳市",
        "area": "滑县"
    }, {
        "name": "河南安阳市内黄县",
        "match": "河南安阳市内黄县|HNAYSNHX",
        "py": "NHX",
        "provinc": "河南",
        "city": "安阳市",
        "area": "内黄县"
    }, {
        "name": "河南安阳市林州市",
        "match": "河南安阳市林州市|HNAYSLZS",
        "py": "LZS",
        "provinc": "河南",
        "city": "安阳市",
        "area": "林州市"
    }, {
        "name": "河南新乡市",
        "match": "河南新乡市|HNXXS",
        "py": "XXS",
        "provinc": "河南",
        "city": "新乡市",
        "area": "新乡市"
    }, {
        "name": "河南新乡市红旗区",
        "match": "河南新乡市红旗区|HNXXSHQQ",
        "py": "HQQ",
        "provinc": "河南",
        "city": "新乡市",
        "area": "红旗区"
    }, {
        "name": "河南新乡市卫滨区",
        "match": "河南新乡市卫滨区|HNXXSWBQ",
        "py": "WBQ",
        "provinc": "河南",
        "city": "新乡市",
        "area": "卫滨区"
    }, {
        "name": "河南新乡市凤泉区",
        "match": "河南新乡市凤泉区|HNXXSFQQ",
        "py": "FQQ",
        "provinc": "河南",
        "city": "新乡市",
        "area": "凤泉区"
    }, {
        "name": "河南新乡市牧野区",
        "match": "河南新乡市牧野区|HNXXSMYQ",
        "py": "MYQ",
        "provinc": "河南",
        "city": "新乡市",
        "area": "牧野区"
    }, {
        "name": "河南新乡市新乡县",
        "match": "河南新乡市新乡县|HNXXSXXX",
        "py": "XXX",
        "provinc": "河南",
        "city": "新乡市",
        "area": "新乡县"
    }, {
        "name": "河南新乡市获嘉县",
        "match": "河南新乡市获嘉县|HNXXSHJX",
        "py": "HJX",
        "provinc": "河南",
        "city": "新乡市",
        "area": "获嘉县"
    }, {
        "name": "河南新乡市原阳县",
        "match": "河南新乡市原阳县|HNXXSYYX",
        "py": "YYX",
        "provinc": "河南",
        "city": "新乡市",
        "area": "原阳县"
    }, {
        "name": "河南新乡市延津县",
        "match": "河南新乡市延津县|HNXXSYJX",
        "py": "YJX",
        "provinc": "河南",
        "city": "新乡市",
        "area": "延津县"
    }, {
        "name": "河南新乡市封丘县",
        "match": "河南新乡市封丘县|HNXXSFQX",
        "py": "FQX",
        "provinc": "河南",
        "city": "新乡市",
        "area": "封丘县"
    }, {
        "name": "河南新乡市长垣县",
        "match": "河南新乡市长垣县|HNXXSCYX",
        "py": "CYX",
        "provinc": "河南",
        "city": "新乡市",
        "area": "长垣县"
    }, {
        "name": "河南新乡市卫辉市",
        "match": "河南新乡市卫辉市|HNXXSWHS",
        "py": "WHS",
        "provinc": "河南",
        "city": "新乡市",
        "area": "卫辉市"
    }, {
        "name": "河南新乡市辉县市",
        "match": "河南新乡市辉县市|HNXXSHXS",
        "py": "HXS",
        "provinc": "河南",
        "city": "新乡市",
        "area": "辉县市"
    }, {
        "name": "河南许昌市",
        "match": "河南许昌市|HNXCS",
        "py": "XCS",
        "provinc": "河南",
        "city": "许昌市",
        "area": "许昌市"
    }, {
        "name": "河南许昌市魏都区",
        "match": "河南许昌市魏都区|HNXCSWDQ",
        "py": "WDQ",
        "provinc": "河南",
        "city": "许昌市",
        "area": "魏都区"
    }, {
        "name": "河南许昌市建安区",
        "match": "河南许昌市建安区|HNXCSJAQ",
        "py": "JAQ",
        "provinc": "河南",
        "city": "许昌市",
        "area": "建安区"
    }, {
        "name": "河南许昌市鄢陵县",
        "match": "河南许昌市鄢陵县|HNXCSYLX",
        "py": "YLX",
        "provinc": "河南",
        "city": "许昌市",
        "area": "鄢陵县"
    }, {
        "name": "河南许昌市襄城县",
        "match": "河南许昌市襄城县|HNXCSXCX",
        "py": "XCX",
        "provinc": "河南",
        "city": "许昌市",
        "area": "襄城县"
    }, {
        "name": "河南许昌市禹州市",
        "match": "河南许昌市禹州市|HNXCSYZS",
        "py": "YZS",
        "provinc": "河南",
        "city": "许昌市",
        "area": "禹州市"
    }, {
        "name": "河南许昌市长葛市",
        "match": "河南许昌市长葛市|HNXCSCGS",
        "py": "CGS",
        "provinc": "河南",
        "city": "许昌市",
        "area": "长葛市"
    }, {
        "name": "河南平顶山市",
        "match": "河南平顶山市|HNPDSS",
        "py": "PDSS",
        "provinc": "河南",
        "city": "平顶山市",
        "area": "平顶山市"
    }, {
        "name": "河南平顶山市新华区",
        "match": "河南平顶山市新华区|HNPDSSXHQ",
        "py": "XHQ",
        "provinc": "河南",
        "city": "平顶山市",
        "area": "新华区"
    }, {
        "name": "河南平顶山市卫东区",
        "match": "河南平顶山市卫东区|HNPDSSWDQ",
        "py": "WDQ",
        "provinc": "河南",
        "city": "平顶山市",
        "area": "卫东区"
    }, {
        "name": "河南平顶山市石龙区",
        "match": "河南平顶山市石龙区|HNPDSSSLQ",
        "py": "SLQ",
        "provinc": "河南",
        "city": "平顶山市",
        "area": "石龙区"
    }, {
        "name": "河南平顶山市湛河区",
        "match": "河南平顶山市湛河区|HNPDSSZHQ",
        "py": "ZHQ",
        "provinc": "河南",
        "city": "平顶山市",
        "area": "湛河区"
    }, {
        "name": "河南平顶山市宝丰县",
        "match": "河南平顶山市宝丰县|HNPDSSBFX",
        "py": "BFX",
        "provinc": "河南",
        "city": "平顶山市",
        "area": "宝丰县"
    }, {
        "name": "河南平顶山市叶县",
        "match": "河南平顶山市叶县|HNPDSSYX",
        "py": "YX",
        "provinc": "河南",
        "city": "平顶山市",
        "area": "叶县"
    }, {
        "name": "河南平顶山市鲁山县",
        "match": "河南平顶山市鲁山县|HNPDSSLSX",
        "py": "LSX",
        "provinc": "河南",
        "city": "平顶山市",
        "area": "鲁山县"
    }, {
        "name": "河南平顶山市郏县",
        "match": "河南平顶山市郏县|HNPDSSJX",
        "py": "JX",
        "provinc": "河南",
        "city": "平顶山市",
        "area": "郏县"
    }, {
        "name": "河南平顶山市舞钢市",
        "match": "河南平顶山市舞钢市|HNPDSSWGS",
        "py": "WGS",
        "provinc": "河南",
        "city": "平顶山市",
        "area": "舞钢市"
    }, {
        "name": "河南平顶山市汝州市",
        "match": "河南平顶山市汝州市|HNPDSSRZS",
        "py": "RZS",
        "provinc": "河南",
        "city": "平顶山市",
        "area": "汝州市"
    }, {
        "name": "河南信阳市",
        "match": "河南信阳市|HNXYS",
        "py": "XYS",
        "provinc": "河南",
        "city": "信阳市",
        "area": "信阳市"
    }, {
        "name": "河南信阳市浉河区",
        "match": "河南信阳市浉河区|HNXYSSHQ",
        "py": "SHQ",
        "provinc": "河南",
        "city": "信阳市",
        "area": "浉河区"
    }, {
        "name": "河南信阳市平桥区",
        "match": "河南信阳市平桥区|HNXYSPQQ",
        "py": "PQQ",
        "provinc": "河南",
        "city": "信阳市",
        "area": "平桥区"
    }, {
        "name": "河南信阳市罗山县",
        "match": "河南信阳市罗山县|HNXYSLSX",
        "py": "LSX",
        "provinc": "河南",
        "city": "信阳市",
        "area": "罗山县"
    }, {
        "name": "河南信阳市光山县",
        "match": "河南信阳市光山县|HNXYSGSX",
        "py": "GSX",
        "provinc": "河南",
        "city": "信阳市",
        "area": "光山县"
    }, {
        "name": "河南信阳市新县",
        "match": "河南信阳市新县|HNXYSXX",
        "py": "XX",
        "provinc": "河南",
        "city": "信阳市",
        "area": "新县"
    }, {
        "name": "河南信阳市商城县",
        "match": "河南信阳市商城县|HNXYSSCX",
        "py": "SCX",
        "provinc": "河南",
        "city": "信阳市",
        "area": "商城县"
    }, {
        "name": "河南信阳市固始县",
        "match": "河南信阳市固始县|HNXYSGSX",
        "py": "GSX",
        "provinc": "河南",
        "city": "信阳市",
        "area": "固始县"
    }, {
        "name": "河南信阳市潢川县",
        "match": "河南信阳市潢川县|HNXYSHCX",
        "py": "HCX",
        "provinc": "河南",
        "city": "信阳市",
        "area": "潢川县"
    }, {
        "name": "河南信阳市淮滨县",
        "match": "河南信阳市淮滨县|HNXYSHBX",
        "py": "HBX",
        "provinc": "河南",
        "city": "信阳市",
        "area": "淮滨县"
    }, {
        "name": "河南信阳市息县",
        "match": "河南信阳市息县|HNXYSXX",
        "py": "XX",
        "provinc": "河南",
        "city": "信阳市",
        "area": "息县"
    }, {
        "name": "河南南阳市",
        "match": "河南南阳市|HNNYS",
        "py": "NYS",
        "provinc": "河南",
        "city": "南阳市",
        "area": "南阳市"
    }, {
        "name": "河南南阳市宛城区",
        "match": "河南南阳市宛城区|HNNYSWCQ",
        "py": "WCQ",
        "provinc": "河南",
        "city": "南阳市",
        "area": "宛城区"
    }, {
        "name": "河南南阳市卧龙区",
        "match": "河南南阳市卧龙区|HNNYSWLQ",
        "py": "WLQ",
        "provinc": "河南",
        "city": "南阳市",
        "area": "卧龙区"
    }, {
        "name": "河南南阳市南召县",
        "match": "河南南阳市南召县|HNNYSNZX",
        "py": "NZX",
        "provinc": "河南",
        "city": "南阳市",
        "area": "南召县"
    }, {
        "name": "河南南阳市方城县",
        "match": "河南南阳市方城县|HNNYSFCX",
        "py": "FCX",
        "provinc": "河南",
        "city": "南阳市",
        "area": "方城县"
    }, {
        "name": "河南南阳市西峡县",
        "match": "河南南阳市西峡县|HNNYSXXX",
        "py": "XXX",
        "provinc": "河南",
        "city": "南阳市",
        "area": "西峡县"
    }, {
        "name": "河南南阳市镇平县",
        "match": "河南南阳市镇平县|HNNYSZPX",
        "py": "ZPX",
        "provinc": "河南",
        "city": "南阳市",
        "area": "镇平县"
    }, {
        "name": "河南南阳市内乡县",
        "match": "河南南阳市内乡县|HNNYSNXX",
        "py": "NXX",
        "provinc": "河南",
        "city": "南阳市",
        "area": "内乡县"
    }, {
        "name": "河南南阳市淅川县",
        "match": "河南南阳市淅川县|HNNYSXCX",
        "py": "XCX",
        "provinc": "河南",
        "city": "南阳市",
        "area": "淅川县"
    }, {
        "name": "河南南阳市社旗县",
        "match": "河南南阳市社旗县|HNNYSSQX",
        "py": "SQX",
        "provinc": "河南",
        "city": "南阳市",
        "area": "社旗县"
    }, {
        "name": "河南南阳市唐河县",
        "match": "河南南阳市唐河县|HNNYSTHX",
        "py": "THX",
        "provinc": "河南",
        "city": "南阳市",
        "area": "唐河县"
    }, {
        "name": "河南南阳市新野县",
        "match": "河南南阳市新野县|HNNYSXYX",
        "py": "XYX",
        "provinc": "河南",
        "city": "南阳市",
        "area": "新野县"
    }, {
        "name": "河南南阳市桐柏县",
        "match": "河南南阳市桐柏县|HNNYSTBX",
        "py": "TBX",
        "provinc": "河南",
        "city": "南阳市",
        "area": "桐柏县"
    }, {
        "name": "河南南阳市邓州市",
        "match": "河南南阳市邓州市|HNNYSDZS",
        "py": "DZS",
        "provinc": "河南",
        "city": "南阳市",
        "area": "邓州市"
    }, {
        "name": "河南开封市",
        "match": "河南开封市|HNKFS",
        "py": "KFS",
        "provinc": "河南",
        "city": "开封市",
        "area": "开封市"
    }, {
        "name": "河南开封市龙亭区",
        "match": "河南开封市龙亭区|HNKFSLTQ",
        "py": "LTQ",
        "provinc": "河南",
        "city": "开封市",
        "area": "龙亭区"
    }, {
        "name": "河南开封市顺河区",
        "match": "河南开封市顺河区|HNKFSSHHZQ",
        "py": "SHHZQ",
        "provinc": "河南",
        "city": "开封市",
        "area": "顺河区"
    }, {
        "name": "河南开封市鼓楼区",
        "match": "河南开封市鼓楼区|HNKFSGLQ",
        "py": "GLQ",
        "provinc": "河南",
        "city": "开封市",
        "area": "鼓楼区"
    }, {
        "name": "河南开封市禹王台区",
        "match": "河南开封市禹王台区|HNKFSYWTQ",
        "py": "YWTQ",
        "provinc": "河南",
        "city": "开封市",
        "area": "禹王台区"
    }, {
        "name": "河南开封市开封新区",
        "match": "河南开封市开封新区|HNKFSKFXQ",
        "py": "KFXQ",
        "provinc": "河南",
        "city": "开封市",
        "area": "开封新区"
    }, {
        "name": "河南开封市杞县",
        "match": "河南开封市杞县|HNKFSQX",
        "py": "QX",
        "provinc": "河南",
        "city": "开封市",
        "area": "杞县"
    }, {
        "name": "河南开封市通许县",
        "match": "河南开封市通许县|HNKFSTXX",
        "py": "TXX",
        "provinc": "河南",
        "city": "开封市",
        "area": "通许县"
    }, {
        "name": "河南开封市尉氏县",
        "match": "河南开封市尉氏县|HNKFSWSX",
        "py": "WSX",
        "provinc": "河南",
        "city": "开封市",
        "area": "尉氏县"
    }, {
        "name": "河南开封市祥符区",
        "match": "河南开封市祥符区|HNKFSXFQ",
        "py": "XFQ",
        "provinc": "河南",
        "city": "开封市",
        "area": "祥符区"
    }, {
        "name": "河南开封市兰考县",
        "match": "河南开封市兰考县|HNKFSLKX",
        "py": "LKX",
        "provinc": "河南",
        "city": "开封市",
        "area": "兰考县"
    }, {
        "name": "河南洛阳市",
        "match": "河南洛阳市|HNLYS",
        "py": "LYS",
        "provinc": "河南",
        "city": "洛阳市",
        "area": "洛阳市"
    }, {
        "name": "河南洛阳市老城区",
        "match": "河南洛阳市老城区|HNLYSLCQ",
        "py": "LCQ",
        "provinc": "河南",
        "city": "洛阳市",
        "area": "老城区"
    }, {
        "name": "河南洛阳市西工区",
        "match": "河南洛阳市西工区|HNLYSXGQ",
        "py": "XGQ",
        "provinc": "河南",
        "city": "洛阳市",
        "area": "西工区"
    }, {
        "name": "河南洛阳市瀍河区",
        "match": "河南洛阳市瀍河区|HNLYSCHHZQ",
        "py": "CHHZQ",
        "provinc": "河南",
        "city": "洛阳市",
        "area": "瀍河区"
    }, {
        "name": "河南洛阳市涧西区",
        "match": "河南洛阳市涧西区|HNLYSJXQ",
        "py": "JXQ",
        "provinc": "河南",
        "city": "洛阳市",
        "area": "涧西区"
    }, {
        "name": "河南洛阳市吉利区",
        "match": "河南洛阳市吉利区|HNLYSJLQ",
        "py": "JLQ",
        "provinc": "河南",
        "city": "洛阳市",
        "area": "吉利区"
    }, {
        "name": "河南洛阳市洛龙区",
        "match": "河南洛阳市洛龙区|HNLYSLLQ",
        "py": "LLQ",
        "provinc": "河南",
        "city": "洛阳市",
        "area": "洛龙区"
    }, {
        "name": "河南洛阳市孟津县",
        "match": "河南洛阳市孟津县|HNLYSMJX",
        "py": "MJX",
        "provinc": "河南",
        "city": "洛阳市",
        "area": "孟津县"
    }, {
        "name": "河南洛阳市新安县",
        "match": "河南洛阳市新安县|HNLYSXAX",
        "py": "XAX",
        "provinc": "河南",
        "city": "洛阳市",
        "area": "新安县"
    }, {
        "name": "河南洛阳市栾川县",
        "match": "河南洛阳市栾川县|HNLYSLCX",
        "py": "LCX",
        "provinc": "河南",
        "city": "洛阳市",
        "area": "栾川县"
    }, {
        "name": "河南洛阳市嵩县",
        "match": "河南洛阳市嵩县|HNLYSSX",
        "py": "SX",
        "provinc": "河南",
        "city": "洛阳市",
        "area": "嵩县"
    }, {
        "name": "河南洛阳市汝阳县",
        "match": "河南洛阳市汝阳县|HNLYSRYX",
        "py": "RYX",
        "provinc": "河南",
        "city": "洛阳市",
        "area": "汝阳县"
    }, {
        "name": "河南洛阳市宜阳县",
        "match": "河南洛阳市宜阳县|HNLYSYYX",
        "py": "YYX",
        "provinc": "河南",
        "city": "洛阳市",
        "area": "宜阳县"
    }, {
        "name": "河南洛阳市洛宁县",
        "match": "河南洛阳市洛宁县|HNLYSLNX",
        "py": "LNX",
        "provinc": "河南",
        "city": "洛阳市",
        "area": "洛宁县"
    }, {
        "name": "河南洛阳市伊川县",
        "match": "河南洛阳市伊川县|HNLYSYCX",
        "py": "YCX",
        "provinc": "河南",
        "city": "洛阳市",
        "area": "伊川县"
    }, {
        "name": "河南洛阳市偃师市",
        "match": "河南洛阳市偃师市|HNLYSYSS",
        "py": "YSS",
        "provinc": "河南",
        "city": "洛阳市",
        "area": "偃师市"
    }, {
        "name": "河南济源市",
        "match": "河南济源市|HNJYS",
        "py": "JYS",
        "provinc": "河南",
        "city": "济源市",
        "area": "济源市"
    }, {
        "name": "河南焦作市",
        "match": "河南焦作市|HNJZS",
        "py": "JZS",
        "provinc": "河南",
        "city": "焦作市",
        "area": "焦作市"
    }, {
        "name": "河南焦作市解放区",
        "match": "河南焦作市解放区|HNJZSJFQ",
        "py": "JFQ",
        "provinc": "河南",
        "city": "焦作市",
        "area": "解放区"
    }, {
        "name": "河南焦作市中站区",
        "match": "河南焦作市中站区|HNJZSZZQ",
        "py": "ZZQ",
        "provinc": "河南",
        "city": "焦作市",
        "area": "中站区"
    }, {
        "name": "河南焦作市马村区",
        "match": "河南焦作市马村区|HNJZSMCQ",
        "py": "MCQ",
        "provinc": "河南",
        "city": "焦作市",
        "area": "马村区"
    }, {
        "name": "河南焦作市山阳区",
        "match": "河南焦作市山阳区|HNJZSSYQ",
        "py": "SYQ",
        "provinc": "河南",
        "city": "焦作市",
        "area": "山阳区"
    }, {
        "name": "河南焦作市修武县",
        "match": "河南焦作市修武县|HNJZSXWX",
        "py": "XWX",
        "provinc": "河南",
        "city": "焦作市",
        "area": "修武县"
    }, {
        "name": "河南焦作市博爱县",
        "match": "河南焦作市博爱县|HNJZSBAX",
        "py": "BAX",
        "provinc": "河南",
        "city": "焦作市",
        "area": "博爱县"
    }, {
        "name": "河南焦作市武陟县",
        "match": "河南焦作市武陟县|HNJZSWZX",
        "py": "WZX",
        "provinc": "河南",
        "city": "焦作市",
        "area": "武陟县"
    }, {
        "name": "河南焦作市温县",
        "match": "河南焦作市温县|HNJZSWX",
        "py": "WX",
        "provinc": "河南",
        "city": "焦作市",
        "area": "温县"
    }, {
        "name": "河南焦作市沁阳市",
        "match": "河南焦作市沁阳市|HNJZSQYS",
        "py": "QYS",
        "provinc": "河南",
        "city": "焦作市",
        "area": "沁阳市"
    }, {
        "name": "河南焦作市孟州市",
        "match": "河南焦作市孟州市|HNJZSMZS",
        "py": "MZS",
        "provinc": "河南",
        "city": "焦作市",
        "area": "孟州市"
    }, {
        "name": "河南鹤壁市",
        "match": "河南鹤壁市|HNHBS",
        "py": "HBS",
        "provinc": "河南",
        "city": "鹤壁市",
        "area": "鹤壁市"
    }, {
        "name": "河南鹤壁市鹤山区",
        "match": "河南鹤壁市鹤山区|HNHBSHSQ",
        "py": "HSQ",
        "provinc": "河南",
        "city": "鹤壁市",
        "area": "鹤山区"
    }, {
        "name": "河南鹤壁市山城区",
        "match": "河南鹤壁市山城区|HNHBSSCQ",
        "py": "SCQ",
        "provinc": "河南",
        "city": "鹤壁市",
        "area": "山城区"
    }, {
        "name": "河南鹤壁市淇滨区",
        "match": "河南鹤壁市淇滨区|HNHBSQBQ",
        "py": "QBQ",
        "provinc": "河南",
        "city": "鹤壁市",
        "area": "淇滨区"
    }, {
        "name": "河南鹤壁市浚县",
        "match": "河南鹤壁市浚县|HNHBSJX",
        "py": "JX",
        "provinc": "河南",
        "city": "鹤壁市",
        "area": "浚县"
    }, {
        "name": "河南鹤壁市淇县",
        "match": "河南鹤壁市淇县|HNHBSQX",
        "py": "QX",
        "provinc": "河南",
        "city": "鹤壁市",
        "area": "淇县"
    }, {
        "name": "河南濮阳市",
        "match": "河南濮阳市|HNPYS",
        "py": "PYS",
        "provinc": "河南",
        "city": "濮阳市",
        "area": "濮阳市"
    }, {
        "name": "河南濮阳市华龙区",
        "match": "河南濮阳市华龙区|HNPYSHLQ",
        "py": "HLQ",
        "provinc": "河南",
        "city": "濮阳市",
        "area": "华龙区"
    }, {
        "name": "河南濮阳市清丰县",
        "match": "河南濮阳市清丰县|HNPYSQFX",
        "py": "QFX",
        "provinc": "河南",
        "city": "濮阳市",
        "area": "清丰县"
    }, {
        "name": "河南濮阳市南乐县",
        "match": "河南濮阳市南乐县|HNPYSNLX",
        "py": "NLX",
        "provinc": "河南",
        "city": "濮阳市",
        "area": "南乐县"
    }, {
        "name": "河南濮阳市范县",
        "match": "河南濮阳市范县|HNPYSFX",
        "py": "FX",
        "provinc": "河南",
        "city": "濮阳市",
        "area": "范县"
    }, {
        "name": "河南濮阳市台前县",
        "match": "河南濮阳市台前县|HNPYSTQX",
        "py": "TQX",
        "provinc": "河南",
        "city": "濮阳市",
        "area": "台前县"
    }, {
        "name": "河南濮阳市濮阳县",
        "match": "河南濮阳市濮阳县|HNPYSPYX",
        "py": "PYX",
        "provinc": "河南",
        "city": "濮阳市",
        "area": "濮阳县"
    }, {
        "name": "河南周口市",
        "match": "河南周口市|HNZKS",
        "py": "ZKS",
        "provinc": "河南",
        "city": "周口市",
        "area": "周口市"
    }, {
        "name": "河南周口市川汇区",
        "match": "河南周口市川汇区|HNZKSCHQ",
        "py": "CHQ",
        "provinc": "河南",
        "city": "周口市",
        "area": "川汇区"
    }, {
        "name": "河南周口市扶沟县",
        "match": "河南周口市扶沟县|HNZKSFGX",
        "py": "FGX",
        "provinc": "河南",
        "city": "周口市",
        "area": "扶沟县"
    }, {
        "name": "河南周口市西华县",
        "match": "河南周口市西华县|HNZKSXHX",
        "py": "XHX",
        "provinc": "河南",
        "city": "周口市",
        "area": "西华县"
    }, {
        "name": "河南周口市商水县",
        "match": "河南周口市商水县|HNZKSSSX",
        "py": "SSX",
        "provinc": "河南",
        "city": "周口市",
        "area": "商水县"
    }, {
        "name": "河南周口市沈丘县",
        "match": "河南周口市沈丘县|HNZKSSQX",
        "py": "SQX",
        "provinc": "河南",
        "city": "周口市",
        "area": "沈丘县"
    }, {
        "name": "河南周口市郸城县",
        "match": "河南周口市郸城县|HNZKSDCX",
        "py": "DCX",
        "provinc": "河南",
        "city": "周口市",
        "area": "郸城县"
    }, {
        "name": "河南周口市淮阳县",
        "match": "河南周口市淮阳县|HNZKSHYX",
        "py": "HYX",
        "provinc": "河南",
        "city": "周口市",
        "area": "淮阳县"
    }, {
        "name": "河南周口市太康县",
        "match": "河南周口市太康县|HNZKSTKX",
        "py": "TKX",
        "provinc": "河南",
        "city": "周口市",
        "area": "太康县"
    }, {
        "name": "河南周口市鹿邑县",
        "match": "河南周口市鹿邑县|HNZKSLYX",
        "py": "LYX",
        "provinc": "河南",
        "city": "周口市",
        "area": "鹿邑县"
    }, {
        "name": "河南周口市项城市",
        "match": "河南周口市项城市|HNZKSXCS",
        "py": "XCS",
        "provinc": "河南",
        "city": "周口市",
        "area": "项城市"
    }, {
        "name": "河南漯河市",
        "match": "河南漯河市|HNLHS",
        "py": "LHS",
        "provinc": "河南",
        "city": "漯河市",
        "area": "漯河市"
    }, {
        "name": "河南漯河市源汇区",
        "match": "河南漯河市源汇区|HNLHSYHQ",
        "py": "YHQ",
        "provinc": "河南",
        "city": "漯河市",
        "area": "源汇区"
    }, {
        "name": "河南漯河市郾城区",
        "match": "河南漯河市郾城区|HNLHSYCQ",
        "py": "YCQ",
        "provinc": "河南",
        "city": "漯河市",
        "area": "郾城区"
    }, {
        "name": "河南漯河市召陵区",
        "match": "河南漯河市召陵区|HNLHSZLQ",
        "py": "ZLQ",
        "provinc": "河南",
        "city": "漯河市",
        "area": "召陵区"
    }, {
        "name": "河南漯河市舞阳县",
        "match": "河南漯河市舞阳县|HNLHSWYX",
        "py": "WYX",
        "provinc": "河南",
        "city": "漯河市",
        "area": "舞阳县"
    }, {
        "name": "河南漯河市临颍县",
        "match": "河南漯河市临颍县|HNLHSLYX",
        "py": "LYX",
        "provinc": "河南",
        "city": "漯河市",
        "area": "临颍县"
    }, {
        "name": "河南驻马店市",
        "match": "河南驻马店市|HNZMDS",
        "py": "ZMDS",
        "provinc": "河南",
        "city": "驻马店市",
        "area": "驻马店市"
    }, {
        "name": "河南驻马店市驿城区",
        "match": "河南驻马店市驿城区|HNZMDSYCQ",
        "py": "YCQ",
        "provinc": "河南",
        "city": "驻马店市",
        "area": "驿城区"
    }, {
        "name": "河南驻马店市西平县",
        "match": "河南驻马店市西平县|HNZMDSXPX",
        "py": "XPX",
        "provinc": "河南",
        "city": "驻马店市",
        "area": "西平县"
    }, {
        "name": "河南驻马店市上蔡县",
        "match": "河南驻马店市上蔡县|HNZMDSSCX",
        "py": "SCX",
        "provinc": "河南",
        "city": "驻马店市",
        "area": "上蔡县"
    }, {
        "name": "河南驻马店市平舆县",
        "match": "河南驻马店市平舆县|HNZMDSPYX",
        "py": "PYX",
        "provinc": "河南",
        "city": "驻马店市",
        "area": "平舆县"
    }, {
        "name": "河南驻马店市正阳县",
        "match": "河南驻马店市正阳县|HNZMDSZYX",
        "py": "ZYX",
        "provinc": "河南",
        "city": "驻马店市",
        "area": "正阳县"
    }, {
        "name": "河南驻马店市确山县",
        "match": "河南驻马店市确山县|HNZMDSQSX",
        "py": "QSX",
        "provinc": "河南",
        "city": "驻马店市",
        "area": "确山县"
    }, {
        "name": "河南驻马店市泌阳县",
        "match": "河南驻马店市泌阳县|HNZMDSMYX",
        "py": "MYX",
        "provinc": "河南",
        "city": "驻马店市",
        "area": "泌阳县"
    }, {
        "name": "河南驻马店市汝南县",
        "match": "河南驻马店市汝南县|HNZMDSRNX",
        "py": "RNX",
        "provinc": "河南",
        "city": "驻马店市",
        "area": "汝南县"
    }, {
        "name": "河南驻马店市遂平县",
        "match": "河南驻马店市遂平县|HNZMDSSPX",
        "py": "SPX",
        "provinc": "河南",
        "city": "驻马店市",
        "area": "遂平县"
    }, {
        "name": "河南驻马店市新蔡县",
        "match": "河南驻马店市新蔡县|HNZMDSXCX",
        "py": "XCX",
        "provinc": "河南",
        "city": "驻马店市",
        "area": "新蔡县"
    }, {
        "name": "河南三门峡市",
        "match": "河南三门峡市|HNSMXS",
        "py": "SMXS",
        "provinc": "河南",
        "city": "三门峡市",
        "area": "三门峡市"
    }, {
        "name": "河南三门峡市湖滨区",
        "match": "河南三门峡市湖滨区|HNSMXSHBQ",
        "py": "HBQ",
        "provinc": "河南",
        "city": "三门峡市",
        "area": "湖滨区"
    }, {
        "name": "河南三门峡市渑池县",
        "match": "河南三门峡市渑池县|HNSMXSMCX",
        "py": "MCX",
        "provinc": "河南",
        "city": "三门峡市",
        "area": "渑池县"
    }, {
        "name": "河南三门峡市陕州区",
        "match": "河南三门峡市陕州区|HNSMXSSZQ",
        "py": "SZQ",
        "provinc": "河南",
        "city": "三门峡市",
        "area": "陕州区"
    }, {
        "name": "河南三门峡市卢氏县",
        "match": "河南三门峡市卢氏县|HNSMXSLSX",
        "py": "LSX",
        "provinc": "河南",
        "city": "三门峡市",
        "area": "卢氏县"
    }, {
        "name": "河南三门峡市义马市",
        "match": "河南三门峡市义马市|HNSMXSYMS",
        "py": "YMS",
        "provinc": "河南",
        "city": "三门峡市",
        "area": "义马市"
    }, {
        "name": "河南三门峡市灵宝市",
        "match": "河南三门峡市灵宝市|HNSMXSLBS",
        "py": "LBS",
        "provinc": "河南",
        "city": "三门峡市",
        "area": "灵宝市"
    }, {
        "name": "浙江舟山市",
        "match": "浙江舟山市|ZJZSS",
        "py": "ZSS",
        "provinc": "浙江",
        "city": "舟山市",
        "area": "舟山市"
    }, {
        "name": "浙江舟山市定海区",
        "match": "浙江舟山市定海区|ZJZSSDHQ",
        "py": "DHQ",
        "provinc": "浙江",
        "city": "舟山市",
        "area": "定海区"
    }, {
        "name": "浙江舟山市普陀区",
        "match": "浙江舟山市普陀区|ZJZSSPTQ",
        "py": "PTQ",
        "provinc": "浙江",
        "city": "舟山市",
        "area": "普陀区"
    }, {
        "name": "浙江舟山市岱山县",
        "match": "浙江舟山市岱山县|ZJZSSDSX",
        "py": "DSX",
        "provinc": "浙江",
        "city": "舟山市",
        "area": "岱山县"
    }, {
        "name": "浙江舟山市嵊泗县",
        "match": "浙江舟山市嵊泗县|ZJZSSSSX",
        "py": "SSX",
        "provinc": "浙江",
        "city": "舟山市",
        "area": "嵊泗县"
    }, {
        "name": "浙江衢州市",
        "match": "浙江衢州市|ZJQZS",
        "py": "QZS",
        "provinc": "浙江",
        "city": "衢州市",
        "area": "衢州市"
    }, {
        "name": "浙江衢州市柯城区",
        "match": "浙江衢州市柯城区|ZJQZSKCQ",
        "py": "KCQ",
        "provinc": "浙江",
        "city": "衢州市",
        "area": "柯城区"
    }, {
        "name": "浙江衢州市衢江区",
        "match": "浙江衢州市衢江区|ZJQZSQJQ",
        "py": "QJQ",
        "provinc": "浙江",
        "city": "衢州市",
        "area": "衢江区"
    }, {
        "name": "浙江衢州市常山县",
        "match": "浙江衢州市常山县|ZJQZSCSX",
        "py": "CSX",
        "provinc": "浙江",
        "city": "衢州市",
        "area": "常山县"
    }, {
        "name": "浙江衢州市开化县",
        "match": "浙江衢州市开化县|ZJQZSKHX",
        "py": "KHX",
        "provinc": "浙江",
        "city": "衢州市",
        "area": "开化县"
    }, {
        "name": "浙江衢州市龙游区",
        "match": "浙江衢州市龙游区|ZJQZSLYQ",
        "py": "LYQ",
        "provinc": "浙江",
        "city": "衢州市",
        "area": "龙游区"
    }, {
        "name": "浙江衢州市江山市",
        "match": "浙江衢州市江山市|ZJQZSJSS",
        "py": "JSS",
        "provinc": "浙江",
        "city": "衢州市",
        "area": "江山市"
    }, {
        "name": "浙江杭州市",
        "match": "浙江杭州市|ZJHZS",
        "py": "HZS",
        "provinc": "浙江",
        "city": "杭州市",
        "area": "杭州市"
    }, {
        "name": "浙江杭州市上城区",
        "match": "浙江杭州市上城区|ZJHZSSCQ",
        "py": "SCQ",
        "provinc": "浙江",
        "city": "杭州市",
        "area": "上城区"
    }, {
        "name": "浙江杭州市下城区",
        "match": "浙江杭州市下城区|ZJHZSXCQ",
        "py": "XCQ",
        "provinc": "浙江",
        "city": "杭州市",
        "area": "下城区"
    }, {
        "name": "浙江杭州市江干区",
        "match": "浙江杭州市江干区|ZJHZSJGQ",
        "py": "JGQ",
        "provinc": "浙江",
        "city": "杭州市",
        "area": "江干区"
    }, {
        "name": "浙江杭州市拱墅区",
        "match": "浙江杭州市拱墅区|ZJHZSGSQ",
        "py": "GSQ",
        "provinc": "浙江",
        "city": "杭州市",
        "area": "拱墅区"
    }, {
        "name": "浙江杭州市西湖区",
        "match": "浙江杭州市西湖区|ZJHZSXHQ",
        "py": "XHQ",
        "provinc": "浙江",
        "city": "杭州市",
        "area": "西湖区"
    }, {
        "name": "浙江杭州市滨江区",
        "match": "浙江杭州市滨江区|ZJHZSBJQ",
        "py": "BJQ",
        "provinc": "浙江",
        "city": "杭州市",
        "area": "滨江区"
    }, {
        "name": "浙江杭州市余杭区",
        "match": "浙江杭州市余杭区|ZJHZSYHQ",
        "py": "YHQ",
        "provinc": "浙江",
        "city": "杭州市",
        "area": "余杭区"
    }, {
        "name": "浙江杭州市桐庐县",
        "match": "浙江杭州市桐庐县|ZJHZSTLX",
        "py": "TLX",
        "provinc": "浙江",
        "city": "杭州市",
        "area": "桐庐县"
    }, {
        "name": "浙江杭州市淳安县",
        "match": "浙江杭州市淳安县|ZJHZSCAX",
        "py": "CAX",
        "provinc": "浙江",
        "city": "杭州市",
        "area": "淳安县"
    }, {
        "name": "浙江杭州市建德市",
        "match": "浙江杭州市建德市|ZJHZSJDS",
        "py": "JDS",
        "provinc": "浙江",
        "city": "杭州市",
        "area": "建德市"
    }, {
        "name": "浙江杭州市富阳区",
        "match": "浙江杭州市富阳区|ZJHZSFYQ",
        "py": "FYQ",
        "provinc": "浙江",
        "city": "杭州市",
        "area": "富阳区"
    }, {
        "name": "浙江杭州市临安区",
        "match": "浙江杭州市临安区|ZJHZSLAQ",
        "py": "LAQ",
        "provinc": "浙江",
        "city": "杭州市",
        "area": "临安区"
    }, {
        "name": "浙江杭州市萧山区",
        "match": "浙江杭州市萧山区|ZJHZSXSQ",
        "py": "XSQ",
        "provinc": "浙江",
        "city": "杭州市",
        "area": "萧山区"
    }, {
        "name": "浙江杭州市下沙区",
        "match": "浙江杭州市下沙区|ZJHZSXSQ",
        "py": "XSQ",
        "provinc": "浙江",
        "city": "杭州市",
        "area": "下沙区"
    }, {
        "name": "浙江湖州市",
        "match": "浙江湖州市|ZJHZS",
        "py": "HZS",
        "provinc": "浙江",
        "city": "湖州市",
        "area": "湖州市"
    }, {
        "name": "浙江湖州市吴兴区",
        "match": "浙江湖州市吴兴区|ZJHZSWXQ",
        "py": "WXQ",
        "provinc": "浙江",
        "city": "湖州市",
        "area": "吴兴区"
    }, {
        "name": "浙江湖州市南浔区",
        "match": "浙江湖州市南浔区|ZJHZSNXQ",
        "py": "NXQ",
        "provinc": "浙江",
        "city": "湖州市",
        "area": "南浔区"
    }, {
        "name": "浙江湖州市德清县",
        "match": "浙江湖州市德清县|ZJHZSDQX",
        "py": "DQX",
        "provinc": "浙江",
        "city": "湖州市",
        "area": "德清县"
    }, {
        "name": "浙江湖州市长兴县",
        "match": "浙江湖州市长兴县|ZJHZSCXX",
        "py": "CXX",
        "provinc": "浙江",
        "city": "湖州市",
        "area": "长兴县"
    }, {
        "name": "浙江湖州市安吉县",
        "match": "浙江湖州市安吉县|ZJHZSAJX",
        "py": "AJX",
        "provinc": "浙江",
        "city": "湖州市",
        "area": "安吉县"
    }, {
        "name": "浙江嘉兴市",
        "match": "浙江嘉兴市|ZJJXS",
        "py": "JXS",
        "provinc": "浙江",
        "city": "嘉兴市",
        "area": "嘉兴市"
    }, {
        "name": "浙江嘉兴市南湖区",
        "match": "浙江嘉兴市南湖区|ZJJXSNHQ",
        "py": "NHQ",
        "provinc": "浙江",
        "city": "嘉兴市",
        "area": "南湖区"
    }, {
        "name": "浙江嘉兴市秀洲区",
        "match": "浙江嘉兴市秀洲区|ZJJXSXZQ",
        "py": "XZQ",
        "provinc": "浙江",
        "city": "嘉兴市",
        "area": "秀洲区"
    }, {
        "name": "浙江嘉兴市嘉善县",
        "match": "浙江嘉兴市嘉善县|ZJJXSJSX",
        "py": "JSX",
        "provinc": "浙江",
        "city": "嘉兴市",
        "area": "嘉善县"
    }, {
        "name": "浙江嘉兴市海盐县",
        "match": "浙江嘉兴市海盐县|ZJJXSHYX",
        "py": "HYX",
        "provinc": "浙江",
        "city": "嘉兴市",
        "area": "海盐县"
    }, {
        "name": "浙江嘉兴市海宁市",
        "match": "浙江嘉兴市海宁市|ZJJXSHNS",
        "py": "HNS",
        "provinc": "浙江",
        "city": "嘉兴市",
        "area": "海宁市"
    }, {
        "name": "浙江嘉兴市平湖市",
        "match": "浙江嘉兴市平湖市|ZJJXSPHS",
        "py": "PHS",
        "provinc": "浙江",
        "city": "嘉兴市",
        "area": "平湖市"
    }, {
        "name": "浙江嘉兴市桐乡市",
        "match": "浙江嘉兴市桐乡市|ZJJXSTXS",
        "py": "TXS",
        "provinc": "浙江",
        "city": "嘉兴市",
        "area": "桐乡市"
    }, {
        "name": "浙江宁波市",
        "match": "浙江宁波市|ZJNBS",
        "py": "NBS",
        "provinc": "浙江",
        "city": "宁波市",
        "area": "宁波市"
    }, {
        "name": "浙江宁波市海曙区",
        "match": "浙江宁波市海曙区|ZJNBSHSQ",
        "py": "HSQ",
        "provinc": "浙江",
        "city": "宁波市",
        "area": "海曙区"
    }, {
        "name": "浙江宁波市江北区",
        "match": "浙江宁波市江北区|ZJNBSJBQ",
        "py": "JBQ",
        "provinc": "浙江",
        "city": "宁波市",
        "area": "江北区"
    }, {
        "name": "浙江宁波市北仑区",
        "match": "浙江宁波市北仑区|ZJNBSBLQ",
        "py": "BLQ",
        "provinc": "浙江",
        "city": "宁波市",
        "area": "北仑区"
    }, {
        "name": "浙江宁波市镇海区",
        "match": "浙江宁波市镇海区|ZJNBSZHQ",
        "py": "ZHQ",
        "provinc": "浙江",
        "city": "宁波市",
        "area": "镇海区"
    }, {
        "name": "浙江宁波市鄞州区",
        "match": "浙江宁波市鄞州区|ZJNBSYZQ",
        "py": "YZQ",
        "provinc": "浙江",
        "city": "宁波市",
        "area": "鄞州区"
    }, {
        "name": "浙江宁波市象山县",
        "match": "浙江宁波市象山县|ZJNBSXSX",
        "py": "XSX",
        "provinc": "浙江",
        "city": "宁波市",
        "area": "象山县"
    }, {
        "name": "浙江宁波市宁海县",
        "match": "浙江宁波市宁海县|ZJNBSNHX",
        "py": "NHX",
        "provinc": "浙江",
        "city": "宁波市",
        "area": "宁海县"
    }, {
        "name": "浙江宁波市余姚市",
        "match": "浙江宁波市余姚市|ZJNBSYYS",
        "py": "YYS",
        "provinc": "浙江",
        "city": "宁波市",
        "area": "余姚市"
    }, {
        "name": "浙江宁波市慈溪市",
        "match": "浙江宁波市慈溪市|ZJNBSCXS",
        "py": "CXS",
        "provinc": "浙江",
        "city": "宁波市",
        "area": "慈溪市"
    }, {
        "name": "浙江宁波市奉化区",
        "match": "浙江宁波市奉化区|ZJNBSFHQ",
        "py": "FHQ",
        "provinc": "浙江",
        "city": "宁波市",
        "area": "奉化区"
    }, {
        "name": "浙江绍兴市",
        "match": "浙江绍兴市|ZJSXS",
        "py": "SXS",
        "provinc": "浙江",
        "city": "绍兴市",
        "area": "绍兴市"
    }, {
        "name": "浙江绍兴市越城区",
        "match": "浙江绍兴市越城区|ZJSXSYCQ",
        "py": "YCQ",
        "provinc": "浙江",
        "city": "绍兴市",
        "area": "越城区"
    }, {
        "name": "浙江绍兴市绍兴县",
        "match": "浙江绍兴市绍兴县|ZJSXSSXX",
        "py": "SXX",
        "provinc": "浙江",
        "city": "绍兴市",
        "area": "绍兴县"
    }, {
        "name": "浙江绍兴市新昌县",
        "match": "浙江绍兴市新昌县|ZJSXSXCX",
        "py": "XCX",
        "provinc": "浙江",
        "city": "绍兴市",
        "area": "新昌县"
    }, {
        "name": "浙江绍兴市诸暨市",
        "match": "浙江绍兴市诸暨市|ZJSXSZJS",
        "py": "ZJS",
        "provinc": "浙江",
        "city": "绍兴市",
        "area": "诸暨市"
    }, {
        "name": "浙江绍兴市上虞区",
        "match": "浙江绍兴市上虞区|ZJSXSSYQ",
        "py": "SYQ",
        "provinc": "浙江",
        "city": "绍兴市",
        "area": "上虞区"
    }, {
        "name": "浙江绍兴市嵊州市",
        "match": "浙江绍兴市嵊州市|ZJSXSSZS",
        "py": "SZS",
        "provinc": "浙江",
        "city": "绍兴市",
        "area": "嵊州市"
    }, {
        "name": "浙江温州市",
        "match": "浙江温州市|ZJWZS",
        "py": "WZS",
        "provinc": "浙江",
        "city": "温州市",
        "area": "温州市"
    }, {
        "name": "浙江温州市鹿城区",
        "match": "浙江温州市鹿城区|ZJWZSLCQ",
        "py": "LCQ",
        "provinc": "浙江",
        "city": "温州市",
        "area": "鹿城区"
    }, {
        "name": "浙江温州市龙湾区",
        "match": "浙江温州市龙湾区|ZJWZSLWQ",
        "py": "LWQ",
        "provinc": "浙江",
        "city": "温州市",
        "area": "龙湾区"
    }, {
        "name": "浙江温州市瓯海区",
        "match": "浙江温州市瓯海区|ZJWZSOHQ",
        "py": "OHQ",
        "provinc": "浙江",
        "city": "温州市",
        "area": "瓯海区"
    }, {
        "name": "浙江温州市洞头区",
        "match": "浙江温州市洞头区|ZJWZSDTQ",
        "py": "DTQ",
        "provinc": "浙江",
        "city": "温州市",
        "area": "洞头区"
    }, {
        "name": "浙江温州市永嘉县",
        "match": "浙江温州市永嘉县|ZJWZSYJX",
        "py": "YJX",
        "provinc": "浙江",
        "city": "温州市",
        "area": "永嘉县"
    }, {
        "name": "浙江温州市平阳县",
        "match": "浙江温州市平阳县|ZJWZSPYX",
        "py": "PYX",
        "provinc": "浙江",
        "city": "温州市",
        "area": "平阳县"
    }, {
        "name": "浙江温州市苍南县",
        "match": "浙江温州市苍南县|ZJWZSCNX",
        "py": "CNX",
        "provinc": "浙江",
        "city": "温州市",
        "area": "苍南县"
    }, {
        "name": "浙江温州市文成县",
        "match": "浙江温州市文成县|ZJWZSWCX",
        "py": "WCX",
        "provinc": "浙江",
        "city": "温州市",
        "area": "文成县"
    }, {
        "name": "浙江温州市泰顺县",
        "match": "浙江温州市泰顺县|ZJWZSTSX",
        "py": "TSX",
        "provinc": "浙江",
        "city": "温州市",
        "area": "泰顺县"
    }, {
        "name": "浙江温州市瑞安市",
        "match": "浙江温州市瑞安市|ZJWZSRAS",
        "py": "RAS",
        "provinc": "浙江",
        "city": "温州市",
        "area": "瑞安市"
    }, {
        "name": "浙江温州市乐清市",
        "match": "浙江温州市乐清市|ZJWZSLQS",
        "py": "LQS",
        "provinc": "浙江",
        "city": "温州市",
        "area": "乐清市"
    }, {
        "name": "浙江丽水市",
        "match": "浙江丽水市|ZJLSS",
        "py": "LSS",
        "provinc": "浙江",
        "city": "丽水市",
        "area": "丽水市"
    }, {
        "name": "浙江丽水市莲都区",
        "match": "浙江丽水市莲都区|ZJLSSLDQ",
        "py": "LDQ",
        "provinc": "浙江",
        "city": "丽水市",
        "area": "莲都区"
    }, {
        "name": "浙江丽水市青田县",
        "match": "浙江丽水市青田县|ZJLSSQTX",
        "py": "QTX",
        "provinc": "浙江",
        "city": "丽水市",
        "area": "青田县"
    }, {
        "name": "浙江丽水市缙云县",
        "match": "浙江丽水市缙云县|ZJLSSJYX",
        "py": "JYX",
        "provinc": "浙江",
        "city": "丽水市",
        "area": "缙云县"
    }, {
        "name": "浙江丽水市遂昌县",
        "match": "浙江丽水市遂昌县|ZJLSSSCX",
        "py": "SCX",
        "provinc": "浙江",
        "city": "丽水市",
        "area": "遂昌县"
    }, {
        "name": "浙江丽水市松阳县",
        "match": "浙江丽水市松阳县|ZJLSSSYX",
        "py": "SYX",
        "provinc": "浙江",
        "city": "丽水市",
        "area": "松阳县"
    }, {
        "name": "浙江丽水市云和县",
        "match": "浙江丽水市云和县|ZJLSSYHX",
        "py": "YHX",
        "provinc": "浙江",
        "city": "丽水市",
        "area": "云和县"
    }, {
        "name": "浙江丽水市庆元县",
        "match": "浙江丽水市庆元县|ZJLSSQYX",
        "py": "QYX",
        "provinc": "浙江",
        "city": "丽水市",
        "area": "庆元县"
    }, {
        "name": "浙江丽水市景宁县",
        "match": "浙江丽水市景宁县|ZJLSSJNZZX",
        "py": "JNZZX",
        "provinc": "浙江",
        "city": "丽水市",
        "area": "景宁县"
    }, {
        "name": "浙江丽水市龙泉市",
        "match": "浙江丽水市龙泉市|ZJLSSLQS",
        "py": "LQS",
        "provinc": "浙江",
        "city": "丽水市",
        "area": "龙泉市"
    }, {
        "name": "浙江金华市",
        "match": "浙江金华市|ZJJHS",
        "py": "JHS",
        "provinc": "浙江",
        "city": "金华市",
        "area": "金华市"
    }, {
        "name": "浙江金华市婺城区",
        "match": "浙江金华市婺城区|ZJJHSWCQ",
        "py": "WCQ",
        "provinc": "浙江",
        "city": "金华市",
        "area": "婺城区"
    }, {
        "name": "浙江金华市金东区",
        "match": "浙江金华市金东区|ZJJHSJDQ",
        "py": "JDQ",
        "provinc": "浙江",
        "city": "金华市",
        "area": "金东区"
    }, {
        "name": "浙江金华市武义县",
        "match": "浙江金华市武义县|ZJJHSWYX",
        "py": "WYX",
        "provinc": "浙江",
        "city": "金华市",
        "area": "武义县"
    }, {
        "name": "浙江金华市浦江县",
        "match": "浙江金华市浦江县|ZJJHSPJX",
        "py": "PJX",
        "provinc": "浙江",
        "city": "金华市",
        "area": "浦江县"
    }, {
        "name": "浙江金华市磐安县",
        "match": "浙江金华市磐安县|ZJJHSPAX",
        "py": "PAX",
        "provinc": "浙江",
        "city": "金华市",
        "area": "磐安县"
    }, {
        "name": "浙江金华市兰溪市",
        "match": "浙江金华市兰溪市|ZJJHSLXS",
        "py": "LXS",
        "provinc": "浙江",
        "city": "金华市",
        "area": "兰溪市"
    }, {
        "name": "浙江金华市义乌市",
        "match": "浙江金华市义乌市|ZJJHSYWS",
        "py": "YWS",
        "provinc": "浙江",
        "city": "金华市",
        "area": "义乌市"
    }, {
        "name": "浙江金华市东阳市",
        "match": "浙江金华市东阳市|ZJJHSDYS",
        "py": "DYS",
        "provinc": "浙江",
        "city": "金华市",
        "area": "东阳市"
    }, {
        "name": "浙江金华市永康市",
        "match": "浙江金华市永康市|ZJJHSYKS",
        "py": "YKS",
        "provinc": "浙江",
        "city": "金华市",
        "area": "永康市"
    }, {
        "name": "浙江台州市",
        "match": "浙江台州市|ZJTZS",
        "py": "TZS",
        "provinc": "浙江",
        "city": "台州市",
        "area": "台州市"
    }, {
        "name": "浙江台州市椒江区",
        "match": "浙江台州市椒江区|ZJTZSJJQ",
        "py": "JJQ",
        "provinc": "浙江",
        "city": "台州市",
        "area": "椒江区"
    }, {
        "name": "浙江台州市黄岩区",
        "match": "浙江台州市黄岩区|ZJTZSHYQ",
        "py": "HYQ",
        "provinc": "浙江",
        "city": "台州市",
        "area": "黄岩区"
    }, {
        "name": "浙江台州市路桥区",
        "match": "浙江台州市路桥区|ZJTZSLQQ",
        "py": "LQQ",
        "provinc": "浙江",
        "city": "台州市",
        "area": "路桥区"
    }, {
        "name": "浙江台州市玉环县",
        "match": "浙江台州市玉环县|ZJTZSYHX",
        "py": "YHX",
        "provinc": "浙江",
        "city": "台州市",
        "area": "玉环县"
    }, {
        "name": "浙江台州市三门县",
        "match": "浙江台州市三门县|ZJTZSSMX",
        "py": "SMX",
        "provinc": "浙江",
        "city": "台州市",
        "area": "三门县"
    }, {
        "name": "浙江台州市天台县",
        "match": "浙江台州市天台县|ZJTZSTTX",
        "py": "TTX",
        "provinc": "浙江",
        "city": "台州市",
        "area": "天台县"
    }, {
        "name": "浙江台州市仙居县",
        "match": "浙江台州市仙居县|ZJTZSXJX",
        "py": "XJX",
        "provinc": "浙江",
        "city": "台州市",
        "area": "仙居县"
    }, {
        "name": "浙江台州市温岭市",
        "match": "浙江台州市温岭市|ZJTZSWLS",
        "py": "WLS",
        "provinc": "浙江",
        "city": "台州市",
        "area": "温岭市"
    }, {
        "name": "浙江台州市临海市",
        "match": "浙江台州市临海市|ZJTZSLHS",
        "py": "LHS",
        "provinc": "浙江",
        "city": "台州市",
        "area": "临海市"
    }, {
        "name": "海南海口市",
        "match": "海南海口市|HNHKS",
        "py": "HKS",
        "provinc": "海南",
        "city": "海口市",
        "area": "海口市"
    }, {
        "name": "海南海口市秀英区",
        "match": "海南海口市秀英区|HNHKSXYQ",
        "py": "XYQ",
        "provinc": "海南",
        "city": "海口市",
        "area": "秀英区"
    }, {
        "name": "海南海口市龙华区",
        "match": "海南海口市龙华区|HNHKSLHQ",
        "py": "LHQ",
        "provinc": "海南",
        "city": "海口市",
        "area": "龙华区"
    }, {
        "name": "海南海口市琼山区",
        "match": "海南海口市琼山区|HNHKSQSQ",
        "py": "QSQ",
        "provinc": "海南",
        "city": "海口市",
        "area": "琼山区"
    }, {
        "name": "海南海口市美兰区",
        "match": "海南海口市美兰区|HNHKSMLQ",
        "py": "MLQ",
        "provinc": "海南",
        "city": "海口市",
        "area": "美兰区"
    }, {
        "name": "海南三亚市",
        "match": "海南三亚市|HNSYS",
        "py": "SYS",
        "provinc": "海南",
        "city": "三亚市",
        "area": "三亚市"
    }, {
        "name": "海南三沙市",
        "match": "海南三沙市|HNSSS",
        "py": "SSS",
        "provinc": "海南",
        "city": "三沙市",
        "area": "三沙市"
    }, {
        "name": "海南三亚市崖州区",
        "match": "海南三亚市崖州区|HNSYSYZQ",
        "py": "YZQ",
        "provinc": "海南",
        "city": "三亚市",
        "area": "崖州区"
    }, {
        "name": "海南三亚市天涯区",
        "match": "海南三亚市天涯区|HNSYSTYQ",
        "py": "TYQ",
        "provinc": "海南",
        "city": "三亚市",
        "area": "天涯区"
    }, {
        "name": "海南三亚市吉阳区",
        "match": "海南三亚市吉阳区|HNSYSJYQ",
        "py": "JYQ",
        "provinc": "海南",
        "city": "三亚市",
        "area": "吉阳区"
    }, {
        "name": "海南三亚市海棠区",
        "match": "海南三亚市海棠区|HNSYSHTQ",
        "py": "HTQ",
        "provinc": "海南",
        "city": "三亚市",
        "area": "海棠区"
    }, {
        "name": "海南五指山市",
        "match": "海南五指山市|HNWZSS",
        "py": "WZSS",
        "provinc": "海南",
        "city": "五指山市",
        "area": "五指山市"
    }, {
        "name": "海南琼海市",
        "match": "海南琼海市|HNQHS",
        "py": "QHS",
        "provinc": "海南",
        "city": "琼海市",
        "area": "琼海市"
    }, {
        "name": "海南儋州市",
        "match": "海南儋州市|HNDZS",
        "py": "DZS",
        "provinc": "海南",
        "city": "儋州市",
        "area": "儋州市"
    }, {
        "name": "海南文昌市",
        "match": "海南文昌市|HNWCS",
        "py": "WCS",
        "provinc": "海南",
        "city": "文昌市",
        "area": "文昌市"
    }, {
        "name": "海南万宁市",
        "match": "海南万宁市|HNWNS",
        "py": "WNS",
        "provinc": "海南",
        "city": "万宁市",
        "area": "万宁市"
    }, {
        "name": "海南东方市",
        "match": "海南东方市|HNDFS",
        "py": "DFS",
        "provinc": "海南",
        "city": "东方市",
        "area": "东方市"
    }, {
        "name": "海南澄迈县",
        "match": "海南澄迈县|HNCMX",
        "py": "CMX",
        "provinc": "海南",
        "city": "澄迈县",
        "area": "澄迈县"
    }, {
        "name": "海南定安县",
        "match": "海南定安县|HNDAX",
        "py": "DAX",
        "provinc": "海南",
        "city": "定安县",
        "area": "定安县"
    }, {
        "name": "海南屯昌县",
        "match": "海南屯昌县|HNTCX",
        "py": "TCX",
        "provinc": "海南",
        "city": "屯昌县",
        "area": "屯昌县"
    }, {
        "name": "海南临高县",
        "match": "海南临高县|HNLGX",
        "py": "LGX",
        "provinc": "海南",
        "city": "临高县",
        "area": "临高县"
    }, {
        "name": "海南白沙县",
        "match": "海南白沙县|HNBSZZX",
        "py": "BSZZX",
        "provinc": "海南",
        "city": "白沙县",
        "area": "白沙县"
    }, {
        "name": "海南昌江县",
        "match": "海南昌江县|HNCJZZX",
        "py": "CJZZX",
        "provinc": "海南",
        "city": "昌江县",
        "area": "昌江县"
    }, {
        "name": "海南乐东县",
        "match": "海南乐东县|HNLDZZX",
        "py": "LDZZX",
        "provinc": "海南",
        "city": "乐东县",
        "area": "乐东县"
    }, {
        "name": "海南乐东县九所镇",
        "match": "海南乐东县九所镇|HNLDZZXJSZ",
        "py": "JSZ",
        "provinc": "海南",
        "city": "乐东县",
        "area": "九所镇"
    }, {
        "name": "海南陵水县",
        "match": "海南陵水县|HNLSZZX",
        "py": "LSZZX",
        "provinc": "海南",
        "city": "陵水县",
        "area": "陵水县"
    }, {
        "name": "海南保亭县",
        "match": "海南保亭县|HNBTZZX",
        "py": "BTZZX",
        "provinc": "海南",
        "city": "保亭县",
        "area": "保亭县"
    }, {
        "name": "海南琼中县",
        "match": "海南琼中县|HNQZZZX",
        "py": "QZZZX",
        "provinc": "海南",
        "city": "琼中县",
        "area": "琼中县"
    }, {
        "name": "湖北武汉市",
        "match": "湖北武汉市|HBWHS",
        "py": "WHS",
        "provinc": "湖北",
        "city": "武汉市",
        "area": "武汉市"
    }, {
        "name": "湖北武汉市江岸区",
        "match": "湖北武汉市江岸区|HBWHSJAQ",
        "py": "JAQ",
        "provinc": "湖北",
        "city": "武汉市",
        "area": "江岸区"
    }, {
        "name": "湖北武汉市江汉区",
        "match": "湖北武汉市江汉区|HBWHSJHQ",
        "py": "JHQ",
        "provinc": "湖北",
        "city": "武汉市",
        "area": "江汉区"
    }, {
        "name": "湖北武汉市硚口区",
        "match": "湖北武汉市硚口区|HBWHSCKQ",
        "py": "CKQ",
        "provinc": "湖北",
        "city": "武汉市",
        "area": "硚口区"
    }, {
        "name": "湖北武汉市汉阳区",
        "match": "湖北武汉市汉阳区|HBWHSHYQ",
        "py": "HYQ",
        "provinc": "湖北",
        "city": "武汉市",
        "area": "汉阳区"
    }, {
        "name": "湖北武汉市武昌区",
        "match": "湖北武汉市武昌区|HBWHSWCQ",
        "py": "WCQ",
        "provinc": "湖北",
        "city": "武汉市",
        "area": "武昌区"
    }, {
        "name": "湖北武汉市青山区",
        "match": "湖北武汉市青山区|HBWHSQSQ",
        "py": "QSQ",
        "provinc": "湖北",
        "city": "武汉市",
        "area": "青山区"
    }, {
        "name": "湖北武汉市洪山区",
        "match": "湖北武汉市洪山区|HBWHSHSQ",
        "py": "HSQ",
        "provinc": "湖北",
        "city": "武汉市",
        "area": "洪山区"
    }, {
        "name": "湖北武汉市东西湖区",
        "match": "湖北武汉市东西湖区|HBWHSDXHQ",
        "py": "DXHQ",
        "provinc": "湖北",
        "city": "武汉市",
        "area": "东西湖区"
    }, {
        "name": "湖北武汉市汉南区",
        "match": "湖北武汉市汉南区|HBWHSHNQ",
        "py": "HNQ",
        "provinc": "湖北",
        "city": "武汉市",
        "area": "汉南区"
    }, {
        "name": "湖北武汉市蔡甸区",
        "match": "湖北武汉市蔡甸区|HBWHSCDQ",
        "py": "CDQ",
        "provinc": "湖北",
        "city": "武汉市",
        "area": "蔡甸区"
    }, {
        "name": "湖北武汉市江夏区",
        "match": "湖北武汉市江夏区|HBWHSJXQ",
        "py": "JXQ",
        "provinc": "湖北",
        "city": "武汉市",
        "area": "江夏区"
    }, {
        "name": "湖北武汉市黄陂区",
        "match": "湖北武汉市黄陂区|HBWHSHPQ",
        "py": "HPQ",
        "provinc": "湖北",
        "city": "武汉市",
        "area": "黄陂区"
    }, {
        "name": "湖北武汉市新洲区",
        "match": "湖北武汉市新洲区|HBWHSXZQ",
        "py": "XZQ",
        "provinc": "湖北",
        "city": "武汉市",
        "area": "新洲区"
    }, {
        "name": "湖北襄阳市",
        "match": "湖北襄阳市|HBXYS",
        "py": "XFS",
        "provinc": "湖北",
        "city": "襄阳市",
        "area": "襄阳市"
    }, {
        "name": "湖北襄阳市襄城区",
        "match": "湖北襄阳市襄城区|HBXYSXCQ",
        "py": "XCQ",
        "provinc": "湖北",
        "city": "襄阳市",
        "area": "襄城区"
    }, {
        "name": "湖北襄阳市樊城区",
        "match": "湖北襄阳市樊城区|HBXYSFCQ",
        "py": "FCQ",
        "provinc": "湖北",
        "city": "襄阳市",
        "area": "樊城区"
    }, {
        "name": "湖北襄阳市襄州区",
        "match": "湖北襄阳市襄州区|HBXYSXZQ",
        "py": "XZQ",
        "provinc": "湖北",
        "city": "襄阳市",
        "area": "襄州区"
    }, {
        "name": "湖北襄阳市南漳县",
        "match": "湖北襄阳市南漳县|HBXYSNZX",
        "py": "NZX",
        "provinc": "湖北",
        "city": "襄阳市",
        "area": "南漳县"
    }, {
        "name": "湖北襄阳市谷城县",
        "match": "湖北襄阳市谷城县|HBXYSGCX",
        "py": "GCX",
        "provinc": "湖北",
        "city": "襄阳市",
        "area": "谷城县"
    }, {
        "name": "湖北襄阳市保康县",
        "match": "湖北襄阳市保康县|HBXYSBKX",
        "py": "BKX",
        "provinc": "湖北",
        "city": "襄阳市",
        "area": "保康县"
    }, {
        "name": "湖北襄阳市老河口市",
        "match": "湖北襄阳市老河口市|HBXYSLHKS",
        "py": "LHKS",
        "provinc": "湖北",
        "city": "襄阳市",
        "area": "老河口市"
    }, {
        "name": "湖北襄阳市枣阳市",
        "match": "湖北襄阳市枣阳市|HBXYSZYS",
        "py": "ZYS",
        "provinc": "湖北",
        "city": "襄阳市",
        "area": "枣阳市"
    }, {
        "name": "湖北襄阳市宜城市",
        "match": "湖北襄阳市宜城市|HBXYSYCS",
        "py": "YCS",
        "provinc": "湖北",
        "city": "襄阳市",
        "area": "宜城市"
    }, {
        "name": "湖北鄂州市",
        "match": "湖北鄂州市|HBEZS",
        "py": "EZS",
        "provinc": "湖北",
        "city": "鄂州市",
        "area": "鄂州市"
    }, {
        "name": "湖北鄂州市梁子湖区",
        "match": "湖北鄂州市梁子湖区|HBEZSLZHQ",
        "py": "LZHQ",
        "provinc": "湖北",
        "city": "鄂州市",
        "area": "梁子湖区"
    }, {
        "name": "湖北鄂州市华容区",
        "match": "湖北鄂州市华容区|HBEZSHRQ",
        "py": "HRQ",
        "provinc": "湖北",
        "city": "鄂州市",
        "area": "华容区"
    }, {
        "name": "湖北鄂州市鄂城区",
        "match": "湖北鄂州市鄂城区|HBEZSECQ",
        "py": "ECQ",
        "provinc": "湖北",
        "city": "鄂州市",
        "area": "鄂城区"
    }, {
        "name": "湖北孝感市",
        "match": "湖北孝感市|HBXGS",
        "py": "XGS",
        "provinc": "湖北",
        "city": "孝感市",
        "area": "孝感市"
    }, {
        "name": "湖北孝感市孝南区",
        "match": "湖北孝感市孝南区|HBXGSXNQ",
        "py": "XNQ",
        "provinc": "湖北",
        "city": "孝感市",
        "area": "孝南区"
    }, {
        "name": "湖北孝感市孝昌县",
        "match": "湖北孝感市孝昌县|HBXGSXCX",
        "py": "XCX",
        "provinc": "湖北",
        "city": "孝感市",
        "area": "孝昌县"
    }, {
        "name": "湖北孝感市大悟县",
        "match": "湖北孝感市大悟县|HBXGSDWX",
        "py": "DWX",
        "provinc": "湖北",
        "city": "孝感市",
        "area": "大悟县"
    }, {
        "name": "湖北孝感市云梦县",
        "match": "湖北孝感市云梦县|HBXGSYMX",
        "py": "YMX",
        "provinc": "湖北",
        "city": "孝感市",
        "area": "云梦县"
    }, {
        "name": "湖北孝感市应城市",
        "match": "湖北孝感市应城市|HBXGSYCS",
        "py": "YCS",
        "provinc": "湖北",
        "city": "孝感市",
        "area": "应城市"
    }, {
        "name": "湖北孝感市安陆市",
        "match": "湖北孝感市安陆市|HBXGSALS",
        "py": "ALS",
        "provinc": "湖北",
        "city": "孝感市",
        "area": "安陆市"
    }, {
        "name": "湖北孝感市汉川市",
        "match": "湖北孝感市汉川市|HBXGSHCS",
        "py": "HCS",
        "provinc": "湖北",
        "city": "孝感市",
        "area": "汉川市"
    }, {
        "name": "湖北黄冈市",
        "match": "湖北黄冈市|HBHGS",
        "py": "HGS",
        "provinc": "湖北",
        "city": "黄冈市",
        "area": "黄冈市"
    }, {
        "name": "湖北黄冈市黄州区",
        "match": "湖北黄冈市黄州区|HBHGSHZQ",
        "py": "HZQ",
        "provinc": "湖北",
        "city": "黄冈市",
        "area": "黄州区"
    }, {
        "name": "湖北黄冈市团风县",
        "match": "湖北黄冈市团风县|HBHGSTFX",
        "py": "TFX",
        "provinc": "湖北",
        "city": "黄冈市",
        "area": "团风县"
    }, {
        "name": "湖北黄冈市红安县",
        "match": "湖北黄冈市红安县|HBHGSHAX",
        "py": "HAX",
        "provinc": "湖北",
        "city": "黄冈市",
        "area": "红安县"
    }, {
        "name": "湖北黄冈市罗田县",
        "match": "湖北黄冈市罗田县|HBHGSLTX",
        "py": "LTX",
        "provinc": "湖北",
        "city": "黄冈市",
        "area": "罗田县"
    }, {
        "name": "湖北黄冈市英山县",
        "match": "湖北黄冈市英山县|HBHGSYSX",
        "py": "YSX",
        "provinc": "湖北",
        "city": "黄冈市",
        "area": "英山县"
    }, {
        "name": "湖北黄冈市浠水县",
        "match": "湖北黄冈市浠水县|HBHGSXSX",
        "py": "XSX",
        "provinc": "湖北",
        "city": "黄冈市",
        "area": "浠水县"
    }, {
        "name": "湖北黄冈市蕲春县",
        "match": "湖北黄冈市蕲春县|HBHGSQCX",
        "py": "QCX",
        "provinc": "湖北",
        "city": "黄冈市",
        "area": "蕲春县"
    }, {
        "name": "湖北黄冈市黄梅县",
        "match": "湖北黄冈市黄梅县|HBHGSHMX",
        "py": "HMX",
        "provinc": "湖北",
        "city": "黄冈市",
        "area": "黄梅县"
    }, {
        "name": "湖北黄冈市麻城市",
        "match": "湖北黄冈市麻城市|HBHGSMCS",
        "py": "MCS",
        "provinc": "湖北",
        "city": "黄冈市",
        "area": "麻城市"
    }, {
        "name": "湖北黄冈市武穴市",
        "match": "湖北黄冈市武穴市|HBHGSWXS",
        "py": "WXS",
        "provinc": "湖北",
        "city": "黄冈市",
        "area": "武穴市"
    }, {
        "name": "湖北黄石市",
        "match": "湖北黄石市|HBHSS",
        "py": "HSS",
        "provinc": "湖北",
        "city": "黄石市",
        "area": "黄石市"
    }, {
        "name": "湖北黄石市黄石港区",
        "match": "湖北黄石市黄石港区|HBHSSHSGQ",
        "py": "HSGQ",
        "provinc": "湖北",
        "city": "黄石市",
        "area": "黄石港区"
    }, {
        "name": "湖北黄石市西塞山区",
        "match": "湖北黄石市西塞山区|HBHSSXSSQ",
        "py": "XSSQ",
        "provinc": "湖北",
        "city": "黄石市",
        "area": "西塞山区"
    }, {
        "name": "湖北黄石市下陆区",
        "match": "湖北黄石市下陆区|HBHSSXLQ",
        "py": "XLQ",
        "provinc": "湖北",
        "city": "黄石市",
        "area": "下陆区"
    }, {
        "name": "湖北黄石市铁山区",
        "match": "湖北黄石市铁山区|HBHSSTSQ",
        "py": "TSQ",
        "provinc": "湖北",
        "city": "黄石市",
        "area": "铁山区"
    }, {
        "name": "湖北黄石市阳新县",
        "match": "湖北黄石市阳新县|HBHSSYXX",
        "py": "YXX",
        "provinc": "湖北",
        "city": "黄石市",
        "area": "阳新县"
    }, {
        "name": "湖北黄石市大冶市",
        "match": "湖北黄石市大冶市|HBHSSDYS",
        "py": "DYS",
        "provinc": "湖北",
        "city": "黄石市",
        "area": "大冶市"
    }, {
        "name": "湖北咸宁市",
        "match": "湖北咸宁市|HBXNS",
        "py": "XNS",
        "provinc": "湖北",
        "city": "咸宁市",
        "area": "咸宁市"
    }, {
        "name": "湖北咸宁市咸安区",
        "match": "湖北咸宁市咸安区|HBXNSXAQ",
        "py": "XAQ",
        "provinc": "湖北",
        "city": "咸宁市",
        "area": "咸安区"
    }, {
        "name": "湖北咸宁市嘉鱼县",
        "match": "湖北咸宁市嘉鱼县|HBXNSJYX",
        "py": "JYX",
        "provinc": "湖北",
        "city": "咸宁市",
        "area": "嘉鱼县"
    }, {
        "name": "湖北咸宁市通城县",
        "match": "湖北咸宁市通城县|HBXNSTCX",
        "py": "TCX",
        "provinc": "湖北",
        "city": "咸宁市",
        "area": "通城县"
    }, {
        "name": "湖北咸宁市崇阳县",
        "match": "湖北咸宁市崇阳县|HBXNSCYX",
        "py": "CYX",
        "provinc": "湖北",
        "city": "咸宁市",
        "area": "崇阳县"
    }, {
        "name": "湖北咸宁市通山县",
        "match": "湖北咸宁市通山县|HBXNSTSX",
        "py": "TSX",
        "provinc": "湖北",
        "city": "咸宁市",
        "area": "通山县"
    }, {
        "name": "湖北咸宁市赤壁市",
        "match": "湖北咸宁市赤壁市|HBXNSCBS",
        "py": "CBS",
        "provinc": "湖北",
        "city": "咸宁市",
        "area": "赤壁市"
    }, {
        "name": "湖北荆州市",
        "match": "湖北荆州市|HBJZS",
        "py": "JZS",
        "provinc": "湖北",
        "city": "荆州市",
        "area": "荆州市"
    }, {
        "name": "湖北荆州市沙市区",
        "match": "湖北荆州市沙市区|HBJZSSSQ",
        "py": "SSQ",
        "provinc": "湖北",
        "city": "荆州市",
        "area": "沙市区"
    }, {
        "name": "湖北荆州市荆州区",
        "match": "湖北荆州市荆州区|HBJZSJZQ",
        "py": "JZQ",
        "provinc": "湖北",
        "city": "荆州市",
        "area": "荆州区"
    }, {
        "name": "湖北荆州市公安县",
        "match": "湖北荆州市公安县|HBJZSGAX",
        "py": "GAX",
        "provinc": "湖北",
        "city": "荆州市",
        "area": "公安县"
    }, {
        "name": "湖北荆州市监利县",
        "match": "湖北荆州市监利县|HBJZSJLX",
        "py": "JLX",
        "provinc": "湖北",
        "city": "荆州市",
        "area": "监利县"
    }, {
        "name": "湖北荆州市江陵县",
        "match": "湖北荆州市江陵县|HBJZSJLX",
        "py": "JLX",
        "provinc": "湖北",
        "city": "荆州市",
        "area": "江陵县"
    }, {
        "name": "湖北荆州市石首市",
        "match": "湖北荆州市石首市|HBJZSSSS",
        "py": "SSS",
        "provinc": "湖北",
        "city": "荆州市",
        "area": "石首市"
    }, {
        "name": "湖北荆州市洪湖市",
        "match": "湖北荆州市洪湖市|HBJZSHHS",
        "py": "HHS",
        "provinc": "湖北",
        "city": "荆州市",
        "area": "洪湖市"
    }, {
        "name": "湖北荆州市松滋市",
        "match": "湖北荆州市松滋市|HBJZSSZS",
        "py": "SZS",
        "provinc": "湖北",
        "city": "荆州市",
        "area": "松滋市"
    }, {
        "name": "湖北宜昌市",
        "match": "湖北宜昌市|HBYCS",
        "py": "YCS",
        "provinc": "湖北",
        "city": "宜昌市",
        "area": "宜昌市"
    }, {
        "name": "湖北宜昌市西陵区",
        "match": "湖北宜昌市西陵区|HBYCSXLQ",
        "py": "XLQ",
        "provinc": "湖北",
        "city": "宜昌市",
        "area": "西陵区"
    }, {
        "name": "湖北宜昌市伍家岗区",
        "match": "湖北宜昌市伍家岗区|HBYCSWJGQ",
        "py": "WJGQ",
        "provinc": "湖北",
        "city": "宜昌市",
        "area": "伍家岗区"
    }, {
        "name": "湖北宜昌市点军区",
        "match": "湖北宜昌市点军区|HBYCSDJQ",
        "py": "DJQ",
        "provinc": "湖北",
        "city": "宜昌市",
        "area": "点军区"
    }, {
        "name": "湖北宜昌市猇亭区",
        "match": "湖北宜昌市猇亭区|HBYCSXTQ",
        "py": "XTQ",
        "provinc": "湖北",
        "city": "宜昌市",
        "area": "猇亭区"
    }, {
        "name": "湖北宜昌市夷陵区",
        "match": "湖北宜昌市夷陵区|HBYCSYLQ",
        "py": "YLQ",
        "provinc": "湖北",
        "city": "宜昌市",
        "area": "夷陵区"
    }, {
        "name": "湖北宜昌市远安县",
        "match": "湖北宜昌市远安县|HBYCSYAX",
        "py": "YAX",
        "provinc": "湖北",
        "city": "宜昌市",
        "area": "远安县"
    }, {
        "name": "湖北宜昌市兴山县",
        "match": "湖北宜昌市兴山县|HBYCSXSX",
        "py": "XSX",
        "provinc": "湖北",
        "city": "宜昌市",
        "area": "兴山县"
    }, {
        "name": "湖北宜昌市秭归县",
        "match": "湖北宜昌市秭归县|HBYCSZGX",
        "py": "ZGX",
        "provinc": "湖北",
        "city": "宜昌市",
        "area": "秭归县"
    }, {
        "name": "湖北宜昌市长阳县",
        "match": "湖北宜昌市长阳县|HBYCSCYZZX",
        "py": "CYZZX",
        "provinc": "湖北",
        "city": "宜昌市",
        "area": "长阳县"
    }, {
        "name": "湖北宜昌市五峰县",
        "match": "湖北宜昌市五峰县|HBYCSWFZZX",
        "py": "WFZZX",
        "provinc": "湖北",
        "city": "宜昌市",
        "area": "五峰县"
    }, {
        "name": "湖北宜昌市宜都市",
        "match": "湖北宜昌市宜都市|HBYCSYDS",
        "py": "YDS",
        "provinc": "湖北",
        "city": "宜昌市",
        "area": "宜都市"
    }, {
        "name": "湖北宜昌市当阳市",
        "match": "湖北宜昌市当阳市|HBYCSDYS",
        "py": "DYS",
        "provinc": "湖北",
        "city": "宜昌市",
        "area": "当阳市"
    }, {
        "name": "湖北宜昌市枝江市",
        "match": "湖北宜昌市枝江市|HBYCSZJS",
        "py": "ZJS",
        "provinc": "湖北",
        "city": "宜昌市",
        "area": "枝江市"
    }, {
        "name": "湖北恩施州",
        "match": "湖北恩施州|HBESZZZ",
        "py": "ESZZZ",
        "provinc": "湖北",
        "city": "恩施州",
        "area": "恩施州"
    }, {
        "name": "湖北恩施州恩施市",
        "match": "湖北恩施州恩施市|HBESZZZESS",
        "py": "ESS",
        "provinc": "湖北",
        "city": "恩施州",
        "area": "恩施市"
    }, {
        "name": "湖北恩施州利川市",
        "match": "湖北恩施州利川市|HBESZZZLCS",
        "py": "LCS",
        "provinc": "湖北",
        "city": "恩施州",
        "area": "利川市"
    }, {
        "name": "湖北恩施州建始县",
        "match": "湖北恩施州建始县|HBESZZZJSX",
        "py": "JSX",
        "provinc": "湖北",
        "city": "恩施州",
        "area": "建始县"
    }, {
        "name": "湖北恩施州巴东县",
        "match": "湖北恩施州巴东县|HBESZZZBDX",
        "py": "BDX",
        "provinc": "湖北",
        "city": "恩施州",
        "area": "巴东县"
    }, {
        "name": "湖北恩施州宣恩县",
        "match": "湖北恩施州宣恩县|HBESZZZXEX",
        "py": "XEX",
        "provinc": "湖北",
        "city": "恩施州",
        "area": "宣恩县"
    }, {
        "name": "湖北恩施州咸丰县",
        "match": "湖北恩施州咸丰县|HBESZZZXFX",
        "py": "XFX",
        "provinc": "湖北",
        "city": "恩施州",
        "area": "咸丰县"
    }, {
        "name": "湖北恩施州来凤县",
        "match": "湖北恩施州来凤县|HBESZZZLFX",
        "py": "LFX",
        "provinc": "湖北",
        "city": "恩施州",
        "area": "来凤县"
    }, {
        "name": "湖北恩施州鹤峰县",
        "match": "湖北恩施州鹤峰县|HBESZZZHFX",
        "py": "HFX",
        "provinc": "湖北",
        "city": "恩施州",
        "area": "鹤峰县"
    }, {
        "name": "湖北神农架林区",
        "match": "湖北神农架林区|HBSNJQ",
        "py": "SNJQ",
        "provinc": "湖北",
        "city": "神农架林区",
        "area": "神农架林区"
    }, {
        "name": "湖北十堰市",
        "match": "湖北十堰市|HBSYS",
        "py": "SYS",
        "provinc": "湖北",
        "city": "十堰市",
        "area": "十堰市"
    }, {
        "name": "湖北十堰市茅箭区",
        "match": "湖北十堰市茅箭区|HBSYSMJQ",
        "py": "MJQ",
        "provinc": "湖北",
        "city": "十堰市",
        "area": "茅箭区"
    }, {
        "name": "湖北十堰市张湾区",
        "match": "湖北十堰市张湾区|HBSYSZWQ",
        "py": "ZWQ",
        "provinc": "湖北",
        "city": "十堰市",
        "area": "张湾区"
    }, {
        "name": "湖北十堰市郧阳区",
        "match": "湖北十堰市郧阳区|HBSYSYYX",
        "py": "YYX",
        "provinc": "湖北",
        "city": "十堰市",
        "area": "郧阳区"
    }, {
        "name": "湖北十堰市郧西县",
        "match": "湖北十堰市郧西县|HBSYSYXX",
        "py": "YXX",
        "provinc": "湖北",
        "city": "十堰市",
        "area": "郧西县"
    }, {
        "name": "湖北十堰市竹山县",
        "match": "湖北十堰市竹山县|HBSYSZSX",
        "py": "ZSX",
        "provinc": "湖北",
        "city": "十堰市",
        "area": "竹山县"
    }, {
        "name": "湖北十堰市竹溪县",
        "match": "湖北十堰市竹溪县|HBSYSZXX",
        "py": "ZXX",
        "provinc": "湖北",
        "city": "十堰市",
        "area": "竹溪县"
    }, {
        "name": "湖北十堰市房县",
        "match": "湖北十堰市房县|HBSYSFX",
        "py": "FX",
        "provinc": "湖北",
        "city": "十堰市",
        "area": "房县"
    }, {
        "name": "湖北十堰市丹江口市",
        "match": "湖北十堰市丹江口市|HBSYSDJKS",
        "py": "DJKS",
        "provinc": "湖北",
        "city": "十堰市",
        "area": "丹江口市"
    }, {
        "name": "湖北随州市",
        "match": "湖北随州市|HBSZS",
        "py": "SZS",
        "provinc": "湖北",
        "city": "随州市",
        "area": "随州市"
    }, {
        "name": "湖北随州市曾都区",
        "match": "湖北随州市曾都区|HBSZSZDQ",
        "py": "ZDQ",
        "provinc": "湖北",
        "city": "随州市",
        "area": "曾都区"
    }, {
        "name": "湖北随州市广水市",
        "match": "湖北随州市广水市|HBSZSGSS",
        "py": "GSS",
        "provinc": "湖北",
        "city": "随州市",
        "area": "广水市"
    }, {
        "name": "湖北随州市随县",
        "match": "湖北随州市随县|HBSZSSX",
        "py": "SX",
        "provinc": "湖北",
        "city": "随州市",
        "area": "随县"
    }, {
        "name": "湖北荆门市",
        "match": "湖北荆门市|HBJMS",
        "py": "JMS",
        "provinc": "湖北",
        "city": "荆门市",
        "area": "荆门市"
    }, {
        "name": "湖北荆门市东宝区",
        "match": "湖北荆门市东宝区|HBJMSDBQ",
        "py": "DBQ",
        "provinc": "湖北",
        "city": "荆门市",
        "area": "东宝区"
    }, {
        "name": "湖北荆门市掇刀区",
        "match": "湖北荆门市掇刀区|HBJMSDDQ",
        "py": "DDQ",
        "provinc": "湖北",
        "city": "荆门市",
        "area": "掇刀区"
    }, {
        "name": "湖北荆门市京山县",
        "match": "湖北荆门市京山县|HBJMSJSX",
        "py": "JSX",
        "provinc": "湖北",
        "city": "荆门市",
        "area": "京山县"
    }, {
        "name": "湖北荆门市沙洋县",
        "match": "湖北荆门市沙洋县|HBJMSSYX",
        "py": "SYX",
        "provinc": "湖北",
        "city": "荆门市",
        "area": "沙洋县"
    }, {
        "name": "湖北荆门市钟祥市",
        "match": "湖北荆门市钟祥市|HBJMSZXS",
        "py": "ZXS",
        "provinc": "湖北",
        "city": "荆门市",
        "area": "钟祥市"
    }, {
        "name": "湖北仙桃市",
        "match": "湖北仙桃市|HBXTS",
        "py": "XTS",
        "provinc": "湖北",
        "city": "仙桃市",
        "area": "仙桃市"
    }, {
        "name": "湖北天门市",
        "match": "湖北天门市|HBTMS",
        "py": "TMS",
        "provinc": "湖北",
        "city": "天门市",
        "area": "天门市"
    }, {
        "name": "湖北潜江市",
        "match": "湖北潜江市|HBQJS",
        "py": "QJS",
        "provinc": "湖北",
        "city": "潜江市",
        "area": "潜江市"
    }, {
        "name": "湖南岳阳市",
        "match": "湖南岳阳市|HNYYS",
        "py": "YYS",
        "provinc": "湖南",
        "city": "岳阳市",
        "area": "岳阳市"
    }, {
        "name": "湖南岳阳市岳阳楼区",
        "match": "湖南岳阳市岳阳楼区|HNYYSYYLQ",
        "py": "YYLQ",
        "provinc": "湖南",
        "city": "岳阳市",
        "area": "岳阳楼区"
    }, {
        "name": "湖南岳阳市云溪区",
        "match": "湖南岳阳市云溪区|HNYYSYXQ",
        "py": "YXQ",
        "provinc": "湖南",
        "city": "岳阳市",
        "area": "云溪区"
    }, {
        "name": "湖南岳阳市君山区",
        "match": "湖南岳阳市君山区|HNYYSJSQ",
        "py": "JSQ",
        "provinc": "湖南",
        "city": "岳阳市",
        "area": "君山区"
    }, {
        "name": "湖南岳阳市岳阳县",
        "match": "湖南岳阳市岳阳县|HNYYSYYX",
        "py": "YYX",
        "provinc": "湖南",
        "city": "岳阳市",
        "area": "岳阳县"
    }, {
        "name": "湖南岳阳市华容县",
        "match": "湖南岳阳市华容县|HNYYSHRX",
        "py": "HRX",
        "provinc": "湖南",
        "city": "岳阳市",
        "area": "华容县"
    }, {
        "name": "湖南岳阳市湘阴县",
        "match": "湖南岳阳市湘阴县|HNYYSXYX",
        "py": "XYX",
        "provinc": "湖南",
        "city": "岳阳市",
        "area": "湘阴县"
    }, {
        "name": "湖南岳阳市平江县",
        "match": "湖南岳阳市平江县|HNYYSPJX",
        "py": "PJX",
        "provinc": "湖南",
        "city": "岳阳市",
        "area": "平江县"
    }, {
        "name": "湖南岳阳市汨罗市",
        "match": "湖南岳阳市汨罗市|HNYYSMLS",
        "py": "MLS",
        "provinc": "湖南",
        "city": "岳阳市",
        "area": "汨罗市"
    }, {
        "name": "湖南岳阳市临湘市",
        "match": "湖南岳阳市临湘市|HNYYSLXS",
        "py": "LXS",
        "provinc": "湖南",
        "city": "岳阳市",
        "area": "临湘市"
    }, {
        "name": "湖南长沙市",
        "match": "湖南长沙市|HNCSS",
        "py": "CSS",
        "provinc": "湖南",
        "city": "长沙市",
        "area": "长沙市"
    }, {
        "name": "湖南长沙市芙蓉区",
        "match": "湖南长沙市芙蓉区|HNCSSFRQ",
        "py": "FRQ",
        "provinc": "湖南",
        "city": "长沙市",
        "area": "芙蓉区"
    }, {
        "name": "湖南长沙市天心区",
        "match": "湖南长沙市天心区|HNCSSTXQ",
        "py": "TXQ",
        "provinc": "湖南",
        "city": "长沙市",
        "area": "天心区"
    }, {
        "name": "湖南长沙市岳麓区",
        "match": "湖南长沙市岳麓区|HNCSSYLQ",
        "py": "YLQ",
        "provinc": "湖南",
        "city": "长沙市",
        "area": "岳麓区"
    }, {
        "name": "湖南长沙市开福区",
        "match": "湖南长沙市开福区|HNCSSKFQ",
        "py": "KFQ",
        "provinc": "湖南",
        "city": "长沙市",
        "area": "开福区"
    }, {
        "name": "湖南长沙市雨花区",
        "match": "湖南长沙市雨花区|HNCSSYHQ",
        "py": "YHQ",
        "provinc": "湖南",
        "city": "长沙市",
        "area": "雨花区"
    }, {
        "name": "湖南长沙市长沙县",
        "match": "湖南长沙市长沙县|HNCSSCSX",
        "py": "CSX",
        "provinc": "湖南",
        "city": "长沙市",
        "area": "长沙县"
    }, {
        "name": "湖南长沙市望城区",
        "match": "湖南长沙市望城区|HNCSSWCQ",
        "py": "WCQ",
        "provinc": "湖南",
        "city": "长沙市",
        "area": "望城区"
    }, {
        "name": "湖南长沙市宁乡市",
        "match": "湖南长沙市宁乡市|HNCSSNXS",
        "py": "NXS",
        "provinc": "湖南",
        "city": "长沙市",
        "area": "宁乡市"
    }, {
        "name": "湖南长沙市浏阳市",
        "match": "湖南长沙市浏阳市|HNCSSLYS",
        "py": "LYS",
        "provinc": "湖南",
        "city": "长沙市",
        "area": "浏阳市"
    }, {
        "name": "湖南湘潭市",
        "match": "湖南湘潭市|HNXTS",
        "py": "XTS",
        "provinc": "湖南",
        "city": "湘潭市",
        "area": "湘潭市"
    }, {
        "name": "湖南湘潭市雨湖区",
        "match": "湖南湘潭市雨湖区|HNXTSYHQ",
        "py": "YHQ",
        "provinc": "湖南",
        "city": "湘潭市",
        "area": "雨湖区"
    }, {
        "name": "湖南湘潭市岳塘区",
        "match": "湖南湘潭市岳塘区|HNXTSYTQ",
        "py": "YTQ",
        "provinc": "湖南",
        "city": "湘潭市",
        "area": "岳塘区"
    }, {
        "name": "湖南湘潭市湘潭县",
        "match": "湖南湘潭市湘潭县|HNXTSXTX",
        "py": "XTX",
        "provinc": "湖南",
        "city": "湘潭市",
        "area": "湘潭县"
    }, {
        "name": "湖南湘潭市湘乡市",
        "match": "湖南湘潭市湘乡市|HNXTSXXS",
        "py": "XXS",
        "provinc": "湖南",
        "city": "湘潭市",
        "area": "湘乡市"
    }, {
        "name": "湖南湘潭市韶山市",
        "match": "湖南湘潭市韶山市|HNXTSSSS",
        "py": "SSS",
        "provinc": "湖南",
        "city": "湘潭市",
        "area": "韶山市"
    }, {
        "name": "湖南株洲市",
        "match": "湖南株洲市|HNZZS",
        "py": "ZZS",
        "provinc": "湖南",
        "city": "株洲市",
        "area": "株洲市"
    }, {
        "name": "湖南株洲市荷塘区",
        "match": "湖南株洲市荷塘区|HNZZSHTQ",
        "py": "HTQ",
        "provinc": "湖南",
        "city": "株洲市",
        "area": "荷塘区"
    }, {
        "name": "湖南株洲市芦淞区",
        "match": "湖南株洲市芦淞区|HNZZSLSQ",
        "py": "LSQ",
        "provinc": "湖南",
        "city": "株洲市",
        "area": "芦淞区"
    }, {
        "name": "湖南株洲市石峰区",
        "match": "湖南株洲市石峰区|HNZZSSFQ",
        "py": "SFQ",
        "provinc": "湖南",
        "city": "株洲市",
        "area": "石峰区"
    }, {
        "name": "湖南株洲市天元区",
        "match": "湖南株洲市天元区|HNZZSTYQ",
        "py": "TYQ",
        "provinc": "湖南",
        "city": "株洲市",
        "area": "天元区"
    }, {
        "name": "湖南株洲市株洲县",
        "match": "湖南株洲市株洲县|HNZZSZZX",
        "py": "ZZX",
        "provinc": "湖南",
        "city": "株洲市",
        "area": "株洲县"
    }, {
        "name": "湖南株洲市攸县",
        "match": "湖南株洲市攸县|HNZZSYX",
        "py": "YX",
        "provinc": "湖南",
        "city": "株洲市",
        "area": "攸县"
    }, {
        "name": "湖南株洲市茶陵县",
        "match": "湖南株洲市茶陵县|HNZZSCLX",
        "py": "CLX",
        "provinc": "湖南",
        "city": "株洲市",
        "area": "茶陵县"
    }, {
        "name": "湖南株洲市炎陵县",
        "match": "湖南株洲市炎陵县|HNZZSYLX",
        "py": "YLX",
        "provinc": "湖南",
        "city": "株洲市",
        "area": "炎陵县"
    }, {
        "name": "湖南株洲市醴陵市",
        "match": "湖南株洲市醴陵市|HNZZSLLS",
        "py": "LLS",
        "provinc": "湖南",
        "city": "株洲市",
        "area": "醴陵市"
    }, {
        "name": "湖南衡阳市",
        "match": "湖南衡阳市|HNHYS",
        "py": "HYS",
        "provinc": "湖南",
        "city": "衡阳市",
        "area": "衡阳市"
    }, {
        "name": "湖南衡阳市珠晖区",
        "match": "湖南衡阳市珠晖区|HNHYSZHQ",
        "py": "ZHQ",
        "provinc": "湖南",
        "city": "衡阳市",
        "area": "珠晖区"
    }, {
        "name": "湖南衡阳市雁峰区",
        "match": "湖南衡阳市雁峰区|HNHYSYFQ",
        "py": "YFQ",
        "provinc": "湖南",
        "city": "衡阳市",
        "area": "雁峰区"
    }, {
        "name": "湖南衡阳市石鼓区",
        "match": "湖南衡阳市石鼓区|HNHYSSGQ",
        "py": "SGQ",
        "provinc": "湖南",
        "city": "衡阳市",
        "area": "石鼓区"
    }, {
        "name": "湖南衡阳市蒸湘区",
        "match": "湖南衡阳市蒸湘区|HNHYSZXQ",
        "py": "ZXQ",
        "provinc": "湖南",
        "city": "衡阳市",
        "area": "蒸湘区"
    }, {
        "name": "湖南衡阳市南岳区",
        "match": "湖南衡阳市南岳区|HNHYSNYQ",
        "py": "NYQ",
        "provinc": "湖南",
        "city": "衡阳市",
        "area": "南岳区"
    }, {
        "name": "湖南衡阳市衡阳县",
        "match": "湖南衡阳市衡阳县|HNHYSHYX",
        "py": "HYX",
        "provinc": "湖南",
        "city": "衡阳市",
        "area": "衡阳县"
    }, {
        "name": "湖南衡阳市衡南县",
        "match": "湖南衡阳市衡南县|HNHYSHNX",
        "py": "HNX",
        "provinc": "湖南",
        "city": "衡阳市",
        "area": "衡南县"
    }, {
        "name": "湖南衡阳市衡山县",
        "match": "湖南衡阳市衡山县|HNHYSHSX",
        "py": "HSX",
        "provinc": "湖南",
        "city": "衡阳市",
        "area": "衡山县"
    }, {
        "name": "湖南衡阳市衡东县",
        "match": "湖南衡阳市衡东县|HNHYSHDX",
        "py": "HDX",
        "provinc": "湖南",
        "city": "衡阳市",
        "area": "衡东县"
    }, {
        "name": "湖南衡阳市祁东县",
        "match": "湖南衡阳市祁东县|HNHYSQDX",
        "py": "QDX",
        "provinc": "湖南",
        "city": "衡阳市",
        "area": "祁东县"
    }, {
        "name": "湖南衡阳市耒阳市",
        "match": "湖南衡阳市耒阳市|HNHYSLYS",
        "py": "LYS",
        "provinc": "湖南",
        "city": "衡阳市",
        "area": "耒阳市"
    }, {
        "name": "湖南衡阳市常宁市",
        "match": "湖南衡阳市常宁市|HNHYSCNS",
        "py": "CNS",
        "provinc": "湖南",
        "city": "衡阳市",
        "area": "常宁市"
    }, {
        "name": "湖南郴州市",
        "match": "湖南郴州市|HNCZS",
        "py": "CZS",
        "provinc": "湖南",
        "city": "郴州市",
        "area": "郴州市"
    }, {
        "name": "湖南郴州市北湖区",
        "match": "湖南郴州市北湖区|HNCZSBHQ",
        "py": "BHQ",
        "provinc": "湖南",
        "city": "郴州市",
        "area": "北湖区"
    }, {
        "name": "湖南郴州市苏仙区",
        "match": "湖南郴州市苏仙区|HNCZSSXQ",
        "py": "SXQ",
        "provinc": "湖南",
        "city": "郴州市",
        "area": "苏仙区"
    }, {
        "name": "湖南郴州市桂阳县",
        "match": "湖南郴州市桂阳县|HNCZSGYX",
        "py": "GYX",
        "provinc": "湖南",
        "city": "郴州市",
        "area": "桂阳县"
    }, {
        "name": "湖南郴州市宜章县",
        "match": "湖南郴州市宜章县|HNCZSYZX",
        "py": "YZX",
        "provinc": "湖南",
        "city": "郴州市",
        "area": "宜章县"
    }, {
        "name": "湖南郴州市永兴县",
        "match": "湖南郴州市永兴县|HNCZSYXX",
        "py": "YXX",
        "provinc": "湖南",
        "city": "郴州市",
        "area": "永兴县"
    }, {
        "name": "湖南郴州市嘉禾县",
        "match": "湖南郴州市嘉禾县|HNCZSJHX",
        "py": "JHX",
        "provinc": "湖南",
        "city": "郴州市",
        "area": "嘉禾县"
    }, {
        "name": "湖南郴州市临武县",
        "match": "湖南郴州市临武县|HNCZSLWX",
        "py": "LWX",
        "provinc": "湖南",
        "city": "郴州市",
        "area": "临武县"
    }, {
        "name": "湖南郴州市汝城县",
        "match": "湖南郴州市汝城县|HNCZSRCX",
        "py": "RCX",
        "provinc": "湖南",
        "city": "郴州市",
        "area": "汝城县"
    }, {
        "name": "湖南郴州市桂东县",
        "match": "湖南郴州市桂东县|HNCZSGDX",
        "py": "GDX",
        "provinc": "湖南",
        "city": "郴州市",
        "area": "桂东县"
    }, {
        "name": "湖南郴州市安仁县",
        "match": "湖南郴州市安仁县|HNCZSARX",
        "py": "ARX",
        "provinc": "湖南",
        "city": "郴州市",
        "area": "安仁县"
    }, {
        "name": "湖南郴州市资兴市",
        "match": "湖南郴州市资兴市|HNCZSZXS",
        "py": "ZXS",
        "provinc": "湖南",
        "city": "郴州市",
        "area": "资兴市"
    }, {
        "name": "湖南常德市",
        "match": "湖南常德市|HNCDS",
        "py": "CDS",
        "provinc": "湖南",
        "city": "常德市",
        "area": "常德市"
    }, {
        "name": "湖南常德市武陵区",
        "match": "湖南常德市武陵区|HNCDSWLQ",
        "py": "WLQ",
        "provinc": "湖南",
        "city": "常德市",
        "area": "武陵区"
    }, {
        "name": "湖南常德市鼎城区",
        "match": "湖南常德市鼎城区|HNCDSDCQ",
        "py": "DCQ",
        "provinc": "湖南",
        "city": "常德市",
        "area": "鼎城区"
    }, {
        "name": "湖南常德市安乡县",
        "match": "湖南常德市安乡县|HNCDSAXX",
        "py": "AXX",
        "provinc": "湖南",
        "city": "常德市",
        "area": "安乡县"
    }, {
        "name": "湖南常德市汉寿县",
        "match": "湖南常德市汉寿县|HNCDSHSX",
        "py": "HSX",
        "provinc": "湖南",
        "city": "常德市",
        "area": "汉寿县"
    }, {
        "name": "湖南常德市澧县",
        "match": "湖南常德市澧县|HNCDSLX",
        "py": "LX",
        "provinc": "湖南",
        "city": "常德市",
        "area": "澧县"
    }, {
        "name": "湖南常德市临澧县",
        "match": "湖南常德市临澧县|HNCDSLLX",
        "py": "LLX",
        "provinc": "湖南",
        "city": "常德市",
        "area": "临澧县"
    }, {
        "name": "湖南常德市桃源县",
        "match": "湖南常德市桃源县|HNCDSTYX",
        "py": "TYX",
        "provinc": "湖南",
        "city": "常德市",
        "area": "桃源县"
    }, {
        "name": "湖南常德市石门县",
        "match": "湖南常德市石门县|HNCDSSMX",
        "py": "SMX",
        "provinc": "湖南",
        "city": "常德市",
        "area": "石门县"
    }, {
        "name": "湖南常德市津市市",
        "match": "湖南常德市津市市|HNCDSJSS",
        "py": "JSS",
        "provinc": "湖南",
        "city": "常德市",
        "area": "津市市"
    }, {
        "name": "湖南益阳市",
        "match": "湖南益阳市|HNYYS",
        "py": "YYS",
        "provinc": "湖南",
        "city": "益阳市",
        "area": "益阳市"
    }, {
        "name": "湖南益阳市资阳区",
        "match": "湖南益阳市资阳区|HNYYSZYQ",
        "py": "ZYQ",
        "provinc": "湖南",
        "city": "益阳市",
        "area": "资阳区"
    }, {
        "name": "湖南益阳市赫山区",
        "match": "湖南益阳市赫山区|HNYYSHSQ",
        "py": "HSQ",
        "provinc": "湖南",
        "city": "益阳市",
        "area": "赫山区"
    }, {
        "name": "湖南益阳市南县",
        "match": "湖南益阳市南县|HNYYSNX",
        "py": "NX",
        "provinc": "湖南",
        "city": "益阳市",
        "area": "南县"
    }, {
        "name": "湖南益阳市桃江县",
        "match": "湖南益阳市桃江县|HNYYSTJX",
        "py": "TJX",
        "provinc": "湖南",
        "city": "益阳市",
        "area": "桃江县"
    }, {
        "name": "湖南益阳市安化县",
        "match": "湖南益阳市安化县|HNYYSAHX",
        "py": "AHX",
        "provinc": "湖南",
        "city": "益阳市",
        "area": "安化县"
    }, {
        "name": "湖南益阳市沅江市",
        "match": "湖南益阳市沅江市|HNYYSYJS",
        "py": "YJS",
        "provinc": "湖南",
        "city": "益阳市",
        "area": "沅江市"
    }, {
        "name": "湖南娄底市",
        "match": "湖南娄底市|HNLDS",
        "py": "LDS",
        "provinc": "湖南",
        "city": "娄底市",
        "area": "娄底市"
    }, {
        "name": "湖南娄底市娄星区",
        "match": "湖南娄底市娄星区|HNLDSLXQ",
        "py": "LXQ",
        "provinc": "湖南",
        "city": "娄底市",
        "area": "娄星区"
    }, {
        "name": "湖南娄底市双峰县",
        "match": "湖南娄底市双峰县|HNLDSSFX",
        "py": "SFX",
        "provinc": "湖南",
        "city": "娄底市",
        "area": "双峰县"
    }, {
        "name": "湖南娄底市新化县",
        "match": "湖南娄底市新化县|HNLDSXHX",
        "py": "XHX",
        "provinc": "湖南",
        "city": "娄底市",
        "area": "新化县"
    }, {
        "name": "湖南娄底市冷水江市",
        "match": "湖南娄底市冷水江市|HNLDSLSJS",
        "py": "LSJS",
        "provinc": "湖南",
        "city": "娄底市",
        "area": "冷水江市"
    }, {
        "name": "湖南娄底市涟源市",
        "match": "湖南娄底市涟源市|HNLDSLYS",
        "py": "LYS",
        "provinc": "湖南",
        "city": "娄底市",
        "area": "涟源市"
    }, {
        "name": "湖南邵阳市",
        "match": "湖南邵阳市|HNSYS",
        "py": "SYS",
        "provinc": "湖南",
        "city": "邵阳市",
        "area": "邵阳市"
    }, {
        "name": "湖南邵阳市双清区",
        "match": "湖南邵阳市双清区|HNSYSSQQ",
        "py": "SQQ",
        "provinc": "湖南",
        "city": "邵阳市",
        "area": "双清区"
    }, {
        "name": "湖南邵阳市大祥区",
        "match": "湖南邵阳市大祥区|HNSYSDXQ",
        "py": "DXQ",
        "provinc": "湖南",
        "city": "邵阳市",
        "area": "大祥区"
    }, {
        "name": "湖南邵阳市北塔区",
        "match": "湖南邵阳市北塔区|HNSYSBTQ",
        "py": "BTQ",
        "provinc": "湖南",
        "city": "邵阳市",
        "area": "北塔区"
    }, {
        "name": "湖南邵阳市邵东县",
        "match": "湖南邵阳市邵东县|HNSYSSDX",
        "py": "SDX",
        "provinc": "湖南",
        "city": "邵阳市",
        "area": "邵东县"
    }, {
        "name": "湖南邵阳市新邵县",
        "match": "湖南邵阳市新邵县|HNSYSXSX",
        "py": "XSX",
        "provinc": "湖南",
        "city": "邵阳市",
        "area": "新邵县"
    }, {
        "name": "湖南邵阳市邵阳县",
        "match": "湖南邵阳市邵阳县|HNSYSSYX",
        "py": "SYX",
        "provinc": "湖南",
        "city": "邵阳市",
        "area": "邵阳县"
    }, {
        "name": "湖南邵阳市隆回县",
        "match": "湖南邵阳市隆回县|HNSYSLHX",
        "py": "LHX",
        "provinc": "湖南",
        "city": "邵阳市",
        "area": "隆回县"
    }, {
        "name": "湖南邵阳市洞口县",
        "match": "湖南邵阳市洞口县|HNSYSDKX",
        "py": "DKX",
        "provinc": "湖南",
        "city": "邵阳市",
        "area": "洞口县"
    }, {
        "name": "湖南邵阳市绥宁县",
        "match": "湖南邵阳市绥宁县|HNSYSSNX",
        "py": "SNX",
        "provinc": "湖南",
        "city": "邵阳市",
        "area": "绥宁县"
    }, {
        "name": "湖南邵阳市新宁县",
        "match": "湖南邵阳市新宁县|HNSYSXNX",
        "py": "XNX",
        "provinc": "湖南",
        "city": "邵阳市",
        "area": "新宁县"
    }, {
        "name": "湖南邵阳市城步县",
        "match": "湖南邵阳市城步县|HNSYSCBZZX",
        "py": "CBZZX",
        "provinc": "湖南",
        "city": "邵阳市",
        "area": "城步县"
    }, {
        "name": "湖南邵阳市武冈市",
        "match": "湖南邵阳市武冈市|HNSYSWGS",
        "py": "WGS",
        "provinc": "湖南",
        "city": "邵阳市",
        "area": "武冈市"
    }, {
        "name": "湖南湘西州",
        "match": "湖南湘西州|HNXXZZZ",
        "py": "XXZZZ",
        "provinc": "湖南",
        "city": "湘西州",
        "area": "湘西州"
    }, {
        "name": "湖南湘西州吉首市",
        "match": "湖南湘西州吉首市|HNXXZZZJSS",
        "py": "JSS",
        "provinc": "湖南",
        "city": "湘西州",
        "area": "吉首市"
    }, {
        "name": "湖南湘西州泸溪县",
        "match": "湖南湘西州泸溪县|HNXXZZZLXX",
        "py": "LXX",
        "provinc": "湖南",
        "city": "湘西州",
        "area": "泸溪县"
    }, {
        "name": "湖南湘西州凤凰县",
        "match": "湖南湘西州凤凰县|HNXXZZZFHX",
        "py": "FHX",
        "provinc": "湖南",
        "city": "湘西州",
        "area": "凤凰县"
    }, {
        "name": "湖南湘西州花垣县",
        "match": "湖南湘西州花垣县|HNXXZZZHYX",
        "py": "HYX",
        "provinc": "湖南",
        "city": "湘西州",
        "area": "花垣县"
    }, {
        "name": "湖南湘西州保靖县",
        "match": "湖南湘西州保靖县|HNXXZZZBJX",
        "py": "BJX",
        "provinc": "湖南",
        "city": "湘西州",
        "area": "保靖县"
    }, {
        "name": "湖南湘西州古丈县",
        "match": "湖南湘西州古丈县|HNXXZZZGZX",
        "py": "GZX",
        "provinc": "湖南",
        "city": "湘西州",
        "area": "古丈县"
    }, {
        "name": "湖南湘西州永顺县",
        "match": "湖南湘西州永顺县|HNXXZZZYSX",
        "py": "YSX",
        "provinc": "湖南",
        "city": "湘西州",
        "area": "永顺县"
    }, {
        "name": "湖南湘西州龙山县",
        "match": "湖南湘西州龙山县|HNXXZZZLSX",
        "py": "LSX",
        "provinc": "湖南",
        "city": "湘西州",
        "area": "龙山县"
    }, {
        "name": "湖南张家界市",
        "match": "湖南张家界市|HNZJJS",
        "py": "ZJJS",
        "provinc": "湖南",
        "city": "张家界市",
        "area": "张家界市"
    }, {
        "name": "湖南张家界市永定区",
        "match": "湖南张家界市永定区|HNZJJSYDQ",
        "py": "YDQ",
        "provinc": "湖南",
        "city": "张家界市",
        "area": "永定区"
    }, {
        "name": "湖南张家界市武陵源区",
        "match": "湖南张家界市武陵源区|HNZJJSWLYQ",
        "py": "WLYQ",
        "provinc": "湖南",
        "city": "张家界市",
        "area": "武陵源区"
    }, {
        "name": "湖南张家界市慈利县",
        "match": "湖南张家界市慈利县|HNZJJSCLX",
        "py": "CLX",
        "provinc": "湖南",
        "city": "张家界市",
        "area": "慈利县"
    }, {
        "name": "湖南张家界市桑植县",
        "match": "湖南张家界市桑植县|HNZJJSSZX",
        "py": "SZX",
        "provinc": "湖南",
        "city": "张家界市",
        "area": "桑植县"
    }, {
        "name": "湖南怀化市",
        "match": "湖南怀化市|HNHHS",
        "py": "HHS",
        "provinc": "湖南",
        "city": "怀化市",
        "area": "怀化市"
    }, {
        "name": "湖南怀化市鹤城区",
        "match": "湖南怀化市鹤城区|HNHHSHCQ",
        "py": "HCQ",
        "provinc": "湖南",
        "city": "怀化市",
        "area": "鹤城区"
    }, {
        "name": "湖南怀化市中方县",
        "match": "湖南怀化市中方县|HNHHSZFX",
        "py": "ZFX",
        "provinc": "湖南",
        "city": "怀化市",
        "area": "中方县"
    }, {
        "name": "湖南怀化市沅陵县",
        "match": "湖南怀化市沅陵县|HNHHSYLX",
        "py": "YLX",
        "provinc": "湖南",
        "city": "怀化市",
        "area": "沅陵县"
    }, {
        "name": "湖南怀化市辰溪县",
        "match": "湖南怀化市辰溪县|HNHHSCXX",
        "py": "CXX",
        "provinc": "湖南",
        "city": "怀化市",
        "area": "辰溪县"
    }, {
        "name": "湖南怀化市溆浦县",
        "match": "湖南怀化市溆浦县|HNHHSXPX",
        "py": "XPX",
        "provinc": "湖南",
        "city": "怀化市",
        "area": "溆浦县"
    }, {
        "name": "湖南怀化市会同县",
        "match": "湖南怀化市会同县|HNHHSHTX",
        "py": "HTX",
        "provinc": "湖南",
        "city": "怀化市",
        "area": "会同县"
    }, {
        "name": "湖南怀化市麻阳县",
        "match": "湖南怀化市麻阳县|HNHHSMYZZX",
        "py": "MYZZX",
        "provinc": "湖南",
        "city": "怀化市",
        "area": "麻阳县"
    }, {
        "name": "湖南怀化市新晃县",
        "match": "湖南怀化市新晃县|HNHHSXHZZX",
        "py": "XHZZX",
        "provinc": "湖南",
        "city": "怀化市",
        "area": "新晃县"
    }, {
        "name": "湖南怀化市芷江县",
        "match": "湖南怀化市芷江县|HNHHSZJZZX",
        "py": "ZJZZX",
        "provinc": "湖南",
        "city": "怀化市",
        "area": "芷江县"
    }, {
        "name": "湖南怀化市靖州县",
        "match": "湖南怀化市靖州县|HNHHSJZZZX",
        "py": "JZZZX",
        "provinc": "湖南",
        "city": "怀化市",
        "area": "靖州县"
    }, {
        "name": "湖南怀化市通道县",
        "match": "湖南怀化市通道县|HNHHSTDZZX",
        "py": "TDZZX",
        "provinc": "湖南",
        "city": "怀化市",
        "area": "通道县"
    }, {
        "name": "湖南怀化市洪江市",
        "match": "湖南怀化市洪江市|HNHHSHJS",
        "py": "HJS",
        "provinc": "湖南",
        "city": "怀化市",
        "area": "洪江市"
    }, {
        "name": "湖南永州市",
        "match": "湖南永州市|HNYZS",
        "py": "YZS",
        "provinc": "湖南",
        "city": "永州市",
        "area": "永州市"
    }, {
        "name": "湖南永州市零陵区",
        "match": "湖南永州市零陵区|HNYZSLLQ",
        "py": "LLQ",
        "provinc": "湖南",
        "city": "永州市",
        "area": "零陵区"
    }, {
        "name": "湖南永州市冷水滩区",
        "match": "湖南永州市冷水滩区|HNYZSLSTQ",
        "py": "LSTQ",
        "provinc": "湖南",
        "city": "永州市",
        "area": "冷水滩区"
    }, {
        "name": "湖南永州市祁阳县",
        "match": "湖南永州市祁阳县|HNYZSQYX",
        "py": "QYX",
        "provinc": "湖南",
        "city": "永州市",
        "area": "祁阳县"
    }, {
        "name": "湖南永州市东安县",
        "match": "湖南永州市东安县|HNYZSDAX",
        "py": "DAX",
        "provinc": "湖南",
        "city": "永州市",
        "area": "东安县"
    }, {
        "name": "湖南永州市双牌县",
        "match": "湖南永州市双牌县|HNYZSSPX",
        "py": "SPX",
        "provinc": "湖南",
        "city": "永州市",
        "area": "双牌县"
    }, {
        "name": "湖南永州市道县",
        "match": "湖南永州市道县|HNYZSDX",
        "py": "DX",
        "provinc": "湖南",
        "city": "永州市",
        "area": "道县"
    }, {
        "name": "湖南永州市江永县",
        "match": "湖南永州市江永县|HNYZSJYX",
        "py": "JYX",
        "provinc": "湖南",
        "city": "永州市",
        "area": "江永县"
    }, {
        "name": "湖南永州市宁远县",
        "match": "湖南永州市宁远县|HNYZSNYX",
        "py": "NYX",
        "provinc": "湖南",
        "city": "永州市",
        "area": "宁远县"
    }, {
        "name": "湖南永州市蓝山县",
        "match": "湖南永州市蓝山县|HNYZSLSX",
        "py": "LSX",
        "provinc": "湖南",
        "city": "永州市",
        "area": "蓝山县"
    }, {
        "name": "湖南永州市新田县",
        "match": "湖南永州市新田县|HNYZSXTX",
        "py": "XTX",
        "provinc": "湖南",
        "city": "永州市",
        "area": "新田县"
    }, {
        "name": "湖南永州市江华县",
        "match": "湖南永州市江华县|HNYZSJHZZX",
        "py": "JHZZX",
        "provinc": "湖南",
        "city": "永州市",
        "area": "江华县"
    }, {
        "name": "澳门",
        "match": "澳门|AM",
        "py": "AM",
        "provinc": "澳门",
        "city": "澳门",
        "area": "澳门"
    }, {
        "name": "甘肃兰州市",
        "match": "甘肃兰州市|GSLZS",
        "py": "LZS",
        "provinc": "甘肃",
        "city": "兰州市",
        "area": "兰州市"
    }, {
        "name": "甘肃兰州市城关区",
        "match": "甘肃兰州市城关区|GSLZSCGQ",
        "py": "CGQ",
        "provinc": "甘肃",
        "city": "兰州市",
        "area": "城关区"
    }, {
        "name": "甘肃兰州市兰州新区",
        "match": "甘肃兰州市兰州新区|GSLZSLZXQ",
        "py": "LZXQ",
        "provinc": "甘肃",
        "city": "兰州市",
        "area": "兰州新区"
    }, {
        "name": "甘肃兰州市七里河区",
        "match": "甘肃兰州市七里河区|GSLZSQLHQ",
        "py": "QLHQ",
        "provinc": "甘肃",
        "city": "兰州市",
        "area": "七里河区"
    }, {
        "name": "甘肃兰州市西固区",
        "match": "甘肃兰州市西固区|GSLZSXGQ",
        "py": "XGQ",
        "provinc": "甘肃",
        "city": "兰州市",
        "area": "西固区"
    }, {
        "name": "甘肃兰州市安宁区",
        "match": "甘肃兰州市安宁区|GSLZSANQ",
        "py": "ANQ",
        "provinc": "甘肃",
        "city": "兰州市",
        "area": "安宁区"
    }, {
        "name": "甘肃兰州市红古区",
        "match": "甘肃兰州市红古区|GSLZSHGQ",
        "py": "HGQ",
        "provinc": "甘肃",
        "city": "兰州市",
        "area": "红古区"
    }, {
        "name": "甘肃兰州市永登县",
        "match": "甘肃兰州市永登县|GSLZSYDX",
        "py": "YDX",
        "provinc": "甘肃",
        "city": "兰州市",
        "area": "永登县"
    }, {
        "name": "甘肃兰州市皋兰县",
        "match": "甘肃兰州市皋兰县|GSLZSGLX",
        "py": "GLX",
        "provinc": "甘肃",
        "city": "兰州市",
        "area": "皋兰县"
    }, {
        "name": "甘肃兰州市榆中县",
        "match": "甘肃兰州市榆中县|GSLZSYZX",
        "py": "YZX",
        "provinc": "甘肃",
        "city": "兰州市",
        "area": "榆中县"
    }, {
        "name": "甘肃金昌市",
        "match": "甘肃金昌市|GSJCS",
        "py": "JCS",
        "provinc": "甘肃",
        "city": "金昌市",
        "area": "金昌市"
    }, {
        "name": "甘肃金昌市金川区",
        "match": "甘肃金昌市金川区|GSJCSJCQ",
        "py": "JCQ",
        "provinc": "甘肃",
        "city": "金昌市",
        "area": "金川区"
    }, {
        "name": "甘肃金昌市永昌县",
        "match": "甘肃金昌市永昌县|GSJCSYCX",
        "py": "YCX",
        "provinc": "甘肃",
        "city": "金昌市",
        "area": "永昌县"
    }, {
        "name": "甘肃白银市",
        "match": "甘肃白银市|GSBYS",
        "py": "BYS",
        "provinc": "甘肃",
        "city": "白银市",
        "area": "白银市"
    }, {
        "name": "甘肃白银市白银区",
        "match": "甘肃白银市白银区|GSBYSBYQ",
        "py": "BYQ",
        "provinc": "甘肃",
        "city": "白银市",
        "area": "白银区"
    }, {
        "name": "甘肃白银市平川区",
        "match": "甘肃白银市平川区|GSBYSPCQ",
        "py": "PCQ",
        "provinc": "甘肃",
        "city": "白银市",
        "area": "平川区"
    }, {
        "name": "甘肃白银市靖远县",
        "match": "甘肃白银市靖远县|GSBYSJYX",
        "py": "JYX",
        "provinc": "甘肃",
        "city": "白银市",
        "area": "靖远县"
    }, {
        "name": "甘肃白银市会宁县",
        "match": "甘肃白银市会宁县|GSBYSHNX",
        "py": "HNX",
        "provinc": "甘肃",
        "city": "白银市",
        "area": "会宁县"
    }, {
        "name": "甘肃白银市景泰县",
        "match": "甘肃白银市景泰县|GSBYSJTX",
        "py": "JTX",
        "provinc": "甘肃",
        "city": "白银市",
        "area": "景泰县"
    }, {
        "name": "甘肃天水市",
        "match": "甘肃天水市|GSTSS",
        "py": "TSS",
        "provinc": "甘肃",
        "city": "天水市",
        "area": "天水市"
    }, {
        "name": "甘肃天水市秦州区",
        "match": "甘肃天水市秦州区|GSTSSQZQ",
        "py": "QZQ",
        "provinc": "甘肃",
        "city": "天水市",
        "area": "秦州区"
    }, {
        "name": "甘肃天水市麦积区",
        "match": "甘肃天水市麦积区|GSTSSMJQ",
        "py": "MJQ",
        "provinc": "甘肃",
        "city": "天水市",
        "area": "麦积区"
    }, {
        "name": "甘肃天水市清水县",
        "match": "甘肃天水市清水县|GSTSSQSX",
        "py": "QSX",
        "provinc": "甘肃",
        "city": "天水市",
        "area": "清水县"
    }, {
        "name": "甘肃天水市秦安县",
        "match": "甘肃天水市秦安县|GSTSSQAX",
        "py": "QAX",
        "provinc": "甘肃",
        "city": "天水市",
        "area": "秦安县"
    }, {
        "name": "甘肃天水市甘谷县",
        "match": "甘肃天水市甘谷县|GSTSSGGX",
        "py": "GGX",
        "provinc": "甘肃",
        "city": "天水市",
        "area": "甘谷县"
    }, {
        "name": "甘肃天水市武山县",
        "match": "甘肃天水市武山县|GSTSSWSX",
        "py": "WSX",
        "provinc": "甘肃",
        "city": "天水市",
        "area": "武山县"
    }, {
        "name": "甘肃天水市张家川县",
        "match": "甘肃天水市张家川县|GSTSSZJCZZX",
        "py": "ZJCZZX",
        "provinc": "甘肃",
        "city": "天水市",
        "area": "张家川县"
    }, {
        "name": "甘肃嘉峪关市",
        "match": "甘肃嘉峪关市|GSJYGS",
        "py": "JYGS",
        "provinc": "甘肃",
        "city": "嘉峪关市",
        "area": "嘉峪关市"
    }, {
        "name": "甘肃武威市",
        "match": "甘肃武威市|GSWWS",
        "py": "WWS",
        "provinc": "甘肃",
        "city": "武威市",
        "area": "武威市"
    }, {
        "name": "甘肃武威市凉州区",
        "match": "甘肃武威市凉州区|GSWWSLZQ",
        "py": "LZQ",
        "provinc": "甘肃",
        "city": "武威市",
        "area": "凉州区"
    }, {
        "name": "甘肃武威市民勤县",
        "match": "甘肃武威市民勤县|GSWWSMQX",
        "py": "MQX",
        "provinc": "甘肃",
        "city": "武威市",
        "area": "民勤县"
    }, {
        "name": "甘肃武威市古浪县",
        "match": "甘肃武威市古浪县|GSWWSGLX",
        "py": "GLX",
        "provinc": "甘肃",
        "city": "武威市",
        "area": "古浪县"
    }, {
        "name": "甘肃武威市天祝县",
        "match": "甘肃武威市天祝县|GSWWSTZZZX",
        "py": "TZZZX",
        "provinc": "甘肃",
        "city": "武威市",
        "area": "天祝县"
    }, {
        "name": "甘肃张掖市",
        "match": "甘肃张掖市|GSZYS",
        "py": "ZYS",
        "provinc": "甘肃",
        "city": "张掖市",
        "area": "张掖市"
    }, {
        "name": "甘肃张掖市甘州区",
        "match": "甘肃张掖市甘州区|GSZYSGZQ",
        "py": "GZQ",
        "provinc": "甘肃",
        "city": "张掖市",
        "area": "甘州区"
    }, {
        "name": "甘肃张掖市肃南县",
        "match": "甘肃张掖市肃南县|GSZYSSNZZX",
        "py": "SNZZX",
        "provinc": "甘肃",
        "city": "张掖市",
        "area": "肃南县"
    }, {
        "name": "甘肃张掖市民乐县",
        "match": "甘肃张掖市民乐县|GSZYSMLX",
        "py": "MLX",
        "provinc": "甘肃",
        "city": "张掖市",
        "area": "民乐县"
    }, {
        "name": "甘肃张掖市临泽县",
        "match": "甘肃张掖市临泽县|GSZYSLZX",
        "py": "LZX",
        "provinc": "甘肃",
        "city": "张掖市",
        "area": "临泽县"
    }, {
        "name": "甘肃张掖市高台县",
        "match": "甘肃张掖市高台县|GSZYSGTX",
        "py": "GTX",
        "provinc": "甘肃",
        "city": "张掖市",
        "area": "高台县"
    }, {
        "name": "甘肃张掖市山丹县",
        "match": "甘肃张掖市山丹县|GSZYSSDX",
        "py": "SDX",
        "provinc": "甘肃",
        "city": "张掖市",
        "area": "山丹县"
    }, {
        "name": "甘肃平凉市",
        "match": "甘肃平凉市|GSPLS",
        "py": "PLS",
        "provinc": "甘肃",
        "city": "平凉市",
        "area": "平凉市"
    }, {
        "name": "甘肃平凉市崆峒区",
        "match": "甘肃平凉市崆峒区|GSPLSKTQ",
        "py": "KTQ",
        "provinc": "甘肃",
        "city": "平凉市",
        "area": "崆峒区"
    }, {
        "name": "甘肃平凉市泾川县",
        "match": "甘肃平凉市泾川县|GSPLSJCX",
        "py": "JCX",
        "provinc": "甘肃",
        "city": "平凉市",
        "area": "泾川县"
    }, {
        "name": "甘肃平凉市灵台县",
        "match": "甘肃平凉市灵台县|GSPLSLTX",
        "py": "LTX",
        "provinc": "甘肃",
        "city": "平凉市",
        "area": "灵台县"
    }, {
        "name": "甘肃平凉市崇信县",
        "match": "甘肃平凉市崇信县|GSPLSCXX",
        "py": "CXX",
        "provinc": "甘肃",
        "city": "平凉市",
        "area": "崇信县"
    }, {
        "name": "甘肃平凉市华亭县",
        "match": "甘肃平凉市华亭县|GSPLSHTX",
        "py": "HTX",
        "provinc": "甘肃",
        "city": "平凉市",
        "area": "华亭县"
    }, {
        "name": "甘肃平凉市庄浪县",
        "match": "甘肃平凉市庄浪县|GSPLSZLX",
        "py": "ZLX",
        "provinc": "甘肃",
        "city": "平凉市",
        "area": "庄浪县"
    }, {
        "name": "甘肃平凉市静宁县",
        "match": "甘肃平凉市静宁县|GSPLSJNX",
        "py": "JNX",
        "provinc": "甘肃",
        "city": "平凉市",
        "area": "静宁县"
    }, {
        "name": "甘肃酒泉市",
        "match": "甘肃酒泉市|GSJQS",
        "py": "JQS",
        "provinc": "甘肃",
        "city": "酒泉市",
        "area": "酒泉市"
    }, {
        "name": "甘肃酒泉市肃州区",
        "match": "甘肃酒泉市肃州区|GSJQSSZQ",
        "py": "SZQ",
        "provinc": "甘肃",
        "city": "酒泉市",
        "area": "肃州区"
    }, {
        "name": "甘肃酒泉市金塔县",
        "match": "甘肃酒泉市金塔县|GSJQSJTX",
        "py": "JTX",
        "provinc": "甘肃",
        "city": "酒泉市",
        "area": "金塔县"
    }, {
        "name": "甘肃酒泉市瓜州县",
        "match": "甘肃酒泉市瓜州县|GSJQSGZX",
        "py": "GZX",
        "provinc": "甘肃",
        "city": "酒泉市",
        "area": "瓜州县"
    }, {
        "name": "甘肃酒泉市肃北县",
        "match": "甘肃酒泉市肃北县|GSJQSSBZZX",
        "py": "SBZZX",
        "provinc": "甘肃",
        "city": "酒泉市",
        "area": "肃北县"
    }, {
        "name": "甘肃酒泉市阿克塞县",
        "match": "甘肃酒泉市阿克塞县|GSJQSAKSZZX",
        "py": "AKSZZX",
        "provinc": "甘肃",
        "city": "酒泉市",
        "area": "阿克塞县"
    }, {
        "name": "甘肃酒泉市玉门市",
        "match": "甘肃酒泉市玉门市|GSJQSYMS",
        "py": "YMS",
        "provinc": "甘肃",
        "city": "酒泉市",
        "area": "玉门市"
    }, {
        "name": "甘肃酒泉市敦煌市",
        "match": "甘肃酒泉市敦煌市|GSJQSDHS",
        "py": "DHS",
        "provinc": "甘肃",
        "city": "酒泉市",
        "area": "敦煌市"
    }, {
        "name": "甘肃庆阳市",
        "match": "甘肃庆阳市|GSQYS",
        "py": "QYS",
        "provinc": "甘肃",
        "city": "庆阳市",
        "area": "庆阳市"
    }, {
        "name": "甘肃庆阳市西峰区",
        "match": "甘肃庆阳市西峰区|GSQYSXFQ",
        "py": "XFQ",
        "provinc": "甘肃",
        "city": "庆阳市",
        "area": "西峰区"
    }, {
        "name": "甘肃庆阳市庆城县",
        "match": "甘肃庆阳市庆城县|GSQYSQCX",
        "py": "QCX",
        "provinc": "甘肃",
        "city": "庆阳市",
        "area": "庆城县"
    }, {
        "name": "甘肃庆阳市环县",
        "match": "甘肃庆阳市环县|GSQYSHX",
        "py": "HX",
        "provinc": "甘肃",
        "city": "庆阳市",
        "area": "环县"
    }, {
        "name": "甘肃庆阳市华池县",
        "match": "甘肃庆阳市华池县|GSQYSHCX",
        "py": "HCX",
        "provinc": "甘肃",
        "city": "庆阳市",
        "area": "华池县"
    }, {
        "name": "甘肃庆阳市合水县",
        "match": "甘肃庆阳市合水县|GSQYSHSX",
        "py": "HSX",
        "provinc": "甘肃",
        "city": "庆阳市",
        "area": "合水县"
    }, {
        "name": "甘肃庆阳市正宁县",
        "match": "甘肃庆阳市正宁县|GSQYSZNX",
        "py": "ZNX",
        "provinc": "甘肃",
        "city": "庆阳市",
        "area": "正宁县"
    }, {
        "name": "甘肃庆阳市宁县",
        "match": "甘肃庆阳市宁县|GSQYSNX",
        "py": "NX",
        "provinc": "甘肃",
        "city": "庆阳市",
        "area": "宁县"
    }, {
        "name": "甘肃庆阳市镇原县",
        "match": "甘肃庆阳市镇原县|GSQYSZYX",
        "py": "ZYX",
        "provinc": "甘肃",
        "city": "庆阳市",
        "area": "镇原县"
    }, {
        "name": "甘肃定西市",
        "match": "甘肃定西市|GSDXS",
        "py": "DXS",
        "provinc": "甘肃",
        "city": "定西市",
        "area": "定西市"
    }, {
        "name": "甘肃定西市安定区",
        "match": "甘肃定西市安定区|GSDXSADQ",
        "py": "ADQ",
        "provinc": "甘肃",
        "city": "定西市",
        "area": "安定区"
    }, {
        "name": "甘肃定西市通渭县",
        "match": "甘肃定西市通渭县|GSDXSTWX",
        "py": "TWX",
        "provinc": "甘肃",
        "city": "定西市",
        "area": "通渭县"
    }, {
        "name": "甘肃定西市陇西县",
        "match": "甘肃定西市陇西县|GSDXSLXX",
        "py": "LXX",
        "provinc": "甘肃",
        "city": "定西市",
        "area": "陇西县"
    }, {
        "name": "甘肃定西市渭源县",
        "match": "甘肃定西市渭源县|GSDXSWYX",
        "py": "WYX",
        "provinc": "甘肃",
        "city": "定西市",
        "area": "渭源县"
    }, {
        "name": "甘肃定西市临洮县",
        "match": "甘肃定西市临洮县|GSDXSLTX",
        "py": "LTX",
        "provinc": "甘肃",
        "city": "定西市",
        "area": "临洮县"
    }, {
        "name": "甘肃定西市漳县",
        "match": "甘肃定西市漳县|GSDXSZX",
        "py": "ZX",
        "provinc": "甘肃",
        "city": "定西市",
        "area": "漳县"
    }, {
        "name": "甘肃定西市岷县",
        "match": "甘肃定西市岷县|GSDXSMX",
        "py": "MX",
        "provinc": "甘肃",
        "city": "定西市",
        "area": "岷县"
    }, {
        "name": "甘肃陇南市",
        "match": "甘肃陇南市|GSLNS",
        "py": "LNS",
        "provinc": "甘肃",
        "city": "陇南市",
        "area": "陇南市"
    }, {
        "name": "甘肃陇南市武都区",
        "match": "甘肃陇南市武都区|GSLNSWDQ",
        "py": "WDQ",
        "provinc": "甘肃",
        "city": "陇南市",
        "area": "武都区"
    }, {
        "name": "甘肃陇南市成县",
        "match": "甘肃陇南市成县|GSLNSCX",
        "py": "CX",
        "provinc": "甘肃",
        "city": "陇南市",
        "area": "成县"
    }, {
        "name": "甘肃陇南市文县",
        "match": "甘肃陇南市文县|GSLNSWX",
        "py": "WX",
        "provinc": "甘肃",
        "city": "陇南市",
        "area": "文县"
    }, {
        "name": "甘肃陇南市宕昌县",
        "match": "甘肃陇南市宕昌县|GSLNSDCX",
        "py": "DCX",
        "provinc": "甘肃",
        "city": "陇南市",
        "area": "宕昌县"
    }, {
        "name": "甘肃陇南市康县",
        "match": "甘肃陇南市康县|GSLNSKX",
        "py": "KX",
        "provinc": "甘肃",
        "city": "陇南市",
        "area": "康县"
    }, {
        "name": "甘肃陇南市西和县",
        "match": "甘肃陇南市西和县|GSLNSXHX",
        "py": "XHX",
        "provinc": "甘肃",
        "city": "陇南市",
        "area": "西和县"
    }, {
        "name": "甘肃陇南市礼县",
        "match": "甘肃陇南市礼县|GSLNSLX",
        "py": "LX",
        "provinc": "甘肃",
        "city": "陇南市",
        "area": "礼县"
    }, {
        "name": "甘肃陇南市徽县",
        "match": "甘肃陇南市徽县|GSLNSHX",
        "py": "HX",
        "provinc": "甘肃",
        "city": "陇南市",
        "area": "徽县"
    }, {
        "name": "甘肃陇南市两当县",
        "match": "甘肃陇南市两当县|GSLNSLDX",
        "py": "LDX",
        "provinc": "甘肃",
        "city": "陇南市",
        "area": "两当县"
    }, {
        "name": "甘肃临夏州",
        "match": "甘肃临夏州|GSLXZZZ",
        "py": "LXZZZ",
        "provinc": "甘肃",
        "city": "临夏州",
        "area": "临夏州"
    }, {
        "name": "甘肃临夏州临夏市",
        "match": "甘肃临夏州临夏市|GSLXZZZLXS",
        "py": "LXS",
        "provinc": "甘肃",
        "city": "临夏州",
        "area": "临夏市"
    }, {
        "name": "甘肃临夏州临夏县",
        "match": "甘肃临夏州临夏县|GSLXZZZLXX",
        "py": "LXX",
        "provinc": "甘肃",
        "city": "临夏州",
        "area": "临夏县"
    }, {
        "name": "甘肃临夏州康乐县",
        "match": "甘肃临夏州康乐县|GSLXZZZKLX",
        "py": "KLX",
        "provinc": "甘肃",
        "city": "临夏州",
        "area": "康乐县"
    }, {
        "name": "甘肃临夏州永靖县",
        "match": "甘肃临夏州永靖县|GSLXZZZYJX",
        "py": "YJX",
        "provinc": "甘肃",
        "city": "临夏州",
        "area": "永靖县"
    }, {
        "name": "甘肃临夏州广河县",
        "match": "甘肃临夏州广河县|GSLXZZZGHX",
        "py": "GHX",
        "provinc": "甘肃",
        "city": "临夏州",
        "area": "广河县"
    }, {
        "name": "甘肃临夏州和政县",
        "match": "甘肃临夏州和政县|GSLXZZZHZX",
        "py": "HZX",
        "provinc": "甘肃",
        "city": "临夏州",
        "area": "和政县"
    }, {
        "name": "甘肃临夏州东乡族县",
        "match": "甘肃临夏州东乡族县|GSLXZZZDXZZZX",
        "py": "DXZZZX",
        "provinc": "甘肃",
        "city": "临夏州",
        "area": "东乡族县"
    }, {
        "name": "甘肃临夏州积石山县",
        "match": "甘肃临夏州积石山县|GSLXZZZJSSZZX",
        "py": "JSSZZX",
        "provinc": "甘肃",
        "city": "临夏州",
        "area": "积石山县"
    }, {
        "name": "甘肃甘南州",
        "match": "甘肃甘南州|GSGNZZZ",
        "py": "GNZZZ",
        "provinc": "甘肃",
        "city": "甘南州",
        "area": "甘南州"
    }, {
        "name": "甘肃甘南州合作市",
        "match": "甘肃甘南州合作市|GSGNZZZHZS",
        "py": "HZS",
        "provinc": "甘肃",
        "city": "甘南州",
        "area": "合作市"
    }, {
        "name": "甘肃甘南州临潭县",
        "match": "甘肃甘南州临潭县|GSGNZZZLTX",
        "py": "LTX",
        "provinc": "甘肃",
        "city": "甘南州",
        "area": "临潭县"
    }, {
        "name": "甘肃甘南州卓尼县",
        "match": "甘肃甘南州卓尼县|GSGNZZZZNX",
        "py": "ZNX",
        "provinc": "甘肃",
        "city": "甘南州",
        "area": "卓尼县"
    }, {
        "name": "甘肃甘南州舟曲县",
        "match": "甘肃甘南州舟曲县|GSGNZZZZQX",
        "py": "ZQX",
        "provinc": "甘肃",
        "city": "甘南州",
        "area": "舟曲县"
    }, {
        "name": "甘肃甘南州迭部县",
        "match": "甘肃甘南州迭部县|GSGNZZZDBX",
        "py": "DBX",
        "provinc": "甘肃",
        "city": "甘南州",
        "area": "迭部县"
    }, {
        "name": "甘肃甘南州玛曲县",
        "match": "甘肃甘南州玛曲县|GSGNZZZMQX",
        "py": "MQX",
        "provinc": "甘肃",
        "city": "甘南州",
        "area": "玛曲县"
    }, {
        "name": "甘肃甘南州碌曲县",
        "match": "甘肃甘南州碌曲县|GSGNZZZLQX",
        "py": "LQX",
        "provinc": "甘肃",
        "city": "甘南州",
        "area": "碌曲县"
    }, {
        "name": "甘肃甘南州夏河县",
        "match": "甘肃甘南州夏河县|GSGNZZZXHX",
        "py": "XHX",
        "provinc": "甘肃",
        "city": "甘南州",
        "area": "夏河县"
    }, {
        "name": "福建福州市",
        "match": "福建福州市|FJFZS",
        "py": "FZS",
        "provinc": "福建",
        "city": "福州市",
        "area": "福州市"
    }, {
        "name": "福建福州市鼓楼区",
        "match": "福建福州市鼓楼区|FJFZSGLQ",
        "py": "GLQ",
        "provinc": "福建",
        "city": "福州市",
        "area": "鼓楼区"
    }, {
        "name": "福建福州市台江区",
        "match": "福建福州市台江区|FJFZSTJQ",
        "py": "TJQ",
        "provinc": "福建",
        "city": "福州市",
        "area": "台江区"
    }, {
        "name": "福建福州市仓山区",
        "match": "福建福州市仓山区|FJFZSCSQ",
        "py": "CSQ",
        "provinc": "福建",
        "city": "福州市",
        "area": "仓山区"
    }, {
        "name": "福建福州市马尾区",
        "match": "福建福州市马尾区|FJFZSMWQ",
        "py": "MWQ",
        "provinc": "福建",
        "city": "福州市",
        "area": "马尾区"
    }, {
        "name": "福建福州市晋安区",
        "match": "福建福州市晋安区|FJFZSJAQ",
        "py": "JAQ",
        "provinc": "福建",
        "city": "福州市",
        "area": "晋安区"
    }, {
        "name": "福建福州市闽侯县",
        "match": "福建福州市闽侯县|FJFZSMHX",
        "py": "MHX",
        "provinc": "福建",
        "city": "福州市",
        "area": "闽侯县"
    }, {
        "name": "福建福州市连江县",
        "match": "福建福州市连江县|FJFZSLJX",
        "py": "LJX",
        "provinc": "福建",
        "city": "福州市",
        "area": "连江县"
    }, {
        "name": "福建福州市罗源县",
        "match": "福建福州市罗源县|FJFZSLYX",
        "py": "LYX",
        "provinc": "福建",
        "city": "福州市",
        "area": "罗源县"
    }, {
        "name": "福建福州市闽清县",
        "match": "福建福州市闽清县|FJFZSMQX",
        "py": "MQX",
        "provinc": "福建",
        "city": "福州市",
        "area": "闽清县"
    }, {
        "name": "福建福州市永泰县",
        "match": "福建福州市永泰县|FJFZSYTX",
        "py": "YTX",
        "provinc": "福建",
        "city": "福州市",
        "area": "永泰县"
    }, {
        "name": "福建福州市平潭县",
        "match": "福建福州市平潭县|FJFZSPTX",
        "py": "PTX",
        "provinc": "福建",
        "city": "福州市",
        "area": "平潭县"
    }, {
        "name": "福建福州市福清市",
        "match": "福建福州市福清市|FJFZSFQS",
        "py": "FQS",
        "provinc": "福建",
        "city": "福州市",
        "area": "福清市"
    }, {
        "name": "福建福州市长乐区",
        "match": "福建福州市长乐区|FJFZSCLQ",
        "py": "CLQ",
        "provinc": "福建",
        "city": "福州市",
        "area": "长乐区"
    }, {
        "name": "福建厦门市",
        "match": "福建厦门市|FJXMS",
        "py": "XMS",
        "provinc": "福建",
        "city": "厦门市",
        "area": "厦门市"
    }, {
        "name": "福建厦门市思明区",
        "match": "福建厦门市思明区|FJXMSSMQ",
        "py": "SMQ",
        "provinc": "福建",
        "city": "厦门市",
        "area": "思明区"
    }, {
        "name": "福建厦门市海沧区",
        "match": "福建厦门市海沧区|FJXMSHCQ",
        "py": "HCQ",
        "provinc": "福建",
        "city": "厦门市",
        "area": "海沧区"
    }, {
        "name": "福建厦门市湖里区",
        "match": "福建厦门市湖里区|FJXMSHLQ",
        "py": "HLQ",
        "provinc": "福建",
        "city": "厦门市",
        "area": "湖里区"
    }, {
        "name": "福建厦门市集美区",
        "match": "福建厦门市集美区|FJXMSJMQ",
        "py": "JMQ",
        "provinc": "福建",
        "city": "厦门市",
        "area": "集美区"
    }, {
        "name": "福建厦门市同安区",
        "match": "福建厦门市同安区|FJXMSTAQ",
        "py": "TAQ",
        "provinc": "福建",
        "city": "厦门市",
        "area": "同安区"
    }, {
        "name": "福建厦门市翔安区",
        "match": "福建厦门市翔安区|FJXMSXAQ",
        "py": "XAQ",
        "provinc": "福建",
        "city": "厦门市",
        "area": "翔安区"
    }, {
        "name": "福建宁德市",
        "match": "福建宁德市|FJNDS",
        "py": "NDS",
        "provinc": "福建",
        "city": "宁德市",
        "area": "宁德市"
    }, {
        "name": "福建宁德市东侨开发区",
        "match": "福建宁德市东侨开发区|FJNDSDQKFQ",
        "py": "DQKFQ",
        "provinc": "福建",
        "city": "宁德市",
        "area": "东侨开发区"
    }, {
        "name": "福建宁德市蕉城区",
        "match": "福建宁德市蕉城区|FJNDSJCQ",
        "py": "JCQ",
        "provinc": "福建",
        "city": "宁德市",
        "area": "蕉城区"
    }, {
        "name": "福建宁德市霞浦县",
        "match": "福建宁德市霞浦县|FJNDSXPX",
        "py": "XPX",
        "provinc": "福建",
        "city": "宁德市",
        "area": "霞浦县"
    }, {
        "name": "福建宁德市古田县",
        "match": "福建宁德市古田县|FJNDSGTX",
        "py": "GTX",
        "provinc": "福建",
        "city": "宁德市",
        "area": "古田县"
    }, {
        "name": "福建宁德市屏南县",
        "match": "福建宁德市屏南县|FJNDSPNX",
        "py": "PNX",
        "provinc": "福建",
        "city": "宁德市",
        "area": "屏南县"
    }, {
        "name": "福建宁德市寿宁县",
        "match": "福建宁德市寿宁县|FJNDSSNX",
        "py": "SNX",
        "provinc": "福建",
        "city": "宁德市",
        "area": "寿宁县"
    }, {
        "name": "福建宁德市周宁县",
        "match": "福建宁德市周宁县|FJNDSZNX",
        "py": "ZNX",
        "provinc": "福建",
        "city": "宁德市",
        "area": "周宁县"
    }, {
        "name": "福建宁德市柘荣县",
        "match": "福建宁德市柘荣县|FJNDSZRX",
        "py": "ZRX",
        "provinc": "福建",
        "city": "宁德市",
        "area": "柘荣县"
    }, {
        "name": "福建宁德市福安市",
        "match": "福建宁德市福安市|FJNDSFAS",
        "py": "FAS",
        "provinc": "福建",
        "city": "宁德市",
        "area": "福安市"
    }, {
        "name": "福建宁德市福鼎市",
        "match": "福建宁德市福鼎市|FJNDSFDS",
        "py": "FDS",
        "provinc": "福建",
        "city": "宁德市",
        "area": "福鼎市"
    }, {
        "name": "福建莆田市",
        "match": "福建莆田市|FJPTS",
        "py": "PTS",
        "provinc": "福建",
        "city": "莆田市",
        "area": "莆田市"
    }, {
        "name": "福建莆田市湄洲湾北岸开发区",
        "match": "福建莆田市湄洲湾北岸开发区|FJPTSMZWBAKFQ",
        "py": "MZWBAKFQ",
        "provinc": "福建",
        "city": "莆田市",
        "area": "湄洲湾北岸开发区"
    }, {
        "name": "福建莆田市湄洲岛国家旅游度假区",
        "match": "福建莆田市湄洲岛国家旅游度假区|FJPTSMZDGJLYDJQ",
        "py": "MZDGJLYDJQ",
        "provinc": "福建",
        "city": "莆田市",
        "area": "湄洲岛国家旅游度假区"
    }, {
        "name": "福建莆田市城厢区",
        "match": "福建莆田市城厢区|FJPTSCXQ",
        "py": "CXQ",
        "provinc": "福建",
        "city": "莆田市",
        "area": "城厢区"
    }, {
        "name": "福建莆田市涵江区",
        "match": "福建莆田市涵江区|FJPTSHJQ",
        "py": "HJQ",
        "provinc": "福建",
        "city": "莆田市",
        "area": "涵江区"
    }, {
        "name": "福建莆田市荔城区",
        "match": "福建莆田市荔城区|FJPTSLCQ",
        "py": "LCQ",
        "provinc": "福建",
        "city": "莆田市",
        "area": "荔城区"
    }, {
        "name": "福建莆田市秀屿区",
        "match": "福建莆田市秀屿区|FJPTSXYQ",
        "py": "XYQ",
        "provinc": "福建",
        "city": "莆田市",
        "area": "秀屿区"
    }, {
        "name": "福建莆田市仙游县",
        "match": "福建莆田市仙游县|FJPTSXYX",
        "py": "XYX",
        "provinc": "福建",
        "city": "莆田市",
        "area": "仙游县"
    }, {
        "name": "福建泉州市",
        "match": "福建泉州市|FJQZS",
        "py": "QZS",
        "provinc": "福建",
        "city": "泉州市",
        "area": "泉州市"
    }, {
        "name": "福建泉州市鲤城区",
        "match": "福建泉州市鲤城区|FJQZSLCQ",
        "py": "LCQ",
        "provinc": "福建",
        "city": "泉州市",
        "area": "鲤城区"
    }, {
        "name": "福建泉州市丰泽区",
        "match": "福建泉州市丰泽区|FJQZSFZQ",
        "py": "FZQ",
        "provinc": "福建",
        "city": "泉州市",
        "area": "丰泽区"
    }, {
        "name": "福建泉州市洛江区",
        "match": "福建泉州市洛江区|FJQZSLJQ",
        "py": "LJQ",
        "provinc": "福建",
        "city": "泉州市",
        "area": "洛江区"
    }, {
        "name": "福建泉州市泉港区",
        "match": "福建泉州市泉港区|FJQZSQGQ",
        "py": "QGQ",
        "provinc": "福建",
        "city": "泉州市",
        "area": "泉港区"
    }, {
        "name": "福建泉州市惠安县",
        "match": "福建泉州市惠安县|FJQZSHAX",
        "py": "HAX",
        "provinc": "福建",
        "city": "泉州市",
        "area": "惠安县"
    }, {
        "name": "福建泉州市安溪县",
        "match": "福建泉州市安溪县|FJQZSAXX",
        "py": "AXX",
        "provinc": "福建",
        "city": "泉州市",
        "area": "安溪县"
    }, {
        "name": "福建泉州市永春县",
        "match": "福建泉州市永春县|FJQZSYCX",
        "py": "YCX",
        "provinc": "福建",
        "city": "泉州市",
        "area": "永春县"
    }, {
        "name": "福建泉州市德化县",
        "match": "福建泉州市德化县|FJQZSDHX",
        "py": "DHX",
        "provinc": "福建",
        "city": "泉州市",
        "area": "德化县"
    }, {
        "name": "福建泉州市石狮市",
        "match": "福建泉州市石狮市|FJQZSSSS",
        "py": "SSS",
        "provinc": "福建",
        "city": "泉州市",
        "area": "石狮市"
    }, {
        "name": "福建泉州市晋江市",
        "match": "福建泉州市晋江市|FJQZSJJS",
        "py": "JJS",
        "provinc": "福建",
        "city": "泉州市",
        "area": "晋江市"
    }, {
        "name": "福建泉州市南安市",
        "match": "福建泉州市南安市|FJQZSNAS",
        "py": "NAS",
        "provinc": "福建",
        "city": "泉州市",
        "area": "南安市"
    }, {
        "name": "福建泉州市金门县",
        "match": "福建泉州市金门县|FJQZSJMX",
        "py": "JMX",
        "provinc": "福建",
        "city": "泉州市",
        "area": "金门县"
    }, {
        "name": "福建漳州市",
        "match": "福建漳州市|FJZZS",
        "py": "ZZS",
        "provinc": "福建",
        "city": "漳州市",
        "area": "漳州市"
    }, {
        "name": "福建漳州市芗城区",
        "match": "福建漳州市芗城区|FJZZSXCQ",
        "py": "XCQ",
        "provinc": "福建",
        "city": "漳州市",
        "area": "芗城区"
    }, {
        "name": "福建漳州市龙文区",
        "match": "福建漳州市龙文区|FJZZSLWQ",
        "py": "LWQ",
        "provinc": "福建",
        "city": "漳州市",
        "area": "龙文区"
    }, {
        "name": "福建漳州市云霄县",
        "match": "福建漳州市云霄县|FJZZSYXX",
        "py": "YXX",
        "provinc": "福建",
        "city": "漳州市",
        "area": "云霄县"
    }, {
        "name": "福建漳州市漳浦县",
        "match": "福建漳州市漳浦县|FJZZSZPX",
        "py": "ZPX",
        "provinc": "福建",
        "city": "漳州市",
        "area": "漳浦县"
    }, {
        "name": "福建漳州市诏安县",
        "match": "福建漳州市诏安县|FJZZS诏AX",
        "py": "诏AX",
        "provinc": "福建",
        "city": "漳州市",
        "area": "诏安县"
    }, {
        "name": "福建漳州市长泰县",
        "match": "福建漳州市长泰县|FJZZSCTX",
        "py": "CTX",
        "provinc": "福建",
        "city": "漳州市",
        "area": "长泰县"
    }, {
        "name": "福建漳州市东山县",
        "match": "福建漳州市东山县|FJZZSDSX",
        "py": "DSX",
        "provinc": "福建",
        "city": "漳州市",
        "area": "东山县"
    }, {
        "name": "福建漳州市南靖县",
        "match": "福建漳州市南靖县|FJZZSNJX",
        "py": "NJX",
        "provinc": "福建",
        "city": "漳州市",
        "area": "南靖县"
    }, {
        "name": "福建漳州市平和县",
        "match": "福建漳州市平和县|FJZZSPHX",
        "py": "PHX",
        "provinc": "福建",
        "city": "漳州市",
        "area": "平和县"
    }, {
        "name": "福建漳州市华安县",
        "match": "福建漳州市华安县|FJZZSHAX",
        "py": "HAX",
        "provinc": "福建",
        "city": "漳州市",
        "area": "华安县"
    }, {
        "name": "福建漳州市龙海市",
        "match": "福建漳州市龙海市|FJZZSLHS",
        "py": "LHS",
        "provinc": "福建",
        "city": "漳州市",
        "area": "龙海市"
    }, {
        "name": "福建龙岩市",
        "match": "福建龙岩市|FJLYS",
        "py": "LYS",
        "provinc": "福建",
        "city": "龙岩市",
        "area": "龙岩市"
    }, {
        "name": "福建龙岩市新罗区",
        "match": "福建龙岩市新罗区|FJLYSXLQ",
        "py": "XLQ",
        "provinc": "福建",
        "city": "龙岩市",
        "area": "新罗区"
    }, {
        "name": "福建龙岩市长汀县",
        "match": "福建龙岩市长汀县|FJLYSCTX",
        "py": "CTX",
        "provinc": "福建",
        "city": "龙岩市",
        "area": "长汀县"
    }, {
        "name": "福建龙岩市永定区",
        "match": "福建龙岩市永定区|FJLYSYDQ",
        "py": "YDQ",
        "provinc": "福建",
        "city": "龙岩市",
        "area": "永定区"
    }, {
        "name": "福建龙岩市上杭县",
        "match": "福建龙岩市上杭县|FJLYSSHX",
        "py": "SHX",
        "provinc": "福建",
        "city": "龙岩市",
        "area": "上杭县"
    }, {
        "name": "福建龙岩市武平县",
        "match": "福建龙岩市武平县|FJLYSWPX",
        "py": "WPX",
        "provinc": "福建",
        "city": "龙岩市",
        "area": "武平县"
    }, {
        "name": "福建龙岩市连城县",
        "match": "福建龙岩市连城县|FJLYSLCX",
        "py": "LCX",
        "provinc": "福建",
        "city": "龙岩市",
        "area": "连城县"
    }, {
        "name": "福建龙岩市漳平市",
        "match": "福建龙岩市漳平市|FJLYSZPS",
        "py": "ZPS",
        "provinc": "福建",
        "city": "龙岩市",
        "area": "漳平市"
    }, {
        "name": "福建三明市",
        "match": "福建三明市|FJSMS",
        "py": "SMS",
        "provinc": "福建",
        "city": "三明市",
        "area": "三明市"
    }, {
        "name": "福建三明市梅列区",
        "match": "福建三明市梅列区|FJSMSMLQ",
        "py": "MLQ",
        "provinc": "福建",
        "city": "三明市",
        "area": "梅列区"
    }, {
        "name": "福建三明市三元区",
        "match": "福建三明市三元区|FJSMSSYQ",
        "py": "SYQ",
        "provinc": "福建",
        "city": "三明市",
        "area": "三元区"
    }, {
        "name": "福建三明市明溪县",
        "match": "福建三明市明溪县|FJSMSMXX",
        "py": "MXX",
        "provinc": "福建",
        "city": "三明市",
        "area": "明溪县"
    }, {
        "name": "福建三明市清流县",
        "match": "福建三明市清流县|FJSMSQLX",
        "py": "QLX",
        "provinc": "福建",
        "city": "三明市",
        "area": "清流县"
    }, {
        "name": "福建三明市宁化县",
        "match": "福建三明市宁化县|FJSMSNHX",
        "py": "NHX",
        "provinc": "福建",
        "city": "三明市",
        "area": "宁化县"
    }, {
        "name": "福建三明市大田县",
        "match": "福建三明市大田县|FJSMSDTX",
        "py": "DTX",
        "provinc": "福建",
        "city": "三明市",
        "area": "大田县"
    }, {
        "name": "福建三明市尤溪县",
        "match": "福建三明市尤溪县|FJSMSYXX",
        "py": "YXX",
        "provinc": "福建",
        "city": "三明市",
        "area": "尤溪县"
    }, {
        "name": "福建三明市沙县",
        "match": "福建三明市沙县|FJSMSSX",
        "py": "SX",
        "provinc": "福建",
        "city": "三明市",
        "area": "沙县"
    }, {
        "name": "福建三明市将乐县",
        "match": "福建三明市将乐县|FJSMSJLX",
        "py": "JLX",
        "provinc": "福建",
        "city": "三明市",
        "area": "将乐县"
    }, {
        "name": "福建三明市泰宁县",
        "match": "福建三明市泰宁县|FJSMSTNX",
        "py": "TNX",
        "provinc": "福建",
        "city": "三明市",
        "area": "泰宁县"
    }, {
        "name": "福建三明市建宁县",
        "match": "福建三明市建宁县|FJSMSJNX",
        "py": "JNX",
        "provinc": "福建",
        "city": "三明市",
        "area": "建宁县"
    }, {
        "name": "福建三明市永安市",
        "match": "福建三明市永安市|FJSMSYAS",
        "py": "YAS",
        "provinc": "福建",
        "city": "三明市",
        "area": "永安市"
    }, {
        "name": "福建南平市",
        "match": "福建南平市|FJNPS",
        "py": "NPS",
        "provinc": "福建",
        "city": "南平市",
        "area": "南平市"
    }, {
        "name": "福建南平市延平区",
        "match": "福建南平市延平区|FJNPSYPQ",
        "py": "YPQ",
        "provinc": "福建",
        "city": "南平市",
        "area": "延平区"
    }, {
        "name": "福建南平市顺昌县",
        "match": "福建南平市顺昌县|FJNPSSCX",
        "py": "SCX",
        "provinc": "福建",
        "city": "南平市",
        "area": "顺昌县"
    }, {
        "name": "福建南平市浦城县",
        "match": "福建南平市浦城县|FJNPSPCX",
        "py": "PCX",
        "provinc": "福建",
        "city": "南平市",
        "area": "浦城县"
    }, {
        "name": "福建南平市光泽县",
        "match": "福建南平市光泽县|FJNPSGZX",
        "py": "GZX",
        "provinc": "福建",
        "city": "南平市",
        "area": "光泽县"
    }, {
        "name": "福建南平市松溪县",
        "match": "福建南平市松溪县|FJNPSSXX",
        "py": "SXX",
        "provinc": "福建",
        "city": "南平市",
        "area": "松溪县"
    }, {
        "name": "福建南平市政和县",
        "match": "福建南平市政和县|FJNPSZHX",
        "py": "ZHX",
        "provinc": "福建",
        "city": "南平市",
        "area": "政和县"
    }, {
        "name": "福建南平市邵武市",
        "match": "福建南平市邵武市|FJNPSSWS",
        "py": "SWS",
        "provinc": "福建",
        "city": "南平市",
        "area": "邵武市"
    }, {
        "name": "福建南平市武夷山市",
        "match": "福建南平市武夷山市|FJNPSWYSS",
        "py": "WYSS",
        "provinc": "福建",
        "city": "南平市",
        "area": "武夷山市"
    }, {
        "name": "福建南平市建瓯市",
        "match": "福建南平市建瓯市|FJNPSJOS",
        "py": "JOS",
        "provinc": "福建",
        "city": "南平市",
        "area": "建瓯市"
    }, {
        "name": "福建南平市建阳区",
        "match": "福建南平市建阳区|FJNPSJYQ",
        "py": "JYQ",
        "provinc": "福建",
        "city": "南平市",
        "area": "建阳区"
    }, {
        "name": "西藏拉萨市",
        "match": "西藏拉萨市|XZLSS",
        "py": "LSS",
        "provinc": "西藏",
        "city": "拉萨市",
        "area": "拉萨市"
    }, {
        "name": "西藏拉萨市城关区",
        "match": "西藏拉萨市城关区|XZLSSCGQ",
        "py": "CGQ",
        "provinc": "西藏",
        "city": "拉萨市",
        "area": "城关区"
    }, {
        "name": "西藏拉萨市林周县",
        "match": "西藏拉萨市林周县|XZLSSLZX",
        "py": "LZX",
        "provinc": "西藏",
        "city": "拉萨市",
        "area": "林周县"
    }, {
        "name": "西藏拉萨市当雄县",
        "match": "西藏拉萨市当雄县|XZLSSDXX",
        "py": "DXX",
        "provinc": "西藏",
        "city": "拉萨市",
        "area": "当雄县"
    }, {
        "name": "西藏拉萨市尼木县",
        "match": "西藏拉萨市尼木县|XZLSSNMX",
        "py": "NMX",
        "provinc": "西藏",
        "city": "拉萨市",
        "area": "尼木县"
    }, {
        "name": "西藏拉萨市曲水县",
        "match": "西藏拉萨市曲水县|XZLSSQSX",
        "py": "QSX",
        "provinc": "西藏",
        "city": "拉萨市",
        "area": "曲水县"
    }, {
        "name": "西藏拉萨市堆龙德庆区",
        "match": "西藏拉萨市堆龙德庆区|XZLSSDLDQQ",
        "py": "DLDQQ",
        "provinc": "西藏",
        "city": "拉萨市",
        "area": "堆龙德庆区"
    }, {
        "name": "西藏拉萨市达孜区",
        "match": "西藏拉萨市达孜区|XZLSSDZQ",
        "py": "DZQ",
        "provinc": "西藏",
        "city": "拉萨市",
        "area": "达孜区"
    }, {
        "name": "西藏拉萨市墨竹工卡县",
        "match": "西藏拉萨市墨竹工卡县|XZLSSMZGKX",
        "py": "MZGKX",
        "provinc": "西藏",
        "city": "拉萨市",
        "area": "墨竹工卡县"
    }, {
        "name": "西藏日喀则市",
        "match": "西藏日喀则市|XZRKZDQ",
        "py": "RKZDQ",
        "provinc": "西藏",
        "city": "日喀则市",
        "area": "日喀则市"
    }, /*{
        "name": "西藏日喀则市",
        "match": "西藏日喀则市|XZRKZS",
        "py": "RKZS",
        "provinc": "西藏",
        "city": "日喀则市",
        "area": "日喀则市"
    },*/

    /*以上项改为以下：*/
    {
        "name": "西藏日喀则市桑珠孜区",
        "match": "西藏日喀则市桑珠孜区|XZRKZDQSZZQ",
        "py": "SZZQ",
        "provinc": "西藏",
        "city": "日喀则市",
        "area": "桑珠孜区"
    },

    {
        "name": "西藏日喀则市南木林县",
        "match": "西藏日喀则市南木林县|XZRKZDQNMLX",
        "py": "NMLX",
        "provinc": "西藏",
        "city": "日喀则市",
        "area": "南木林县"
    }, {
        "name": "西藏日喀则市江孜县",
        "match": "西藏日喀则市江孜县|XZRKZDQJZX",
        "py": "JZX",
        "provinc": "西藏",
        "city": "日喀则市",
        "area": "江孜县"
    }, {
        "name": "西藏日喀则市定日县",
        "match": "西藏日喀则市定日县|XZRKZDQDRX",
        "py": "DRX",
        "provinc": "西藏",
        "city": "日喀则市",
        "area": "定日县"
    }, {
        "name": "西藏日喀则市萨迦县",
        "match": "西藏日喀则市萨迦县|XZRKZDQSJX",
        "py": "SJX",
        "provinc": "西藏",
        "city": "日喀则市",
        "area": "萨迦县"
    }, {
        "name": "西藏日喀则市拉孜县",
        "match": "西藏日喀则市拉孜县|XZRKZDQLZX",
        "py": "LZX",
        "provinc": "西藏",
        "city": "日喀则市",
        "area": "拉孜县"
    }, {
        "name": "西藏日喀则市昂仁县",
        "match": "西藏日喀则市昂仁县|XZRKZDQARX",
        "py": "ARX",
        "provinc": "西藏",
        "city": "日喀则市",
        "area": "昂仁县"
    }, {
        "name": "西藏日喀则市谢通门县",
        "match": "西藏日喀则市谢通门县|XZRKZDQXTMX",
        "py": "XTMX",
        "provinc": "西藏",
        "city": "日喀则市",
        "area": "谢通门县"
    }, {
        "name": "西藏日喀则市白朗县",
        "match": "西藏日喀则市白朗县|XZRKZDQBLX",
        "py": "BLX",
        "provinc": "西藏",
        "city": "日喀则市",
        "area": "白朗县"
    }, {
        "name": "西藏日喀则市仁布县",
        "match": "西藏日喀则市仁布县|XZRKZDQRBX",
        "py": "RBX",
        "provinc": "西藏",
        "city": "日喀则市",
        "area": "仁布县"
    }, {
        "name": "西藏日喀则市康马县",
        "match": "西藏日喀则市康马县|XZRKZDQKMX",
        "py": "KMX",
        "provinc": "西藏",
        "city": "日喀则市",
        "area": "康马县"
    }, {
        "name": "西藏日喀则市定结县",
        "match": "西藏日喀则市定结县|XZRKZDQDJX",
        "py": "DJX",
        "provinc": "西藏",
        "city": "日喀则市",
        "area": "定结县"
    }, {
        "name": "西藏日喀则市仲巴县",
        "match": "西藏日喀则市仲巴县|XZRKZDQZBX",
        "py": "ZBX",
        "provinc": "西藏",
        "city": "日喀则市",
        "area": "仲巴县"
    }, {
        "name": "西藏日喀则市亚东县",
        "match": "西藏日喀则市亚东县|XZRKZDQYDX",
        "py": "YDX",
        "provinc": "西藏",
        "city": "日喀则市",
        "area": "亚东县"
    }, {
        "name": "西藏日喀则市吉隆县",
        "match": "西藏日喀则市吉隆县|XZRKZDQJLX",
        "py": "JLX",
        "provinc": "西藏",
        "city": "日喀则市",
        "area": "吉隆县"
    }, {
        "name": "西藏日喀则市聂拉木县",
        "match": "西藏日喀则市聂拉木县|XZRKZDQNLMX",
        "py": "NLMX",
        "provinc": "西藏",
        "city": "日喀则市",
        "area": "聂拉木县"
    }, {
        "name": "西藏日喀则市萨嘎县",
        "match": "西藏日喀则市萨嘎县|XZRKZDQSGX",
        "py": "SGX",
        "provinc": "西藏",
        "city": "日喀则市",
        "area": "萨嘎县"
    }, {
        "name": "西藏日喀则市岗巴县",
        "match": "西藏日喀则市岗巴县|XZRKZDQGBX",
        "py": "GBX",
        "provinc": "西藏",
        "city": "日喀则市",
        "area": "岗巴县"
    }, {
        "name": "西藏山南市",
        "match": "西藏山南市|XZSNDQ",
        "py": "SNDQ",
        "provinc": "西藏",
        "city": "山南市",
        "area": "山南市"
    }, {
        "name": "西藏山南市乃东区",
        "match": "西藏山南市乃东区|XZSNDQNDQ",
        "py": "NDQ",
        "provinc": "西藏",
        "city": "山南市",
        "area": "乃东区"
    }, {
        "name": "西藏山南市扎囊县",
        "match": "西藏山南市扎囊县|XZSNDQZNX",
        "py": "ZNX",
        "provinc": "西藏",
        "city": "山南市",
        "area": "扎囊县"
    }, {
        "name": "西藏山南市贡嘎县",
        "match": "西藏山南市贡嘎县|XZSNDQGGX",
        "py": "GGX",
        "provinc": "西藏",
        "city": "山南市",
        "area": "贡嘎县"
    }, {
        "name": "西藏山南市桑日县",
        "match": "西藏山南市桑日县|XZSNDQSRX",
        "py": "SRX",
        "provinc": "西藏",
        "city": "山南市",
        "area": "桑日县"
    }, {
        "name": "西藏山南市琼结县",
        "match": "西藏山南市琼结县|XZSNDQQJX",
        "py": "QJX",
        "provinc": "西藏",
        "city": "山南市",
        "area": "琼结县"
    }, {
        "name": "西藏山南市曲松县",
        "match": "西藏山南市曲松县|XZSNDQQSX",
        "py": "QSX",
        "provinc": "西藏",
        "city": "山南市",
        "area": "曲松县"
    }, {
        "name": "西藏山南市措美县",
        "match": "西藏山南市措美县|XZSNDQCMX",
        "py": "CMX",
        "provinc": "西藏",
        "city": "山南市",
        "area": "措美县"
    }, {
        "name": "西藏山南市洛扎县",
        "match": "西藏山南市洛扎县|XZSNDQLZX",
        "py": "LZX",
        "provinc": "西藏",
        "city": "山南市",
        "area": "洛扎县"
    }, {
        "name": "西藏山南市加查县",
        "match": "西藏山南市加查县|XZSNDQJCX",
        "py": "JCX",
        "provinc": "西藏",
        "city": "山南市",
        "area": "加查县"
    }, {
        "name": "西藏山南市隆子县",
        "match": "西藏山南市隆子县|XZSNDQLZX",
        "py": "LZX",
        "provinc": "西藏",
        "city": "山南市",
        "area": "隆子县"
    }, {
        "name": "西藏山南市错那县",
        "match": "西藏山南市错那县|XZSNDQCNX",
        "py": "CNX",
        "provinc": "西藏",
        "city": "山南市",
        "area": "错那县"
    }, {
        "name": "西藏山南市浪卡子县",
        "match": "西藏山南市浪卡子县|XZSNDQLKZX",
        "py": "LKZX",
        "provinc": "西藏",
        "city": "山南市",
        "area": "浪卡子县"
    }, {
        "name": "西藏林芝市",
        "match": "西藏林芝市|XZLZDQ",
        "py": "LZDQ",
        "provinc": "西藏",
        "city": "林芝市",
        "area": "林芝市"
    }, {
        "name": "西藏林芝市巴宜区",
        "match": "西藏林芝市巴宜区|XZLZDQBYQ",
        "py": "BYQ",
        "provinc": "西藏",
        "city": "林芝市",
        "area": "巴宜区"
    }, {
        "name": "西藏林芝市工布江达县",
        "match": "西藏林芝市工布江达县|XZLZDQGBJDX",
        "py": "GBJDX",
        "provinc": "西藏",
        "city": "林芝市",
        "area": "工布江达县"
    }, {
        "name": "西藏林芝市米林县",
        "match": "西藏林芝市米林县|XZLZDQMLX",
        "py": "MLX",
        "provinc": "西藏",
        "city": "林芝市",
        "area": "米林县"
    }, {
        "name": "西藏林芝市墨脱县",
        "match": "西藏林芝市墨脱县|XZLZDQMTX",
        "py": "MTX",
        "provinc": "西藏",
        "city": "林芝市",
        "area": "墨脱县"
    }, {
        "name": "西藏林芝市波密县",
        "match": "西藏林芝市波密县|XZLZDQBMX",
        "py": "BMX",
        "provinc": "西藏",
        "city": "林芝市",
        "area": "波密县"
    }, {
        "name": "西藏林芝市察隅县",
        "match": "西藏林芝市察隅县|XZLZDQCYX",
        "py": "CYX",
        "provinc": "西藏",
        "city": "林芝市",
        "area": "察隅县"
    }, {
        "name": "西藏林芝市朗县",
        "match": "西藏林芝市朗县|XZLZDQLX",
        "py": "LX",
        "provinc": "西藏",
        "city": "林芝市",
        "area": "朗县"
    }, {
        "name": "西藏昌都市",
        "match": "西藏昌都市|XZCDDQ",
        "py": "CDDQ",
        "provinc": "西藏",
        "city": "昌都市",
        "area": "昌都市"
    }, {
        "name": "西藏昌都市卡若区",
        "match": "西藏昌都市卡若区|XZCDDQKRQ",
        "py": "KRQ",
        "provinc": "西藏",
        "city": "昌都市",
        "area": "卡若区"
    }, {
        "name": "西藏昌都市江达县",
        "match": "西藏昌都市江达县|XZCDDQJDX",
        "py": "JDX",
        "provinc": "西藏",
        "city": "昌都市",
        "area": "江达县"
    }, {
        "name": "西藏昌都市贡觉县",
        "match": "西藏昌都市贡觉县|XZCDDQGJX",
        "py": "GJX",
        "provinc": "西藏",
        "city": "昌都市",
        "area": "贡觉县"
    }, {
        "name": "西藏昌都市类乌齐县",
        "match": "西藏昌都市类乌齐县|XZCDDQLWQX",
        "py": "LWQX",
        "provinc": "西藏",
        "city": "昌都市",
        "area": "类乌齐县"
    }, {
        "name": "西藏昌都市丁青县",
        "match": "西藏昌都市丁青县|XZCDDQDQX",
        "py": "DQX",
        "provinc": "西藏",
        "city": "昌都市",
        "area": "丁青县"
    }, {
        "name": "西藏昌都市察雅县",
        "match": "西藏昌都市察雅县|XZCDDQCYX",
        "py": "CYX",
        "provinc": "西藏",
        "city": "昌都市",
        "area": "察雅县"
    }, {
        "name": "西藏昌都市八宿县",
        "match": "西藏昌都市八宿县|XZCDDQBSX",
        "py": "BSX",
        "provinc": "西藏",
        "city": "昌都市",
        "area": "八宿县"
    }, {
        "name": "西藏昌都市左贡县",
        "match": "西藏昌都市左贡县|XZCDDQZGX",
        "py": "ZGX",
        "provinc": "西藏",
        "city": "昌都市",
        "area": "左贡县"
    }, {
        "name": "西藏昌都市芒康县",
        "match": "西藏昌都市芒康县|XZCDDQMKX",
        "py": "MKX",
        "provinc": "西藏",
        "city": "昌都市",
        "area": "芒康县"
    }, {
        "name": "西藏昌都市洛隆县",
        "match": "西藏昌都市洛隆县|XZCDDQLLX",
        "py": "LLX",
        "provinc": "西藏",
        "city": "昌都市",
        "area": "洛隆县"
    }, {
        "name": "西藏昌都市边坝县",
        "match": "西藏昌都市边坝县|XZCDDQBBX",
        "py": "BBX",
        "provinc": "西藏",
        "city": "昌都市",
        "area": "边坝县"
    }, {
        "name": "西藏那曲市",
        "match": "西藏那曲市|XZNQDQ",
        "py": "NQDQ",
        "provinc": "西藏",
        "city": "那曲市",
        "area": "那曲市"
    }, {
        "name": "西藏那曲市色尼区",
        "match": "西藏那曲市色尼区|XZNQDQSNQ",
        "py": "SNQ",
        "provinc": "西藏",
        "city": "那曲市",
        "area": "色尼区"
    }, {
        "name": "西藏那曲市嘉黎县",
        "match": "西藏那曲市嘉黎县|XZNQDQJLX",
        "py": "JLX",
        "provinc": "西藏",
        "city": "那曲市",
        "area": "嘉黎县"
    }, {
        "name": "西藏那曲市比如县",
        "match": "西藏那曲市比如县|XZNQDQBRX",
        "py": "BRX",
        "provinc": "西藏",
        "city": "那曲市",
        "area": "比如县"
    }, {
        "name": "西藏那曲市聂荣县",
        "match": "西藏那曲市聂荣县|XZNQDQNRX",
        "py": "NRX",
        "provinc": "西藏",
        "city": "那曲市",
        "area": "聂荣县"
    }, {
        "name": "西藏那曲市安多县",
        "match": "西藏那曲市安多县|XZNQDQADX",
        "py": "ADX",
        "provinc": "西藏",
        "city": "那曲市",
        "area": "安多县"
    }, {
        "name": "西藏那曲市申扎县",
        "match": "西藏那曲市申扎县|XZNQDQSZX",
        "py": "SZX",
        "provinc": "西藏",
        "city": "那曲市",
        "area": "申扎县"
    }, {
        "name": "西藏那曲市索县",
        "match": "西藏那曲市索县|XZNQDQSX",
        "py": "SX",
        "provinc": "西藏",
        "city": "那曲市",
        "area": "索县"
    }, {
        "name": "西藏那曲市班戈县",
        "match": "西藏那曲市班戈县|XZNQDQBGX",
        "py": "BGX",
        "provinc": "西藏",
        "city": "那曲市",
        "area": "班戈县"
    }, {
        "name": "西藏那曲市巴青县",
        "match": "西藏那曲市巴青县|XZNQDQBQX",
        "py": "BQX",
        "provinc": "西藏",
        "city": "那曲市",
        "area": "巴青县"
    }, {
        "name": "西藏那曲市尼玛县",
        "match": "西藏那曲市尼玛县|XZNQDQNMX",
        "py": "NMX",
        "provinc": "西藏",
        "city": "那曲市",
        "area": "尼玛县"
    }, {
        "name": "西藏那曲市双湖县",
        "match": "西藏那曲市双湖县|XZNQDQSHX",
        "py": "SHX",
        "provinc": "西藏",
        "city": "那曲市",
        "area": "双湖县"
    }, {
        "name": "西藏阿里地区",
        "match": "西藏阿里地区|XZALDQ",
        "py": "ALDQ",
        "provinc": "西藏",
        "city": "阿里地区",
        "area": "阿里地区"
    }, {
        "name": "西藏阿里地区普兰县",
        "match": "西藏阿里地区普兰县|XZALDQPLX",
        "py": "PLX",
        "provinc": "西藏",
        "city": "阿里地区",
        "area": "普兰县"
    }, {
        "name": "西藏阿里地区札达县",
        "match": "西藏阿里地区札达县|XZALDQZDX",
        "py": "ZDX",
        "provinc": "西藏",
        "city": "阿里地区",
        "area": "札达县"
    }, {
        "name": "西藏阿里地区噶尔县",
        "match": "西藏阿里地区噶尔县|XZALDQGEX",
        "py": "GEX",
        "provinc": "西藏",
        "city": "阿里地区",
        "area": "噶尔县"
    }, {
        "name": "西藏阿里地区日土县",
        "match": "西藏阿里地区日土县|XZALDQRTX",
        "py": "RTX",
        "provinc": "西藏",
        "city": "阿里地区",
        "area": "日土县"
    }, {
        "name": "西藏阿里地区革吉县",
        "match": "西藏阿里地区革吉县|XZALDQGJX",
        "py": "GJX",
        "provinc": "西藏",
        "city": "阿里地区",
        "area": "革吉县"
    }, {
        "name": "西藏阿里地区改则县",
        "match": "西藏阿里地区改则县|XZALDQGZX",
        "py": "GZX",
        "provinc": "西藏",
        "city": "阿里地区",
        "area": "改则县"
    }, {
        "name": "西藏阿里地区措勤县",
        "match": "西藏阿里地区措勤县|XZALDQCQX",
        "py": "CQX",
        "provinc": "西藏",
        "city": "阿里地区",
        "area": "措勤县"
    }, {
        "name": "贵州贵阳市",
        "match": "贵州贵阳市|GZGYS",
        "py": "GYS",
        "provinc": "贵州",
        "city": "贵阳市",
        "area": "贵阳市"
    }, {
        "name": "贵州贵阳市南明区",
        "match": "贵州贵阳市南明区|GZGYSNMQ",
        "py": "NMQ",
        "provinc": "贵州",
        "city": "贵阳市",
        "area": "南明区"
    }, {
        "name": "贵州贵阳市云岩区",
        "match": "贵州贵阳市云岩区|GZGYSYYQ",
        "py": "YYQ",
        "provinc": "贵州",
        "city": "贵阳市",
        "area": "云岩区"
    }, {
        "name": "贵州贵阳市花溪区",
        "match": "贵州贵阳市花溪区|GZGYSHXQ",
        "py": "HXQ",
        "provinc": "贵州",
        "city": "贵阳市",
        "area": "花溪区"
    }, {
        "name": "贵州贵阳市乌当区",
        "match": "贵州贵阳市乌当区|GZGYSWDQ",
        "py": "WDQ",
        "provinc": "贵州",
        "city": "贵阳市",
        "area": "乌当区"
    }, {
        "name": "贵州贵阳市白云区",
        "match": "贵州贵阳市白云区|GZGYSBYQ",
        "py": "BYQ",
        "provinc": "贵州",
        "city": "贵阳市",
        "area": "白云区"
    }, {
        "name": "贵州贵阳市小河区",
        "match": "贵州贵阳市小河区|GZGYSXHQ",
        "py": "XHQ",
        "provinc": "贵州",
        "city": "贵阳市",
        "area": "小河区"
    }, {
        "name": "贵州贵阳市开阳县",
        "match": "贵州贵阳市开阳县|GZGYSKYX",
        "py": "KYX",
        "provinc": "贵州",
        "city": "贵阳市",
        "area": "开阳县"
    }, {
        "name": "贵州贵阳市息烽县",
        "match": "贵州贵阳市息烽县|GZGYSXFX",
        "py": "XFX",
        "provinc": "贵州",
        "city": "贵阳市",
        "area": "息烽县"
    }, {
        "name": "贵州贵阳市修文县",
        "match": "贵州贵阳市修文县|GZGYSXWX",
        "py": "XWX",
        "provinc": "贵州",
        "city": "贵阳市",
        "area": "修文县"
    }, {
        "name": "贵州贵阳市清镇市",
        "match": "贵州贵阳市清镇市|GZGYSQZS",
        "py": "QZS",
        "provinc": "贵州",
        "city": "贵阳市",
        "area": "清镇市"
    }, {
        "name": "贵州遵义市",
        "match": "贵州遵义市|GZZYS",
        "py": "ZYS",
        "provinc": "贵州",
        "city": "遵义市",
        "area": "遵义市"
    }, {
        "name": "贵州遵义市红花岗区",
        "match": "贵州遵义市红花岗区|GZZYSHHGQ",
        "py": "HHGQ",
        "provinc": "贵州",
        "city": "遵义市",
        "area": "红花岗区"
    }, {
        "name": "贵州遵义市汇川区",
        "match": "贵州遵义市汇川区|GZZYSHCQ",
        "py": "HCQ",
        "provinc": "贵州",
        "city": "遵义市",
        "area": "汇川区"
    }, {
        "name": "贵州遵义市播州区",
        "match": "贵州遵义市播州区|GZZYSBZQ",
        "py": "BZQ",
        "provinc": "贵州",
        "city": "遵义市",
        "area": "播州区"
    }, {
        "name": "贵州遵义市桐梓县",
        "match": "贵州遵义市桐梓县|GZZYSTZX",
        "py": "TZX",
        "provinc": "贵州",
        "city": "遵义市",
        "area": "桐梓县"
    }, {
        "name": "贵州遵义市绥阳县",
        "match": "贵州遵义市绥阳县|GZZYSSYX",
        "py": "SYX",
        "provinc": "贵州",
        "city": "遵义市",
        "area": "绥阳县"
    }, {
        "name": "贵州遵义市正安县",
        "match": "贵州遵义市正安县|GZZYSZAX",
        "py": "ZAX",
        "provinc": "贵州",
        "city": "遵义市",
        "area": "正安县"
    }, {
        "name": "贵州遵义市道真县",
        "match": "贵州遵义市道真县|GZZYSDZZZX",
        "py": "DZZZX",
        "provinc": "贵州",
        "city": "遵义市",
        "area": "道真县"
    }, {
        "name": "贵州遵义市务川县",
        "match": "贵州遵义市务川县|GZZYSWCZZX",
        "py": "WCZZX",
        "provinc": "贵州",
        "city": "遵义市",
        "area": "务川县"
    }, {
        "name": "贵州遵义市凤冈县",
        "match": "贵州遵义市凤冈县|GZZYSFGX",
        "py": "FGX",
        "provinc": "贵州",
        "city": "遵义市",
        "area": "凤冈县"
    }, {
        "name": "贵州遵义市湄潭县",
        "match": "贵州遵义市湄潭县|GZZYSMTX",
        "py": "MTX",
        "provinc": "贵州",
        "city": "遵义市",
        "area": "湄潭县"
    }, {
        "name": "贵州遵义市余庆县",
        "match": "贵州遵义市余庆县|GZZYSYQX",
        "py": "YQX",
        "provinc": "贵州",
        "city": "遵义市",
        "area": "余庆县"
    }, {
        "name": "贵州遵义市习水县",
        "match": "贵州遵义市习水县|GZZYSXSX",
        "py": "XSX",
        "provinc": "贵州",
        "city": "遵义市",
        "area": "习水县"
    }, {
        "name": "贵州遵义市赤水市",
        "match": "贵州遵义市赤水市|GZZYSCSS",
        "py": "CSS",
        "provinc": "贵州",
        "city": "遵义市",
        "area": "赤水市"
    }, {
        "name": "贵州遵义市仁怀市",
        "match": "贵州遵义市仁怀市|GZZYSRHS",
        "py": "RHS",
        "provinc": "贵州",
        "city": "遵义市",
        "area": "仁怀市"
    }, {
        "name": "贵州安顺市",
        "match": "贵州安顺市|GZASS",
        "py": "ASS",
        "provinc": "贵州",
        "city": "安顺市",
        "area": "安顺市"
    }, {
        "name": "贵州安顺市西秀区",
        "match": "贵州安顺市西秀区|GZASSXXQ",
        "py": "XXQ",
        "provinc": "贵州",
        "city": "安顺市",
        "area": "西秀区"
    }, {
        "name": "贵州安顺市平坝区",
        "match": "贵州安顺市平坝区|GZASSPBQ",
        "py": "PBQ",
        "provinc": "贵州",
        "city": "安顺市",
        "area": "平坝区"
    }, {
        "name": "贵州安顺市普定县",
        "match": "贵州安顺市普定县|GZASSPDX",
        "py": "PDX",
        "provinc": "贵州",
        "city": "安顺市",
        "area": "普定县"
    }, {
        "name": "贵州安顺市镇宁县",
        "match": "贵州安顺市镇宁县|GZASSZNZZX",
        "py": "ZNZZX",
        "provinc": "贵州",
        "city": "安顺市",
        "area": "镇宁县"
    }, {
        "name": "贵州安顺市关岭县",
        "match": "贵州安顺市关岭县|GZASSGLZZX",
        "py": "GLZZX",
        "provinc": "贵州",
        "city": "安顺市",
        "area": "关岭县"
    }, {
        "name": "贵州安顺市紫云县",
        "match": "贵州安顺市紫云县|GZASSZYZZX",
        "py": "ZYZZX",
        "provinc": "贵州",
        "city": "安顺市",
        "area": "紫云县"
    }, {
        "name": "贵州黔南州",
        "match": "贵州黔南州|GZQNZZZ",
        "py": "QNZZZ",
        "provinc": "贵州",
        "city": "黔南州",
        "area": "黔南州"
    }, {
        "name": "贵州黔南州都匀市",
        "match": "贵州黔南州都匀市|GZQNZZZDYS",
        "py": "DYS",
        "provinc": "贵州",
        "city": "黔南州",
        "area": "都匀市"
    }, {
        "name": "贵州黔南州福泉市",
        "match": "贵州黔南州福泉市|GZQNZZZFQS",
        "py": "FQS",
        "provinc": "贵州",
        "city": "黔南州",
        "area": "福泉市"
    }, {
        "name": "贵州黔南州荔波县",
        "match": "贵州黔南州荔波县|GZQNZZZLBX",
        "py": "LBX",
        "provinc": "贵州",
        "city": "黔南州",
        "area": "荔波县"
    }, {
        "name": "贵州黔南州贵定县",
        "match": "贵州黔南州贵定县|GZQNZZZGDX",
        "py": "GDX",
        "provinc": "贵州",
        "city": "黔南州",
        "area": "贵定县"
    }, {
        "name": "贵州黔南州瓮安县",
        "match": "贵州黔南州瓮安县|GZQNZZZWAX",
        "py": "WAX",
        "provinc": "贵州",
        "city": "黔南州",
        "area": "瓮安县"
    }, {
        "name": "贵州黔南州独山县",
        "match": "贵州黔南州独山县|GZQNZZZDSX",
        "py": "DSX",
        "provinc": "贵州",
        "city": "黔南州",
        "area": "独山县"
    }, {
        "name": "贵州黔南州平塘县",
        "match": "贵州黔南州平塘县|GZQNZZZPTX",
        "py": "PTX",
        "provinc": "贵州",
        "city": "黔南州",
        "area": "平塘县"
    }, {
        "name": "贵州黔南州罗甸县",
        "match": "贵州黔南州罗甸县|GZQNZZZLDX",
        "py": "LDX",
        "provinc": "贵州",
        "city": "黔南州",
        "area": "罗甸县"
    }, {
        "name": "贵州黔南州长顺县",
        "match": "贵州黔南州长顺县|GZQNZZZCSX",
        "py": "CSX",
        "provinc": "贵州",
        "city": "黔南州",
        "area": "长顺县"
    }, {
        "name": "贵州黔南州龙里县",
        "match": "贵州黔南州龙里县|GZQNZZZLLX",
        "py": "LLX",
        "provinc": "贵州",
        "city": "黔南州",
        "area": "龙里县"
    }, {
        "name": "贵州黔南州惠水县",
        "match": "贵州黔南州惠水县|GZQNZZZHSX",
        "py": "HSX",
        "provinc": "贵州",
        "city": "黔南州",
        "area": "惠水县"
    }, {
        "name": "贵州黔南州三都县",
        "match": "贵州黔南州三都县|GZQNZZZSDZZX",
        "py": "SDZZX",
        "provinc": "贵州",
        "city": "黔南州",
        "area": "三都县"
    }, {
        "name": "贵州黔东南州",
        "match": "贵州黔东南州|GZQDNZZZ",
        "py": "QDNZZZ",
        "provinc": "贵州",
        "city": "黔东南州",
        "area": "黔东南州"
    }, {
        "name": "贵州黔东南州凯里市",
        "match": "贵州黔东南州凯里市|GZQDNZZZKLS",
        "py": "KLS",
        "provinc": "贵州",
        "city": "黔东南州",
        "area": "凯里市"
    }, {
        "name": "贵州黔东南州黄平县",
        "match": "贵州黔东南州黄平县|GZQDNZZZHPX",
        "py": "HPX",
        "provinc": "贵州",
        "city": "黔东南州",
        "area": "黄平县"
    }, {
        "name": "贵州黔东南州施秉县",
        "match": "贵州黔东南州施秉县|GZQDNZZZSBX",
        "py": "SBX",
        "provinc": "贵州",
        "city": "黔东南州",
        "area": "施秉县"
    }, {
        "name": "贵州黔东南州三穗县",
        "match": "贵州黔东南州三穗县|GZQDNZZZSSX",
        "py": "SSX",
        "provinc": "贵州",
        "city": "黔东南州",
        "area": "三穗县"
    }, {
        "name": "贵州黔东南州镇远县",
        "match": "贵州黔东南州镇远县|GZQDNZZZZYX",
        "py": "ZYX",
        "provinc": "贵州",
        "city": "黔东南州",
        "area": "镇远县"
    }, {
        "name": "贵州黔东南州岑巩县",
        "match": "贵州黔东南州岑巩县|GZQDNZZZCGX",
        "py": "CGX",
        "provinc": "贵州",
        "city": "黔东南州",
        "area": "岑巩县"
    }, {
        "name": "贵州黔东南州天柱县",
        "match": "贵州黔东南州天柱县|GZQDNZZZTZX",
        "py": "TZX",
        "provinc": "贵州",
        "city": "黔东南州",
        "area": "天柱县"
    }, {
        "name": "贵州黔东南州锦屏县",
        "match": "贵州黔东南州锦屏县|GZQDNZZZJPX",
        "py": "JPX",
        "provinc": "贵州",
        "city": "黔东南州",
        "area": "锦屏县"
    }, {
        "name": "贵州黔东南州剑河县",
        "match": "贵州黔东南州剑河县|GZQDNZZZJHX",
        "py": "JHX",
        "provinc": "贵州",
        "city": "黔东南州",
        "area": "剑河县"
    }, {
        "name": "贵州黔东南州台江县",
        "match": "贵州黔东南州台江县|GZQDNZZZTJX",
        "py": "TJX",
        "provinc": "贵州",
        "city": "黔东南州",
        "area": "台江县"
    }, {
        "name": "贵州黔东南州黎平县",
        "match": "贵州黔东南州黎平县|GZQDNZZZLPX",
        "py": "LPX",
        "provinc": "贵州",
        "city": "黔东南州",
        "area": "黎平县"
    }, {
        "name": "贵州黔东南州榕江县",
        "match": "贵州黔东南州榕江县|GZQDNZZZRJX",
        "py": "RJX",
        "provinc": "贵州",
        "city": "黔东南州",
        "area": "榕江县"
    }, {
        "name": "贵州黔东南州从江县",
        "match": "贵州黔东南州从江县|GZQDNZZZCJX",
        "py": "CJX",
        "provinc": "贵州",
        "city": "黔东南州",
        "area": "从江县"
    }, {
        "name": "贵州黔东南州雷山县",
        "match": "贵州黔东南州雷山县|GZQDNZZZLSX",
        "py": "LSX",
        "provinc": "贵州",
        "city": "黔东南州",
        "area": "雷山县"
    }, {
        "name": "贵州黔东南州麻江县",
        "match": "贵州黔东南州麻江县|GZQDNZZZMJX",
        "py": "MJX",
        "provinc": "贵州",
        "city": "黔东南州",
        "area": "麻江县"
    }, {
        "name": "贵州黔东南州丹寨县",
        "match": "贵州黔东南州丹寨县|GZQDNZZZDZX",
        "py": "DZX",
        "provinc": "贵州",
        "city": "黔东南州",
        "area": "丹寨县"
    }, {
        "name": "贵州铜仁市",
        "match": "贵州铜仁市|GZTRDQ",
        "py": "TRDQ",
        "provinc": "贵州",
        "city": "铜仁市",
        "area": "铜仁市"
    }, {
        "name": "贵州铜仁市碧江区",
        "match": "贵州铜仁市碧江区|GZTRDQTRS",
        "py": "TRS",
        "provinc": "贵州",
        "city": "铜仁市",
        "area": "碧江区"
    }, {
        "name": "贵州铜仁市江口县",
        "match": "贵州铜仁市江口县|GZTRDQJKX",
        "py": "JKX",
        "provinc": "贵州",
        "city": "铜仁市",
        "area": "江口县"
    }, {
        "name": "贵州铜仁市玉屏县",
        "match": "贵州铜仁市玉屏县|GZTRDQYPZZX",
        "py": "YPZZX",
        "provinc": "贵州",
        "city": "铜仁市",
        "area": "玉屏县"
    }, {
        "name": "贵州铜仁市石阡县",
        "match": "贵州铜仁市石阡县|GZTRDQSQX",
        "py": "SQX",
        "provinc": "贵州",
        "city": "铜仁市",
        "area": "石阡县"
    }, {
        "name": "贵州铜仁市思南县",
        "match": "贵州铜仁市思南县|GZTRDQSNX",
        "py": "SNX",
        "provinc": "贵州",
        "city": "铜仁市",
        "area": "思南县"
    }, {
        "name": "贵州铜仁市印江县",
        "match": "贵州铜仁市印江县|GZTRDQYJZZX",
        "py": "YJZZX",
        "provinc": "贵州",
        "city": "铜仁市",
        "area": "印江县"
    }, {
        "name": "贵州铜仁市德江县",
        "match": "贵州铜仁市德江县|GZTRDQDJX",
        "py": "DJX",
        "provinc": "贵州",
        "city": "铜仁市",
        "area": "德江县"
    }, {
        "name": "贵州铜仁市沿河县",
        "match": "贵州铜仁市沿河县|GZTRDQYHZZX",
        "py": "YHZZX",
        "provinc": "贵州",
        "city": "铜仁市",
        "area": "沿河县"
    }, {
        "name": "贵州铜仁市松桃县",
        "match": "贵州铜仁市松桃县|GZTRDQSTZZX",
        "py": "STZZX",
        "provinc": "贵州",
        "city": "铜仁市",
        "area": "松桃县"
    }, {
        "name": "贵州铜仁市万山区",
        "match": "贵州铜仁市万山区|GZTRDQWSTQ",
        "py": "WSTQ",
        "provinc": "贵州",
        "city": "铜仁市",
        "area": "万山区"
    }, {
        "name": "贵州毕节市",
        "match": "贵州毕节市|GZBJDQ",
        "py": "BJDQ",
        "provinc": "贵州",
        "city": "毕节市",
        "area": "毕节市"
    }, {
        "name": "贵州毕节市七星关区",
        "match": "贵州毕节市七星关区|GZBJDQBJS",
        "py": "BJS",
        "provinc": "贵州",
        "city": "毕节市",
        "area": "七星关区"
    }, {
        "name": "贵州毕节市大方县",
        "match": "贵州毕节市大方县|GZBJDQDFX",
        "py": "DFX",
        "provinc": "贵州",
        "city": "毕节市",
        "area": "大方县"
    }, {
        "name": "贵州毕节市黔西县",
        "match": "贵州毕节市黔西县|GZBJDQQXX",
        "py": "QXX",
        "provinc": "贵州",
        "city": "毕节市",
        "area": "黔西县"
    }, {
        "name": "贵州毕节市金沙县",
        "match": "贵州毕节市金沙县|GZBJDQJSX",
        "py": "JSX",
        "provinc": "贵州",
        "city": "毕节市",
        "area": "金沙县"
    }, {
        "name": "贵州毕节市织金县",
        "match": "贵州毕节市织金县|GZBJDQZJX",
        "py": "ZJX",
        "provinc": "贵州",
        "city": "毕节市",
        "area": "织金县"
    }, {
        "name": "贵州毕节市纳雍县",
        "match": "贵州毕节市纳雍县|GZBJDQNYX",
        "py": "NYX",
        "provinc": "贵州",
        "city": "毕节市",
        "area": "纳雍县"
    }, {
        "name": "贵州毕节市威宁县",
        "match": "贵州毕节市威宁县|GZBJDQWNZZX",
        "py": "WNZZX",
        "provinc": "贵州",
        "city": "毕节市",
        "area": "威宁县"
    }, {
        "name": "贵州毕节市赫章县",
        "match": "贵州毕节市赫章县|GZBJDQHZX",
        "py": "HZX",
        "provinc": "贵州",
        "city": "毕节市",
        "area": "赫章县"
    }, {
        "name": "贵州六盘水市",
        "match": "贵州六盘水市|GZLPSS",
        "py": "LPSS",
        "provinc": "贵州",
        "city": "六盘水市",
        "area": "六盘水市"
    }, {
        "name": "贵州六盘水市钟山区",
        "match": "贵州六盘水市钟山区|GZLPSSZSQ",
        "py": "ZSQ",
        "provinc": "贵州",
        "city": "六盘水市",
        "area": "钟山区"
    }, {
        "name": "贵州六盘水市六枝特区",
        "match": "贵州六盘水市六枝特区|GZLPSSLZQ",
        "py": "LZQ",
        "provinc": "贵州",
        "city": "六盘水市",
        "area": "六枝特区"
    }, {
        "name": "贵州六盘水市水城县",
        "match": "贵州六盘水市水城县|GZLPSSSCX",
        "py": "SCX",
        "provinc": "贵州",
        "city": "六盘水市",
        "area": "水城县"
    }, {
        "name": "贵州六盘水市盘县",
        "match": "贵州六盘水市盘县|GZLPSSPX",
        "py": "PX",
        "provinc": "贵州",
        "city": "六盘水市",
        "area": "盘县"
    }, {
        "name": "贵州黔西南州",
        "match": "贵州黔西南州|GZQXNZZZ",
        "py": "QXNZZZ",
        "provinc": "贵州",
        "city": "黔西南州",
        "area": "黔西南州"
    }, {
        "name": "贵州黔西南州兴义市",
        "match": "贵州黔西南州兴义市|GZQXNZZZXYS",
        "py": "XYS",
        "provinc": "贵州",
        "city": "黔西南州",
        "area": "兴义市"
    }, {
        "name": "贵州黔西南州兴仁县",
        "match": "贵州黔西南州兴仁县|GZQXNZZZXRX",
        "py": "XRX",
        "provinc": "贵州",
        "city": "黔西南州",
        "area": "兴仁县"
    }, {
        "name": "贵州黔西南州普安县",
        "match": "贵州黔西南州普安县|GZQXNZZZPAX",
        "py": "PAX",
        "provinc": "贵州",
        "city": "黔西南州",
        "area": "普安县"
    }, {
        "name": "贵州黔西南州晴隆县",
        "match": "贵州黔西南州晴隆县|GZQXNZZZQLX",
        "py": "QLX",
        "provinc": "贵州",
        "city": "黔西南州",
        "area": "晴隆县"
    }, {
        "name": "贵州黔西南州贞丰县",
        "match": "贵州黔西南州贞丰县|GZQXNZZZZFX",
        "py": "ZFX",
        "provinc": "贵州",
        "city": "黔西南州",
        "area": "贞丰县"
    }, {
        "name": "贵州黔西南州望谟县",
        "match": "贵州黔西南州望谟县|GZQXNZZZWMX",
        "py": "WMX",
        "provinc": "贵州",
        "city": "黔西南州",
        "area": "望谟县"
    }, {
        "name": "贵州黔西南州册亨县",
        "match": "贵州黔西南州册亨县|GZQXNZZZCHX",
        "py": "CHX",
        "provinc": "贵州",
        "city": "黔西南州",
        "area": "册亨县"
    }, {
        "name": "贵州黔西南州安龙县",
        "match": "贵州黔西南州安龙县|GZQXNZZZALX",
        "py": "ALX",
        "provinc": "贵州",
        "city": "黔西南州",
        "area": "安龙县"
    }, {
        "name": "辽宁沈阳市",
        "match": "辽宁沈阳市|LNSYS",
        "py": "SYS",
        "provinc": "辽宁",
        "city": "沈阳市",
        "area": "沈阳市"
    }, {
        "name": "辽宁沈阳市和平区",
        "match": "辽宁沈阳市和平区|LNSYSHPQ",
        "py": "HPQ",
        "provinc": "辽宁",
        "city": "沈阳市",
        "area": "和平区"
    }, {
        "name": "辽宁沈阳市浑南区",
        "match": "辽宁沈阳市浑南区|LNSYSHNQ",
        "py": "HNQ",
        "provinc": "辽宁",
        "city": "沈阳市",
        "area": "浑南区"
    }, {
        "name": "辽宁沈阳市沈河区",
        "match": "辽宁沈阳市沈河区|LNSYSSHQ",
        "py": "SHQ",
        "provinc": "辽宁",
        "city": "沈阳市",
        "area": "沈河区"
    }, {
        "name": "辽宁沈阳市大东区",
        "match": "辽宁沈阳市大东区|LNSYSDDQ",
        "py": "DDQ",
        "provinc": "辽宁",
        "city": "沈阳市",
        "area": "大东区"
    }, {
        "name": "辽宁沈阳市皇姑区",
        "match": "辽宁沈阳市皇姑区|LNSYSHGQ",
        "py": "HGQ",
        "provinc": "辽宁",
        "city": "沈阳市",
        "area": "皇姑区"
    }, {
        "name": "辽宁沈阳市铁西区",
        "match": "辽宁沈阳市铁西区|LNSYSTXQ",
        "py": "TXQ",
        "provinc": "辽宁",
        "city": "沈阳市",
        "area": "铁西区"
    }, {
        "name": "辽宁沈阳市苏家屯区",
        "match": "辽宁沈阳市苏家屯区|LNSYSSJTQ",
        "py": "SJTQ",
        "provinc": "辽宁",
        "city": "沈阳市",
        "area": "苏家屯区"
    }, {
        "name": "辽宁沈阳市沈北新区",
        "match": "辽宁沈阳市沈北新区|LNSYSSBXQ",
        "py": "SBXQ",
        "provinc": "辽宁",
        "city": "沈阳市",
        "area": "沈北新区"
    }, {
        "name": "辽宁沈阳市于洪区",
        "match": "辽宁沈阳市于洪区|LNSYSYHQ",
        "py": "YHQ",
        "provinc": "辽宁",
        "city": "沈阳市",
        "area": "于洪区"
    }, {
        "name": "辽宁沈阳市辽中县",
        "match": "辽宁沈阳市辽中县|LNSYSLZX",
        "py": "LZX",
        "provinc": "辽宁",
        "city": "沈阳市",
        "area": "辽中县"
    }, {
        "name": "辽宁沈阳市康平县",
        "match": "辽宁沈阳市康平县|LNSYSKPX",
        "py": "KPX",
        "provinc": "辽宁",
        "city": "沈阳市",
        "area": "康平县"
    }, {
        "name": "辽宁沈阳市法库县",
        "match": "辽宁沈阳市法库县|LNSYSFKX",
        "py": "FKX",
        "provinc": "辽宁",
        "city": "沈阳市",
        "area": "法库县"
    }, {
        "name": "辽宁沈阳市新民市",
        "match": "辽宁沈阳市新民市|LNSYSXMS",
        "py": "XMS",
        "provinc": "辽宁",
        "city": "沈阳市",
        "area": "新民市"
    }, {
        "name": "辽宁铁岭市",
        "match": "辽宁铁岭市|LNTLS",
        "py": "TLS",
        "provinc": "辽宁",
        "city": "铁岭市",
        "area": "铁岭市"
    }, {
        "name": "辽宁铁岭市银州区",
        "match": "辽宁铁岭市银州区|LNTLSYZQ",
        "py": "YZQ",
        "provinc": "辽宁",
        "city": "铁岭市",
        "area": "银州区"
    }, {
        "name": "辽宁铁岭市铁岭新城区",
        "match": "辽宁铁岭市铁岭新城区|LNTLSTLXCQ",
        "py": "TLXCQ",
        "provinc": "辽宁",
        "city": "铁岭市",
        "area": "铁岭新城区"
    }, {
        "name": "辽宁铁岭市清河区",
        "match": "辽宁铁岭市清河区|LNTLSQHQ",
        "py": "QHQ",
        "provinc": "辽宁",
        "city": "铁岭市",
        "area": "清河区"
    }, {
        "name": "辽宁铁岭市铁岭县",
        "match": "辽宁铁岭市铁岭县|LNTLSTLX",
        "py": "TLX",
        "provinc": "辽宁",
        "city": "铁岭市",
        "area": "铁岭县"
    }, {
        "name": "辽宁铁岭市西丰县",
        "match": "辽宁铁岭市西丰县|LNTLSXFX",
        "py": "XFX",
        "provinc": "辽宁",
        "city": "铁岭市",
        "area": "西丰县"
    }, {
        "name": "辽宁铁岭市昌图县",
        "match": "辽宁铁岭市昌图县|LNTLSCTX",
        "py": "CTX",
        "provinc": "辽宁",
        "city": "铁岭市",
        "area": "昌图县"
    }, {
        "name": "辽宁铁岭市调兵山市",
        "match": "辽宁铁岭市调兵山市|LNTLSDBSS",
        "py": "DBSS",
        "provinc": "辽宁",
        "city": "铁岭市",
        "area": "调兵山市"
    }, {
        "name": "辽宁铁岭市开原市",
        "match": "辽宁铁岭市开原市|LNTLSKYS",
        "py": "KYS",
        "provinc": "辽宁",
        "city": "铁岭市",
        "area": "开原市"
    }, {
        "name": "辽宁大连市",
        "match": "辽宁大连市|LNDLS",
        "py": "DLS",
        "provinc": "辽宁",
        "city": "大连市",
        "area": "大连市"
    }, {
        "name": "辽宁大连市长海县",
        "match": "辽宁大连市长海县|LNDLSCHX",
        "py": "CHX",
        "provinc": "辽宁",
        "city": "大连市",
        "area": "长海县"
    }, {
        "name": "辽宁大连市旅顺口区",
        "match": "辽宁大连市旅顺口区|LNDLSLSKQ",
        "py": "LSKQ",
        "provinc": "辽宁",
        "city": "大连市",
        "area": "旅顺口区"
    }, {
        "name": "辽宁大连市中山区",
        "match": "辽宁大连市中山区|LNDLSZSQ",
        "py": "ZSQ",
        "provinc": "辽宁",
        "city": "大连市",
        "area": "中山区"
    }, {
        "name": "辽宁大连市大连开发区",
        "match": "辽宁大连市大连开发区|LNDLSDLKFQ",
        "py": "DLKFQ",
        "provinc": "辽宁",
        "city": "大连市",
        "area": "大连开发区"
    }, {
        "name": "辽宁大连市高新园区",
        "match": "辽宁大连市高新园区|LNDLSGXYQ",
        "py": "GXYQ",
        "provinc": "辽宁",
        "city": "大连市",
        "area": "高新园区"
    }, {
        "name": "辽宁大连市西岗区",
        "match": "辽宁大连市西岗区|LNDLSXGQ",
        "py": "XGQ",
        "provinc": "辽宁",
        "city": "大连市",
        "area": "西岗区"
    }, {
        "name": "辽宁大连市沙河口区",
        "match": "辽宁大连市沙河口区|LNDLSSHKQ",
        "py": "SHKQ",
        "provinc": "辽宁",
        "city": "大连市",
        "area": "沙河口区"
    }, {
        "name": "辽宁大连市甘井子区",
        "match": "辽宁大连市甘井子区|LNDLSGJZQ",
        "py": "GJZQ",
        "provinc": "辽宁",
        "city": "大连市",
        "area": "甘井子区"
    }, {
        "name": "辽宁大连市金州区",
        "match": "辽宁大连市金州区|LNDLSJZQ",
        "py": "JZQ",
        "provinc": "辽宁",
        "city": "大连市",
        "area": "金州区"
    }, {
        "name": "辽宁大连市普兰店区",
        "match": "辽宁大连市普兰店区|LNDLSPLDQ",
        "py": "PLDQ",
        "provinc": "辽宁",
        "city": "大连市",
        "area": "普兰店区"
    }, {
        "name": "辽宁大连市瓦房店市",
        "match": "辽宁大连市瓦房店市|LNDLSWFDS",
        "py": "WFDS",
        "provinc": "辽宁",
        "city": "大连市",
        "area": "瓦房店市"
    }, {
        "name": "辽宁大连市庄河市",
        "match": "辽宁大连市庄河市|LNDLSZHS",
        "py": "ZHS",
        "provinc": "辽宁",
        "city": "大连市",
        "area": "庄河市"
    }, {
        "name": "辽宁鞍山市",
        "match": "辽宁鞍山市|LNASS",
        "py": "ASS",
        "provinc": "辽宁",
        "city": "鞍山市",
        "area": "鞍山市"
    }, {
        "name": "辽宁鞍山市铁东区",
        "match": "辽宁鞍山市铁东区|LNASSTDQ",
        "py": "TDQ",
        "provinc": "辽宁",
        "city": "鞍山市",
        "area": "铁东区"
    }, {
        "name": "辽宁鞍山市铁西区",
        "match": "辽宁鞍山市铁西区|LNASSTXQ",
        "py": "TXQ",
        "provinc": "辽宁",
        "city": "鞍山市",
        "area": "铁西区"
    }, {
        "name": "辽宁鞍山市立山区",
        "match": "辽宁鞍山市立山区|LNASSLSQ",
        "py": "LSQ",
        "provinc": "辽宁",
        "city": "鞍山市",
        "area": "立山区"
    }, {
        "name": "辽宁鞍山市千山区",
        "match": "辽宁鞍山市千山区|LNASSQSQ",
        "py": "QSQ",
        "provinc": "辽宁",
        "city": "鞍山市",
        "area": "千山区"
    }, {
        "name": "辽宁鞍山市台安县",
        "match": "辽宁鞍山市台安县|LNASSTAX",
        "py": "TAX",
        "provinc": "辽宁",
        "city": "鞍山市",
        "area": "台安县"
    }, {
        "name": "辽宁鞍山市岫岩县",
        "match": "辽宁鞍山市岫岩县|LNASSXYZZX",
        "py": "XYZZX",
        "provinc": "辽宁",
        "city": "鞍山市",
        "area": "岫岩县"
    }, {
        "name": "辽宁鞍山市海城市",
        "match": "辽宁鞍山市海城市|LNASSHCS",
        "py": "HCS",
        "provinc": "辽宁",
        "city": "鞍山市",
        "area": "海城市"
    }, {
        "name": "辽宁抚顺市",
        "match": "辽宁抚顺市|LNFSS",
        "py": "FSS",
        "provinc": "辽宁",
        "city": "抚顺市",
        "area": "抚顺市"
    }, {
        "name": "辽宁抚顺市新抚区",
        "match": "辽宁抚顺市新抚区|LNFSSXFQ",
        "py": "XFQ",
        "provinc": "辽宁",
        "city": "抚顺市",
        "area": "新抚区"
    }, {
        "name": "辽宁抚顺市东洲区",
        "match": "辽宁抚顺市东洲区|LNFSSDZQ",
        "py": "DZQ",
        "provinc": "辽宁",
        "city": "抚顺市",
        "area": "东洲区"
    }, {
        "name": "辽宁抚顺市望花区",
        "match": "辽宁抚顺市望花区|LNFSSWHQ",
        "py": "WHQ",
        "provinc": "辽宁",
        "city": "抚顺市",
        "area": "望花区"
    }, {
        "name": "辽宁抚顺市顺城区",
        "match": "辽宁抚顺市顺城区|LNFSSSCQ",
        "py": "SCQ",
        "provinc": "辽宁",
        "city": "抚顺市",
        "area": "顺城区"
    }, {
        "name": "辽宁抚顺市抚顺县",
        "match": "辽宁抚顺市抚顺县|LNFSSFSX",
        "py": "FSX",
        "provinc": "辽宁",
        "city": "抚顺市",
        "area": "抚顺县"
    }, {
        "name": "辽宁抚顺市新宾县",
        "match": "辽宁抚顺市新宾县|LNFSSXBZZX",
        "py": "XBZZX",
        "provinc": "辽宁",
        "city": "抚顺市",
        "area": "新宾县"
    }, {
        "name": "辽宁抚顺市清原县",
        "match": "辽宁抚顺市清原县|LNFSSQYZZX",
        "py": "QYZZX",
        "provinc": "辽宁",
        "city": "抚顺市",
        "area": "清原县"
    }, {
        "name": "辽宁本溪市",
        "match": "辽宁本溪市|LNBXS",
        "py": "BXS",
        "provinc": "辽宁",
        "city": "本溪市",
        "area": "本溪市"
    }, {
        "name": "辽宁本溪市平山区",
        "match": "辽宁本溪市平山区|LNBXSPSQ",
        "py": "PSQ",
        "provinc": "辽宁",
        "city": "本溪市",
        "area": "平山区"
    }, {
        "name": "辽宁本溪市溪湖区",
        "match": "辽宁本溪市溪湖区|LNBXSXHQ",
        "py": "XHQ",
        "provinc": "辽宁",
        "city": "本溪市",
        "area": "溪湖区"
    }, {
        "name": "辽宁本溪市明山区",
        "match": "辽宁本溪市明山区|LNBXSMSQ",
        "py": "MSQ",
        "provinc": "辽宁",
        "city": "本溪市",
        "area": "明山区"
    }, {
        "name": "辽宁本溪市南芬区",
        "match": "辽宁本溪市南芬区|LNBXSNFQ",
        "py": "NFQ",
        "provinc": "辽宁",
        "city": "本溪市",
        "area": "南芬区"
    }, {
        "name": "辽宁本溪市本溪县",
        "match": "辽宁本溪市本溪县|LNBXSBXZZX",
        "py": "BXZZX",
        "provinc": "辽宁",
        "city": "本溪市",
        "area": "本溪县"
    }, {
        "name": "辽宁本溪市桓仁县",
        "match": "辽宁本溪市桓仁县|LNBXSHRZZX",
        "py": "HRZZX",
        "provinc": "辽宁",
        "city": "本溪市",
        "area": "桓仁县"
    }, {
        "name": "辽宁丹东市",
        "match": "辽宁丹东市|LNDDS",
        "py": "DDS",
        "provinc": "辽宁",
        "city": "丹东市",
        "area": "丹东市"
    }, {
        "name": "辽宁丹东市元宝区",
        "match": "辽宁丹东市元宝区|LNDDSYBQ",
        "py": "YBQ",
        "provinc": "辽宁",
        "city": "丹东市",
        "area": "元宝区"
    }, {
        "name": "辽宁丹东市振兴区",
        "match": "辽宁丹东市振兴区|LNDDSZXQ",
        "py": "ZXQ",
        "provinc": "辽宁",
        "city": "丹东市",
        "area": "振兴区"
    }, {
        "name": "辽宁丹东市振安区",
        "match": "辽宁丹东市振安区|LNDDSZAQ",
        "py": "ZAQ",
        "provinc": "辽宁",
        "city": "丹东市",
        "area": "振安区"
    }, {
        "name": "辽宁丹东市宽甸县",
        "match": "辽宁丹东市宽甸县|LNDDSKDZZX",
        "py": "KDZZX",
        "provinc": "辽宁",
        "city": "丹东市",
        "area": "宽甸县"
    }, {
        "name": "辽宁丹东市东港市",
        "match": "辽宁丹东市东港市|LNDDSDGS",
        "py": "DGS",
        "provinc": "辽宁",
        "city": "丹东市",
        "area": "东港市"
    }, {
        "name": "辽宁丹东市凤城市",
        "match": "辽宁丹东市凤城市|LNDDSFCS",
        "py": "FCS",
        "provinc": "辽宁",
        "city": "丹东市",
        "area": "凤城市"
    }, {
        "name": "辽宁锦州市",
        "match": "辽宁锦州市|LNJZS",
        "py": "JZS",
        "provinc": "辽宁",
        "city": "锦州市",
        "area": "锦州市"
    }, {
        "name": "辽宁锦州市古塔区",
        "match": "辽宁锦州市古塔区|LNJZSGTQ",
        "py": "GTQ",
        "provinc": "辽宁",
        "city": "锦州市",
        "area": "古塔区"
    }, {
        "name": "辽宁锦州市松山新区",
        "match": "辽宁锦州市松山新区|LNJZSSSXQ",
        "py": "SSXQ",
        "provinc": "辽宁",
        "city": "锦州市",
        "area": "松山新区"
    }, {
        "name": "辽宁锦州市龙栖湾新区",
        "match": "辽宁锦州市龙栖湾新区|LNJZSLQWXQ",
        "py": "LQWXQ",
        "provinc": "辽宁",
        "city": "锦州市",
        "area": "龙栖湾新区"
    }, {
        "name": "辽宁锦州市经济技术开发区",
        "match": "辽宁锦州市经济技术开发区|LNJZSJJJSKFQ",
        "py": "JJJSKFQ",
        "provinc": "辽宁",
        "city": "锦州市",
        "area": "经济技术开发区"
    }, {
        "name": "辽宁锦州市凌河区",
        "match": "辽宁锦州市凌河区|LNJZSLHQ",
        "py": "LHQ",
        "provinc": "辽宁",
        "city": "锦州市",
        "area": "凌河区"
    }, {
        "name": "辽宁锦州市太和区",
        "match": "辽宁锦州市太和区|LNJZSTHQ",
        "py": "THQ",
        "provinc": "辽宁",
        "city": "锦州市",
        "area": "太和区"
    }, {
        "name": "辽宁锦州市黑山县",
        "match": "辽宁锦州市黑山县|LNJZSHSX",
        "py": "HSX",
        "provinc": "辽宁",
        "city": "锦州市",
        "area": "黑山县"
    }, {
        "name": "辽宁锦州市义县",
        "match": "辽宁锦州市义县|LNJZSYX",
        "py": "YX",
        "provinc": "辽宁",
        "city": "锦州市",
        "area": "义县"
    }, {
        "name": "辽宁锦州市凌海市",
        "match": "辽宁锦州市凌海市|LNJZSLHS",
        "py": "LHS",
        "provinc": "辽宁",
        "city": "锦州市",
        "area": "凌海市"
    }, {
        "name": "辽宁锦州市北镇市",
        "match": "辽宁锦州市北镇市|LNJZSBZS",
        "py": "BZS",
        "provinc": "辽宁",
        "city": "锦州市",
        "area": "北镇市"
    }, {
        "name": "辽宁营口市",
        "match": "辽宁营口市|LNYKS",
        "py": "YKS",
        "provinc": "辽宁",
        "city": "营口市",
        "area": "营口市"
    }, {
        "name": "辽宁营口市站前区",
        "match": "辽宁营口市站前区|LNYKSZQQ",
        "py": "ZQQ",
        "provinc": "辽宁",
        "city": "营口市",
        "area": "站前区"
    }, {
        "name": "辽宁营口市西市区",
        "match": "辽宁营口市西市区|LNYKSXSQ",
        "py": "XSQ",
        "provinc": "辽宁",
        "city": "营口市",
        "area": "西市区"
    }, {
        "name": "辽宁营口市鲅鱼圈区",
        "match": "辽宁营口市鲅鱼圈区|LNYKSBYQQ",
        "py": "BYQQ",
        "provinc": "辽宁",
        "city": "营口市",
        "area": "鲅鱼圈区"
    }, {
        "name": "辽宁营口市老边区",
        "match": "辽宁营口市老边区|LNYKSLBQ",
        "py": "LBQ",
        "provinc": "辽宁",
        "city": "营口市",
        "area": "老边区"
    }, {
        "name": "辽宁营口市盖州市",
        "match": "辽宁营口市盖州市|LNYKSGZS",
        "py": "GZS",
        "provinc": "辽宁",
        "city": "营口市",
        "area": "盖州市"
    }, {
        "name": "辽宁营口市大石桥市",
        "match": "辽宁营口市大石桥市|LNYKSDSQS",
        "py": "DSQS",
        "provinc": "辽宁",
        "city": "营口市",
        "area": "大石桥市"
    }, {
        "name": "辽宁阜新市",
        "match": "辽宁阜新市|LNFXS",
        "py": "FXS",
        "provinc": "辽宁",
        "city": "阜新市",
        "area": "阜新市"
    }, {
        "name": "辽宁阜新市海州区",
        "match": "辽宁阜新市海州区|LNFXSHZQ",
        "py": "HZQ",
        "provinc": "辽宁",
        "city": "阜新市",
        "area": "海州区"
    }, {
        "name": "辽宁阜新市新邱区",
        "match": "辽宁阜新市新邱区|LNFXSXQQ",
        "py": "XQQ",
        "provinc": "辽宁",
        "city": "阜新市",
        "area": "新邱区"
    }, {
        "name": "辽宁阜新市太平区",
        "match": "辽宁阜新市太平区|LNFXSTPQ",
        "py": "TPQ",
        "provinc": "辽宁",
        "city": "阜新市",
        "area": "太平区"
    }, {
        "name": "辽宁阜新市清河门区",
        "match": "辽宁阜新市清河门区|LNFXSQHMQ",
        "py": "QHMQ",
        "provinc": "辽宁",
        "city": "阜新市",
        "area": "清河门区"
    }, {
        "name": "辽宁阜新市细河区",
        "match": "辽宁阜新市细河区|LNFXSXHQ",
        "py": "XHQ",
        "provinc": "辽宁",
        "city": "阜新市",
        "area": "细河区"
    }, {
        "name": "辽宁阜新市阜新县",
        "match": "辽宁阜新市阜新县|LNFXSFXZZX",
        "py": "FXZZX",
        "provinc": "辽宁",
        "city": "阜新市",
        "area": "阜新县"
    }, {
        "name": "辽宁阜新市彰武县",
        "match": "辽宁阜新市彰武县|LNFXSZWX",
        "py": "ZWX",
        "provinc": "辽宁",
        "city": "阜新市",
        "area": "彰武县"
    }, {
        "name": "辽宁辽阳市",
        "match": "辽宁辽阳市|LNLYS",
        "py": "LYS",
        "provinc": "辽宁",
        "city": "辽阳市",
        "area": "辽阳市"
    }, {
        "name": "辽宁辽阳市白塔区",
        "match": "辽宁辽阳市白塔区|LNLYSBTQ",
        "py": "BTQ",
        "provinc": "辽宁",
        "city": "辽阳市",
        "area": "白塔区"
    }, {
        "name": "辽宁辽阳市文圣区",
        "match": "辽宁辽阳市文圣区|LNLYSWSQ",
        "py": "WSQ",
        "provinc": "辽宁",
        "city": "辽阳市",
        "area": "文圣区"
    }, {
        "name": "辽宁辽阳市宏伟区",
        "match": "辽宁辽阳市宏伟区|LNLYSHWQ",
        "py": "HWQ",
        "provinc": "辽宁",
        "city": "辽阳市",
        "area": "宏伟区"
    }, {
        "name": "辽宁辽阳市弓长岭区",
        "match": "辽宁辽阳市弓长岭区|LNLYSGCLQ",
        "py": "GCLQ",
        "provinc": "辽宁",
        "city": "辽阳市",
        "area": "弓长岭区"
    }, {
        "name": "辽宁辽阳市太子河区",
        "match": "辽宁辽阳市太子河区|LNLYSTZHQ",
        "py": "TZHQ",
        "provinc": "辽宁",
        "city": "辽阳市",
        "area": "太子河区"
    }, {
        "name": "辽宁辽阳市辽阳县",
        "match": "辽宁辽阳市辽阳县|LNLYSLYX",
        "py": "LYX",
        "provinc": "辽宁",
        "city": "辽阳市",
        "area": "辽阳县"
    }, {
        "name": "辽宁辽阳市灯塔市",
        "match": "辽宁辽阳市灯塔市|LNLYSDTS",
        "py": "DTS",
        "provinc": "辽宁",
        "city": "辽阳市",
        "area": "灯塔市"
    }, {
        "name": "辽宁朝阳市",
        "match": "辽宁朝阳市|LNCYS",
        "py": "CYS",
        "provinc": "辽宁",
        "city": "朝阳市",
        "area": "朝阳市"
    }, {
        "name": "辽宁朝阳市双塔区",
        "match": "辽宁朝阳市双塔区|LNCYSSTQ",
        "py": "STQ",
        "provinc": "辽宁",
        "city": "朝阳市",
        "area": "双塔区"
    }, {
        "name": "辽宁朝阳市龙城区",
        "match": "辽宁朝阳市龙城区|LNCYSLCQ",
        "py": "LCQ",
        "provinc": "辽宁",
        "city": "朝阳市",
        "area": "龙城区"
    }, {
        "name": "辽宁朝阳市朝阳县",
        "match": "辽宁朝阳市朝阳县|LNCYSCYX",
        "py": "CYX",
        "provinc": "辽宁",
        "city": "朝阳市",
        "area": "朝阳县"
    }, {
        "name": "辽宁朝阳市建平县",
        "match": "辽宁朝阳市建平县|LNCYSJPX",
        "py": "JPX",
        "provinc": "辽宁",
        "city": "朝阳市",
        "area": "建平县"
    }, {
        "name": "辽宁朝阳市喀喇沁左翼县",
        "match": "辽宁朝阳市喀喇沁左翼县|LNCYSKLQZYZZX",
        "py": "KLQZYZZX",
        "provinc": "辽宁",
        "city": "朝阳市",
        "area": "喀喇沁左翼县"
    }, {
        "name": "辽宁朝阳市北票市",
        "match": "辽宁朝阳市北票市|LNCYSBPS",
        "py": "BPS",
        "provinc": "辽宁",
        "city": "朝阳市",
        "area": "北票市"
    }, {
        "name": "辽宁朝阳市凌源市",
        "match": "辽宁朝阳市凌源市|LNCYSLYS",
        "py": "LYS",
        "provinc": "辽宁",
        "city": "朝阳市",
        "area": "凌源市"
    }, {
        "name": "辽宁盘锦市",
        "match": "辽宁盘锦市|LNPJS",
        "py": "PJS",
        "provinc": "辽宁",
        "city": "盘锦市",
        "area": "盘锦市"
    }, {
        "name": "辽宁盘锦市双台子区",
        "match": "辽宁盘锦市双台子区|LNPJSSTZQ",
        "py": "STZQ",
        "provinc": "辽宁",
        "city": "盘锦市",
        "area": "双台子区"
    }, {
        "name": "辽宁盘锦市兴隆台区",
        "match": "辽宁盘锦市兴隆台区|LNPJSXLTQ",
        "py": "XLTQ",
        "provinc": "辽宁",
        "city": "盘锦市",
        "area": "兴隆台区"
    }, {
        "name": "辽宁盘锦市大洼县",
        "match": "辽宁盘锦市大洼县|LNPJSDWX",
        "py": "DWX",
        "provinc": "辽宁",
        "city": "盘锦市",
        "area": "大洼县"
    }, {
        "name": "辽宁盘锦市盘山县",
        "match": "辽宁盘锦市盘山县|LNPJSPSX",
        "py": "PSX",
        "provinc": "辽宁",
        "city": "盘锦市",
        "area": "盘山县"
    }, {
        "name": "辽宁葫芦岛市",
        "match": "辽宁葫芦岛市|LNHLDS",
        "py": "HLDS",
        "provinc": "辽宁",
        "city": "葫芦岛市",
        "area": "葫芦岛市"
    }, {
        "name": "辽宁葫芦岛市连山区",
        "match": "辽宁葫芦岛市连山区|LNHLDSLSQ",
        "py": "LSQ",
        "provinc": "辽宁",
        "city": "葫芦岛市",
        "area": "连山区"
    }, {
        "name": "辽宁葫芦岛市龙港区",
        "match": "辽宁葫芦岛市龙港区|LNHLDSLGQ",
        "py": "LGQ",
        "provinc": "辽宁",
        "city": "葫芦岛市",
        "area": "龙港区"
    }, {
        "name": "辽宁葫芦岛市南票区",
        "match": "辽宁葫芦岛市南票区|LNHLDSNPQ",
        "py": "NPQ",
        "provinc": "辽宁",
        "city": "葫芦岛市",
        "area": "南票区"
    }, {
        "name": "辽宁葫芦岛市绥中县",
        "match": "辽宁葫芦岛市绥中县|LNHLDSSZX",
        "py": "SZX",
        "provinc": "辽宁",
        "city": "葫芦岛市",
        "area": "绥中县"
    }, {
        "name": "辽宁葫芦岛市建昌县",
        "match": "辽宁葫芦岛市建昌县|LNHLDSJCX",
        "py": "JCX",
        "provinc": "辽宁",
        "city": "葫芦岛市",
        "area": "建昌县"
    }, {
        "name": "辽宁葫芦岛市兴城市",
        "match": "辽宁葫芦岛市兴城市|LNHLDSXCS",
        "py": "XCS",
        "provinc": "辽宁",
        "city": "葫芦岛市",
        "area": "兴城市"
    }, {
        "name": "重庆市",
        "match": "重庆市|CQS",
        "py": "CQS",
        "provinc": "重庆",
        "city": "重庆市",
        "area": "重庆市"
    }, {
        "name": "重庆万州区",
        "match": "重庆市万州区|CQSWZQ",
        "py": "WZQ",
        "provinc": "重庆",
        "city": "重庆市",
        "area": "万州区"
    }, {
        "name": "重庆涪陵区",
        "match": "重庆市涪陵区|CQSFLQ",
        "py": "FLQ",
        "provinc": "重庆",
        "city": "重庆市",
        "area": "涪陵区"
    }, {
        "name": "重庆渝中区",
        "match": "重庆市渝中区|CQSYZQ",
        "py": "YZQ",
        "provinc": "重庆",
        "city": "重庆市",
        "area": "渝中区"
    }, {
        "name": "重庆大渡口区",
        "match": "重庆市大渡口区|CQSDDKQ",
        "py": "DDKQ",
        "provinc": "重庆",
        "city": "重庆市",
        "area": "大渡口区"
    }, {
        "name": "重庆江北区",
        "match": "重庆市江北区|CQSJBQ",
        "py": "JBQ",
        "provinc": "重庆",
        "city": "重庆市",
        "area": "江北区"
    }, {
        "name": "重庆沙坪坝区",
        "match": "重庆市沙坪坝区|CQSSPBQ",
        "py": "SPBQ",
        "provinc": "重庆",
        "city": "重庆市",
        "area": "沙坪坝区"
    }, {
        "name": "重庆九龙坡区",
        "match": "重庆市九龙坡区|CQSJLPQ",
        "py": "JLPQ",
        "provinc": "重庆",
        "city": "重庆市",
        "area": "九龙坡区"
    }, {
        "name": "重庆南岸区",
        "match": "重庆市南岸区|CQSNAQ",
        "py": "NAQ",
        "provinc": "重庆",
        "city": "重庆市",
        "area": "南岸区"
    }, {
        "name": "重庆北碚区",
        "match": "重庆市北碚区|CQSBBQ",
        "py": "BBQ",
        "provinc": "重庆",
        "city": "重庆市",
        "area": "北碚区"
    }, {
        "name": "重庆渝北区",
        "match": "重庆市渝北区|CQSYBQ",
        "py": "YBQ",
        "provinc": "重庆",
        "city": "重庆市",
        "area": "渝北区"
    }, {
        "name": "重庆巴南区",
        "match": "重庆市巴南区|CQSBNQ",
        "py": "BNQ",
        "provinc": "重庆",
        "city": "重庆市",
        "area": "巴南区"
    }, {
        "name": "重庆黔江区",
        "match": "重庆市黔江区|CQSQJQ",
        "py": "QJQ",
        "provinc": "重庆",
        "city": "重庆市",
        "area": "黔江区"
    }, {
        "name": "重庆长寿区",
        "match": "重庆市长寿区|CQSCSQ",
        "py": "CSQ",
        "provinc": "重庆",
        "city": "重庆市",
        "area": "长寿区"
    }, {
        "name": "重庆江津区",
        "match": "重庆市江津区|CQSJJQ",
        "py": "JJQ",
        "provinc": "重庆",
        "city": "重庆市",
        "area": "江津区"
    }, {
        "name": "重庆合川区",
        "match": "重庆市合川区|CQSHCQ",
        "py": "HCQ",
        "provinc": "重庆",
        "city": "重庆市",
        "area": "合川区"
    }, {
        "name": "重庆永川区",
        "match": "重庆市永川区|CQSYCQ",
        "py": "YCQ",
        "provinc": "重庆",
        "city": "重庆市",
        "area": "永川区"
    }, {
        "name": "重庆南川区",
        "match": "重庆市南川区|CQSNCQ",
        "py": "NCQ",
        "provinc": "重庆",
        "city": "重庆市",
        "area": "南川区"
    }, {
        "name": "重庆綦江区",
        "match": "重庆市綦江区|CQSQJQ",
        "py": "QJQ",
        "provinc": "重庆",
        "city": "重庆市",
        "area": "綦江区"
    }, {
        "name": "重庆潼南区",
        "match": "重庆市潼南区|CQSTNQ",
        "py": "TNQ",
        "provinc": "重庆",
        "city": "重庆市",
        "area": "潼南区"
    }, {
        "name": "重庆铜梁区",
        "match": "重庆市铜梁区|CQSTLQ",
        "py": "TLQ",
        "provinc": "重庆",
        "city": "重庆市",
        "area": "铜梁区"
    }, {
        "name": "重庆大足区",
        "match": "重庆市大足区|CQSDZQ",
        "py": "DZQ",
        "provinc": "重庆",
        "city": "重庆市",
        "area": "大足区"
    }, {
        "name": "重庆荣昌区",
        "match": "重庆市荣昌区|CQSRCQ",
        "py": "RCQ",
        "provinc": "重庆",
        "city": "重庆市",
        "area": "荣昌区"
    }, {
        "name": "重庆璧山区",
        "match": "重庆市璧山区|CQSBSQ",
        "py": "BSQ",
        "provinc": "重庆",
        "city": "重庆市",
        "area": "璧山区"
    }, {
        "name": "重庆梁平区",
        "match": "重庆市梁平区|CQSLPQ",
        "py": "LPQ",
        "provinc": "重庆",
        "city": "重庆市",
        "area": "梁平区"
    }, {
        "name": "重庆城口县",
        "match": "重庆市城口县|CQSCKX",
        "py": "CKX",
        "provinc": "重庆",
        "city": "重庆市",
        "area": "城口县"
    }, {
        "name": "重庆丰都县",
        "match": "重庆市丰都县|CQSFDX",
        "py": "FDX",
        "provinc": "重庆",
        "city": "重庆市",
        "area": "丰都县"
    }, {
        "name": "重庆垫江县",
        "match": "重庆市垫江县|CQSDJX",
        "py": "DJX",
        "provinc": "重庆",
        "city": "重庆市",
        "area": "垫江县"
    }, {
        "name": "重庆武隆区",
        "match": "重庆市武隆区|CQSWLQ",
        "py": "WLQ",
        "provinc": "重庆",
        "city": "重庆市",
        "area": "武隆区"
    }, {
        "name": "重庆忠县",
        "match": "重庆市忠县|CQSZX",
        "py": "ZX",
        "provinc": "重庆",
        "city": "重庆市",
        "area": "忠县"
    }, {
        "name": "重庆开州区",
        "match": "重庆市开州区|CQSKZQ",
        "py": "KZQ",
        "provinc": "重庆",
        "city": "重庆市",
        "area": "开州区"
    }, {
        "name": "重庆云阳县",
        "match": "重庆市云阳县|CQSYYX",
        "py": "YYX",
        "provinc": "重庆",
        "city": "重庆市",
        "area": "云阳县"
    }, {
        "name": "重庆奉节县",
        "match": "重庆市奉节县|CQSFJX",
        "py": "FJX",
        "provinc": "重庆",
        "city": "重庆市",
        "area": "奉节县"
    }, {
        "name": "重庆巫山县",
        "match": "重庆市巫山县|CQSWSX",
        "py": "WSX",
        "provinc": "重庆",
        "city": "重庆市",
        "area": "巫山县"
    }, {
        "name": "重庆巫溪县",
        "match": "重庆市巫溪县|CQSWXX",
        "py": "WXX",
        "provinc": "重庆",
        "city": "重庆市",
        "area": "巫溪县"
    }, {
        "name": "重庆石柱县",
        "match": "重庆市石柱县|CQSSZZZX",
        "py": "SZZZX",
        "provinc": "重庆",
        "city": "重庆市",
        "area": "石柱县"
    }, {
        "name": "重庆秀山县",
        "match": "重庆市秀山县|CQSXSZZX",
        "py": "XSZZX",
        "provinc": "重庆",
        "city": "重庆市",
        "area": "秀山县"
    }, {
        "name": "重庆酉阳县",
        "match": "重庆市酉阳县|CQSYYZZX",
        "py": "YYZZX",
        "provinc": "重庆",
        "city": "重庆市",
        "area": "酉阳县"
    }, {
        "name": "重庆彭水县",
        "match": "重庆市彭水县|CQSPSZZX",
        "py": "PSZZX",
        "provinc": "重庆",
        "city": "重庆市",
        "area": "彭水县"
    }, {
        "name": "陕西西安市",
        "match": "陕西西安市|SXXAS",
        "py": "XAS",
        "provinc": "陕西",
        "city": "西安市",
        "area": "西安市"
    }, {
        "name": "陕西西安市新城区",
        "match": "陕西西安市新城区|SXXASXCQ",
        "py": "XCQ",
        "provinc": "陕西",
        "city": "西安市",
        "area": "新城区"
    }, {
        "name": "陕西西安市碑林区",
        "match": "陕西西安市碑林区|SXXASBLQ",
        "py": "BLQ",
        "provinc": "陕西",
        "city": "西安市",
        "area": "碑林区"
    }, {
        "name": "陕西西安市莲湖区",
        "match": "陕西西安市莲湖区|SXXASLHQ",
        "py": "LHQ",
        "provinc": "陕西",
        "city": "西安市",
        "area": "莲湖区"
    }, {
        "name": "陕西西安市灞桥区",
        "match": "陕西西安市灞桥区|SXXASBQQ",
        "py": "BQQ",
        "provinc": "陕西",
        "city": "西安市",
        "area": "灞桥区"
    }, {
        "name": "陕西西安市未央区",
        "match": "陕西西安市未央区|SXXASWYQ",
        "py": "WYQ",
        "provinc": "陕西",
        "city": "西安市",
        "area": "未央区"
    }, {
        "name": "陕西西安市雁塔区",
        "match": "陕西西安市雁塔区|SXXASYTQ",
        "py": "YTQ",
        "provinc": "陕西",
        "city": "西安市",
        "area": "雁塔区"
    }, {
        "name": "陕西西安市阎良区",
        "match": "陕西西安市阎良区|SXXASYLQ",
        "py": "YLQ",
        "provinc": "陕西",
        "city": "西安市",
        "area": "阎良区"
    }, {
        "name": "陕西西安市临潼区",
        "match": "陕西西安市临潼区|SXXASLTQ",
        "py": "LTQ",
        "provinc": "陕西",
        "city": "西安市",
        "area": "临潼区"
    }, {
        "name": "陕西西安市长安区",
        "match": "陕西西安市长安区|SXXASCAQ",
        "py": "CAQ",
        "provinc": "陕西",
        "city": "西安市",
        "area": "长安区"
    }, {
        "name": "陕西西安市蓝田县",
        "match": "陕西西安市蓝田县|SXXASLTX",
        "py": "LTX",
        "provinc": "陕西",
        "city": "西安市",
        "area": "蓝田县"
    }, {
        "name": "陕西西安市周至县",
        "match": "陕西西安市周至县|SXXASZZX",
        "py": "ZZX",
        "provinc": "陕西",
        "city": "西安市",
        "area": "周至县"
    }, {
        "name": "陕西西安市鄠邑区",
        "match": "陕西西安市鄠邑区|SXXASHYQ",
        "py": "HYQ",
        "provinc": "陕西",
        "city": "西安市",
        "area": "鄠邑区"
    }, {
        "name": "陕西西安市高陵区",
        "match": "陕西西安市高陵区|SXXASGLQ",
        "py": "GLQ",
        "provinc": "陕西",
        "city": "西安市",
        "area": "高陵区"
    }, {
        "name": "陕西咸阳市",
        "match": "陕西咸阳市|SXXYS",
        "py": "XYS",
        "provinc": "陕西",
        "city": "咸阳市",
        "area": "咸阳市"
    }, {
        "name": "陕西咸阳市秦都区",
        "match": "陕西咸阳市秦都区|SXXYSQDQ",
        "py": "QDQ",
        "provinc": "陕西",
        "city": "咸阳市",
        "area": "秦都区"
    }, {
        "name": "陕西咸阳市杨陵区",
        "match": "陕西咸阳市杨陵区|SXXYSYLQ",
        "py": "YLQ",
        "provinc": "陕西",
        "city": "咸阳市",
        "area": "杨陵区"
    }, {
        "name": "陕西咸阳市渭城区",
        "match": "陕西咸阳市渭城区|SXXYSWCQ",
        "py": "WCQ",
        "provinc": "陕西",
        "city": "咸阳市",
        "area": "渭城区"
    }, {
        "name": "陕西咸阳市三原县",
        "match": "陕西咸阳市三原县|SXXYSSYX",
        "py": "SYX",
        "provinc": "陕西",
        "city": "咸阳市",
        "area": "三原县"
    }, {
        "name": "陕西咸阳市泾阳县",
        "match": "陕西咸阳市泾阳县|SXXYSJYX",
        "py": "JYX",
        "provinc": "陕西",
        "city": "咸阳市",
        "area": "泾阳县"
    }, {
        "name": "陕西咸阳市乾县",
        "match": "陕西咸阳市乾县|SXXYSQX",
        "py": "QX",
        "provinc": "陕西",
        "city": "咸阳市",
        "area": "乾县"
    }, {
        "name": "陕西咸阳市礼泉县",
        "match": "陕西咸阳市礼泉县|SXXYSLQX",
        "py": "LQX",
        "provinc": "陕西",
        "city": "咸阳市",
        "area": "礼泉县"
    }, {
        "name": "陕西咸阳市永寿县",
        "match": "陕西咸阳市永寿县|SXXYSYSX",
        "py": "YSX",
        "provinc": "陕西",
        "city": "咸阳市",
        "area": "永寿县"
    }, {
        "name": "陕西咸阳市彬县",
        "match": "陕西咸阳市彬县|SXXYSBX",
        "py": "BX",
        "provinc": "陕西",
        "city": "咸阳市",
        "area": "彬县"
    }, {
        "name": "陕西咸阳市长武县",
        "match": "陕西咸阳市长武县|SXXYSCWX",
        "py": "CWX",
        "provinc": "陕西",
        "city": "咸阳市",
        "area": "长武县"
    }, {
        "name": "陕西咸阳市旬邑县",
        "match": "陕西咸阳市旬邑县|SXXYSXYX",
        "py": "XYX",
        "provinc": "陕西",
        "city": "咸阳市",
        "area": "旬邑县"
    }, {
        "name": "陕西咸阳市淳化县",
        "match": "陕西咸阳市淳化县|SXXYSCHX",
        "py": "CHX",
        "provinc": "陕西",
        "city": "咸阳市",
        "area": "淳化县"
    }, {
        "name": "陕西咸阳市武功县",
        "match": "陕西咸阳市武功县|SXXYSWGX",
        "py": "WGX",
        "provinc": "陕西",
        "city": "咸阳市",
        "area": "武功县"
    }, {
        "name": "陕西咸阳市兴平市",
        "match": "陕西咸阳市兴平市|SXXYSXPS",
        "py": "XPS",
        "provinc": "陕西",
        "city": "咸阳市",
        "area": "兴平市"
    }, {
        "name": "陕西延安市",
        "match": "陕西延安市|SXYAS",
        "py": "YAS",
        "provinc": "陕西",
        "city": "延安市",
        "area": "延安市"
    }, {
        "name": "陕西延安市宝塔区",
        "match": "陕西延安市宝塔区|SXYASBTQ",
        "py": "BTQ",
        "provinc": "陕西",
        "city": "延安市",
        "area": "宝塔区"
    }, {
        "name": "陕西延安市延长县",
        "match": "陕西延安市延长县|SXYASYCX",
        "py": "YCX",
        "provinc": "陕西",
        "city": "延安市",
        "area": "延长县"
    }, {
        "name": "陕西延安市延川县",
        "match": "陕西延安市延川县|SXYASYCX",
        "py": "YCX",
        "provinc": "陕西",
        "city": "延安市",
        "area": "延川县"
    }, {
        "name": "陕西延安市子长县",
        "match": "陕西延安市子长县|SXYASZCX",
        "py": "ZCX",
        "provinc": "陕西",
        "city": "延安市",
        "area": "子长县"
    }, {
        "name": "陕西延安市安塞区",
        "match": "陕西延安市安塞区|SXYASASQ",
        "py": "ASQ",
        "provinc": "陕西",
        "city": "延安市",
        "area": "安塞区"
    }, {
        "name": "陕西延安市志丹县",
        "match": "陕西延安市志丹县|SXYASZDX",
        "py": "ZDX",
        "provinc": "陕西",
        "city": "延安市",
        "area": "志丹县"
    }, {
        "name": "陕西延安市吴起县",
        "match": "陕西延安市吴起县|SXYASWQX",
        "py": "WQX",
        "provinc": "陕西",
        "city": "延安市",
        "area": "吴起县"
    }, {
        "name": "陕西延安市甘泉县",
        "match": "陕西延安市甘泉县|SXYASGQX",
        "py": "GQX",
        "provinc": "陕西",
        "city": "延安市",
        "area": "甘泉县"
    }, {
        "name": "陕西延安市富县",
        "match": "陕西延安市富县|SXYASFX",
        "py": "FX",
        "provinc": "陕西",
        "city": "延安市",
        "area": "富县"
    }, {
        "name": "陕西延安市洛川县",
        "match": "陕西延安市洛川县|SXYASLCX",
        "py": "LCX",
        "provinc": "陕西",
        "city": "延安市",
        "area": "洛川县"
    }, {
        "name": "陕西延安市宜川县",
        "match": "陕西延安市宜川县|SXYASYCX",
        "py": "YCX",
        "provinc": "陕西",
        "city": "延安市",
        "area": "宜川县"
    }, {
        "name": "陕西延安市黄龙县",
        "match": "陕西延安市黄龙县|SXYASHLX",
        "py": "HLX",
        "provinc": "陕西",
        "city": "延安市",
        "area": "黄龙县"
    }, {
        "name": "陕西延安市黄陵县",
        "match": "陕西延安市黄陵县|SXYASHLX",
        "py": "HLX",
        "provinc": "陕西",
        "city": "延安市",
        "area": "黄陵县"
    }, {
        "name": "陕西榆林市",
        "match": "陕西榆林市|SXYLS",
        "py": "YLS",
        "provinc": "陕西",
        "city": "榆林市",
        "area": "榆林市"
    }, {
        "name": "陕西榆林市榆阳区",
        "match": "陕西榆林市榆阳区|SXYLSYYQ",
        "py": "YYQ",
        "provinc": "陕西",
        "city": "榆林市",
        "area": "榆阳区"
    }, {
        "name": "陕西榆林市神木市",
        "match": "陕西榆林市神木市|SXYLSSMS",
        "py": "SMS",
        "provinc": "陕西",
        "city": "榆林市",
        "area": "神木市"
    }, {
        "name": "陕西榆林市府谷县",
        "match": "陕西榆林市府谷县|SXYLSFGX",
        "py": "FGX",
        "provinc": "陕西",
        "city": "榆林市",
        "area": "府谷县"
    }, {
        "name": "陕西榆林市横山区",
        "match": "陕西榆林市横山区|SXYLSHSQ",
        "py": "HSQ",
        "provinc": "陕西",
        "city": "榆林市",
        "area": "横山区"
    }, {
        "name": "陕西榆林市靖边县",
        "match": "陕西榆林市靖边县|SXYLSJBX",
        "py": "JBX",
        "provinc": "陕西",
        "city": "榆林市",
        "area": "靖边县"
    }, {
        "name": "陕西榆林市定边县",
        "match": "陕西榆林市定边县|SXYLSDBX",
        "py": "DBX",
        "provinc": "陕西",
        "city": "榆林市",
        "area": "定边县"
    }, {
        "name": "陕西榆林市绥德县",
        "match": "陕西榆林市绥德县|SXYLSSDX",
        "py": "SDX",
        "provinc": "陕西",
        "city": "榆林市",
        "area": "绥德县"
    }, {
        "name": "陕西榆林市米脂县",
        "match": "陕西榆林市米脂县|SXYLSMZX",
        "py": "MZX",
        "provinc": "陕西",
        "city": "榆林市",
        "area": "米脂县"
    }, {
        "name": "陕西榆林市佳县",
        "match": "陕西榆林市佳县|SXYLSJX",
        "py": "JX",
        "provinc": "陕西",
        "city": "榆林市",
        "area": "佳县"
    }, {
        "name": "陕西榆林市吴堡县",
        "match": "陕西榆林市吴堡县|SXYLSWBX",
        "py": "WBX",
        "provinc": "陕西",
        "city": "榆林市",
        "area": "吴堡县"
    }, {
        "name": "陕西榆林市清涧县",
        "match": "陕西榆林市清涧县|SXYLSQJX",
        "py": "QJX",
        "provinc": "陕西",
        "city": "榆林市",
        "area": "清涧县"
    }, {
        "name": "陕西榆林市子洲县",
        "match": "陕西榆林市子洲县|SXYLSZZX",
        "py": "ZZX",
        "provinc": "陕西",
        "city": "榆林市",
        "area": "子洲县"
    }, {
        "name": "陕西渭南市",
        "match": "陕西渭南市|SXWNS",
        "py": "WNS",
        "provinc": "陕西",
        "city": "渭南市",
        "area": "渭南市"
    }, {
        "name": "陕西渭南市临渭区",
        "match": "陕西渭南市临渭区|SXWNSLWQ",
        "py": "LWQ",
        "provinc": "陕西",
        "city": "渭南市",
        "area": "临渭区"
    }, {
        "name": "陕西渭南市华州区",
        "match": "陕西渭南市华州区|SXWNSHZQ",
        "py": "HZQ",
        "provinc": "陕西",
        "city": "渭南市",
        "area": "华州区"
    }, {
        "name": "陕西渭南市潼关县",
        "match": "陕西渭南市潼关县|SXWNSTGX",
        "py": "TGX",
        "provinc": "陕西",
        "city": "渭南市",
        "area": "潼关县"
    }, {
        "name": "陕西渭南市大荔县",
        "match": "陕西渭南市大荔县|SXWNSDLX",
        "py": "DLX",
        "provinc": "陕西",
        "city": "渭南市",
        "area": "大荔县"
    }, {
        "name": "陕西渭南市合阳县",
        "match": "陕西渭南市合阳县|SXWNSHYX",
        "py": "HYX",
        "provinc": "陕西",
        "city": "渭南市",
        "area": "合阳县"
    }, {
        "name": "陕西渭南市澄城县",
        "match": "陕西渭南市澄城县|SXWNSCCX",
        "py": "CCX",
        "provinc": "陕西",
        "city": "渭南市",
        "area": "澄城县"
    }, {
        "name": "陕西渭南市蒲城县",
        "match": "陕西渭南市蒲城县|SXWNSPCX",
        "py": "PCX",
        "provinc": "陕西",
        "city": "渭南市",
        "area": "蒲城县"
    }, {
        "name": "陕西渭南市白水县",
        "match": "陕西渭南市白水县|SXWNSBSX",
        "py": "BSX",
        "provinc": "陕西",
        "city": "渭南市",
        "area": "白水县"
    }, {
        "name": "陕西渭南市富平县",
        "match": "陕西渭南市富平县|SXWNSFPX",
        "py": "FPX",
        "provinc": "陕西",
        "city": "渭南市",
        "area": "富平县"
    }, {
        "name": "陕西渭南市韩城市",
        "match": "陕西渭南市韩城市|SXWNSHCS",
        "py": "HCS",
        "provinc": "陕西",
        "city": "渭南市",
        "area": "韩城市"
    }, {
        "name": "陕西渭南市华阴市",
        "match": "陕西渭南市华阴市|SXWNSHYS",
        "py": "HYS",
        "provinc": "陕西",
        "city": "渭南市",
        "area": "华阴市"
    }, {
        "name": "陕西商洛市",
        "match": "陕西商洛市|SXSLS",
        "py": "SLS",
        "provinc": "陕西",
        "city": "商洛市",
        "area": "商洛市"
    }, {
        "name": "陕西商洛市商州区",
        "match": "陕西商洛市商州区|SXSLSSZQ",
        "py": "SZQ",
        "provinc": "陕西",
        "city": "商洛市",
        "area": "商州区"
    }, {
        "name": "陕西商洛市洛南县",
        "match": "陕西商洛市洛南县|SXSLSLNX",
        "py": "LNX",
        "provinc": "陕西",
        "city": "商洛市",
        "area": "洛南县"
    }, {
        "name": "陕西商洛市丹凤县",
        "match": "陕西商洛市丹凤县|SXSLSDFX",
        "py": "DFX",
        "provinc": "陕西",
        "city": "商洛市",
        "area": "丹凤县"
    }, {
        "name": "陕西商洛市商南县",
        "match": "陕西商洛市商南县|SXSLSSNX",
        "py": "SNX",
        "provinc": "陕西",
        "city": "商洛市",
        "area": "商南县"
    }, {
        "name": "陕西商洛市山阳县",
        "match": "陕西商洛市山阳县|SXSLSSYX",
        "py": "SYX",
        "provinc": "陕西",
        "city": "商洛市",
        "area": "山阳县"
    }, {
        "name": "陕西商洛市镇安县",
        "match": "陕西商洛市镇安县|SXSLSZAX",
        "py": "ZAX",
        "provinc": "陕西",
        "city": "商洛市",
        "area": "镇安县"
    }, {
        "name": "陕西商洛市柞水县",
        "match": "陕西商洛市柞水县|SXSLSZSX",
        "py": "ZSX",
        "provinc": "陕西",
        "city": "商洛市",
        "area": "柞水县"
    }, {
        "name": "陕西安康市",
        "match": "陕西安康市|SXAKS",
        "py": "AKS",
        "provinc": "陕西",
        "city": "安康市",
        "area": "安康市"
    }, {
        "name": "陕西安康市汉滨区",
        "match": "陕西安康市汉滨区|SXAKSHBQ",
        "py": "HBQ",
        "provinc": "陕西",
        "city": "安康市",
        "area": "汉滨区"
    }, {
        "name": "陕西安康市汉阴县",
        "match": "陕西安康市汉阴县|SXAKSHYX",
        "py": "HYX",
        "provinc": "陕西",
        "city": "安康市",
        "area": "汉阴县"
    }, {
        "name": "陕西安康市石泉县",
        "match": "陕西安康市石泉县|SXAKSSQX",
        "py": "SQX",
        "provinc": "陕西",
        "city": "安康市",
        "area": "石泉县"
    }, {
        "name": "陕西安康市宁陕县",
        "match": "陕西安康市宁陕县|SXAKSNSX",
        "py": "NSX",
        "provinc": "陕西",
        "city": "安康市",
        "area": "宁陕县"
    }, {
        "name": "陕西安康市紫阳县",
        "match": "陕西安康市紫阳县|SXAKSZYX",
        "py": "ZYX",
        "provinc": "陕西",
        "city": "安康市",
        "area": "紫阳县"
    }, {
        "name": "陕西安康市岚皋县",
        "match": "陕西安康市岚皋县|SXAKSLGX",
        "py": "LGX",
        "provinc": "陕西",
        "city": "安康市",
        "area": "岚皋县"
    }, {
        "name": "陕西安康市平利县",
        "match": "陕西安康市平利县|SXAKSPLX",
        "py": "PLX",
        "provinc": "陕西",
        "city": "安康市",
        "area": "平利县"
    }, {
        "name": "陕西安康市镇坪县",
        "match": "陕西安康市镇坪县|SXAKSZPX",
        "py": "ZPX",
        "provinc": "陕西",
        "city": "安康市",
        "area": "镇坪县"
    }, {
        "name": "陕西安康市旬阳县",
        "match": "陕西安康市旬阳县|SXAKSXYX",
        "py": "XYX",
        "provinc": "陕西",
        "city": "安康市",
        "area": "旬阳县"
    }, {
        "name": "陕西安康市白河县",
        "match": "陕西安康市白河县|SXAKSBHX",
        "py": "BHX",
        "provinc": "陕西",
        "city": "安康市",
        "area": "白河县"
    }, {
        "name": "陕西汉中市",
        "match": "陕西汉中市|SXHZS",
        "py": "HZS",
        "provinc": "陕西",
        "city": "汉中市",
        "area": "汉中市"
    }, {
        "name": "陕西汉中市汉台区",
        "match": "陕西汉中市汉台区|SXHZSHTQ",
        "py": "HTQ",
        "provinc": "陕西",
        "city": "汉中市",
        "area": "汉台区"
    }, {
        "name": "陕西汉中市南郑区",
        "match": "陕西汉中市南郑区|SXHZSNZQ",
        "py": "NZQ",
        "provinc": "陕西",
        "city": "汉中市",
        "area": "南郑区"
    }, {
        "name": "陕西汉中市城固县",
        "match": "陕西汉中市城固县|SXHZSCGX",
        "py": "CGX",
        "provinc": "陕西",
        "city": "汉中市",
        "area": "城固县"
    }, {
        "name": "陕西汉中市洋县",
        "match": "陕西汉中市洋县|SXHZSYX",
        "py": "YX",
        "provinc": "陕西",
        "city": "汉中市",
        "area": "洋县"
    }, {
        "name": "陕西汉中市西乡县",
        "match": "陕西汉中市西乡县|SXHZSXXX",
        "py": "XXX",
        "provinc": "陕西",
        "city": "汉中市",
        "area": "西乡县"
    }, {
        "name": "陕西汉中市勉县",
        "match": "陕西汉中市勉县|SXHZSMX",
        "py": "MX",
        "provinc": "陕西",
        "city": "汉中市",
        "area": "勉县"
    }, {
        "name": "陕西汉中市宁强县",
        "match": "陕西汉中市宁强县|SXHZSNQX",
        "py": "NQX",
        "provinc": "陕西",
        "city": "汉中市",
        "area": "宁强县"
    }, {
        "name": "陕西汉中市略阳县",
        "match": "陕西汉中市略阳县|SXHZSLYX",
        "py": "LYX",
        "provinc": "陕西",
        "city": "汉中市",
        "area": "略阳县"
    }, {
        "name": "陕西汉中市镇巴县",
        "match": "陕西汉中市镇巴县|SXHZSZBX",
        "py": "ZBX",
        "provinc": "陕西",
        "city": "汉中市",
        "area": "镇巴县"
    }, {
        "name": "陕西汉中市留坝县",
        "match": "陕西汉中市留坝县|SXHZSLBX",
        "py": "LBX",
        "provinc": "陕西",
        "city": "汉中市",
        "area": "留坝县"
    }, {
        "name": "陕西汉中市佛坪县",
        "match": "陕西汉中市佛坪县|SXHZSFPX",
        "py": "FPX",
        "provinc": "陕西",
        "city": "汉中市",
        "area": "佛坪县"
    }, {
        "name": "陕西宝鸡市",
        "match": "陕西宝鸡市|SXBJS",
        "py": "BJS",
        "provinc": "陕西",
        "city": "宝鸡市",
        "area": "宝鸡市"
    }, {
        "name": "陕西宝鸡市渭滨区",
        "match": "陕西宝鸡市渭滨区|SXBJSWBQ",
        "py": "WBQ",
        "provinc": "陕西",
        "city": "宝鸡市",
        "area": "渭滨区"
    }, {
        "name": "陕西宝鸡市金台区",
        "match": "陕西宝鸡市金台区|SXBJSJTQ",
        "py": "JTQ",
        "provinc": "陕西",
        "city": "宝鸡市",
        "area": "金台区"
    }, {
        "name": "陕西宝鸡市陈仓区",
        "match": "陕西宝鸡市陈仓区|SXBJSCCQ",
        "py": "CCQ",
        "provinc": "陕西",
        "city": "宝鸡市",
        "area": "陈仓区"
    }, {
        "name": "陕西宝鸡市凤翔县",
        "match": "陕西宝鸡市凤翔县|SXBJSFXX",
        "py": "FXX",
        "provinc": "陕西",
        "city": "宝鸡市",
        "area": "凤翔县"
    }, {
        "name": "陕西宝鸡市岐山县",
        "match": "陕西宝鸡市岐山县|SXBJSQSX",
        "py": "QSX",
        "provinc": "陕西",
        "city": "宝鸡市",
        "area": "岐山县"
    }, {
        "name": "陕西宝鸡市扶风县",
        "match": "陕西宝鸡市扶风县|SXBJSFFX",
        "py": "FFX",
        "provinc": "陕西",
        "city": "宝鸡市",
        "area": "扶风县"
    }, {
        "name": "陕西宝鸡市眉县",
        "match": "陕西宝鸡市眉县|SXBJSMX",
        "py": "MX",
        "provinc": "陕西",
        "city": "宝鸡市",
        "area": "眉县"
    }, {
        "name": "陕西宝鸡市陇县",
        "match": "陕西宝鸡市陇县|SXBJSLX",
        "py": "LX",
        "provinc": "陕西",
        "city": "宝鸡市",
        "area": "陇县"
    }, {
        "name": "陕西宝鸡市千阳县",
        "match": "陕西宝鸡市千阳县|SXBJSQYX",
        "py": "QYX",
        "provinc": "陕西",
        "city": "宝鸡市",
        "area": "千阳县"
    }, {
        "name": "陕西宝鸡市麟游县",
        "match": "陕西宝鸡市麟游县|SXBJSLYX",
        "py": "LYX",
        "provinc": "陕西",
        "city": "宝鸡市",
        "area": "麟游县"
    }, {
        "name": "陕西宝鸡市凤县",
        "match": "陕西宝鸡市凤县|SXBJSFX",
        "py": "FX",
        "provinc": "陕西",
        "city": "宝鸡市",
        "area": "凤县"
    }, {
        "name": "陕西宝鸡市太白县",
        "match": "陕西宝鸡市太白县|SXBJSTBX",
        "py": "TBX",
        "provinc": "陕西",
        "city": "宝鸡市",
        "area": "太白县"
    }, {
        "name": "陕西铜川市",
        "match": "陕西铜川市|SXTCS",
        "py": "TCS",
        "provinc": "陕西",
        "city": "铜川市",
        "area": "铜川市"
    }, {
        "name": "陕西铜川市王益区",
        "match": "陕西铜川市王益区|SXTCSWYQ",
        "py": "WYQ",
        "provinc": "陕西",
        "city": "铜川市",
        "area": "王益区"
    }, {
        "name": "陕西铜川市印台区",
        "match": "陕西铜川市印台区|SXTCSYTQ",
        "py": "YTQ",
        "provinc": "陕西",
        "city": "铜川市",
        "area": "印台区"
    }, {
        "name": "陕西铜川市耀州区",
        "match": "陕西铜川市耀州区|SXTCSYZQ",
        "py": "YZQ",
        "provinc": "陕西",
        "city": "铜川市",
        "area": "耀州区"
    }, {
        "name": "陕西铜川市宜君县",
        "match": "陕西铜川市宜君县|SXTCSYJX",
        "py": "YJX",
        "provinc": "陕西",
        "city": "铜川市",
        "area": "宜君县"
    }, {
        "name": "青海海北州",
        "match": "青海海北州|QHHBZZZ",
        "py": "HBZZZ",
        "provinc": "青海",
        "city": "海北州",
        "area": "海北州"
    }, {
        "name": "青海海北州门源县",
        "match": "青海海北州门源县|QHHBZZZMYZZX",
        "py": "MYZZX",
        "provinc": "青海",
        "city": "海北州",
        "area": "门源县"
    }, {
        "name": "青海海西州冷湖",
        "match": "青海海西州冷湖|QHHXZLH",
        "py": "LH",
        "provinc": "青海",
        "city": "海西州",
        "area": "冷湖"
    }, {
        "name": "青海海西州茫崖",
        "match": "青海海西州茫崖|QHHXZMY",
        "py": "MY",
        "provinc": "青海",
        "city": "海西州",
        "area": "茫崖"
    }, {
        "name": "青海海北州祁连县",
        "match": "青海海北州祁连县|QHHBZZZQLX",
        "py": "QLX",
        "provinc": "青海",
        "city": "海北州",
        "area": "祁连县"
    }, {
        "name": "青海海北州海晏县",
        "match": "青海海北州海晏县|QHHBZZZHYX",
        "py": "HYX",
        "provinc": "青海",
        "city": "海北州",
        "area": "海晏县"
    }, {
        "name": "青海海北州刚察县",
        "match": "青海海北州刚察县|QHHBZZZGCX",
        "py": "GCX",
        "provinc": "青海",
        "city": "海北州",
        "area": "刚察县"
    }, {
        "name": "青海西宁市",
        "match": "青海西宁市|QHXNS",
        "py": "XNS",
        "provinc": "青海",
        "city": "西宁市",
        "area": "西宁市"
    }, {
        "name": "青海西宁市城东区",
        "match": "青海西宁市城东区|QHXNSCDQ",
        "py": "CDQ",
        "provinc": "青海",
        "city": "西宁市",
        "area": "城东区"
    }, {
        "name": "青海西宁市城中区",
        "match": "青海西宁市城中区|QHXNSCZQ",
        "py": "CZQ",
        "provinc": "青海",
        "city": "西宁市",
        "area": "城中区"
    }, {
        "name": "青海西宁市城西区",
        "match": "青海西宁市城西区|QHXNSCXQ",
        "py": "CXQ",
        "provinc": "青海",
        "city": "西宁市",
        "area": "城西区"
    }, {
        "name": "青海西宁市城北区",
        "match": "青海西宁市城北区|QHXNSCBQ",
        "py": "CBQ",
        "provinc": "青海",
        "city": "西宁市",
        "area": "城北区"
    }, {
        "name": "青海西宁市大通县",
        "match": "青海西宁市大通县|QHXNSDTZZX",
        "py": "DTZZX",
        "provinc": "青海",
        "city": "西宁市",
        "area": "大通县"
    }, {
        "name": "青海西宁市湟中县",
        "match": "青海西宁市湟中县|QHXNSHZX",
        "py": "HZX",
        "provinc": "青海",
        "city": "西宁市",
        "area": "湟中县"
    }, {
        "name": "青海西宁市湟源县",
        "match": "青海西宁市湟源县|QHXNSHYX",
        "py": "HYX",
        "provinc": "青海",
        "city": "西宁市",
        "area": "湟源县"
    }, {
        "name": "青海海东市",
        "match": "青海海东市|QHHDDQ",
        "py": "HDDQ",
        "provinc": "青海",
        "city": "海东市",
        "area": "海东市"
    }, {
        "name": "青海海东市平安区",
        "match": "青海海东市平安区|QHHDDQPAQ",
        "py": "PAQ",
        "provinc": "青海",
        "city": "海东市",
        "area": "平安区"
    }, {
        "name": "青海海东市民和县",
        "match": "青海海东市民和县|QHHDDQMHZZX",
        "py": "MHZZX",
        "provinc": "青海",
        "city": "海东市",
        "area": "民和县"
    }, {
        "name": "青海海东市乐都区",
        "match": "青海海东市乐都区|QHHDDQLDQ",
        "py": "LDQ",
        "provinc": "青海",
        "city": "海东市",
        "area": "乐都区"
    }, {
        "name": "青海海东市互助县",
        "match": "青海海东市互助县|QHHDDQHZZZX",
        "py": "HZZZX",
        "provinc": "青海",
        "city": "海东市",
        "area": "互助县"
    }, {
        "name": "青海海东市化隆县",
        "match": "青海海东市化隆县|QHHDDQHLZZX",
        "py": "HLZZX",
        "provinc": "青海",
        "city": "海东市",
        "area": "化隆县"
    }, {
        "name": "青海海东市循化县",
        "match": "青海海东市循化县|QHHDDQXHZZX",
        "py": "XHZZX",
        "provinc": "青海",
        "city": "海东市",
        "area": "循化县"
    }, {
        "name": "青海黄南州",
        "match": "青海黄南州|QHHNZZZ",
        "py": "HNZZZ",
        "provinc": "青海",
        "city": "黄南州",
        "area": "黄南州"
    }, {
        "name": "青海黄南州同仁县",
        "match": "青海黄南州同仁县|QHHNZZZTRX",
        "py": "TRX",
        "provinc": "青海",
        "city": "黄南州",
        "area": "同仁县"
    }, {
        "name": "青海黄南州尖扎县",
        "match": "青海黄南州尖扎县|QHHNZZZJZX",
        "py": "JZX",
        "provinc": "青海",
        "city": "黄南州",
        "area": "尖扎县"
    }, {
        "name": "青海黄南州泽库县",
        "match": "青海黄南州泽库县|QHHNZZZZKX",
        "py": "ZKX",
        "provinc": "青海",
        "city": "黄南州",
        "area": "泽库县"
    }, {
        "name": "青海黄南州河南县",
        "match": "青海黄南州河南县|QHHNZZZHNZZX",
        "py": "HNZZX",
        "provinc": "青海",
        "city": "黄南州",
        "area": "河南县"
    }, {
        "name": "青海海南州",
        "match": "青海海南州|QHHNZZZ",
        "py": "HNZZZ",
        "provinc": "青海",
        "city": "海南州",
        "area": "海南州"
    }, {
        "name": "青海海南州共和县",
        "match": "青海海南州共和县|QHHNZZZGHX",
        "py": "GHX",
        "provinc": "青海",
        "city": "海南州",
        "area": "共和县"
    }, {
        "name": "青海海南州同德县",
        "match": "青海海南州同德县|QHHNZZZTDX",
        "py": "TDX",
        "provinc": "青海",
        "city": "海南州",
        "area": "同德县"
    }, {
        "name": "青海海南州贵德县",
        "match": "青海海南州贵德县|QHHNZZZGDX",
        "py": "GDX",
        "provinc": "青海",
        "city": "海南州",
        "area": "贵德县"
    }, {
        "name": "青海海南州兴海县",
        "match": "青海海南州兴海县|QHHNZZZXHX",
        "py": "XHX",
        "provinc": "青海",
        "city": "海南州",
        "area": "兴海县"
    }, {
        "name": "青海海南州贵南县",
        "match": "青海海南州贵南县|QHHNZZZGNX",
        "py": "GNX",
        "provinc": "青海",
        "city": "海南州",
        "area": "贵南县"
    }, {
        "name": "青海果洛州",
        "match": "青海果洛州|QHGLZZZ",
        "py": "GLZZZ",
        "provinc": "青海",
        "city": "果洛州",
        "area": "果洛州"
    }, {
        "name": "青海果洛州玛沁县",
        "match": "青海果洛州玛沁县|QHGLZZZMQX",
        "py": "MQX",
        "provinc": "青海",
        "city": "果洛州",
        "area": "玛沁县"
    }, {
        "name": "青海果洛州班玛县",
        "match": "青海果洛州班玛县|QHGLZZZBMX",
        "py": "BMX",
        "provinc": "青海",
        "city": "果洛州",
        "area": "班玛县"
    }, {
        "name": "青海果洛州甘德县",
        "match": "青海果洛州甘德县|QHGLZZZGDX",
        "py": "GDX",
        "provinc": "青海",
        "city": "果洛州",
        "area": "甘德县"
    }, {
        "name": "青海果洛州达日县",
        "match": "青海果洛州达日县|QHGLZZZDRX",
        "py": "DRX",
        "provinc": "青海",
        "city": "果洛州",
        "area": "达日县"
    }, {
        "name": "青海果洛州久治县",
        "match": "青海果洛州久治县|QHGLZZZJZX",
        "py": "JZX",
        "provinc": "青海",
        "city": "果洛州",
        "area": "久治县"
    }, {
        "name": "青海果洛州玛多县",
        "match": "青海果洛州玛多县|QHGLZZZMDX",
        "py": "MDX",
        "provinc": "青海",
        "city": "果洛州",
        "area": "玛多县"
    }, {
        "name": "青海玉树州",
        "match": "青海玉树州|QHYSZZZ",
        "py": "YSZZZ",
        "provinc": "青海",
        "city": "玉树州",
        "area": "玉树州"
    }, {
        "name": "青海玉树州玉树市",
        "match": "青海玉树州玉树市|QHYSZZZYSS",
        "py": "YSS",
        "provinc": "青海",
        "city": "玉树州",
        "area": "玉树市"
    }, {
        "name": "青海玉树州杂多县",
        "match": "青海玉树州杂多县|QHYSZZZZDX",
        "py": "ZDX",
        "provinc": "青海",
        "city": "玉树州",
        "area": "杂多县"
    }, {
        "name": "青海玉树州称多县",
        "match": "青海玉树州称多县|QHYSZZZCDX",
        "py": "CDX",
        "provinc": "青海",
        "city": "玉树州",
        "area": "称多县"
    }, {
        "name": "青海玉树州治多县",
        "match": "青海玉树州治多县|QHYSZZZZDX",
        "py": "ZDX",
        "provinc": "青海",
        "city": "玉树州",
        "area": "治多县"
    }, {
        "name": "青海玉树州囊谦县",
        "match": "青海玉树州囊谦县|QHYSZZZNQX",
        "py": "NQX",
        "provinc": "青海",
        "city": "玉树州",
        "area": "囊谦县"
    }, {
        "name": "青海玉树州曲麻莱县",
        "match": "青海玉树州曲麻莱县|QHYSZZZQMLX",
        "py": "QMLX",
        "provinc": "青海",
        "city": "玉树州",
        "area": "曲麻莱县"
    }, {
        "name": "青海海西州",
        "match": "青海海西州|QHHXZZZ",
        "py": "HXZZZ",
        "provinc": "青海",
        "city": "海西州",
        "area": "海西州"
    }, {
        "name": "青海海西州格尔木市",
        "match": "青海海西州格尔木市|QHHXZZZGEMS",
        "py": "GEMS",
        "provinc": "青海",
        "city": "海西州",
        "area": "格尔木市"
    }, {
        "name": "青海海西州德令哈市",
        "match": "青海海西州德令哈市|QHHXZZZDLHS",
        "py": "DLHS",
        "provinc": "青海",
        "city": "海西州",
        "area": "德令哈市"
    }, {
        "name": "青海海西州乌兰县",
        "match": "青海海西州乌兰县|QHHXZZZWLX",
        "py": "WLX",
        "provinc": "青海",
        "city": "海西州",
        "area": "乌兰县"
    }, {
        "name": "青海海西州都兰县",
        "match": "青海海西州都兰县|QHHXZZZDLX",
        "py": "DLX",
        "provinc": "青海",
        "city": "海西州",
        "area": "都兰县"
    }, {
        "name": "青海海西州天峻县",
        "match": "青海海西州天峻县|QHHXZZZTJX",
        "py": "TJX",
        "provinc": "青海",
        "city": "海西州",
        "area": "天峻县"
    }, {
        "name": "青海海西州大柴旦",
        "match": "青海海西州大柴旦|QHHXZZZDCD",
        "py": "DCD",
        "provinc": "青海",
        "city": "海西州",
        "area": "大柴旦"
    }, {
        "name": "香港",
        "match": "香港|XG",
        "py": "XG",
        "provinc": "香港",
        "city": "香港",
        "area": "香港"
    }, {
        "name": "黑龙江哈尔滨市",
        "match": "黑龙江哈尔滨市|HLJHEBS",
        "py": "HEBS",
        "provinc": "黑龙江",
        "city": "哈尔滨市",
        "area": "哈尔滨市"
    }, {
        "name": "黑龙江哈尔滨市道里区",
        "match": "黑龙江哈尔滨市道里区|HLJHEBSDLQ",
        "py": "DLQ",
        "provinc": "黑龙江",
        "city": "哈尔滨市",
        "area": "道里区"
    }, {
        "name": "黑龙江哈尔滨市南岗区",
        "match": "黑龙江哈尔滨市南岗区|HLJHEBSNGQ",
        "py": "NGQ",
        "provinc": "黑龙江",
        "city": "哈尔滨市",
        "area": "南岗区"
    }, {
        "name": "黑龙江哈尔滨市道外区",
        "match": "黑龙江哈尔滨市道外区|HLJHEBSDWQ",
        "py": "DWQ",
        "provinc": "黑龙江",
        "city": "哈尔滨市",
        "area": "道外区"
    }, {
        "name": "黑龙江哈尔滨市平房区",
        "match": "黑龙江哈尔滨市平房区|HLJHEBSPFQ",
        "py": "PFQ",
        "provinc": "黑龙江",
        "city": "哈尔滨市",
        "area": "平房区"
    }, {
        "name": "黑龙江哈尔滨市松北区",
        "match": "黑龙江哈尔滨市松北区|HLJHEBSSBQ",
        "py": "SBQ",
        "provinc": "黑龙江",
        "city": "哈尔滨市",
        "area": "松北区"
    }, {
        "name": "黑龙江哈尔滨市香坊区",
        "match": "黑龙江哈尔滨市香坊区|HLJHEBSXFQ",
        "py": "XFQ",
        "provinc": "黑龙江",
        "city": "哈尔滨市",
        "area": "香坊区"
    }, {
        "name": "黑龙江哈尔滨市呼兰区",
        "match": "黑龙江哈尔滨市呼兰区|HLJHEBSHLQ",
        "py": "HLQ",
        "provinc": "黑龙江",
        "city": "哈尔滨市",
        "area": "呼兰区"
    }, {
        "name": "黑龙江哈尔滨市阿城区",
        "match": "黑龙江哈尔滨市阿城区|HLJHEBSACQ",
        "py": "ACQ",
        "provinc": "黑龙江",
        "city": "哈尔滨市",
        "area": "阿城区"
    }, {
        "name": "黑龙江哈尔滨市依兰县",
        "match": "黑龙江哈尔滨市依兰县|HLJHEBSYLX",
        "py": "YLX",
        "provinc": "黑龙江",
        "city": "哈尔滨市",
        "area": "依兰县"
    }, {
        "name": "黑龙江哈尔滨市方正县",
        "match": "黑龙江哈尔滨市方正县|HLJHEBSFZX",
        "py": "FZX",
        "provinc": "黑龙江",
        "city": "哈尔滨市",
        "area": "方正县"
    }, {
        "name": "黑龙江哈尔滨市宾县",
        "match": "黑龙江哈尔滨市宾县|HLJHEBSBX",
        "py": "BX",
        "provinc": "黑龙江",
        "city": "哈尔滨市",
        "area": "宾县"
    }, {
        "name": "黑龙江哈尔滨市巴彦县",
        "match": "黑龙江哈尔滨市巴彦县|HLJHEBSBYX",
        "py": "BYX",
        "provinc": "黑龙江",
        "city": "哈尔滨市",
        "area": "巴彦县"
    }, {
        "name": "黑龙江哈尔滨市木兰县",
        "match": "黑龙江哈尔滨市木兰县|HLJHEBSMLX",
        "py": "MLX",
        "provinc": "黑龙江",
        "city": "哈尔滨市",
        "area": "木兰县"
    }, {
        "name": "黑龙江哈尔滨市通河县",
        "match": "黑龙江哈尔滨市通河县|HLJHEBSTHX",
        "py": "THX",
        "provinc": "黑龙江",
        "city": "哈尔滨市",
        "area": "通河县"
    }, {
        "name": "黑龙江哈尔滨市延寿县",
        "match": "黑龙江哈尔滨市延寿县|HLJHEBSYSX",
        "py": "YSX",
        "provinc": "黑龙江",
        "city": "哈尔滨市",
        "area": "延寿县"
    }, {
        "name": "黑龙江哈尔滨市双城区",
        "match": "黑龙江哈尔滨市双城区|HLJHEBSSCQ",
        "py": "SCQ",
        "provinc": "黑龙江",
        "city": "哈尔滨市",
        "area": "双城区"
    }, {
        "name": "黑龙江哈尔滨市尚志市",
        "match": "黑龙江哈尔滨市尚志市|HLJHEBSSZS",
        "py": "SZS",
        "provinc": "黑龙江",
        "city": "哈尔滨市",
        "area": "尚志市"
    }, {
        "name": "黑龙江哈尔滨市五常市",
        "match": "黑龙江哈尔滨市五常市|HLJHEBSWCS",
        "py": "WCS",
        "provinc": "黑龙江",
        "city": "哈尔滨市",
        "area": "五常市"
    }, {
        "name": "黑龙江齐齐哈尔市",
        "match": "黑龙江齐齐哈尔市|HLJQQHES",
        "py": "QQHES",
        "provinc": "黑龙江",
        "city": "齐齐哈尔市",
        "area": "齐齐哈尔市"
    }, {
        "name": "黑龙江齐齐哈尔市龙沙区",
        "match": "黑龙江齐齐哈尔市龙沙区|HLJQQHESLSQ",
        "py": "LSQ",
        "provinc": "黑龙江",
        "city": "齐齐哈尔市",
        "area": "龙沙区"
    }, {
        "name": "黑龙江齐齐哈尔市建华区",
        "match": "黑龙江齐齐哈尔市建华区|HLJQQHESJHQ",
        "py": "JHQ",
        "provinc": "黑龙江",
        "city": "齐齐哈尔市",
        "area": "建华区"
    }, {
        "name": "黑龙江齐齐哈尔市铁锋区",
        "match": "黑龙江齐齐哈尔市铁锋区|HLJQQHESTFQ",
        "py": "TFQ",
        "provinc": "黑龙江",
        "city": "齐齐哈尔市",
        "area": "铁锋区"
    }, {
        "name": "黑龙江齐齐哈尔市昂昂溪区",
        "match": "黑龙江齐齐哈尔市昂昂溪区|HLJQQHESAAXQ",
        "py": "AAXQ",
        "provinc": "黑龙江",
        "city": "齐齐哈尔市",
        "area": "昂昂溪区"
    }, {
        "name": "黑龙江齐齐哈尔市富拉尔基区",
        "match": "黑龙江齐齐哈尔市富拉尔基区|HLJQQHESFLEJQ",
        "py": "FLEJQ",
        "provinc": "黑龙江",
        "city": "齐齐哈尔市",
        "area": "富拉尔基区"
    }, {
        "name": "黑龙江齐齐哈尔市碾子山区",
        "match": "黑龙江齐齐哈尔市碾子山区|HLJQQHESNZSQ",
        "py": "NZSQ",
        "provinc": "黑龙江",
        "city": "齐齐哈尔市",
        "area": "碾子山区"
    }, {
        "name": "黑龙江齐齐哈尔市梅里斯达斡尔族区",
        "match": "黑龙江齐齐哈尔市梅里斯达斡尔族区|HLJQQHESMLSDWEZQ",
        "py": "MLSDWEZQ",
        "provinc": "黑龙江",
        "city": "齐齐哈尔市",
        "area": "梅里斯达斡尔族区"
    }, {
        "name": "黑龙江齐齐哈尔市龙江县",
        "match": "黑龙江齐齐哈尔市龙江县|HLJQQHESLJX",
        "py": "LJX",
        "provinc": "黑龙江",
        "city": "齐齐哈尔市",
        "area": "龙江县"
    }, {
        "name": "黑龙江齐齐哈尔市依安县",
        "match": "黑龙江齐齐哈尔市依安县|HLJQQHESYAX",
        "py": "YAX",
        "provinc": "黑龙江",
        "city": "齐齐哈尔市",
        "area": "依安县"
    }, {
        "name": "黑龙江齐齐哈尔市泰来县",
        "match": "黑龙江齐齐哈尔市泰来县|HLJQQHESTLX",
        "py": "TLX",
        "provinc": "黑龙江",
        "city": "齐齐哈尔市",
        "area": "泰来县"
    }, {
        "name": "黑龙江齐齐哈尔市甘南县",
        "match": "黑龙江齐齐哈尔市甘南县|HLJQQHESGNX",
        "py": "GNX",
        "provinc": "黑龙江",
        "city": "齐齐哈尔市",
        "area": "甘南县"
    }, {
        "name": "黑龙江齐齐哈尔市富裕县",
        "match": "黑龙江齐齐哈尔市富裕县|HLJQQHESFYX",
        "py": "FYX",
        "provinc": "黑龙江",
        "city": "齐齐哈尔市",
        "area": "富裕县"
    }, {
        "name": "黑龙江齐齐哈尔市克山县",
        "match": "黑龙江齐齐哈尔市克山县|HLJQQHESKSX",
        "py": "KSX",
        "provinc": "黑龙江",
        "city": "齐齐哈尔市",
        "area": "克山县"
    }, {
        "name": "黑龙江齐齐哈尔市克东县",
        "match": "黑龙江齐齐哈尔市克东县|HLJQQHESKDX",
        "py": "KDX",
        "provinc": "黑龙江",
        "city": "齐齐哈尔市",
        "area": "克东县"
    }, {
        "name": "黑龙江齐齐哈尔市拜泉县",
        "match": "黑龙江齐齐哈尔市拜泉县|HLJQQHESBQX",
        "py": "BQX",
        "provinc": "黑龙江",
        "city": "齐齐哈尔市",
        "area": "拜泉县"
    }, {
        "name": "黑龙江齐齐哈尔市讷河市",
        "match": "黑龙江齐齐哈尔市讷河市|HLJQQHESNHS",
        "py": "NHS",
        "provinc": "黑龙江",
        "city": "齐齐哈尔市",
        "area": "讷河市"
    }, {
        "name": "黑龙江鸡西市",
        "match": "黑龙江鸡西市|HLJJXS",
        "py": "JXS",
        "provinc": "黑龙江",
        "city": "鸡西市",
        "area": "鸡西市"
    }, {
        "name": "黑龙江鸡西市鸡冠区",
        "match": "黑龙江鸡西市鸡冠区|HLJJXSJGQ",
        "py": "JGQ",
        "provinc": "黑龙江",
        "city": "鸡西市",
        "area": "鸡冠区"
    }, {
        "name": "黑龙江鸡西市恒山区",
        "match": "黑龙江鸡西市恒山区|HLJJXSHSQ",
        "py": "HSQ",
        "provinc": "黑龙江",
        "city": "鸡西市",
        "area": "恒山区"
    }, {
        "name": "黑龙江鸡西市滴道区",
        "match": "黑龙江鸡西市滴道区|HLJJXSDDQ",
        "py": "DDQ",
        "provinc": "黑龙江",
        "city": "鸡西市",
        "area": "滴道区"
    }, {
        "name": "黑龙江鸡西市梨树区",
        "match": "黑龙江鸡西市梨树区|HLJJXSLSQ",
        "py": "LSQ",
        "provinc": "黑龙江",
        "city": "鸡西市",
        "area": "梨树区"
    }, {
        "name": "黑龙江鸡西市城子河区",
        "match": "黑龙江鸡西市城子河区|HLJJXSCZHQ",
        "py": "CZHQ",
        "provinc": "黑龙江",
        "city": "鸡西市",
        "area": "城子河区"
    }, {
        "name": "黑龙江鸡西市麻山区",
        "match": "黑龙江鸡西市麻山区|HLJJXSMSQ",
        "py": "MSQ",
        "provinc": "黑龙江",
        "city": "鸡西市",
        "area": "麻山区"
    }, {
        "name": "黑龙江鸡西市鸡东县",
        "match": "黑龙江鸡西市鸡东县|HLJJXSJDX",
        "py": "JDX",
        "provinc": "黑龙江",
        "city": "鸡西市",
        "area": "鸡东县"
    }, {
        "name": "黑龙江鸡西市虎林市",
        "match": "黑龙江鸡西市虎林市|HLJJXSHLS",
        "py": "HLS",
        "provinc": "黑龙江",
        "city": "鸡西市",
        "area": "虎林市"
    }, {
        "name": "黑龙江鸡西市密山市",
        "match": "黑龙江鸡西市密山市|HLJJXSMSS",
        "py": "MSS",
        "provinc": "黑龙江",
        "city": "鸡西市",
        "area": "密山市"
    }, {
        "name": "黑龙江牡丹江市",
        "match": "黑龙江牡丹江市|HLJMDJS",
        "py": "MDJS",
        "provinc": "黑龙江",
        "city": "牡丹江市",
        "area": "牡丹江市"
    }, {
        "name": "黑龙江牡丹江市东安区",
        "match": "黑龙江牡丹江市东安区|HLJMDJSDAQ",
        "py": "DAQ",
        "provinc": "黑龙江",
        "city": "牡丹江市",
        "area": "东安区"
    }, {
        "name": "黑龙江牡丹江市阳明区",
        "match": "黑龙江牡丹江市阳明区|HLJMDJSYMQ",
        "py": "YMQ",
        "provinc": "黑龙江",
        "city": "牡丹江市",
        "area": "阳明区"
    }, {
        "name": "黑龙江牡丹江市爱民区",
        "match": "黑龙江牡丹江市爱民区|HLJMDJSAMQ",
        "py": "AMQ",
        "provinc": "黑龙江",
        "city": "牡丹江市",
        "area": "爱民区"
    }, {
        "name": "黑龙江牡丹江市西安区",
        "match": "黑龙江牡丹江市西安区|HLJMDJSXAQ",
        "py": "XAQ",
        "provinc": "黑龙江",
        "city": "牡丹江市",
        "area": "西安区"
    }, {
        "name": "黑龙江牡丹江市东宁市",
        "match": "黑龙江牡丹江市东宁市|HLJMDJSDNS",
        "py": "DNS",
        "provinc": "黑龙江",
        "city": "牡丹江市",
        "area": "东宁市"
    }, {
        "name": "黑龙江牡丹江市林口县",
        "match": "黑龙江牡丹江市林口县|HLJMDJSLKX",
        "py": "LKX",
        "provinc": "黑龙江",
        "city": "牡丹江市",
        "area": "林口县"
    }, {
        "name": "黑龙江牡丹江市绥芬河市",
        "match": "黑龙江牡丹江市绥芬河市|HLJMDJSSFHS",
        "py": "SFHS",
        "provinc": "黑龙江",
        "city": "牡丹江市",
        "area": "绥芬河市"
    }, {
        "name": "黑龙江牡丹江市海林市",
        "match": "黑龙江牡丹江市海林市|HLJMDJSHLS",
        "py": "HLS",
        "provinc": "黑龙江",
        "city": "牡丹江市",
        "area": "海林市"
    }, {
        "name": "黑龙江牡丹江市宁安市",
        "match": "黑龙江牡丹江市宁安市|HLJMDJSNAS",
        "py": "NAS",
        "provinc": "黑龙江",
        "city": "牡丹江市",
        "area": "宁安市"
    }, {
        "name": "黑龙江牡丹江市穆棱市",
        "match": "黑龙江牡丹江市穆棱市|HLJMDJSMLS",
        "py": "MLS",
        "provinc": "黑龙江",
        "city": "牡丹江市",
        "area": "穆棱市"
    }, {
        "name": "黑龙江七台河市",
        "match": "黑龙江七台河市|HLJQTHS",
        "py": "QTHS",
        "provinc": "黑龙江",
        "city": "七台河市",
        "area": "七台河市"
    }, {
        "name": "黑龙江七台河市新兴区",
        "match": "黑龙江七台河市新兴区|HLJQTHSXXQ",
        "py": "XXQ",
        "provinc": "黑龙江",
        "city": "七台河市",
        "area": "新兴区"
    }, {
        "name": "黑龙江七台河市桃山区",
        "match": "黑龙江七台河市桃山区|HLJQTHSTSQ",
        "py": "TSQ",
        "provinc": "黑龙江",
        "city": "七台河市",
        "area": "桃山区"
    }, {
        "name": "黑龙江七台河市茄子河区",
        "match": "黑龙江七台河市茄子河区|HLJQTHSQZHQ",
        "py": "QZHQ",
        "provinc": "黑龙江",
        "city": "七台河市",
        "area": "茄子河区"
    }, {
        "name": "黑龙江七台河市勃利县",
        "match": "黑龙江七台河市勃利县|HLJQTHSBLX",
        "py": "BLX",
        "provinc": "黑龙江",
        "city": "七台河市",
        "area": "勃利县"
    }, {
        "name": "黑龙江佳木斯市",
        "match": "黑龙江佳木斯市|HLJJMSS",
        "py": "JMSS",
        "provinc": "黑龙江",
        "city": "佳木斯市",
        "area": "佳木斯市"
    }, {
        "name": "黑龙江佳木斯市向阳区",
        "match": "黑龙江佳木斯市向阳区|HLJJMSSXYQ",
        "py": "XYQ",
        "provinc": "黑龙江",
        "city": "佳木斯市",
        "area": "向阳区"
    }, {
        "name": "黑龙江佳木斯市前进区",
        "match": "黑龙江佳木斯市前进区|HLJJMSSQJQ",
        "py": "QJQ",
        "provinc": "黑龙江",
        "city": "佳木斯市",
        "area": "前进区"
    }, {
        "name": "黑龙江佳木斯市东风区",
        "match": "黑龙江佳木斯市东风区|HLJJMSSDFQ",
        "py": "DFQ",
        "provinc": "黑龙江",
        "city": "佳木斯市",
        "area": "东风区"
    }, {
        "name": "黑龙江佳木斯市桦南县",
        "match": "黑龙江佳木斯市桦南县|HLJJMSSHNX",
        "py": "HNX",
        "provinc": "黑龙江",
        "city": "佳木斯市",
        "area": "桦南县"
    }, {
        "name": "黑龙江佳木斯市桦川县",
        "match": "黑龙江佳木斯市桦川县|HLJJMSSHCX",
        "py": "HCX",
        "provinc": "黑龙江",
        "city": "佳木斯市",
        "area": "桦川县"
    }, {
        "name": "黑龙江佳木斯市汤原县",
        "match": "黑龙江佳木斯市汤原县|HLJJMSSTYX",
        "py": "TYX",
        "provinc": "黑龙江",
        "city": "佳木斯市",
        "area": "汤原县"
    }, {
        "name": "黑龙江佳木斯市抚远市",
        "match": "黑龙江佳木斯市抚远市|HLJJMSSFYS",
        "py": "FYS",
        "provinc": "黑龙江",
        "city": "佳木斯市",
        "area": "抚远市"
    }, {
        "name": "黑龙江佳木斯市同江市",
        "match": "黑龙江佳木斯市同江市|HLJJMSSTJS",
        "py": "TJS",
        "provinc": "黑龙江",
        "city": "佳木斯市",
        "area": "同江市"
    }, {
        "name": "黑龙江佳木斯市富锦市",
        "match": "黑龙江佳木斯市富锦市|HLJJMSSFJS",
        "py": "FJS",
        "provinc": "黑龙江",
        "city": "佳木斯市",
        "area": "富锦市"
    }, {
        "name": "黑龙江鹤岗市",
        "match": "黑龙江鹤岗市|HLJHGS",
        "py": "HGS",
        "provinc": "黑龙江",
        "city": "鹤岗市",
        "area": "鹤岗市"
    }, {
        "name": "黑龙江鹤岗市向阳区",
        "match": "黑龙江鹤岗市向阳区|HLJHGSXYQ",
        "py": "XYQ",
        "provinc": "黑龙江",
        "city": "鹤岗市",
        "area": "向阳区"
    }, {
        "name": "黑龙江鹤岗市工农区",
        "match": "黑龙江鹤岗市工农区|HLJHGSGNQ",
        "py": "GNQ",
        "provinc": "黑龙江",
        "city": "鹤岗市",
        "area": "工农区"
    }, {
        "name": "黑龙江鹤岗市南山区",
        "match": "黑龙江鹤岗市南山区|HLJHGSNSQ",
        "py": "NSQ",
        "provinc": "黑龙江",
        "city": "鹤岗市",
        "area": "南山区"
    }, {
        "name": "黑龙江鹤岗市兴安区",
        "match": "黑龙江鹤岗市兴安区|HLJHGSXAQ",
        "py": "XAQ",
        "provinc": "黑龙江",
        "city": "鹤岗市",
        "area": "兴安区"
    }, {
        "name": "黑龙江鹤岗市东山区",
        "match": "黑龙江鹤岗市东山区|HLJHGSDSQ",
        "py": "DSQ",
        "provinc": "黑龙江",
        "city": "鹤岗市",
        "area": "东山区"
    }, {
        "name": "黑龙江鹤岗市兴山区",
        "match": "黑龙江鹤岗市兴山区|HLJHGSXSQ",
        "py": "XSQ",
        "provinc": "黑龙江",
        "city": "鹤岗市",
        "area": "兴山区"
    }, {
        "name": "黑龙江鹤岗市萝北县",
        "match": "黑龙江鹤岗市萝北县|HLJHGSLBX",
        "py": "LBX",
        "provinc": "黑龙江",
        "city": "鹤岗市",
        "area": "萝北县"
    }, {
        "name": "黑龙江鹤岗市绥滨县",
        "match": "黑龙江鹤岗市绥滨县|HLJHGSSBX",
        "py": "SBX",
        "provinc": "黑龙江",
        "city": "鹤岗市",
        "area": "绥滨县"
    }, {
        "name": "黑龙江双鸭山市",
        "match": "黑龙江双鸭山市|HLJSYSS",
        "py": "SYSS",
        "provinc": "黑龙江",
        "city": "双鸭山市",
        "area": "双鸭山市"
    }, {
        "name": "黑龙江双鸭山市尖山区",
        "match": "黑龙江双鸭山市尖山区|HLJSYSSJSQ",
        "py": "JSQ",
        "provinc": "黑龙江",
        "city": "双鸭山市",
        "area": "尖山区"
    }, {
        "name": "黑龙江双鸭山市岭东区",
        "match": "黑龙江双鸭山市岭东区|HLJSYSSLDQ",
        "py": "LDQ",
        "provinc": "黑龙江",
        "city": "双鸭山市",
        "area": "岭东区"
    }, {
        "name": "黑龙江双鸭山市四方台区",
        "match": "黑龙江双鸭山市四方台区|HLJSYSSSFTQ",
        "py": "SFTQ",
        "provinc": "黑龙江",
        "city": "双鸭山市",
        "area": "四方台区"
    }, {
        "name": "黑龙江双鸭山市宝山区",
        "match": "黑龙江双鸭山市宝山区|HLJSYSSBSQ",
        "py": "BSQ",
        "provinc": "黑龙江",
        "city": "双鸭山市",
        "area": "宝山区"
    }, {
        "name": "黑龙江双鸭山市集贤县",
        "match": "黑龙江双鸭山市集贤县|HLJSYSSJXX",
        "py": "JXX",
        "provinc": "黑龙江",
        "city": "双鸭山市",
        "area": "集贤县"
    }, {
        "name": "黑龙江双鸭山市友谊县",
        "match": "黑龙江双鸭山市友谊县|HLJSYSSYYX",
        "py": "YYX",
        "provinc": "黑龙江",
        "city": "双鸭山市",
        "area": "友谊县"
    }, {
        "name": "黑龙江双鸭山市宝清县",
        "match": "黑龙江双鸭山市宝清县|HLJSYSSBQX",
        "py": "BQX",
        "provinc": "黑龙江",
        "city": "双鸭山市",
        "area": "宝清县"
    }, {
        "name": "黑龙江双鸭山市饶河县",
        "match": "黑龙江双鸭山市饶河县|HLJSYSSRHX",
        "py": "RHX",
        "provinc": "黑龙江",
        "city": "双鸭山市",
        "area": "饶河县"
    }, {
        "name": "黑龙江绥化市",
        "match": "黑龙江绥化市|HLJSHS",
        "py": "SHS",
        "provinc": "黑龙江",
        "city": "绥化市",
        "area": "绥化市"
    }, {
        "name": "黑龙江绥化市北林区",
        "match": "黑龙江绥化市北林区|HLJSHSBLQ",
        "py": "BLQ",
        "provinc": "黑龙江",
        "city": "绥化市",
        "area": "北林区"
    }, {
        "name": "黑龙江绥化市望奎县",
        "match": "黑龙江绥化市望奎县|HLJSHSWKX",
        "py": "WKX",
        "provinc": "黑龙江",
        "city": "绥化市",
        "area": "望奎县"
    }, {
        "name": "黑龙江绥化市兰西县",
        "match": "黑龙江绥化市兰西县|HLJSHSLXX",
        "py": "LXX",
        "provinc": "黑龙江",
        "city": "绥化市",
        "area": "兰西县"
    }, {
        "name": "黑龙江绥化市青冈县",
        "match": "黑龙江绥化市青冈县|HLJSHSQGX",
        "py": "QGX",
        "provinc": "黑龙江",
        "city": "绥化市",
        "area": "青冈县"
    }, {
        "name": "黑龙江绥化市庆安县",
        "match": "黑龙江绥化市庆安县|HLJSHSQAX",
        "py": "QAX",
        "provinc": "黑龙江",
        "city": "绥化市",
        "area": "庆安县"
    }, {
        "name": "黑龙江绥化市明水县",
        "match": "黑龙江绥化市明水县|HLJSHSMSX",
        "py": "MSX",
        "provinc": "黑龙江",
        "city": "绥化市",
        "area": "明水县"
    }, {
        "name": "黑龙江绥化市绥棱县",
        "match": "黑龙江绥化市绥棱县|HLJSHSSLX",
        "py": "SLX",
        "provinc": "黑龙江",
        "city": "绥化市",
        "area": "绥棱县"
    }, {
        "name": "黑龙江绥化市安达市",
        "match": "黑龙江绥化市安达市|HLJSHSADS",
        "py": "ADS",
        "provinc": "黑龙江",
        "city": "绥化市",
        "area": "安达市"
    }, {
        "name": "黑龙江绥化市肇东市",
        "match": "黑龙江绥化市肇东市|HLJSHSZDS",
        "py": "ZDS",
        "provinc": "黑龙江",
        "city": "绥化市",
        "area": "肇东市"
    }, {
        "name": "黑龙江绥化市海伦市",
        "match": "黑龙江绥化市海伦市|HLJSHSHLS",
        "py": "HLS",
        "provinc": "黑龙江",
        "city": "绥化市",
        "area": "海伦市"
    }, {
        "name": "黑龙江黑河市",
        "match": "黑龙江黑河市|HLJHHS",
        "py": "HHS",
        "provinc": "黑龙江",
        "city": "黑河市",
        "area": "黑河市"
    }, {
        "name": "黑龙江黑河市爱辉区",
        "match": "黑龙江黑河市爱辉区|HLJHHSAHQ",
        "py": "AHQ",
        "provinc": "黑龙江",
        "city": "黑河市",
        "area": "爱辉区"
    }, {
        "name": "黑龙江黑河市嫩江县",
        "match": "黑龙江黑河市嫩江县|HLJHHSNJX",
        "py": "NJX",
        "provinc": "黑龙江",
        "city": "黑河市",
        "area": "嫩江县"
    }, {
        "name": "黑龙江黑河市逊克县",
        "match": "黑龙江黑河市逊克县|HLJHHSXKX",
        "py": "XKX",
        "provinc": "黑龙江",
        "city": "黑河市",
        "area": "逊克县"
    }, {
        "name": "黑龙江黑河市孙吴县",
        "match": "黑龙江黑河市孙吴县|HLJHHSSWX",
        "py": "SWX",
        "provinc": "黑龙江",
        "city": "黑河市",
        "area": "孙吴县"
    }, {
        "name": "黑龙江黑河市北安市",
        "match": "黑龙江黑河市北安市|HLJHHSBAS",
        "py": "BAS",
        "provinc": "黑龙江",
        "city": "黑河市",
        "area": "北安市"
    }, {
        "name": "黑龙江黑河市五大连池市",
        "match": "黑龙江黑河市五大连池市|HLJHHSWDLCS",
        "py": "WDLCS",
        "provinc": "黑龙江",
        "city": "黑河市",
        "area": "五大连池市"
    }, {
        "name": "黑龙江大兴安岭市",
        "match": "黑龙江大兴安岭市|HLJDXAL",
        "py": "DXAL",
        "provinc": "黑龙江",
        "city": "大兴安岭市",
        "area": "大兴安岭市"
    }, {
        "name": "黑龙江大兴安岭市呼玛县",
        "match": "黑龙江大兴安岭市呼玛县|HLJDXALHMX",
        "py": "HMX",
        "provinc": "黑龙江",
        "city": "大兴安岭市",
        "area": "呼玛县"
    }, {
        "name": "黑龙江大兴安岭市塔河县",
        "match": "黑龙江大兴安岭市塔河县|HLJDXALTHX",
        "py": "THX",
        "provinc": "黑龙江",
        "city": "大兴安岭市",
        "area": "塔河县"
    }, {
        "name": "黑龙江大兴安岭市漠河县",
        "match": "黑龙江大兴安岭市漠河县|HLJDXALMHX",
        "py": "MHX",
        "provinc": "黑龙江",
        "city": "大兴安岭市",
        "area": "漠河县"
    }, {
        "name": "黑龙江伊春市",
        "match": "黑龙江伊春市|HLJYCS",
        "py": "YCS",
        "provinc": "黑龙江",
        "city": "伊春市",
        "area": "伊春市"
    }, {
        "name": "黑龙江伊春市伊春区",
        "match": "黑龙江伊春市伊春区|HLJYCSYCQ",
        "py": "YCQ",
        "provinc": "黑龙江",
        "city": "伊春市",
        "area": "伊春区"
    }, {
        "name": "黑龙江伊春市南岔区",
        "match": "黑龙江伊春市南岔区|HLJYCSNCQ",
        "py": "NCQ",
        "provinc": "黑龙江",
        "city": "伊春市",
        "area": "南岔区"
    }, {
        "name": "黑龙江伊春市友好区",
        "match": "黑龙江伊春市友好区|HLJYCSYHQ",
        "py": "YHQ",
        "provinc": "黑龙江",
        "city": "伊春市",
        "area": "友好区"
    }, {
        "name": "黑龙江伊春市西林区",
        "match": "黑龙江伊春市西林区|HLJYCSXLQ",
        "py": "XLQ",
        "provinc": "黑龙江",
        "city": "伊春市",
        "area": "西林区"
    }, {
        "name": "黑龙江伊春市翠峦区",
        "match": "黑龙江伊春市翠峦区|HLJYCSCLQ",
        "py": "CLQ",
        "provinc": "黑龙江",
        "city": "伊春市",
        "area": "翠峦区"
    }, {
        "name": "黑龙江伊春市新青区",
        "match": "黑龙江伊春市新青区|HLJYCSXQQ",
        "py": "XQQ",
        "provinc": "黑龙江",
        "city": "伊春市",
        "area": "新青区"
    }, {
        "name": "黑龙江伊春市美溪区",
        "match": "黑龙江伊春市美溪区|HLJYCSMXQ",
        "py": "MXQ",
        "provinc": "黑龙江",
        "city": "伊春市",
        "area": "美溪区"
    }, {
        "name": "黑龙江伊春市金山屯区",
        "match": "黑龙江伊春市金山屯区|HLJYCSJSTQ",
        "py": "JSTQ",
        "provinc": "黑龙江",
        "city": "伊春市",
        "area": "金山屯区"
    }, {
        "name": "黑龙江伊春市五营区",
        "match": "黑龙江伊春市五营区|HLJYCSWYQ",
        "py": "WYQ",
        "provinc": "黑龙江",
        "city": "伊春市",
        "area": "五营区"
    }, {
        "name": "黑龙江伊春市乌马河区",
        "match": "黑龙江伊春市乌马河区|HLJYCSWMHQ",
        "py": "WMHQ",
        "provinc": "黑龙江",
        "city": "伊春市",
        "area": "乌马河区"
    }, {
        "name": "黑龙江伊春市汤旺河区",
        "match": "黑龙江伊春市汤旺河区|HLJYCSTWHQ",
        "py": "TWHQ",
        "provinc": "黑龙江",
        "city": "伊春市",
        "area": "汤旺河区"
    }, {
        "name": "黑龙江伊春市带岭区",
        "match": "黑龙江伊春市带岭区|HLJYCSDLQ",
        "py": "DLQ",
        "provinc": "黑龙江",
        "city": "伊春市",
        "area": "带岭区"
    }, {
        "name": "黑龙江伊春市乌伊岭区",
        "match": "黑龙江伊春市乌伊岭区|HLJYCSWYLQ",
        "py": "WYLQ",
        "provinc": "黑龙江",
        "city": "伊春市",
        "area": "乌伊岭区"
    }, {
        "name": "黑龙江伊春市红星区",
        "match": "黑龙江伊春市红星区|HLJYCSHXQ",
        "py": "HXQ",
        "provinc": "黑龙江",
        "city": "伊春市",
        "area": "红星区"
    }, {
        "name": "黑龙江伊春市上甘岭区",
        "match": "黑龙江伊春市上甘岭区|HLJYCSSGLQ",
        "py": "SGLQ",
        "provinc": "黑龙江",
        "city": "伊春市",
        "area": "上甘岭区"
    }, {
        "name": "黑龙江伊春市嘉荫县",
        "match": "黑龙江伊春市嘉荫县|HLJYCSJYX",
        "py": "JYX",
        "provinc": "黑龙江",
        "city": "伊春市",
        "area": "嘉荫县"
    }, {
        "name": "黑龙江伊春市铁力市",
        "match": "黑龙江伊春市铁力市|HLJYCSTLS",
        "py": "TLS",
        "provinc": "黑龙江",
        "city": "伊春市",
        "area": "铁力市"
    }, {
        "name": "黑龙江大庆市",
        "match": "黑龙江大庆市|HLJDQS",
        "py": "DQS",
        "provinc": "黑龙江",
        "city": "大庆市",
        "area": "大庆市"
    }, {
        "name": "黑龙江大庆市萨尔图区",
        "match": "黑龙江大庆市萨尔图区|HLJDQSSETQ",
        "py": "SETQ",
        "provinc": "黑龙江",
        "city": "大庆市",
        "area": "萨尔图区"
    }, {
        "name": "黑龙江大庆市龙凤区",
        "match": "黑龙江大庆市龙凤区|HLJDQSLFQ",
        "py": "LFQ",
        "provinc": "黑龙江",
        "city": "大庆市",
        "area": "龙凤区"
    }, {
        "name": "黑龙江大庆市让胡路区",
        "match": "黑龙江大庆市让胡路区|HLJDQSRHLQ",
        "py": "RHLQ",
        "provinc": "黑龙江",
        "city": "大庆市",
        "area": "让胡路区"
    }, {
        "name": "黑龙江大庆市红岗区",
        "match": "黑龙江大庆市红岗区|HLJDQSHGQ",
        "py": "HGQ",
        "provinc": "黑龙江",
        "city": "大庆市",
        "area": "红岗区"
    }, {
        "name": "黑龙江大庆市大同区",
        "match": "黑龙江大庆市大同区|HLJDQSDTQ",
        "py": "DTQ",
        "provinc": "黑龙江",
        "city": "大庆市",
        "area": "大同区"
    }, {
        "name": "黑龙江大庆市肇州县",
        "match": "黑龙江大庆市肇州县|HLJDQSZZX",
        "py": "ZZX",
        "provinc": "黑龙江",
        "city": "大庆市",
        "area": "肇州县"
    }, {
        "name": "黑龙江大庆市肇源县",
        "match": "黑龙江大庆市肇源县|HLJDQSZYX",
        "py": "ZYX",
        "provinc": "黑龙江",
        "city": "大庆市",
        "area": "肇源县"
    }, {
        "name": "黑龙江大庆市林甸县",
        "match": "黑龙江大庆市林甸县|HLJDQSLDX",
        "py": "LDX",
        "provinc": "黑龙江",
        "city": "大庆市",
        "area": "林甸县"
    }, {
        "name": "黑龙江大庆市杜尔伯特县",
        "match": "黑龙江大庆市杜尔伯特县|HLJDQSDEBTZZX",
        "py": "DEBTZZX",
        "provinc": "黑龙江",
        "city": "大庆市",
        "area": "杜尔伯特县"
    }
    /*yy end*/

];

export default cities;

export function filterCityAsync(value){
    const p = new Promise((resolve, reject) => {
        setTimeout(() => {
            const result = cities.filter(c=>{
                let matched;
                try{
                    let keys = c.match.split('|');                    
                    for(let i = 0; i < keys.length; i++){
                        if(keys[i].toLowerCase().indexOf(value.toLowerCase()) > -1) {
                            matched = c;
                            break;
                        }
                    }
                }catch(e){
                    reject(e);
                }
                return matched;

            });

            resolve(result);
        }, 1);
    });
    
    return p;
}
