import React from 'react'
import './crumbs.less'

export default ({str}) =>{
    let string
    if(typeof str==='object'){
        string = (
            <div className="crumbs_nav">
                <div className="nav">
                    <em>当前所在位置：&nbsp;&nbsp;</em>
                    {/*
                        str.map((vv,index)=>{
                            let link = vv.link
                            if(index===str.length-1) return link ? <span key={index} className="blue"><a href={link}>{vv.name}</a></span> : <span key={index} className="blue">{vv.name}</span>
                            return link ? <em key={index}><a href={link}>{vv.name}</a>&nbsp;&gt;&nbsp;</em> : <em key={index}>{vv.name}&nbsp;&gt;&nbsp;</em>
                        })
                    */}
                    {
                        str.map((vv,index)=>{
                            let link = vv.link
                            if(index===str.length-1) return link ? <span key={index} className="blue"><em>{vv.name}</em></span> : <span key={index} className="blue">{vv.name}</span>
                            return link ? <em key={index}><em>{vv.name}</em>&nbsp;&gt;&nbsp;</em> : <em key={index}>{vv.name}&nbsp;&gt;&nbsp;</em>
                        })
                    }
                </div>
            </div>
        )
    }else if(typeof  str==='string'){
        string = (
            <div className="crumbs_sub">
                {str}
            </div>
        )
    }
    return string || ''
}