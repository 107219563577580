import React from 'react';
import {Link} from 'react-router-dom';
import { Form, Input, Button, Icon, Checkbox, message } from 'antd';
import createHistory from 'history/createHashHistory';
import forge from 'node-forge';
import BaseComponent from '../../components/base/BaseComponent';
import {regexPhone} from '../../constant';
import './index.less';
import service from '../../service/loginService';

const FormItem = Form.Item;
const history = createHistory();

class LoginPage extends BaseComponent {
    constructor(props) {
        super(props);
        this.state = {
            autoLogin: false,
            checkedValue: ''
        };
        this.data = {
            firstStatus: false
        };
        this.checkedValue = '';
    }

    componentDidMount(){
        super.componentDidMount();
        const {setFieldsValue} = this.props.form;

        if(this.loginInfo.loginId){
            setFieldsValue({username: this.loginInfo.loginId});
            this.checkedValue = this.loginInfo.loginId;
        }

        const autoLogin = loginInfo.autoLogin;
        if(autoLogin){
            this.setState({formData: this.loginInfo, autoLogin: true});
            setFieldsValue({password: '123123123'});
            this.login(true, loginInfo.loginId, loginInfo.pwd);
        }
    }

    componentWillReceiveProps(nextProps){
        if(nextProps.formData && nextProps.formData.hasOwnProperty('autoLogin')){
            this.setState({autoLogin: nextProps.formData.autoLogin});
        }
    }

    render() {
        const { getFieldDecorator } = this.props.form;
        const formData = this.props.formData || '';
        return (
            <div className="loginpagewrap">
                <div className="box">
                    <div className="rightBox">
                        <div className="loginTop">
                            <div className="right">免费<br/>试用版</div>
                            <div className="logo"></div>
                            <h3 className="title">特运通企业版</h3>
                        </div>
                        <div className="loginWrap tytfont">
                            <Form onSubmit={this.handleSubmit}>
                                <FormItem style={{marginBottom: -8}} className="username">
                                    {getFieldDecorator('username', {
                                        rules: [
                                            { required: true, message: '请输入手机号' },
                                            { pattern: regexPhone, message: '请输入正确的手机号'}
                                        ],
                                        initialValue: formData.loginId || localStorage.phone || ''
                                    })(
                                        <Input
                                            className="left0"
                                            addonBefore={<i className="tytfont icon-shouji i-gray" />}
                                            onBlur={ this.checkFirst }
                                            placeholder="请输入手机号"
                                            size="large"
                                        />
                                    )}
                                </FormItem>
                                <FormItem className="pwd">
                                    {getFieldDecorator('password', {
                                        rules: [
                                            { required: true, message: '请输入密码' },
                                            { max: 16, message: '最多输入16个字符'}
                                        ],
                                        initialValue: formData.pwd || ''
                                    })(
                                        <Input
                                            className="left0"
                                            addonBefore={<i className="tytfont icon-mima i-gray" />}
                                            type="password"
                                            placeholder="请输入密码"
                                            size="large"
                                        />
                                    )}
                                </FormItem>
                                <div style={{marginBottom: 20, marginTop: 20, paddingLeft: 10}}>
                                    <Checkbox
                                        checked={this.state.autoLogin}
                                        onChange={e => this.setState({autoLogin: e.target.checked})}
                                    >
                                        下次自动登录
                                    </Checkbox>
                                </div>
                                <div>
                                    <Button type="primary" htmlType="submit" className="loginBtn" size="large">登录</Button>
                                </div>
                                <div style={{marginTop: 10, textAlign: 'right'}}>
                                    <a onClick={this.gotoForget}>忘记密码？</a>
                                </div>
                            </Form>
                        </div>
                    </div>
                    <div className="leftBox">
                        <div className="title">欢迎使用特运通企业版</div>
                        <div className="txt">快速调板车，就用特运通！</div>
                        <div className="action" style={{display: 'none'}}>
                            <Button type="primary" style={{width: 120}}>企业入驻</Button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    handleSubmit = (e) => {
        e && e.preventDefault();
        this.login(false);
    };

    gotoForget = () => {
        const {history} = this.props;
        const path = {
            pathname: '/forget',
            state: {phone: this.checkedValue}
        };

        history.push(path);
    };

    login = (autoLogin, loginId, pwd) => {
        const loginInfo = this.loginInfo;

        this.props.form.validateFields((err, values)=>{
            if(!err || autoLogin){
                let cellPhone = this.props.form.getFieldsValue().username;
                let password = this.props.form.getFieldsValue().password;
                if (cellPhone && password) {
                    let md = forge.md.md5.create();
                    md.update(password);
                    password = md.digest().toHex();

                    const PHONE = loginId || cellPhone;
                    const PASSWORD = pwd || password;

                    service.login({phone: PHONE, pwd: PASSWORD}, {
                        ok: (data) => {
                            loginInfo.user = JSON.stringify(data);
                            loginInfo.token = data.tokenId || '';
                            loginInfo.adminName = data.adminName || '';
                            loginInfo.avatar = data.avatar || '';
                            loginInfo.ctime = data.ctime || '';
                            loginInfo.id = data.id || '';
                            loginInfo.loginPhone = data.loginPhone || '';
                            loginInfo.nickname = data.nickname || '';
                            loginInfo.trueName = data.trueName || '';
                            loginInfo.autoLogin = false;
                            loginInfo.loginId = PHONE;
                            loginInfo.pwd = '';

                            localStorage.setItem('phone', cellPhone);

                            if(this.state.autoLogin){
                                loginInfo.loginId = cellPhone;
                                loginInfo.pwd = PASSWORD;
                                loginInfo.autoLogin = true;
                            }

                            let { firstStatus } = this.data;

                            if(firstStatus){
                                history.replace('/setPwd');
                            }else{
                                history.replace('/index');
                            }
                        },
                        failed: e => {
                            message.warn('登录失败，' + e.msg);
                        },
                        error: e => {
                            message.error('登录失败，请稍后再试！');
                        }
                    });

                } else {
                    message.error('手机号和密码必填')
                }
            }
        })
    };

    checkFirst = (e) => {
        let value = e.target.value;
        this.props.form.validateFields(['username'], { force: true }, (err,values) => {
            if(!err){
                //this.setState({checkedValue: value});
                this.checkedValue = value;
                service.checkFirst({cellphone: value}, {
                    ok: (data)=>{
                        this.data.firstStatus = true;
                        message.info('首次登录，密码已经发送给您的手机，请注意查收！');
                    },
                    failed: e => {
                        if(e.code == 201){
                            message.warn('您的手机号尚未注册，请联系企业管理员帮您添加当前手机号');
                        }
                    },
                    error: e => {
                        message.error('服务异常，请稍后再试！');
                    }
                });
            }else{
                //this.setState({checkedValue: ''});
                this.checkedValue = '';
            }
        });
    };
}

const Login = Form.create()(LoginPage);
export default Login;
