import React from 'react'
import {filterMenuItem} from 'utils/menu'
import { ajax } from 'utils'
import {Link} from 'react-router-dom'
import {Col, Row, message} from 'antd'
import '../../container/reset_antd.less'
import emitter from 'utils/events'
import tips from 'components/tips'

export default class Index extends React.Component {
    constructor(props){
        super(props)

        this.state = {
            data: {},
            permission: {}
        }
    }

    componentDidMount() {
        let that = this;

        let makePowers = menuId=> {
            ajax.fetchJson(`getPowers/${menuId}`).then(data=>{
                if(data && data.code==200){
                    that.setState({ permission: data.data }, function(){
                        that.getData()
                    })
                }else tips('error', data && data.msg || '查询操作项失败！')
            })
        }
        const {index,regTab} = this.props
        let menuId = regTab(filterMenuItem(index))
        if(menuId){
            makePowers(menuId)
        }else{
            this.eventEmitter = emitter.addListener('meunDataYet', (menuId) => {
                makePowers(menuId)
            })
        }
    }

    renderByPermission = (permissionGroup, permissionVal, reactNode) => {
        let permissionData = this.state.permission

        for(let o in permissionData){
            if(o == permissionGroup && permissionData[o].length > 0){
                for(let i=0; i<permissionData[o].length; i++){
                    if(permissionData[o][i] == permissionVal){
                        return reactNode
                    }
                }
            }
        }

        return null
    }

    getData = () => {
        let that = this

        ajax.fetchJson('getWorkstationData', 'get', {}).then(res=> {
            if(res && res.code == 200){
                let data = res.data;

                that.setState({ data })
            }else{
                res && message.error(res.msg)
            }
        }, ()=>{
            message.error('服务异常！')
        })
    }

    getProSource = () => {
        let data = this.state.data && this.state.data.transportMainBeanList || [], result = []

        if(data.length>0){
            for(let i=0; i<data.length; i++){
                result.push(
                    <Col span={8} key={data[i].id} onClick={() => window.location.hash= `/goodsSrcNew/${data[i].id}`}><span>{ data[i].taskContent }</span></Col>
                )
            }
        }

        return result
    }

    newGoods = () => {
        location.hash='/goodsSrcNew/0'
    }

    routeOrder = (type) => {
        let route = '/books/'

        // 1:待同意  2:装货中  3:装货完成  4:卸货完成  5:违约异常

        switch (type){
            case 1:
                route += 'forAgree'
                break
            case 2:
                route += 'loading'
                break
            case 3:
                route += 'loaded'
                break
            case 4:
                route += 'unloaded'
                break
            case 5:
                route += 'ex'
                break
        }

        window.location.hash = route
    }

    routeGoods = (type) => {
        // 1:全部  2:发布中  3:已下架
        window.location.hash = `/goodsSrc/${type}`
    }

    /*componentWillUnmount() {
        typeof this.eventEmitter==='function' && emitter.removeListener(this.eventEmitter)
    }*/

    render() {
        const { data, permission } = this.state
        const {exceptionNum,inLoadingNum,loadOverNum,waitAgreeNum} = this.props.forIndex

        return (
            <div className="index">
                <h5 className='title' >工作台</h5>
                <Row gutter={0} className='indexItem' >
                    {
                        this.renderByPermission(
                            'scanList',
                            '货源',
                            <Col span={4} className='v-center'><i className='order-blue'></i><span>货 源</span></Col>
                        )
                    }
                    {
                        this.renderByPermission(
                            'scanList',
                            '累计发货',
                            <Col span={5} className='indexItem-total'>
                                <div>
                                    <dl>
                                        <dt>累计发货</dt>
                                        <dd>{ data.totalPubNum }</dd>
                                    </dl>
                                </div>
                                <div>
                                    <dl>
                                        <dt>本周</dt>
                                        <dd>{ data.weekPubNum }</dd>
                                    </dl>
                                    <dl>
                                        <dt>本月</dt>
                                        <dd>{ data.monthPubNum }</dd>
                                    </dl>
                                </div>
                            </Col>
                        )
                    }
                    {
                        this.renderByPermission(
                            'buttonList',
                            '发布货源',
                            <Col span={9} className='index-source'>
                                <dl>
                                    <dt>发布货源</dt>
                                    <dd>
                                        <Row gutter={4} className='t1'>
                                            { this.getProSource() }
                                            <Col span={8}><span className="add" onClick={ this.newGoods }><i></i><strong>发布新货源</strong></span></Col>
                                        </Row>
                                    </dd>
                                </dl>
                            </Col>
                        )
                    }
                    { this.renderByPermission(
                        'buttonList',
                        '发布中',
                        <Col span={3} className='v-center-item' onClick={ this.routeGoods.bind(this, 2) }>
                            <i className='release'><em>{ data.pubNum || '' }</em></i>
                            <span>发布中</span>
                        </Col>
                    )}
                    { this.renderByPermission(
                        'buttonList',
                        '已下架',
                        <Col span={3} className='v-center-item' onClick={ this.routeGoods.bind(this, 3) }>
                            <i className="removed"><em>{ data.undercarriageNum || '' }</em></i>
                            <span>已下架</span>
                        </Col>
                    )}
                </Row>

                <Row gutter={0} className='indexItem' >
                    {
                        this.renderByPermission(
                            'scanList',
                            '运单',
                            <Col span={4} className='v-center'><i className='order-green'></i><span>运 单</span></Col>
                        )
                    }
                    {
                        this.renderByPermission(
                            'scanList',
                            '累计运单',
                            <Col span={5} className='indexItem-total bgfb'>
                                <div>
                                    <dl>
                                        <dt>累计运单</dt>
                                        <dd>{ data.totalOrderNum }</dd>
                                    </dl>
                                </div>
                                <div>
                                    <dl>
                                        <dt>本周</dt>
                                        <dd>{ data.weekOrderNum }</dd>
                                    </dl>
                                    <dl>
                                        <dt>本月</dt>
                                        <dd>{ data.monthOrderNum }</dd>
                                    </dl>
                                </div>
                            </Col>
                        )
                    }
                    {
                        this.renderByPermission(
                            'buttonList',
                            '待同意',
                            <Col span={3} className='v-center-item' onClick={ this.routeOrder.bind(this, 1) }>
                                <i className="wait-agree"><em>{ waitAgreeNum || '' }</em></i>
                                <span>待同意</span>
                            </Col>
                        )
                    }
                    {
                        this.renderByPermission(
                            'buttonList',
                            '装货中',
                            <Col span={3} className='v-center-item' onClick={ this.routeOrder.bind(this, 2) }>
                                <i className="loading-ing"><em>{ inLoadingNum || '' }</em></i>
                                <span>装货中</span>
                            </Col>
                        )
                    }
                    {
                        this.renderByPermission(
                            'buttonList',
                            '装货完成',
                            <Col span={3} className='v-center-item' onClick={ this.routeOrder.bind(this, 3) }>
                                <i className="loading-complete"><em>{ loadOverNum || '' }</em></i>
                                <span>装货完成</span>
                            </Col>
                        )
                    }
                    {
                        this.renderByPermission(
                            'buttonList',
                            '卸货完成',
                            <Col span={3} className='v-center-item' onClick={ this.routeOrder.bind(this, 4) }>
                                <i className="discharge"><em></em></i>
                                <span>卸货完成</span>
                            </Col>
                        )
                    }
                    {
                        this.renderByPermission(
                            'buttonList',
                            '违约异常',
                            <Col span={3} className='v-center-item' onClick={ this.routeOrder.bind(this, 5) }>
                                <i className="error"><em>{ exceptionNum || '' }</em></i>
                                <span>违约异常</span>
                            </Col>
                        )
                    }
                </Row>
            </div>
        )
    }
}