/**
 * Created by liuyc14 on 2018/8/24.
 */
import { Select, Spin  } from 'antd';
import React from 'react';
import {filterCityAsync, cities} from '../../constant/fuzzySearch';
import propTypes from 'prop-types';

const Option = Select.Option;
let f;

export default class CityFilterSelect extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            filtering: false,
            data: props.defaultData || [],
            value: props.value || ""
        }
    }

    render() {
        const { data, value, filtering } = this.state;
        return (
            <Select
                {...this.props}
                value={value}
                getPopupContainer={() => document.getElementById(this.props.cid)}
                placeholder={this.props.placeholder || ''}
                filterOption={false}
                showSearch={true}
                showArrow={false}
                notFoundContent={filtering ? <Spin size="small" /> : '没有匹配信息，请修改关键词'}
                onSelect={this.handleSelect}
                onChange={this.handleChange}
                onSearch={this.handleSearch}
                style={{ width: '100%' }}
            >
                {data.map((d, index) => <Option key={d.name} city={d}>{d.name}</Option>)}
            </Select>
        );
    }

    handleChange = (value) => {
        const {onChange} = this.props;
        this.setState({
            filtering: false,
            value
        });
        if(onChange){
            onChange(value);
        }
    };

    handleSearch = (value) => {
        if(!value) return;

        const delay = this.props.delay || 500;
        clearTimeout(f);
        f = setTimeout(() => {
            this.setState({data: [], filtering: true});
            filterCityAsync(value)
                .then(d => {
                    setTimeout(() => {
                        this.setState({data: d, filtering: false});
                    }, 300);
                });
        }, delay);
    };

    handleSelect = (value, option) => {
        const {onChangeValue} = this.props;
        const city = option.props.city;
        if(onChangeValue){
            onChangeValue(city);
        }
        
    };
}

CityFilterSelect.propTypes = {
    cid: propTypes.string,
    onChange: propTypes.func,
    onChangeValue: propTypes.func,
    defaultData: propTypes.array
};