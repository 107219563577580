import React from 'react';
import {filterMenuItem} from 'utils/menu';
import {openWinAuto} from '../../utils/myOpt';
import {Link,HashRouter as Router, Route} from 'react-router-dom'
import {Tabs, Table, Pagination, Popconfirm, Modal, Select, Input, Row, Col, Button, notification, Icon, message } from 'antd';
import moment from 'moment';
import './list.less';
import {goods_Status_PUBLISH, goods_Status_SOLDOUT, offReason, POWER_GoodsList} from '../../constant';
import service from '../../service/goodsSrcService';
import BaseComponent, {EVENTKEY_POWER} from '../../components/base/BaseComponent';

const TabPane = Tabs.TabPane;
const Option = Select.Option;
const { TextArea } = Input;
const PAGESIZE = 10;
const POWER = POWER_GoodsList;


export default class GoodsSrc extends BaseComponent {
    changeArea(e) {
        let value = e.target.value;
        if(value.length > 512){
            value = value.substring(0, 512);
            e.target.value = value;
            message.warn('备注不能超过512个字')
        }
        this.setState({remark: value})
    }
    constructor(props){
        super(props);

        this.menuId = "2";

        this.event
            .off(EVENTKEY_POWER, 'goodsList_power')
            .on(EVENTKEY_POWER, 'goodsList_power', () => {
                console.log('接收到权限数据');
                this.setState({
                    buttonList: this.buttonList,
                    scanList: this.scanList,
                    tabList: this.tabList
                });
            });

        this.checkedID = ""; //选中的货源ID，用于操作

        this.state = {
            lastUnloadReason: offReason[0].name,
            remark: '',
            showOffModal: false,
            showTypeModal: false,
            tableLoading: false,
            tab: 1,
            tableData:{
                total:0,
                rows: [],
                pageSize: PAGESIZE,
                page: 1
            },
            tableData1:{
                loading: false,
                total:0,
                rows: [],
                pageSize: PAGESIZE,
                page: 1
            },
            tableData2:{
                loading: false,
                total:0,
                rows: [],
                pageSize: PAGESIZE,
                page: 1
            },
            tableData3:{
                loading: false,
                total:0,
                rows: [],
                pageSize: PAGESIZE,
                page: 1
            }
        };

        this.columns = [
            {
                title: '货源编号',
                dataIndex: 'id'
            },
            {
                title: '路线',
                dataIndex: 'startEnd',
                render: (text, item) => (
                    <div>{item.startPoint} - {item.destPoint}</div>
                )
            },
            {
                title: '货物信息',
                dataIndex: 'taskContent',
                width: 300
            },
            {
                title: '发布时间',
                dataIndex: 'ctime',
                render: (text) => {
                    return (
                        <div>{moment(new Date(text)).format('YYYY-MM-DD HH:mm:ss')}</div>
                    );
                }
            },
            {
                title: '货源渠道',
                dataIndex: 'notifyPattern',
                render: (text) => {
                    let dom;
                    if(text == 1){
                        dom = '通知熟车';
                    }
                    else if(text == 2){
                        dom = '特运通代发';
                    }
                    else if(text == 3){
                        dom = '指定熟车';
                    }
                    else{
                        dom = '无';
                    }
                    return (
                        <div>{dom}</div>
                    );
                }
            },
            {
                title: '货源状态',
                dataIndex: 'status',
                render: (text) => {
                    if(text == 1){
                        return (<div className='status-new'>发布中</div>);
                    }else{
                        return (<div className='status-off'>已下架</div>);
                    }
                }
            },
            {
                title: '操作',
                key: 'operation',
                width: 200,
                render: (text, item) => {
                    let actionNew = "action-new";
                    let actionOff = "action-off";
                    let actionNear = "action-near";
                    if(item.status == 1){
                        actionNew += ' action-hide';
                        actionNear += ' action-hide';
                    }else{
                        actionOff += ' action-hide';
                    }
                    return (
                        <div>
                            <a className="action-detail" onClick={ () => this.gotoDetail(item.id, item.status) }>详情</a>
                            {this.reanderComponent(POWER.buttonList_up, this.state.buttonList,
                                <Popconfirm title="确定要上架吗?" onConfirm={()=>this.rePublish(item.id)}>
                                    <a
                                        href="javascript: void(0);"
                                        className={actionNew}
                                    >上架</a>
                                </Popconfirm>
                            )}
                            {this.reanderComponent(POWER.buttonList_off, this.state.buttonList,
                                <a
                                    href="javascript: void(0);"
                                    onClick={()=>{
                                    this.checkedID = item.id;
                                    this.setState({showOffModal: true});
                                }}
                                    className={actionOff}
                                >下架</a>
                            )}
                            {this.reanderComponent(POWER.buttonList_publish, this.state.buttonList,
                                <Link to={`goodsSrcNew/${item.id}`} className={actionNear}>发布相似货源</Link>
                            )}

                        </div>
                    );
                }
            }
        ];
    }

    gotoDetail(id, status){
        const goto = document.URL.split('/#/')[0] + '#/goodsSrcView/' + id + '/' + status;
        // window.open(goto, '_blank', 'height=100%,width=100%, toolbar=no, Resizable=no,location=no, status=no')
        openWinAuto(goto,'gotoDetail'+id,window.screen.availWidth,window.screen.availHeight,0,0)
    }

    componentDidMount() {
        super.componentDidMount();

        const tab = this.params.tab || 1;

        this.setState({tab});
        /**
         * tableData1:Object.assign(this.state.tableData1, {loading: true}),
         tableData2:Object.assign(this.state.tableData2, {loading: true}),
         tableData3:Object.assign(this.state.tableData3, {loading: true})
         */
        //this.getAllData();
        this.getDataByTab(tab, 1);
    }

    receivePower(power){
        //或者在这个生命周期函数中也可以获得权限
        this.setState({
            buttonList: power.buttonList,
            scanList: power.scanList,
            tabList: power.tabList
        });
    }

    render() {
        const {tableData, tableLoading} = this.state;
        return (
            <div className='container_gsList'>
                <h3 className='title'>货源</h3>
                <div className="content">
                    <Tabs
                        type="card"
                        activeKey={this.state.tab + ''}
                        onChange={k => {
                            this.setState({tab: k});
                            this.getDataByTab(k);
                        }}
                    >
                        {this.reanderComponent(POWER.tabList_all, this.state.tabList,
                            <TabPane tab="全部货源" key="1" className="ccc">
                                <Table
                                    columns={this.columns}
                                    dataSource={tableData.rows}
                                    loading={tableLoading}
                                    rowKey={record => record.id}
                                    pagination={false}
                                />
                                <div className="p">
                                    <Pagination
                                        className="ant-table-pagination pagination"
                                        showTotal={(total, range) => `当前${range[0]}-${range[1]}条， 共 ${total} 条信息`}
                                        total={tableData.total}
                                        current={tableData.page}
                                        pageSize={tableData.pageSize}
                                        showQuickJumper
                                        onChange={page => {
                                    this.getDataByTab(1, page);
                                }}
                                    />
                                </div>
                            </TabPane>
                        )}
                        {this.reanderComponent(POWER.tabList_publish, this.state.tabList,
                            <TabPane tab="发布中" key="2" className="ccc">
                                <Table
                                    columns={this.columns}
                                    dataSource={tableData.rows}
                                    loading={tableLoading}
                                    rowKey={record => record.id}
                                    pagination={false}
                                />
                                <Pagination
                                    className="ant-table-pagination pagination"
                                    showTotal={(total, range) => `当前${range[0]}-${range[1]}条， 共 ${total} 条信息`}
                                    total={tableData.total}
                                    current={tableData.page}
                                    pageSize={tableData.pageSize}
                                    showQuickJumper
                                    onChange={page => {
                                    this.getDataByTab(2, page);
                                }}
                                />
                            </TabPane>
                        )}
                        {this.reanderComponent(POWER.tabList_off, this.state.tabList,
                            <TabPane tab="已下架" key="3" className="ccc">
                                <Table
                                    columns={this.columns}
                                    dataSource={tableData.rows}
                                    loading={tableLoading}
                                    rowKey={record => record.id}
                                    pagination={false}
                                />
                                <Pagination
                                    className="ant-table-pagination pagination"
                                    showTotal={(total, range) => `当前${range[0]}-${range[1]}条， 共 ${total} 条信息`}
                                    total={tableData.total}
                                    current={tableData.page}
                                    pageSize={tableData.pageSize}
                                    showQuickJumper
                                    onChange={page => {
                                    this.getDataByTab(3, page);
                                }}
                                />
                            </TabPane>
                        )}
                    </Tabs>
                </div>

                <Modal
                    width={600}
                    maskClosable={false}
                    closable={true}
                    visible={this.state.showOffModal}
                    onCancel={()=>{this.setState({showOffModal: false})}}
                    title=""
                    footer=""
                >
                    <div className="modalBox">
                        <h3 className="modal-title-off">下架货源</h3>
                        <div className="modal-title-label firstTitle">确定要将该货源下架么？</div>
                        <div className="modal-label-off firstTitle">货源下架后如果装车未完成，您可以重新上架货源</div>
                        <div className="modal-title-label grayTitle">货源下架原因：</div>
                        <div>
                            <Select
                                style={{width: '100%'}}
                                value={this.state.lastUnloadReason}
                                size="large"
                                onSelect={e => this.setState({lastUnloadReason: e})}
                            >
                                {offReason.map(c=><Option key={c.id} value={c.name}>{c.name}</Option>)}
                            </Select>
                        </div>
                        <div className="modal-title-label grayTitle">备&nbsp;&nbsp;&nbsp;&nbsp;注：</div>
                        <div>
                            <TextArea rows={4} value={this.state.remark} onChange={e => this.changeArea(e) } />
                        </div>
                        <div className="btnGroup">
                            <Row>
                                <Col span={12}>
                                    <Button
                                        style={{width:'98%'}}
                                        size="large"
                                        className="defaultButton"
                                        onClick={()=>{this.setState({showOffModal: false})}}
                                    >取消</Button>
                                </Col>
                                <Col span={12} style={{textAlign: 'right'}}>
                                    <Button
                                        type="primary"
                                        style={{width:'98%'}}
                                        size="large"
                                        className="okButton"
                                        onClick={this.offGoods}
                                    >下架</Button>
                                </Col>
                            </Row>
                        </div>
                    </div>
                </Modal>
            </div>
        )
    }

    getAllData = () => {
        this.getTableData('', 1, (data) => {
            this.setState({
                tableData1: {
                    loading: false,
                    total: data.total,
                    rows: data.data,
                    pageSize: PAGESIZE,
                    page: data.page
                }
            });
        });

        this.getTableData(goods_Status_PUBLISH, 1, (data) => {
            this.setState({
                tableData2: {
                    loading: false,
                    total: data.total,
                    rows: data.data,
                    pageSize: PAGESIZE,
                    page: data.page
                }
            });
        });

        this.getTableData(goods_Status_SOLDOUT, 1, (data) => {
            this.setState({
                tableData3: {
                    loading: false,
                    total: data.total,
                    rows: data.data,
                    pageSize: PAGESIZE,
                    page: data.page
                }
            });
        });
    };

    getDataByTab = (tab, page = 1) => {
        let status = "";
        if(tab == 2){
            status = goods_Status_PUBLISH;
        }
        else if (tab == 3){
            status = goods_Status_SOLDOUT;
        }else{
            status = "";
        }
        this.setState({tableLoading: true});

        this.getTableData(status, page, (data) => {
            this.setState({
                tableLoading: false,
                tableData: {
                    total: data.total,
                    rows: data.data,
                    pageSize: PAGESIZE,
                    page: data.page
                }
            });
        });
    };

    getTableData = (status, page, okCallback) => {
        service.getList({status, page, pageSize: PAGESIZE},{
            ok: okCallback,
            failed: () => {
                let message = '接口加载失败';
                let description = '货源列表接口加载失败，请稍后再试！';
                notification.open({
                    message,
                    description,
                    icon: <Icon type="smile-circle" style={{ color: '#108ee9' }} />
                });
                this.setState({tableLoading: false});
            }, error: () => {
                let message = '接口加载失败';
                let description = '货源列表接口加载失败，请稍后再试！';
                notification.open({
                    message,
                    description,
                    icon: <Icon type="smile-circle" style={{ color: 'red' }} />
                });
                this.setState({tableLoading: false});
            }}
        );
    };

    /**
     * 下架货源
     */
    offGoods = () => {
        const id = this.checkedID;
        const lastUnloadReason = this.state.lastUnloadReason || '';
        const remark = this.state.remark || '';
        let message = '下架加载失败';
        let description = '下架接口加载失败，请稍后再试！';
        service.cancel({id, lastUnloadReason, remark}, {
            ok: data => {
                message.success('下架成功');
                this.setState({showOffModal: false, lastUnloadReason: offReason[0].name, remark: ''});
                this.getDataByTab(this.state.tab, 1);
            },
            failed: e => {
                notification.open({
                    message,
                    description,
                    icon: <Icon type="smile-circle" style={{ color: '#108ee9' }} />
                });
            },
            error: e => {
                notification.open({
                    message,
                    description,
                    icon: <Icon type="smile-circle" style={{ color: 'red' }} />
                });
            }
        });
    };

    rePublish = (id) => {
        service.rePublish(id, {
            ok: (data) => {
                message.success('重新上架成功');
                this.getDataByTab(this.state.tab, 1);
            },
            failed: e=> {

            },
            error: e => {

            }
        });
    };
}
