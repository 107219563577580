import React from 'react'
import createHistory from 'history/createHashHistory';
import {filterMenuItem} from 'utils/menu'
import {message, Button, Input, Select, Table, Modal, Pagination} from 'antd'
import {Link} from 'dva/router';
import { ajax } from 'utils'
import emitter from 'utils/events'
import AddFamiliarCar from '../../components/addFamiliarCar/addFamiliarCar'
import '../../container/reset_antd.less'
import tips from 'components/tips'

const
    Option = Select.Option,
    Search = Input.Search,
    TextArea = Input.TextArea,
    pageSize = 10,
    history = createHistory()

class FamiliarCar extends React.Component {
    constructor(props){
        super(props)

        this.state = {
            loading: false,
            data: [],
            total: 0,
            historyData: [],
            page: 1,
            removeModalVisible: false,
            removeLoading: false,
            remarkModalVisible: false,
            editRemarkLoading: false,
            historyVisible: false,
            editItem: {},
            addModalVisible: false,
            currentPersonal: {},
            permission: {},
            remarkContent: ''
        }

        this.data = {
            removeItem: {},
            searchType: 1, //1 手机  2  姓名
            searchVal: 'noQuery',
            remark: ''
        }
    }

    /*componentWillUnmount() {
        typeof this.eventEmitter==='function' && emitter.removeListener(this.eventEmitter)
    }*/

    componentDidMount() {
        let that = this

        let makePowers = menuId=> {
            ajax.fetchJson(`getPowers/${menuId}`).then(data=>{
                if(data && data.code==200){
                    that.setState({ permission: data.data }, function(){
                        that.getData()
                    })
                }else tips('error', data && data.msg || '查询操作项失败！')
            })
        }
        const { index, regTab, creatTel } = this.props
        let menuId = regTab(filterMenuItem(index))
        if(menuId){
            makePowers(menuId)
        }else{
            this.eventEmitter = emitter.addListener('meunDataYet', (menuId) => {
                makePowers(menuId)
            })
        }

        if(creatTel) this.addFamiliarCar()
    }

    renderByPermission = (permissionGroup, permissionVal, reactNode) => {
        let permissionData = this.state.permission

        for(let o in permissionData){
            if(o == permissionGroup && permissionData[o].length > 0){
                for(let i=0; i<permissionData[o].length; i++){
                    if(permissionData[o][i] == permissionVal){
                        return reactNode
                    }
                }
            }
        }

        return null
    }

    addSuccess = () => {
        document.querySelectorAll('.familiar-search input')[0].value = '';
        this.search();
    }

    //查询熟车列表
    getData = (page = 1) => {
        this.setState({ loading: true, page })

        let { searchType, searchVal } = this.data

        ajax.fetchJson(`getFamiliarList/${searchVal}?queryType=${searchType}`, 'get', {offset: (page - 1) * pageSize, limit: pageSize}).then((res) => {
            this.setState({ loading: false })

            if(res && res.code == 200){
                this.setState({ data: res.data, total: res.count })
            }else{
                message.error(res && res.msg)
            }
        }, () => {
            this.setState({ loading: false })
            message.error('服务异常！')
        })
    }

    selectType = (e) => {
        this.data.searchType = e;
    }

    search = (e) => {
        this.data.searchVal = e ? e : 'noQuery'

        this.getData()
    }

    addFamiliarCar = () => {
        this.setState({ addModalVisible: true })
    }

    cancelAdd = () => {
        this.setState({ addModalVisible: false })

        //this.search();
    }

    cooperationDetail = (record) => {
        const history = this.props.router.history

        let href = window.location.href, userName = encodeURIComponent(record.userName)

        let goto = href.split('/#/')[0] + '/#/familiarCar/history/'+userName+'/'+record.cellphone

        window.open(goto, '_blank', 'height=600,width=1000, toolbar=no, Resizable=no,location=no, status=no')

        /*history.push({
            pathname: '/familiarCar/history',
            state: record
        })*/

    }

    removeItem = (record) => {
        this.data.removeItem = record
        this.setState({ removeModalVisible: true })
    }

    //移除熟车
    removeHandler = () => {
        this.setState({ removeLoading: true })

        let { removeItem } = this.data, that = this
        //to do remove
        ajax.fetchJson(`delFamiliar/${removeItem.id}`, 'get', {}).then((res) => {
            if(res && res.code == 200){
                this.setState({ removeModalVisible: false, removeLoading: false }, function(){
                    message.success(res && res.msg)
                    that.getData(that.state.page)
                })
            }else{
                message.error(res && res.msg)
            }
        }, () => {
            this.setState({ removeModalVisible: false, removeLoading: false })
            message.error('服务异常！')
        })
    }

    removeHandleCancel = () => {
        this.setState({ removeModalVisible: false })
    }

    editItem = (record) => {
        this.setState({ editItem: record, remarkModalVisible: true, remarkContent: record.remark })
    }

    //修改备注
    editRemarkHandler = () => {
        this.setState({ editRemarkLoading: true })

        let { editItem } = this.state

        let that = this,
            editObj = {
                id: editItem && editItem.id,
                remark: this.state.remarkContent
            }

        //to do edit remark
        ajax.fetchJson('editFamiliarRemark', 'post', editObj).then((res) => {
            if(res && res.code == 200){
                this.setState({ editRemarkLoading: false, remarkModalVisible: false, remarkContent: '' }, function(){
                    message.success(res && res.msg)

                    that.getData(that.state.page)
                })
            }else{
                message.error(res && res.msg)
            }
        }, () => {
            this.setState({ editRemarkLoading: false, remarkModalVisible: false, remarkContent: '' })
            message.error('服务异常！')
        })
    }

    editRemarkHandleCancel = () => {
        this.setState({ remarkModalVisible: false })
    }

    historyCancel = () => {
        this.setState({ historyVisible: false })
    }

    writeRemark = (e) => {
        if(e.target.value.length > 200){
            message.warn('备注不能超过200字');
            e.target.value = e.target.value.substring(0, 200);
        };

        this.setState({ remarkContent: e.target.value })
    }

    gotoDetail = (record) => {
        ajax.fetchJson(`bookDetail/${record.id}`, 'get').then((res) => {
            if(res && res.code == 200){
                window.location.hash = `/books/all/${record.id}`
            }else{
                message.error(res && res.msg)
            }
        }, () => {
            message.error('服务异常！')
        })
    }

    render() {
        const selectBefore = (
            <Select defaultValue="1" style={{ width: 90 }} onChange={ this.selectType.bind(this) }>
                <Option value="1">手机号</Option>
                <Option value="2">姓名</Option>
            </Select>
        )

        const columns = [
            {
                title: '车主姓名',
                dataIndex: 'userName',
            },
            {
                title: '联系电话',
                dataIndex: 'cellphone',
            },
            {
                title: '拥有车辆次',
                dataIndex: 'ownsCar',
                render: (text) => { return (<span>{ text || 0 }</span>) }
            },
            {
                title: '合作历史',
                key: 'cooperationNumber',
                render: (text, record) => {
                    if(!record.cooperationNumber){
                        return (<span>0</span>)
                    }else if(record.cooperationNumber > 0){
                        return (
                            <a className="cooperationNums" onClick={ () => this.cooperationDetail(record) }>{ record.cooperationNumber || 0 }</a>
                        )
                    }
                }
            },
            {
                title: '备注',
                dataIndex: 'remark',
                width: 260,
                render: (text) => { return (<span>{ text || '无' }</span>) }
            },
            {
                title: '是否注册特运通',
                dataIndex: 'isRegistered', //2 未注册  1  注册
                render: (text) => {
                    return (<div>{ text == 2 ? <span className="not-registered">未注册</span> : <span className="registered">已注册</span> }</div>)
                }
            },
            {
                title: '操作',
                key: 'operation',
                width: 140,
                render: (text, record) => {
                    return (
                        <div>
                            {
                                this.renderByPermission(
                                    'buttonList',
                                    '移除',
                                    <a className="remove" onClick={ this.removeItem.bind(this, record) }>移除</a>
                                )
                            }
                            {
                                this.renderByPermission(
                                    'buttonList',
                                    '编辑备注',
                                    <a onClick={ this.editItem.bind(this, record) }>编辑备注</a>
                                )
                            }
                        </div>
                    )
                }
            }
        ]

        const historyColumns = [
            {
                title: '路线',
                key: 'route',
                render: (text, record) => {
                    return (<span>{ (record.startPoint || '') + '--' + (record.destPoint || '') }</span>)
                }
            },
            {
                title: '货物信息',
                dataIndex: 'taskContent',
            },
            {
                title: '运价(现金＋油卡)',
                key: 'price',
                render: (text, record) => {
                    return (<span>{ (record.cashPrice || 0) + '+' + (record.oilPrice || 0) }</span>)
                }
            },
            {
                title: '合作时间',
                dataIndex: 'ctime',
            },
            {
                title: '操作',
                key: 'operation',
                // render: (text, record) => { return (<span><Link to={`/books/all/${record.id}`}>详情</Link></span>) }
                render: (text, record) => { return (<span><a onClick={ this.gotoDetail.bind(this, record) }>详情</a></span>) }
            }
        ]

        const {
            data,
            page,
            removeModalVisible,
            removeLoading,
            remarkModalVisible,
            editRemarkLoading,
            editItem,
            addModalVisible,
            remarkContent
        } = this.state

        return (
            <div>
                <h5 className="title">熟车</h5>
                <div className="page-pannel clearfix">
                    <div className="search-header">
                        {
                            this.renderByPermission(
                                'buttonList',
                                '添加熟车',
                                <Button type='primary' onClick={ this.addFamiliarCar } size="large">添加熟车</Button>
                            )
                        }
                        <Search
                            className="familiar-search"
                            placeholder="请输入关键字"
                            enterButton="搜索"
                            addonBefore={selectBefore}
                            style={{ width: 400, float: 'right' }}
                            size="large"
                            onSearch={this.search.bind(this)}
                        />
                    </div>

                    <Table
                        className='page-pannel-list'
                        columns={ columns }
                        dataSource={ data }
                        rowKey={record => record.id}
                        pagination={{
                            onChange: (page) => {
                                this.setState({ page })
                                this.getData(page)
                            },
                            total: this.state.total,
                            current: this.state.page,
                            pageSize: pageSize,
                            showQuickJumper: true,
                            showTotal: total => `共 ${ Math.ceil(total / pageSize) } 页`
                        }}
                    />

                    <Modal
                        title="提示"
                        maskClosable={false}
                        visible={ removeModalVisible }
                        onOk={ this.removeHandler }
                        onCancel={ this.removeHandleCancel }
                        confirmLoading={ removeLoading }
                        cancelText="取消"
                        okText="确定"
                        className="my-modal-confirm"
                        width={ 600 }
                    >
                        <p>确定将该车主从您的熟车列表移除么？</p>
                    </Modal>

                    <Modal
                        maskClosable={false}
                        title="编辑备注"
                        visible={ remarkModalVisible }
                        onOk={ this.editRemarkHandler }
                        onCancel={ this.editRemarkHandleCancel }
                        confirmLoading={ editRemarkLoading }
                        cancelText="取消"
                        okText="保存"
                        className="my-modal-confirm"
                        width={ 600 }
                    >
                        <div>
                            <h6>备 注：</h6>
                            <TextArea placeholder="请输入备注信息" autosize={{ minRows: 2, maxRows: 6 }} onChange={ this.writeRemark } value={ remarkContent } />
                        </div>
                    </Modal>

                    <AddFamiliarCar
                        visible={ addModalVisible } //控制添加熟车弹窗显示的布尔值
                        callBack={ this.addSuccess }   //添加成功后的回调函数
                        cancelCallBack={ this.cancelAdd } //隐藏弹窗
                        initialTelValue = {this.props.creatTel||''}
                    />

                    {/*<Modal
                        visible={ historyVisible }
                        className="my-modal-confirm"
                        header=""
                        footer=""
                        width={ 1000 }
                        onCancel={ this.historyCancel }
                    >
                        <div>
                            <h6 className="familiar-history">与车主 <strong>{currentPersonal.userName}（{currentPersonal.cellphone}）</strong> 合作历史</h6>
                            <Table
                                className='page-pannel-list'
                                columns={ historyColumns }
                                dataSource={ historyData }
                                rowKey={record => record.tsOrderNo}
                            />
                        </div>
                    </Modal>*/}
                </div>
            </div>
        )
    }
}

export default FamiliarCar
