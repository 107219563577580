import React from 'react'
import './index.less'
import { Row, Col } from 'antd'
import moment from 'moment'

export default ({data})=> {
    let detailBean = data || {}
    let consultSettleType2localObj = {'3':'现金+油卡','1':'现金','2':'油卡'}
    let {
        startPoint,
        startAddress,
        destPoint,
        destAddress,
        taskContent,
        orderContent,
        weight,length, wide,high,
        loadingPredictTime,unloadPredictTime,
        loadUserName,loadUserPhone,
        unloadUserName,unloadUserPhone,
        remark,
        settleType,
        corpOrderType,
        cashPrice,
        oilPrice,
        carTypeLength,
        carSpecialRequire,
        handingMode,
    } = detailBean

    let s1 = cashPrice ? `${cashPrice}元`:''
    let s2 = oilPrice ? `${oilPrice}元`:''
    let payWay = (
        <React.Fragment>
            <label>现  金：</label><span>{s1}</span><span className="as_label">油  卡：</span><span>{s2}</span>
        </React.Fragment>
    )
    let _corpOrderType ='',_corp = '';
    if(corpOrderType == 2){
       _corpOrderType=(
       <Row>
            <Col span={24} className="new-dtl-col"><label>货物：</label><span>{taskContent}</span></Col>
        </Row>
       ) 
        _corp = (
            <div>

                <div className="sline"></div>
                <Row>
                    <Col span={12} className="new-dtl-col"><label>结算方式：</label><span>{consultSettleType2localObj[settleType]||''}</span></Col>
                    <Col span={12} className="new-dtl-col">{payWay}</Col>
                </Row>
            </div>
        )
    }else if (corpOrderType == 1){
       _corp = ''
    }
    if(settleType==1) {
        payWay = (
            <React.Fragment>
                <label>现  金：</label><span>{s1}</span>
            </React.Fragment>
        )
    }else if(settleType==2){
        payWay = (
            <React.Fragment>
                <label>油  卡：</label><span>{s2}</span>
            </React.Fragment>
        )
    }
    let carType
    if(carTypeLength==='不限'){
        if(carSpecialRequire==='不限') carType = '不限'
        else carType = carSpecialRequire
    }else{
        if(carSpecialRequire==='不限') carType = carTypeLength
        else carType = `${carTypeLength}-${carSpecialRequire}`
    }
    carType = carType.replace(/undefined/gi,'')
    if(carType==='-') carType = ''
    let lenWH = []
    if(length) lenWH.push(`长${length}米`)
    if(wide) lenWH.push(`宽${wide}米`)
    if(high) lenWH.push(`高${high}米`)
    lenWH = lenWH.join('，')

    let startt = startAddress ? (<Row>
        <Col span={24} className="new-dtl-col"><label>&nbsp;</label><span>{startAddress}</span></Col>
    </Row>) : ''
    let endd = destAddress ? (<Row>
        <Col span={24} className="new-dtl-col"><label>&nbsp;</label><span>{destAddress}</span></Col>
    </Row>) : ''
    let dun = weight? `${weight}吨`:''
    return (
        <div className="innerTitbox new-dtl">
            <Row>
                <Col span={24} className="new-dtl-col b"><label>出发地：</label><span>{startPoint}</span></Col>
            </Row>
            {startt}
            <Row>
                <Col span={24} className="new-dtl-col b"><label>目的地：</label><span>{destPoint}</span></Col>
            </Row>
            {endd}
            {_corpOrderType}
            <Row>
                <Col span={12} className="new-dtl-col"><label>尺寸：</label><span>{lenWH}</span></Col>
                <Col span={12} className="new-dtl-col"><label>重量：</label><span>{dun}</span></Col>
            </Row>
            <div className="sline"></div>
            <Row>
                <Col span={12} className="new-dtl-col"><label>装车时间：</label><span>{loadingPredictTime?moment(loadingPredictTime).format('YYYY-MM-DD'):''}</span></Col>
                <Col span={12} className="new-dtl-col"><label>卸车时间：</label><span>{unloadPredictTime?moment(unloadPredictTime).format('YYYY-MM-DD'):''}</span></Col>
            </Row>
            <Row>
                <Col span={12} className="new-dtl-col"><label>车辆类型：</label><span>{carType}</span></Col>
                <Col span={12} className="new-dtl-col"><label>装卸方式：</label><span>{handingMode}</span></Col>
            </Row>
            <Row>
                <Col span={12} className="new-dtl-col"><label>装车人姓名：</label><span>{loadUserName}</span></Col>
                <Col span={12} className="new-dtl-col"><label>电话：</label><span>{loadUserPhone}</span></Col>
            </Row>
            <Row>
                <Col span={12} className="new-dtl-col"><label>卸车人姓名：</label><span>{unloadUserName}</span></Col>
                <Col span={12} className="new-dtl-col"><label>电话：</label><span>{unloadUserPhone}</span></Col>
            </Row>
            {_corp}
            <Row>
                <Col span={24} className="new-dtl-col"><label>备注：</label><span style={{display:'inline-block',width:'790px',paddingTop: '7px',verticalAlign:'top',lineHeight:1.5}}>{remark}</span></Col>
            </Row>
        </div>
    )
}
