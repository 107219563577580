import './tips.less'
import { message, Button } from 'antd'
message.config({
    top: 50,
    duration: 3,
    maxCount: 3,
});

export default (type,msg,cb) => { //type: success   info    warn   error  warning  loading
    message[type](msg,cb);
}

