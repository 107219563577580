import React from 'react'
import {filterMenuItem} from 'utils/menu'
import Crumbs from 'components/crumbs'
import  './index.less'

export default class NewUserHelp extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            newUserList:[
                {
                    "url":"http://www.baidu.com/index.html",
                    "tit":"如何发布货源"
                },
                {
                    "url":"http://www.teyuntong.com",
                    "tit":"如何管理运单"
                },
                {
                    "url":"http://www.teyuntong.cn/manage_new/admin/userlogin",
                    "tit":"学会熟车管理"
                },
                {
                    "url":"http://api.56dajian.com/admin_login.html?agentName=2851128143@qq.com",
                    "tit":"企业组织结构及人员管理"
                }
            ]
        }
    }
    componentDidMount() {
        const {index} = this.props
        this.props.regTab(filterMenuItem(index))
    }
    render() {
        return (
            <div>
                <Crumbs str="新手帮助" />
                <div className="newUser">
                    <ul className="newUserBox cf">
                        {
                            this.state.newUserList.map((e,i)=>{
                                return (
                                    <li key={i} className="fl">
                                        <a href={e.url} target="_blank"></a>
                                        <p>{e.tit}</p>
                                    </li>
                                )
                            })
                        }
                    </ul>
                </div>
            </div>
        )
    }
}