import React from 'react'
import { Modal, Button, Radio } from 'antd'
import propTypes from 'prop-types'
import './exPop.less'
import { ajax } from 'utils'

const RadioGroup = Radio.Group;

export default class ExPop extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            list: [],
            chooseItemValue: 0,
            chooseTxt: '',
            areaShow: false
        }
    }
    componentDidMount() {
        /*{
            "success": true,
            "data": [ { "value": "1", "name": "车方爽约" }, { "value": "2", "name": "车方未按照约定时间装车" } ],
            "code": 200,
            "msg": "查询成功"
        }*/
        ajax.fetchJson(`getExTypes`,{exParty:1}).then(data => {
            if (data && data.code == 200) {
                let list = data.data
                if(list && list.length){
                    let chooseArr = list.filter(v=>v.name==='其他'||v.name==='其它')
                    let chooseV = chooseArr.length ? chooseArr[0].value : null
                    this.setState({list},()=>{
                        this.setState({defV:list[0].value, otherItemV:chooseV})
                        this.onRadioChange('', list[0].value)
                    })
                }
            } else {

            }
        })
    }
    onRadioChange(e,chooseV){
        if(arguments.length===2){
            this.setState({
                chooseItemValue: chooseV,
                //areaShow: true,
                areaShow: false,
            });
            return
        }
        this.setState({chooseItemValue: e.target.value,});
        if(this.state.otherItemV) this.setState({areaShow: this.state.otherItemV===e.target.value})
    }
    onTxtChange(e){
        this.setState({
            chooseTxt: e.target.value,
        });
    }
    render() {
        const radioStyle = {
            display: 'block',
            height: '30px',
            lineHeight: '30px',
        };
        const {visible,onOk,onCancel} = this.props
        return (
            <Modal
                className="takeModal"
                title="异常上报"
                centered={true}
                visible={visible}
                onOk={()=>onOk(this.state.chooseItemValue,this.state.chooseTxt,this.state.areaShow,this.state.chooseTxt)}
                onCancel={onCancel}
                okText="提 交"
                maskClosable={false}
                cancelText="取 消"
                closable = {true}
                wrapClassName = "exPop"
                afterClose={()=>{
                    this.setState({chooseItemValue: 0, chooseTxt: ''})
                    if(this.state.defV) this.onRadioChange('',this.state.defV)
                }}
            >
                <h5>请选择上报原因：</h5>
                <RadioGroup onChange={this.onRadioChange.bind(this)} value={this.state.chooseItemValue}>
                    {
                        this.state.list.map((v,i)=>(
                            <Radio className="radioList" key={i} style={radioStyle} value={v.value}>{v.name}</Radio>
                        ))
                    }
                </RadioGroup>
                <div style={{display:this.state.areaShow?'block':'none'}}>
                    <textarea className="textareaGroup"  placeholder="最多100个字" onChange={this.onTxtChange.bind(this)} value={this.state.chooseTxt} maxLength="100" />
                </div>

            </Modal>
        )
    }
}

ExPop.propsTypes={
    visible:propTypes.bool,
    onOk:propTypes.func,
    onCancel:propTypes.func,
};