import React from 'react'
import { ajax } from 'utils'
import {filterMenuItem} from 'utils/menu'
import Crumbs from 'components/crumbs'
import './booksDetailSshowReceipt.less'
import {getTabsItem} from '../books/booksConfig'
import emitter from 'utils/events'
import createHistory from 'history/createHashHistory'
const history = createHistory()
import tips from 'components/tips'
import picEmpty from './../../images/picEmpty.png'

export default class BooksDetailSshowReceipt extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            list: []
        }
    }
    componentDidMount() {
        const {index,bookDetailId,bookActiveTab} = this.props
        let makePowers = menuId=> {
            ajax.fetchJson(`getPowers/${menuId}`).then(data => {
                let canRunPage = false
                if (data && data.code == 200) {
                    let btnList = data.data.buttonList
                    if(btnList && btnList.length){
                        if(btnList.indexOf('查看回执')>-1) canRunPage = true
                    }
                } else tips('error', data && data.msg || '查询操作项失败！')
                if(!canRunPage) history.goBack()
            })
        }
        let menuId = this.props.regTab(filterMenuItem(index))
        if(menuId){
            makePowers(menuId)
        }else{
            this.eventEmitter = emitter.addListener('meunDataYet', (menuId) => {
                makePowers(menuId)
            })
        }

        ajax.fetchJson(`backReceipt/${bookDetailId}`).then(data=>{
            if(data && data.data){
                data.data.forEach((v,i)=>{
                    let imgurl = v.filePath
                    if(imgurl){
                        if(!(imgurl.indexOf('http')===0 || imgurl.indexOf('//')===0)){
                            if(imgurl.indexOf('/')===0) imgurl = '/rootdata' + imgurl
                            else imgurl = '/rootdata/' + imgurl
                            imgurl = ajax.getUrl(imgurl,'com')
                            v.filePath = imgurl
                        }
                    }
                    imgurl = v.fileSmallPath
                    if(imgurl){
                        if(!(imgurl.indexOf('http')===0 || imgurl.indexOf('//')===0)){
                            if(imgurl.indexOf('/')===0) imgurl = '/rootdata' + imgurl
                            else imgurl = '/rootdata/' + imgurl
                            imgurl = ajax.getUrl(imgurl,'com')
                            v.fileSmallPath = imgurl
                        }
                    }
                })
                this.setState({list:data.data})
            }
        }).catch(err=>void(0))

    }
    showBig(item,index){
        //this.setState({overlayShow: true, overlayIndex: index})
        let arr = this.state.list.map((v,i)=>`${i}=${v.fileName||''},${v.filePath||'no'}`)
        let url = `${ajax.getUrl('/showImg/index.html','com',true)}?index=${index}&${arr.join('&')}`
        window.open(url)
    }
    render() {
        const {index,bookDetailId,bookActiveTab} = this.props
        return (
            <div className="bookdetailShowReceipt">
                <Crumbs str={
                    [
                        {name:'运单'},
                        {name:getTabsItem(bookActiveTab,true), link:`#/books/${bookActiveTab}`},
                        {name:'详情', link:`#/books/${bookActiveTab}/${bookDetailId}`},
                        {name: '查看回执'}
                    ]
                } />
                <div className="bookdetail_m">
                    <div className="bookdetailShowReceipt_m">
                        <div className="showThree_tit">查看回执</div>
                        <div className="bookdetailShowReceipt_list">
                            <ul className="ful">
                                {
                                    this.state.list.length>=1 ? this.state.list.map((v,i)=>{
                                        return (
                                            <li key={i}><img title="点击新窗口中查看大图" onClick={()=>this.showBig(v,i)} src={v.fileSmallPath} alt={v.fileName||''}/></li>
                                        )
                                    }):(
                                        <div style={{textAlign:'center',background:'#F5F6FA',padding:'110px 0 130px'}}><img src={picEmpty} /><p style={{fontSize:'28px',color:'#B8C4D7'}}>暂无图片</p></div>
                                    )
                                }
                            </ul>
                        </div>
                    </div>
                </div>

            </div>
        )
    }
}
