/**
 * Created by liuyc14 on 2017/9/27.
 */
const KEYMAP = {
    adminName: "adminName",
    avatar: "avatar",
    ctime: "ctime",
    id: "id",
    loginPhone: "loginPhone",
    nickname: "nickname",
    token: "token",
    trueName: "trueName",
    user: "user",
    autoLogin: 'autoLogin',
    loginId: 'loginId',
    pwd: 'pwd'
};

const webStorage = window.sessionStorage;

class LoginInfo{
    constructor() {
        this.info = {};
    }
    get autoLogin(){
        if(this.info.autoLogin){
            return this.info.autoLogin;
        }else {
            this.info.autoLogin = eval(localStorage.getItem(KEYMAP.autoLogin));
            return this.info.autoLogin || false;
        }
    }
    set autoLogin(autoLogin){
        this.info.autoLogin = autoLogin;
        webStorage.setItem(KEYMAP.autoLogin, autoLogin);
        localStorage.setItem(KEYMAP.autoLogin, autoLogin);
    }

    get adminName(){
        if(this.info.adminName){
            return this.info.adminName;
        }else {
            this.info.adminName = webStorage.getItem(KEYMAP.adminName);
            return this.info.adminName || '';
        }
    }
    set adminName(adminName){
        this.info.adminName = adminName;
        webStorage.setItem(KEYMAP.adminName, adminName);
    }

    get avatar(){
        if(this.info.avatar){
            return this.info.avatar;
        }else {
            this.info.avatar = webStorage.getItem(KEYMAP.avatar);
            return this.info.avatar || '';
        }
    }
    set avatar(avatar){
        this.info.avatar = avatar;
        webStorage.setItem(KEYMAP.avatar, avatar);
    }

    get ctime(){
        if(this.info.ctime){
            return this.info.ctime;
        }else {
            this.info.ctime = webStorage.getItem(KEYMAP.ctime);
            return this.info.ctime || '';
        }
    }
    set ctime(ctime){
        this.info.ctime = ctime;
        webStorage.setItem(KEYMAP.ctime, ctime);
    }

    get id(){
        if(this.info.id){
            return this.info.id;
        }else {
            this.info.id = webStorage.getItem(KEYMAP.id);
            return this.info.id || '';
        }
    }
    set id(id){
        this.info.id = id;
        webStorage.setItem(KEYMAP.id, id);
    }

    get loginPhone(){
        if(this.info.loginPhone){
            return this.info.loginPhone;
        }else {
            this.info.loginPhone = webStorage.getItem(KEYMAP.loginPhone);
            return this.info.loginPhone || '';
        }
    }
    set loginPhone(loginPhone){
        this.info.loginPhone = loginPhone;
        webStorage.setItem(KEYMAP.loginPhone, loginPhone);
    }

    get nickname(){
        if(this.info.nickname){
            return this.info.nickname;
        }else {
            this.info.nickname = webStorage.getItem(KEYMAP.nickname);
            return this.info.nickname || '';
        }
    }
    set nickname(nickname){
        this.info.nickname = nickname;
        webStorage.setItem(KEYMAP.nickname, nickname);
    }

    get token(){
        if(this.info.token){
            return this.info.token;
        }else {
            this.info.token = webStorage.getItem(KEYMAP.token);
            return this.info.token || '';
        }
    }
    set token(token){
        this.info.token = token;
        webStorage.setItem(KEYMAP.token, token);
    }

    get trueName(){
        if(this.info.trueName){
            return this.info.trueName;
        }else {
            this.info.trueName = webStorage.getItem(KEYMAP.trueName);
            return this.info.trueName || '';
        }
    }
    set trueName(trueName){
        this.info.trueName = trueName;
        webStorage.setItem(KEYMAP.trueName, trueName);
    }

    get user(){
        if(this.info.user){
            return this.info.user;
        }else {
            this.info.user = webStorage.getItem(KEYMAP.user);
            return this.info.user || '';
        }
    }
    set user(user){
        this.info.user = user;
        webStorage.setItem(KEYMAP.user, user);
    }

    get loginId(){
        if(this.info.loginId){
            return this.info.loginId;
        }else {
            this.info.loginId = localStorage.getItem(KEYMAP.loginId);
            return this.info.loginId || '';
        }
    }
    set loginId(loginId){
        this.info.loginId = loginId;
        webStorage.setItem(KEYMAP.loginId, loginId);
        localStorage.setItem(KEYMAP.loginId, loginId);
    }

    get pwd(){
        if(this.info.pwd){
            return this.info.pwd;
        }else {
            this.info.pwd = localStorage.getItem(KEYMAP.pwd);
            return this.info.pwd || '';
        }
    }
    set pwd(pwd){
        this.info.pwd = pwd;
        webStorage.setItem(KEYMAP.pwd, pwd);
        localStorage.setItem(KEYMAP.pwd, pwd);
    }
    clearInfo = (clearAll) => {
        for(let key in KEYMAP){
            if(clearAll){
                this.info[KEYMAP[key]] = '';
                webStorage.removeItem(KEYMAP[key]);
                localStorage.removeItem(KEYMAP[key]);
            }else{
                if(this.autoLogin && (key == KEYMAP.loginId || key == KEYMAP.pwd || key == KEYMAP.autoLogin)){

                }else{
                    this.info[KEYMAP[key]] = '';
                    webStorage.removeItem(KEYMAP[key]);
                    localStorage.removeItem(KEYMAP[key]);
                }
            }
        }
    }
}

LoginInfo.getInstance = () => {
    if(window.loginInfo){
        return loginInfo;
    }else{
        window.loginInfo = new LoginInfo();
        return window.loginInfo;
    }
};

export default LoginInfo;