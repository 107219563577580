export const api = { // http://59.110.104.99:8096
    //获取左侧菜单列表接口
    leftMenu:                             '/originservice/jurisdiction/getLeftMenuList',
    //获取页面可操作项接口
    getPowers:                            '/originservice/jurisdiction/getOperaList',
    //订单管理列表接口(全部/待同意/装货中/装货完成/卸货完成)
    getBooksList:                         '/originservice/order/list',
    //订单管理违约异常列表接口
    getBookCatchList:                    '/originservice/order/ex/list',
    //提交异常上报接口
    sbtCatch:                             '/originservice/order/ex/save/report',
    //完成卸货接口
    unloadGoods:                          '/originservice/order/unload',
    //订单详情
    bookDetail:                           '/originservice/order/detail',
    //根据车方或者货方获取异常上报类型
    getExTypes:                           '/originservice/order/ex/getExType',
    //拒绝承运接口
    rejectDeal:                           '/originservice/order/reject',
    //同意承运接口
    agreeDeal:                            '/originservice/order/agree',
    //三证查看接口
    getThree:                             '/originservice/orderFile/scanThreeFile',
    //回执单查看接口
    backReceipt:                          '/originservice/orderFile/scanReceiptFile',
    //登录
    login:                                 '/originservice/user/login',
    //登出
    loginOut:                              '/originservice/user/logout',
    //消息弹窗提醒接口
    globalMsg:                            '/originservice/inMail/messageTip',
    //消息置为已读接口
    doReaded:                            '/originservice/inMail/changeRead',
    //发货熟车列表接口
    addCarFlModalSearch:                '/originservice/familiar/carList',

    getWorkstationData: '/originservice/user/getWorkstationData', //获取首页工作台
    getFamiliarList: '/originservice/familiar/list', //获取熟车列表
    delFamiliar: '/originservice/familiar/delete', //删除熟车
    editFamiliarRemark: '/originservice/familiar/editRemark', //编辑熟车备注
    addFamiliar: '/originservice/familiar/add', //添加熟车
    importFamiliar: '/originservice/familiar/import', // 批量导入熟车
    historyOrder: '/originservice/familiar/historyOrder', //合作历史

    getMessageList: '/originservice/inMail/list', //获取站内信列表
    delMessage: '/originservice/inMail/del', //删除消息
}