import React from 'react'
import './confirm.less'
import { Modal } from 'antd'

export default ({title,closable=true,visible,onOk,onCancel,okText,content})=>{
    return (
        <Modal
            centered={true}
            title={title}
            maskClosable={false}
            closable={closable}
            visible={visible}
            onOk={onOk}
            onCancel={onCancel}
            okText={okText}
            cancelText="取 消"
        >
            <div className="bodyContent">{content}</div>
        </Modal>
    )
}