import React from 'react';
import moment from 'moment';
import 'moment/locale/zh-cn';
import {filterMenuItem} from 'utils/menu';
import {Form, List, Modal, Select, Input, Row, Col, Button, Divider, DatePicker, Radio, message,Checkbox, Dropdown, Menu, Icon, Pagination} from 'antd';
import CityFilter from '../../components/cityFilter/cityFilter';
import GoodsSearch from '../../components/goodsSearch/goodsSearch';
import service from '../../service/goodsSrcService';
import './new.less';
import {loadType, regexWeight, regexSize, regexTel, regexChinese} from '../../constant';
import LoginInfo from '../../utils/loginInfo';
import CarModal from '../../components/addFamiliarCar/addFamiliarCar';
import pic1 from './a1.png'
import pic2 from './a2.png'
import { ajax } from 'utils'
import tips from 'components/tips'
import {kindsStatusObj} from "../books/booksConfig";

const Option = Select.Option;
const { TextArea,Search } = Input;
const RadioGroup = Radio.Group;
const FormItem = Form.Item;

const FormItemLayoutLeft = {
    labelCol: {span: 6},
    wrapperCol: {span: 18}
};

const FormItemLayoutLeft2 = {
    labelCol: {span: 5},
    wrapperCol: {span: 18}
};

const FormItemLayoutRight = {
    labelCol: {span: 4},
    wrapperCol: {span: 20}
};

const FormItemLayout1 = {
    labelCol: {span: 3},
    wrapperCol: {span: 21}
};

class DataForm extends React.Component{
    constructor(props){
        super(props);
        this.state = {
            startValue: null,
            endValue: null,
            endOpen: false,
            payTypeValue: 1,
            carType: '',//车辆类型
            carDes: '',//特殊需求
            showTypeModal: false,
            desList: [],
            isStandard: false,
            gsInfo: {},//标准货源信息

            ifSelectedFaimilcarItem: false,
        };

        this.showCarType = false;
        this.init = false;
        this.now = new Date();
        this.dayMiS = 24 * 60 * 60 * 1000;
    }

    componentWillReceiveProps(nextProps){
        if(nextProps.desList && nextProps.desList != this.state.desList){
            this.setState({desList: nextProps.desList});
        }
        if(nextProps.formData){
            const formData = nextProps.formData || {};

            if(formData.carTypeLength && this.props.carTypeLength != formData.carTypeLength){
                this.showCarType = true;
                this.setState({carType: formData.carTypeLength});
            }
            if(formData.carSpecialRequire && this.props.carSpecialRequire != formData.carSpecialRequire){
                this.showCarType = true;
                this.setState({carDes: formData.carSpecialRequire});
            }
            if(formData.gsInfo){
                this.setState({gsInfo: formData.gsInfo, isStandard: true});
            }
            if(formData.gsInfo === null){
                this.setState({gsInfo: {}, isStandard: false});
            }
            if(formData.remark != this.props.formData.remark){
                let desList = this.state.desList;
                desList.map(c => {
                    if(formData.remark.indexOf(c.name) >= 0){
                        c.checked = true;
                    }else{
                        c.checked = false;
                    }
                });
                this.setState({desList: [...desList]});
            }
        }
    }

    componentDidMount(){
    }

    render() {
        const familiarCar = this.props.flModalSelects || []
        const { getFieldDecorator } = this.props.form;
        const {formData} = this.props;
        const gsInfo = this.state.gsInfo || {};
        const loginInfo = LoginInfo.getInstance();
        const familiarCarNum = familiarCar.length||''
        return (
            <div>
                <Modal
                    maskClosable={false}
                    width={600}
                    closable={true}
                    visible={this.state.showTypeModal}
                    onCancel={()=>{this.setState({showTypeModal: false})}}
                    title=""
                    footer=""
                >
                    <div className="modalBox">
                        <h3 className="modal-title-off">车辆类型</h3>
                        <div className="modal-title-label">板车长度</div>
                        <div>
                            <RadioGroup
                                style={{width: '100%'}}
                                onChange={(e)=>{
                                    const value = e.target.value;
                                    this.props.onChange({carTypeLength: value});
                                    this.setState({carType: value});
                                }}
                                value={this.state.carType}
                            >
                                {this.props.carTypeList.map((c, i) => <Radio key={c.value} value={c.name == '不限' ? '' : c.name} className="radioWidth">{c.name}</Radio>)}
                            </RadioGroup>
                        </div>
                        <Divider className="carDivider"/>
                        <div className="modal-title-label">板车特殊要求</div>
                        <div>
                            <RadioGroup
                                style={{width: '100%'}}
                                onChange={(e)=> {
                                this.props.onChange({carSpecialRequire: e.target.value});
                                    this.setState({carDes: e.target.value});
                                }}
                                value={this.state.carDes}
                            >
                                {this.props.carDesList.map((c, i) => <Radio key={c.value} value={c.name == '不限' ? '' : c.name} className="radioWidth">{c.name}</Radio>)}
                            </RadioGroup>
                        </div>
                        <div style={{marginTop: 20}}>
                            <Row style={{paddingBottom: 20}}>
                                <Col span={12}>
                                    <Button
                                        style={{width:'98%'}}
                                        className="defaultButton"
                                        onClick={()=>this.setState({showTypeModal: false})}
                                    >取消
                                    </Button>
                                </Col>
                                <Col span={12} style={{textAlign: 'right'}}>
                                    <Button
                                        type="primary"
                                        style={{width:'98%'}}
                                        className="okButton"
                                        onClick={() => {
                                            //const {setFieldsValue} = this.props.form;
                                            //setFieldsValue({carType: this.state.carType + ' , ' + this.state.carDes});
                                            this.setState({showTypeModal: false});
                                            this.showCarType = true;
                                        }}
                                    >
                                        确定
                                    </Button>
                                </Col>
                            </Row>
                        </div>
                    </div>
                </Modal>
                <Form
                    onSubmit={this.handleSubmit}
                >
                    <Row>
                        <Col span={10} id="col_start">
                            <FormItem {...FormItemLayoutLeft} label={'出发地'}>
                                {getFieldDecorator('startPoint', {
                                    rules: [{
                                        required: true,
                                        message: '请输入出发地!'
                                    }],
                                    initialValue: ''
                                })(
                                    <CityFilter
                                        cid="col_start"
                                        placeholder="出发地"
                                        size="large"
                                        onChangeValue={ city => this.startCity = city}
                                    />
                                )}
                            </FormItem>
                        </Col>
                        <Col span={14}>
                            <FormItem {...FormItemLayoutRight} label={'详细地址'}>
                                {getFieldDecorator('startAddress', {
                                    rules: [
                                        {max: 40, message: '最多输入40个字符'}
                                    ],
                                    initialValue: ''
                                })(
                                    <Input autoComplete="off" placeholder="出发地详细地址" size="large" />
                                )}
                            </FormItem>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={10} id="col_end">
                            <FormItem {...FormItemLayoutLeft} label={'目的地'}>
                                {getFieldDecorator('endPoint', {
                                    rules: [{
                                        required: true,
                                        message: '请输入目的地!'
                                    }],
                                    initialValue: ''
                                })(
                                    <CityFilter
                                        cid="col_end"
                                        placeholder="目的地"
                                        size="large"
                                        onChangeValue={ city => this.endCity = city}
                                    />
                                )}
                            </FormItem>
                        </Col>
                        <Col span={14}>
                            <FormItem {...FormItemLayoutRight} label={'详细地址'}>
                                {getFieldDecorator('endAddress', {
                                    rules: [
                                        {max: 40, message: '最多输入40个字符'}
                                    ],
                                    initialValue: ''
                                })(
                                    <Input autoComplete="off" placeholder="目的地详细地址" size="large" />
                                )}
                            </FormItem>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24} className="oneItem" id="col_goods">
                            <FormItem {...FormItemLayout1} label={'货物'}>
                                {getFieldDecorator('taskContent', {
                                    rules: [
                                        {required: true, message: '请输入货物!'},
                                        {max: 40, message: '最多输入40个字符'}
                                    ],
                                    initialValue: formData.taskContent || gsInfo.displayContent || ''
                                })(
                                    <GoodsSearch
                                        cid="col_goods"
                                        placeholder="如：360挖机"
                                        size="large"
                                        onCheckedData={this.handleCheckedData}
                                    />
                                )}
                            </FormItem>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={15} className="leftWeight weightError">
                            <FormItem labelCol={{span: 3}} wrapperCol={{span: 20}} label={'重量'}>
                                {getFieldDecorator('weight', {
                                    rules: [
                                        {pattern: regexWeight, message: '需为900以内数字'}
                                    ],
                                    initialValue: formData.weight || ''
                                })(
                                    <Input autoComplete="off" placeholder="请输入货物总重量" size="large" />
                                )}
                            </FormItem>
                        </Col>
                        <Col span={9}>
                            <span className="span-text span-black">吨</span>
                            <span className="span-text" style={{display: this.state.isStandard ? '' : 'none'}}>
                                {gsInfo.displayContent}参考重量为{gsInfo.weight}吨&nbsp;&nbsp;
                                <a onClick={() => {
                                    const {setFieldsValue} = this.props.form;
                                    setFieldsValue({'weight': gsInfo.weight});
                                }}>使用</a>
                            </span>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={15} className="leftWeight sizeError">
                            <Row>
                                <Col span={3} style={{textAlign: 'right'}} className="lineHeight labelLeft">尺寸:</Col>
                                <Col span={20}>
                                    <Row>
                                        <Col span={7}>
                                            <FormItem>
                                                {getFieldDecorator('length', {
                                                    rules: [
                                                        {pattern: regexSize, message: '需为99.99以内数字'}
                                                    ],
                                                    initialValue: formData.length || ''
                                                })(
                                                    <Input autoComplete="off" placeholder="长" size="large" />
                                                )}
                                            </FormItem>
                                        </Col>
                                        <Col span={1} className="lineHeight">X</Col>
                                        <Col span={7}>
                                            <FormItem>
                                                {getFieldDecorator('width', {
                                                    rules: [
                                                        {pattern: regexSize, message: '需为99.99以内数字'}
                                                    ],
                                                    initialValue: formData.wide || ''
                                                })(
                                                    <Input autoComplete="off" placeholder="宽" size="large" />
                                                )}
                                            </FormItem>
                                        </Col>
                                        <Col span={1} className="lineHeight">X</Col>
                                        <Col span={7}>
                                            <FormItem>
                                                {getFieldDecorator('height', {
                                                    rules: [
                                                        {pattern: regexSize, message: '需为99.99以内数字'}
                                                    ],
                                                    initialValue: formData.high || ''
                                                })(
                                                    <Input autoComplete="off" placeholder="高" size="large" />
                                                )}
                                            </FormItem>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Col>
                        <Col span={9}>
                            <span className="span-text span-black">米</span>
                            <span className="span-text" style={{display: this.state.isStandard ? '' : 'none'}}>
                                {gsInfo.displayContent}参考尺寸为{gsInfo.length}*{gsInfo.width}*{gsInfo.height}&nbsp;&nbsp;
                                <a onClick={() => {
                                    const {setFieldsValue} = this.props.form;
                                    setFieldsValue({'length': gsInfo.length, 'width': gsInfo.width, 'height': gsInfo.height});
                                }}>使用</a>
                            </span>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={12} id="col_uptime">
                            <FormItem {...FormItemLayoutLeft2} label={'装车时间'}>
                                {getFieldDecorator('upTime', {
                                    rules: [],
                                    initialValue: this.state.startValue
                                })(
                                    <DatePicker
                                        getCalendarContainer={() => document.getElementById('col_uptime')}
                                        disabledDate={this.disabledStartDate}
                                        format="YYYY-MM-DD"
                                        size="large"
                                        placeholder='装车时间'
                                        onChange={this.onStartChange}
                                        onOpenChange={this.handleStartOpenChange}
                                    />
                                )}
                            </FormItem>
                        </Col>
                        <Col span={12} id="col_downtime">
                            <FormItem {...FormItemLayoutLeft} label={'卸车时间'}>
                                {getFieldDecorator('downTime', {
                                    rules: [],
                                    initialValue: this.state.endValue
                                })(
                                    <DatePicker
                                        getCalendarContainer={() => document.getElementById('col_downtime')}
                                        disabledDate={this.disabledEndDate}
                                        format="YYYY-MM-DD"
                                        size="large"
                                        placeholder='卸车时间'
                                        onChange={this.onEndChange}
                                        onOpenChange={this.handleEndOpenChange}
                                    />
                                )}
                            </FormItem>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={12}>
                            <FormItem {...FormItemLayoutLeft2} label={'车辆类型'}>
                                {getFieldDecorator('carType', {
                                    rules: [],
                                    initialValue: this.showCarType ? this.state.carType + this.state.carDes: ''
                                })(
                                    <Input
                                        autoComplete="off"
                                        placeholder="车辆类型"
                                        readOnly={true}
                                        size="large"
                                        onClick={() => {
                                            this.setState({showTypeModal: true});
                                        }}
                                    />
                                )}
                            </FormItem>
                        </Col>
                        <Col span={12} id="col_uptype">
                            <FormItem {...FormItemLayoutLeft} label={'装卸方式'}>
                                {getFieldDecorator('upType', {
                                    rules: [],
                                    initialValue: formData.handingMode || loadType[0].name
                                })(
                                    <Select
                                        size="large"
                                        getPopupContainer={() => document.getElementById('col_uptype')}
                                    >
                                        {loadType.map(c => <Option key={c.id} value={c.name}>{c.name}</Option>)}
                                    </Select>
                                )}
                            </FormItem>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={12}>
                            <FormItem {...FormItemLayoutLeft2} label={'装车人姓名'}>
                                {getFieldDecorator('upName', {
                                    rules: [
                                        {required: this.state.uptel, message: '请填写装车人姓名'},
                                        {max: 15, message: '最多输入15个字符'},
                                        {pattern: regexChinese, message: '姓名中包含非法字符，只能输入中文'}
                                    ],
                                    initialValue: ''
                                })(
                                    <Input
                                        autoComplete="off"
                                        placeholder="请输入装车人姓名"
                                        size="large"
                                        onChange={ e=> {
                                            this.setState({upName: e.target.value}, () => {
                                                this.props.form.validateFields(['uptel'], { force: true });
                                            });
                                        }}
                                    />
                                )}
                            </FormItem>
                        </Col>
                        <Col span={12}>
                            <FormItem {...FormItemLayoutLeft} label={'电话'}>
                                {getFieldDecorator('uptel', {
                                    rules: [
                                        {required: this.state.upName, message: '请填写装车人电话'},
                                        {pattern: regexTel, message: '请输入正确的电话号码，以0或1开头的11位数字'}
                                    ],
                                    initialValue: ''
                                })(
                                    <Input
                                        autoComplete="off"
                                        placeholder="请输入装车人电话"
                                        size="large"
                                        onChange={ e=> {
                                            this.setState({uptel: e.target.value}, () => {
                                                this.props.form.validateFields(['upName'], { force: true });
                                            });
                                        }}
                                    />
                                )}
                            </FormItem>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={12}>
                            <FormItem {...FormItemLayoutLeft2} label={'卸车人姓名'}>
                                {getFieldDecorator('downName', {
                                    rules: [
                                        {required: this.state.downtel, message: '请填写卸车人姓名'},
                                        {max: 15, message: '最多输入15个字符'},
                                        {pattern: regexChinese, message: '姓名中包含非法字符，只能输入中文'}
                                    ],
                                    initialValue: ''
                                })(
                                    <Input
                                        autoComplete="off"
                                        placeholder="请输入卸车人姓名"
                                        size="large"
                                        onChange={ e=> {
                                            this.setState({downName: e.target.value}, () => {
                                                this.props.form.validateFields(['downtel'], { force: true });
                                            });
                                        }}
                                    />
                                )}
                            </FormItem>
                        </Col>
                        <Col span={12}>
                            <FormItem {...FormItemLayoutLeft} label={'电话'}>
                                {getFieldDecorator('downtel', {
                                    rules: [
                                        {required: this.state.downName, message: '请填写卸车人电话'},
                                        {pattern: regexTel, message: '请输入正确的电话号码，以0或1开头的11位数字'}
                                    ],
                                    initialValue: ''
                                })(
                                    <Input
                                        autoComplete="off"
                                        placeholder="请输入卸车人电话"
                                        size="large"
                                        onChange={ e=> {
                                            this.setState({downtel: e.target.value}, () => {
                                                this.props.form.validateFields(['downName'], { force: true });
                                            });
                                        }}
                                    />
                                )}
                            </FormItem>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24}>
                            <Divider />
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24} className="oneItem" id="col_payType">
                            <FormItem {...FormItemLayout1} label={'结算方式'}>
                                {getFieldDecorator('payType', {
                                    rules: [],
                                    initialValue: 1
                                })(
                                    <Select
                                        size="large"
                                        getPopupContainer={() => document.getElementById('col_payType')}
                                        onChange={ value => {
                                            this.setState({payTypeValue: value});
                                        }}
                                    >
                                        <Option value={1}>现金</Option>
                                        <Option value={2}>油卡</Option>
                                        <Option value={3}>现金 + 油卡</Option>
                                    </Select>
                                )}
                            </FormItem>
                        </Col>
                    </Row>
                    <Row>
                        {this.renderPayType(this.state.payTypeValue)}
                    </Row>
                    <Row>
                        <Col span={24} className="oneItem">
                            <FormItem {...FormItemLayout1} label={'备注'}>
                                {getFieldDecorator('des', {
                                    rules: [
                                        {max: 200, message: '最多可输入200个字符'}
                                    ],
                                    initialValue: formData.remark || ''
                                })(
                                    <Input
                                        autoComplete="off"
                                        placeholder="请输入备注信息"
                                        size="large"
                                        maxLength="200"
                                    />
                                )}
                            </FormItem>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={2} style={{width: '10.41%'}}></Col>
                        <Col span={21} style={{width: '89.59%'}}>
                            <ul className="checkButton">
                                {this.renderDesList()}
                            </ul>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24} className="oneItem">
                            <FormItem {...FormItemLayout1} label={'联系电话'}>
                                {getFieldDecorator('telphone', {
                                    rules: [
                                        {required: true, message: '请输入联系电话'},
                                        {pattern: regexTel, message: '请输入正确的电话号码，以0或1开头的11位数字'}
                                    ],
                                    initialValue: loginInfo.loginPhone || ''
                                })(
                                    <Input autoComplete="off" placeholder="请输入联系人电话号码" size="large" maxLength="11" />
                                )}
                            </FormItem>
                        </Col>
                        {/*
                         <Col span={4} style={{textAlign: 'right'}} className="lineHeight">
                         <a style={{display: 'none'}}>更换联系人</a>
                         </Col>
                        */}
                    </Row>
                    {
                        this.props.empList.length > 1 ? (
                            <Row><Col span={24} className="oneItem" id="col_emplist"><FormItem {...FormItemLayout1} label={'货物归属'}>
                                {getFieldDecorator('department', {
                                    rules: [],
                                    initialValue: this.props.empList[0].depCode
                                })(
                                    <Select
                                        size="large"
                                        getPopupContainer={() => document.getElementById('col_emplist')}
                                    >
                                        {this.props.empList.map(c => <Option key={c.depCode}>{`${c.parentName} - ${c.depName}`}</Option>)}
                                    </Select>
                                )}
                            </FormItem></Col></Row>
                        ): null
                    }

                    <Row>
                        <Col span={24} className={['oneItem',(this.state.ifSelectedFaimilcarItem&&(familiarCarNum>0))?'saddselect':''].join(' ')} id="col_notice">
                            <a href="javascript:;" className="saddselect_txt" onClick={()=>this.props.zhiDing()}>共选中{familiarCarNum}辆熟车</a>
                            <FormItem {...FormItemLayout1} label={'通知范围'}>
                                {getFieldDecorator('notice', {
                                    rules: [],
                                    initialValue: this.props.familiarCount ? '1' : '2'
                                })(
                                    <Select
                                        size="large"
                                        getPopupContainer={() => document.getElementById('col_notice')}
                                        onChange={value => {
                                            if(value == 1 && !this.props.familiarCount){
                                                this.setState({showModal: true, ifSelectedFaimilcarItem: false});

                                            }else if(value==3){
                                                this.setState({ifSelectedFaimilcarItem: true});
                                                if(this.props.familiarCount){//没细看他代码，猜测是有企业熟车
                                                    if(familiarCarNum>0){

                                                    }else{
                                                        this.props.zhiDing()
                                                    }
                                                }
                                                else  this.setState({showModal: true})
                                            }else{
                                                this.setState({ifSelectedFaimilcarItem: false});
                                            }
                                        }}
                                    >
                                        <Option key={"1"}>全部熟车</Option>
                                        <Option key={"3"}>指定熟车</Option>
                                        <Option key={"2"}>特运通代发</Option>
                                    </Select>
                                )}
                            </FormItem>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24} style={{ textAlign: 'center' }}>
                            <Button
                                type="primary"
                                htmlType="submit"
                                style={{width: 300, marginTop: 34}}
                                size="large"
                                className="okButton"
                                loading={this.props.submiting}
                            >
                                发布
                            </Button>
                        </Col>
                    </Row>
                </Form>

                <CarModal
                    visible={this.state.showModal}
                    callBack={() => this.addSuccess = true}
                    cancelCallBack={() => this.setState({showModal: false})}
                >
                </CarModal>
            </div>
        )
    }

    showRemark = () => {
        const {desList} = this.state;
        let result = '';
        desList.map(c => {
            if(c.checked){
                result += c.name + ',';
            }
        });
        return result;
    };

    handleSubmit = (e) => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if(!err){
                if(!this.props.familiarCount && !this.addSuccess && values.notice == 1){
                    message.warn('请先添加熟车');
                    return false;
                }

                const {onSubmit} = this.props;
                const loginInfo = LoginInfo.getInstance();

                let data = {
                    "startPoint":"", //出发地
                    "destPoint":"", //目的地
                    "taskContent":"", //货物内容
                    "startDetailAdd":"", //出发地详细地址
                    "destDetailAdd":" ", //目的地详细地址
                    "corpUserId":"",//发布人id
                    "corpUserName":"",
                    "tel":"",//联系电话
                    "weight":"", //重量
                    "length":"", //长
                    "wide":"",  //宽
                    "high":"", //高
                    "loadingTime":"", //装车时间
                    "unloadTime":"", //卸车时间
                    "remark":"",//备注
                    "startProvinc":"",//出发省
                    "startCity":"",//出发市
                    "startArea":"",//出发区域
                    "destProvinc":"",//到达省
                    "destCity":"",//到达市
                    "destArea":"",//到达区域
                    "referWeight":"", //参考重量(标准化货源处取值)
                    "referLength":"", //参考长(标准化货源处取值)
                    "referWidth":"",  //参考宽(标准化货源处取值)
                    "referHeight":"", //参考高(标准化货源处取值)
                    "type":"",//货物型号(标准化货源处取值)
                    "brand":"",//货物品牌(标准化货源处取值)
                    "goodTypeName":"",//货物类型名称,如“装载机”，“挖掘机”(标准化货源处取值)
                    "goodNumber":"",//货物的台数，针对标准化的数据(标准化货源处取值)
                    "isStandard":"1",//是否是标准化数据：0是，1不是(标准化货源处取值)
                    "matchItemId":"",//匹配项的ID，针对标准化的数据(标准化货源处取值)
                    "carTypeLength":"", //车辆类型-长度
                    "carSpecialRequire":"", //板车特殊要求
                    "handingMode":"",//装卸方式
                    "loadUserName":"",//装车人姓名
                    "loadUserPhone":"",//装车人电话
                    "unloadUserName":"",//卸车人姓名
                    "unloadUserPhone":"",//卸车人电话
                    "settlementType":"",//结算方式1现金 2油卡 3现金和油卡
                    "cashPrice":"", //现金
                    "oilPrice":"",//油卡
                    "notifyPattern":"",//通知方式 1：通知熟车 2：特运通代发
                    "similarTsId":"",  //相似货源的ID，拷贝的来源ID
                    "familiarIds":"",  //指定熟车 id集合
                };

                data.startPoint = values.startPoint;
                data.destPoint = values.endPoint;
                data.taskContent = values.taskContent.replace(/^\s+|\s+$/g, '');
                data.startDetailAdd = values.startAddress || '';
                data.destDetailAdd = values.endAddress || '';
                data.corpUserId = loginInfo.id;
                data.corpUserName = loginInfo.trueName;
                data.tel = values.telphone || '';
                data.weight = values.weight || '';
                data.length = values.length || '';
                data.wide = values.width || '';
                data.high = values.height || '';
                data.loadingTime = (values.upTime && values.upTime.format('YYYY-MM-DD')) || '';
                data.unloadTime = (values.downTime && values.downTime.format('YYYY-MM-DD')) || '';
                data.remark = values.des || '';
                data.startProvinc = this.startCity.provinc || '';
                data.startCity = this.startCity.city || '';
                data.startArea = this.startCity.area || '';
                data.destProvinc = this.endCity.provinc || '';
                data.destCity = this.endCity.city || '';
                data.destArea = this.endCity.area || '';


                const gsInfo = this.state.gsInfo || {};
                if(this.state.isStandard && gsInfo && gsInfo.id){
                    data.referWeight = (gsInfo.weight && gsInfo.weight * 100) || '';
                    data.referLength = (gsInfo.length && gsInfo.length * 100) || '';
                    data.referWidth = (gsInfo.width && gsInfo.width * 100) || '';
                    data.referHeight = (gsInfo.height && gsInfo.height * 100) || '';
                    data.type = gsInfo.type;
                    data.brand = gsInfo.brand;
                    data.goodTypeName = gsInfo.machineType;
                    data.goodNumber = gsInfo.goodNumber;
                    data.isStandard = 0;
                    data.matchItemId = gsInfo.id;
                }
                //如果多个部门，选择一个部门
                if(this.props.empList.length > 1){
                    data.deptPathCode = values.department;
                }
                data.carTypeLength = this.state.carType || ''; //车辆类型-长度
                data.carSpecialRequire = this.state.carDes || ''; //板车特殊要求
                data.handingMode = values.upType || '';//装卸方式
                data.loadUserName = values.upName || '';//装车人姓名
                if(!data.loadUserName) delete data.loadUserName;
                data.loadUserPhone = values.uptel || '';//装车人电话
                if(!data.loadUserPhone) delete data.loadUserPhone;
                data.unloadUserName = values.downName || '';//卸车人姓名
                if(!data.unloadUserName) delete data.unloadUserName;
                data.unloadUserPhone = values.downtel || '';//卸车人电话
                if(!data.unloadUserPhone) delete data.unloadUserPhone;
                data.settlementType = values.payType || '';//结算方式1现金 2油卡 3现金和油卡
                data.cashPrice = values.cash || ''; //现金
                data.oilPrice = values.card || '';//油卡
                data.notifyPattern = values.notice || '';//通知方式 1：通知熟车 2：特运通代发 3:
                if(data.notifyPattern==3){
                    /*
                    let userId = sessionStorage.getItem('id')
                    let p = localStorage.getItem(`flModalSelects_${userId}`)
                    if(p){
                        p = JSON.parse(p)
                        p = p.map(v=>v.id)
                        //data.familiarIds = JSON.stringify(p)
                        data.familiarIds = p
                    }*/
                    let p = this.props.flModalSelects
                    if(p){
                        p = p.map(v=>v.id)
                        //data.familiarIds = JSON.stringify(p)
                        data.familiarIds = p
                    }
                }else delete data.familiarIds
                data.similarTsId = this.props.similarTsId || '';  //相似货源的ID，拷贝的来源ID

                if(onSubmit){
                    onSubmit(data);
                }
            }
        });
    };

    checkMoney = (rule, value, callback) => {
        console.log(parseFloat(value))
        if(value && Number.isNaN(parseFloat(value))){
            callback('请填写有效数字！');
        }

        if (value && value < 0) {
            callback('不能小于0！');
        }

        // Note: 必须总是返回一个 callback，否则 validateFieldsAndScroll 无法响应
        callback()
    };

    renderPayType = (typeid) => {
        const { getFieldDecorator, setFieldsValue } = this.props.form;
        const pattern = /^(0|e)/gi;
        const cashRules = [
            //{required: typeid == 1 || typeid == 3, message: '请填写现金'},
            {max: 7, message: '最多7位数字'},
            {validator: this.checkMoney}
        ];
        const cardRules = [
            //{required: typeid == 2 || typeid == 3, message: '请填写现金'},
            {max: 7, message: '最多7位数字'},
            {validator: this.checkMoney}
        ];

        const change = (e, fileds) => {
            const value = e.target.value || '';
            if(/^0/.test(value)) e.target.value = '';
            if(/\./.test(value)) e.target.value = value.replace('.', '');
            if(/[^\d]/.test(value)) e.target.value = value.replace(/[^\d]/g, '');
            if(value.length > 7) e.target.value = value.substring(0, 7);

            setFieldsValue({[fileds]: e.target.value});

            // if(value && pattern.test(value)){
            //     setFieldsValue({[fileds]: ''});
            // }
            // if(value && /\.$/.test(value)){
            //     setFieldsValue({[fileds]: value.replace(/\.\d*/g, '')});
            // }
        };

        const InputCash = getFieldDecorator('cash', {
            rules: cashRules,
            initialValue: this.state.cash || ''
        })(
            <Input
                autoComplete="off"
                placeholder="现金"
                size="large"
                onChange={e => change(e, 'cash')}
            />
        );

        const InputCard = getFieldDecorator('card', {
            rules: cardRules,
            initialValue: ''
        })(
            <Input autoComplete="off" placeholder="油卡" size="large" onChange={e => change(e, 'card')}/>
        );
        if(typeid == 1){
            return (
                <Row>
                    <Col span={24} className="oneItem">
                        <FormItem {...FormItemLayout1} label={'现金'}>
                            {InputCash}
                        </FormItem>
                    </Col>
                </Row>
            );
        }
        else if(typeid == 2){
            return (
                <Row>
                    <Col span={24} className="oneItem">
                        <FormItem {...FormItemLayout1} label={'油卡'}>
                            {InputCard}
                        </FormItem>
                    </Col>
                </Row>
            );
        }
        else{
            return (
                <Row>
                    <Col span={12}>
                        <FormItem {...FormItemLayoutLeft2} label={'现金'}>
                            {InputCash}
                        </FormItem>
                    </Col>
                    <Col span={12}>
                        <FormItem {...FormItemLayoutLeft} label={'油卡'}>
                            {InputCard}
                        </FormItem>
                    </Col>
                </Row>
            );
        }
    };

    renderDesList = () => {
        const {desList} = this.state;
        let result = [];
        desList.forEach((item, index) => {
            result.push(<li key={item.value} className={item.checked ? "" : ""} onClick={()=>this.handleDesItemClick(index)}>{item.name}</li>);
        });
        return result;
    };

    handleDesItemClick = (index) => {
        const {desList} = this.state;
        const {getFieldValue, setFieldsValue} = this.props.form;
        let text = getFieldValue('des');

        const des = desList[index];
        if(des){
            des.checked = !(!!des.checked);
            if(text.indexOf(des.name) >= 0 ){
                //text = text.replace(new RegExp('\\s?' + des.name), '');
            }else{
                text+= (!text ? '' : ' ') + des.name;
            }
            setFieldsValue({des: text});
        }
    };

    isChecked = (value) => {
        const {desList} = this.state;
        for(let i = 0; i < desList; i++){
            if(value === desList[i]){
                return desList[i].checked;
            }
        }
        return true;
    };

    handleCheckedData = data => {
        if(data && data.standardStatus == 0){
            this.setState({
                isStandard: true,
                gsInfo: data
            });
        }
    };

    disabledStartDate = (startValue) => {
        const endValue = this.state.endValue;
        let disable = false;

        if(!startValue && !endValue){
            return false;
        }
        if (startValue && !endValue) {
            disable = this.now.valueOf() > startValue.valueOf();
        }
        else if(startValue && endValue){
            disable = moment(this.now).format('YYYY-MM-DD') > startValue.format('YYYY-MM-DD') || startValue.format('YYYY-MM-DD') > endValue.format('YYYY-MM-DD');
        }

        return disable;

    };

    disabledEndDate = (endValue) => {
        const startValue = this.state.startValue;
        let disable = false;

        if(endValue && !startValue){
            disable = this.now.valueOf()  >= endValue.valueOf();
        }else if(endValue && startValue){
            //disable = endValue.format('YYYY-MM-DD') <= startValue.format('YYYY-MM-DD');
            disable = startValue.format('YYYY-MM-DD') > endValue.format('YYYY-MM-DD');
        }

        return disable;
    };

    __onChange = (field, value) => {
        this.setState({
            [field]: value
        });
    };

    onStartChange = (value) => {
        const e = this.state.endValue;
        const {setFieldsValue} = this.props.form;
        this.__onChange('startValue', value);
        if(this.props.onChange){
            this.props.onChange(value, this.state.endValue);
        }
    };

    onEndChange = (value) => {
        this.__onChange('endValue', value);
        if(this.props.onChange){
            this.props.onChange(this.state.startValue, value);
        }
    };

    handleStartOpenChange = (open) => {
        if (!open) {
            this.setState({ endOpen: true });
        }
    };

    handleEndOpenChange = (open) => {
        this.setState({ endOpen: open });
    };

}

const WrappedForm = Form.create()(DataForm);

export default class NewGoodsSrc extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            submiting: false,
            formData: {},
            empList: [],
            familiarCount: 0,
            carTypeList: [], //车辆类型列表
            carDesList: [],//特殊需求列表
            historyList: [],//货源发布历史列表
            desList: [],//备注选项列表

            flModalShow:false,
            flModalData:[],
            flModalSelects:[],
            flModalSelectsTemp:[],
            flModalForm:{
                limit: 8,
                carType: 1,
                phone: '',
                name: '',
                offset: 0,
            },
            flModalType: 'phone',
            flModalTotal: 0
        };
    }
    componentDidMount() {
        const {index, menuId} = this.props;
        this.props.regTab(filterMenuItem(index));

        const {router} = this.props;
        const match = router.match || {};
        const params = match.params || {};

        const similarTsId = params.similarTsId || "";
        this.similarTsId = similarTsId;

        this.init();
        this.flModalGet();
        let userId = sessionStorage.getItem('id')
        let flModalSelects = localStorage.getItem(`flModalSelects_${userId}`)
        if(flModalSelects){
            flModalSelects = JSON.parse(flModalSelects)
            this.setState({flModalSelects,flModalSelectsTemp:[...flModalSelects]})
        }
    }

    scheckedChange = (num) =>{
        let flModalForm =  {...this.state.flModalForm, phone: '', name: '', offset:0, carType:num}
        this.setState({flModalForm},()=>{
            this.flModalGet()
        })
    }
    flModalSearch(v){
        let flModalForm = {...this.state.flModalForm, name:'', phone:'', offset:0}
        flModalForm[this.state.flModalType] = v
        this.setState({flModalForm},()=>{
            this.flModalGet()
        })
    }
    flModalGet(){
        let mockData = {
            "success": true,
            "data": [
                {"id": 90, "userName": "测试测试东", /* 姓名*/ "cellphone": "16576767676" /* 手机号*/}
            ],
            "limit": 10,
            "count": 1,
            "code": 200
        }
        ajax.fetchJson(`addCarFlModalSearch`,'get',this.state.flModalForm/*,mockData*/).then(data => {
            if (data && data.code == 200) {
                let flModalData = data.data || []
                let flModalTotal = data.count || 0
                this.setState({flModalData,flModalTotal})
            } else tips('error', data && data.msg || '查询失败！')
        })
    }
    selectFlModalType(v){
        this.setState({flModalType:v})
    }
    closeFlModal(){
        this.setState({flModalShow:false})
    }
    sureFlModal(){
        let {flModalSelectsTemp} = this.state
        let userId = sessionStorage.getItem('id')
        if(flModalSelectsTemp && flModalSelectsTemp.length>0){
            localStorage.setItem(`flModalSelects_${userId}`,JSON.stringify(flModalSelectsTemp))
        }else{
            localStorage.removeItem(`flModalSelects_${userId}`)
        }
        this.setState({flModalSelects:[...flModalSelectsTemp]})
        this.closeFlModal()
    }
    flModalAfterClose(){
        this.setState({flModalSelectsTemp:[...this.state.flModalSelects]})
    }
    onFlModalPageChange(page, pageSizes){
        let offset = (page - 1) * this.state.flModalForm.limit
        let flModalForm = {...this.state.flModalForm,offset}
        this.setState({flModalForm}, ()=>this.flModalGet())
    }
    flModalAdd(item){
        let arr = this.state.flModalSelectsTemp || []
        if(!arr.some(v=>v.id===item.id)){
            arr.push(item)
            this.setState({flModalSelectsTemp:arr})
        }
    }
    flModalDel(item){
        let arr = this.state.flModalSelectsTemp || []
        let index = -1
        arr.forEach((v,i)=>{if(v.id===item.id) index = i})
        if(index!==-1){
            arr.splice(index,1)
            this.setState({flModalSelectsTemp:arr})
        }
    }
    zhiDing(){
        this.setState({flModalShow:true})
    }
    render(){
        const selectBefore = (
            <Select defaultValue="phone" style={{ width: 90 }} onChange={ this.selectFlModalType.bind(this) }>
                <Option value="phone">手机号</Option>
                <Option value="name">姓名</Option>
            </Select>
        )
        return (
            <div className='container_gsNew'>
                <h3 className="title">发布货源</h3>
                <div className="content">
                    <Row gutter={24} style={{height: '100%'}}>
                        <Col span={18} className="leftCol">
                            <div className="contentDiv">
                                <h3 className='title'>货源信息</h3>
                                <div className="content" style={{marginTop: 40}}>
                                    <WrappedForm
                                        onChange={this.handleFormChange}
                                        submiting={this.state.submiting}
                                        formData={this.state.formData}
                                        carTypeList={this.state.carTypeList}
                                        carDesList={this.state.carDesList}
                                        desList={this.state.desList}
                                        familiarCount={this.state.familiarCount}
                                        empList={this.state.empList}
                                        onSubmit={this.handleSubmit}
                                        zhiDing={this.zhiDing.bind(this)}
                                        flModalSelects={this.state.flModalSelects}
                                    />
                                </div>
                            </div>
                        </Col>
                        <Col span={6} className="rightCol">
                            <div className="contentDiv">
                                <h3 className='title'>历史发布货源</h3>
                                <div className="content" style={{marginTop: 30}}>
                                    <List
                                        size="smal"
                                        header=""
                                        footer=""
                                        split={false}
                                        dataSource={this.state.historyList}
                                        renderItem={item => (
                                            <List.Item key={item.id}>
                                                <a
                                                    className={item.checked ? 'itemChecked' : ''}
                                                    onClick={() => this.clickHistoryItem(item.id, item)}
                                                >
                                                    {moment(new Date(item.ctime)).format('YYYY-MM-DD') + ' ,  ' + item.taskContent}
                                                </a>
                                            </List.Item>
                                        )
                                    }
                                    />
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Modal
                        className="addd"
                        centered={true}
                        title="指定熟车"
                        maskClosable={false}
                        closable={true}
                        visible={this.state.flModalShow}
                        width={800}
                        onOk={this.sureFlModal.bind(this)}
                        onCancel={this.closeFlModal.bind(this)}
                        okText="确 定"
                        cancelText="取 消"
                        afterClose={this.flModalAfterClose.bind(this)}
                    >
                        <div className="saddd">
                            <div className="fix adddfm">
                                <div className="fl">
                                    <label className={this.state.flModalForm.carType==1?'schecded':''}>
                                        <Checkbox checked={!!(this.state.flModalForm.carType==1)} onChange={()=>this.scheckedChange(1)} />
                                        我添加的熟车
                                    </label>
                                    <label className={this.state.flModalForm.carType==2?'schecded':''}>
                                        <Checkbox checked={!!(this.state.flModalForm.carType==2)} onChange={()=>this.scheckedChange(2)} />
                                        全部企业熟车
                                    </label>
                                </div>
                                <div className="fr">
                                    <Search
                                        className="familiar-search"
                                        placeholder="请输入关键字"
                                        enterButton="搜索"
                                        addonBefore={selectBefore}
                                        style={{ width: 400, float: 'right' }}
                                        size="large"
                                        onSearch={this.flModalSearch.bind(this)}
                                    />
                                </div>
                            </div>
                            <Row>
                                <Col span={12}>
                                    <p className="addfmp">从下面选择要添加的熟车</p>
                                    <div className="addfmdv addfmdv1">
                                        <div>
                                            <table width="100%" border="0" cellSpacing="0" cellPadding="0">
                                                <thead>
                                                <tr><th>&emsp;&nbsp;车方姓名</th><th>联系电话</th></tr>
                                                </thead>
                                                <tbody>
                                                {
                                                    this.state.flModalData.map((item,index)=>{
                                                        return <tr onClick={()=>this.flModalAdd(item)} key={index}><td>&emsp;&nbsp;{item.userName}</td><td>{item.cellphone}</td></tr>
                                                    })
                                                }
                                                </tbody>
                                            </table>
                                        </div>
                                        <div className="addpg">
                                            <Pagination
                                                defaultCurrent={1}
                                                pageSize={this.state.flModalForm.limit}
                                                current={Math.floor(this.state.flModalForm.offset/8)+1}
                                                total={this.state.flModalTotal}
                                                onChange={this.onFlModalPageChange.bind(this)}
                                                size="small"
                                            />
                                        </div>
                                    </div>
                                </Col>
                                <Col span={1}></Col>
                                <Col span={1} className="addar"><a><img src={pic1} alt=""/></a></Col>
                                <Col span={1}></Col>
                                <Col span={9}>
                                    <p className="addfmp">已经选定熟车</p>
                                    <div className="addfmdv addfmdv2">
                                        <ul className="ful">
                                            {
                                                this.state.flModalSelectsTemp.map((item,index)=>{
                                                    return <li key={index}><a onClick={()=>this.flModalDel(item)} className="fr" href="javascript:;"><img src={pic2} alt=""/></a>{item.userName}({item.cellphone})</li>
                                                })
                                            }
                                        </ul>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </Modal>
                </div>
            </div>
        );
    }

    init = () => {
        service
            .getHistoryList()
            .then(d => {
                const data = d.data || {};
                if(data.code == 200){
                    this.setState({historyList: data.data});
                }else{
                    message.warn('货源发布历史列表加载失败！');
                }
            }, error => {
                message.warn('货源发布历史列表加载错误！');
            });
        service
            .init()
            .then(d => {
                const data = d.data || {};
                if(data.code == 200){
                    const _d = data.data || {};
                    this.setState({
                        familiarCount: _d.familiarCount || 0,
                        empList: _d.empList || [],
                        carTypeList: _d.carType || [],
                        carDesList: _d.specialRequire || [],
                        desList: _d.remarkTip || []
                    });
                }else{
                    message.warn('货源初始化失败！');
                }
            }, error => {
                message.warn('货源初始化错误！');
            });

        if(this.similarTsId && this.similarTsId != 0){
            this.clickHistoryItem(this.similarTsId);
        }

    };

    handleSubmit = (fromData) => {
        // console.log('submit data >>>>>>>>>>>>>>>>>>>>>>>>');
        // console.log(fromData);
        this.setState({submiting: true});
        service.publish(fromData, {
            ok: data => {
                message.success('发布成功！');
                this.setState({submiting: false});
                window.location.hash = '/goodsSrc/2';
            },
            failed: e => {
                this.setState({submiting: false});
                message.warn('发布失败，' + e.msg);
            },
            error: e => {
                this.setState({submiting: false});
                message.error('发布接口错误，请稍后再试！');
            }
        });
    };

    handleFormChange = (changedFields) => {
        this.setState(({ formData }) => ({
            formData: { ...formData, ...changedFields }
        }));
    };

    clickHistoryItem = (id, item) => {
        const {historyList} = this.state;
        historyList.forEach((c, i) => {
            c.checked = c.id == id;
        });

        this.setState({historyList: historyList});

        service.getHistoryInfo(id, {
            ok: data => {
                service.check(data.taskContent, {
                    ok: data2 => {
                        if(data2 && data2.standardStatus == 0){
                            data.gsInfo = data2;
                        }else{
                            data.gsInfo = null;
                        }
                        this.setState({formData: data});
                    }
                })
            },
            failed: e => {

            },
            error: e => {

            }
        });
    };


}
