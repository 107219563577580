import React from 'react'
import { ajax } from 'utils'
import {filterMenuItem} from 'utils/menu'
import Crumbs from 'components/crumbs'
import './booksDetailThreeCard.less'
import {getTabsItem} from '../books/booksConfig'
import emitter from 'utils/events'
import { Tabs } from 'antd';
import createHistory from 'history/createHashHistory'
import tips from 'components/tips'
const history = createHistory()
import btnL from './img/rotate_l.png'
import btnLH from './img/rotate_l_h.png'
import btnR from './img/rotate_r.png'
import btnRH from './img/rotate_r_h.png'
import picEmpty from './../../images/picEmpty.png'

const TabPane = Tabs.TabPane;

export default class BooksDetailShowCredentials extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            //2-回执单，3-司机身份证，4-司机驾驶证，5-车头行驶本，6-挂车行驶本
            cards: [
                {name:'车头行驶证', key:5},
                {name:'挂车行驶证', key:6},
                {name:'驾驶员驾驶证', key:4},
                {name:'驾驶员身份证', key:3}
            ],
            //overlayIndex: 0,
            overlayShow: false,
            tabCurrentIndex: 0,
            rotates:[0,0,0,0]
        }
    }
    componentDidMount() {
        const {index,bookDetailId,bookActiveTab} = this.props
        let makePowers = menuId=> {
            ajax.fetchJson(`getPowers/${menuId}`).then(data => {
                let canRunPage = false
                if (data && data.code == 200) {
                    let btnList = data.data.buttonList
                    if(btnList && btnList.length){
                        if(btnList.indexOf('查看三证')>-1) canRunPage = true
                    }
                }else tips('error', data && data.msg || '查询操作项失败！')
                if(!canRunPage) history.goBack()
            })
        }
        let menuId = this.props.regTab(filterMenuItem(index))
        if(menuId){
            makePowers(menuId)
        }else{
            this.eventEmitter = emitter.addListener('meunDataYet', (menuId) => {
                makePowers(menuId)
            })
        }

        ajax.fetchJson(`getThree/${bookDetailId}`).then(data=>{
            let cards = this.state.cards
            data && data.data && data.data.forEach((v,i)=>{ ////2-回执单，3-司机身份证，4-司机驾驶证，5-车头行驶本，6-挂车行驶本
                if(v.fileType==5){
                    cards[0] = {...cards[0], img1: v.fileSmallPath, img2: v.filePath, names: v.fileName}
                }else if(v.fileType==6){
                    cards[1] = {...cards[1], img1: v.fileSmallPath, img2: v.filePath, names: v.fileName}
                }else if(v.fileType==4){
                    cards[2] = {...cards[2], img1: v.fileSmallPath, img2: v.filePath, names: v.fileName}
                }else if(v.fileType==3){
                    cards[3] = {...cards[3], img1: v.fileSmallPath, img2: v.filePath, names: v.fileName}
                }
            })
            cards.forEach((v,i)=>{
                let imgurl = v.img1
                if(imgurl){
                    if(!(imgurl.indexOf('http')===0 || imgurl.indexOf('//')===0)){
                        if(imgurl.indexOf('/')===0) imgurl = '/rootdata' + imgurl
                        else imgurl = '/rootdata/' + imgurl
                        imgurl = ajax.getUrl(imgurl,'com')
                        v.img1 = imgurl
                    }
                }
                imgurl = v.img2
                if(imgurl){
                    if(!(imgurl.indexOf('http')===0 || imgurl.indexOf('//')===0)){
                        if(imgurl.indexOf('/')===0) imgurl = '/rootdata' + imgurl
                        else imgurl = '/rootdata/' + imgurl
                        imgurl = ajax.getUrl(imgurl,'com')
                        v.img2 = imgurl
                    }
                }
            })
            this.setState({cards})
        }).catch(err=>void(0))

    }
    callback(key){
        //console.log(key)
    }
    changeTab(tabCurrentIndex){
        this.setState({tabCurrentIndex})
    }
    rotatePic(ltOrRt){
        let rotates = this.state.rotates
        let deg = rotates[this.state.tabCurrentIndex]
        if(ltOrRt==='left') deg += 90
        else deg -= 90
        rotates[this.state.tabCurrentIndex] = deg
        this.setState({rotates})
    }
    showBig(item,index){
        //this.setState({overlayShow: true, overlayIndex: index})
        let arr = this.state.cards.map((v,i)=>`${i}=${v.names||''},${v.img2||'no'}`)
        let url = `${ajax.getUrl('/showImg/index.html','com',true)}?index=${index}&${arr.join('&')}`
        window.open(url)
    }
    /*overlayBtnLeft(e){
        e.nativeEvent.stopImmediatePropagation()
        e.nativeEvent.stopPropagation();
        let { overlayIndex } = this.state
        overlayIndex--
        if(overlayIndex<0) overlayIndex = 0
        this.setState({overlayIndex})
    }
    overlayBtnRight(e){
        e.nativeEvent.stopPropagation();
        e.nativeEvent.stopImmediatePropagation()
        let { overlayIndex } = this.state
        overlayIndex++
        if(overlayIndex>=this.state.cards.length) overlayIndex = this.state.cards.length -1
        this.setState({overlayIndex})
    }*/
    render() {
        const {index,bookDetailId,bookActiveTab} = this.props
        return (
            <div className="bookdetailThreeCard">
                <Crumbs str={
                    [
                        {name:'运单'},
                        {name:getTabsItem(bookActiveTab,true), link:`#/books/${bookActiveTab}`},
                        {name:'详情', link:`#/books/${bookActiveTab}/${bookDetailId}`},
                        {name: '查看三证'}
                    ]
                } />
                <div className="bookdetail_m">
                    <div className="bookdetailThreeCard_m">
                        <div className="showThree_tit">查看三证</div>
                        <ul className="ful tyttabs">
                            {
                                this.state.cards.map((v,i)=>{
                                    return <li key={v.key} className={this.state.tabCurrentIndex===i?'active':''} onClick={()=>this.changeTab(i)}><div>{v.name}</div></li>
                                })
                            }
                        </ul>
                        <div className="tytcons">
                            {
                                this.state.cards.map((v,i)=>{
                                    let imgCon
                                    if(v.img1&&v.img1.length) imgCon = <img style={{transform:`rotate(${this.state.rotates[i]}deg)`}} onClick={()=>this.showBig(v,i)} src={v.img1} alt={v.names||''} title="点击新窗口中查看大图" />
                                    else imgCon = <div style={{textAlign:'center',background:'#F5F6FA',padding:'130px 0'}}><img src={picEmpty} /><p style={{fontSize:'28px',color:'#B8C4D7'}}>暂无图片</p></div>
                                    return (
                                        <div className="tytcon_i" style={{display:this.state.tabCurrentIndex===i?'block':'none'}} key={v.key}>
                                            <div className="tytcon_i_img">
                                                {imgCon}
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                        <div className="tyt_rotate">
                            <a className="rotateBtn rotateBtn_l" href="javascript:;" onClick={()=>this.rotatePic('left')}>
                                <em><img src={btnL}/></em>
                                <em><img src={btnLH}/></em>
                                <span>向左旋转</span>
                            </a>
                            <a className="rotateBtn rotateBtn_r" href="javascript:;" onClick={()=>this.rotatePic('right')}>
                                <em><img src={btnR}/></em>
                                <em><img src={btnRH}/></em>
                                <span>向右旋转</span>
                            </a>
                        </div>
                    </div>
                </div>
                {/*<div className="showPicBig" style={{display:this.state.overlayShow?'block':'none'}} onClick={()=>this.setState({overlayShow: false})}>
                    <p>{this.state.cards[this.state.overlayIndex].name}</p>
                    <div className="fix">
                        <div className="fl" onClick={this.overlayBtnLeft.bind(this)}>左按钮</div>
                        <div className="fr" onClick={this.overlayBtnRight.bind(this)}>右按钮</div>
                        <div className="showPicBig_m"><img src={this.state.cards[this.state.overlayIndex].img2} /></div>
                    </div>
                </div>*/}
            </div>
        )
    }
}
