import React from 'react'
import { Route } from 'react-router-dom'
import { Switch,Redirect } from 'react-router'
import createHistory from 'history/createHashHistory'
import LoginInfo from '../utils/loginInfo';
const history = createHistory()
import './content.less'
import Index from 'pages/index'
import SetPwd from 'pages/setPwd'
import NoMatch from 'pages/404'
import Books from 'pages/books'
import BooksDetail from 'pages/booksDetail'
import BooksDetailThreeCard from 'pages/booksDetailThreeCard'
import BooksDetailSshowReceipt from 'pages/booksDetailSshowReceipt'
import FamiliarCar from 'pages/familiarCar'
import goodsSrc, {GoodsList} from '../pages/goodsSrc'
import UpdateHeadPic from 'pages/updateHeadPic'
import NewUserHelp from 'pages/newUserHelp'
import MyMsg from 'pages/myMsg'
import Forget from 'pages/forget/forget'

export default class Contents extends React.Component {
    checkLoign(){
        const loginInfo = LoginInfo.getInstance();
        const user = loginInfo.user;
        const token = loginInfo.token;
        if(!user || !token) history.replace(`/login`)
    }
    componentDidMount(){
        this.checkLoign()
    }
    componentWillUnmount = () => {
        this.setState = (state,callback)=>{
            return
        }
    }
    render() {
        const props = this.props;
        return (
            <div className="main_c">
                <div className="main_c_s clearfix">
                    <Switch>
                        <Route exact path="/" render={() => <Index index="index" {...props} />} />
                        <Route path="/index" render={() => <Index index="index" {...props} />} />
                        <Route path="/setPwd" render={() => <SetPwd index="setPwd" {...props} />} />
                        <Route exact path="/books" render={() => <Books index="books" {...props} />} />
                        <Route exact path="/books/:tabActive" render={(match) => <Books bookActiveTab={match.match.params.tabActive} index="books" {...props} />} />
                        <Route exact path="/books/:tabActive/:id" render={(match) => <BooksDetail bookActiveTab={match.match.params.tabActive} bookDetailId={match.match.params.id} index="books" {...props} />} />
                        <Route exact path="/books/:tabActive/:id/threeCard" render={(match) => <BooksDetailThreeCard bookActiveTab={match.match.params.tabActive} bookDetailId={match.match.params.id} index="books" {...props} />} />
                        <Route exact path="/books/:tabActive/:id/showReceipt" render={(match) => <BooksDetailSshowReceipt bookActiveTab={match.match.params.tabActive} bookDetailId={match.match.params.id} index="books" {...props} />} />
                        <Route exact path="/familiarCar" render={router => <FamiliarCar index="familiarCar" {...props} router={router} />} />
                        <Route path="/familiarCar/:creatTel" render={(match) => <FamiliarCar creatTel={match.match.params.creatTel} index="familiarCar" {...props} />} />
                        <Route exact path="/goodsSrc" render={router => <goodsSrc.GoodsList index="goodsSrc" {...props} router={router} />} />
                        <Route path="/goodsSrc/:tab" render={router => <goodsSrc.GoodsList index="goodsSrc" {...props} router={router} />} />
                        <Route exact path="/goodsSrcNew" render={router => <goodsSrc.GoodsNew index="goodsSrcNew" {...props} router={router} />} />
                        <Route path="/goodsSrcNew/:similarTsId" render={router => <goodsSrc.GoodsNew index="goodsSrcNew" {...props} router={router} />} />
                        <Route path="/updateHeadPic" render={() => <UpdateHeadPic index="updateHeadPic" {...props} />} />
                        <Route path="/newUserHelp" render={() => <NewUserHelp index="newUserHelp" {...props} />} />
                        <Route path="/myMsg" render={() => <MyMsg index="myMsg" {...props} />} />
                        <Route render={() => <NoMatch index="noMatch" {...props} />} />
                    </Switch>
                </div>
            </div>
        )
    }
}
