/**
 * Created by liuyc14 on 2017/10/16.
 */
const testMain = "http://59.110.104.99";
const releaseMain = "http://47.93.178.186";
const productMain = "http://www.teyuntong.cn";

const getEnv = ()=>{
    const href = location.href;
    let env = 'production';
    if(href.indexOf('localhost') >= 0){
        env = 'local';
    }
    else if(href.indexOf('39.107.155.163') >= 0){
        env = 'test';
    }
    else if(href.indexOf('47.93.178.186') >= 0){
        env = 'release';
    }
    else if(href.indexOf('qiye.teyuntong.com') >= 0) {
        env = 'production';
    }
    return env;
};

let env = getEnv();

export default {
    env: env,
    baseDomain: {
        local: '/originservice',
        test: testMain + '/originservice',
        release: releaseMain + '/originservice',
        production: productMain + '/originservice'
    },
    getEnv: getEnv
};