/**
 * Created by liuyc14 on 2018/8/23.
 */
import goodsList from './list';
import goodsNew from './new';
import goodsView from './view';

export default {
    GoodsList: goodsList,
    GoodsNew: goodsNew,
    GoodsView: goodsView
};