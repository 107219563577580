import fetchJsonp from 'fetch-jsonp'
import 'whatwg-fetch'
import LoginInfo from './loginInfo';
import { api } from 'api/api'

export function fetchJsonP(url, params) {
  params = {
    ...params,
  }
  return fetchJsonp(url, params)
}

export const fetchJsonpByGet = url => query => {
  const params = {
    method: 'GET',
  }
  let getQuery = '?'
  if (query) {
    for(let name in query) {
      getQuery += `${name}=${query[name]}&`
    }
  }
  let getUrl = url + (query ? getQuery.substring(0, getQuery.length - 1) : '')
  return fetchJsonP(getUrl, params)
}

export const fetchJson = (name,methods,opts,mockData) =>{
    const loginInfo = LoginInfo.getInstance();
    //todo: abort fetch: maybe by emitter,...
    let method= 'GET'
    let opt = {}
    if(typeof methods==='object'){
        method = 'GET'
        opt = methods
    }else if(typeof methods==='string') method = methods
    if(typeof opts==='object') opt = opts
    let sarr = name.split('/')
    let url = getUrl(api[sarr[0]]) + (sarr.length>1 ? `/${sarr[1]}` : '')
    if(mockData) {
        url = ''
        method = 'get'
    }

    const defAppOpt = {
        token: loginInfo.token,
        clientId: localStorage.getItem('clientId')||'',
        clientSign: 7,
        h5Version: 1.0
    }

    let fetchOpts = {
        method: method,
        body: JSON.stringify(opt),
        headers: Object.assign({},defAppOpt,{
            "Content-Type": "application/json",  //text/plain;charset=UTF-8  //multipart/form-data //application/x-www-form-urlencoded;charset=UTF-8  //    inherited from the blob.type property
            //'Accept': 'application/json',
        }),
        //credentials: "include"
    }

    if(method.toUpperCase()=== 'GET'){
        let arr=[]
        for(let i in opt) arr.push(`${i}=${opt[i]}`)
        if(arr.length){
            url += url.indexOf('?')>1 ? '&':'?'
            url += arr.join('&')
        }
        delete fetchOpts.body
    }
    return fetch(url, fetchOpts).then(function(response) {
        if (response.ok) {
            if(mockData) return mockData
            return response.json()
        }
        return Promise.reject({response})
    }).catch(e => console.log(e))
}

export const getUrl = (url,comStr,ifTestCom)=>{
    if(url && (url.indexOf('//')===0 || url.indexOf('http://')===0 || url.indexOf('https://')===0)) return url //没考虑其它协议
    let _url, href = location.href
    if(href.indexOf('www.teyuntong')>-1||href.indexOf('boss.teyuntong')>-1){
        _url = 'http://www.teyuntong.cn'
        if(comStr==='com'||comStr==='net'||comStr==='comnet'){
            _url = 'http://www.teyuntong.com'
            if(href.indexOf('teyuntong.net')>-1) _url = 'http://www.teyuntong.net'
        }
    }
    else if(href.indexOf('47.93.81.31')>-1) _url = 'http://47.93.81.31'
    else if(href.indexOf('releasecp.teyuntong')>-1||href.indexOf('47.93.178.186')>-1){
        _url = 'http://47.93.178.186'
        /*if(ifTestCom){
            _url = '47.93.178.186'
        }*/
    }
    else if(href.indexOf('59.110.104.99')>-1) _url = 'http://59.110.104.99'
    else if(href.indexOf('39.107.155.163')>-1){
        _url = 'http://59.110.104.99'
        if(ifTestCom){
            _url = 'http://39.107.155.163'
        }
    }
    else if(href.indexOf('qiye.teyuntong.com')>-1) {
        _url = 'http://www.teyuntong.cn'
    }
    else _url = location.origin || (location.protocol  ? location.protocol + '//' + location.host : '//' + location.host)
    return _url + (url||'')
}