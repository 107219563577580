/**
 * Created by liuyc14 on 2018/8/29.
 */
/**
 * 发布中状态码(上架)
 * @type {number}
 */
export const goods_Status_PUBLISH = 1;

/**
 * 下架状态码(下架)
 * @type {number}
 */
export const goods_Status_SOLDOUT = 5;

export const contentType_json = 'application/json';//application/json;charset=utf-8
export const contentType_form = 'application/x-www-form-urlencoded;charset=utf-8';

/**
 * 装卸方式
 * @type {*[]}
 */
export const loadType = [{id: 1, name: '一装一卸'}, {id: 2, name: '一装多卸'}, {id: 3, name: '多装一卸'}, {id: 4, name: '多装多卸'}];

/**
 * 结算方式
 * @type {*[]}
 */
export const payType = [{id: 1, name: '现金'}, {id: 2, name: '油卡'}, {id: 3, name: '现金+油卡'}];

export const regPwd = /^.{6,16}$/;
export const regexWeight = /(^[1-9]\d?$)|(^[1-8]\d{0,2}$)|(^900$)|(^[1-8]\d{0,2}\.\d{1,2}$)|(^900\.0+$)|(^9\d?\.\d{1,2}$)/gi;
export const regexSize = /(^\d{1,2}$)|(^\d{1,2}\.\d{1,2}$)/gi;
export const regexPhone = /^1\d{10}$/gi;
export const regexTel = /^[0-1]\d{10}$/gi;
export const regexChinese = /^[\u4e00-\u9fa5]+$/gi;
export const regexName = /(^[\u4e00-\u9fa5][\u4e00-\u9fa5•]*[\u4e00-\u9fa5]$)|^[\u4e00-\u9fa5]$/gi; //发布熟车，姓名必须是中文，支持•

export const offReason = [{id:1, name: '已成交'}, {id:2, name: '厂家取消发货'}, {id:3, name: '货源信息录入有误'}, {id:4, name: '其他'}];


export const POWER_GoodsList = {
    buttonList_detail: '详情',
    buttonList_publish: '发布相似货源',
    buttonList_off: '下架',
    buttonList_up: '上架',
    tabList_all: '全部货源',
    tabList_publish: '发布中',
    tabList_off: '已下架'
};